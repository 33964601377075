import {zodResolver} from '@hookform/resolvers/zod';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Checkbox,
  InputAdornment,
  IconButton,
  Stack,
  Link,
  Typography,
  Button,
} from '@mui/material';
import {Auth} from 'aws-amplify';
import React, {useEffect, useState} from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {z} from 'zod';
import {PasswordValidation} from '../../components/PasswordValidation';
import {TextField} from '../../components/TextField';
import {CompanyType} from '../../types';
import {combineAlphanumericCharacters} from '../../utils/combineAlphanumericCharactersRegex';

export const formInput = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
  password: z.string().superRefine((value, ctx) => {
    // パスワードの最小長をチェック
    if (value.length < 8) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'too_small',
      });
    }
    // 正規表現でのバリデーションをチェック
    if (!combineAlphanumericCharacters.test(value)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'invalid_type',
      });
    }
  }),
  phoneNumber: z.string().min(1),
  workLocation: z.string().min(1),
  division: z.string().min(1),
  position: z.any(),
});

export type FormInput = z.infer<typeof formInput>;

export const SignUpScreen = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: {errors, dirtyFields},
  } = useForm<FormInput>({
    mode: 'all',
    defaultValues: {
      email: '',
      password: '',
    },
    criteriaMode: 'all',
    shouldFocusError: false,
    resolver: zodResolver(formInput),
  });
  const [existsUser, setExistsUser] = useState(false);
  const [companyType, setCompanyType] = useState<CompanyType | null>(null);
  const [termsAcception, setTermsAcception] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit: SubmitHandler<FormInput> = data => {
    Auth.signUp({
      username: data.email,
      password: data.password,
    })
      .then(() => {
        if (!companyType) {
          setExistsUser(true);
          return;
        }
        localStorage.setItem('companyType', companyType);
        localStorage.setItem('firstName', data.firstName);
        localStorage.setItem('lastName', data.lastName);
        localStorage.setItem('phoneNumber', data.phoneNumber);
        localStorage.setItem('workLocation', data.workLocation);
        localStorage.setItem('division', data.division);
        localStorage.setItem('position', data.position);
        navigate('/signUpVerification', {
          state: {
            email: data.email,
            password: data.password,
          },
        });
      })
      .catch(err => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (err.code === 'UsernameExistsException') {
          setExistsUser(true);
        }
      });
  };

  useEffect(() => {
    document.title = `${t('createAccount')} | MAST`;
  }, [t]);

  return !companyType ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '100vh',
      }}>
      <Typography mb={3} variant="h1">
        MAST
      </Typography>
      <Typography align="center">{t('selectIndustry')}</Typography>
      <Stack
        direction="row"
        justifyContent="center"
        gap={'24px'}
        sx={{mt: '24px'}}>
        <Button
          variant="outlined"
          onClick={() => {
            setCompanyType('Shipper');
          }}>
          {t('exporter')}
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setCompanyType('Forwarder');
          }}>
          {t('logistics')}
        </Button>
      </Stack>
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '452px',
        minHeight: '100vh',
        mx: 'auto',
        py: '72px',
      }}>
      <Typography variant="h1" mb={3}>
        MAST
      </Typography>
      <Typography align="center">
        {`${companyType === 'Forwarder' ? t('logistics') : t('exporter')}`}
        {t('provideAccount')}
      </Typography>
      <Stack justifyContent="center" sx={{mt: '32px'}} spacing={1}>
        <Stack
          direction="row"
          spacing={2}
          alignContent="center"
          sx={{
            flexWrap: 'wrap',
          }}>
          <Typography sx={{fontWeight: '500'}}>{t('name')}</Typography>
          <Typography variant="error">{t('requiredField')}</Typography>
        </Stack>
        <Stack direction="row">
          <Stack direction="column" sx={{width: 'calc((652px * 0.35) - 4px)'}}>
            <TextField
              fullWidth
              placeholder="姓"
              sx={{
                backgroundColor: '#fff',
              }}
              error={!!errors.lastName}
              {...register('lastName', {required: true})}
            />
            {errors.lastName && (
              <Typography variant="error">{t('enterLastName')}</Typography>
            )}
          </Stack>
          <Stack
            direction="column"
            sx={{width: 'calc((652px * 0.35) - 4px)', pl: '8px'}}>
            <TextField
              fullWidth
              placeholder="名"
              error={!!errors.firstName}
              sx={{
                backgroundColor: '#fff',
              }}
              {...register('firstName', {required: true})}
            />
            {errors.firstName && (
              <Typography variant="error">{t('enterFirstName')}</Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack
        direction="column"
        justifyContent="center"
        sx={{mt: '16px'}}
        spacing={1}>
        <Stack
          direction="row"
          spacing={2}
          alignContent="center"
          sx={{
            flexWrap: 'wrap',
          }}>
          <Typography sx={{fontWeight: '500'}}>{t('email')}</Typography>
          <Typography variant="error">{t('requiredField')}</Typography>
        </Stack>
        <Stack
          direction="column"
          sx={{
            width: 'calc(652px * 0.7)',
          }}>
          <TextField
            fullWidth
            placeholder={t('email')}
            sx={{
              backgroundColor: '#fff',
            }}
            error={!!errors.email || existsUser}
            {...register('email', {
              required: true,
            })}
            onChange={() => setExistsUser(false)}
          />
          {errors.email ? (
            <Typography variant="error">{t('enterEmailCorrectly')}</Typography>
          ) : null}
          {existsUser ? (
            <Typography variant="error">{t('emailAlreadyInUse')}</Typography>
          ) : null}
        </Stack>
      </Stack>
      <Stack
        direction="column"
        justifyContent="center"
        sx={{mt: '16px'}}
        spacing={1}>
        <Stack
          direction="row"
          spacing={2}
          alignContent="center"
          sx={{
            flexWrap: 'wrap',
          }}>
          <Typography sx={{fontWeight: '500'}}>{t('password')}</Typography>
          <Typography sx={{ml: '12px'}} variant="error">
            {t('requiredField')}
          </Typography>
        </Stack>
        <Stack sx={{width: 'calc(652px * 0.7)'}}>
          <TextField
            error={!!errors.password}
            fullWidth
            aria-label="password"
            placeholder={t('password')}
            type={showPassword ? 'text' : 'password'}
            sx={{
              backgroundColor: '#fff',
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...register('password', {
              required: true,
            })}
          />
          <Box
            sx={{
              marginTop: '16px',
            }}>
            <PasswordValidation
              wordCountError={
                (errors.password?.types?.custom &&
                  ((typeof errors.password.types.custom === 'string' &&
                    errors.password.types.custom === 'too_small') ||
                    (Array.isArray(errors.password.types.custom) &&
                      errors.password.types.custom.includes('too_small')))) ||
                !!errors.password?.types?.required ||
                !dirtyFields.password
              }
              alphanumericError={
                (errors.password?.types?.custom &&
                  ((typeof errors.password.types.custom === 'string' &&
                    errors.password.types.custom === 'invalid_type') ||
                    (Array.isArray(errors.password.types.custom) &&
                      errors.password.types.custom.includes(
                        'invalid_type',
                      )))) ||
                !!errors.password?.types?.required ||
                !dirtyFields.password
              }
            />
          </Box>
        </Stack>
      </Stack>
      <Stack
        direction="column"
        justifyContent="center"
        sx={{mt: '16px'}}
        spacing={1}>
        <Stack
          direction="row"
          spacing={2}
          alignContent="center"
          sx={{
            flexWrap: 'wrap',
          }}>
          <Typography sx={{fontWeight: '500'}}>{t('phoneNumber')}</Typography>
          <Typography variant="error">{t('requiredField')}</Typography>
        </Stack>
        <Stack direction="column">
          <TextField
            fullWidth
            sx={{
              backgroundColor: '#fff',
            }}
            error={!!errors.phoneNumber}
            {...register('phoneNumber', {
              required: true,
            })}
          />
          {errors.phoneNumber ? (
            <Typography variant="error">
              {t('enterPhoneNumberCorrectly')}
            </Typography>
          ) : null}
        </Stack>
      </Stack>
      <Stack
        direction="column"
        justifyContent="center"
        sx={{mt: '16px'}}
        spacing={1}>
        <Stack
          direction="row"
          spacing={2}
          alignContent="center"
          sx={{
            flexWrap: 'wrap',
          }}>
          <Typography sx={{fontWeight: '500'}}>
            {t('workplaceAddress')}
          </Typography>
          <Typography variant="error">{t('requiredField')}</Typography>
        </Stack>
        <Stack
          direction="column"
          sx={{
            width: 'calc(652px * 0.7)',
          }}>
          <TextField
            fullWidth
            sx={{
              backgroundColor: '#fff',
            }}
            error={!!errors.workLocation}
            {...register('workLocation', {
              required: true,
            })}
          />
          {errors.workLocation ? (
            <Typography variant="error">
              {t('workplaceAddressRequired')}
            </Typography>
          ) : null}
        </Stack>
      </Stack>
      <Stack
        direction="column"
        justifyContent="center"
        sx={{mt: '16px'}}
        spacing={1}>
        <Stack
          direction="row"
          spacing={2}
          alignContent="center"
          sx={{
            flexWrap: 'wrap',
          }}>
          <Typography sx={{fontWeight: '500'}}>{t('department')}</Typography>
          <Typography variant="error">{t('requiredField')}</Typography>
        </Stack>
        <Stack direction="column">
          <TextField
            fullWidth
            sx={{
              backgroundColor: '#fff',
            }}
            error={!!errors.division}
            {...register('division', {
              required: true,
            })}
          />
          {errors.division ? (
            <Typography variant="error">{t('deparatmentRequired')}</Typography>
          ) : null}
        </Stack>
      </Stack>
      <Stack
        direction="column"
        justifyContent="center"
        sx={{mt: '16px'}}
        spacing={1}>
        <Stack
          direction="row"
          spacing={2}
          alignContent="center"
          sx={{
            flexWrap: 'wrap',
          }}>
          <Typography sx={{fontWeight: '500'}}>{t('jobPosition')}</Typography>
        </Stack>
        <Stack direction="column">
          <TextField
            fullWidth
            sx={{
              backgroundColor: '#fff',
            }}
            {...register('position', {
              required: false,
            })}
          />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{mt: '32px'}}>
        <Checkbox
          checked={termsAcception}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setTermsAcception(event.target.checked);
          }}
        />
        <Trans
          i18nKey="agreement"
          components={{
            link_terms: (
              <Link
                sx={{ml: '8px'}}
                href="https://www.notion.so/cozmic/3f158b6aed084d5880574720c4f5829b?pvs=4"
                underline="hover"
                target="_blank"></Link>
            ),
            link_privacy: (
              <Link
                href="https://www.notion.so/cozmic/de2c8de607bf471285cdf0f1d46627be?pvs=4"
                underline="hover"
                target="_blank"></Link>
            ),
          }}
        />
      </Stack>
      <Stack alignItems="center" justifyContent="center" sx={{mt: '24px'}}>
        <Button
          variant="primary"
          type="submit"
          size="large"
          disabled={
            !!errors.firstName ||
            !!errors.lastName ||
            !!errors.email ||
            !!errors.password ||
            !!errors.phoneNumber ||
            !!errors.workLocation ||
            !!errors.division ||
            !termsAcception ||
            existsUser
          }
          onClick={handleSubmit(onSubmit)}>
          {t('register')}
        </Button>
      </Stack>
    </Box>
  );
};
