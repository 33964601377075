import {SvgIconProps, SvgIcon} from '@material-ui/core';

export const PlusIcon = (props: SvgIconProps) => {
  const StrokeColor = props.color === 'primary' ? '#1976d2' : '#181C32';
  return (
    <SvgIcon
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M10.5 4.66699V16.3337"
        stroke={StrokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66699 10.5H16.3337"
        stroke={StrokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
