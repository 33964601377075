import {Box, Typography, Link} from '@mui/material';
import {ComponentProps, VFC} from 'react';
import {useTranslation} from 'react-i18next';

type Props = {
  isRequired?: boolean;
  title: string;
  titleSize?: string;
  titleWeight?: string;
  value: string | null | undefined;
  valueWeight?: string;
  valueSize?: string;
  valueLeftMargin?: string;
  valueBottomMargin?: string;
  color?: string;
  row?: boolean;
  rowValueLeftMaring?: string;
  linked?: boolean;
} & ComponentProps<typeof Box>;

export const LabelValue: VFC<Props> = ({
  isRequired = false,
  title,
  titleSize = '16px',
  titleWeight = 500,
  value,
  valueWeight = 300,
  valueSize = '16px',
  valueLeftMargin = '12px',
  valueBottomMargin = '12.5px',
  color = '#333333',
  row = false,
  rowValueLeftMaring = '0px',
  linked = false,
  ...props
}) => {
  const {t} = useTranslation();
  return (
    <Box display="flex" flexDirection={row ? 'row' : 'column'} {...props}>
      <Typography
        sx={{
          fontSize: titleSize,
          fontWeight: titleWeight,
          lineHeight: '20.8px',
          color: '#000000',
          display: 'flex',
          flexDirection: 'row',
        }}>
        {title}
        {isRequired && (
          <Typography
            sx={{
              marginLeft: '12px',
              color: '#DA0000',
              fontSize: '14px',
              wordBreak: 'keep-all',
            }}>
            {t('requiredField')}
          </Typography>
        )}
      </Typography>
      {!linked && (
        <Typography
          sx={{
            fontSize: valueSize,
            fontWeight: valueWeight,
            lineHeight: '19.5px',
            color: color,
            overflowWrap: 'break-word',
            marginTop: row ? '0px' : '9px',
            marginBottom: row ? '0px' : valueBottomMargin,
            marginLeft: row ? rowValueLeftMaring : valueLeftMargin,
          }}>
          {value ?? ''}
        </Typography>
      )}
      {linked && (
        <Link
          href={value ?? ''}
          sx={{
            fontSize: valueSize,
            fontWeight: valueWeight,
            lineHeight: '19.5px',
            color: color,
            overflowWrap: 'break-word',
            marginTop: row ? '0px' : '9px',
            marginBottom: row ? '0px' : valueBottomMargin,
            marginLeft: row ? rowValueLeftMaring : valueLeftMargin,
          }}>
          {value ?? ''}
        </Link>
      )}
    </Box>
  );
};
