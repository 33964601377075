import {Select, Stack, Typography, SelectProps, MenuItem} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {isNonNull} from '../utils/isEmpty';

type LabelSelectProps = {
  title: string;
  isRequired?: boolean;
  options: {
    value?: string | number | boolean | undefined;
    label?: string;
  }[];
} & SelectProps;

export const LabelSelect: React.FC<LabelSelectProps> = ({
  title,
  options,
  isRequired,
  ...selectProps
}) => {
  const {t} = useTranslation();

  return (
    <Stack direction="column" gap="9px">
      <Stack direction="row" gap="12px">
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '21px',
          }}>
          {title}
        </Typography>
        {isRequired && (
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 300,
              lineHeight: '18px',
              color: '#DA0000',
            }}>
            {t('requiredField')}
          </Typography>
        )}
      </Stack>
      <Select size="small" style={{height: '45px'}} {...selectProps}>
        {options.filter(isNonNull).map(option => {
          if (option.label) {
            return (
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              <MenuItem key={String(option.value)} value={option.value as any}>
                {option.label}
              </MenuItem>
            );
          } else {
            return null;
          }
        })}
      </Select>
    </Stack>
  );
};
