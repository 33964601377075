import {
  FormControl,
  FormHelperText,
  TextFieldProps,
  TextField as MuiTextField,
} from '@mui/material';
import React, {FormEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';

export const MAX_INPUT_LENGTH = 200;
type CustomProps = {
  maxLength?: number;
} & TextFieldProps;

export const TextField = React.forwardRef<HTMLDivElement, CustomProps>(
  function TextField({error, maxLength = MAX_INPUT_LENGTH, ...props}, ref) {
    const {t} = useTranslation();
    const [overLength, setOverLength] = useState(0);
    const checkLength = (e: FormEvent) => {
      if (!(e.target instanceof HTMLInputElement)) {
        return;
      }
      e.target?.value
        ? setOverLength(e.target.value.length - maxLength)
        : setOverLength(0);
    };

    return (
      <FormControl fullWidth>
        <MuiTextField
          ref={ref}
          error={error || overLength > 0}
          onInput={checkLength}
          {...props}
        />
        {overLength > 0 && (
          <FormHelperText>
            {t('exceedsCharacters', {overLength})}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);
