import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {useState} from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {LabelTitle} from '../components/LabelTitle';
import {useUpdateForwarderUserMutation} from '../queries/forwarderUser';
import {useUpdateInvitedUserMutation} from '../queries/invitedUser';
import {useUpdateShipperUserMutation} from '../queries/shipperUser';
import {User} from '../screens/Manager/Employees';

interface FormInput {
  email: string;
  firstName: string;
  lastName: string;
}

interface EditUserModalProps {
  data: User;
  isForwarder: boolean;
  isInvited: boolean;
}

export const EditUserModal = (props: EditUserModalProps) => {
  const {t} = useTranslation();
  const {
    register,
    handleSubmit,

    formState: {errors},
  } = useForm<FormInput>({
    defaultValues: {
      firstName: props.data.firstName,
      lastName: props.data.lastName,
    },
  });

  const [submitError, setSubmitError] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const {mutate: updateInvitedUserMutation} = useUpdateInvitedUserMutation();
  const {mutate: updateForwarderUserMutation} =
    useUpdateForwarderUserMutation();
  const {mutate: updateShipperUserMutation} = useUpdateShipperUserMutation();

  const onSubmit: SubmitHandler<FormInput> = async data => {
    if (props.isInvited) {
      await updateInvitedUserMutation({
        input: {
          id: props.data.id,
          email: props.data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          companyId: props.data.companyId,
          isForwarder: props.isForwarder,
        },
      });
      setOpen(false);
    } else if (props.isForwarder) {
      await updateForwarderUserMutation({
        input: {
          id: props.data.id,
          email: props.data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: props.data.phoneNumber,
          workLocation: props.data.workLocation,
          division: props.data.division,
          position: props.data.position,
        },
      });
      setOpen(false);
    } else if (!props.isForwarder) {
      await updateShipperUserMutation({
        input: {
          id: props.data.id,
          email: props.data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: props.data.phoneNumber,
          workLocation: props.data.workLocation,
          division: props.data.division,
          position: props.data.position,
          area: props.data.area,
          role: props.data.role,
        },
      });
      setOpen(false);
    } else {
      setSubmitError(true);
    }
  };

  return (
    <>
      <IconButton
        size="large"
        onClick={() => {
          setOpen(true);
        }}>
        <EditIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Stack
          alignItems={'center'}
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '544px',
            bgcolor: 'background.paper',
            borderRadius: '12px',
            p: '52px',
          }}>
          <Typography
            textAlign="center"
            sx={{
              fontStyle: 'normal',
              fontSize: '20px',
              LineHeight: '26px',
            }}>
            {t('edit')}
          </Typography>
          {submitError ? (
            <Typography
              color="warning.main"
              fontSize="16px"
              mb="24px"
              textAlign="center">
              {t('wrongInformation')}
              <br />
              {t('checkAgain')}
            </Typography>
          ) : null}
          <Stack flexDirection="column" spacing={1} sx={{width: '100%'}}>
            <LabelTitle isRequired sx={{marginTop: '16px'}}>
              {t('name')}
            </LabelTitle>
            <Stack flexDirection="row">
              <Stack
                flexDirection="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                  position: 'relative',
                  width: '100%',
                }}>
                <Stack sx={{width: '100%'}}>
                  <TextField
                    fullWidth
                    sx={{width: '100%'}}
                    error={!!errors.lastName}
                    {...register('lastName', {required: true})}
                    helperText={errors.lastName ? t('enterLastName') : ''}
                  />
                </Stack>
              </Stack>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                ml={1}
                sx={{
                  position: 'relative',
                  width: '100%',
                }}>
                <Stack sx={{width: '100%'}}>
                  <TextField
                    fullWidth
                    sx={{width: '100%'}}
                    error={!!errors.firstName}
                    {...register('firstName', {required: true})}
                    helperText={errors.firstName ? t('enterFirstName') : ''}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={3} mt={3}>
            <Button variant="gray" onClick={handleClose} size="small">
              {t('cancel')}
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit(onSubmit)}
              size="small">
              {t('update')}
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};
