import {Stack, Typography, Rating, RatingProps} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

type LabelRatingProps = {
  title: string;
  isRequired?: boolean;
} & RatingProps;

export const LabelRating: React.FC<LabelRatingProps> = ({
  title,
  isRequired,
  ...ratingProps
}) => {
  const {t} = useTranslation();
  return (
    <Stack direction="column" gap="9px">
      <Stack direction="row" gap="12px">
        <Typography variant="h5">{title}</Typography>
        {isRequired && (
          <Typography variant="body2" color="error">
            {t('requiredField')}
          </Typography>
        )}
      </Stack>
      <Typography variant="body2">{t('reviewRatingExplanation')}</Typography>
      <Stack direction="row" gap="4px" alignItems="center">
        <Rating {...ratingProps}></Rating>
      </Stack>
    </Stack>
  );
};
