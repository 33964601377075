import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {CompanyProfile} from './CompanyProfile';
import {useForwarderCompany} from '../../queries/forwarderCompany';
import {isNonNull} from '../../utils/isEmpty';

export const ForwarderCompanyProfileScreen = () => {
  const location = useLocation();
  const state = location.state as {
    companyId: string;
  };
  const {data: forwarderCompany} = useForwarderCompany(state.companyId);
  const [averageRate, setAverateRate] = useState(0);

  useEffect(() => {
    const items = forwarderCompany?.receivedReviews?.items ?? [];
    let totalRate = 0;
    items.forEach(item => {
      if (item) {
        totalRate += item.rate;
      }
    });
    const averageRate = items.length > 0 ? totalRate / items.length : 0;
    setAverateRate(averageRate);
  }, [forwarderCompany]);

  if (!forwarderCompany) return <></>;
  return (
    <>
      <CompanyProfile
        name={forwarderCompany.name}
        averageRate={averageRate}
        firstName={forwarderCompany.firstName}
        lastName={forwarderCompany.lastName}
        location={forwarderCompany.location}
        establishment={forwarderCompany.establishment}
        paidInCaptial={forwarderCompany.paidInCaptial}
        corporateNumber={forwarderCompany.corporateNumber}
        listed={forwarderCompany.listed}
        numberOfEmployees={forwarderCompany.numberOfEmployees}
        companyURL={forwarderCompany.companyURL}
        reviews={
          forwarderCompany.receivedReviews?.items
            .filter(isNonNull)
            .map(review => {
              return {
                rate: review.rate ?? 0,
                title: review.title ?? '',
                description: review.description ?? '',
                createdAt: review.createdAt ?? '',
              };
            }) ?? []
        }
      />
    </>
  );
};
