export const isEmpty = (
  value: string | boolean | undefined | null,
): boolean => {
  return value === undefined || value === null || value === '';
};

export const isObjectAnyPropertyEmpty = (
  obj: Record<string, string | boolean | undefined | null> | undefined | null,
): boolean => {
  if (!obj) {
    return true;
  }
  for (const key in obj) {
    if (isEmpty(obj[key])) {
      return true;
    }
  }

  return false;
};

export const isObjectAllPropertyEmpty = (
  obj: Record<string, string | boolean | undefined | null> | undefined | null,
): boolean => {
  for (const key in obj) {
    if (!isEmpty(obj[key])) {
      return false;
    }
  }
  return true;
};

export const isNonNull = <T>(item: T): item is NonNullable<typeof item> => {
  return item != null;
};
