import {Grid, Stack, Typography} from '@mui/material';
import {useState, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Reservation, Status} from '../../API';
import {ReservationCreateHawbDialog} from '../../components/ReservationCreateHawbDialog';
import {ReservationDetail} from '../../components/ReservationDetail';
import {ReservationFilter} from '../../components/ReservationFilter';
import {ReservationFilterType} from '../../components/ReservationFilter';
import {ReservationSummary} from '../../components/ReservationSummary';
import {useOAuthUserState} from '../../providers/AuthProvider';
import {useForwarderUser} from '../../queries/forwarderUser';
import {
  useReservations,
  useUpdateReservationMutation,
} from '../../queries/reservation';
import {isNonNull} from '../../utils/isEmpty';

export const ReservationListScreen = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const userState = useOAuthUserState();
  const sub = userState.user?.attributes.sub ?? '';
  const {data: forwarder} = useForwarderUser(sub);

  const [departure, setDeparture] = useState('');
  const [status, setStatus] = useState<Status | undefined>(undefined);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [hawb, setHawb] = useState('');
  const [selectedReservationId, setSelectedReservationId] = useState('');
  const [sortedReservations, setSortedReservations] = useState<
    Reservation[] | null
  >(null);
  const {data: reservations} = useReservations(
    forwarder?.companyId ?? '',
    departure,
    status,
  );

  const {mutateAsync: updateReservationMutation} =
    useUpdateReservationMutation();

  const filterReservation = useCallback((filter: ReservationFilterType) => {
    setDeparture(filter.departure);
    setStatus(filter.status);
  }, []);
  const openConfirm = useCallback((id: string, open: boolean) => {
    setHawb('');
    setIsConfirmOpen(open);
    setSelectedReservationId(id);
  }, []);
  const openDetail = useCallback((id: string, open: boolean) => {
    setIsDetailOpen(open);
    setSelectedReservationId(id);
  }, []);
  const confirmReservation = useCallback(async () => {
    try {
      await updateReservationMutation({
        input: {
          id: selectedReservationId,
          hawb,
          status: Status.APPROVED,
        },
      });
      const updatedReservationIndex = reservations?.items.findIndex(
        reservation => reservation?.id === selectedReservationId,
      );
      if (updatedReservationIndex != null) {
        const reservation = reservations?.items[updatedReservationIndex];
        if (reservation) {
          reservation.status = Status.APPROVED;
        }
      }
      setHawb('');
      setIsConfirmOpen(false);
      setIsDetailOpen(false);
      setHawb('');
    } catch (error) {
      navigate('error');
    }
  }, [
    hawb,
    navigate,
    reservations?.items,
    selectedReservationId,
    updateReservationMutation,
  ]);
  const rejectReservation = useCallback(
    (id: string) => {
      updateReservationMutation({
        input: {
          id,
          hawb: '',
          status: Status.REJECTED,
        },
      });
      const rejectedReservationIndex = reservations?.items.findIndex(
        reservation => reservation?.id === id,
      );
      if (rejectedReservationIndex != null) {
        const reservation = reservations?.items[rejectedReservationIndex];
        if (reservation) {
          reservation.status = Status.REJECTED;
        }
      }
      setIsDetailOpen(false);
    },
    [reservations?.items, updateReservationMutation],
  );

  useEffect(() => {
    if (reservations?.items) {
      const sortedItems = [...reservations.items].sort((a, b) => {
        return (b?.shippingInstruction?.flightDate ?? '').localeCompare(
          a?.shippingInstruction?.flightDate ?? '',
        );
      });
      setSortedReservations(sortedItems as Reservation[]);
    } else {
      setSortedReservations(null);
    }
  }, [reservations]);

  return sortedReservations ? (
    <>
      <Grid container>
        <Grid item xs={4}>
          <ReservationFilter
            filter={{departure, status}}
            filterReservation={filterReservation}
          />
        </Grid>
        <Grid item xs={8}>
          <Stack ml={7} spacing={3}>
            {sortedReservations?.filter(isNonNull).map(reservation => {
              return (
                <ReservationSummary
                  id={reservation.id}
                  setIsConfirmOpen={openConfirm}
                  setIsDetailOpen={openDetail}
                  rejectReservation={rejectReservation}
                />
              );
            })}
            {sortedReservations?.length === 0 && (
              <Typography variant="h3">{t('noFilteredReservation')}</Typography>
            )}
          </Stack>
        </Grid>
      </Grid>
      <ReservationCreateHawbDialog
        isConfirmOpen={isConfirmOpen}
        hawb={hawb}
        setIsConfirmOpen={setIsConfirmOpen}
        setHawb={setHawb}
        confirmReservation={confirmReservation}
      />
      <ReservationDetail
        isDetailOpen={isDetailOpen}
        reservationId={selectedReservationId}
        setIsConfirmOpen={openConfirm}
        rejectReservation={rejectReservation}
        setIsDetailOpen={openDetail}
      />
    </>
  ) : null;
};
