import 'react-phone-input-2/lib/style.css';
import {zodResolver} from '@hookform/resolvers/zod';
import {
  Box,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  Chip,
  Button,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {useEffect, useState} from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';
import {ShipperRole} from '../../API';
import {EditIcon} from '../../images/EditIcon';
import Inner from '../../layouts/Inner';
import {useOAuthUserState} from '../../providers/AuthProvider';
import {useShipperUser} from '../../queries/shipperUser';
import {useUpdateShipperUserMutation} from '../../queries/shipperUser';

export const formInput = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  phoneNumber: z.string().min(1),
  workLocation: z.string().min(1),
  division: z.string().min(1),
  position: z.string().nullable(),
});

export type FormInput = z.infer<typeof formInput>;

export const ShipperProfileScreen = () => {
  const {t} = useTranslation();
  const userState = useOAuthUserState();
  const sub = userState.user?.attributes.sub ?? '';

  const {data: shipper} = useShipperUser(sub);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<FormInput>({
    mode: 'all',
    criteriaMode: 'all',
    shouldFocusError: false,
    resolver: zodResolver(formInput),
  });

  const [email, setEmail] = useState('');
  const [area, setArea] = useState('');
  const [role, setRole] = useState<ShipperRole | string>('');

  const [originalArea, setOriginalArea] = useState('');
  const [originalRole, setOriginalRole] = useState<ShipperRole | string>('');

  const {mutate: updateShipperUserMutation} = useUpdateShipperUserMutation();

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const {getNames} = require('country-list');
  const countries: string[] = getNames();

  useEffect(() => {
    if (shipper) {
      setValue('firstName', shipper.firstName);
      setValue('lastName', shipper.lastName);
      setValue('phoneNumber', shipper.phoneNumber);
      setValue('workLocation', shipper.workLocation);
      setEmail(shipper.email);
      setValue('division', shipper.division);
      setValue('position', shipper.position ?? null);
      setArea(shipper.area ?? '');
      setRole(shipper.role ?? '');
    }
  }, [shipper, setValue]);

  const onSubmit: SubmitHandler<FormInput> = async data => {
    if (shipper) {
      await updateShipperUserMutation({
        input: {
          id: shipper.id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: shipper.email,
          phoneNumber: data.phoneNumber,
          workLocation: data.workLocation,
          division: data.division,
          position: data.position,
          area,
          role: role === '' ? null : (role as ShipperRole),
        },
      });
    }
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
    // 編集前のデータに戻す
    setValue('firstName', originalData['firstName']);
    setValue('lastName', originalData['lastName']);
    setValue('phoneNumber', originalData['phoneNumber']);
    setValue('workLocation', originalData['workLocation']);
    setValue('division', originalData['division']);
    setValue('position', originalData['position']);
    setArea(originalArea);
    setRole(originalRole);
  };

  const [editing, setEditing] = useState(false);
  const [originalData, setOriginalData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    workLocation: '',
    division: '',
    position: '',
  });

  useEffect(() => {
    document.title = `${t('profile')} | MAST`;
  }, [t]);

  return (
    <>
      {editing ? (
        /*ユーザー情報編集画面*/
        <Stack
          alignItems="center"
          flexDirection="column"
          sx={{
            paddingBottom: '120px',
          }}>
          <Stack justifyContent="start" width="730px">
            <Typography mb={3} variant="h2">
              {t('profile')}
            </Typography>
            <Inner>
              <Stack spacing={3}>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h5">{t('name')}</Typography>
                    <Typography variant="error" sx={{marginLeft: '12px'}}>
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <Stack mt={1} flexDirection="row">
                    <Stack flexDirection="column">
                      <TextField
                        sx={{width: '222px'}}
                        placeholder={t('enterLastName')}
                        error={!!errors.lastName}
                        {...register('lastName', {required: true})}
                      />
                      {errors.lastName && (
                        <Typography variant="error">
                          {t('enterLastName')}
                        </Typography>
                      )}
                    </Stack>
                    <Stack ml={1} flexDirection="column">
                      <TextField
                        sx={{width: '222px'}}
                        placeholder={t('enterFirstName')}
                        error={!!errors.firstName}
                        {...register('firstName', {required: true})}
                      />
                      {errors.firstName && (
                        <Typography variant="error">
                          {t('enterFirstName')}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h5">{t('phoneNumber')}</Typography>
                    <Typography variant="error" sx={{marginLeft: '12px'}}>
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <TextField
                    fullWidth
                    sx={{maxWidth: '452px'}}
                    placeholder=""
                    error={!!errors.phoneNumber}
                    {...register('phoneNumber', {required: true})}
                  />
                  {errors.phoneNumber && (
                    <Typography variant="error">
                      {t('enterPhoneNumber')}
                    </Typography>
                  )}
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h5">
                      {t('workplaceAddress')}
                    </Typography>
                    <Typography variant="error" sx={{marginLeft: '12px'}}>
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <TextField
                    sx={{width: '452px'}}
                    placeholder={t('workplaceAddress')}
                    error={!!errors.workLocation}
                    {...register('workLocation', {required: true})}
                  />
                  {errors.workLocation && (
                    <Typography variant="error">
                      {t('enterWorkplaceAddress')}
                    </Typography>
                  )}
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h5">{t('department')}</Typography>
                    <Typography variant="error" sx={{marginLeft: '12px'}}>
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <TextField
                    sx={{width: '452px'}}
                    placeholder={t('department')}
                    error={!!errors.division}
                    {...register('division', {required: true})}
                  />
                  {errors.division && (
                    <Typography variant="error">
                      {t('enterDepartment')}
                    </Typography>
                  )}
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Typography variant="h5">{t('jobPosition')}</Typography>
                  <TextField
                    placeholder={t('jobPosition')}
                    {...register('position')}
                    sx={{width: '452px'}}
                  />
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h5">{t('mainAreas')}</Typography>
                    <Typography variant="error" sx={{marginLeft: '12px'}}>
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <Autocomplete
                    value={area}
                    onChange={(_, newValue) => {
                      setArea(newValue ? newValue : 'Japan');
                    }}
                    sx={{width: '452px'}}
                    options={countries}
                    getOptionLabel={country => country}
                    renderInput={params => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          sx: {
                            paddingY: '2px !important',
                            paddingLeft: '9px !important',
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h5">
                      {t('mainResponsibilities')}
                    </Typography>
                    <Typography variant="error" sx={{marginLeft: '12px'}}>
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <Select
                    sx={{width: '452px'}}
                    value={role}
                    inputProps={{
                      sx: {
                        paddingY: '11px !important',
                        paddingLeft: '15px !important',
                      },
                    }}
                    onChange={e => {
                      setRole(e.target.value as ShipperRole);
                    }}>
                    <MenuItem value={ShipperRole.AIR_EXPORT}>
                      {t('airExport')}
                    </MenuItem>
                    <MenuItem value={ShipperRole.AIR_IMPORT}>
                      {t('airImport')}
                    </MenuItem>
                    <MenuItem value={ShipperRole.SEA_EXPORT}>
                      {t('seaExport')}
                    </MenuItem>
                    <MenuItem value={ShipperRole.SEA_IMPORT}>
                      {t('seaImport')}
                    </MenuItem>
                    <MenuItem value={ShipperRole.WAREHOUSE}>
                      {t('warehouse')}
                    </MenuItem>
                    <MenuItem value={ShipperRole.TRUCK}>{t('truck')}</MenuItem>
                  </Select>
                </Stack>
              </Stack>
              <Box
                sx={{
                  borderRadius: '24px',
                  marginTop: '24px',
                }}>
                <Button variant="gray" size="small" onClick={handleCancel}>
                  {t('cancel')}
                </Button>
                <Button
                  sx={{marginLeft: '24px'}}
                  variant="primary"
                  type="submit"
                  size="small"
                  onClick={handleSubmit(onSubmit)}>
                  {t('update')}
                </Button>
              </Box>
            </Inner>
          </Stack>
        </Stack>
      ) : (
        /*ユーザー情報確認画面*/
        <Stack
          alignItems="center"
          flexDirection="column"
          sx={{
            paddingBottom: '120px',
          }}>
          <Stack justifyContent="start" width="730px">
            <Stack
              flexDirection="row"
              mb={3}
              justifyContent="space-between"
              alignItems="flex-end">
              <Typography variant="h2">{t('profile')}</Typography>
              <Chip
                color="primary"
                icon={<EditIcon color="primary" style={{fill: 'none'}} />}
                label={t('edit')}
                variant="outlined"
                onClick={() => {
                  setEditing(true);
                  setOriginalData({
                    ...getValues(),
                    position: getValues().position || '',
                  });
                  setOriginalArea(area);
                  setOriginalRole(role);
                }}
                sx={{
                  width: '127px',
                  backgroundColor: '#FFFFFF',
                  '& .MuiChip-label': {
                    paddingLeft: '8px',
                    fontSize: '16px',
                    fontWeight: '500',
                    lineHeight: '20.8px',
                  },
                  '&.MuiChip-root': {
                    height: '45px',
                    borderRadius: '30px',
                  },
                }}
              />
            </Stack>
            <Inner>
              <Stack spacing={3}>
                <Stack spacing={1}>
                  <Typography variant="h6">{t('name')}</Typography>
                  <Typography>
                    {`${getValues('lastName')} ${getValues('firstName')}`}
                  </Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="h6">{t('email')}</Typography>
                  <Typography>{email}</Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="h6">{t('phoneNumber')}</Typography>
                  <Typography>{getValues('phoneNumber')}</Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="h6">{t('workplaceAddress')}</Typography>
                  <Typography>{getValues('workLocation')}</Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="h6">{t('department')}</Typography>
                  <Typography>{getValues('division')}</Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="h6">{t('jobPosition')}</Typography>
                  <Typography>{getValues('position')}</Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="h6">{t('mainAreas')}</Typography>
                  <Typography>{area}</Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="h6">
                    {t('mainResponsibilities')}
                  </Typography>
                  <Typography>
                    {role === ShipperRole.AIR_EXPORT
                      ? t('airExport')
                      : role === ShipperRole.AIR_IMPORT
                      ? t('airImport')
                      : role === ShipperRole.SEA_EXPORT
                      ? t('seaExport')
                      : role === ShipperRole.SEA_IMPORT
                      ? t('seaImport')
                      : role === ShipperRole.WAREHOUSE
                      ? t('warehouse')
                      : role === ShipperRole.TRUCK
                      ? t('truck')
                      : ''}
                  </Typography>
                </Stack>
              </Stack>
            </Inner>
          </Stack>
        </Stack>
      )}
    </>
  );
};
