export const categoryOptions = [
  {value: '1', label: '総合建設'},
  {value: '2', label: 'インフラ建設'},
  {value: '3', label: 'プラント・空調衛生工事'},
  {value: '4', label: '建設関連サービス'},
  {value: '5', label: '不動産開発・流通'},
  {value: '6', label: '不動産サービス'},
  {value: '7', label: '石油・ガス'},
  {value: '8', label: '再生可能エネルギー源'},
  {value: '9', label: '石炭'},
  {value: '10', label: '金属採掘'},
  {value: '11', label: '非金属採掘'},
  {value: '12', label: '鉄鋼'},
  {value: '13', label: '非鉄金属'},
  {value: '14', label: '化学'},
  {value: '15', label: '繊維・織物・皮革'},
  {value: '16', label: '窯業・紙'},
  {value: '17', label: '容器・包装'},
  {value: '18', label: 'その他素材加工品'},
  {value: '19', label: '住設・建設資材'},
  {value: '20', label: '半導体関連製造'},
  {value: '21', label: '電子部品・デバイス製造'},
  {value: '22', label: '重工業機械製造'},
  {value: '23', label: '生産用機械製造'},
  {value: '24', label: '業務用機械製造'},
  {value: '25', label: '民生用電子機器製造'},
  {value: '26', label: '情報通信機器製造'},
  {value: '27', label: '機械・電気サービス'},
  {value: '28', label: '自動車部品製造'},
  {value: '29', label: '船舶・航空部品製造'},
  {value: '30', label: '輸送用機械製造'},
  {value: '31', label: '農業'},
  {value: '32', label: '畜産・水産業'},
  {value: '33', label: '食品加工'},
  {value: '34', label: '飲料・たばこ製造'},
  {value: '35', label: 'パーソナルケア'},
  {value: '36', label: '衣服・装飾品製造'},
  {value: '37', label: '家具・インテリア・雑貨'},
  {value: '38', label: 'その他生活用品製造'},
  {value: '39', label: 'バイオ・医薬品製造'},
  {value: '40', label: '医療・福祉関連品製造'},
  {value: '41', label: '医療・福祉関連サービス'},
  {value: '42', label: '総合卸'},
  {value: '43', label: '素材専門卸'},
  {value: '44', label: '電気・機械専門卸'},
  {value: '45', label: '食品専門卸'},
  {value: '46', label: '医療関連専門卸'},
  {value: '47', label: '生活用品専門卸'},
  {value: '48', label: '総合・食品小売'},
  {value: '49', label: '医薬品小売'},
  {value: '50', label: '生活用品小売'},
  {value: '51', label: '通信・訪問販売'},
  {value: '52', label: '本・映像・ゲーム小売'},
  {value: '53', label: '家電・OA小売'},
  {value: '54', label: '自動車関連小売'},
  {value: '55', label: 'Eコマース'},
  {value: '56', label: '飲食店'},
  {value: '57', label: '弁当・宅配'},
  {value: '58', label: '銀行・ノンバンク'},
  {value: '59', label: '証券'},
  {value: '60', label: '保険'},
  {value: '61', label: '資産運用'},
  {value: '62', label: '金融関連サービス'},
  {value: '63', label: '陸上輸送'},
  {value: '64', label: '水運'},
  {value: '65', label: '空運'},
  {value: '66', label: '通信インフラ'},
  {value: '67', label: '放送・出版'},
  {value: '68', label: '広告・イベント'},
  {value: '69', label: 'コンテンツ制作・配信'},
  {value: '70', label: 'インターネットメディア'},
  {value: '71', label: 'ITインフラサービス'},
  {value: '72', label: 'システム開発'},
  {value: '73', label: 'ソフトウェアサービス'},
  {value: '74', label: '教育・学習支援'},
  {value: '75', label: '旅行・宿泊'},
  {value: '76', label: 'レジャー'},
  {value: '77', label: '生活関連サービス'},
  {value: '78', label: '人材関連サービス'},
  {value: '79', label: 'リース・レンタル'},
  {value: '80', label: '印刷サービス'},
  {value: '81', label: '廃棄物・環境関連'},
  {value: '82', label: '業務支援サービス'},
  {value: '83', label: '公共インフラ'},
  {value: '84', label: '公的機関'},
];

export function getLabelByValue(valueToFind: string | undefined) {
  const foundOption = categoryOptions.find(
    option => option.value === valueToFind,
  );
  return foundOption ? foundOption.label : null;
}
