import {Stack, ToggleButton, ToggleButtonGroup} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TransportType} from '../API';

interface SelectExportChargesProps {
  onValueChange: (newValue: string) => void;
  defaultCharge?: string;
}

export const SelectExportCharges: React.FC<SelectExportChargesProps> = ({
  onValueChange,
  defaultCharge,
}) => {
  const {t} = useTranslation();
  const [charge, setCharge] = useState<string>(
    defaultCharge || TransportType.AIR_IMPORT,
  );
  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string,
  ) => {
    setCharge(newValue);
    onValueChange(newValue);
  };
  return (
    <Stack direction="column" justifyContent="center" width="100%">
      <ToggleButtonGroup
        value={charge}
        exclusive
        aria-label="text alignment"
        onChange={handleAlignment}>
        <ToggleButton
          value={TransportType.AIR_IMPORT}
          sx={{
            fontSize: '13.5px',
            '&.Mui-selected': {
              backgroundColor: '#1976d2',
              color: 'white',
            },
          }}>
          {t('airfreight')}
        </ToggleButton>
        <ToggleButton
          value={TransportType.AIR_EXPORT}
          sx={{
            fontSize: '13.5px',
            '&.Mui-selected': {
              backgroundColor: '#1976d2',
              color: 'white',
            },
          }}>
          {t('airfreightExportCharge')}
        </ToggleButton>
        <ToggleButton
          value={TransportType.TRILATERAL}
          sx={{
            fontSize: '13.5px',
            '&.Mui-selected': {
              backgroundColor: '#1976d2',
              color: 'white',
            },
          }}>
          {t('airfreightExportAndImportCharge')}
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};
