import {Stack, Typography, Box, Divider} from '@mui/material';
import {PlaneIcon} from '../images/PlaneIcon';
import {getAirportNameByCode} from '../utils/getAirportNameByCode';

type DepartureArrivalAirportsProps = {
  departure?: string;
  arrival?: string;
};

export const DepartureArrivalAirports = (
  props: DepartureArrivalAirportsProps,
) => {
  return (
    <Stack gap={1}>
      <Stack>
        <Typography variant="h5">
          {getAirportNameByCode(props.departure ?? '')}
        </Typography>
        <Typography variant="h6">{props.departure}</Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Divider
            style={{
              width: '24px',
              border: 'dashed 1px #000000',
            }}
          />
        </Box>
        <PlaneIcon
          style={{
            transform: 'rotate(90deg)',
            width: '18px',
            height: '19px',
          }}
        />
        <Box display="flex" alignItems="center" justifyContent="center">
          <Divider
            style={{
              width: '24px',
              border: 'dashed 1px #000000',
            }}
          />
        </Box>
      </Stack>
      <Stack>
        <Typography variant="h5">
          {getAirportNameByCode(props.arrival ?? '')}
        </Typography>
        <Typography variant="h6">{props.arrival}</Typography>
      </Stack>
    </Stack>
  );
};
