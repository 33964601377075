import {makeStyles} from '@material-ui/core/styles';
import {Stack, Typography, TextField, TextFieldProps} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles({
  input: {
    paddingTop: '11px',
    paddingBottom: '11px',
  },
});

type LabelTextFieldProps = {
  title: string;
  isRequired?: boolean;
  unit?: string;
} & TextFieldProps;

export const LabelTextField: React.FC<LabelTextFieldProps> = ({
  title,
  isRequired,
  unit,
  ...textFieldProps
}) => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <Stack direction="column" gap="9px">
      <Stack direction="row" gap="12px">
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '21px',
            color: '#181C32',
          }}>
          {title}
        </Typography>
        {isRequired && (
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 300,
              lineHeight: '18px',
              color: '#DA0000',
            }}>
            {t('requiredField')}
          </Typography>
        )}
      </Stack>
      <Stack direction="row" gap="4px" alignItems="center">
        <TextField
          size="small"
          id="input-textfield"
          variant="outlined"
          fullWidth
          InputProps={{
            classes: {
              input: classes.input,
            },
          }}
          {...textFieldProps}
        />
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '21px',
            color: '#181C32',
          }}>
          {unit}
        </Typography>
      </Stack>
    </Stack>
  );
};
