import {GraphQLResult} from '@aws-amplify/api-graphql';
import AddIcon from '@mui/icons-material/Add';
import {Button, Modal, Stack, TextField, Typography} from '@mui/material';
import * as Sentry from '@sentry/react';
import {API, graphqlOperation} from 'aws-amplify';
import {useState} from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {UseQueryResult} from 'react-query';
import LoadingButton from './LoadingButton';
import {SendInvitationQuery} from '../API';
import {LabelTitle} from '../components/LabelTitle';
import {sendInvitation} from '../graphql/queries';
import {useOAuthUserState} from '../providers/AuthProvider';
import {useCreateInvitedUserMutation} from '../queries/invitedUser';

interface FormInput {
  email: string;
  firstName: string;
  lastName: string;
}

interface InviteUserModalProps {
  userMutation: (
    userId?: string | undefined,
  ) => UseQueryResult<User | null | undefined, unknown>;
  companyMutation: (
    companyId?: string | undefined,
  ) => UseQueryResult<Company | null | undefined, unknown>;
}

interface User {
  company?: Company | null | undefined;
}

interface Company {
  id: string;
}

export const InviteUserModal = (props: InviteUserModalProps) => {
  const {t} = useTranslation();
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormInput>();

  const [submitError, setSubmitError] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const userState = useOAuthUserState();
  const sub = userState.user?.attributes.sub ?? '';
  const isForwarder = userState.user?.isForwarders ?? false;

  const {data: user} = props.userMutation(sub);
  const {data: company} = props.companyMutation(user?.company?.id);
  const {mutate: createInvitedUserMutation} = useCreateInvitedUserMutation();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<FormInput> = async data => {
    try {
      setIsLoading(true);
      const companyId = company?.id ?? '';
      const result = (await API.graphql(
        graphqlOperation(sendInvitation, {
          event: {
            to: data.email,
          },
        }),
      )) as GraphQLResult<SendInvitationQuery>;
      if (result.data?.sendInvitation?.statusCode === 200) {
        createInvitedUserMutation({
          input: {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            companyId: companyId,
            isForwarder: isForwarder,
          },
        });
        setOpen(false);
      } else {
        setSubmitError(true);
      }
    } catch (err) {
      Sentry.captureException(err);
      setSubmitError(true);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          setOpen(true);
        }}
        startIcon={<AddIcon />}>
        {t('newRegistration')}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Stack
          alignItems={'center'}
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '544px',
            bgcolor: 'background.paper',
            borderRadius: '12px',
            p: '52px',
          }}>
          <Typography
            textAlign="center"
            sx={{
              fontStyle: 'normal',
              fontSize: '20px',
              LineHeight: '26px',
            }}>
            {t('newRegistration')}
          </Typography>
          {submitError ? (
            <Typography
              color="warning.main"
              fontSize="16px"
              mb="24px"
              textAlign="center">
              {t('wrongInformation')}
              <br />
              {t('checkAgain')}
            </Typography>
          ) : null}
          <Stack flexDirection="column" spacing={1} sx={{width: '100%'}}>
            <LabelTitle isRequired sx={{marginTop: '16px'}}>
              {t('name')}
            </LabelTitle>
            <Stack flexDirection="row">
              <Stack
                flexDirection="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                  position: 'relative',
                  width: '100%',
                }}>
                <Stack sx={{width: '100%'}}>
                  <TextField
                    fullWidth
                    sx={{width: '100%'}}
                    error={!!errors.lastName}
                    {...register('lastName', {required: true})}
                    helperText={errors.lastName ? t('enterLastName') : ''}
                  />
                </Stack>
              </Stack>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                ml={1}
                sx={{
                  position: 'relative',
                  width: '100%',
                }}>
                <Stack sx={{width: '100%'}}>
                  <TextField
                    fullWidth
                    sx={{width: '100%'}}
                    error={!!errors.firstName}
                    {...register('firstName', {required: true})}
                    helperText={errors.firstName ? t('enterFirstName') : ''}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack flexDirection="column" spacing={1} sx={{width: '100%'}}>
            <LabelTitle isRequired sx={{marginTop: '16px'}}>
              {t('email')}
            </LabelTitle>
            <Stack>
              <TextField
                fullWidth
                sx={{width: '100%'}}
                error={!!errors.email}
                {...register('email', {required: true})}
                helperText={errors.email ? t('enterEmail') : ''}
              />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={3} mt={3}>
            <Button variant="gray" onClick={handleClose} size="small">
              {t('cancel')}
            </Button>
            <LoadingButton
              size="small"
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}>
              {t('invite')}
            </LoadingButton>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};
