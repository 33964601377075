import { Auth, API } from 'aws-amplify';

export const addUserToGroup = async (username: string, groupname: string) => {
  const apiName = 'AdminQueries';
  const path = '/addUserToGroup';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
    body: {
      "username": username,
      "groupname": groupname,
    },
  };
  return await API.post(apiName, path, params);
};

export const listGroupsForUser = async (username: string) => {
  const apiName = 'AdminQueries';
  const path = '/listGroupsForUser';
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
    queryStringParameters: {
      "username": username,
    },
  };
  const { Groups } = await API.get(apiName, path, params);
  return  Groups;
};
