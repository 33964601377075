import 'react-phone-input-2/lib/style.css';
import {zodResolver} from '@hookform/resolvers/zod';
import {Stack, Typography, Button} from '@mui/material';
import React, {useCallback, useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {z} from 'zod';
import {
  CargoType,
  PaymentCondition,
  DocumentInput,
  ContactInput,
} from '../../API';
import {ConsigneeContact} from '../../components/ConsigneeContact';
import {CreateSIOptions, OptionsInput} from '../../components/CreateSIOptions';
import {DocumentForm} from '../../components/Document';
import {LabelRadio} from '../../components/LabelRadio';
import {LabelTextField} from '../../components/LabelTextField';
import Inner from '../../layouts/Inner';
import {useEstimate} from '../../queries/estimate';
import {getLocalizedCargoType} from '../../utils/getLocalizedCargoType';
import {getLocalizedPaymentCondition} from '../../utils/getLocalizedPaymentCondition';
import {phoneRegex} from '../../utils/phoneRegex';

const CargoTypeValidate = z.enum(['NORMAL', 'LITHIUM', 'CLASS1_TO_9']);
const paymentOptions = [
  PaymentCondition.CREDIT_REGISTERED_WITH_HISTORY,
  PaymentCondition.EOM_NEXT_MONTH_MAX_60,
  PaymentCondition.MID_MONTH_EOM_MAX_30,
  PaymentCondition.CASH_ON_DELIVERY,
];

const cargoTypeOptions = [
  CargoType.NORMAL,
  CargoType.LITHIUM,
  CargoType.CLASS1_TO_9,
];
const PaymentConditionValidate = z.enum([
  'CREDIT_REGISTERED_WITH_HISTORY',
  'EOM_NEXT_MONTH_MAX_60',
  'MID_MONTH_EOM_MAX_30',
  'CASH_ON_DELIVERY',
]);

enum PaymentConditionEnum {
  CREDIT_REGISTERED_WITH_HISTORY = 'CREDIT_REGISTERED_WITH_HISTORY',
  EOM_NEXT_MONTH_MAX_60 = 'EOM_NEXT_MONTH_MAX_60',
  MID_MONTH_EOM_MAX_30 = 'MID_MONTH_EOM_MAX_30',
  CASH_ON_DELIVERY = 'CASH_ON_DELIVERY',
}

const baseSchema = z.object({
  itemName: z.string().min(1),
  cargoType: CargoTypeValidate,
  isSds: z.boolean(),
  document: z.any(),
  consigneeContact: z.object({
    companyName: z.string().min(1),
    name: z.string().min(1),
    email: z.string().email().min(1),
    phoneNumber: z.string().regex(phoneRegex, '電話番号を入力してください'),
  }),
  options: z.any(),
});

const regulatedSchema = z
  .object({
    regulated: z.string().min(1),
    regulatedDetail: z.string().optional(),
  })
  .refine(
    ({regulated, regulatedDetail}) =>
      regulated !== 'other' || (regulated === 'other' && regulatedDetail),
    {
      path: ['regulatedDetail'],
      message: '入力してください',
    },
  );

const paymentConditionSchema = z
  .object({
    paymentCondition: PaymentConditionValidate,
    paymentConditionDetail: z.string().optional(),
  })
  .refine(
    ({paymentCondition, paymentConditionDetail}) =>
      paymentCondition !== 'CREDIT_REGISTERED_WITH_HISTORY' ||
      (paymentCondition === 'CREDIT_REGISTERED_WITH_HISTORY' &&
        paymentConditionDetail),
    {
      path: ['paymentConditionDetail'],
      message: '入力してください',
    },
  );

const packingStyleSchema = z
  .object({
    packingStyle: z.string().min(1),
    packingStyleDetail: z.string().optional(),
  })
  .refine(
    ({packingStyle, packingStyleDetail}) =>
      packingStyle !== 'other' ||
      (packingStyle === 'other' && packingStyleDetail),
    {
      path: ['packingStyleDetail'],
      message: '入力してください',
    },
  );

const ShippingInstructionInputForm = baseSchema
  .and(packingStyleSchema)
  .and(paymentConditionSchema)
  .and(regulatedSchema);

export type FormInput = z.infer<typeof ShippingInstructionInputForm>;

export const CreateSIJpnDepartureScreen = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const {
    estimateId,
    totalCw,
    totalGw,
    totalFee,
    totalFeeTax,
    totalFeeIncludeTax,
    searchItems,
    charge,
    unit,
  } = location.state;
  const defaultShippingInstruction = location.state?.shippingInstruction ?? '';
  const estimate = useEstimate(estimateId);

  const navigate = useNavigate();
  const {
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: {errors},
  } = useForm<FormInput>({
    defaultValues: defaultShippingInstruction
      ? {...defaultShippingInstruction}
      : {},
    resolver: zodResolver(ShippingInstructionInputForm),
  });

  const onSubmit: SubmitHandler<FormInput> = async data => {
    navigate('/createSIConfirmation', {
      state: {
        previousPath: '/createSIJpnDeparture',
        estimateId,
        totalCw,
        totalGw,
        totalFee,
        totalFeeTax,
        totalFeeIncludeTax,
        searchItems,
        shippingInstruction: data,
        charge,
        unit,
      },
    });
  };

  const onError = () => {
    window.scrollTo(0, 0);
  };

  const handleDocumentChange = useCallback(
    (newDocument: DocumentInput) => {
      setValue('document', newDocument);
    },
    [setValue],
  );

  const handleConsigneeContactChange = useCallback(
    (newContact: Partial<ContactInput>) => {
      setValue('consigneeContact', {
        ...getValues('consigneeContact'),
        ...newContact,
      });
    },
    [getValues, setValue],
  );

  const handleOptionsChange = useCallback(
    (newOptions: OptionsInput) => {
      setValue('options', newOptions);
    },
    [setValue],
  );

  useEffect(() => {
    document.title = `${t('createSi')} | MAST`;
  }, [t]);

  return (
    <Stack
      pb={10}
      sx={{
        width: '700px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}>
      <Typography variant="h2" mb={3}>
        {t('si')}
      </Typography>
      <Stack
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center">
        <Inner>
          <Stack
            flexDirection="column"
            sx={{
              width: '620px',
              gap: '12px',
            }}>
            <Typography variant="h3">{t('cargoDetails')}</Typography>
            <Stack flexDirection="column" gap="16px" sx={{px: '8px'}}>
              <LabelTextField
                title={t('productName')}
                isRequired={true}
                error={!!errors.itemName}
                value={watch('itemName')}
                onChange={event => {
                  setValue('itemName', event.target.value);
                }}
              />
              {errors.itemName && (
                <Typography variant="error">
                  {t('requiredFieldVaridation')}
                </Typography>
              )}
              <LabelRadio
                labelTitle={<Typography>{t('packingType')}</Typography>}
                isRequired={true}
                error={!!errors.packingStyle}
                options={[
                  {value: 'carton', label: t('carton')},
                  {value: 'pallet', label: t('pallet')},
                  {
                    value: 'other',
                    label: t('other'),
                    isTextRequired: true,
                    textFieldProps: {
                      value:
                        watch('packingStyle') === 'other'
                          ? watch('packingStyleDetail')
                          : '',
                      onChange: event =>
                        setValue('packingStyleDetail', event.target.value),
                      disabled: watch('packingStyle') !== 'other',
                      error: !!errors.packingStyleDetail,
                    },
                  },
                ]}
                value={watch('packingStyle')}
                onChange={event => {
                  const value = event.target.value as
                    | 'other'
                    | 'pallet'
                    | 'carton';
                  setValue('packingStyle', value);
                }}
              />
            </Stack>
            <LabelRadio
              labelTitle={
                <Typography variant="h3">{t('cargoClassification')}</Typography>
              }
              isRequired={true}
              error={!!errors.cargoType}
              options={cargoTypeOptions.map(value => ({
                value,
                label: getLocalizedCargoType(value),
                ...(value === 'NORMAL' ? null : {isDisabled: true}),
              }))}
              value={watch('cargoType')}
              onChange={event => {
                setValue('cargoType', event.target.value as CargoType);
              }}
            />
            <LabelRadio
              labelTitle={<Typography variant="h3">{t('sds')}</Typography>}
              isRequired={true}
              error={!!errors.isSds}
              options={[
                {value: 'true', label: t('possible')},
                {
                  value: 'false',
                  label: t('impossible'),
                },
              ]}
              value={watch('isSds')}
              onChange={event => {
                setValue('isSds', event.target.value === 'true');
              }}
            />
            <LabelRadio
              labelTitle={<Typography variant="h3">{t('laws')}</Typography>}
              isRequired={true}
              error={!!errors.regulated}
              options={[
                {value: 'true', label: t('subjectToExportTrade')},
                {
                  value: 'false',
                  label: t('nonApplicableToExportTrade'),
                },
                {
                  value: 'other',
                  label: t('other'),
                  isTextRequired: true,
                  textFieldProps: {
                    value: watch('regulatedDetail'),
                    onChange: event => {
                      setValue('regulatedDetail', event.target.value);
                    },
                    disabled: watch('regulated') !== 'other',
                    error: !!errors.regulatedDetail,
                  },
                },
              ]}
              value={watch('regulated')}
              onChange={event => {
                setValue('regulated', event.target.value);
                if (event.target.value !== 'other') {
                  setValue('regulatedDetail', '');
                }
              }}
            />
            <LabelRadio
              labelTitle={
                <Typography variant="h3">
                  {t('preferredPaymentsTerms')}
                </Typography>
              }
              isRequired={true}
              error={!!errors.paymentCondition}
              options={paymentOptions.map(value => ({
                value,
                label: getLocalizedPaymentCondition(value),
                ...(value === PaymentCondition.CREDIT_REGISTERED_WITH_HISTORY
                  ? {
                      isTextRequired: true,
                      textFieldProps: {
                        value: watch('paymentConditionDetail'),
                        onChange: event => {
                          setValue(
                            'paymentConditionDetail',
                            event.target.value,
                          );
                        },
                        error: !!errors.paymentConditionDetail,
                        disabled:
                          watch('paymentCondition') !==
                          PaymentCondition.CREDIT_REGISTERED_WITH_HISTORY,
                        placeholder:
                          '取引実績が有る場合は、交信部署・担当者名を入力(必須)',
                      },
                    }
                  : null),
              }))}
              value={watch('paymentCondition')}
              onChange={event => {
                setValue(
                  'paymentCondition',
                  event.target.value as PaymentConditionEnum,
                );
                if (
                  event.target.value !==
                  PaymentCondition.CREDIT_REGISTERED_WITH_HISTORY
                ) {
                  setValue('paymentConditionDetail', '');
                }
              }}
            />
            <Typography variant="h3">{t('dispatchDocuments')}</Typography>
            <Stack sx={{ml: '12px'}}>
              <DocumentForm
                document={watch('document') || null}
                handleDocumentChange={handleDocumentChange}
              />
            </Stack>
            <ConsigneeContact
              consigneeContact={watch('consigneeContact')}
              handleContactChange={handleConsigneeContactChange}
              errors={errors?.consigneeContact}
            />
            <CreateSIOptions
              options={watch('options') || null}
              handleOptionsChange={handleOptionsChange}
            />
          </Stack>
          <Button
            variant="gray"
            size="large"
            style={{marginTop: '24px'}}
            onClick={() =>
              navigate('/searchEstimate/results/compare', {
                state: {
                  departure: estimate?.data?.departure ?? '',
                  arrival: estimate?.data?.arrival ?? '',
                  flightDate: new Date(estimate?.data?.flightDate ?? ''),
                  airlineCode: estimate?.data?.airline ?? '',
                  charge: charge,
                  searchItems: searchItems,
                },
              })
            }>
            {t('back')}
          </Button>
          <Button
            variant="primary"
            type="submit"
            size="large"
            style={{marginTop: '24px', marginLeft: '24px'}}
            onClick={handleSubmit(onSubmit, onError)}>
            {t('finalConfirmation')}
          </Button>
        </Inner>
      </Stack>
    </Stack>
  );
};
