import {Stack, Box, TextField, Typography, Dialog, Button} from '@mui/material';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

type ReservationCreateHawbDialogProps = {
  isConfirmOpen: boolean;
  hawb: string;
  setIsConfirmOpen: (open: boolean) => void;
  setHawb: (value: string) => void;
  confirmReservation: () => void;
};

export const ReservationCreateHawbDialog: FC<
  ReservationCreateHawbDialogProps
> = props => {
  const {t} = useTranslation();

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '12px',
        },
      }}
      onClose={() => {
        props.setIsConfirmOpen(false);
      }}
      open={props.isConfirmOpen}>
      <Stack
        width="544px"
        height="302px"
        spacing={4}
        justifyContent="center"
        alignItems="center">
        <Typography variant="h4">{t('registerHawb')}</Typography>
        <Box width="476px">
          <Typography variant="h5">{t('bookRequest')}</Typography>
          <TextField
            fullWidth
            onChange={event => {
              props.setHawb(event.target.value);
            }}
          />
        </Box>
        <Stack flexDirection="row">
          <Button
            variant="outlined"
            onClick={() => {
              props.setIsConfirmOpen(false);
              props.setHawb('');
            }}>
            {t('cancel')}
          </Button>
          <Button
            variant="primary"
            size="small"
            disabled={!props.hawb}
            onClick={props.confirmReservation}
            sx={{marginLeft: '24px'}}>
            {t('register')}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
