import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Grid,
  Button,
} from '@mui/material';
import {Auth} from 'aws-amplify';
import {useEffect, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {LabelTitle} from '../../components/LabelTitle';
import {PasswordValidation} from '../../components/PasswordValidation';
import Inner from '../../layouts/Inner';
import {combineAlphanumericCharacters} from '../../utils/combineAlphanumericCharactersRegex';

type FormInput = {
  authcode: number;
  newPassword: string;
};

export const ResetPasswordScreen = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: {errors, dirtyFields},
  } = useForm<FormInput>({
    mode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const onSubmit: SubmitHandler<FormInput> = data => {
    const state = location.state as {email: string};
    Auth.forgotPasswordSubmit(
      state.email,
      data.authcode.toString(),
      data.newPassword,
    )
      .then(_data => {
        navigate('/completeResetPassword');
      })
      .catch(() => {
        setSubmitError(true);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    document.title = `${t('personWhoForgotPassword')} | MAST`;
  }, [t]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      sx={{minHeight: '100vh'}}>
      <Typography mb={3} variant="h1">
        MAST
      </Typography>
      <Inner>
        <Stack spacing={4} sx={{width: '632px'}}>
          <Typography textAlign="center" variant="h3">
            {t('changePassword')}
          </Typography>
          {submitError ? (
            <Typography variant="error" mb={3} textAlign="center">
              {t('wrongInformation')}
              <br />
              {t('checkAgain')}
            </Typography>
          ) : null}
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{mb: '25.5px'}}>
            <LabelTitle isRequired>{t('sixDigitVerification')}</LabelTitle>

            <TextField
              fullWidth
              variant="outlined"
              InputLabelProps={{shrink: false}}
              sx={{
                width: '408px',
                '& .MuiInputBase-root': {bgcolor: 'white'},
              }}
              error={!!errors.authcode}
              {...register('authcode', {
                required: true,
                pattern: /^(?=.*?\d)[\d]{6}$/,
              })}
              helperText={
                errors.authcode ? t('enterSixDigitVerification') : ''
              }></TextField>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{
              position: 'relative',
            }}>
            <LabelTitle isRequired sx={{marginTop: '16px'}}>
              {t('newPassword')}
            </LabelTitle>
            <Stack>
              <TextField
                error={!!errors.newPassword}
                fullWidth
                aria-label="password"
                label={t('password')}
                variant="outlined"
                sx={{
                  width: '408px',
                  position: 'relative',
                }}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}>
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register('newPassword', {
                  required: true,
                  minLength: 8,
                  pattern: combineAlphanumericCharacters,
                })}
              />
              <Box
                sx={{
                  marginTop: '16px',
                }}>
                <PasswordValidation
                  wordCountError={
                    !!errors.newPassword?.types?.required ||
                    !!errors.newPassword?.types?.minLength ||
                    !dirtyFields.newPassword
                  }
                  alphanumericError={
                    !!errors.newPassword?.types?.required ||
                    !!errors.newPassword?.types?.pattern ||
                    !dirtyFields.newPassword
                  }
                />
              </Box>
            </Stack>
          </Stack>
          <Grid container alignItems="center" justifyContent="center">
            <Button
              size="large"
              variant="primary"
              type="submit"
              onClick={handleSubmit(onSubmit)}>
              {t('change')}
            </Button>
          </Grid>
        </Stack>
      </Inner>
    </Grid>
  );
};
