import {Stack, Typography} from '@mui/material';
import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StyledTable} from './StyledTable';
import {ShippingFhdFee} from '../API';
import {useShippingFhdFee} from '../queries/shippingFhdFee';
import {isNonNull} from '../utils/isEmpty';
import {roundToDecimal} from '../utils/roundToDecimal';

interface ShippingFhdDetailProps {
  shippingFhdFeeId: string | null | undefined;
}

export const ShippingFhdDetail: FC<ShippingFhdDetailProps> = props => {
  if (!props.shippingFhdFeeId) return null;
  const {t} = useTranslation();
  const {data: shippingFhdFee} = useShippingFhdFee(props.shippingFhdFeeId);

  const totalCustomFee =
    shippingFhdFee?.customFees?.items.reduce((total, item) => {
      return (
        total +
        roundToDecimal(
          (item?.currency?.exchangeRateJPY ?? 0) * (item?.fee ?? 0),
          0,
        )
      );
    }, 0) ?? 0;

  const [sortedShippingFhdFee, setSortedShippingFhdFee] =
    useState<ShippingFhdFee | null>(null);

  useEffect(() => {
    if (shippingFhdFee?.customFees?.items) {
      const sortedItems = [...shippingFhdFee.customFees.items].sort(
        (a, b) =>
          new Date(a?.createdAt ?? '').getTime() -
          new Date(b?.createdAt ?? '').getTime(),
      );
      const sortedShippingFhdFee = {
        ...shippingFhdFee,
        customFees: {
          ...shippingFhdFee.customFees,
          items: sortedItems,
        },
      } as ShippingFhdFee;
      setSortedShippingFhdFee(sortedShippingFhdFee);
    } else {
      setSortedShippingFhdFee(null);
    }
  }, [shippingFhdFee]);

  return (
    <Stack spacing={5}>
      <Stack spacing={3}>
        <Typography variant="h2">{t('importCharge')}</Typography>
        <StyledTable>
          <tr>
            <th>{t('ItemName')}</th>
            <th>{t('currency')}</th>
            <th>{t('unitPrice')}</th>
            <th>{t('unit')}</th>
            <th>{t('minimum')}</th>
            <th>{t('maximum')}</th>
            <th>{t('estimatedAmount')}</th>
            <th>{t('exchangeRate')}</th>
            <th>{t('yenCurrency')}</th>
            <th>{t('remark')}</th>
          </tr>
          <>
            {sortedShippingFhdFee?.customFees?.items
              .filter(isNonNull)
              .map(item => (
                <tr>
                  <th>{item.title}</th>
                  <td>{item.currency?.code}</td>
                  <td>{item.unitPrice.toLocaleString()}</td>
                  <td>{item.unitType}</td>
                  <td>{item.minimum?.toLocaleString()}</td>
                  <td>{item.maximum?.toLocaleString()}</td>
                  <td>{roundToDecimal(item.fee, 0).toLocaleString()}</td>
                  <td>
                    {roundToDecimal(
                      item.currency?.exchangeRateJPY,
                      2,
                    ).toLocaleString()}
                  </td>
                  <td>
                    {roundToDecimal(
                      (item.currency?.exchangeRateJPY ?? 0) * (item.fee ?? 0),
                      0,
                    ).toLocaleString()}
                  </td>
                  <td>{item.remark}</td>
                </tr>
              ))}
          </>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{t('subtotal')}</td>
            <td>{roundToDecimal(totalCustomFee, 0).toLocaleString()}</td>

            <td></td>
          </tr>
        </StyledTable>
        <Typography>{shippingFhdFee?.remark}</Typography>
      </Stack>
    </Stack>
  );
};
