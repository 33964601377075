import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {
  CreateInvitedUserMutation,
  CreateInvitedUserMutationVariables,
  DeleteInvitedUserMutation,
  DeleteInvitedUserMutationVariables,
  GetInvitedUserQuery,
  GetInvitedUserQueryVariables,
  InvitedUsersByCompanyIdQuery,
  InvitedUsersByCompanyIdQueryVariables,
  ListInvitedUsersQuery,
  ListInvitedUsersQueryVariables,
  UpdateInvitedUserMutation,
  UpdateInvitedUserMutationVariables,
} from '../API';
import {
  deleteInvitedUser,
  createInvitedUser,
  updateInvitedUser,
} from '../graphql/mutations';
import {
  getInvitedUser,
  invitedUsersByCompanyId,
  listInvitedUsers,
} from '../graphql/queries';

export const useInvitedUsers = () => {
  return useQuery(['invitedUsers'], async () => {
    const variables: ListInvitedUsersQueryVariables = {
      limit: 10000,
    };
    const result = (await API.graphql(
      graphqlOperation(listInvitedUsers, variables),
    )) as GraphQLResult<ListInvitedUsersQuery>;
    return result?.data?.listInvitedUsers;
  });
};

export const useInvitedUsersByCompanyId = (companyId?: string) => {
  return useQuery(['invitedUsers', companyId], async () => {
    if (!companyId) return;
    const variables: InvitedUsersByCompanyIdQueryVariables = {
      companyId: companyId,
      limit: 10000,
    };
    const result = (await API.graphql(
      graphqlOperation(invitedUsersByCompanyId, variables),
    )) as GraphQLResult<InvitedUsersByCompanyIdQuery>;
    return result?.data?.invitedUsersByCompanyId;
  });
};

export const useInvitedUser = (email?: string) => {
  return useQuery(
    ['invitedUser', email],
    async () => {
      if (!email) return;
      const variable: GetInvitedUserQueryVariables = {
        email: email,
      };
      const result = (await API.graphql(
        graphqlOperation(getInvitedUser, variable),
      )) as GraphQLResult<GetInvitedUserQuery>;
      return result.data?.getInvitedUser;
    },
    {
      enabled: !!email,
    },
  );
};

export const useCreateInvitedUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (variables: CreateInvitedUserMutationVariables) => {
      const user = (await API.graphql(
        graphqlOperation(createInvitedUser, variables),
      )) as GraphQLResult<CreateInvitedUserMutation>;
      return user.data?.createInvitedUser;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
    },
  );
};

export const useUpdateInvitedUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (variables: UpdateInvitedUserMutationVariables) => {
      const user = (await API.graphql(
        graphqlOperation(updateInvitedUser, variables),
      )) as GraphQLResult<UpdateInvitedUserMutation>;
      return user.data?.updateInvitedUser;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
    },
  );
};

export const useDeleteInvitedUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (variables: DeleteInvitedUserMutationVariables) => {
      const user = (await API.graphql(
        graphqlOperation(deleteInvitedUser, variables),
      )) as GraphQLResult<DeleteInvitedUserMutation>;
      return user.data?.deleteInvitedUser;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
    },
  );
};
