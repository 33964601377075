import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery} from 'react-query';
import {
  ListShippingFobFeesQueryVariables,
  ListShippingFobFeesQuery,
  GetShippingFobFeeQueryVariables,
  GetShippingFobFeeQuery,
  CreateShippingFobFeeMutationVariables,
  CreateShippingFobFeeMutation,
  UpdateShippingFobFeeMutationVariables,
  UpdateShippingFobFeeMutation,
  DeleteShippingFobFeeMutationVariables,
  DeleteShippingFobFeeMutation,
} from '../API';
import {
  createShippingFobFee,
  deleteShippingFobFee,
  updateShippingFobFee,
} from '../graphql/mutations';
import {getShippingFobFee, listShippingFobFees} from '../graphql/queries';

export const useShippingFobFees = () => {
  return useQuery(['shippingFobFees'], async () => {
    const variables: ListShippingFobFeesQueryVariables = {
      limit: 10000,
    };
    const result = (await API.graphql(
      graphqlOperation(listShippingFobFees, variables),
    )) as GraphQLResult<ListShippingFobFeesQuery>;
    return result?.data?.listShippingFobFees;
  });
};

export const useShippingFobFee = (id: string) => {
  return useQuery(
    ['shippingFobFee', id],
    async () => {
      const variable: GetShippingFobFeeQueryVariables = {
        id,
      };
      const result = (await API.graphql(
        graphqlOperation(getShippingFobFee, variable),
      )) as GraphQLResult<GetShippingFobFeeQuery>;
      return result.data?.getShippingFobFee;
    },
    {
      enabled: !!id,
    },
  );
};

export const useCreateShippingFobFeeMutation = () => {
  return useMutation(
    async (variables: CreateShippingFobFeeMutationVariables) => {
      const shippingFobFee = (await API.graphql(
        graphqlOperation(createShippingFobFee, variables),
      )) as GraphQLResult<CreateShippingFobFeeMutation>;
      return shippingFobFee.data?.createShippingFobFee;
    },
  );
};

export const useUpdateShippingFobFeeMutation = () => {
  return useMutation(
    async (variables: UpdateShippingFobFeeMutationVariables) => {
      const shippingFobFee = (await API.graphql(
        graphqlOperation(updateShippingFobFee, variables),
      )) as GraphQLResult<UpdateShippingFobFeeMutation>;
      return shippingFobFee.data?.updateShippingFobFee;
    },
  );
};

export const useDeleteShippingFobFeeMutation = () => {
  return useMutation(
    async (variables: DeleteShippingFobFeeMutationVariables) => {
      const shippingFobFee = (await API.graphql(
        graphqlOperation(deleteShippingFobFee, variables),
      )) as GraphQLResult<DeleteShippingFobFeeMutation>;
      return shippingFobFee.data?.deleteShippingFobFee;
    },
  );
};
