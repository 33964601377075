import {Drawer, Box, Stack, Typography} from '@mui/material';
import dayjs from 'dayjs';
import {Dispatch, SetStateAction, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {DepartureArrivalAirports} from './DepartureArrivalAirports';
import {FeeDetail} from './FeeDetail';
import {FhdDetail} from './FhdDetail';
import {FobDetail} from './FobDetail';
import {ForwarderCompanyRate} from './ForwarderCompanyRate';
import {LabelValue} from './LabelValue';
import {TransportType} from '../API';
import {useEstimate} from '../queries/estimate';
import {useFirstFhds} from '../queries/fhd';
import {useFirstFobs} from '../queries/fob';
import {calculateCustomFee} from '../utils/calculateCustomFee';
import {calculateCustomFeeTax} from '../utils/calculateCustomFeeTax';
import {calculateFee} from '../utils/calculateFee';
import {isNonNull} from '../utils/isEmpty';
import {roundToDecimal} from '../utils/roundToDecimal';

type estimateDetailProps = {
  isDetailOpen: boolean;
  selectedEstimateId?: string | null;
  totalCw: number;
  totalGw: number;
  unit: number;
  charge: string;
  setIsDetailOpen: Dispatch<SetStateAction<boolean>>;
};

export const EstimateDetail = (props: estimateDetailProps) => {
  const {t} = useTranslation();

  const {data: estimate} = useEstimate(props.selectedEstimateId ?? '');

  const {data: fob} = useFirstFobs(
    estimate?.forwarderUser.companyId ?? '',
    estimate?.departure ?? '',
  );

  const {data: fhd} = useFirstFhds(
    estimate?.forwarderUser.companyId ?? '',
    estimate?.arrival ?? '',
  );

  const handleClose = useCallback(() => {
    props.setIsDetailOpen(false);
  }, [props]);

  const shippingFee = roundToDecimal(
    calculateFee(
      props.totalCw,
      props.totalGw,
      props.unit,
      estimate?.over1000,
      estimate?.over500,
      estimate?.over300,
      estimate?.over100,
      estimate?.over45,
      estimate?.under45,
      estimate?.fsc,
      estimate?.minimum,
      estimate?.customItems?.items,
      estimate?.currency?.exchangeRateJPY,
    ),
    0,
  );
  const shippingFobFee = roundToDecimal(
    calculateCustomFee(
      fob?.customItems?.items?.filter(isNonNull) ?? [],
      props.totalCw,
      props.totalGw,
      props.unit,
    ),
    0,
  );
  const shippingFhdFee = roundToDecimal(
    calculateCustomFee(
      fhd?.customItems?.items?.filter(isNonNull) ?? [],
      props.totalCw,
      props.totalGw,
      props.unit,
    ),
    0,
  );

  const shippingFeeTax = roundToDecimal(
    calculateCustomFeeTax(
      estimate?.customItems?.items.filter(isNonNull) ?? [],
      props.totalCw,
      props.totalGw,
      props.unit,
    ),
    0,
  );
  const shippingFobFeeTax = roundToDecimal(
    calculateCustomFeeTax(
      fob?.customItems?.items?.filter(isNonNull) ?? [],
      props.totalCw,
      props.totalGw,
      props.unit,
    ),
    0,
  );
  const shippingFhdFeeTax = roundToDecimal(
    calculateCustomFeeTax(
      fhd?.customItems?.items?.filter(isNonNull) ?? [],
      props.totalCw,
      props.totalGw,
      props.unit,
    ),
    0,
  );

  // 料金確認範囲によって表示総額を変更
  const totalFeeTax =
    props.charge === TransportType.TRILATERAL
      ? shippingFeeTax + shippingFobFeeTax + shippingFhdFeeTax
      : props.charge === TransportType.AIR_EXPORT
      ? shippingFeeTax + shippingFobFeeTax
      : shippingFeeTax;

  const totalFee =
    props.charge === TransportType.TRILATERAL
      ? shippingFee + shippingFobFee + shippingFhdFee
      : props.charge === TransportType.AIR_EXPORT
      ? shippingFee + shippingFobFee
      : shippingFee;

  const totalFeeIncludeTax =
    props.charge === TransportType.TRILATERAL
      ? shippingFeeTax +
        shippingFobFeeTax +
        shippingFhdFeeTax +
        shippingFee +
        shippingFobFee +
        shippingFhdFee
      : props.charge === TransportType.AIR_EXPORT
      ? shippingFeeTax + shippingFobFeeTax + shippingFee + shippingFobFee
      : shippingFeeTax + shippingFee;

  return (
    <Drawer
      anchor="right"
      open={props.isDetailOpen}
      onClose={handleClose}
      sx={{overflowX: 'auto'}}>
      <Box width="1300px" mt={5} ml={5}>
        <Stack spacing={3} mb={3}>
          <Stack direction="row" spacing={1}>
            <Box
              sx={{
                backgroundColor: estimate?.directFlight ? '#DE6E54' : '#33BDD4',
                paddingX: '10px',
                paddingY: '5px',
                borderRadius: '6px',
                color: '#333333',
              }}>
              <Typography variant="h6" color="white">
                {estimate?.directFlight
                  ? t('directFlight')
                  : t('connectingFlight')}
              </Typography>
            </Box>
            {estimate?.useSaf && (
              <Box
                sx={{
                  backgroundColor: '#2E7D32',
                  paddingX: '10px',
                  paddingY: '5px',
                  borderRadius: '6px',
                  color: '#333333',
                }}>
                <Typography variant="h6" color="white">
                  SAF
                </Typography>
              </Box>
            )}
          </Stack>
          <ForwarderCompanyRate
            forwarderCompanyId={estimate?.forwarderUser.companyId}
          />
          <Stack spacing={3}>
            <DepartureArrivalAirports
              departure={estimate?.departure}
              arrival={estimate?.arrival}
            />
            <LabelValue
              title={`${t('etd')}:`}
              titleWeight="600"
              value={dayjs(estimate?.flightDate).format('YYYY/M/D') ?? ''}
              row
            />
            <Stack spacing={7}>
              <FeeDetail
                estimateId={estimate?.id ?? ''}
                totalCw={props.totalCw}
                totalGw={props.totalGw}
                unit={props.unit}
              />
              {/* 料金確認範囲が"航空運賃"以外で輸出料金を表示 */}
              {props.charge !== TransportType.AIR_IMPORT && (
                <FobDetail
                  companyId={estimate?.forwarderUser.companyId ?? ''}
                  departure={estimate?.departure ?? ''}
                  totalCw={props.totalCw}
                  totalGw={props.totalGw}
                  unit={props.unit}
                />
              )}
              {/* 料金確認範囲が"航空運賃+輸出料金+輸入料金"の場合のみ輸入料金を表示 */}
              {props.charge === TransportType.TRILATERAL && (
                <FhdDetail
                  companyId={estimate?.forwarderUser.companyId ?? ''}
                  arrival={estimate?.arrival ?? ''}
                  totalCw={props.totalCw}
                  totalGw={props.totalGw}
                  unit={props.unit}
                />
              )}
            </Stack>
          </Stack>
          <LabelValue
            title={t('excludingTax')}
            value={`¥${totalFee.toLocaleString()}`}
            valueWeight="700"
            valueSize="24px"
            width="260px"
            color="#3F6CDF"
          />
          <LabelValue
            title={`${t('consumptionTax')}${t('consumptionTaxExplanation')}`}
            value={`¥${totalFeeTax.toLocaleString()}`}
            valueWeight="700"
            valueSize="24px"
            width="260px"
            color="#3F6CDF"
          />
          <LabelValue
            title={t('totalFee')}
            value={`¥${totalFeeIncludeTax.toLocaleString()}`}
            valueWeight="700"
            valueSize="24px"
            width="260px"
            color="#3F6CDF"
          />
        </Stack>
      </Box>
    </Drawer>
  );
};
