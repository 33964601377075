/* eslint-disable @typescript-eslint/no-explicit-any */

import {makeStyles} from '@material-ui/core/styles';
import {convert, stringToCode} from 'encoding-japanese';
import jsonExport from 'jsonexport/dist';
import {useEffect, useState} from 'react';
import {
  Button,
  Create,
  CreateProps,
  Edit,
  EditProps,
  Datagrid,
  EmailField,
  Layout,
  LayoutProps,
  List,
  ListProps,
  NumberField,
  NumberInput,
  Admin as ReactAdmin,
  required,
  Resource,
  SaveButton,
  SimpleForm,
  TextInput,
  TextField,
  Toolbar,
  ToolbarProps,
  useNotify,
  useRecordContext,
  EditButton,
  GetListParams,
  useRefresh,
  FunctionField,
  ReferenceField,
  AppBar,
} from 'react-admin';
import {buildAuthProvider, buildDataProvider} from 'react-admin-amplify';
import {useTranslation} from 'react-i18next';
import {NumberOfEmployees, ShipperRole} from '../../API';
import {
  addUserToGroup,
  listGroupsForUser,
} from '../../admin-queries/adminQueries';
import {categoryOptions} from '../../data/categoryOptions';
import RoleOptions from '../../data/roleOptions';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';

const exporter = (
  posts: any[],
  columns: string[],
  headers: string[],
  file_name: string,
) => {
  const postsForExport = posts.map(post => {
    const postForExport: any = {};
    columns.forEach((column, index) => {
      const header = headers[index];
      const columnParts = column.split('.'); // ドットで文字列を分割
      let value = post;
      for (const part of columnParts) {
        if (value && Object.prototype.hasOwnProperty.call(value, part)) {
          value = value[part]; // プロパティにアクセス
        } else {
          value = ''; // プロパティが存在しない場合は空文字を設定
          break;
        }
      }
      postForExport[header] = value;
    });
    return postForExport;
  });

  jsonExport(postsForExport, (err: Error, csv: string) => {
    const unicodeArray = stringToCode(csv);
    const shiftJisArray = convert(unicodeArray, {
      to: 'SJIS',
      from: 'UNICODE',
    });
    const csvOfShiftJis = new Uint8Array(shiftJisArray);
    const blob = new Blob([csvOfShiftJis], {type: 'text/csv'});
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = file_name + '.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

const ForwarderUserList = (props: ListProps) => {
  const {t} = useTranslation();
  const classes = useStyles();
  return (
    <List
      title={t('userList')}
      {...props}
      exporter={posts =>
        exporter(
          posts,
          [
            'lastName',
            'firstName',
            'email',
            'phoneNumber',
            'company.name',
            'workLocation',
            'division',
            'position',
          ],
          [
            t('lastName'),
            t('firstName'),
            t('email'),
            t('phoneNumber'),
            t('companyName'),
            t('workplaceAddress'),
            t('department'),
            t('jobPosition'),
          ],
          'ForwarderUserList',
        )
      }>
      <Datagrid
        rowClick="edit"
        bulkActionButtons={false}
        className={classes.table}>
        <TextField source="lastName" label={t('lastName')} />
        <TextField source="firstName" label={t('firstName')} />
        <EmailField source="email" label={t('email')} />
        <TextField source="phoneNumber" label={t('phoneNumber')} />
        <TextField source="company.name" label={t('companyName')} />
        <TextField source="workLocation" label={t('workplaceAddress')} />
        <TextField source="division" label={t('department')} />
        <TextField source="position" label={t('jobPosition')} />
      </Datagrid>
    </List>
  );
};

const ShipperUserList = (props: ListProps) => {
  const {t} = useTranslation();
  const classes = useStyles();
  return (
    <List
      title={t('userList')}
      {...props}
      exporter={posts =>
        exporter(
          posts,
          [
            'lastName',
            'firstName',
            'email',
            'phoneNumber',
            'company.name',
            'workLocation',
            'division',
            'position',
            'area',
            'role',
          ],
          [
            t('lastName'),
            t('firstName'),
            t('email'),
            t('phoneNumber'),
            t('companyName'),
            t('workplaceAddress'),
            t('department'),
            t('jobPosition'),
            t('mainAreas'),
            t('mainResponsibilities'),
          ],
          'ShipperUserList',
        )
      }>
      <Datagrid
        rowClick="edit"
        bulkActionButtons={false}
        className={classes.table}>
        <TextField source="lastName" label={t('lastName')} />
        <TextField source="firstName" label={t('firstName')} />
        <EmailField source="email" label={t('email')} />
        <TextField source="phoneNumber" label={t('phoneNumber')} />
        <TextField source="company.name" label={t('companyName')} />
        <TextField source="workLocation" label={t('workplaceAddress')} />
        <TextField source="division" label={t('department')} />
        <TextField source="position" label={t('jobPosition')} />
        <TextField source="area" label={t('mainAreas')} />
        <FunctionField
          source="role"
          label={t('mainResponsibilities')}
          render={(record: {role: ShipperRole}) =>
            RoleOptions.find(option => option.value === record.role)?.name || ''
          }
        />
      </Datagrid>
    </List>
  );
};

type Group = {
  GroupName: string;
};

type GroupType = 'Forwarders' | 'Shippers';

interface ApproveButtonProps {
  group: GroupType;
}

const ApproveButton = (props: ApproveButtonProps) => {
  const notify = useNotify();
  const record = useRecordContext();
  const [approved, setApproved] = useState(false);
  useEffect(() => {
    const initialize = async () => {
      try {
        const groups: [Group] = await listGroupsForUser(record.manager.email);
        setApproved(groups.some(({GroupName}) => GroupName === props.group));
      } catch {
        notify('failed to fetch groups', {type: 'error'});
      }
    };
    initialize();
  }, [record.manager.email, props.group, notify]);
  const handleClick = async () => {
    try {
      const res = await addUserToGroup(record.manager.email, props.group);
      if (!res.message.startsWith('Success')) {
        return notify(
          `failed to add ${record.manager.email} to ${props.group}`,
          {
            type: 'error',
          },
        );
      }
      return setApproved(true);
    } catch {
      notify(`failed to add ${record.manager.email} to ${props.group}`, {
        type: 'error',
      });
    }
  };
  return (
    <Button onClick={handleClick} {...props} disabled={approved} label="承認" />
  );
};

const ForwarderCompanyList = (props: ListProps) => {
  const {t} = useTranslation();
  const classes = useStyles();
  return (
    <List
      title={t('companyList')}
      {...props}
      exporter={posts =>
        exporter(
          posts,
          [
            'name',
            'fullName',
            'location',
            'establishment',
            'paidInCaptial',
            'corporateNumber',
            'iataAgency',
            'listed',
            'numberOfEmployees',
            'companyURL',
          ],
          [
            t('companyName'),
            t('admin'),
            t('headquartersAddress'),
            t('establishmentDate'),
            t('capitalAmount'),
            t('corporateRegistrationNumber'),
            t('iataAgency'),
            t('listedCompany'),
            t('employeesNumber'),
            t('URL'),
          ],
          'ForwarderCompanyList',
        )
      }>
      <Datagrid
        rowClick="edit"
        bulkActionButtons={false}
        className={classes.table}>
        <TextField source="name" label={t('companyName')} />
        <TextField source="fullName" label={t('admin')} />
        <TextField source="location" label={t('headquartersAddress')} />
        <TextField source="establishment" label={t('establishmentDate')} />
        <FunctionField
          source="paidInCaptial"
          label={t('capitalAmount')}
          render={(record: {paidInCaptial: number}) => record.paidInCaptial}
        />
        <TextField
          source="corporateNumber"
          label={t('corporateRegistrationNumber')}
        />
        <TextField source="iataAgency" label={t('iataAgency')} />
        <TextField source="listed" label={t('listedCompany')} />
        <FunctionField
          source="numberOfEmployees" // あなたのデータソースに合わせて修正してください
          label={t('employeesNumber')}
          render={(record: {numberOfEmployees: NumberOfEmployees}) => {
            const numberOfEmployeesOptions = getNumberOfEmployeesOptions();
            const numberOfEmployees = numberOfEmployeesOptions.find(
              option => option.value === record.numberOfEmployees,
            );
            return numberOfEmployees ? numberOfEmployees.label : '';
          }}
        />
        <TextField source="companyURL" label={t('URL')} />
        <ApproveButton group="Forwarders" />
      </Datagrid>
    </List>
  );
};

const numberOfEmployeesOptions = (
  t: ReturnType<typeof useTranslation>['t'],
) => {
  return [
    {label: t('lessFivePeople'), value: NumberOfEmployees.UNDER5},
    {label: t('lessTwentyPeople'), value: NumberOfEmployees.UNDER20},
    {label: t('lessHundredPeople'), value: NumberOfEmployees.UNDER100},
    {label: t('lessThreeHundredPeople'), value: NumberOfEmployees.UNDER300},
    {label: t('moreThreeHundredPeople'), value: NumberOfEmployees.OVER300},
  ];
};

const getNumberOfEmployeesOptions = () => {
  const {t} = useTranslation();
  return numberOfEmployeesOptions(t);
};

const ShipperCompanyList = (props: ListProps) => {
  const {t} = useTranslation();
  const classes = useStyles();
  return (
    <List
      title={t('companyList')}
      {...props}
      exporter={posts =>
        exporter(
          posts,
          [
            'name',
            'fullName',
            'location',
            'establishment',
            'paidInCaptial',
            'corporateNumber',
            'category',
            'listed',
            'numberOfEmployees',
            'companyURL',
          ],
          [
            t('companyName'),
            t('admin'),
            t('headquartersAddress'),
            t('establishmentDate'),
            t('capitalAmount'),
            t('corporateRegistrationNumber'),
            t('industry'),
            t('listedCompany'),
            t('employeesNumber'),
            t('URL'),
          ],
          'ShipperCompanyList',
        )
      }>
      <Datagrid
        rowClick="edit"
        bulkActionButtons={false}
        className={classes.table}>
        <TextField source="name" label={t('companyName')} />
        <TextField source="fullName" label={t('admin')} />
        <TextField source="location" label={t('headquartersAddress')} />
        <TextField source="establishment" label={t('establishmentDate')} />
        <FunctionField
          source="paidInCaptial"
          label={t('capitalAmount')}
          render={(record: {paidInCaptial: number}) => record.paidInCaptial}
        />
        <TextField
          source="corporateNumber"
          label={t('corporateRegistrationNumber')}
        />
        <FunctionField
          source="category"
          label={t('industry')}
          render={(record: {category: string}) => {
            const category = categoryOptions.find(
              option => option.value === record.category,
            );
            return category ? category.label : '';
          }}
        />
        <TextField source="listed" label={t('listedCompany')} />
        <FunctionField
          source="numberOfEmployees" // あなたのデータソースに合わせて修正してください
          label={t('employeesNumber')}
          render={(record: {numberOfEmployees: NumberOfEmployees}) => {
            const numberOfEmployeesOptions = getNumberOfEmployeesOptions();
            const numberOfEmployees = numberOfEmployeesOptions.find(
              option => option.value === record.numberOfEmployees,
            );
            return numberOfEmployees ? numberOfEmployees.label : '';
          }}
        />
        <TextField source="companyURL" label={t('URL')} />
        <ApproveButton group="Shippers" />
      </Datagrid>
    </List>
  );
};

const CurrencyCreate = (props: CreateProps) => {
  const {t} = useTranslation();
  const refresh = useRefresh();
  return (
    <Create
      title={t('currencyCreation')}
      {...props}
      resource="currencys"
      redirect={() => {
        refresh();
        return 'currencies';
      }}>
      <SimpleForm>
        <TextInput
          source="code"
          validate={required()}
          label={t('currencyCode')}
        />
        <NumberInput
          source="exchangeRateJPY"
          validate={required()}
          label={t('exchangeRate')}
        />
      </SimpleForm>
    </Create>
  );
};

const CurrencyList = (props: ListProps) => {
  const {t} = useTranslation();
  const classes = useStyles();
  return (
    <List
      title={t('currencyList')}
      {...props}
      exporter={posts =>
        exporter(
          posts,
          ['code', 'exchangeRateJPY'],
          [t('currencyCode'), t('exchangeRate')],
          'CurrencyList',
        )
      }>
      <Datagrid bulkActionButtons={false} className={classes.table}>
        <TextField source="code" label={t('currencyCode')} />
        <NumberField source="exchangeRateJPY" label={t('exchangeRate')} />
        <EditButton label={t('edit')}></EditButton>
      </Datagrid>
    </List>
  );
};

const UserEditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const CurrencyEdit = (props: EditProps) => {
  const {t} = useTranslation();
  const refresh = useRefresh();
  return (
    <Edit
      title={t('currencyEdit')}
      {...props}
      mutationMode="pessimistic"
      resource="currencys"
      transform={(data: {id: string; exchangeRateJPY: string}) => ({
        id: data.id,
        exchangeRateJPY: data.exchangeRateJPY,
      })}
      redirect={() => {
        refresh();
        return 'currencies';
      }}>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <TextInput source="code" disabled />
        <NumberInput source="exchangeRateJPY" />
      </SimpleForm>
    </Edit>
  );
};

const EstimatesList = (props: ListProps) => {
  const {t} = useTranslation();
  const classes = useStyles();
  return (
    <List
      title={t('quotationsList')}
      {...props}
      exporter={posts =>
        exporter(
          posts,
          [
            'id',
            'departure',
            'arrival',
            'airline',
            'flightDate',
            'directFlight',
            'useSaf',
            'forwarderUser.company.name',
          ],
          [
            'id',
            t('originAirport'),
            t('destinationAirport'),
            t('airline'),
            t('etd'),
            t('flightType'),
            t('saf'),
            t('companyName'),
          ],
          'EstimatesList',
        )
      }>
      <Datagrid bulkActionButtons={false} className={classes.table}>
        <TextField source="id" />
        <TextField source="departure" label={t('originAirport')} />
        <TextField source="arrival" label={t('destinationAirport')} />
        <TextField source="airline" label={t('airline')} />
        <TextField source="flightDate" label={t('etd')} />
        <TextField source="directFlight" label={t('flightType')} />
        <TextField source="useSaf" label={t('saf')} />
        <TextField
          source="forwarderUser.company.name"
          label={t('companyName')}
        />
      </Datagrid>
    </List>
  );
};

const reservationExporter = async (
  posts: any[],
  fetchRelatedRecords: (
    records: any[],
    field: string,
    resource: string,
  ) => Promise<any>,
  columns: string[],
  headers: string[],
  file_name: string,
) => {
  const forwarderPosts = await fetchRelatedRecords(
    posts,
    'forwarderCompanyId',
    'ForwarderCompanys',
  );
  const shipperPosts = await fetchRelatedRecords(
    posts,
    'shipperCompanyId',
    'ShipperCompanys',
  );

  const postsForExport = posts.map(post => {
    const postForExport: any = {};
    columns.forEach((column, index) => {
      const header = headers[index];
      const columnParts = column.split('.'); // ドットで文字列を分割
      let value = post;
      for (const part of columnParts) {
        if (value && Object.prototype.hasOwnProperty.call(value, part)) {
          value = value[part]; // プロパティにアクセス
        } else {
          value = ''; // プロパティが存在しない場合は空文字を設定
          break;
        }
      }
      postForExport[header] = value;
    });
    postForExport['forwarderCompany'] =
      forwarderPosts[post.forwarderCompanyId].name;
    postForExport['shipperCompany'] = shipperPosts[post.shipperCompanyId].name;

    return postForExport;
  });

  jsonExport(postsForExport, (err: Error, csv: string) => {
    const unicodeArray = stringToCode(csv);
    const shiftJisArray = convert(unicodeArray, {
      to: 'SJIS',
      from: 'UNICODE',
    });
    const csvOfShiftJis = new Uint8Array(shiftJisArray);
    const blob = new Blob([csvOfShiftJis], {type: 'text/csv'});
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = file_name + '.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

const ReservationsList: React.FC<ListProps> = props => {
  const {t} = useTranslation();
  const classes = useStyles();
  return (
    <List
      title={t('reservationsList')}
      {...props}
      exporter={(posts, fetchRelatedRecords) =>
        reservationExporter(
          posts,
          fetchRelatedRecords,
          [
            'id',
            'hawb',
            'status',
            'shippingInstruction.departure',
            'shippingInstruction.arrival',
            'shippingInstruction.airline',
            'shippingInstruction.flightDate',
          ],
          [
            'id',
            t('hawb'),
            t('status'),
            t('originAirport'),
            t('destinationAirport'),
            t('airline'),
            t('etd'),
          ],
          'ReservationList',
        )
      }>
      <Datagrid bulkActionButtons={false} className={classes.table}>
        <TextField source="id" />
        <ReferenceField
          source="forwarderCompanyId"
          reference="ForwarderCompanys"
          label={t('forwarderCompany')}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="shipperCompanyId"
          reference="ShipperCompanys"
          label={t('shipperCompany')}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="hawb" label={t('hawb')} />
        <TextField source="status" label={t('status')} />
        <TextField
          source="shippingInstruction.departure"
          label={t('originAirport')}
        />
        <TextField
          source="shippingInstruction.arrival"
          label={t('destinationAirport')}
        />
        <TextField source="shippingInstruction.airline" label={t('airline')} />
        <TextField source="shippingInstruction.flightDate" label={t('etd')} />
      </Datagrid>
    </List>
  );
};

const authProvider = buildAuthProvider({
  authGroups: ['Admins'],
});

const dataProvider = buildDataProvider(
  {
    queries,
    mutations,
  },
  {
    enableAdminQueries: true,
  },
);

const extendedDataProvider = {
  ...dataProvider,
  getList: async (resource: string, params: GetListParams) => {
    const response = await dataProvider.getList(resource, params);
    const dataWithFullName = response.data.map(item => ({
      ...item,
      fullName: `${item.lastName} ${item.firstName}`,
    }));
    return {...response, data: dataWithFullName};
  },
};

const CustomizedAppBar = () => (
  <AppBar
    sx={{
      '& .RaAppBar-toolbar': {
        display: 'none',
      },
    }}></AppBar>
);

const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      minWidth: '150px',
    },
  },
});

const CustomizedLayout = (props: LayoutProps) => (
  <Layout
    {...props}
    appBar={CustomizedAppBar}
    sx={{
      background: '#f2f2f2',
      overflowX: 'auto',
      minWidth: '100%',
      '& .RaLayout-content': {
        background: '#f2f2f2',
      },
    }}
  />
);

export const AdminScreen = () => {
  const {t} = useTranslation();
  useEffect(() => {
    document.title = `${t('administrator')} | MAST`;
  }, [t]);

  return (
    <>
      <ReactAdmin
        layout={CustomizedLayout}
        basename="/admin"
        authProvider={authProvider}
        dataProvider={extendedDataProvider}>
        <Resource
          name="shipperUsers"
          options={{label: t('shipperUser')}}
          list={ShipperUserList}
        />
        <Resource
          name="forwarderUsers"
          options={{label: t('forwarderUser')}}
          list={ForwarderUserList}
        />
        <Resource
          name="shipperCompanies"
          options={{label: t('shipperCorporation')}}
          list={ShipperCompanyList}
        />
        <Resource
          name="forwarderCompanies"
          options={{label: t('forwarderCorporation')}}
          list={ForwarderCompanyList}
        />
        <Resource
          name="currencies"
          options={{label: t('currency')}}
          create={CurrencyCreate}
          list={CurrencyList}
          edit={CurrencyEdit}
        />
        <Resource
          name="estimates"
          options={{label: t('quotationsList')}}
          list={EstimatesList}
        />
        <Resource
          name="reservations"
          options={{label: t('reservationsList')}}
          list={ReservationsList}
        />
      </ReactAdmin>
    </>
  );
};
