export const airports = [
  {id: 1, name: 'Anaa / AAA / French Polynesia', code: 'AAA'},
  {id: 2, name: 'Abidjan / ABJ / Côte d’Ivoire', code: 'ABJ'},
  {id: 3, name: 'Albuquerque / ABQ', code: 'ABQ'},
  {id: 4, name: 'Abuja / ABV / Nigeria', code: 'ABV'},
  {id: 5, name: 'Aberdeen / ABZ', code: 'ABZ'},
  {id: 6, name: 'Accra / ACC / Ghana', code: 'ACC'},
  {id: 7, name: 'Adana / ADA / Turkey', code: 'ADA'},
  {id: 8, name: 'Izmir / ADB / Turkey', code: 'ADB'},
  {id: 9, name: 'Addis Ababa / ADD / Ethiopia', code: 'ADD'},
  {id: 10, name: 'Adelaide / ADL', code: 'ADL'},
  {id: 11, name: 'Malaga / AGP', code: 'AGP'},
  {id: 12, name: 'Asahikawa / AKJ / Japan', code: 'AKJ'},
  {id: 13, name: 'Auckland / AKL', code: 'AKL'},
  {id: 14, name: 'Almaty / ALA / Kazakhstan', code: 'ALA'},
  {id: 15, name: 'Alger / Algiers / ALG / Algeria', code: 'ALG'},
  {id: 16, name: 'Ahmedabad / AMD', code: 'AMD'},
  {id: 17, name: 'Amman / AMM / Jordan', code: 'AMM'},
  {id: 18, name: 'Amsterdam / AMS', code: 'AMS'},
  {id: 19, name: 'Anchorage / ANC', code: 'ANC'},
  {id: 20, name: 'Ankara / ANK / Turkey', code: 'ANK'},
  {id: 21, name: 'Antwerp / ANR', code: 'ANR'},
  {id: 22, name: 'Antigua and Barbuda / ANU', code: 'ANU'},
  {id: 23, name: 'Aomori / AOJ / Japan', code: 'AOJ'},
  {id: 24, name: 'Stockholm / ARN', code: 'ARN'},
  {id: 25, name: 'Ashkhabad / ASB / Turkmenistan', code: 'ASB'},
  {id: 26, name: 'Asuncion / ASU / Paraguay', code: 'ASU'},
  {id: 27, name: 'Athinai / ATH', code: 'ATH'},
  {id: 28, name: 'Atlanta / ATL', code: 'ATL'},
  {id: 29, name: 'Aruba / AUA', code: 'AUA'},
  {id: 30, name: 'Abu Dhabi / AUH', code: 'AUH'},
  {id: 31, name: 'Akita / AXT / Japan', code: 'AXT'},
  {id: 32, name: 'Bahrain / BAH', code: 'BAH'},
  {id: 33, name: 'Barranquilla / BAQ / Colombia', code: 'BAQ'},
  {id: 34, name: 'Barcelona / BCN', code: 'BCN'},
  {id: 35, name: 'Hamilton / BDA', code: 'BDA'},
  {id: 36, name: 'Belgrade / Beograd / BEG / Serbia', code: 'BEG'},
  {id: 37, name: 'Berlin / BER', code: 'BER'},
  {id: 38, name: 'Beirut / BEY / Lebanon', code: 'BEY'},
  {id: 39, name: 'Belfast / BFS', code: 'BFS'},
  {id: 40, name: 'Baghdad / BGW / Iraq', code: 'BGW'},
  {id: 41, name: 'Birmingham / BHX', code: 'BHX'},
  {id: 42, name: 'Bilbao / BIO', code: 'BIO'},
  {id: 43, name: 'Bujumbura / BJM / Burundi', code: 'BJM'},
  {id: 44, name: 'Leon / BJX', code: 'BJX'},
  {id: 45, name: 'Kota Kinabalu / BKI', code: 'BKI'},
  {id: 46, name: 'Bangkok / BKK / Thai', code: 'BKK'},
  {id: 47, name: 'Bamako / BKO / Mali', code: 'BKO'},
  {id: 48, name: 'Billund / BLL / Denmark', code: 'BLL'},
  {id: 49, name: 'Bologna / BLQ / Italy', code: 'BLQ'},
  {id: 50, name: 'Bangalore / BLR', code: 'BLR'},
  {id: 51, name: 'Nashville / BNA', code: 'BNA'},
  {id: 52, name: 'Brisbane / BNE', code: 'BNE'},
  {id: 53, name: 'Bordeaux / BOD / France', code: 'BOD'},
  {id: 54, name: 'Bogota / BOG', code: 'BOG'},
  {id: 55, name: 'Mumbai / BOM / Bombay', code: 'BOM'},
  {id: 56, name: 'Boston / BOS', code: 'BOS'},
  {id: 57, name: 'Balikpapan / BPN ', code: 'BPN'},
  {id: 58, name: 'Bremen / BRE', code: 'BRE'},
  {id: 59, name: 'Bern / BRN', code: 'BRN'},
  {id: 60, name: 'Brussel / BRU', code: 'BRU'},
  {id: 61, name: 'Basel / BSL', code: 'BSL'},
  {id: 62, name: 'Basrah / BSR / Iraq', code: 'BSR'},
  {id: 63, name: 'Batam / BTH', code: 'BTH'},
  {id: 64, name: 'Bratislava / BTS / ', code: 'BTS'},
  {id: 65, name: 'Bintulu Airport / BTU', code: 'BTU'},
  {id: 66, name: 'Budapest / BUD', code: 'BUD'},
  {id: 67, name: 'Buenos Aires / BUE / Argentina', code: 'BUE'},
  {id: 68, name: 'Buffalo / BUF', code: 'BUF'},
  {id: 69, name: 'Bucuresti / BUH / Romania', code: 'BUH'},
  {id: 70, name: 'Baltimore / BWI ', code: 'BWI'},
  {id: 71, name: 'Bandar Seri Begawan / BWN / Brunei', code: 'BWN'},
  {id: 72, name: 'Cairo / CAI', code: 'CAI'},
  {id: 73, name: 'Guangzhou / CAN', code: 'CAN'},
  {id: 74, name: 'Casablanca / CAS', code: 'CAS'},
  {id: 75, name: 'Caracas / CCS', code: 'CCS'},
  {id: 76, name: 'Kolkata / CCU / Calcutta', code: 'CCU'},
  {id: 77, name: 'Paris / CDG / PAR', code: 'CDG'},
  {id: 78, name: 'Cebu / CEB', code: 'CEB'},
  {id: 79, name: 'Jakarta / CGK / JKT', code: 'CGK'},
  {id: 80, name: 'Cologne Bonn Airport / CGN', code: 'CGN'},
  {id: 81, name: 'Zhengzhou Xinzheng / CGO', code: 'CGO'},
  {id: 82, name: 'Changchun / CGQ', code: 'CGQ'},
  {id: 83, name: 'Christchurch / CHC', code: 'CHC'},
  {id: 84, name: 'Charleston / CHS', code: 'CHS'},
  {id: 85, name: 'Coimbatore / CJB', code: 'CJB'},
  {id: 86, name: 'Jeju / CJU', code: 'CJU'},
  {id: 87, name: 'Chongqing / CKG', code: 'CKG'},
  {id: 88, name: 'Conakry / CKY', code: 'CKY'},
  {id: 89, name: 'Cleveland / CLE', code: 'CLE'},
  {id: 90, name: 'Cali / CLO', code: 'CLO'},
  {id: 91, name: 'Charlotte / CLT', code: 'CLT'},
  {id: 92, name: 'Colombo / CMB', code: 'CMB'},
  {id: 93, name: 'Columbus / CMH', code: 'CMH'},
  {id: 94, name: 'Casablanca / CMN', code: 'CMN'},
  {id: 95, name: 'Confins / CNF / Belo Horizonte', code: 'CNF'},
  {id: 96, name: 'Chiang Mai / CNX / Thai', code: 'CNX'},
  {id: 97, name: 'Cochin / COK', code: 'COK'},
  {id: 98, name: 'Cotonou / COO', code: 'COO'},
  {id: 99, name: 'Copenhagen / CPH', code: 'CPH'},
  {id: 100, name: 'Cape Town / CPT', code: 'CPT'},
  {id: 101, name: 'Clark / CRK / Philippines', code: 'CRK'},
  {id: 102, name: 'Yeager / CRW / Charleston', code: 'CRW'},
  {id: 103, name: 'Changsha / CSX', code: 'CSX'},
  {id: 104, name: 'Chitose / CTS / Sapporo / Japan', code: 'CTS'},
  {id: 105, name: 'Chengdu / CTU', code: 'CTU'},
  {id: 106, name: 'Cancun / CUN', code: 'CUN'},
  {id: 107, name: 'Curacao / CUR', code: 'CUR'},
  {id: 108, name: 'Cincinnati / CVG', code: 'CVG'},
  {id: 109, name: 'Curitiba / CWB', code: 'CWB'},
  {id: 110, name: 'Dhaka / DAC', code: 'DAC'},
  {id: 111, name: 'DaNang / DAD', code: 'DAD'},
  {id: 112, name: 'Dar es Salaam / DAR', code: 'DAR'},
  {id: 113, name: 'Delhi / DEL', code: 'DEL'},
  {id: 114, name: 'Denver / DEN', code: 'DEN'},
  {id: 115, name: 'Dallas / DFW', code: 'DFW'},
  {id: 116, name: 'Dongguan / DGM', code: 'DGM'},
  {id: 117, name: 'Dakar / DKR', code: 'DKR'},
  {id: 118, name: 'Douala / DLA', code: 'DLA'},
  {id: 119, name: 'Dalian / DLC', code: 'DLC'},
  {id: 120, name: 'Domodedovo / DME / Moscow', code: 'DME'},
  {id: 121, name: 'Don Mueang / DMK', code: 'DMK'},
  {id: 122, name: 'Dammam / DMM / Saudi Arabia', code: 'DMM'},
  {id: 123, name: 'Doha / DOH', code: 'DOH'},
  {id: 124, name: 'Denpasar / DPS / Bali', code: 'DPS'},
  {id: 125, name: 'Dresden / DRS', code: 'DRS'},
  {id: 126, name: 'Darwin / DRW', code: 'DRW'},
  {id: 127, name: 'Blaise Diagne / DSS / Senegal', code: 'DSS'},
  {id: 128, name: 'Dortmund / DTM', code: 'DTM'},
  {id: 129, name: 'Detroit  / DTT', code: 'DTT'},
  {id: 130, name: 'Wayne County / DTW / Detroit', code: 'DTW'},
  {id: 131, name: 'Dublin / DUB', code: 'DUB'},
  {id: 132, name: 'Durban / DUR', code: 'DUR'},
  {id: 133, name: 'Dusseldorf / DUS', code: 'DUS'},
  {id: 134, name: 'Davao / DVO / Mindanao', code: 'DVO'},
  {id: 135, name: 'Dubai World Central / DWC', code: 'DWC'},
  {id: 136, name: 'Dubai / DXB', code: 'DXB'},
  {id: 137, name: 'Dushanbe / DYU', code: 'DYU'},
  {id: 138, name: 'Entebbe / EBB', code: 'EBB'},
  {id: 139, name: 'Erbil / EBL / Iraq', code: 'EBL'},
  {id: 140, name: 'Edinburgh / EDI', code: 'EDI'},
  {id: 141, name: 'Beef Island / EIS / Virgin Islands', code: 'EIS'},
  {id: 142, name: 'El Paso / ELP', code: 'ELP'},
  {id: 143, name: 'Esenboga / ESB / Ankara', code: 'ESB'},
  {id: 144, name: 'New York / EWR / Newark', code: 'EWR'},
  {id: 145, name: 'Buenos Aires / EZE', code: 'EZE'},
  {id: 146, name: 'Lubumbashi / FBM', code: 'FBM'},
  {id: 147, name: 'Kinshasa / FIH', code: 'FIH'},
  {id: 148, name: 'Fukushima / FKS / Japan', code: 'FKS'},
  {id: 149, name: 'Freetown / FNA', code: 'FNA'},
  {id: 150, name: 'Fuzhou  / FOC', code: 'FOC'},
  {id: 151, name: 'Fortaleza / FOR', code: 'FOR'},
  {id: 152, name: 'Frankfurt / FRA / Main', code: 'FRA'},
  {id: 153, name: 'Bishkek / FRU / Frunze', code: 'FRU'},
  {id: 154, name: 'Shizuoka / FSZ / Japan', code: 'FSZ'},
  {id: 155, name: 'Fort Worth Meacham / FTW', code: 'FTW'},
  {id: 156, name: 'Fukuoka / FUK / Japan', code: 'FUK'},
  {id: 157, name: 'Cayman / GCM', code: 'GCM'},
  {id: 158, name: 'Guadalajara / GDL', code: 'GDL'},
  {id: 159, name: 'Gdansk / GDN', code: 'GDN'},
  {id: 160, name: 'Georgetown / GEO', code: 'GEO'},
  {id: 161, name: 'Galeao / GIG / Rio de Janeiro', code: 'GIG'},
  {id: 162, name: 'Glasgow / GLA', code: 'GLA'},
  {id: 163, name: 'Gimpo / GMP / Seoul', code: 'GMP'},
  {id: 164, name: 'Genova / GOA', code: 'GOA'},
  {id: 165, name: 'Goa / GOI', code: 'GOI'},
  {id: 166, name: 'Nizhniy Novgorod / GOJ / Gorkiy', code: 'GOJ'},
  {id: 167, name: 'Goteborg / GOT', code: 'GOT'},
  {id: 168, name: 'Guarulhos / GRU / Sao Paulo', code: 'GRU'},
  {id: 169, name: 'Piedmont Triad / GSO', code: 'GSO'},
  {id: 170, name: 'Guatemala City / GUA', code: 'GUA'},
  {id: 171, name: 'Guam / GUM', code: 'GUM'},
  {id: 172, name: 'Atyrau / GUW / Guryev', code: 'GUW'},
  {id: 173, name: 'Geneve / GVA', code: 'GVA'},
  {id: 174, name: 'Heydar Aliyev / GYD / Azerbaijan', code: 'GYD'},
  {id: 175, name: 'Guayaquil / GYE', code: 'GYE'},
  {id: 176, name: 'Goiania / GYN', code: 'GYN'},
  {id: 177, name: 'Hannover / HAJ', code: 'HAJ'},
  {id: 178, name: 'Haikou / HAK', code: 'HAK'},
  {id: 179, name: 'Hamburg / HAM', code: 'HAM'},
  {id: 180, name: 'Hanoi / HAN', code: 'HAN'},
  {id: 181, name: 'La Habana / HAV', code: 'HAV'},
  {id: 182, name: 'Hobart / HBA', code: 'HBA'},
  {id: 183, name: 'Helsinki / HEL', code: 'HEL'},
  {id: 184, name: 'Hefei Xinqiao  / HFE', code: 'HFE'},
  {id: 185, name: 'Hangzhou / HGH', code: 'HGH'},
  {id: 186, name: 'Hiroshima / HIJ / Japan', code: 'HIJ'},
  {id: 187, name: 'Honiara / HIR / Solomon Islands', code: 'HIR'},
  {id: 188, name: 'Hakodate / HKD / Japan', code: 'HKD'},
  {id: 189, name: 'Hong Kong / HKG', code: 'HKG'},
  {id: 190, name: 'Hanamaki / HNA / Japan', code: 'HNA'},
  {id: 191, name: 'Haneda / HND / Tokyo / Japan', code: 'HND'},
  {id: 192, name: 'Honolulu / HNL', code: 'HNL'},
  {id: 193, name: 'Houston / HOU', code: 'HOU'},
  {id: 194, name: 'Harbin / HRB', code: 'HRB'},
  {id: 195, name: 'Saga / HSG / Japan', code: 'HSG'},
  {id: 196, name: 'Huntsville / HSV', code: 'HSV'},
  {id: 197, name: 'Hyderabad / HYD', code: 'HYD'},
  {id: 198, name: 'Washington / IAD', code: 'IAD'},
  {id: 199, name: 'Houston / IAH', code: 'IAH'},
  {id: 200, name: 'Ibaraki / IBR / Japan', code: 'IBR'},
  {id: 201, name: 'Incheon / ICN / Seoul', code: 'ICN'},
  {id: 202, name: 'Irkutsk / IKT', code: 'IKT'},
  {id: 203, name: 'Yinchuan Hedong / INC', code: 'INC'},
  {id: 204, name: 'Islamabad / ISB', code: 'ISB'},
  {id: 205, name: 'Istanbul / IST', code: 'IST'},
  {id: 206, name: 'Jacksonville / JAX', code: 'JAX'},
  {id: 207, name: 'Jeddah / JED', code: 'JED'},
  {id: 208, name: 'New York / JFK / John F.Kennedy', code: 'JFK'},
  {id: 209, name: 'Johor Bahru / JHB', code: 'JHB'},
  {id: 210, name: 'Djibouti / JIB', code: 'JIB'},
  {id: 211, name: 'Johannesburg / JNB', code: 'JNB'},
  {id: 212, name: 'Kilimanjaro / JRO', code: 'JRO'},
  {id: 213, name: 'Kabul / KBL', code: 'KBL'},
  {id: 214, name: 'Borispol / KBP / Kiev', code: 'KBP'},
  {id: 215, name: 'Kuching / KCH / Sarawak', code: 'KCH'},
  {id: 216, name: 'Kigali / KGL', code: 'KGL'},
  {id: 217, name: 'Kaohsiung / KHH / Taiwan', code: 'KHH'},
  {id: 218, name: 'Karachi / KHI', code: 'KHI'},
  {id: 219, name: 'Nanchang Changbei / KHN', code: 'KHN'},
  {id: 220, name: 'Khabarovsk / KHV', code: 'KHV'},
  {id: 221, name: 'Niigata / KIJ / Japan', code: 'KIJ'},
  {id: 222, name: 'Kingston / KIN', code: 'KIN'},
  {id: 223, name: 'Kansai / KIX / Osaka / Japan', code: 'KIX'},
  {id: 224, name: 'Kitakyushu / KKJ / Fukuoka / Japan', code: 'KKJ'},
  {id: 225, name: 'Kirkenes / KKN', code: 'KKN'},
  {id: 226, name: 'Kunming / KMG', code: 'KMG'},
  {id: 227, name: 'Miyazaki / KMI / Japan', code: 'KMI'},
  {id: 228, name: 'Kumamoto / KMJ / Japan', code: 'KMJ'},
  {id: 229, name: 'Komatsu / KMQ / Japan', code: 'KMQ'},
  {id: 230, name: 'Kagoshima / KOJ / Japan', code: 'KOJ'},
  {id: 231, name: 'Krakow / KRK', code: 'KRK'},
  {id: 232, name: 'Khartoum / KRT', code: 'KRT'},
  {id: 233, name: 'Kathmandu / KTM', code: 'KTM'},
  {id: 234, name: 'Katowice / KTW', code: 'KTW'},
  {id: 235, name: 'Kuala Lumpur / KUL', code: 'KUL'},
  {id: 236, name: 'Kuwait / KWI', code: 'KWI'},
  {id: 237, name: 'Luanda / LAD', code: 'LAD'},
  {id: 238, name: 'Las Vegas / LAS', code: 'LAS'},
  {id: 239, name: 'Los Angeles / LAX', code: 'LAX'},
  {id: 240, name: 'Labuan / LBU / Malaysia', code: 'LBU'},
  {id: 241, name: 'Larnaca / LCA', code: 'LCA'},
  {id: 242, name: 'La Coruna  / LCG / A Coruna', code: 'LCG'},
  {id: 243, name: 'Saint Petersburg / LED / Leningrad', code: 'LED'},
  {id: 244, name: 'Leipzig / LEJ', code: 'LEJ'},
  {id: 245, name: 'New York / LGA / LaGuardia', code: 'LGA'},
  {id: 246, name: 'Lahore / LHE', code: 'LHE'},
  {id: 247, name: 'London / LHR / LON', code: 'LHR'},
  {id: 248, name: 'Lille-Lesquin / LIL', code: 'LIL'},
  {id: 249, name: 'Lima / LIM', code: 'LIM'},
  {id: 250, name: 'Linate / LIN / Milan', code: 'LIN'},
  {id: 251, name: 'Lisboa / LIS', code: 'LIS'},
  {id: 252, name: 'Little Rock / LIT', code: 'LIT'},
  {id: 253, name: 'Ljubljana / LJU', code: 'LJU'},
  {id: 254, name: 'Lilongwe / LLW', code: 'LLW'},
  {id: 255, name: 'Linz / LNZ', code: 'LNZ'},
  {id: 256, name: 'Lagos / LOS / Nigeria', code: 'LOS'},
  {id: 257, name: 'Monclova / LOV', code: 'LOV'},
  {id: 258, name: 'Las Palmas de Gran Canaria / LPA', code: 'LPA'},
  {id: 259, name: 'La Paz / LPB', code: 'LPB'},
  {id: 260, name: 'Laredo / LRD', code: 'LRD'},
  {id: 261, name: 'Lusaka / LUN', code: 'LUN'},
  {id: 262, name: 'Luxembourg / LUX', code: 'LUX'},
  {id: 263, name: 'Lyon / LYS', code: 'LYS'},
  {id: 264, name: 'Chennai / MAA / Madras', code: 'MAA'},
  {id: 265, name: 'Madrid / MAD', code: 'MAD'},
  {id: 266, name: 'Manchester / MAN', code: 'MAN'},
  {id: 267, name: 'Manaus / MAO', code: 'MAO'},
  {id: 268, name: 'Kansas City / MCI', code: 'MCI'},
  {id: 269, name: 'Orlando / MCO', code: 'MCO'},
  {id: 270, name: 'Muscat / MCT', code: 'MCT'},
  {id: 271, name: 'Melbourne / MEL', code: 'MEL'},
  {id: 272, name: 'Memphis / MEM', code: 'MEM'},
  {id: 273, name: 'Mexico City / MEX', code: 'MEX'},
  {id: 274, name: 'Macau / MFM', code: 'MFM'},
  {id: 275, name: 'Managua / MGA', code: 'MGA'},
  {id: 276, name: 'Mogadishu / MGQ', code: 'MGQ'},
  {id: 277, name: 'Miami / MIA', code: 'MIA'},
  {id: 278, name: 'Merida / MID', code: 'MID'},
  {id: 279, name: 'Luqa / MLA', code: 'MLA'},
  {id: 280, name: 'Male / MLE', code: 'MLE'},
  {id: 281, name: 'Miles City / MLS', code: 'MLS'},
  {id: 282, name: 'Malmo Bulltofta / MMA / Sweden', code: 'MMA'},
  {id: 283, name: 'Malmo / MMX', code: 'MMX'},
  {id: 284, name: 'Manila / MNL', code: 'MNL'},
  {id: 285, name: 'Maputo / MPM', code: 'MPM'},
  {id: 286, name: 'Marseille / MRS', code: 'MRS'},
  {id: 287, name: 'Sir Seewoosagur Ramgoolam / MRU / Mauritius', code: 'MRU'},
  {id: 288, name: 'Minneapolis / MSP', code: 'MSP'},
  {id: 289, name: 'Minsk / MSQ', code: 'MSQ'},
  {id: 290, name: 'Maseru / MSU', code: 'MSU'},
  {id: 291, name: 'New Orleans / MSY', code: 'MSY'},
  {id: 292, name: 'Monterrey / MTY', code: 'MTY'},
  {id: 293, name: 'Munchen / MUC', code: 'MUC'},
  {id: 294, name: 'Montevideo / MVD', code: 'MVD'},
  {id: 295, name: 'Milan / MXP / Malpensa', code: 'MXP'},
  {id: 296, name: 'Matsuyama / MYJ / Japan', code: 'MYJ'},
  {id: 297, name: 'Miri / MYY', code: 'MYY'},
  {id: 298, name: 'Nadi / NAN / Fiji', code: 'NAN'},
  {id: 299, name: 'Napoli / NAP', code: 'NAP'},
  {id: 300, name: 'Nairobi / NBO / Kenya', code: 'NBO'},
  {id: 301, name: 'Nice / NCE', code: 'NCE'},
  {id: 302, name: 'Newcastle upon Tyne / NCL', code: 'NCL'},
  {id: 303, name: 'Ningbo / NGB', code: 'NGB'},
  {id: 304, name: 'Nagoya / NGO / Chubu Centrair / Japan', code: 'NGO'},
  {id: 305, name: 'Nagasaki / NGS / Japan', code: 'NGS'},
  {id: 306, name: 'Nouakchott / NKC', code: 'NKC'},
  {id: 307, name: 'Nanjing / NKG', code: 'NKG'},
  {id: 308, name: 'Mexico City / NLU / Felipe Angeles', code: 'NLU'},
  {id: 309, name: 'Nanning / NNG', code: 'NNG'},
  {id: 310, name: 'Narita / NRT / Tokyo / Japan', code: 'NRT'},
  {id: 311, name: 'Nelson / NSN', code: 'NSN'},
  {id: 312, name: 'Nantes / NTE', code: 'NTE'},
  {id: 313, name: 'Nantong / NTG', code: 'NTG'},
  {id: 314, name: 'Nurnberg / NUE', code: 'NUE'},
  {id: 315, name: 'Navegantes / NVT', code: 'NVT'},
  {id: 316, name: 'Oita / OIT / Japan', code: 'OIT'},
  {id: 317, name: 'Okinawa / OKA / Naha / Japan', code: 'OKA'},
  {id: 318, name: 'Okayama / OKJ / Japan', code: 'OKJ'},
  {id: 319, name: 'Porto / OPO', code: 'OPO'},
  {id: 320, name: "Chicago / ORD / O'Hare", code: 'ORD'},
  {id: 321, name: 'Norfolk / ORF', code: 'ORF'},
  {id: 322, name: 'Cork / ORK', code: 'ORK'},
  {id: 323, name: 'Oran / ORN', code: 'ORN'},
  {id: 324, name: 'Oslo / OSL', code: 'OSL'},
  {id: 325, name: 'Bucharest Henri Coandă / OTP / Romania', code: 'OTP'},
  {id: 326, name: 'Ouagadougou / OUA', code: 'OUA'},
  {id: 327, name: 'Novosibirsk / OVB', code: 'OVB'},
  {id: 328, name: 'Port-au-Prince / PAP', code: 'PAP'},
  {id: 329, name: 'Paro / PBH', code: 'PBH'},
  {id: 330, name: 'Portland / PDX', code: 'PDX'},
  {id: 331, name: 'Beijing / PEK / BJS', code: 'PEK'},
  {id: 332, name: 'Penang / PEN', code: 'PEN'},
  {id: 333, name: 'Perth / PER', code: 'PER'},
  {id: 334, name: 'Peshawar / PEW', code: 'PEW'},
  {id: 335, name: 'Philadelphia / PHL', code: 'PHL'},
  {id: 336, name: 'Phoenix / PHX', code: 'PHX'},
  {id: 337, name: 'Pittsburgh / PIT', code: 'PIT'},
  {id: 338, name: 'Port Elizabeth / PLZ', code: 'PLZ'},
  {id: 339, name: 'Puerto Montt / PMC', code: 'PMC'},
  {id: 340, name: 'Palmerston North / PMR', code: 'PMR'},
  {id: 341, name: 'Phnom Penh / PNH', code: 'PNH'},
  {id: 342, name: 'Porto Alegre / POA', code: 'POA'},
  {id: 343, name: 'Port Moresby / POM', code: 'POM'},
  {id: 344, name: 'Port-of-Spain / POS', code: 'POS'},
  {id: 345, name: 'Poznan / POZ', code: 'POZ'},
  {id: 346, name: 'Tahiti / PPT', code: 'PPT'},
  {id: 347, name: 'Praha / PRG', code: 'PRG'},
  {id: 348, name: 'Pisa / PSA', code: 'PSA'},
  {id: 349, name: 'Point-a-Pitre / PTP', code: 'PTP'},
  {id: 350, name: 'Tocumen / PTY / Panama', code: 'PTY'},
  {id: 351, name: 'Busan / PUS', code: 'PUS'},
  {id: 352, name: 'Pudong / PVG / Shanghai', code: 'PVG'},
  {id: 353, name: 'Recife / REC', code: 'REC'},
  {id: 354, name: 'Yangon / RGN', code: 'RGN'},
  {id: 355, name: 'Rio de Janeiro / RIO', code: 'RIO'},
  {id: 356, name: 'Riga / RIX', code: 'RIX'},
  {id: 357, name: 'Roberts / ROB / Liberia', code: 'ROB'},
  {id: 358, name: 'Rochester / ROC', code: 'ROC'},
  {id: 359, name: 'Rome / ROM / FCO', code: 'ROM'},
  {id: 360, name: 'Koror / ROR', code: 'ROR'},
  {id: 361, name: 'Rotterdam / RTM', code: 'RTM'},
  {id: 362, name: 'Riyadh / RUH', code: 'RUH'},
  {id: 363, name: 'Saint-Denis / RUN', code: 'RUN'},
  {id: 364, name: 'San Salvador / SAL', code: 'SAL'},
  {id: 365, name: 'San Diego / SAN', code: 'SAN'},
  {id: 366, name: 'San Pedro Sula / SAP', code: 'SAP'},
  {id: 367, name: 'Sabiha Gokcen / SAW / Istanbul', code: 'SAW'},
  {id: 368, name: 'Stockton / SCK', code: 'SCK'},
  {id: 369, name: 'Santiago / SCL', code: 'SCL'},
  {id: 370, name: 'Aktau / SCO', code: 'SCO'},
  {id: 371, name: 'Louisville / SDF', code: 'SDF'},
  {id: 372, name: 'Sendai / SDJ / Japan', code: 'SDJ'},
  {id: 373, name: 'Santo Domingo / SDQ', code: 'SDQ'},
  {id: 374, name: 'Seattle / SEA', code: 'SEA'},
  {id: 375, name: 'Mahe Island / SEZ', code: 'SEZ'},
  {id: 376, name: 'San Francisco / SFO', code: 'SFO'},
  {id: 377, name: 'HoChiMinh City / SGN', code: 'SGN'},
  {id: 378, name: 'Hongqiao / SHA / Shanghai', code: 'SHA'},
  {id: 379, name: 'Shenyang / SHE', code: 'SHE'},
  {id: 380, name: 'Sharjah / SHJ', code: 'SHJ'},
  {id: 381, name: 'Singapore / SIN', code: 'SIN'},
  {id: 382, name: 'San Jose / SJC', code: 'SJC'},
  {id: 383, name: 'Sarajevo / SJJ', code: 'SJJ'},
  {id: 384, name: 'San Jose / SJO', code: 'SJO'},
  {id: 385, name: 'San Juan / SJU', code: 'SJU'},
  {id: 386, name: 'Thessaloniki / SKG', code: 'SKG'},
  {id: 387, name: 'Skopje / SKP', code: 'SKP'},
  {id: 388, name: 'Salt Lake City / SLC', code: 'SLC'},
  {id: 389, name: 'Sofia / SOF', code: 'SOF'},
  {id: 390, name: 'Southampton / SOU', code: 'SOU'},
  {id: 391, name: 'Saipan / SPN', code: 'SPN'},
  {id: 392, name: 'Semarang / SRG', code: 'SRG'},
  {id: 393, name: 'Santa Cruz / SRZ', code: 'SRZ'},
  {id: 394, name: 'Salvador / SSA', code: 'SSA'},
  {id: 395, name: 'Malabo / SSG', code: 'SSG'},
  {id: 396, name: 'Cibao / STI / Dominican Republic', code: 'STI'},
  {id: 397, name: 'Saint Louis / STL', code: 'STL'},
  {id: 398, name: 'Stockholm / STO', code: 'STO'},
  {id: 399, name: 'Stuttgart / STR', code: 'STR'},
  {id: 400, name: 'Surabaya / SUB', code: 'SUB'},
  {id: 401, name: 'Sola / SVG', code: 'SVG'},
  {id: 402, name: 'Sheremetyevo / SVO / Moscow', code: 'SVO'},
  {id: 403, name: 'Strasbourg / SXB', code: 'SXB'},
  {id: 404, name: 'Sint-Maarten / SXM', code: 'SXM'},
  {id: 405, name: 'Sydney / SYD', code: 'SYD'},
  {id: 406, name: 'Salzburg / SZG', code: 'SZG'},
  {id: 407, name: 'Suzhou Guangfu / SZV', code: 'SZV'},
  {id: 408, name: 'Shenzhen / SZX', code: 'SZX'},
  {id: 409, name: 'Takamatsu / TAK / Japan', code: 'TAK'},
  {id: 410, name: 'Qingdao / TAO', code: 'TAO'},
  {id: 411, name: 'Tashkent / TAS', code: 'TAS'},
  {id: 412, name: 'Tbilisi / TBS', code: 'TBS'},
  {id: 413, name: 'Nukualofa / TBU', code: 'TBU'},
  {id: 414, name: 'Tenerife North / TFN / Spain', code: 'TFN'},
  {id: 415, name: 'Podgorica / TGD', code: 'TGD'},
  {id: 416, name: 'Tallinn / TLL', code: 'TLL'},
  {id: 417, name: 'Toulouse / TLS', code: 'TLS'},
  {id: 418, name: 'Tel Aviv / TLV / Israel', code: 'TLV'},
  {id: 419, name: 'Jinan / TNA', code: 'TNA'},
  {id: 420, name: 'Antananarivo / TNR', code: 'TNR'},
  {id: 421, name: 'Toyama / TOY / Japan', code: 'TOY'},
  {id: 422, name: 'Tampa / TPA', code: 'TPA'},
  {id: 423, name: 'Taipei / TPE / Taiwan', code: 'TPE'},
  {id: 424, name: 'Torino / TRN', code: 'TRN'},
  {id: 425, name: 'Thiruvananthapuram / TRV / Trivandrum', code: 'TRV'},
  {id: 426, name: 'Tianjin / TSN', code: 'TSN'},
  {id: 427, name: 'Tunis / TUN', code: 'TUN'},
  {id: 428, name: 'Berlin-Tegel / TXL', code: 'TXL'},
  {id: 429, name: 'Taiyuan Wusu / TYN', code: 'TYN'},
  {id: 430, name: 'Knoxville / TYS', code: 'TYS'},
  {id: 431, name: 'Chinggis / Ulaanbaatar / UBN / Mongolia', code: 'UBN'},
  {id: 432, name: 'Ufa / UFA', code: 'UFA'},
  {id: 433, name: 'Quito / UIO', code: 'UIO'},
  {id: 434, name: 'Ulaanbaatar / ULN / Mongolia', code: 'ULN'},
  {id: 435, name: 'Urumqi Diwobao / URC', code: 'URC'},
  {id: 436, name: 'Yuzhno-Sakhalinsk / UUS', code: 'UUS'},
  {id: 437, name: 'Hewanorra  / UVF', code: 'UVF'},
  {id: 438, name: 'Venezia / VCE', code: 'VCE'},
  {id: 439, name: 'Viracopos / VCP / Sao Paulo', code: 'VCP'},
  {id: 440, name: 'Vienna Danubepier Hov / VIE', code: 'VIE'},
  {id: 441, name: 'Valencia / VLC', code: 'VLC'},
  {id: 442, name: 'Vilnius / VNO', code: 'VNO'},
  {id: 443, name: 'Vientiane / VTE', code: 'VTE'},
  {id: 444, name: 'Viru Viru / VVI', code: 'VVI'},
  {id: 445, name: 'Vladivostok / VVO', code: 'VVO'},
  {id: 446, name: 'Warsaw / WAW', code: 'WAW'},
  {id: 447, name: 'Windhoek / WDH', code: 'WDH'},
  {id: 448, name: 'Weihai Dashuipo / WEH', code: 'WEH'},
  {id: 449, name: 'Wroclaw / WRO', code: 'WRO'},
  {id: 450, name: 'Wuhan / WUH', code: 'WUH'},
  {id: 451, name: 'Wuxi / WUX', code: 'WUX'},
  {id: 452, name: 'Xian / XIY', code: 'XIY'},
  {id: 453, name: 'Xiamen / XMN', code: 'XMN'},
  {id: 454, name: 'Edmonton / YEG', code: 'YEG'},
  {id: 455, name: 'Yonago / YGJ / Japan', code: 'YGJ'},
  {id: 456, name: 'Hamilton / YHM', code: 'YHM'},
  {id: 457, name: 'Halifax / YHZ', code: 'YHZ'},
  {id: 458, name: 'Yantai / YNT', code: 'YNT'},
  {id: 459, name: 'Ottawa / YOW', code: 'YOW'},
  {id: 460, name: 'Quebec / YQB', code: 'YQB'},
  {id: 461, name: 'Montreal / YUL', code: 'YUL'},
  {id: 462, name: 'Vancouver / YVR', code: 'YVR'},
  {id: 463, name: 'Winnipeg / YWG', code: 'YWG'},
  {id: 464, name: 'Calgary / YYC', code: 'YYC'},
  {id: 465, name: 'Toronto / YYZ', code: 'YYZ'},
  {id: 466, name: 'Zagreb / ZAG', code: 'ZAG'},
  {id: 467, name: 'Harbour Huangpu / ZMY', code: 'ZMY'},
  {id: 468, name: 'Zanzibar / ZNZ', code: 'ZNZ'},
  {id: 469, name: 'Zurich / ZRH', code: 'ZRH'},
] as const;
