import {Checkbox, FormControlLabel, TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {DocumentInput} from '../API';

type DocumentFormProps = {
  document: DocumentInput | null;
  handleDocumentChange: (newDocument: DocumentInput) => void;
};

export const DocumentForm: React.FC<DocumentFormProps> = ({
  document,
  handleDocumentChange,
}) => {
  const {t} = useTranslation();
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={document?.invoice || false}
            onChange={() => {
              handleDocumentChange({
                ...document,
                invoice: !document?.invoice,
              });
            }}
          />
        }
        label={t('invoice')}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={document?.packingList || false}
            onChange={() => {
              handleDocumentChange({
                ...document,
                packingList: !document?.packingList,
              });
            }}
          />
        }
        label={t('packingList')}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={document?.nonApplicabilityCertificate || false}
            onChange={() => {
              handleDocumentChange({
                ...document,
                nonApplicabilityCertificate:
                  !document?.nonApplicabilityCertificate,
              });
            }}
          />
        }
        label={t('nonWoodCertificate')}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={document?.msds || false}
            onChange={() => {
              handleDocumentChange({...document, msds: !document?.msds});
            }}
          />
        }
        label={t('originCertificate')}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={document?.other || false}
            onChange={() => {
              handleDocumentChange({...document, other: !document?.other});
            }}
          />
        }
        label={t('other')}
      />
      <TextField
        size="small"
        id="input-textfield"
        variant="outlined"
        fullWidth
        value={document?.detail}
        disabled={!document?.other}
        onChange={event => {
          handleDocumentChange({...document, detail: event.target.value});
        }}
      />
    </>
  );
};
