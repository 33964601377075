import 'react-phone-input-2/lib/style.css';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import {Stack, Typography, Grid, Divider, Box, Button} from '@mui/material';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {EstimateInputForm} from './CreateEstimate';
import {EstimateStatus} from '../../API';
import LoadingButton from '../../components/LoadingButton';
import {Title} from '../../components/Title';
import {airports} from '../../data/airports';
import Inner from '../../layouts/Inner';
import {useOAuthUserState} from '../../providers/AuthProvider';
import {useCurrencies} from '../../queries/currency';
import {useCreateCustomFeeMutation} from '../../queries/customFee';
import {useCreateEstimateMutation} from '../../queries/estimate';
import {OmitedCustomFee} from '../../types/index';
import {getLocalizedCalculationMethod} from '../../utils/getLocalizedCalculationMethod';
import {getLocalizedCargoType} from '../../utils/getLocalizedCargoType';
import {getLocalizedLeadTime} from '../../utils/getLocalizedLeadTime';
import {toAWSDate} from '../../utils/toAWSDate';

export const CreateEstimateConfirmationScreen = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const customFees = location.state.estimate.customItems as OmitedCustomFee[];
  const estimate = location.state.estimate as EstimateInputForm;
  const userState = useOAuthUserState();
  const {mutateAsync: createEstimateMutation} = useCreateEstimateMutation();
  const {mutateAsync: createCustomFeeMutation} = useCreateCustomFeeMutation();
  const navigate = useNavigate();
  const {data: currencies} = useCurrencies();

  const [isLoading, setIsLoading] = useState(false);

  const createEstimate = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await createEstimateMutation({
        input: {
          departure: estimate.departure,
          arrival: estimate.arrival,
          airline: estimate.airline,
          flightDate: toAWSDate(dayjs(estimate.flightDate).toDate()),
          directFlight: estimate.directFlight,
          leadTime: estimate.leadTime,
          useSaf: estimate.useSaf,
          cargoType: estimate.cargoType,
          yardCutDate: toAWSDate(dayjs(estimate.yardCutDate).toDate()),
          carrierShedCutDate: toAWSDate(
            dayjs(estimate.carrierShedCutDate).toDate(),
          ),
          estimateCurrencyId: estimate.estimateCurrencyId,
          minimum: estimate.minimum,
          under45: estimate.under45,
          over45: estimate.over45,
          over100: estimate.over100,
          over300: estimate.over300,
          over500: estimate.over500,
          over1000: estimate.over1000,
          fsc: estimate.fsc,
          remark: estimate.remark,
          status: EstimateStatus.OPEN,
          forwarderUserEstimatesId: userState.user?.attributes.sub,
        },
      });

      if (!response) {
        return navigate('error');
      }

      for (const customFee of customFees) {
        await createCustomFeeMutation({
          input: {
            title: customFee.title,
            unitPrice: customFee.unitPrice,
            unitType: customFee.unitType,
            calculationMethod: customFee.calculationMethod,
            minimum: customFee.minimum,
            maximum: customFee.maximum,
            includeTax: customFee.includeTax,
            taxRate: customFee.taxRate,
            remark: customFee.remark,
            estimateId: response.id,
            customFeeCurrencyId: customFee.customFeeCurrencyId,
          },
        });
      }

      navigate('/estimateList');
    } catch (err) {
      Sentry.captureException(err);
      navigate('error');
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [
    estimate,
    createCustomFeeMutation,
    createEstimateMutation,
    customFees,
    navigate,
    userState.user?.attributes.sub,
  ]);

  useEffect(() => {
    document.title = `${t('quotationRegistration')} | MAST`;
  }, [t]);

  return (
    <>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Stack width="770px">
          <Title title={t('quotationRegistration')} />
        </Stack>
        <Stack
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt={3}>
          <Inner>
            <Stack spacing={3}>
              <Stack
                flexDirection="column"
                sx={{
                  width: '690px',
                  gap: '12px',
                }}>
                <Stack
                  flexDirection="row"
                  sx={{width: '665px', height: '74px'}}>
                  <Stack
                    direction="column"
                    width="246px"
                    height="78px"
                    justifyContent="center">
                    <Typography variant="h5">{t('originAirport')}</Typography>

                    <Stack
                      direction="row"
                      sx={{width: '246px', height: '27px', gap: '3px'}}>
                      <FlightTakeoffIcon
                        sx={{
                          fontSize: '19px',
                          paddingLeft: '4px',
                        }}
                      />
                      <Typography variant="body2">
                        {airports.find(
                          airport => airport.code === estimate.departure,
                        )?.name ?? ''}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="column"
                    width="246px"
                    height="78px"
                    justifyContent="center">
                    <Typography variant="h5">
                      {t('destinationAirport')}
                    </Typography>
                    <Stack
                      direction="row"
                      sx={{width: '246px', height: '27px', gap: '3px'}}>
                      <FlightTakeoffIcon
                        sx={{
                          fontSize: '19px',
                          paddingLeft: '4px',
                        }}
                      />
                      <Typography variant="body2">
                        {airports.find(
                          airport => airport.code === estimate.arrival,
                        )?.name ?? ''}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Divider />
                <Stack
                  direction="row"
                  alignItems="center"
                  width="713px"
                  height="106px">
                  <Grid container spacing="12px">
                    <Grid item xs={4}>
                      <Stack
                        direction="column"
                        width="100%"
                        height="100%"
                        gap="9px">
                        <Typography variant="h5">{t('airline')}</Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            padding: '7px 0px 7px 12px',
                          }}>
                          {estimate.airline}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack direction="column" gap="9px">
                        <Typography variant="h5">{t('etd')}</Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            padding: '7px 0px 7px 12px',
                          }}>
                          {estimate.flightDate}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
                <Divider />
                <Stack
                  direction="row"
                  alignItems="center"
                  width="713px"
                  height="106px">
                  <Grid container spacing="13px">
                    <Grid item xs={4}>
                      <Stack direction="column" gap="9px">
                        <Typography variant="h5">{t('flightType')}</Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            padding: '7px 0px 7px 12px',
                          }}>
                          {(() => {
                            if (estimate.directFlight === true) {
                              return t('directFlight');
                            } else {
                              return t('connectingFlight');
                            }
                          })()}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack direction="column" gap="9px">
                        <Typography variant="h5">{t('leadTime')}</Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            padding: '7px 0px 7px 12px',
                          }}>
                          {getLocalizedLeadTime(estimate.leadTime)}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack direction="column" gap="9px">
                        <Typography variant="h5">{t('saf')}</Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            padding: '7px 0px 7px 12px',
                          }}>
                          {(() => {
                            if (estimate.useSaf === true) {
                              return t('use');
                            } else {
                              return t('nonUse');
                            }
                          })()}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
                <Divider />
                <Stack
                  direction="row"
                  alignItems="center"
                  width="713px"
                  height="106px">
                  <Grid container>
                    <Grid item xs={12}>
                      <Stack direction="column" gap="9px">
                        <Typography variant="h5">
                          {t('commodityTypeExport')}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            padding: '7px 0px 7px 12px',
                          }}>
                          {getLocalizedCargoType(estimate.cargoType)}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
                <Divider />
                <Stack
                  direction="row"
                  alignItems="center"
                  width="713px"
                  height="106px">
                  <Grid container spacing="12px">
                    <Grid item xs={5}>
                      <Stack direction="column" gap="9px">
                        <Typography variant="h5">
                          {t('ownWarehouse') + ' ' + t('cut')}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            padding: '7px 0px 7px 12px',
                          }}>
                          {estimate.yardCutDate}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={5}>
                      <Stack direction="column" gap="9px">
                        <Typography variant="h5">
                          {t('airlineWarehouseDirectCut')}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            padding: '7px 0px 7px 12px',
                          }}>
                          {estimate.carrierShedCutDate}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
                <Divider />
                <Stack
                  direction="row"
                  alignItems="center"
                  width="713px"
                  height="106px">
                  <Stack direction="column" gap="9px">
                    <Typography variant="h5">{t('currency')}</Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        padding: '7px 0px 7px 12px',
                      }}>
                      {currencies?.items.find(
                        currency =>
                          currency?.id === estimate.estimateCurrencyId,
                      )?.code ?? ''}
                    </Typography>
                  </Stack>
                </Stack>
                <Divider />
                <Stack
                  direction="row"
                  alignItems="center"
                  width="713px"
                  height="106px">
                  <Grid container spacing="13px">
                    <Grid item xs={4}>
                      <Stack direction="column" gap="9px">
                        <Typography variant="h5">{t('minimum')}</Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            padding: '7px 0px 7px 12px',
                          }}>
                          {estimate.minimum}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack direction="column" gap="9px">
                        <Typography variant="h5">
                          {t('minusFortyFive')}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            padding: '7px 0px 7px 12px',
                          }}>
                          {estimate.under45}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack direction="column" gap="9px">
                        <Typography variant="h5">
                          {t('plusFortyFive')}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            padding: '7px 0px 7px 12px',
                          }}>
                          {estimate.over45}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
                <Divider />
                <Stack
                  direction="row"
                  alignItems="center"
                  width="713px"
                  height="106px">
                  <Grid container spacing="13px">
                    <Grid item xs={4}>
                      <Stack direction="column" gap="9px">
                        <Typography variant="h5">{t('plusHundred')}</Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            padding: '7px 0px 7px 12px',
                          }}>
                          {estimate.over100}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack direction="column" gap="9px">
                        <Typography variant="h5">
                          {t('plusThreeHundred')}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            padding: '7px 0px 7px 12px',
                          }}>
                          {estimate.over300}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack direction="column" gap="9px">
                        <Typography variant="h5">
                          {t('plusFiveHundred')}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            padding: '7px 0px 7px 12px',
                          }}>
                          {estimate.over500}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
                <Divider />
                <Stack
                  direction="row"
                  alignItems="center"
                  width="713px"
                  height="106px">
                  <Grid container spacing="13px">
                    <Grid item xs={4}>
                      <Stack direction="column" gap="9px">
                        <Typography variant="h5">
                          {t('plusThousand')}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            padding: '7px 0px 7px 12px',
                          }}>
                          {estimate.over1000}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack direction="column" gap="9px">
                        <Typography variant="h5">{t('fsc')}</Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            padding: '7px 0px 7px 12px',
                          }}>
                          {estimate.fsc}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
                <Divider />
              </Stack>

              <Stack direction="column" gap="8px" width="690px">
                <Typography variant="h5">{t('customizedItem')}</Typography>
                {customFees.map((item, index) => (
                  <Stack
                    key={index}
                    sx={{
                      background: '#ffffff',
                      width: '674px',
                      borderRadius: '6px',
                      border: 1,
                      borderColor: '#ACC0F1',
                      padding: '10px',
                      gap: '16px',
                    }}>
                    <Stack
                      flexDirection="column"
                      spacing={2}
                      mx="20px"
                      my="20px">
                      <Stack
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center">
                        <Stack flexDirection="column">
                          <Typography variant="h5">{item.title}</Typography>
                        </Stack>
                      </Stack>
                      <Stack flexDirection="row" alignItems="center">
                        <Stack flexDirection="column" width="152px">
                          <Typography variant="h5">{t('unitPrice')}</Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              padding: '7px 0px 7px 12px',
                            }}>
                            {item.unitPrice}
                          </Typography>
                        </Stack>
                        <Stack flexDirection="column" ml={2} width="152px">
                          <Typography variant="h5">{t('unit')}</Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              padding: '7px 0px 7px 12px',
                            }}>
                            {item.unitType}
                          </Typography>
                        </Stack>
                        <Stack flexDirection="column" ml={2} width="152px">
                          <Typography variant="h5">
                            {t('formulaOfCalculation')}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              padding: '7px 0px 7px 12px',
                            }}>
                            {getLocalizedCalculationMethod(
                              item.calculationMethod,
                            )}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack flexDirection="row" alignItems="center">
                        <Stack flexDirection="column" width="152px">
                          <Typography variant="h5">{t('minimum')}</Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              padding: '7px 0px 7px 12px',
                            }}>
                            {item.minimum}
                          </Typography>
                        </Stack>
                        <Stack flexDirection="column" ml={2} width="152px">
                          <Typography variant="h5">{t('maximum')}</Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              padding: '7px 0px 7px 12px',
                            }}>
                            {item.maximum}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack flexDirection="row" alignItems="center">
                        <Stack flexDirection="column" width="152px">
                          <Typography variant="h5">{t('currency')}</Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              padding: '7px 0px 7px 12px',
                            }}>
                            {item.customFeeCurrencyId
                              ? (
                                  currencies?.items.find(
                                    c => c?.id === item.customFeeCurrencyId,
                                  ) || {}
                                ).code || null
                              : null}
                          </Typography>
                        </Stack>
                        <Stack flexDirection="column" ml={2} width="152px">
                          <Typography variant="h5">
                            {t('taxableItem')}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              padding: '7px 0px 7px 12px',
                            }}>
                            {(() => {
                              if (
                                typeof item.includeTax === 'string' &&
                                item.includeTax === 'true'
                              ) {
                                return t('taxableItem');
                              } else if (
                                typeof item.includeTax === 'string' &&
                                item.includeTax === 'false'
                              ) {
                                return t('taxFree');
                              } else {
                                return '';
                              }
                            })()}
                          </Typography>
                        </Stack>
                        <Stack flexDirection="column" ml={2} width="152px">
                          <Typography variant="h5">{t('taxRate')}</Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              padding: '7px 0px 7px 12px',
                            }}>
                            {item.taxRate}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack flexDirection="row" alignItems="center">
                        <Stack flexDirection="column">
                          <Typography variant="h5">{t('remark')}</Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              padding: '7px 0px 7px 12px',
                            }}>
                            {item.remark}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                ))}
                <Divider sx={{mt: '30px'}} />
              </Stack>
              <Stack
                flexDirection="column"
                alignContent="center"
                sx={{
                  width: '690px',
                  display: 'flex',
                  gap: '12px',
                  py: '20px',
                }}>
                <Stack flexDirection="column" sx={{gap: '9px'}}>
                  <Typography
                    variant="h5"
                    sx={{
                      textAlign: 'left',
                    }}>
                    {t('remark')}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: 'left',
                      padding: '7px 0px 7px 12px',
                    }}>
                    {estimate.remark}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                flexDirection="row"
                alignItems="center"
                sx={{
                  width: '564px',
                  height: '45px',
                }}>
                <Button
                  variant="gray"
                  size="large"
                  style={{marginRight: '24px'}}
                  onClick={() => {
                    navigate('/createEstimate', {state: {estimate}});
                  }}>
                  {t('modify')}
                </Button>
                <LoadingButton
                  size="large"
                  onClick={createEstimate}
                  isLoading={isLoading}>
                  {t('createQuotation')}
                </LoadingButton>
              </Stack>
            </Stack>
          </Inner>
        </Stack>
      </Box>
    </>
  );
};
