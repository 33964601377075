import {Auth, Hub} from 'aws-amplify';
import constate from 'constate';
import {useCallback, useEffect, useState} from 'react';

export interface OAuthUser {
  attributes: {
    sub: string;
    email: string;
  };
  isAdmins: boolean;
  isForwarders: boolean;
  isShippers: boolean;
}

export const [AuthProvider, useOAuthUserState] = constate(() => {
  const [oAuthUser, setOAuthUser] = useState<{
    state: 'loading' | 'ready';
    user: OAuthUser | null;
  }>({
    state: 'loading',
    user: null,
  });
  const updateUser = useCallback(async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      if (currentUser) {
        const currentSession = await Auth.currentSession();
        const {payload} = currentSession.getIdToken();
        const groups: string[] = (payload && payload['cognito:groups']) || [];
        setOAuthUser({
          state: 'ready',
          user: {
            ...currentUser,
            isAdmins: groups.some(group => group === 'Admins'),
            isForwarders: groups.some(group => group === 'Forwarders'),
            isShippers: groups.some(group => group === 'Shippers'),
          },
        });
      } else {
        setOAuthUser({state: 'ready', user: null});
      }
    } catch (err) {
      setOAuthUser({state: 'ready', user: null});
    }
  }, []);
  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({payload: {event}}) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          updateUser();
          break;
        case 'signOut':
          setOAuthUser({state: 'ready', user: null});
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          break;
      }
    });
    updateUser();
    return unsubscribe;
  }, [updateUser]);
  return oAuthUser;
});

export const useOAuthUser = () => {
  const userState = useOAuthUserState();
  return userState.user;
};
