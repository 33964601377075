import {TooltipItem} from 'chart.js';
import dayjs from 'dayjs';
import React, {FC, useEffect, useState} from 'react';
import {Scatter} from 'react-chartjs-2';
import {useTranslation} from 'react-i18next';
import 'chart.js/auto';
import {useMarketConditionsWithSafs} from '../queries/marketConditionsWithSaf';
import {useMarketConditionsWithoutSafs} from '../queries/marketConditionsWithoutSaf';
import {isNonNull} from '../utils/isEmpty';

interface ChartProps {
  arrival: string;
}

export const Chart: FC<ChartProps> = ({arrival}) => {
  const {t} = useTranslation();
  const {data: marketConditionsWithSaf} = useMarketConditionsWithSafs(arrival);
  const {data: marketConditionsWithoutSaf} =
    useMarketConditionsWithoutSafs(arrival);

  const [data, setData] = useState({
    datasets: [] as {
      label: string;
      data: {x: string; y: number | null}[];
      backgroundColor: string;
    }[],
  });

  const [options, setOptions] = useState({});

  useEffect(() => {
    const labels =
      marketConditionsWithSaf?.items
        ?.sort((a, b) => dayjs(a?.flightDate).diff(dayjs(b?.flightDate)))
        .map(item => dayjs(item?.flightDate).format('M/D')) || [];

    const marketPricesWithoutSaf =
      marketConditionsWithoutSaf?.items
        ?.sort((a, b) => dayjs(a?.flightDate).diff(dayjs(b?.flightDate)))
        .map(item =>
          item
            ? {
                x: dayjs(item.flightDate).format('M/D'),
                y: item.marketPrice === 0 ? null : item.marketPrice,
              }
            : null,
        )
        .filter(isNonNull) || [];

    const marketPricesWithSaf =
      marketConditionsWithSaf?.items
        ?.sort((a, b) => dayjs(a?.flightDate).diff(dayjs(b?.flightDate)))
        .map(item =>
          item
            ? {
                x: dayjs(item.flightDate).format('M/D'),
                y: item.marketPrice === 0 ? null : item.marketPrice,
              }
            : null,
        )
        .filter(isNonNull) || [];

    setData({
      datasets: [
        {
          label: t('regularFare'),
          data: marketPricesWithoutSaf,
          backgroundColor: 'blue',
        },
        {
          label: t('fareWithSaf'),
          data: marketPricesWithSaf,
          backgroundColor: 'green',
        },
      ],
    });

    setOptions({
      plugins: {
        legend: {
          display: true,
          position: 'bottom' as const,
        },
        tooltip: {
          callbacks: {
            label: function (context: TooltipItem<'scatter'>) {
              let label =
                `${(context.raw as {x: string; y: number}).x} ${
                  context.dataset.label
                }` || '';
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += `${context.parsed.y}${t('jpy')}`;
              }
              return label;
            },
          },
        },
      },
      scales: {
        x: {
          type: 'category',
          labels: labels,
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
        },
      },
    });
  }, [marketConditionsWithSaf, marketConditionsWithoutSaf, t]);

  return <Scatter data={data} options={options} />;
};
