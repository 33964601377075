import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveIcon from '@mui/icons-material/Remove';
import {Stack, Typography} from '@mui/material';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

type Props = {
  wordCountError: boolean;
  alphanumericError: boolean;
};

export const PasswordValidation: FC<Props> = ({
  wordCountError,
  alphanumericError,
}) => {
  const {t} = useTranslation();
  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={0.5}>
        {wordCountError ? (
          <RemoveIcon fontSize="small" />
        ) : (
          <CheckCircleIcon fontSize="small" sx={{color: 'primary.main'}} />
        )}
        <Typography sx={{fontSize: '12px'}}>{t('minimumEight')}</Typography>
      </Stack>
      <Stack direction="row" spacing={0.5}>
        {alphanumericError ? (
          <RemoveIcon fontSize="small" />
        ) : (
          <CheckCircleIcon fontSize="small" sx={{color: 'primary.main'}} />
        )}
        <Typography sx={{fontSize: '12px'}}>
          {t('combineLettersAndNumbers')}
        </Typography>
      </Stack>
    </Stack>
  );
};
