import {Button, Container, Grid, Stack, Typography} from '@mui/material';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import Inner from '../../layouts/Inner';

export const CompleteResetPasswordScreen = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${t('personWhoForgotPassword')} | MAST`;
  }, [t]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      sx={{minHeight: '100vh'}}>
      <Container maxWidth="sm">
        <Typography mb={3} variant="h1">
          MAST
        </Typography>
        <Inner>
          <Stack spacing={3} alignItems="center">
            <Typography
              textAlign="center"
              sx={{
                fontSize: '20px',
                fontWeight: '500',
                LineHeight: '26px',
              }}>
              {t('passwordReset')}
            </Typography>
            <Button
              variant="primary"
              type="submit"
              size="large"
              onClick={() => {
                return navigate('/signIn');
              }}>
              {t('loginPage')}
            </Button>
          </Stack>
        </Inner>
      </Container>
    </Grid>
  );
};
