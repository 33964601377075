import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery} from 'react-query';
import {
  CreateEstimateMutation,
  CreateEstimateMutationVariables,
  DeleteEstimateMutation,
  DeleteEstimateMutationVariables,
  EstimateStatus,
  GetEstimateQuery,
  GetEstimateQueryVariables,
  ListEstimatesQuery,
  ListEstimatesQueryVariables,
  ModelEstimateFilterInput,
  UpdateEstimateMutation,
  UpdateEstimateMutationVariables,
} from '../API';
import {
  createEstimate,
  deleteEstimate,
  updateEstimate,
} from '../graphql/mutations';
import {getEstimate, listEstimates} from '../graphql/queries';
import {toAWSDate} from '../utils/toAWSDate';

export const useEstimate = (id: string) => {
  return useQuery(
    ['estimate', id],
    async () => {
      const variable: GetEstimateQueryVariables = {
        id,
      };
      const result = (await API.graphql(
        graphqlOperation(getEstimate, variable),
      )) as GraphQLResult<GetEstimateQuery>;
      return result.data?.getEstimate;
    },
    {
      enabled: !!id,
    },
  );
};

export const useEstimates = ({
  userId,
  departure,
  arrival,
  startDate,
  endDate,
  airlineCode,
}: {
  userId?: string;
  departure?: string;
  arrival?: string;
  startDate?: Date;
  endDate?: Date;
  airlineCode?: string;
}) => {
  return useQuery(
    [
      //キャッシュ保持を同一の検索のみにする
      'estimates',
      userId,
      departure,
      arrival,
      airlineCode,
      startDate?.toISOString(),
      endDate?.toISOString(),
    ],
    async () => {
      const filter: ModelEstimateFilterInput = {
        status: {
          eq: EstimateStatus.OPEN,
        },
      };
      if (userId) {
        filter.forwarderUserEstimatesId = {
          eq: userId,
        };
      }
      if (departure) {
        filter.departure = {
          eq: departure,
        };
      }
      if (arrival) {
        filter.arrival = {
          eq: arrival,
        };
      }
      if (airlineCode) {
        filter.airline = {
          eq: airlineCode,
        };
      }
      if (startDate && endDate) {
        filter.flightDate = {
          between: [toAWSDate(startDate), toAWSDate(endDate)],
        };
      }
      const variables: ListEstimatesQueryVariables = {
        limit: 10000,
        filter: filter,
      };
      const result = (await API.graphql(
        graphqlOperation(listEstimates, variables),
      )) as GraphQLResult<ListEstimatesQuery>;
      return result?.data?.listEstimates;
    },
  );
};

export const useCreateEstimateMutation = () => {
  return useMutation(async (variables: CreateEstimateMutationVariables) => {
    const user = (await API.graphql(
      graphqlOperation(createEstimate, variables),
    )) as GraphQLResult<CreateEstimateMutation>;
    return user.data?.createEstimate;
  });
};

export const useUpdateEstimateMutation = () => {
  return useMutation(async (variables: UpdateEstimateMutationVariables) => {
    const user = (await API.graphql(
      graphqlOperation(updateEstimate, variables),
    )) as GraphQLResult<UpdateEstimateMutation>;
    return user.data?.updateEstimate;
  });
};

export const useDeleteEstimateMutation = () => {
  return useMutation(async (variables: DeleteEstimateMutationVariables) => {
    const user = (await API.graphql(
      graphqlOperation(deleteEstimate, variables),
    )) as GraphQLResult<DeleteEstimateMutation>;
    return user.data?.deleteEstimate;
  });
};
