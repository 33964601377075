import './App.css';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import {Amplify, Auth} from 'aws-amplify';
import {useMemo} from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import awsExports from './aws-exports';
import {AuthProvider} from './providers/AuthProvider';
import {Screens} from './screens';

Amplify.configure({
  ...awsExports,
  Auth: {
    identityPoolId: awsExports.aws_cognito_identity_pool_id,
    region: awsExports.aws_cognito_region,
    userPoolId: awsExports.aws_user_pools_id,
    userPoolWebClientId: awsExports.aws_user_pools_web_client_id,
  },
  API: {
    graphql_headers: async () => ({
      Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    }),
  },
});

declare module '@mui/material/styles' {
  interface TypographyVariants {
    error: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    error: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    error: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    gray: true;
    approve: true;
  }
}

declare module '@mui/system' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    gray: true;
    approve: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#3F6CDF',
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: {variant: 'outlined'},
          style: {
            backgroundColor: 'white',
            fontSize: '16px',
            borderRadius: '50px',
            color: '#3F6CDF',
            border: '1px solid #3F6CDF',
            padding: '12px 20px',
            transition: 'all 0.2s ease',
            '&:hover': {
              backgroundColor: 'white',
              opacity: '0.7',
              boxShadow: 'none',
            },
          },
        },
        {
          props: {variant: 'primary'},
          style: {
            backgroundColor: '#3F6CDF',
            fontSize: '16px',
            borderRadius: '50px',
            color: 'white',
            padding: '12px 12px',
            transition: 'all 0.2s ease',
            '&:hover': {
              backgroundColor: '#3F6CDF',
              opacity: '0.7',
              boxShadow: 'none',
            },
            '&:disabled': {
              backgroundColor: '#DADCE3',
              opacity: '0.7',
              boxShadow: 'none',
            },
          },
        },
        {
          props: {variant: 'gray'},
          style: {
            backgroundColor: '#DADCE3',
            fontSize: '16px',
            borderRadius: '50px',
            color: '#494747',
            padding: '12px 20px',
            transition: 'all 0.2s ease',
            '&:hover': {
              backgroundColor: '#DADCE3',
              opacity: '0.7',
              boxShadow: 'none',
            },
          },
        },
        {
          props: {variant: 'approve'},
          style: {
            backgroundColor: 'white',
            fontSize: '16px',
            borderRadius: '50px',
            color: '#2E7D32',
            border: '1px solid #2E7D32',
            padding: '12px 20px',
            transition: 'all 0.2s ease',
            '&:hover': {
              backgroundColor: 'white',
              opacity: '0.7',
              boxShadow: 'none',
            },
          },
        },
        {
          props: {size: 'large'},
          style: {
            width: '270px',
          },
        },
        {
          props: {size: 'small'},
          style: {
            width: '127px',
          },
        },
      ],
    },
  },
  typography: {
    fontSize: 14,
    allVariants: {
      color: '#494747',
    },
    h1: {
      fontSize: '38px',
      fontWeight: 'bold',
      lineHeight: 1.32,
      textAlign: 'center',
      color: '#3F6CDF',
    },
    h2: {
      fontSize: '24px',
      fontWeight: 'bold',
      lineHeight: 1.32,
    },
    h3: {
      fontSize: '22px',
      fontWeight: 'bold',
      lineHeight: 1.32,
    },
    h4: {
      fontSize: '18px',
      fontWeight: 'bold',
      lineHeight: 1.32,
    },
    h5: {
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: 1.32,
    },
    h6: {
      fontSize: '13px',
      lineHeight: 1.32,
    },
    body1: {
      fontSize: '16px',
      lineHeight: 1.6,
    },
    body2: {
      fontSize: '14px',
      lineHeight: 1.6,
    },
    error: {
      fontSize: '14px',
      lineHeight: 1.6,
      color: 'red',
    },
  },
});

Sentry.init({
  dsn: 'https://6f761fe78c463ef564a606287ce41eef@o1280658.ingest.sentry.io/4506422108291072',
  integrations: [new Sentry.Replay()],
  tracesSampleRate: 1.0,
});

function App() {
  const queryClient = useMemo(() => new QueryClient(), []);
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Screens />
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
