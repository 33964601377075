import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {
  CreateForwarderCompanyMutation,
  CreateForwarderCompanyMutationVariables,
  DeleteForwarderCompanyMutation,
  DeleteForwarderCompanyMutationVariables,
  GetForwarderCompanyQuery,
  GetForwarderCompanyQueryVariables,
  ListForwarderCompaniesQuery,
  ListForwarderCompaniesQueryVariables,
  UpdateForwarderCompanyMutation,
  UpdateForwarderCompanyMutationVariables,
} from '../API';
import {
  createForwarderCompany,
  deleteForwarderCompany,
  updateForwarderCompany,
} from '../graphql/mutations';
import {getForwarderCompany, listForwarderCompanies} from '../graphql/queries';

export const useForwarderCompanies = () => {
  return useQuery(['forwarderCompanies'], async () => {
    const variables: ListForwarderCompaniesQueryVariables = {
      limit: 10000,
    };
    const result = (await API.graphql(
      graphqlOperation(listForwarderCompanies, variables),
    )) as GraphQLResult<ListForwarderCompaniesQuery>;
    return result?.data?.listForwarderCompanies;
  });
};

export const useForwarderCompany = (userId?: string) => {
  return useQuery(
    ['forwarderCompany', userId],
    async () => {
      if (!userId) return;
      const variable: GetForwarderCompanyQueryVariables = {
        id: userId,
      };
      const result = (await API.graphql(
        graphqlOperation(getForwarderCompany, variable),
      )) as GraphQLResult<GetForwarderCompanyQuery>;
      return result.data?.getForwarderCompany;
    },
    {
      enabled: !!userId,
    },
  );
};

export const useCreateForwarderCompanyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (variables: CreateForwarderCompanyMutationVariables) => {
      const user = (await API.graphql(
        graphqlOperation(createForwarderCompany, variables),
      )) as GraphQLResult<CreateForwarderCompanyMutation>;
      return user.data?.createForwarderCompany;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
    },
  );
};

export const useUpdateForwarderCompanyMutation = () => {
  return useMutation(
    async (variables: UpdateForwarderCompanyMutationVariables) => {
      const user = (await API.graphql(
        graphqlOperation(updateForwarderCompany, variables),
      )) as GraphQLResult<UpdateForwarderCompanyMutation>;
      return user.data?.updateForwarderCompany;
    },
  );
};

export const useDeleteForwarderCompanyMutation = () => {
  return useMutation(
    async (variables: DeleteForwarderCompanyMutationVariables) => {
      const user = (await API.graphql(
        graphqlOperation(deleteForwarderCompany, variables),
      )) as GraphQLResult<DeleteForwarderCompanyMutation>;
      return user.data?.deleteForwarderCompany;
    },
  );
};
