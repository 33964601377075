import {Stack, Typography} from '@mui/material';
import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LabelValue} from './LabelValue';
import {StyledTable} from './StyledTable';
import {CalculationMethod, CustomFee} from '../API';
import {useCurrencies} from '../queries/currency';
import {useEstimate} from '../queries/estimate';
import {OmitedCustomFee} from '../types';
import {calculateFee} from '../utils/calculateFee';
import {calculateItemFee} from '../utils/calculateItemFee';
import {isNonNull} from '../utils/isEmpty';
import {roundToDecimal} from '../utils/roundToDecimal';

interface FeeDetailProps {
  estimateId: string;
  totalCw: number;
  totalGw: number;
  unit: number;
}

export const FeeDetail: FC<FeeDetailProps> = props => {
  const {t} = useTranslation();
  const {data: estimate} = useEstimate(props.estimateId);
  const {data: currencies} = useCurrencies();
  const totalCw = props.totalCw;
  const baseUnitPrice =
    totalCw >= 1000
      ? estimate?.over1000 ?? 0
      : totalCw >= 500
      ? estimate?.over500 ?? 0
      : totalCw >= 300
      ? estimate?.over300 ?? 0
      : totalCw >= 100
      ? estimate?.over100 ?? 0
      : totalCw >= 45
      ? estimate?.over45 ?? 0
      : estimate?.under45 ?? 0;

  // 最小値との比較処理
  const airfreightPrice = Math.max(
    baseUnitPrice * totalCw,
    estimate?.minimum ?? 0,
  );
  const airfreightPriceYen =
    airfreightPrice * (estimate?.currency?.exchangeRateJPY ?? 0);

  const fsc = estimate?.fsc ?? 0;
  const fscPrice = fsc * totalCw;
  const fscPriceYen = fscPrice * (estimate?.currency?.exchangeRateJPY ?? 0);

  const [customFees, setCustomFees] = useState<OmitedCustomFee[]>([
    {
      id: '',
      title: '',
      unitPrice: 0,
      unitType: t('shipment'),
      calculationMethod: CalculationMethod.PER_SHIPMENT,
      customFeeCurrencyId: null,
      minimum: null,
      maximum: null,
      currency: null,
      includeTax: null,
      taxRate: 0.0,
      remark: '',
      createdAt: '',
    },
  ]);

  useEffect(() => {
    if (estimate?.customItems?.items) {
      setCustomFees(
        estimate.customItems.items
          .filter((item): item is CustomFee => item !== null)
          .sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
          )
          .map(item => {
            return {
              id: item.id,
              title: item.title,
              unitPrice: item.unitPrice,
              unitType: item.unitType,
              calculationMethod: item.calculationMethod,
              customFeeCurrencyId: item.customFeeCurrencyId ?? null,
              minimum: item.minimum,
              maximum: item.maximum,
              currency: item.currency,
              includeTax: item.includeTax ?? null,
              taxRate: item.taxRate ?? 0.0,
              remark: item.remark ?? '',
              createdAt: item.createdAt,
            };
          }),
      );
    }
  }, [estimate]);

  return (
    <Stack spacing={5}>
      <Stack spacing={3}>
        <Typography variant="h2">{t('airfreight')}</Typography>
        <StyledTable>
          <tr>
            <th>{t('ItemName')}</th>
            <th>{t('currency')}</th>
            <th>{t('unitPrice')}</th>
            <th>{t('unit')}</th>
            <th>{t('minimum')}</th>
            <th>{t('maximum')}</th>
            <th>{t('estimatedAmount')}</th>
            <th>{t('exchangeRate')}</th>
            <th>{t('yenCurrency')}</th>
            <th>{t('remark')}</th>
          </tr>
          <tr>
            <th>{t('airfreight')}</th>
            <td>
              {currencies?.items.find(
                currency => currency?.id === estimate?.estimateCurrencyId,
              )?.code ?? ''}
            </td>
            <td>{roundToDecimal(baseUnitPrice, 2).toLocaleString()}</td>
            <td>kg</td>
            <td>{estimate?.minimum.toLocaleString()}</td>
            <td>{/* NOTE: estimate 最大料金は無し */}</td>
            <td>{roundToDecimal(airfreightPrice, 0).toLocaleString()}</td>
            <td>
              {roundToDecimal(
                estimate?.currency?.exchangeRateJPY ?? 0,
                2,
              ).toLocaleString()}
            </td>
            <td>{roundToDecimal(airfreightPriceYen, 0).toLocaleString()}</td>
            <td>{estimate?.remark}</td>
          </tr>
          <tr>
            <th>{t('fsc')}</th>
            <td>
              {currencies?.items.find(
                currency => currency?.id === estimate?.estimateCurrencyId,
              )?.code ?? ''}
            </td>
            <td>{roundToDecimal(fsc, 2).toLocaleString()}</td>
            <td>kg</td>
            <td>{/* NOTE: FSC 最低料金は無し */}</td>
            <td>{/* NOTE: FSC 最大料金は無し */}</td>
            <td>{roundToDecimal(fscPrice, 0).toLocaleString()}</td>
            <td>
              {roundToDecimal(
                estimate?.currency?.exchangeRateJPY ?? 0,
                2,
              ).toLocaleString()}
            </td>
            <td>{roundToDecimal(fscPriceYen, 0).toLocaleString()}</td>
            <td>{/* NOTE: FSC 備考は無し */}</td>
          </tr>
          {customFees.filter(isNonNull).map(item => (
            <tr>
              <th>{item.title}</th>
              <td>{item.currency?.code}</td>
              <td>{item.unitPrice.toLocaleString()}</td>
              <td>{item.unitType}</td>
              <td>{item.minimum?.toLocaleString()}</td>
              <td>{item.maximum?.toLocaleString()}</td>
              <td>
                {roundToDecimal(
                  calculateItemFee(
                    item.unitPrice,
                    props.totalCw,
                    props.totalGw,
                    props.unit,
                    item.calculationMethod,
                    item.minimum,
                    item.maximum,
                  ),
                  0,
                ).toLocaleString()}
              </td>
              <td>
                {roundToDecimal(
                  item.currency?.exchangeRateJPY ?? 0,
                  2,
                ).toLocaleString()}
              </td>
              <td>
                {roundToDecimal(
                  (item.currency?.exchangeRateJPY ?? 0) *
                    calculateItemFee(
                      item.unitPrice,
                      props.totalCw,
                      props.totalGw,
                      props.unit,
                      item.calculationMethod,
                      item.minimum,
                      item.maximum,
                    ),
                  0,
                ).toLocaleString()}
              </td>
              <td>{item.remark}</td>
            </tr>
          ))}
          <tr>
            <th></th>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <th>{t('subtotal')}</th>
            <td>
              {roundToDecimal(
                calculateFee(
                  props.totalCw,
                  props.totalGw,
                  props.unit,
                  estimate?.over1000,
                  estimate?.over500,
                  estimate?.over300,
                  estimate?.over100,
                  estimate?.over45,
                  estimate?.under45,
                  estimate?.fsc,
                  estimate?.minimum,
                  estimate?.customItems?.items as CustomFee[],
                  estimate?.currency?.exchangeRateJPY,
                ),
                0,
              ).toLocaleString()}
            </td>
            <td></td>
          </tr>
        </StyledTable>
        <LabelValue title={t('remark')} value={estimate?.remark} />
      </Stack>
    </Stack>
  );
};
