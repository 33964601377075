import {zodResolver} from '@hookform/resolvers/zod';
import {
  Box,
  Stack,
  Typography,
  TextField,
  Link,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  Button,
} from '@mui/material';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import {useEffect, useState} from 'react';
import * as React from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {z} from 'zod';
import {NumberOfEmployees} from '../../API';
import {EditIcon} from '../../images/EditIcon';
import {useOAuthUserState} from '../../providers/AuthProvider';
import {
  useForwarderCompany,
  useUpdateForwarderCompanyMutation,
} from '../../queries/forwarderCompany';
import {useForwarderUser} from '../../queries/forwarderUser';

export const formInput = z.object({
  name: z.string().min(1),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  location: z.string().min(1),
  establishment: z.string().min(1),
  paidInCaptial: z.number().min(1),
  companyURL: z.string().url(),
  corporateNumber: z.string().min(1),
  iataAgency: z.string().min(1),
});

export type FormInput = z.infer<typeof formInput>;

export const ForwarderCompanyInfoScreen = () => {
  const {t} = useTranslation();
  const userState = useOAuthUserState();
  const sub = userState.user?.attributes.sub ?? '';

  const {data: forwarder} = useForwarderUser(sub);
  const {data: forwarderCompany} = useForwarderCompany(forwarder?.company?.id);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<FormInput>({
    mode: 'all',
    criteriaMode: 'all',
    shouldFocusError: false,
    resolver: zodResolver(formInput),
  });
  const [listed, setListed] = useState<boolean | null>(null);
  const [numberOfEmployees, setNumberOfEmployees] =
    useState<NumberOfEmployees | null>(null);

  const [originalListed, setOriginalListed] = useState<boolean | null>(null);
  const [originalNumberOfEmployees, setOriginalNumberOfEmployees] =
    useState<NumberOfEmployees | null>(null);

  useEffect(() => {
    if (forwarderCompany) {
      setValue('name', forwarderCompany.name);
      setValue('firstName', forwarderCompany.firstName);
      setValue('lastName', forwarderCompany.lastName);
      setValue('location', forwarderCompany.location);
      setValue('establishment', forwarderCompany.establishment);
      setValue('paidInCaptial', forwarderCompany.paidInCaptial);
      setListed(forwarderCompany.listed);
      setNumberOfEmployees(forwarderCompany.numberOfEmployees);
      setValue('companyURL', forwarderCompany.companyURL);
      setValue('corporateNumber', forwarderCompany.corporateNumber);
      setValue('iataAgency', forwarderCompany?.iataAgency);
    }
  }, [forwarderCompany, setValue]);

  const {mutate: updateForwarderCompanyMutation} =
    useUpdateForwarderCompanyMutation();

  const onSubmit: SubmitHandler<FormInput> = async data => {
    if (forwarderCompany) {
      await updateForwarderCompanyMutation({
        input: {
          id: forwarderCompany.id,
          name: data.name,
          firstName: data.firstName,
          lastName: data.lastName,
          location: data.location,
          establishment: data.establishment,
          paidInCaptial: data.paidInCaptial,
          listed,
          numberOfEmployees,
          companyURL: data.companyURL,
          corporateNumber: data.corporateNumber,
          iataAgency: data.iataAgency,
          forwarderCompanyManagerId:
            forwarderCompany?.forwarderCompanyManagerId,
        },
      });
    }
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
    // 編集前のデータに戻す
    setValue('name', originalData['name']);
    setValue('firstName', originalData['firstName']);
    setValue('lastName', originalData['lastName']);
    setValue('location', originalData['location']);
    setValue('establishment', originalData['establishment']);
    setValue('paidInCaptial', originalData['paidInCaptial']);
    setListed(originalListed);
    setNumberOfEmployees(originalNumberOfEmployees);
    setValue('companyURL', originalData['companyURL']);
    setValue('corporateNumber', originalData['corporateNumber']);
    setValue('iataAgency', originalData['iataAgency']);
  };

  const [editing, setEditing] = useState(false);
  const [originalData, setOriginalData] = useState({
    name: '',
    firstName: '',
    lastName: '',
    location: '',
    establishment: '',
    paidInCaptial: 0,
    companyURL: '',
    corporateNumber: '',
    iataAgency: '',
  });

  useEffect(() => {
    document.title = `${t('companyProfile')} | MAST`;
  }, [t]);

  return (
    <>
      {editing ? (
        /*会社情報編集画面*/
        <Stack
          alignItems="center"
          flexDirection="column"
          sx={{
            paddingBottom: '120px',
          }}>
          <Stack justifyContent="start" width="730px">
            <Typography mb={3} variant="h2">
              {t('companyProfile')}
            </Typography>
            <Box
              sx={{
                borderRadius: '12px',
                boxShadow: '0 2px 18px rgba(204, 204, 204, 0.3)',
                backgroundColor: '#fff',
              }}>
              <Stack spacing={3} my="26px" ml={5}>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h6">{t('companyName')}</Typography>
                    <Typography variant="error" ml="12px">
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <TextField
                    sx={{width: '452px'}}
                    size="small"
                    placeholder={t('companyName')}
                    error={!!errors.name}
                    {...register('name', {required: true})}>
                    {errors.name && (
                      <Typography variant="error">
                        {t('enterCompanyName')}
                      </Typography>
                    )}
                  </TextField>
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h6">
                      {t('representativeDirectorName')}
                    </Typography>
                    <Typography variant="error" ml="12px">
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="row">
                    <Stack flexDirection="column">
                      <TextField
                        sx={{width: '222px'}}
                        size="small"
                        placeholder={t('representativeDirectorLastName')}
                        error={!!errors.lastName}
                        {...register('lastName', {required: true})}
                      />
                      {errors.lastName && (
                        <Typography variant="error">
                          {t('enterLastName')}
                        </Typography>
                      )}
                    </Stack>
                    <Stack flexDirection="column" ml={1}>
                      <TextField
                        sx={{width: '222px'}}
                        size="small"
                        placeholder={t('representativeDirectorFirstName')}
                        error={!!errors.firstName}
                        {...register('firstName', {required: true})}
                      />
                      {errors.firstName && (
                        <Typography variant="error">
                          {t('enterFirstName')}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h6">
                      {t('headquartersAddress')}
                    </Typography>
                    <Typography variant="error" ml="12px">
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <TextField
                    sx={{width: '452px'}}
                    size="small"
                    placeholder={t('headquartersAddress')}
                    error={!!errors.location}
                    {...register('location', {required: true})}
                  />
                  {errors.location && (
                    <Typography variant="error">
                      {t('enterHeadquatersAddress')}
                    </Typography>
                  )}
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h6">
                      {t('establishmentDate')}
                    </Typography>
                    <Typography variant="error" ml="12px">
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="ja">
                    <DatePicker
                      sx={{width: '452px'}}
                      format="YYYY/M/D"
                      slotProps={{textField: {size: 'small'}}}
                      defaultValue={dayjs(getValues('establishment'))}
                      onChange={newValue =>
                        setValue(
                          'establishment',
                          dayjs(newValue).format('YYYY-MM-DD'),
                        )
                      }
                    />
                  </LocalizationProvider>
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h6">{t('capitalAmount')}</Typography>
                    <Typography variant="error" ml="12px">
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <TextField
                    sx={{width: '452px'}}
                    type="number"
                    size="small"
                    placeholder={t('capitalAmount')}
                    error={!!errors.paidInCaptial}
                    {...register('paidInCaptial', {
                      required: true,
                      valueAsNumber: true,
                    })}
                    onChange={event => {
                      setValue('paidInCaptial', Number(event.target.value), {
                        shouldValidate: true,
                      });
                    }}
                  />
                  {errors.paidInCaptial && (
                    <Typography variant="error">
                      {t('enterCapitalAmount')}
                    </Typography>
                  )}
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h6">{t('category')}</Typography>
                    <Typography variant="error" ml="12px">
                      {t('requiredField')}{' '}
                    </Typography>
                  </Stack>
                  <RadioGroup
                    value={listed}
                    style={{marginLeft: '10px'}}
                    onChange={e => setListed(e.target.value === 'true')}>
                    <FormControlLabel
                      value="true"
                      control={<Radio size="small" />}
                      label={t('listedCompany')}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio size="small" />}
                      label={t('unlistedCompany')}
                    />
                  </RadioGroup>
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h6">{t('employeesNumber')}</Typography>
                    <Typography variant="error" ml="12px">
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <RadioGroup
                    value={numberOfEmployees}
                    style={{marginLeft: '10px'}}
                    onChange={e =>
                      setNumberOfEmployees(e.target.value as NumberOfEmployees)
                    }>
                    <FormControlLabel
                      value={NumberOfEmployees.UNDER5}
                      control={<Radio size="small" />}
                      label={t('lessFivePeople')}
                    />
                    <FormControlLabel
                      value={NumberOfEmployees.UNDER20}
                      control={<Radio size="small" />}
                      label={t('lessTwentyPeople')}
                    />
                    <FormControlLabel
                      value={NumberOfEmployees.UNDER100}
                      control={<Radio size="small" />}
                      label={t('lessHundredPeople')}
                    />
                    <FormControlLabel
                      value={NumberOfEmployees.UNDER300}
                      control={<Radio size="small" />}
                      label={t('lessThreeHundredPeople')}
                    />
                    <FormControlLabel
                      value={NumberOfEmployees.OVER300}
                      control={<Radio size="small" />}
                      label={t('moreThreeHundredPeople')}
                    />
                  </RadioGroup>
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography>{t('homepageLink')}</Typography>
                    <Typography variant="error" ml="12px">
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <TextField
                    sx={{width: '452px'}}
                    size="small"
                    placeholder={t('homepageLink')}
                    error={!!errors.companyURL}
                    {...register('companyURL', {required: true})}
                  />
                  {errors.companyURL && (
                    <Typography variant="error">{t('enterHPLink')}</Typography>
                  )}
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography>{t('corporateRegistrationNumber')}</Typography>
                    <Typography variant="error" ml="12px">
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <TextField
                    sx={{width: '452px'}}
                    type="number"
                    size="small"
                    placeholder={t('corporateRegistrationNumber')}
                    error={!!errors.corporateNumber}
                    {...register('corporateNumber', {
                      required: true,
                    })}
                    onChange={event => {
                      setValue('corporateNumber', event.target.value, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  {errors.corporateNumber && (
                    <Typography variant="error">
                      {t('enterCorporateRegistrationNumber')}
                    </Typography>
                  )}
                  <Typography fontSize="12px">
                    <Trans
                      i18nKey="checkCorporateRegistrationNumber"
                      components={{
                        link_here: (
                          <Link
                            href="https://info.gbiz.go.jp/"
                            underline="hover"
                            target="_blank"></Link>
                        ),
                      }}
                    />
                  </Typography>
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography>{t('iataAgency')}</Typography>
                    <Typography variant="error" ml="12px">
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <TextField
                    sx={{width: '452px'}}
                    size="small"
                    placeholder={t('iataAgency')}
                    error={!!errors.iataAgency}
                    {...register('iataAgency', {required: true})}
                  />
                  {errors.iataAgency && (
                    <Typography variant="error">
                      {t('enterIataAgency')}
                    </Typography>
                  )}
                  <Typography fontSize="12px">
                    <Trans
                      i18nKey="checkIataAgency"
                      components={{
                        link_here: (
                          <Link
                            href="https://www.iata.org/en/publications/directories/cargolink/directory/"
                            underline="hover"
                            target="_blank"></Link>
                        ),
                      }}
                    />
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    borderRadius: '24px',
                    marginLeft: '40px',
                    marginBottom: '32px',
                  }}>
                  <Button variant="gray" size="small" onClick={handleCancel}>
                    {t('cancel')}
                  </Button>
                  <Button
                    sx={{marginLeft: '24px'}}
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    size="small">
                    {t('update')}
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      ) : (
        /*会社情報確認画面*/
        <Stack
          alignItems="center"
          flexDirection="column"
          sx={{
            paddingBottom: '120px',
          }}>
          <Stack justifyContent="start" width="730px">
            <Stack
              flexDirection="row"
              mb={3}
              justifyContent="space-between"
              alignItems="flex-end">
              <Typography variant="h2">{t('companyProfile')}</Typography>
              <Chip
                color="primary"
                icon={<EditIcon color="primary" style={{fill: 'none'}} />}
                label={t('edit')}
                variant="outlined"
                onClick={() => {
                  setEditing(true);
                  setOriginalData({...getValues()});
                  setOriginalListed(listed);
                  setOriginalNumberOfEmployees(numberOfEmployees);
                }}
                sx={{
                  width: '127px',
                  backgroundColor: '#FFFFFF',
                  '& .MuiChip-label': {
                    paddingLeft: '8px',
                    fontSize: '16px',
                    fontWeight: '500',
                    lineHeight: '20.8px',
                  },
                  '&.MuiChip-root': {
                    height: '45px',
                    borderRadius: '30px',
                  },
                }}
              />
            </Stack>
            <Box
              sx={{
                borderRadius: '12px',
                boxShadow: '0 2px 18px rgba(204, 204, 204, 0.3)',
                backgroundColor: '#fff',
              }}>
              <Stack spacing={3} my="26px" ml={5}>
                <Box>
                  <Typography variant="h6">{t('companyName')}</Typography>
                  <Typography>{getValues('name')}</Typography>
                </Box>
                <Box>
                  <Typography variant="h6">
                    {t('representativeDirectorName')}
                  </Typography>
                  <Typography>
                    {`${getValues('lastName') ?? ''} ${
                      getValues('firstName') ?? ''
                    }`}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6">
                    {t('headquartersAddress')}
                  </Typography>
                  <Typography>{getValues('location')}</Typography>
                </Box>
                <Box>
                  <Typography variant="h6">{t('establishmentDate')}</Typography>
                  <Typography>
                    {getValues('establishment')
                      ? dayjs(getValues('establishment')).format('YYYY/M/D')
                      : ''}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6">{t('capitalAmount')}</Typography>
                  <Typography>{getValues('paidInCaptial')}</Typography>
                </Box>
                <Box>
                  <Typography variant="h6">{t('category')}</Typography>
                  <Typography>
                    {`${listed ? t('listedCompany') : t('unlistedCompany')}`}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6">{t('employeesNumber')}</Typography>
                  <Typography>
                    {`${
                      numberOfEmployees === NumberOfEmployees.UNDER5
                        ? t('lessFivePeople')
                        : numberOfEmployees === NumberOfEmployees.UNDER20
                        ? t('lessTwentyPeople')
                        : numberOfEmployees === NumberOfEmployees.UNDER100
                        ? t('lessHundredPeople')
                        : numberOfEmployees === NumberOfEmployees.UNDER300
                        ? t('lessThreeHundredPeople')
                        : t('moreThreeHundredPeople')
                    }`}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6">{t('homepageLink')}</Typography>
                  <Typography>{getValues('companyURL')}</Typography>
                </Box>
                <Box>
                  <Typography variant="h6">
                    {t('corporateRegistrationNumber')}
                  </Typography>
                  <Typography>{getValues('corporateNumber')}</Typography>
                </Box>
                <Box>
                  <Typography variant="h6">{t('iataAgency')}</Typography>
                  <Typography>{getValues('iataAgency')}</Typography>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      )}
    </>
  );
};
