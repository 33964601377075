import 'react-phone-input-2/lib/style.css';
import {Button, Divider, Stack, Typography} from '@mui/material';
import dayjs from 'dayjs';
import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  CargoType,
  CustomFee,
  PaymentCondition,
  ShippingItemInput,
  Status,
  TransportType,
} from '../../API';
import {DepartureArrivalAirports} from '../../components/DepartureArrivalAirports';
import {FeeDetail} from '../../components/FeeDetail';
import {FhdDetail} from '../../components/FhdDetail';
import {FobDetail} from '../../components/FobDetail';
import {Item} from '../../components/InputEstimateItems';
import {LabelValue} from '../../components/LabelValue';
import LoadingButton from '../../components/LoadingButton';
import {StyledTable} from '../../components/StyledTable';
import {japanAirports} from '../../data/japanAirports';
import Inner from '../../layouts/Inner';
import {useOAuthUserState} from '../../providers/AuthProvider';
import {useEstimate} from '../../queries/estimate';
import {useFirstFhds} from '../../queries/fhd';
import {useFirstFobs} from '../../queries/fob';
import {useCreateReservationMutation} from '../../queries/reservation';
import {useShipperUser} from '../../queries/shipperUser';
import {useCreateShippingCustomFeeMutation} from '../../queries/shippingCustomFee';
import {useCreateShippingFeeMutation} from '../../queries/shippingFee';
import {useCreateShippingFhdFeeMutation} from '../../queries/shippingFhdFee';
import {useCreateShippingFobFeeMutation} from '../../queries/shippingFobFee';
import {useCreateShippingInstructionMutation} from '../../queries/shippingInstruction';
import {assert} from '../../utils/assert';
import {calculateItemFee} from '../../utils/calculateItemFee';
import {getLocalizedCargoType} from '../../utils/getLocalizedCargoType';
import {getLocalizedLeadTime} from '../../utils/getLocalizedLeadTime';
import {getLocalizedPackingStyle} from '../../utils/getLocalizedPackingStyle';
import {getLocalizedPaymentCondition} from '../../utils/getLocalizedPaymentCondition';
import {getLocalizedRegulated} from '../../utils/getLocalizedRegulated';
import {roundToDecimal} from '../../utils/roundToDecimal';
import {toAWSDate} from '../../utils/toAWSDate';

// Item 型から ShippingItemInput 型に変換する関数
const convertItemToShippingItemInput = (item: Item): ShippingItemInput => {
  return {
    width: item.width,
    length: item.length,
    height: item.height,
    weight: item.weight,
    numberOfUnit: item.numberOfUnit,
    volume: item.volume,
    gw: item.gw,
    volumeWeight: item.volumeWeight,
    cw: item.cw,
  };
};

// Item[] を ShippingItemInput[] に変換する関数
const convertItemsToShippingItemInputs = (
  items: Item[],
): ShippingItemInput[] => {
  return items.map(convertItemToShippingItemInput);
};

export const CreateSIConfirmationScreen = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const userState = useOAuthUserState();
  const sub = userState.user?.attributes.sub ?? '';
  const {data: shipper} = useShipperUser(sub);

  const location = useLocation();
  const {data: estimate} = useEstimate(location.state.estimateId);
  const totalCw = location.state.totalCw;
  const totalGw = location.state.totalGw;
  const charge = location.state.charge;
  const unit = location.state.unit;
  const totalFee = location.state.totalFee;
  const totalFeeTax = location.state.totalFeeTax;
  const totalFeeIncludeTax = location.state.totalFeeIncludeTax;
  const shippingInstruction = location.state.shippingInstruction;

  const {data: fob} = useFirstFobs(
    estimate?.forwarderUser.companyId ?? '',
    estimate?.departure ?? '',
  );

  const {data: fhd} = useFirstFhds(
    estimate?.forwarderUser.companyId ?? '',
    estimate?.arrival ?? '',
  );

  const searchItems = convertItemsToShippingItemInputs(
    location.state.searchItems,
  );

  const {mutateAsync: createShippingInstructionMutation} =
    useCreateShippingInstructionMutation();
  const {mutateAsync: createShippingFeeMutation} =
    useCreateShippingFeeMutation();
  const {mutateAsync: createShippingFobFeeMutation} =
    useCreateShippingFobFeeMutation();
  const {mutateAsync: createShippingFhdFeeMutation} =
    useCreateShippingFhdFeeMutation();
  const {mutateAsync: createShippingCustomFeeMutation} =
    useCreateShippingCustomFeeMutation();
  const {mutateAsync: createReservationMutation} =
    useCreateReservationMutation();

  const shippingCustomFees = useMemo(() => {
    if (!estimate?.customItems?.items) return [];
    return [...estimate.customItems.items]
      .filter((item): item is CustomFee => item !== null)
      .sort((a, b) => {
        return (
          new Date(a.createdAt ?? '').getTime() -
          new Date(b.createdAt ?? '').getTime()
        );
      });
  }, [estimate?.customItems?.items]);

  const shippingFobCustomFees = useMemo(() => {
    if (!fob?.customItems?.items) return [];
    return [...fob.customItems.items]
      .filter((item): item is CustomFee => item !== null)
      .sort((a, b) => {
        return (
          new Date(a.createdAt ?? '').getTime() -
          new Date(b.createdAt ?? '').getTime()
        );
      });
  }, [fob?.customItems?.items]);

  const shippingFhdCustomFees = useMemo(() => {
    if (!fhd?.customItems?.items) return [];
    return [...fhd.customItems.items]
      .filter((item): item is CustomFee => item !== null)
      .sort((a, b) => {
        return (
          new Date(a.createdAt ?? '').getTime() -
          new Date(b.createdAt ?? '').getTime()
        );
      });
  }, [fhd?.customItems?.items]);

  const basePrice =
    totalCw >= 1000
      ? estimate?.over1000 ?? 0
      : totalCw >= 500
      ? estimate?.over500 ?? 0
      : totalCw >= 300
      ? estimate?.over300 ?? 0
      : totalCw >= 100
      ? estimate?.over100 ?? 0
      : totalCw >= 45
      ? estimate?.over45 ?? 0
      : estimate?.under45 ?? 0;

  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      assert(
        shipper,
        'An error occurs because shipper is undefined in CreateSIConfirmationScreen',
      );
      assert(
        estimate,
        'An error occurs because estimate is undefined in CreateSIConfirmationScreen',
      );

      // 1. Shipping Feeの作成
      const shippingFeeResult = await createShippingFeeMutation({
        input: {
          price: basePrice,
          fsc: estimate.fsc,
          remark: estimate.remark,
          minimum: estimate.minimum,
          shippingFeeCurrencyId: estimate.estimateCurrencyId,
        },
      });
      assert(
        shippingFeeResult,
        'An error occurs because shippingFeeResult is undefined in CreateSIConfirmationScreen',
      );

      const shippingFeeId = shippingFeeResult.id;

      // 2. 通貨とカスタムフィーの作成
      if (shippingCustomFees) {
        for (const shippingCustomFee of shippingCustomFees) {
          if (shippingCustomFee) {
            await createShippingCustomFeeMutation({
              input: {
                title: shippingCustomFee.title,
                unitPrice: shippingCustomFee.unitPrice,
                unitType: shippingCustomFee.unitType,
                fee: calculateItemFee(
                  shippingCustomFee.unitPrice,
                  totalCw,
                  totalGw,
                  unit,
                  shippingCustomFee.calculationMethod,
                  shippingCustomFee.minimum,
                  shippingCustomFee.maximum,
                ),
                taxRate: shippingCustomFee.taxRate,
                minimum: shippingCustomFee.minimum,
                maximum: shippingCustomFee.maximum,
                remark: shippingCustomFee.remark ?? '',
                shippingCustomFeeCurrencyId:
                  shippingCustomFee.currency?.id ?? '',
                shippingFeeCustomFeesId: shippingFeeId,
              },
            });
          }
        }
      }

      // 3. Shipping Fob Feeの作成
      const shippingFobFeeResult = await createShippingFobFeeMutation({
        input: {
          remark: fob?.remark,
        },
      });
      assert(
        shippingFobFeeResult,
        'An error occurs because shippingFobFeeResult is undefined in CreateSIConfirmationScreen',
      );
      const shippingFobFeeId = shippingFobFeeResult.id;

      // 4. Fobの通貨とカスタムフィーの作成
      if (shippingFobCustomFees) {
        for (const shippingFobCustomFee of shippingFobCustomFees) {
          if (shippingFobCustomFee) {
            await createShippingCustomFeeMutation({
              input: {
                title: shippingFobCustomFee.title,
                unitPrice: shippingFobCustomFee.unitPrice,
                unitType: shippingFobCustomFee.unitType,
                fee: calculateItemFee(
                  shippingFobCustomFee.unitPrice,
                  totalCw,
                  totalGw,
                  unit,
                  shippingFobCustomFee.calculationMethod,
                  shippingFobCustomFee.minimum,
                  shippingFobCustomFee.maximum,
                ),
                taxRate: shippingFobCustomFee.taxRate,
                minimum: shippingFobCustomFee.minimum,
                maximum: shippingFobCustomFee.maximum,
                remark: shippingFobCustomFee.remark ?? '',
                shippingCustomFeeCurrencyId:
                  shippingFobCustomFee.currency?.id ?? '',
                shippingFobFeeCustomFeesId: shippingFobFeeId,
              },
            });
          }
        }
      }

      // 5. Shipping Fhd Feeの作成
      const shippingFhdFeeResult = await createShippingFhdFeeMutation({
        input: {
          remark: fhd?.remark,
        },
      });
      assert(
        shippingFhdFeeResult,
        'An error occurs because shippingFhdFeeResult is undefined in CreateSIConfirmationScreen',
      );
      const shippingFhdFeeId = shippingFhdFeeResult.id;

      // 6. Fhdの通貨とカスタムフィーの作成
      if (shippingFhdCustomFees) {
        for (const shippingFhdCustomFee of shippingFhdCustomFees) {
          if (shippingFhdCustomFee) {
            await createShippingCustomFeeMutation({
              input: {
                title: shippingFhdCustomFee.title,
                unitPrice: shippingFhdCustomFee.unitPrice,
                unitType: shippingFhdCustomFee.unitType,
                fee: calculateItemFee(
                  shippingFhdCustomFee.unitPrice,
                  totalCw,
                  totalGw,
                  unit,
                  shippingFhdCustomFee.calculationMethod,
                  shippingFhdCustomFee.minimum,
                  shippingFhdCustomFee.maximum,
                ),
                taxRate: shippingFhdCustomFee.taxRate,
                minimum: shippingFhdCustomFee.minimum,
                maximum: shippingFhdCustomFee.maximum,
                remark: shippingFhdCustomFee.remark ?? '',
                shippingCustomFeeCurrencyId:
                  shippingFhdCustomFee.currency?.id ?? '',
                shippingFhdFeeCustomFeesId: shippingFhdFeeId,
              },
            });
          }
        }
      }

      // 7. Shipping Instructionの作成
      const shippingInstructionResult = await createShippingInstructionMutation(
        {
          input: {
            departure: estimate.departure,
            arrival: estimate.arrival,
            airline: estimate.airline,
            flightDate: toAWSDate(dayjs(estimate.flightDate).toDate()),
            directFlight: estimate.directFlight,
            leadTime: estimate.leadTime,
            useSaf: estimate.useSaf,
            yardCutDate: toAWSDate(dayjs(estimate.yardCutDate).toDate()),
            carrierShedCutDate: toAWSDate(
              dayjs(estimate.carrierShedCutDate).toDate(),
            ),
            itemName: shippingInstruction?.itemName ?? '',
            packingStyle: shippingInstruction?.packingStyle ?? '',
            packingStyleDetail: shippingInstruction?.packingStyleDetail ?? '',
            cargoType: shippingInstruction?.cargoType ?? CargoType.NORMAL,
            isSds: shippingInstruction?.isSds ?? true,
            regulated: shippingInstruction?.regulated ?? '',
            regulatedDetail: shippingInstruction?.regulatedDetail ?? '',
            paymentCondition:
              shippingInstruction?.paymentCondition ??
              PaymentCondition.CASH_ON_DELIVERY,
            paymentConditionDetail:
              shippingInstruction?.paymentConditionDetail ?? '',
            taxPaymentConditions: {
              realTimeAccount:
                shippingInstruction?.taxPaymentRealTimeAccount ?? null,
              comprehensiveDeferredPayment:
                shippingInstruction?.taxPaymentComprehensiveDeferredPayment ??
                null,
              cashOnDeliveryAllowed:
                shippingInstruction?.taxPaymentConditions ===
                'cashOnDeliveryAllowed'
                  ? true
                  : null,
              beforePermissionPickup:
                shippingInstruction?.taxPaymentConditions ===
                'beforePermissionPickup'
                  ? true
                  : null,
              othersOption: shippingInstruction?.taxPaymentOthersOption ?? null,
            },
            cargoInsuranceConditions: {
              comprehensiveInsurance:
                shippingInstruction?.cargoInsuranceComprehensiveInsurance ??
                null,
              individualInsurance:
                shippingInstruction?.cargoInsuranceConditions ===
                'individualInsurance'
                  ? true
                  : null,
              uninsuredWithInsurance:
                shippingInstruction?.cargoInsuranceConditions ===
                'uninsuredWithInsurance'
                  ? true
                  : null,
              othersOption:
                shippingInstruction?.cargoInsuranceOthersOption ?? null,
            },
            document: shippingInstruction?.document,
            consigneeContact: {
              companyName:
                shippingInstruction?.consigneeContact?.companyName ?? '',
              name: shippingInstruction?.consigneeContact?.name ?? '',
              email: shippingInstruction?.consigneeContact?.email ?? '',
              phoneNumber:
                shippingInstruction?.consigneeContact?.phoneNumber ?? '',
            },
            shipperContact: {
              companyName:
                shippingInstruction?.shipperContact?.companyName ?? '',
              name: shippingInstruction?.shipperContact?.name ?? '',
              email: shippingInstruction?.shipperContact?.email ?? '',
              phoneNumber:
                shippingInstruction?.shipperContact?.phoneNumber ?? '',
            },
            cargoInsurance:
              shippingInstruction?.options?.cargoInsurance ?? false,
            cargoInsuranceDetail:
              shippingInstruction?.options?.cargoInsuranceDetail ?? null,
            packed: shippingInstruction?.options?.packed ?? false,
            packedDetail: shippingInstruction?.options?.packedDetail ?? null,
            collected: shippingInstruction?.options?.collected ?? false,
            collectedDetail:
              shippingInstruction?.options?.collectedDetail ?? null,
            delivery: shippingInstruction?.options?.delivery ?? false,
            deliveryDetail:
              shippingInstruction?.options?.deliveryDetail ?? null,
            request: shippingInstruction?.options?.request,
            shippingItems: searchItems,
            transportType: charge,
            shippingInstructionShippingFeeId: shippingFeeId,
            shippingInstructionFobId: shippingFobFeeId,
            shippingInstructionFhdId: shippingFhdFeeId,
            shippingInstructionShipperUserId: sub,
          },
        },
      );
      assert(
        shippingInstructionResult,
        'An error occurs because shippingInstructionResult is undefined in CreateSIConfirmationScreen()',
      );

      // 8. 予約の作成
      await createReservationMutation({
        input: {
          hawb: '',
          status: Status.PENDING,
          reservationShippingInstructionId: shippingInstructionResult.id,
          forwarderCompanyId: estimate.forwarderUser.companyId,
          shipperCompanyId: shipper.companyId,
          departure: estimate.departure,
        },
      });

      // 9. ナビゲーション
      navigate('/reserveConfirmation', {
        state: {forwarderCompanyId: estimate.forwarderUser.companyId},
      });
    } catch {
      navigate('error');
    } finally {
      setIsLoading(false);
    }
  }, [
    shipper,
    estimate,
    createShippingFeeMutation,
    basePrice,
    shippingCustomFees,
    createShippingFobFeeMutation,
    fob?.remark,
    shippingFobCustomFees,
    createShippingFhdFeeMutation,
    fhd?.remark,
    shippingFhdCustomFees,
    createShippingInstructionMutation,
    shippingInstruction?.itemName,
    shippingInstruction?.packingStyle,
    shippingInstruction?.packingStyleDetail,
    shippingInstruction?.cargoType,
    shippingInstruction?.isSds,
    shippingInstruction?.regulated,
    shippingInstruction?.regulatedDetail,
    shippingInstruction?.paymentCondition,
    shippingInstruction?.paymentConditionDetail,
    shippingInstruction?.taxPaymentRealTimeAccount,
    shippingInstruction?.taxPaymentComprehensiveDeferredPayment,
    shippingInstruction?.taxPaymentConditions,
    shippingInstruction?.taxPaymentOthersOption,
    shippingInstruction?.cargoInsuranceComprehensiveInsurance,
    shippingInstruction?.cargoInsuranceOthersOption,
    shippingInstruction?.document,
    shippingInstruction?.consigneeContact?.companyName,
    shippingInstruction?.consigneeContact?.name,
    shippingInstruction?.consigneeContact?.email,
    shippingInstruction?.consigneeContact?.phoneNumber,
    shippingInstruction?.shipperContact?.companyName,
    shippingInstruction?.shipperContact?.name,
    shippingInstruction?.shipperContact?.email,
    shippingInstruction?.shipperContact?.phoneNumber,
    shippingInstruction?.options?.cargoInsurance,
    shippingInstruction?.options?.cargoInsuranceDetail,
    shippingInstruction?.options?.packed,
    shippingInstruction?.options?.packedDetail,
    shippingInstruction?.options?.collected,
    shippingInstruction?.options?.collectedDetail,
    shippingInstruction?.options?.delivery,
    shippingInstruction?.options?.deliveryDetail,
    shippingInstruction?.options?.request,
    searchItems,
    charge,
    sub,
    createReservationMutation,
    navigate,
    createShippingCustomFeeMutation,
    totalCw,
    totalGw,
    unit,
  ]);

  return (
    <Stack
      pb={10}
      sx={{
        width: '1128px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}>
      <Typography variant="h2" mb={3}>
        {t('si')}
      </Typography>
      <Inner>
        <Stack spacing={4}>
          <Stack
            flexDirection="column"
            sx={{
              gap: '12px',
            }}>
            <Stack flexDirection="column">
              <Typography>{t('quotationID')}</Typography>
              <Typography>{estimate?.id}</Typography>
            </Stack>
            <Divider
              style={{
                border: 'solid 0.5px #DADCE3',
                marginBottom: '16px',
              }}
            />
            <DepartureArrivalAirports
              departure={estimate?.departure}
              arrival={estimate?.arrival}
            />
            <LabelValue
              title={`${t('airline')}:`}
              titleWeight="600"
              value={estimate?.airline}
              row
              rowValueLeftMaring="16px"
            />
            <LabelValue
              title={`${t('etd')}:`}
              titleWeight="600"
              value={estimate?.flightDate}
              row
              rowValueLeftMaring="16px"
            />
            <LabelValue
              title={`${t('flightType')}:`}
              titleWeight="600"
              value={
                estimate?.directFlight
                  ? t('directFlight')
                  : t('connectingFlight')
              }
              row
              rowValueLeftMaring="16px"
            />
            <LabelValue
              title={`${t('leadTime')}:`}
              titleWeight="600"
              value={getLocalizedLeadTime(estimate?.leadTime)}
              row
              rowValueLeftMaring="16px"
            />
            <LabelValue
              title={`${t('saf')}:`}
              titleWeight="600"
              value={estimate?.useSaf ? t('use') : t('nonUse')}
              row
              rowValueLeftMaring="16px"
            />
            <LabelValue
              title={`${t('fwdWarehouse') + ' ' + t('cut')}:`}
              titleWeight="600"
              value={estimate?.yardCutDate}
              row
              rowValueLeftMaring="16px"
            />
            <LabelValue
              title={`${t('airlineWarehouseDirectCut')}:`}
              titleWeight="600"
              value={estimate?.carrierShedCutDate}
              row
              rowValueLeftMaring="16px"
            />
          </Stack>
          <Stack
            flexDirection="column"
            sx={{
              gap: '12px',
            }}>
            <Typography variant="h4">{t('cargoDetails')}</Typography>
            <Stack
              flexDirection="column"
              sx={{
                gap: '8px',
              }}>
              <LabelValue
                title={`${t('productName')}:`}
                titleWeight="600"
                value={shippingInstruction?.itemName}
                row
                rowValueLeftMaring="16px"
              />
              <LabelValue
                title={`${t('packingType')}:`}
                titleWeight="600"
                value={getLocalizedPackingStyle(
                  shippingInstruction?.packingStyle,
                  shippingInstruction?.packingStyleDetail,
                )}
                row
                rowValueLeftMaring="16px"
              />
              <StyledTable>
                <tr>
                  <th>{t('length')}</th>
                  <th>{t('width')}</th>
                  <th>{t('height')}</th>
                  <th>{t('weight')}</th>
                  <th>{t('numberOfPackage')}</th>
                  <th>{t('grossWeight')}</th>
                  <th>{t('grossVolume')}</th>
                  <th>{t('volumeWeight')}</th>
                </tr>
                {searchItems.map((searchItem, index) => (
                  <tr key={index}>
                    <td>{searchItem.width}</td>
                    <td>{searchItem.length}</td>
                    <td>{searchItem.height}</td>
                    <td>{searchItem.weight}</td>
                    <td>{searchItem.numberOfUnit}</td>
                    <td>{searchItem.gw}</td>
                    <td>{searchItem.volume}</td>
                    <td>{searchItem.volumeWeight}</td>
                  </tr>
                ))}
              </StyledTable>
              <Stack
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="center">
                <StyledTable>
                  <th style={{paddingRight: '40px'}}>
                    {t('chargeableWeight')}
                  </th>
                  <td style={{paddingRight: '40px'}}>{totalCw}</td>
                </StyledTable>
              </Stack>
              <LabelValue
                title={`${t('commodityTypeExport')}:`}
                titleWeight="600"
                value={getLocalizedCargoType(shippingInstruction?.cargoType)}
                row
                rowValueLeftMaring="16px"
              />
              <LabelValue
                title={`${t('sds')}:`}
                titleWeight="600"
                value={
                  shippingInstruction?.isSds ? t('possible') : t('impossible')
                }
                row
                rowValueLeftMaring="16px"
              />
              {japanAirports.includes(estimate?.departure ?? '') ? (
                <LabelValue
                  title={`${t('laws')}:`}
                  titleWeight="600"
                  value={getLocalizedRegulated(
                    shippingInstruction?.regulated,
                    shippingInstruction?.regulatedDetail,
                  )}
                  row
                  rowValueLeftMaring="16px"
                />
              ) : null}
              <LabelValue
                title={`${t('preferredPaymentsTerms')}:`}
                titleWeight="600"
                value={getLocalizedPaymentCondition(
                  shippingInstruction?.paymentCondition,
                )}
                row
                rowValueLeftMaring="16px"
              />
              <Typography>
                {shippingInstruction?.paymentConditionDetail}
              </Typography>
              {japanAirports.includes(estimate?.arrival ?? '') ? (
                <>
                  <LabelValue
                    title={`${t('preferredPaymentsTermsImportDuty')}:`}
                    titleWeight="600"
                    value={
                      shippingInstruction.taxPaymentConditions ===
                      'realTimeAccount'
                        ? t('realTimeAccount')
                        : shippingInstruction.taxPaymentConditions ===
                          'comprehensiveDeferredPayment'
                        ? t('customsDeferredPayment')
                        : shippingInstruction.taxPaymentConditions ===
                          'cashOnDeliveryAllowed'
                        ? t('cod')
                        : shippingInstruction.taxPaymentConditions ===
                          'beforePermissionPickup'
                        ? t('bp')
                        : shippingInstruction.taxPaymentConditions ===
                          'othersOption'
                        ? t('other')
                        : ''
                    }
                    row
                    rowValueLeftMaring="16px"
                  />
                  <Typography>
                    {(() => {
                      if (
                        shippingInstruction.taxPaymentConditions ===
                        'realTimeAccount'
                      ) {
                        return shippingInstruction.taxPaymentRealTimeAccount;
                      } else if (
                        shippingInstruction.taxPaymentConditions ===
                        'comprehensiveDeferredPayment'
                      ) {
                        return shippingInstruction.taxPaymentComprehensiveDeferredPayment;
                      } else if (
                        shippingInstruction.taxPaymentConditions ===
                        'othersOption'
                      ) {
                        return shippingInstruction.taxPaymentOthersOption;
                      }
                    })()}
                  </Typography>

                  <LabelValue
                    title={`${t('cargoInsuranceImport')}:`}
                    titleWeight="600"
                    value={
                      shippingInstruction.cargoInsuranceConditions ===
                      'comprehensiveInsurance'
                        ? t('comprehensiveInsurance')
                        : shippingInstruction.cargoInsuranceConditions ===
                          'individualInsurance'
                        ? t('individualInsurance')
                        : shippingInstruction.cargoInsuranceConditions ===
                          'uninsuredWithInsurance'
                        ? t('noInsurance')
                        : shippingInstruction.cargoInsuranceConditions ===
                          'othersOption'
                        ? t('other')
                        : ''
                    }
                    row
                    rowValueLeftMaring="16px"
                  />
                  <Typography>
                    {(() => {
                      if (
                        shippingInstruction.cargoInsuranceConditions ===
                        'comprehensiveInsurance'
                      ) {
                        return shippingInstruction.cargoInsuranceComprehensiveInsurance;
                      } else if (
                        shippingInstruction.cargoInsuranceConditions ===
                        'othersOption'
                      ) {
                        return shippingInstruction.cargoInsuranceOthersOption;
                      }
                    })()}
                  </Typography>
                </>
              ) : null}
            </Stack>
          </Stack>
          {japanAirports.includes(estimate?.departure ?? '') ? (
            <Stack
              flexDirection="column"
              sx={{
                gap: '12px',
              }}>
              <Typography variant="h4">{t('dispatchDocuments')}</Typography>
              <Stack
                flexDirection="column"
                sx={{
                  gap: '8px',
                }}>
                <LabelValue
                  title={`${t('invoice')}:`}
                  titleWeight="600"
                  value={
                    shippingInstruction?.document?.invoice ? t('yes') : t('no')
                  }
                  row
                  rowValueLeftMaring="16px"
                />
                <LabelValue
                  title={`${t('packingList')}:`}
                  titleWeight="600"
                  value={
                    shippingInstruction?.document?.packingList
                      ? t('yes')
                      : t('no')
                  }
                  row
                  rowValueLeftMaring="16px"
                />
                <LabelValue
                  title={`${t('nonWoodCertificate')}:`}
                  titleWeight="600"
                  value={
                    shippingInstruction?.document?.nonApplicabilityCertificate
                      ? t('yes')
                      : t('no')
                  }
                  row
                  rowValueLeftMaring="16px"
                />
                <LabelValue
                  title={`${t('originCertificate')}:`}
                  titleWeight="600"
                  value={
                    shippingInstruction?.document?.msds ? t('yes') : t('no')
                  }
                  row
                  rowValueLeftMaring="16px"
                />
                <LabelValue
                  title={`${t('other')}:`}
                  titleWeight="600"
                  value={
                    shippingInstruction?.document?.other
                      ? shippingInstruction?.document.detail
                      : t('no')
                  }
                  row
                  rowValueLeftMaring="16px"
                />
              </Stack>
            </Stack>
          ) : null}
          <Stack
            flexDirection="column"
            sx={{
              gap: '16px',
            }}>
            <Typography variant="h4">{t('options')}</Typography>
            <Stack
              flexDirection="column"
              sx={{
                gap: '8px',
              }}>
              <LabelValue
                title={`${t('cargoInsurance')}:`}
                titleWeight="600"
                value={
                  shippingInstruction?.options?.cargoInsurance
                    ? t('possible')
                    : t('impossible')
                }
                row
                rowValueLeftMaring="16px"
              />
              <Typography>
                {shippingInstruction?.options?.cargoInsuranceDetail ?? ''}
              </Typography>
              <LabelValue
                title={`${t('packingWork')}:`}
                titleWeight="600"
                value={
                  shippingInstruction?.options?.packed
                    ? t('possible')
                    : t('impossible')
                }
                row
                rowValueLeftMaring="16px"
              />
              <Typography>
                {shippingInstruction?.options?.packedDetail ?? ''}
              </Typography>
              <LabelValue
                title={`${
                  japanAirports.includes(estimate?.departure ?? '')
                    ? t('pickupLocation')
                    : japanAirports.includes(estimate?.arrival ?? '')
                    ? t('domesticDeliveryLocation')
                    : t('departurePickupLocation')
                }:`}
                titleWeight="600"
                value={
                  shippingInstruction?.options?.collected
                    ? t('possible')
                    : t('impossible')
                }
                row
                rowValueLeftMaring="16px"
              />
              <Typography>
                {shippingInstruction?.options?.collectedDetail ?? ''}
              </Typography>
              <LabelValue
                title={`${
                  japanAirports.includes(estimate?.departure ?? '')
                    ? t('deliveryLocation')
                    : japanAirports.includes(estimate?.arrival ?? '')
                    ? t('internationalPickupLocation')
                    : t('arrivalDeliveryLocation')
                }:`}
                titleWeight="600"
                value={
                  shippingInstruction?.options?.delivery
                    ? t('possible')
                    : t('impossible')
                }
                row
                rowValueLeftMaring="16px"
              />
              <Typography>
                {shippingInstruction?.options?.deliveryDetail ?? ''}
              </Typography>
              <LabelValue
                title={`${t('otherRequests')}:`}
                titleWeight="600"
                value={shippingInstruction?.options?.request}
                row
                rowValueLeftMaring="16px"
              />
            </Stack>
          </Stack>
          <Divider
            style={{
              border: 'solid 0.5px #DADCE3',
              marginBottom: '16px',
            }}
          />
          <Stack
            flexDirection="column"
            sx={{
              gap: '8px',
            }}>
            <Typography variant="h4">{t('orderingManager')}</Typography>
            <Stack
              flexDirection="column"
              sx={{
                gap: '8px',
              }}>
              <LabelValue
                title={`${t('companyName')}:`}
                titleSize="14px"
                titleWeight="300"
                value={shipper?.company?.name}
                valueSize="14px"
                valueWeight="300"
                row
                rowValueLeftMaring="8px"
              />
              <LabelValue
                title={`${t('personInCharge')}:`}
                titleSize="14px"
                titleWeight="300"
                value={`${shipper?.lastName} ${shipper?.firstName}`}
                valueSize="14px"
                valueWeight="300"
                row
                rowValueLeftMaring="8px"
              />
              <LabelValue
                title={`${t('email')}:`}
                titleSize="14px"
                titleWeight="300"
                value={shipper?.email}
                valueSize="14px"
                valueWeight="300"
                row
                rowValueLeftMaring="8px"
              />
              <LabelValue
                title={`${t('phoneNumber')}:`}
                titleSize="14px"
                titleWeight="300"
                value={shipper?.phoneNumber}
                valueSize="14px"
                valueWeight="300"
                row
                rowValueLeftMaring="8px"
              />
            </Stack>
          </Stack>
          {japanAirports.includes(estimate?.departure ?? '') ? null : (
            <Stack
              flexDirection="column"
              sx={{
                gap: '8px',
              }}>
              <Typography variant="h4">{t('shipper')}</Typography>
              <Stack
                flexDirection="column"
                sx={{
                  gap: '8px',
                }}>
                <LabelValue
                  title={`${t('companyName')}:`}
                  titleSize="14px"
                  titleWeight="300"
                  value={shippingInstruction?.shipperContact?.companyName}
                  valueSize="14px"
                  valueWeight="300"
                  row
                  rowValueLeftMaring="8px"
                />
                <LabelValue
                  title={`${t('personInCharge')}:`}
                  titleSize="14px"
                  titleWeight="300"
                  value={shippingInstruction?.shipperContact?.name}
                  valueSize="14px"
                  valueWeight="300"
                  row
                  rowValueLeftMaring="8px"
                />
                <LabelValue
                  title={`${t('email')}:`}
                  titleSize="14px"
                  titleWeight="300"
                  value={shippingInstruction?.shipperContact?.email}
                  valueSize="14px"
                  valueWeight="300"
                  row
                  rowValueLeftMaring="8px"
                />
                <LabelValue
                  title={`${t('phoneNumber')}:`}
                  titleSize="14px"
                  titleWeight="300"
                  value={shippingInstruction?.shipperContact?.phoneNumber}
                  valueSize="14px"
                  valueWeight="300"
                  row
                  rowValueLeftMaring="8px"
                />
              </Stack>
            </Stack>
          )}
          {japanAirports.includes(estimate?.arrival ?? '') ? null : (
            <Stack
              flexDirection="column"
              sx={{
                gap: '8px',
              }}>
              <Typography variant="h4">{t('consignee')}</Typography>
              <Stack
                flexDirection="column"
                sx={{
                  gap: '8px',
                }}>
                <LabelValue
                  title={`${t('companyName')}:`}
                  titleSize="14px"
                  titleWeight="300"
                  value={shippingInstruction?.consigneeContact?.companyName}
                  valueSize="14px"
                  valueWeight="300"
                  row
                  rowValueLeftMaring="8px"
                />
                <LabelValue
                  title={`${t('personInCharge')}:`}
                  titleSize="14px"
                  titleWeight="300"
                  value={shippingInstruction?.consigneeContact?.name}
                  valueSize="14px"
                  valueWeight="300"
                  row
                  rowValueLeftMaring="8px"
                />
                <LabelValue
                  title={`${t('email')}:`}
                  titleSize="14px"
                  titleWeight="300"
                  value={shippingInstruction?.consigneeContact?.email}
                  valueSize="14px"
                  valueWeight="300"
                  row
                  rowValueLeftMaring="8px"
                />
                <LabelValue
                  title={`${t('phoneNumber')}:`}
                  titleSize="14px"
                  titleWeight="300"
                  value={shippingInstruction?.consigneeContact?.phoneNumber}
                  valueSize="14px"
                  valueWeight="300"
                  row
                  rowValueLeftMaring="8px"
                />
              </Stack>
            </Stack>
          )}
          <Stack spacing={7}>
            <FeeDetail
              estimateId={estimate?.id ?? ''}
              totalCw={totalCw}
              totalGw={totalGw}
              unit={unit}
            />
            {/* 料金確認範囲が"航空運賃"以外で輸出料金を表示 */}
            {charge !== TransportType.AIR_IMPORT && (
              <FobDetail
                companyId={estimate?.forwarderUser.companyId ?? ''}
                departure={estimate?.departure ?? ''}
                totalCw={totalCw}
                totalGw={totalGw}
                unit={unit}
              />
            )}
            {/* 料金確認範囲が"航空運賃+輸出料金+輸入料金"の場合のみ輸入料金を表示 */}
            {charge === TransportType.TRILATERAL && (
              <FhdDetail
                companyId={estimate?.forwarderUser.companyId ?? ''}
                arrival={estimate?.arrival ?? ''}
                totalCw={totalCw}
                totalGw={totalGw}
                unit={unit}
              />
            )}
          </Stack>
          <LabelValue
            title={t('excludingTax')}
            value={`¥${roundToDecimal(totalFee, 0).toLocaleString()}`}
            valueWeight="700"
            valueSize="24px"
            width="260px"
            color="#3F6CDF"
          />
          <LabelValue
            title={t('consumptionTax')}
            value={`¥${roundToDecimal(totalFeeTax, 0).toLocaleString()}`}
            valueWeight="700"
            valueSize="24px"
            width="260px"
            color="#3F6CDF"
          />
          <LabelValue
            title={t('totalFee')}
            value={`¥${roundToDecimal(totalFeeIncludeTax, 0).toLocaleString()}`}
            valueWeight="700"
            valueSize="24px"
            width="260px"
            color="#3F6CDF"
          />
          <Stack flexDirection="row">
            <Button
              variant="gray"
              size="small"
              style={{
                marginRight: '24px',
              }}
              onClick={() =>
                navigate(location.state.previousPath, {
                  state: {
                    estimateId: location.state.estimateId,
                    totalCw: location.state.totalCw,
                    totalGw: location.state.totalGw,
                    totalFee: location.state.totalFee,
                    totalFeeTax: location.state.totalFeeTax,
                    totalFeeIncludeTax: location.state.totalFeeIncludeTax,
                    searchItems: location.state.searchItems,
                    shippingInstruction: location.state.shippingInstruction,
                    charge: location.state.charge,
                    unit: location.state.unit,
                  },
                })
              }>
              {t('back')}
            </Button>
            <LoadingButton
              size="small"
              onClick={onSubmit}
              isLoading={isLoading}>
              {t('makeTentativeBooking')}
            </LoadingButton>
          </Stack>
        </Stack>
      </Inner>
    </Stack>
  );
};
