import 'react-phone-input-2/lib/style.css';
import {zodResolver} from '@hookform/resolvers/zod';
import {Stack, Button} from '@mui/material';
import * as Sentry from '@sentry/react';
import {useEffect, useCallback, useState} from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {FormInput, formInput} from './CreateFob';
import {Fob} from '../../API';
import {CustomItems} from '../../components/CustomItems';
import {LabelTextField} from '../../components/LabelTextField';
import {Title} from '../../components/Title';
import {
  useCreateCustomFeeMutation,
  useUpdateCustomFeeMutation,
  useDeleteCustomFeeMutation,
} from '../../queries/customFee';
import {useUpdateFobMutation} from '../../queries/fob';
import {useFob} from '../../queries/fob';
import {assert} from '../../utils/assert';
import {isNonNull} from '../../utils/isEmpty';

export const UpdateFobScreen = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const {mutateAsync: updateFobMutation} = useUpdateFobMutation();
  const {mutateAsync: updateCustomFeeMutation} = useUpdateCustomFeeMutation();
  const {mutateAsync: createCustomFeeMutation} = useCreateCustomFeeMutation();
  const {mutateAsync: deleteCustomFeeMutation} = useDeleteCustomFeeMutation();
  const state = location.state as {
    id: string;
  };
  const {data: fob} = useFob(state.id);
  const [sortedFob, setSortedFob] = useState<Fob | null>(null);

  const methods = useForm<FormInput>({
    defaultValues: {
      remark: fob?.remark,
      customItems:
        sortedFob?.customItems?.items.map(item => ({
          id: item?.id,
          title: item?.title,
          unitPrice: item?.unitPrice,
          unitType: item?.unitType,
          calculationMethod: item?.calculationMethod,
          customFeeCurrencyId: item?.customFeeCurrencyId ?? undefined,
          minimum: item?.minimum,
          maximum: item?.maximum,
          includeTax: item?.includeTax ?? undefined,
          taxRate: item?.taxRate,
          remark: item?.remark,
        })) || [],
    },
    mode: 'all',
    criteriaMode: 'all',
    resolver: zodResolver(formInput),
  });

  const {control, handleSubmit, reset} = methods;

  useEffect(() => {
    reset({
      remark: fob?.remark,
      customItems:
        sortedFob?.customItems?.items.map(item => ({
          id: item?.id,
          title: item?.title,
          unitPrice: item?.unitPrice,
          unitType: item?.unitType,
          calculationMethod: item?.calculationMethod,
          customFeeCurrencyId: item?.customFeeCurrencyId ?? undefined,
          minimum: item?.minimum,
          maximum: item?.maximum,
          includeTax: item?.includeTax ?? undefined,
          taxRate: item?.taxRate,
          remark: item?.remark,
        })) || [],
    });
  }, [sortedFob?.customItems?.items, fob?.remark, reset, sortedFob?.remark]);

  const updateCustomFees: SubmitHandler<FormInput> = useCallback(
    async data => {
      try {
        assert(
          fob,
          'An error occurs because fob is undefined in UpdateFobScreen',
        );
        // Fobの備考を更新
        await updateFobMutation({
          input: {
            id: fob.id,
            remark: data.remark,
          },
        });
        for (const customItem of data.customItems) {
          assert(
            customItem,
            'An error occurs because customItem is undefined in UpdateFobScreen',
          );

          if (customItem.id !== '') {
            // カスタム料金の項目が更新された場合
            await updateCustomFeeMutation(
              {
                input: {
                  id: customItem.id,
                  title: customItem.title,
                  unitPrice: customItem.unitPrice,
                  unitType: customItem.unitType,
                  calculationMethod: customItem.calculationMethod,
                  minimum: customItem.minimum,
                  maximum: customItem.maximum,
                  includeTax: customItem.includeTax,
                  taxRate: customItem.taxRate,
                  remark: customItem.remark,
                  fobId: fob.id,
                  customFeeCurrencyId: customItem.customFeeCurrencyId,
                },
              },
              {
                onError: err => {
                  Sentry.captureException(err);
                  return navigate('error');
                },
              },
            );
          } else {
            // カスタム料金の項目が追加された場合
            await createCustomFeeMutation(
              {
                input: {
                  title: customItem.title,
                  unitPrice: customItem.unitPrice,
                  unitType: customItem.unitType,
                  calculationMethod: customItem.calculationMethod,
                  minimum: customItem.minimum,
                  maximum: customItem.maximum,
                  includeTax: customItem.includeTax,
                  taxRate: customItem.taxRate,
                  remark: customItem.remark,
                  fobId: fob.id,
                  customFeeCurrencyId: customItem.customFeeCurrencyId,
                },
              },
              {
                onError: err => {
                  Sentry.captureException(err);
                  return navigate('error');
                },
              },
            );
          }
        }
        fob.customItems?.items.filter(isNonNull).map(async item => {
          if (
            !data.customItems.map(item => item && item.id).includes(item.id)
          ) {
            // カスタム料金が削除された場合
            await deleteCustomFeeMutation(
              {
                input: {id: item.id},
              },
              {
                onError: err => {
                  Sentry.captureException(err);
                  return navigate('error');
                },
              },
            );
          }
        });
        navigate('/fobFhd');
      } catch {
        navigate('error');
      }
    },
    [
      createCustomFeeMutation,
      deleteCustomFeeMutation,
      fob,
      navigate,
      updateCustomFeeMutation,
      updateFobMutation,
    ],
  );

  useEffect(() => {
    document.title = `${t('updateFob')} | MAST`;
  }, [t]);

  useEffect(() => {
    if (fob?.customItems?.items) {
      const sortedItems = [...fob.customItems.items].sort(
        (a, b) =>
          new Date(a?.createdAt ?? '').getTime() -
          new Date(b?.createdAt ?? '').getTime(),
      );
      const sortedFob = {
        ...fob,
        customItems: {
          ...fob.customItems,
          items: sortedItems,
        },
      } as Fob;
      setSortedFob(sortedFob);
    } else {
      setSortedFob(null);
    }
  }, [fob]);

  return (
    <>
      <FormProvider {...methods}>
        <Stack ml={20} alignItems="start" flexDirection="column">
          <Title title={`${t('exportCharge')} ${t('edit')}`} />
          <CustomItems minimumItems={1} />
          <Stack
            flexDirection="column"
            alignContent="center"
            sx={{
              width: '690px',
              display: 'flex',
              gap: '10px',
            }}>
            <Controller
              name="remark"
              control={control}
              render={({field}) => (
                <LabelTextField
                  title={t('remark')}
                  {...field}
                  multiline={true}
                  minRows={3}
                  fullWidth
                />
              )}
            />
          </Stack>
          <Stack flexDirection="row" mt={5}>
            <Button
              variant="gray"
              size="large"
              onClick={() => {
                navigate('/fobFhd');
              }}>
              {t('cancel')}
            </Button>
            <Button
              variant="primary"
              type="submit"
              size="large"
              style={{marginLeft: '24px'}}
              onClick={handleSubmit(updateCustomFees)}>
              {t('update')}
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
    </>
  );
};
