import {zodResolver} from '@hookform/resolvers/zod';
import {
  Stack,
  TextField,
  Typography,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Link,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import * as Sentry from '@sentry/react';
import {Dayjs} from 'dayjs';
import {useEffect, useState} from 'react';
import {useForm, SubmitHandler, Controller} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import {z} from 'zod';
import {NumberOfEmployees} from '../../API';
import {categoryOptions} from '../../data/categoryOptions';
import {useOAuthUserState} from '../../providers/AuthProvider';
import {useCreateShipperCompanyMutation} from '../../queries/shipperCompany';
import {useUpdateShipperUserMutation} from '../../queries/shipperUser';
import {toAWSDate} from '../../utils/toAWSDate';

// TODO: 処理自体を分けてanyのものにもバリデーションかける
export const formInput = z.object({
  companyName: z.string().min(1),
  corporateNumber: z.string().min(1),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  location: z.string().min(1),
  establishment: z.string().min(1),
  paidInCaptial: z.number().min(1),
  numberOfEmployees: z.any(),
  listed: z.boolean(),
  companyURL: z.string().url(),
  category: z.string().min(1),
});

export type FormInput = z.infer<typeof formInput>;

export const SignUpShipperCompanyInfoScreen = () => {
  const {t} = useTranslation();
  const userState = useOAuthUserState();
  const sub = userState.user?.attributes.sub ?? '';
  const navigate = useNavigate();
  const {mutateAsync: updateShipperUserMutation} =
    useUpdateShipperUserMutation();
  const {mutateAsync: createShipperCompanyMutation} =
    useCreateShipperCompanyMutation();
  const firstName = localStorage.getItem('firstName') ?? '';
  const lastName = localStorage.getItem('lastName') ?? '';

  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<FormInput>({
    mode: 'all',
    defaultValues: {
      firstName,
      lastName,
    },
    criteriaMode: 'all',
    shouldFocusError: false,
    resolver: zodResolver(formInput),
  });

  const [termsAcception, setTermsAcception] = useState(false);

  const onSubmit: SubmitHandler<FormInput> = async data => {
    const companyId = uuid();

    await Promise.all([
      // TODO: paidInCaptial スペルミス修正
      createShipperCompanyMutation({
        input: {
          id: companyId,
          name: data.companyName,
          shipperCompanyManagerId: sub,
          corporateNumber: data.corporateNumber,
          location: data.location,
          listed: data.listed,
          category: data.category,
          paidInCaptial: data.paidInCaptial,
          establishment: data.establishment,
          firstName,
          lastName,
          numberOfEmployees: data.numberOfEmployees,
          companyURL: data.companyURL,
        },
      }),
      updateShipperUserMutation({
        input: {
          id: sub,
          companyId: companyId,
        },
      }),
    ]).catch(err => {
      Sentry.captureException(err);
    });
    navigate('/');
  };

  useEffect(() => {
    document.title = `${t('createAccount')} | MAST`;
  }, [t]);

  return (
    <Box
      sx={{
        width: '452px',
        px: '53px',
        py: '53px',
        mx: 'auto',
      }}>
      <Typography mb={5} variant="h1">
        MAST
      </Typography>
      <Typography align="center">{t('registerCompanyInformation')}</Typography>
      <Stack
        direction="column"
        justifyContent="center"
        sx={{mt: '16px'}}
        spacing={1}>
        <Stack
          direction="row"
          spacing={2}
          alignContent="center"
          sx={{
            width: 'calc(652px * 0.3)',
            flexWrap: 'wrap',
          }}>
          <Typography sx={{fontWeight: '500'}}>{t('companyName')}</Typography>
          <Typography
            color="red"
            sx={{ml: '12px', fontWeight: '500', fontSize: '14px'}}>
            {t('requiredField')}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          sx={{
            width: 'calc(652px * 0.7)',
          }}>
          <TextField
            fullWidth
            sx={{
              backgroundColor: '#fff',
            }}
            error={!!errors.companyName}
            {...register('companyName', {
              required: true,
            })}
          />
          {errors.companyName && (
            <Typography variant="error">{t('enterCompanyName')}</Typography>
          )}
        </Stack>
      </Stack>
      <Stack direction="column" justifyContent="center" sx={{mt: '16px'}}>
        <Stack
          direction="row"
          spacing={2}
          alignContent="center"
          sx={{width: '170px', height: '40px', flexWrap: 'wrap'}}>
          <Typography>{t('companyRepresentitive')}</Typography>
          <Typography
            color="red"
            sx={{ml: '12px', fontWeight: '500', fontSize: '14px'}}>
            {t('requiredField')}
          </Typography>
        </Stack>
        <Stack direction="row">
          <Stack direction="column" sx={{width: 'calc((652px * 0.35) - 4px)'}}>
            <TextField
              fullWidth
              sx={{
                backgroundColor: '#fff',
              }}
              error={!!errors.lastName}
              {...register('lastName', {required: true})}
            />
            {errors.lastName && (
              <Typography variant="error">{t('enterLastName')}</Typography>
            )}
          </Stack>
          <Stack
            direction="column"
            sx={{width: 'calc((652px * 0.35) - 4px)', pl: '8px'}}>
            <TextField
              fullWidth
              error={!!errors.firstName}
              sx={{
                backgroundColor: '#fff',
              }}
              {...register('firstName', {required: true})}
            />
            {errors.firstName && (
              <Typography variant="error">{t('enterFirstName')}</Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack
        direction="column"
        justifyContent="center"
        sx={{mt: '16px'}}
        spacing={1}>
        <Stack
          direction="row"
          spacing={2}
          alignContent="center"
          sx={{
            width: 'calc(652px * 0.3)',
            flexWrap: 'wrap',
          }}>
          <Typography sx={{fontWeight: '500'}}>
            {t('headquartersAddress')}
          </Typography>
          <Typography
            color="red"
            sx={{ml: '12px', fontWeight: '500', fontSize: '14px'}}>
            {t('requiredField')}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          sx={{
            width: 'calc(652px * 0.7)',
          }}>
          <TextField
            fullWidth
            sx={{
              backgroundColor: '#fff',
            }}
            error={!!errors.location}
            {...register('location', {
              required: true,
            })}
          />
          {errors.location && (
            <Typography variant="error">
              {t('enterHeadquatersAddress')}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack
        direction="column"
        justifyContent="center"
        sx={{mt: '16px'}}
        spacing={1}>
        <Stack
          direction="row"
          spacing={2}
          alignContent="center"
          sx={{
            width: 'calc(652px * 0.3)',
            flexWrap: 'wrap',
          }}>
          <Typography sx={{fontWeight: '500'}}>
            {t('establishmentDate')}
          </Typography>
          <Typography
            color="red"
            sx={{ml: '12px', fontWeight: '500', fontSize: '14px'}}>
            {t('requiredField')}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          sx={{
            width: 'calc(652px * 0.7)',
          }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              name="establishment"
              control={control}
              render={({field}) => {
                return (
                  <DatePicker
                    format="YYYY/M/D"
                    slotProps={{
                      textField: {
                        size: 'small',
                        placeholder: '',
                        error: !!errors.establishment,
                      },
                    }}
                    sx={{
                      backgroundColor: '#fff',
                    }}
                    onChange={(newValue: Dayjs | null) => {
                      if (newValue)
                        field.onChange(toAWSDate(newValue.toDate()));
                    }}
                  />
                );
              }}
            />
          </LocalizationProvider>
          {!!errors.establishment && (
            <Typography variant="error">
              {t('enterEstablishmentDate')}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack
        direction="column"
        justifyContent="center"
        sx={{mt: '16px'}}
        spacing={1}>
        <Stack
          direction="row"
          spacing={2}
          alignContent="center"
          sx={{
            width: 'calc(652px * 0.3)',
            flexWrap: 'wrap',
          }}>
          <Typography sx={{fontWeight: '500'}}>{t('capitalAmount')}</Typography>
          <Typography
            color="red"
            sx={{ml: '12px', fontWeight: '500', fontSize: '14px'}}>
            {t('requiredField')}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          sx={{
            width: 'calc(652px * 0.7)',
          }}>
          <TextField
            fullWidth
            type="number"
            sx={{
              backgroundColor: '#fff',
            }}
            error={!!errors.paidInCaptial}
            {...register('paidInCaptial', {
              required: true,
              valueAsNumber: true,
            })}
          />
          {errors.paidInCaptial && (
            <Typography variant="error">{t('enterCapitalAmount')}</Typography>
          )}
        </Stack>
      </Stack>
      <Stack
        direction="column"
        justifyContent="center"
        sx={{mt: '16px'}}
        spacing={1}>
        <Stack
          direction="row"
          spacing={2}
          alignContent="center"
          sx={{
            width: 'calc(652px * 0.3)',
            flexWrap: 'wrap',
          }}>
          <Typography sx={{fontWeight: '500'}}>
            {t('corporateRegistrationNumber')}
          </Typography>
          <Typography
            color="red"
            sx={{ml: '12px', fontWeight: '500', fontSize: '14px'}}>
            {t('requiredField')}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          sx={{
            width: 'calc(652px * 0.7)',
          }}>
          <TextField
            fullWidth
            sx={{
              backgroundColor: '#fff',
            }}
            error={!!errors.corporateNumber}
            {...register('corporateNumber', {
              required: true,
            })}
          />
          <Typography sx={{fontSize: '12px'}}>
            <Trans
              i18nKey="checkCorporateRegistrationNumber"
              components={{
                link_here: (
                  <a href="https://info.gbiz.go.jp/" target="_blank"></a>
                ),
              }}
            />
          </Typography>
          {errors.corporateNumber && (
            <Typography variant="error">
              {t('enterCorporateRegistrationNumber')}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack
        direction="column"
        justifyContent="center"
        sx={{mt: '16px'}}
        spacing={1}>
        <Stack
          direction="row"
          spacing={2}
          alignContent="center"
          sx={{
            width: 'calc(652px * 0.3)',
            flexWrap: 'wrap',
          }}>
          <Typography sx={{fontWeight: '500'}}>{t('industry')}</Typography>
          <Typography
            color="red"
            sx={{ml: '12px', fontWeight: '500', fontSize: '14px'}}>
            {t('requiredField')}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          sx={{
            width: 'calc(652px * 0.7)',
          }}>
          <Controller
            name="category"
            control={control}
            defaultValue=""
            render={({field}) => (
              <>
                <Select
                  labelId="area-label"
                  {...field}
                  size="small"
                  sx={{
                    backgroundColor: '#fff',
                    paddingTop: '2.5px',
                    paddingBottom: '2.5px',
                  }}>
                  {categoryOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.category && (
                  <Typography variant="error">{t('enterIndustry')}</Typography>
                )}
              </>
            )}
          />
        </Stack>
      </Stack>
      <Stack
        direction="column"
        justifyContent="center"
        sx={{mt: '16px'}}
        spacing={1}>
        <Stack
          direction="row"
          spacing={2}
          alignContent="center"
          sx={{
            width: 'calc(652px * 0.3)',
            flexWrap: 'wrap',
          }}>
          <Typography sx={{fontWeight: '500'}}>{t('category')}</Typography>
          <Typography
            color="red"
            sx={{ml: '12px', fontWeight: '500', fontSize: '14px'}}>
            {t('requiredField')}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          sx={{
            width: 'calc(652px * 0.7)',
          }}>
          <Controller
            name="listed"
            control={control}
            defaultValue={true}
            render={({field}) => (
              <FormControl
                sx={{
                  pl: '10px',
                }}>
                <RadioGroup
                  aria-labelledby="radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={field.value}
                  onChange={e => field.onChange(e.target.value === 'true')}>
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label={t('listedCompany')}
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label={t('unlistedCompany')}
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Stack>
      </Stack>
      <Stack
        direction="column"
        justifyContent="center"
        sx={{mt: '16px'}}
        spacing={1}>
        <Stack
          direction="row"
          spacing={2}
          alignContent="center"
          sx={{
            width: 'calc(652px * 0.3)',
            flexWrap: 'wrap',
          }}>
          <Typography sx={{fontWeight: '500'}}>
            {t('employeesNumber')}
          </Typography>
          <Typography
            color="red"
            sx={{ml: '12px', fontWeight: '500', fontSize: '14px'}}>
            {t('requiredField')}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          sx={{
            width: 'calc(652px * 0.7)',
          }}>
          <Controller
            name="numberOfEmployees"
            control={control}
            defaultValue={NumberOfEmployees.UNDER5}
            render={({field}) => (
              <FormControl
                sx={{
                  pl: '10px',
                }}>
                <RadioGroup
                  aria-labelledby="radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={field.value}
                  onChange={e => field.onChange(e.target.value)}>
                  <FormControlLabel
                    value={NumberOfEmployees.UNDER5}
                    control={<Radio />}
                    label={t('lessFivePeople')}
                  />
                  <FormControlLabel
                    value={NumberOfEmployees.UNDER20}
                    control={<Radio />}
                    label={t('lessTwentyPeople')}
                  />
                  <FormControlLabel
                    value={NumberOfEmployees.UNDER100}
                    control={<Radio />}
                    label={t('lessHundredPeople')}
                  />
                  <FormControlLabel
                    value={NumberOfEmployees.UNDER300}
                    control={<Radio />}
                    label={t('lessThreeHundredPeople')}
                  />
                  <FormControlLabel
                    value={NumberOfEmployees.OVER300}
                    control={<Radio />}
                    label={t('moreThreeHundredPeople')}
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
          {errors.numberOfEmployees && (
            <Typography variant="error">
              {t('enterNumberOfEmployees')}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack
        direction="column"
        justifyContent="center"
        sx={{mt: '16px'}}
        spacing={1}>
        <Stack
          direction="row"
          spacing={2}
          alignContent="center"
          sx={{
            width: 'calc(652px * 0.3)',
            flexWrap: 'wrap',
          }}>
          <Typography sx={{fontWeight: '500'}}>{t('homepageLink')}</Typography>
          <Typography
            color="red"
            sx={{ml: '12px', fontWeight: '500', fontSize: '14px'}}>
            {t('requiredField')}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          sx={{
            width: 'calc(652px * 0.7)',
          }}>
          <TextField
            fullWidth
            sx={{
              backgroundColor: '#fff',
            }}
            error={!!errors.companyURL}
            {...register('companyURL', {
              required: true,
            })}
          />
          {errors.companyURL && (
            <Typography variant="error">{t('enterHPLink')}</Typography>
          )}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{mt: '32px'}}>
        <Checkbox
          checked={termsAcception}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setTermsAcception(event.target.checked);
          }}
        />
        <Trans
          i18nKey="agreement"
          components={{
            link_terms: (
              <Link
                sx={{ml: '8px'}}
                href="https://www.notion.so/cozmic/3f158b6aed084d5880574720c4f5829b?pvs=4"
                underline="hover"
                target="_blank"></Link>
            ),
            link_privacy: (
              <Link
                href="https://www.notion.so/cozmic/de2c8de607bf471285cdf0f1d46627be?pvs=4"
                underline="hover"
                target="_blank"></Link>
            ),
          }}
        />
      </Stack>
      <Stack alignItems="center" justifyContent="center" sx={{mt: '24px'}}>
        <Button
          size="large"
          variant="primary"
          type="submit"
          disabled={
            !!errors.companyName ||
            !!errors.corporateNumber ||
            !!errors.firstName ||
            !!errors.lastName ||
            !!errors.location ||
            !!errors.establishment ||
            !!errors.paidInCaptial ||
            !!errors.category ||
            !!errors.companyURL ||
            !termsAcception
          }
          onClick={handleSubmit(onSubmit)}>
          {t('register')}
        </Button>
      </Stack>
    </Box>
  );
};
