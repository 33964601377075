import {Stack, Typography} from '@mui/material';
import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {Dayjs} from 'dayjs';

type LabelDatePickerProps = {
  value?: Dayjs;
  title: string;
  error?: string;
} & DatePickerProps<Dayjs | null>;

export const LabelDatePicker: React.FC<LabelDatePickerProps> = ({
  title,
  value,
  error,
  ...datePickerProps
}) => {
  return (
    <Stack direction="column" gap="9px">
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '21px',
          color: '#000000',
        }}>
        {title}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          format="YYYY/M/D"
          value={value || undefined}
          slotProps={{
            textField: {
              size: 'small',
              error: !!error,
              helperText: error,
            },
          }}
          {...datePickerProps}
        />
      </LocalizationProvider>
    </Stack>
  );
};
