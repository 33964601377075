export const airlines = [
  {id: 1, name: 'To Be Determined', code: 'TBD'},
  {id: 2, name: '3U / Sichuan Airlines', code: '3U'},
  {id: 3, name: '5J / Cebu Pacific', code: '5J'},
  {id: 4, name: '5X / UPS', code: '5X'},
  {id: 5, name: '7C / Jeju Air', code: '7C'},
  {id: 6, name: 'AA / American Airlines', code: 'AA'},
  {id: 7, name: 'AC / Air Canada', code: 'AC'},
  {id: 8, name: 'AF / Air France', code: 'AF'},
  {id: 9, name: 'AI / Air India', code: 'AI'},
  {id: 10, name: 'AM / Aeroméxico', code: 'AM'},
  {id: 11, name: 'AY / Finnair', code: 'AY'},
  {id: 12, name: 'AZ / Alitalia Airlines', code: 'AZ'},
  {id: 13, name: 'BA / British Airways', code: 'BA'},
  {id: 14, name: 'BR / EVA Airways', code: 'BR'},
  {id: 15, name: 'C8 / Cargolux Italia', code: 'C8'},
  {id: 16, name: 'CA / Air China', code: 'CA'},
  {id: 17, name: 'CF / China Postal Airlines', code: 'CF'},
  {id: 18, name: 'CI / China Airlines', code: 'CI'},
  {id: 19, name: 'CM / Copa Airlines', code: 'CM'},
  {id: 20, name: 'CV / Cargolux Airlines', code: 'CV'},
  {id: 21, name: 'CX / Cathay Pacific Airways', code: 'CX'},
  {id: 22, name: 'CZ / China Southern Airlines', code: 'CZ'},
  {id: 23, name: 'D7 / AirAsia X', code: 'D7'},
  {id: 24, name: 'DL / Delta Air Lines', code: 'DL'},
  {id: 25, name: 'E6 / Bringer Air Cargo', code: 'E6'},
  {id: 26, name: 'EK / Emirates', code: 'EK'},
  {id: 27, name: 'ET / Ethiopian Airlines', code: 'ET'},
  {id: 28, name: 'EY / Etihad Airways', code: 'EY'},
  {id: 29, name: 'FX / FedEx', code: 'FX'},
  {id: 30, name: 'GA / Garuda Indonesia', code: 'GA'},
  {id: 31, name: 'HA / Hawaiian Airlines', code: 'HA'},
  {id: 32, name: 'HX / Hong Kong Airlines', code: 'HX'},
  {id: 33, name: 'HY / Uzbekistan Airways', code: 'HY'},
  {id: 34, name: 'JL / Japan Airlines', code: 'JL'},
  {id: 35, name: 'KE / Korean Air Lines', code: 'KE'},
  {id: 36, name: 'KJ / Air Incheon', code: 'KJ'},
  {id: 37, name: 'KL / KLM', code: 'KL'},
  {id: 38, name: 'KZ / Nippon Cargo Airlines', code: 'KZ'},
  {id: 39, name: 'LA / LATAM Airlines', code: 'LA'},
  {id: 40, name: 'LH / Lufthansa', code: 'LH'},
  {id: 41, name: 'LO / LOT Polish Airlines', code: 'LO'},
  {id: 42, name: 'LX / Swiss Air Lines', code: 'LX'},
  {id: 43, name: 'LY / El Al Israel Airlines', code: 'LY'},
  {id: 44, name: 'M6 / Amerijet International', code: 'M6'},
  {id: 45, name: 'M7 / MasAir', code: 'M7'},
  {id: 46, name: 'MF / Xiamen Airlines', code: 'MF'},
  {id: 47, name: 'MH / Malaysia Airlines', code: 'MH'},
  {id: 48, name: 'MS / Egyptair', code: 'MS'},
  {id: 49, name: 'MU / China Eastern Airlines', code: 'MU'},
  {id: 50, name: 'NH / All Nippon Airways', code: 'NH'},
  {id: 51, name: 'NX / Air Macau', code: 'NX'},
  {id: 52, name: 'NZ / Air New Zealand', code: 'NZ'},
  {id: 53, name: 'OK / Czech Airlines', code: 'OK'},
  {id: 54, name: 'OM / MIAT Mongolian Airlines', code: 'OM'},
  {id: 55, name: 'OZ / Asiana Airlines', code: 'OZ'},
  {id: 56, name: 'PK / Pakistan Airlines', code: 'PK'},
  {id: 57, name: 'PO / Polar Air Cargo', code: 'PO'},
  {id: 58, name: 'PR / Philippine Airlines', code: 'PR'},
  {id: 59, name: 'PX / Air Niugini', code: 'PX'},
  {id: 60, name: 'QF / Qantas Airways', code: 'QF'},
  {id: 61, name: 'QR / Qatar Airways', code: 'QR'},
  {id: 62, name: 'QT / Avianca Cargo', code: 'QT'},
  {id: 63, name: 'RU / AirBridgeCargo Airlines', code: 'RU'},
  {id: 64, name: 'SA / South African Airways', code: 'SA'},
  {id: 65, name: 'SC / Shandong Airlines', code: 'SC'},
  {id: 66, name: 'SK / Scandinavian Airlines', code: 'SK'},
  {id: 67, name: 'SQ / Singapore Airlines', code: 'SQ'},
  {id: 68, name: 'SU / Aeroflot', code: 'SU'},
  {id: 69, name: 'SV / Saudi Arabian Airlines', code: 'SV'},
  {id: 70, name: 'TG / Thai Airways', code: 'TG'},
  {id: 71, name: 'TK / Turkish Airlines', code: 'TK'},
  {id: 72, name: 'TN / Air Tahiti Nui', code: 'TN'},
  {id: 73, name: 'UA / United Airlines', code: 'UA'},
  {id: 74, name: 'UL / SriLankan Airlines', code: 'UL'},
  {id: 75, name: 'UO / Hong Kong Express', code: 'UO'},
  {id: 76, name: 'VN / Vietnam Airlines', code: 'VN'},
  {id: 77, name: 'XJ / Thai Airasia X', code: 'XJ'},
  {id: 78, name: 'Y8 / Suparna Airlines', code: 'Y8'},
] as const;
