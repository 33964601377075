export const calculateTaxFee = (
  feeAfterLimits: number,
  onlyTax: boolean,
  includeTax?: boolean | null,
  taxRate?: number | null,
) => {
  // 税の計算
  return onlyTax
    ? includeTax
      ? Math.round(feeAfterLimits * ((taxRate ?? 0) / 100))
      : 0
    : includeTax
    ? Math.round(feeAfterLimits * (1 + (taxRate ?? 0) / 100))
    : feeAfterLimits;
};
