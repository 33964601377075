import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import {
  Grid,
  Stack,
  Box,
  Typography,
  Divider,
  Dialog,
  Drawer,
  Button,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import {useState, useEffect, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {
  CalculationMethod,
  CustomFee,
  Estimate,
  EstimateStatus,
} from '../../API';
import {DepartureArrivalAirports} from '../../components/DepartureArrivalAirports';
import {EstimateFilter} from '../../components/EstimateFilter';
import {LabelValue} from '../../components/LabelValue';
import {Title} from '../../components/Title';
import {airlines} from '../../data/airlines';
import {airports} from '../../data/airports';
import {PlaneIcon} from '../../images/PlaneIcon';
import Inner from '../../layouts/Inner';
import {useOAuthUserState} from '../../providers/AuthProvider';
import {useCurrencies} from '../../queries/currency';
import {
  useEstimates,
  useUpdateEstimateMutation,
  useEstimate,
} from '../../queries/estimate';
import {OmitedCustomFee} from '../../types/index';
import {getLocalizedCalculationMethod} from '../../utils/getLocalizedCalculationMethod';
import {getLocalizedCargoType} from '../../utils/getLocalizedCargoType';
import {isNonNull} from '../../utils/isEmpty';

export const EstimateListScreen = () => {
  const {t} = useTranslation();
  const userState = useOAuthUserState();
  const navigate = useNavigate();

  const [departure, setDeparture] = useState('');
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [selectedEstimateId, setSelectedEstimateId] = useState<null | string>(
    null,
  );
  const {data: estimate} = useEstimate(selectedEstimateId ?? '');
  const {data: currencies} = useCurrencies();
  const {data: estimates} = useEstimates({
    userId: userState.user?.attributes.sub,
    departure: departure,
  });
  const {mutateAsync: updateEstimateMutation} = useUpdateEstimateMutation();

  const [customFees, setCustomFees] = useState<OmitedCustomFee[]>([
    {
      id: '',
      title: '',
      unitPrice: 0,
      unitType: t('shipment'),
      calculationMethod: CalculationMethod.PER_SHIPMENT,
      customFeeCurrencyId: null,
      minimum: null,
      maximum: null,
      includeTax: null,
      taxRate: 0.0,
      remark: '',
      createdAt: '',
    },
  ]);

  useEffect(() => {
    if (estimate?.customItems?.items) {
      setCustomFees(
        estimate.customItems.items
          .filter((item): item is CustomFee => item !== null)
          .sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
          )
          .map(item => {
            return {
              id: item.id,
              title: item.title,
              unitPrice: item.unitPrice,
              unitType: item.unitType,
              calculationMethod: item.calculationMethod,
              customFeeCurrencyId: item.customFeeCurrencyId ?? null,
              minimum: item.minimum,
              maximum: item.maximum,
              includeTax: item.includeTax ?? null,
              taxRate: item.taxRate ?? 0.0,
              remark: item.remark ?? '',
              createdAt: item.createdAt,
            };
          }),
      );
    }
  }, [estimate, selectedEstimateId]);

  const closeEstimateStatus = useCallback(() => {
    if (!selectedEstimateId) {
      return null;
    }
    updateEstimateMutation(
      {
        input: {
          id: selectedEstimateId,
          status: EstimateStatus.CLOSED,
        },
      },
      {
        onError: err => {
          Sentry.captureException(err);
          return navigate('error');
        },
      },
    );
    const updatedEstimateIndex = estimates?.items.findIndex(
      estimate => estimate?.id === selectedEstimateId,
    );
    if (
      updatedEstimateIndex !== undefined &&
      updatedEstimateIndex !== -1 &&
      estimates?.items[updatedEstimateIndex]
    ) {
      (estimates.items[updatedEstimateIndex] as Estimate).status =
        EstimateStatus.CLOSED;
    }
    setIsConfirmOpen(false);
    setIsDetailOpen(false);
    setSelectedEstimateId(null);
  }, [estimates, navigate, selectedEstimateId, updateEstimateMutation]);

  const showDetail = (estimateId: string) => {
    setSelectedEstimateId(estimateId);
    setIsDetailOpen(true);
  };

  const filterEstimate = useCallback((departure: string) => {
    setDeparture(departure);
  }, []);

  useEffect(() => {
    document.title = `${t('quotationsList')} | MAST`;
  }, [t]);

  // estimates?.items.find で見積もりを取得
  const selectedEstimate = estimates?.items.find(
    estimate => estimate?.id === selectedEstimateId,
  );

  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          <EstimateFilter
            departure={departure}
            filterEstimate={filterEstimate}
          />
        </Grid>
        <Grid item xs={8}>
          <Stack flexDirection="row" alignItems="center" mb={3} width="765px">
            <Title title={t('quotation')} />
            <Button
              variant="outlined"
              type="submit"
              style={{marginLeft: '32px'}}
              onClick={() => {
                navigate('/createEstimate');
              }}
              startIcon={<AddIcon />}>
              {t('newAddition')}
            </Button>
          </Stack>
          <Stack sx={{width: '765px'}} spacing={3}>
            {estimates?.items
              ?.filter(isNonNull)
              .sort(
                (a, b) =>
                  new Date(b.flightDate).getTime() -
                  new Date(a.flightDate).getTime(),
              )
              .map(estimate => {
                if (estimate.status === 'CLOSED') {
                  return <></>;
                }
                return (
                  <Inner key={estimate.id} sx={{padding: '20px'}}>
                    <Stack spacing={3}>
                      <Stack
                        justifyContent="space-between"
                        alignItems="center"
                        flexDirection="row">
                        <Typography>
                          {t('airline')}:
                          {airlines.find(
                            airline => airline.code === estimate.airline,
                          )?.name ?? ''}
                        </Typography>
                        <Box
                          sx={{
                            backgroundColor: estimate.directFlight
                              ? '#DE6E54'
                              : '#33BDD4',
                            paddingX: '10px',
                            paddingY: '5px',
                            borderRadius: '6px',
                            color: '#333333',
                          }}>
                          <Typography
                            sx={{
                              color: 'white',
                              fontWeight: '600',
                              fontSize: '13px',
                            }}>
                            {estimate.directFlight
                              ? t('directFlight')
                              : t('connectingFlight')}
                          </Typography>
                        </Box>
                      </Stack>
                      <DepartureArrivalAirports
                        departure={estimate.departure}
                        arrival={estimate.arrival}
                      />
                      <LabelValue
                        title={`${t('etd')}:`}
                        titleWeight="600"
                        value={
                          dayjs(estimate.flightDate).format('YYYY/M/D') ?? ''
                        }
                        row
                        rowValueLeftMaring="8px"
                      />
                      <LabelValue
                        title={`${t('ownWarehouse')} ${t('cut')}:`}
                        titleWeight="600"
                        value={
                          dayjs(estimate.yardCutDate).format('YYYY/M/D') ?? ''
                        }
                        row
                        rowValueLeftMaring="8px"
                      />
                      <LabelValue
                        title={`${t('airlineWarehouseDirectCut')}:`}
                        titleWeight="600"
                        value={
                          dayjs(estimate.carrierShedCutDate).format(
                            'YYYY/M/D',
                          ) ?? ''
                        }
                        row
                        rowValueLeftMaring="8px"
                      />
                      <Stack
                        alignItems="center"
                        flexDirection="row"
                        justifyContent="space-between">
                        <Box width="316px" height="127px">
                          <Typography
                            sx={{
                              lineHeight: '24px',
                              display: '-webkit-box',
                              '-webkit-line-clamp': '5',
                              '-webkit-box-orient': 'vertical',
                              overflow: 'hidden',
                            }}>
                            {estimate.remark}
                          </Typography>
                        </Box>
                        <Box>
                          <Stack alignItems="end">
                            <Button
                              variant="gray"
                              style={{
                                marginTop: '13px',
                              }}
                              onClick={() => {
                                showDetail(estimate.id);
                              }}>
                              {t('viewDetails')}
                            </Button>
                          </Stack>
                          <Stack flexDirection="row">
                            <Button
                              variant="approve"
                              style={{
                                marginTop: '13px',
                              }}
                              onClick={() => {
                                setSelectedEstimateId(estimate.id);
                                setIsConfirmOpen(true);
                              }}>
                              {t('closeBid')}
                            </Button>
                            <Button
                              variant="outlined"
                              style={{
                                marginTop: '13px',
                                marginLeft: '16px',
                              }}
                              onClick={() => {
                                navigate('/updateEstimate', {
                                  state: {id: estimate.id},
                                });
                              }}
                              startIcon={<EditIcon />}>
                              {t('edit')}
                            </Button>
                          </Stack>
                        </Box>
                      </Stack>
                    </Stack>
                  </Inner>
                );
              })}
          </Stack>
        </Grid>
      </Grid>
      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '12px',
          },
        }}
        onClose={() => {
          setIsConfirmOpen(false);
        }}
        open={isConfirmOpen}>
        <Stack
          width="544px"
          height="424px"
          spacing={4}
          justifyContent="center"
          alignItems="center">
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '20px',
              lineHeight: '26px',
            }}>
            {t('closeBidQuestion')}
          </Typography>
          <Stack width="465px" justifyContent="start" spacing={3}>
            <Stack alignItems="center" flexDirection="row">
              <Typography>
                {t('airline')}:
                {airlines.find(
                  airline =>
                    airline.code ===
                    estimates?.items.find(
                      estimate => estimate?.id === selectedEstimateId,
                    )?.airline,
                )?.name ?? ''}
              </Typography>
              <Box
                ml={2}
                sx={{
                  backgroundColor: estimates?.items.find(
                    estimate => estimate?.id === selectedEstimateId,
                  )?.directFlight
                    ? '#DE6E54'
                    : '#33BDD4',
                  paddingX: '10px',
                  paddingY: '5px',
                  borderRadius: '6px',
                  color: '#333333',
                }}>
                <Typography
                  sx={{color: 'white', fontWeight: '600', fontSize: '13px'}}>
                  {estimates?.items.find(
                    estimate => estimate?.id === selectedEstimateId,
                  )?.directFlight
                    ? t('directFlight')
                    : t('connectingFlight')}
                </Typography>
              </Box>
            </Stack>
            <Stack alignItems="center" flexDirection="row">
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '20px',
                  lineHeight: '19.5px',
                }}>
                {airports.find(
                  airport =>
                    airport.code ===
                    estimates?.items.find(
                      estimate => estimate?.id === selectedEstimateId,
                    )?.departure,
                )?.name ?? ''}
              </Typography>
              <Typography ml={1} variant="h5">
                {airports.find(
                  airport =>
                    airport.code ===
                    estimates?.items.find(
                      estimate => estimate?.id === selectedEstimateId,
                    )?.arrival,
                )?.name ?? ''}
              </Typography>
              <Divider
                style={{
                  width: '24px',
                  border: 'dashed 1px #000000',
                  marginLeft: '16px',
                }}
              />
              <PlaneIcon
                style={{
                  transform: 'rotate(90deg)',
                  marginLeft: '16px',
                  width: '18px',
                  height: '19px',
                }}
              />
              <Divider
                style={{
                  width: '24px',
                  border: 'dashed 1px #000000',
                  marginLeft: '16px',
                }}
              />
              <Typography
                ml={2}
                sx={{
                  fontWeight: '600',
                  fontSize: '20px',
                  lineHeight: '19.5px',
                }}>
                {
                  estimates?.items.find(
                    estimate => estimate?.id === selectedEstimateId,
                  )?.arrival
                }
              </Typography>
              <Typography ml={1} variant="h5">
                {
                  estimates?.items.find(
                    estimate => estimate?.id === selectedEstimateId,
                  )?.arrival
                }
              </Typography>
            </Stack>
            <LabelValue
              title={`${t('etd')}:`}
              titleWeight="600"
              value={dayjs(
                estimates?.items.find(
                  estimate => estimate?.id === selectedEstimateId,
                )?.flightDate,
              ).format('YYYY/M/D')}
              row
              rowValueLeftMaring="8px"
            />
            <LabelValue
              title={`${t('ownWarehouse')} ${t('cut')}:`}
              titleWeight="600"
              value={dayjs(
                estimates?.items.find(
                  estimate => estimate?.id === selectedEstimateId,
                )?.yardCutDate,
              ).format('YYYY/M/D')}
              row
              rowValueLeftMaring="8px"
            />
            <LabelValue
              title={`${t('airlineWarehouseDirectCut')}:`}
              titleWeight="600"
              value={dayjs(
                estimates?.items.find(
                  estimate => estimate?.id === selectedEstimateId,
                )?.carrierShedCutDate,
              ).format('YYYY/M/D')}
              row
              rowValueLeftMaring="8px"
            />
          </Stack>
          <Stack flexDirection="row">
            <Button
              variant="outlined"
              style={{width: '180px'}}
              onClick={() => setIsConfirmOpen(false)}>
              {t('cancel')}
            </Button>
            <Button
              variant="approve"
              style={{width: '180px', marginLeft: '24px'}}
              onClick={closeEstimateStatus}>
              {t('closeBid')}
            </Button>
          </Stack>
        </Stack>
      </Dialog>
      <Drawer
        anchor="right"
        open={isDetailOpen}
        onClose={() => setIsDetailOpen(false)}>
        <Box width="761px" mt={5} ml={5}>
          <Stack spacing={3}>
            <Box display="flex" flexDirection="row" mb={3}>
              <Box display="flex" flexDirection="column" width="220px">
                <Typography variant="h5">{t('originAirport')}</Typography>
                <Box display="flex" flexDirection="row" sx={{marginTop: '9px'}}>
                  <FlightTakeoffIcon />
                  <Typography
                    sx={{
                      marginLeft: '12px',
                      fontSize: '20px',
                      fontWeight: 600,
                      lineHeight: '22px',
                    }}>
                    {airports.find(
                      airport =>
                        airport.code ===
                        estimates?.items.find(
                          estimate => estimate?.id === selectedEstimateId,
                        )?.departure,
                    )?.name ?? ''}
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                width="220px"
                sx={{marginLeft: '22px'}}>
                <Typography variant="h5">{t('destinationAirport')}</Typography>
                <Box display="flex" flexDirection="row" sx={{marginTop: '9px'}}>
                  <FlightLandIcon />
                  <Typography
                    sx={{
                      marginLeft: '12px',
                      fontSize: '20px',
                      fontWeight: 600,
                      lineHeight: '22px',
                    }}>
                    {airports.find(
                      airport =>
                        airport.code ===
                        estimates?.items.find(
                          estimate => estimate?.id === selectedEstimateId,
                        )?.arrival,
                    )?.name ?? ''}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row">
              <LabelValue
                title={t('airline')}
                value={
                  airlines.find(
                    airline =>
                      airline.code ===
                        estimates?.items.find(
                          estimate => estimate?.id === selectedEstimateId,
                        )?.airline ?? '',
                  )?.name ?? ''
                }
                width="229px"
              />
              <LabelValue
                title={t('etd')}
                value={dayjs(
                  estimates?.items.find(
                    estimate => estimate?.id === selectedEstimateId,
                  )?.flightDate,
                ).format('YYYY/M/D')}
                sx={{marginLeft: '12px'}}
              />
            </Box>
            <Box display="flex" flexDirection="row">
              <LabelValue
                title={t('flightType')}
                value={
                  estimates?.items.find(
                    estimate => estimate?.id === selectedEstimateId,
                  )?.directFlight
                    ? t('directFlight')
                    : t('connectingFlight')
                }
                width="161px"
              />
              <LabelValue
                title={t('leadTime')}
                value={
                  `${
                    estimates?.items.find(
                      estimate => estimate?.id === selectedEstimateId,
                    )?.leadTime ?? ''
                  }` + t('days')
                }
                width="152px"
                ml={3}
              />
              <LabelValue
                title={t('saf')}
                value={
                  estimates?.items.find(
                    estimate => estimate?.id === selectedEstimateId,
                  )?.useSaf
                    ? t('use')
                    : t('nonUse')
                }
                ml={3}
              />
            </Box>
            <Box display="flex" flexDirection="row">
              <LabelValue
                isRequired
                title={t('commodityTypeExport')}
                value={
                  selectedEstimate?.cargoType !== undefined
                    ? getLocalizedCargoType(selectedEstimate?.cargoType)
                    : ''
                }
                width="214px"
              />
              <Typography
                variant="body2"
                sx={{
                  padding: '7px 0px 7px 12px',
                }}></Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <LabelValue
                title={t('ownWarehouse') + ' ' + t('cut')}
                value={dayjs(
                  estimates?.items.find(
                    estimate => estimate?.id === selectedEstimateId,
                  )?.yardCutDate,
                ).format('YYYY/M/D')}
                width="238px"
              />
              <LabelValue
                title={t('airlineWarehouseDirectCut')}
                value={dayjs(
                  estimates?.items.find(
                    estimate => estimate?.id === selectedEstimateId,
                  )?.carrierShedCutDate,
                ).format('YYYY/M/D')}
                sx={{marginLeft: '13px'}}
              />
            </Box>
            <Box display="flex" flexDirection="row">
              <LabelValue
                isRequired
                title={t('currency')}
                value={
                  currencies?.items.find(
                    currency => currency?.id === estimate?.estimateCurrencyId,
                  )?.code ?? ''
                }
                width="214px"
              />
              <Typography
                variant="body2"
                sx={{
                  padding: '7px 0px 7px 12px',
                }}></Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <LabelValue
                isRequired
                title={t('minimum')}
                value={`${
                  estimates?.items.find(
                    estimate => estimate?.id === selectedEstimateId,
                  )?.minimum ?? ''
                }`}
                width="214px"
              />
              <LabelValue
                isRequired
                title={t('minusFortyFive')}
                value={`${
                  estimates?.items.find(
                    estimate => estimate?.id === selectedEstimateId,
                  )?.under45 ?? ''
                }`}
                width="214px"
                sx={{marginLeft: '22px'}}
              />
              <LabelValue
                isRequired
                title={t('plusFortyFive')}
                value={`${
                  estimates?.items.find(
                    estimate => estimate?.id === selectedEstimateId,
                  )?.over45 ?? ''
                }`}
                width="214px"
                sx={{marginLeft: '22px'}}
              />
            </Box>
            <Box display="flex" flexDirection="row">
              <LabelValue
                isRequired
                title={t('plusHundred')}
                value={`${
                  estimates?.items.find(
                    estimate => estimate?.id === selectedEstimateId,
                  )?.over100 ?? ''
                }`}
                width="214px"
              />
              <LabelValue
                isRequired
                title={t('plusThreeHundred')}
                value={`${
                  estimates?.items.find(
                    estimate => estimate?.id === selectedEstimateId,
                  )?.over300 ?? ''
                }`}
                width="214px"
                sx={{marginLeft: '22px'}}
              />
              <LabelValue
                isRequired
                title={t('plusFiveHundred')}
                value={`${
                  estimates?.items.find(
                    estimate => estimate?.id === selectedEstimateId,
                  )?.over500 ?? ''
                }`}
                width="214px"
                sx={{marginLeft: '22px'}}
              />
            </Box>
            <Box display="flex" flexDirection="row">
              <LabelValue
                isRequired
                title={t('plusThousand')}
                value={`${
                  estimates?.items.find(
                    estimate => estimate?.id === selectedEstimateId,
                  )?.over1000 ?? ''
                }`}
                width="214px"
              />
              <LabelValue
                isRequired
                title={t('fsc')}
                value={`${
                  estimates?.items.find(
                    estimate => estimate?.id === selectedEstimateId,
                  )?.fsc ?? ''
                }`}
                width="214px"
                sx={{marginLeft: '22px'}}
              />
            </Box>
            <LabelValue
              title={t('remark')}
              value={
                estimates?.items.find(
                  estimate => estimate?.id === selectedEstimateId,
                )?.remark ?? ''
              }
              sx={{width: '690px'}}
            />
          </Stack>
          <Typography mt={4} variant="h5">
            {t('customizedItem')}
          </Typography>
          <Stack spacing={2}>
            {customFees.map(item => {
              return (
                <Stack
                  key={item.id}
                  width="696px"
                  sx={{
                    paddingY: '20px',
                    paddingLeft: '20px',
                    border: '1px solid #ACC0F1',
                    borderRadius: '6px',
                  }}>
                  <Typography my={2} variant="h5">
                    {item.title}
                  </Typography>
                  <Box display="flex" flexDirection="row">
                    <LabelValue
                      title={t('unitPrice')}
                      value={item.unitPrice.toLocaleString()}
                      width="152px"
                    />
                    <LabelValue
                      title={t('unit')}
                      value={item.unitType}
                      width="152px"
                      sx={{marginLeft: '16px'}}
                    />
                    <LabelValue
                      title={t('formulaOfCalculation')}
                      value={getLocalizedCalculationMethod(
                        item.calculationMethod,
                      )}
                      sx={{marginLeft: '16px'}}
                    />
                  </Box>
                  <Box display="flex" flexDirection="row">
                    <LabelValue
                      title={t('minimum')}
                      value={item.minimum?.toLocaleString() ?? '0'}
                      width="152px"
                    />
                    <LabelValue
                      title={t('maximum')}
                      value={item.maximum?.toLocaleString() ?? '0'}
                      width="152px"
                      sx={{marginLeft: '16px'}}
                    />
                  </Box>
                  <Box display="flex" flexDirection="row">
                    <LabelValue
                      title={t('currency')}
                      value={
                        currencies?.items.find(
                          currency => currency?.id === item.customFeeCurrencyId,
                        )?.code ?? ''
                      }
                      width="152px"
                    />
                    <LabelValue
                      title={t('taxableItem')}
                      value={item.includeTax ? t('taxableItem') : t('taxFree')}
                      width="152px"
                      sx={{marginLeft: '16px'}}
                    />
                    <LabelValue
                      title={t('taxRate')}
                      value={item.taxRate?.toString() ?? '0'}
                      sx={{marginLeft: '16px'}}
                    />
                  </Box>
                  <LabelValue
                    title={t('remark')}
                    value={item.remark ?? ''}
                    sx={{width: '656px'}}
                  />
                </Stack>
              );
            })}
          </Stack>
          <Stack flexDirection="row" sx={{marginTop: '36px'}}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                navigate('/updateEstimate', {
                  state: {id: selectedEstimateId},
                });
              }}
              startIcon={<EditIcon />}>
              {t('edit')}
            </Button>
            <Button
              variant="approve"
              style={{
                marginLeft: '16px',
              }}
              onClick={() => {
                setIsConfirmOpen(true);
              }}>
              {t('closeBid')}
            </Button>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};
