import {Stack, Typography} from '@mui/material';
import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StyledTable} from './StyledTable';
import {ShippingCustomFee} from '../API';
import {useShippingFee} from '../queries/shippingFee';
import {OmitedShippingCustomFee} from '../types';
import {isNonNull} from '../utils/isEmpty';
import {roundToDecimal} from '../utils/roundToDecimal';

interface ShippingFeeDetailProps {
  shippingFeeId: string;
  totalCw: number | undefined;
}

export const ShippingFeeDetail: FC<ShippingFeeDetailProps> = props => {
  const {t} = useTranslation();
  const {data: shippingFee} = useShippingFee(props.shippingFeeId);

  const totalCw = props.totalCw ?? 0;

  const totalCustomFee =
    shippingFee?.customFees?.items.reduce((total, item) => {
      return (
        total +
        roundToDecimal(
          (item?.currency?.exchangeRateJPY ?? 0) * (item?.fee ?? 0),
          0,
        )
      );
    }, 0) ?? 0;

  const basePrice = shippingFee?.price ?? 0;
  const airfreightPrice = Math.max(
    basePrice * totalCw,
    shippingFee?.minimum ?? 0,
  );
  const airfreightPriceYen = roundToDecimal(
    airfreightPrice * (shippingFee?.currency?.exchangeRateJPY ?? 0),
    0,
  );

  const fsc = shippingFee?.fsc ?? 0;
  const fscPrice = fsc * totalCw;
  const fscPriceYen = roundToDecimal(
    fscPrice * (shippingFee?.currency?.exchangeRateJPY ?? 0),
    0,
  );

  const totalAirFee = airfreightPriceYen + fscPriceYen + totalCustomFee;
  const [shippingCustomFees, setShippingCustomFees] = useState<
    OmitedShippingCustomFee[]
  >([
    {
      id: '',
      title: '',
      currency: {
        __typename: 'Currency',
        id: '',
        code: '',
        exchangeRateJPY: 0,
        createdAt: '',
        updatedAt: '',
        owner: '',
      },
      unitPrice: 0,
      unitType: t('shipment'),
      fee: 0,
      minimum: null,
      maximum: null,
      taxRate: 0.0,
      remark: '',
      createdAt: '',
    },
  ]);

  useEffect(() => {
    if (shippingFee?.customFees?.items) {
      setShippingCustomFees(
        shippingFee.customFees.items
          .filter((item): item is ShippingCustomFee => item !== null)
          .sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
          )
          .map(item => {
            return {
              id: item.id,
              title: item.title,
              unitPrice: item.unitPrice,
              unitType: item.unitType,
              fee: item.fee,
              currency: item.currency,
              minimum: item.minimum,
              maximum: item.maximum,
              taxRate: item.taxRate ?? 0.0,
              remark: item.remark ?? '',
              createdAt: item.createdAt,
            };
          }),
      );
    }
  }, [shippingFee]);

  return (
    <Stack spacing={5}>
      <Stack spacing={3}>
        <Typography variant="h2">{t('airfreight')}</Typography>
        <StyledTable>
          <tr>
            <th>{t('ItemName')}</th>
            <th>{t('currency')}</th>
            <th>{t('unitPrice')}</th>
            <th>{t('unit')}</th>
            <th>{t('minimum')}</th>
            <th>{t('maximum')}</th>
            <th>{t('estimatedAmount')}</th>
            <th>{t('exchangeRate')}</th>
            <th>{t('yenCurrency')}</th>
            <th>{t('remark')}</th>
          </tr>
          <tr>
            <th>{t('airfreight')}</th>
            <td>{shippingFee?.currency?.code}</td>
            <td>{roundToDecimal(basePrice, 2).toLocaleString()}</td>
            <td>kg</td>
            <td>{shippingFee?.minimum?.toLocaleString()}</td>
            <td></td>
            <td>{roundToDecimal(airfreightPrice, 0).toLocaleString()}</td>
            <td>
              {roundToDecimal(
                shippingFee?.currency?.exchangeRateJPY ?? 0,
                2,
              ).toLocaleString()}
            </td>
            <td>{roundToDecimal(airfreightPriceYen, 0).toLocaleString()}</td>
            <td>{shippingFee?.remark ?? ''}</td>
          </tr>
          <tr>
            <th>{t('fsc')}</th>
            <td>{shippingFee?.currency?.code}</td>
            <td>{roundToDecimal(fsc, 2).toLocaleString()}</td>
            <td>kg</td>
            <td></td>
            <td></td>
            <td>{roundToDecimal(fscPrice, 0).toLocaleString()}</td>
            <td>
              {roundToDecimal(
                shippingFee?.currency?.exchangeRateJPY ?? 0,
                2,
              ).toLocaleString()}
            </td>
            <td>{roundToDecimal(fscPriceYen, 0).toLocaleString()}</td>
            <td>{/* NOTE: FSC 備考は無し */}</td>
          </tr>
          {shippingCustomFees?.filter(isNonNull).map(item => (
            <tr>
              <th>{item.title}</th>
              <td>{item.currency?.code}</td>
              <td>{item.unitPrice.toLocaleString()}</td>
              <td>{item.unitType}</td>
              <td>{item.minimum?.toLocaleString()}</td>
              <td>{item.maximum?.toLocaleString()}</td>
              <td>{roundToDecimal(item.fee, 0).toLocaleString()}</td>
              <td>
                {roundToDecimal(
                  item.currency?.exchangeRateJPY,
                  2,
                ).toLocaleString()}
              </td>
              <td>
                {roundToDecimal(
                  (item.currency?.exchangeRateJPY ?? 0) * (item.fee ?? 0),
                  0,
                ).toLocaleString()}
              </td>
              <td>{item.remark}</td>
            </tr>
          ))}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{t('subtotal')}</td>
            <td>{roundToDecimal(totalAirFee ?? 0, 0).toLocaleString()}</td>
            <td></td>
          </tr>
        </StyledTable>
        <Typography>{shippingFee?.remark ?? ''}</Typography>
      </Stack>
    </Stack>
  );
};
