import 'react-phone-input-2/lib/style.css';
import {zodResolver} from '@hookform/resolvers/zod';
import {Button, Stack, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {z} from 'zod';
import {Estimate, TransportType} from '../../API';
import {Airports, InputAirport} from '../../components/InputAirport';
import {InputEstimateItems, Item} from '../../components/InputEstimateItems';
import {SelectExportCharges} from '../../components/SelectExportCharges';
import Inner from '../../layouts/Inner';

export type EstimateInputForm = Omit<
  Estimate,
  '__typename' | 'id' | 'status' | 'forwarderUser'
>;

export const formInput = z.object({
  departure: z.string().min(1),
  arrival: z.string().min(1),
  charge: z.string().min(1),
  items: z.any(),
});

export type FormInput = z.infer<typeof formInput>;

export const SearchEstimateScreen = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const state = location.state as
    | {
        departure: string;
        arrival: string;
        charge: string;
      }
    | undefined;

  const defaultDeparture = state?.departure || '';
  const defaultArrival = state?.arrival || '';
  const defaultCharge = state?.charge || TransportType.AIR_IMPORT;

  useEffect(() => {
    document.title = `${t('searchQuotation')} | MAST`;
  }, [t]);

  const {
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<FormInput>({
    mode: 'all',
    criteriaMode: 'all',
    shouldFocusError: false,
    defaultValues: {
      departure: defaultDeparture,
      arrival: defaultArrival,
      charge: defaultCharge,
    },
    resolver: zodResolver(formInput),
  });
  const onSubmit: SubmitHandler<FormInput> = data => {
    navigate('/searchEstimate/results', {
      state: {
        departure: data.departure,
        arrival: data.arrival,
        charge: data.charge,
        items: data.items,
      },
    });
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleInputAirportChange = (newValue: Airports) => {
    setValue('departure', newValue?.departure ?? '');
    setValue('arrival', newValue?.arrival ?? '');
  };

  const handleAlignment = (newValue: string) => {
    setValue('charge', newValue);
  };

  const handleInputEstimateItemChange = (newValue: Item[]) => {
    setValue('items', newValue);
  };

  const handleButtonStateChange = (newState: boolean) => {
    setIsButtonDisabled(newState);
  };

  return (
    <>
      <Typography variant="h2" mb={3}>
        {t('searchQuotation')}
      </Typography>
      <Inner>
        <Stack spacing={3}>
          <Stack
            spacing={1}
            sx={{
              width: '650px',
            }}>
            <Typography variant="h5">
              {t('route')}
              {errors.departure && (
                <Typography variant="error">
                  {t('enterOriginAirport')}
                </Typography>
              )}
              {errors.arrival && (
                <Typography variant="error">
                  {t('enterDistinationAirport')}
                </Typography>
              )}
            </Typography>
            <InputAirport
              onValueChange={handleInputAirportChange}
              defaultDeparture={defaultDeparture}
              defaultArrival={defaultArrival}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography variant="h5">
              {t('selectPriceConfirmationRange')}
            </Typography>
            <SelectExportCharges
              onValueChange={handleAlignment}
              defaultCharge={defaultCharge}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography variant="h5">{t('size')}</Typography>
            <InputEstimateItems
              onValueChange={handleInputEstimateItemChange}
              onItemsDisableChange={handleButtonStateChange}
            />
          </Stack>
          <Button
            variant="primary"
            type="submit"
            disabled={isButtonDisabled}
            size="large"
            onClick={handleSubmit(onSubmit)}>
            {t('searchNoun')}
          </Button>
        </Stack>
      </Inner>
    </>
  );
};
