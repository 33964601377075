import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery} from 'react-query';
import {
  CreateFobMutation,
  CreateFobMutationVariables,
  DeleteFobMutation,
  DeleteFobMutationVariables,
  FobsByForwarderCompanyIdQuery,
  FobsByForwarderCompanyIdQueryVariables,
  GetFobQuery,
  GetFobQueryVariables,
  ModelFobFilterInput,
  UpdateFobMutation,
  UpdateFobMutationVariables,
} from '../API';
import {createFob, deleteFob, updateFob} from '../graphql/mutations';
import {fobsByForwarderCompanyId, getFob} from '../graphql/queries';

export const useFobs = (companyId: string, departure?: string) => {
  return useQuery(['fobs', companyId, departure], async () => {
    const filter: ModelFobFilterInput = {};
    if (departure) {
      filter.airport = {
        eq: departure,
      };
    }
    const variables: FobsByForwarderCompanyIdQueryVariables = {
      forwarderCompanyId: companyId,
      limit: 10000,
      filter,
    };
    const result = (await API.graphql(
      graphqlOperation(fobsByForwarderCompanyId, variables),
    )) as GraphQLResult<FobsByForwarderCompanyIdQuery>;
    return result?.data?.fobsByForwarderCompanyId?.items;
  });
};

export const useFirstFobs = (companyId: string, departure?: string) => {
  return useQuery(['fobs', companyId, departure], async () => {
    const filter: ModelFobFilterInput = {};
    if (departure) {
      filter.airport = {
        eq: departure,
      };
    }
    const variables: FobsByForwarderCompanyIdQueryVariables = {
      forwarderCompanyId: companyId,
      limit: 10000,
      filter,
    };
    const result = (await API.graphql(
      graphqlOperation(fobsByForwarderCompanyId, variables),
    )) as GraphQLResult<FobsByForwarderCompanyIdQuery>;
    return result?.data?.fobsByForwarderCompanyId?.items?.[0];
  });
};

export const useFob = (id: string) => {
  return useQuery(
    ['fob', id],
    async () => {
      const variable: GetFobQueryVariables = {
        id,
      };
      const result = (await API.graphql(
        graphqlOperation(getFob, variable),
      )) as GraphQLResult<GetFobQuery>;
      return result.data?.getFob;
    },
    {
      enabled: !!id,
    },
  );
};

export const useCreateFobMutation = () => {
  return useMutation(async (variables: CreateFobMutationVariables) => {
    const fob = (await API.graphql(
      graphqlOperation(createFob, variables),
    )) as GraphQLResult<CreateFobMutation>;
    return fob.data?.createFob;
  });
};

export const useUpdateFobMutation = () => {
  return useMutation(async (variables: UpdateFobMutationVariables) => {
    const fob = (await API.graphql(
      graphqlOperation(updateFob, variables),
    )) as GraphQLResult<UpdateFobMutation>;
    return fob.data?.updateFob;
  });
};

export const useDeleteFobMutation = () => {
  return useMutation(async (variables: DeleteFobMutationVariables) => {
    const fob = (await API.graphql(
      graphqlOperation(deleteFob, variables),
    )) as GraphQLResult<DeleteFobMutation>;
    return fob.data?.deleteFob;
  });
};
