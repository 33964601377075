import {CalculationMethod} from '../API';

// カスタム項目一つの料金を計算
export const calculateItemFee = (
  unitPrice: number,
  totalCw: number,
  totalGw: number,
  unit: number,
  calculationMethod: CalculationMethod,
  minimum?: number | null,
  maximum?: number | null,
) => {
  const itemFee = (() => {
    switch (calculationMethod) {
      case CalculationMethod.PER_SHIPMENT:
        return unitPrice;
      case CalculationMethod.PER_UNIT:
        return (unitPrice ?? 0) * (unit ?? 0);
      case CalculationMethod.PER_CW:
        return (unitPrice ?? 0) * (totalCw ?? 0);
      case CalculationMethod.PER_GW:
        return (unitPrice ?? 0) * (totalGw ?? 0);
      case CalculationMethod.ACTUAL_COST:
        return 0;
      default:
        return 0;
    }
  })();

  // 最小値最大値の処理
  const feeAfterLimits = Math.max(
    minimum ?? 0,
    Math.min(itemFee ?? 0, maximum ?? Infinity),
  );

  return feeAfterLimits;
};
