import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import {Typography, Box, Autocomplete, TextField, Button} from '@mui/material';
import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {airports} from '../data/airports';

type FobFhdFilterProps = {
  filteredAirport: string;
  filterFobFhd: (filteredAirport: string) => void;
};

export const FobFhdFilter: FC<FobFhdFilterProps> = (
  props: FobFhdFilterProps,
) => {
  const {t} = useTranslation();
  const [filteredAirport, setFilteredAirport] = useState(props.filteredAirport);

  return (
    <Box>
      <Box
        sx={{
          width: '311px',
          paddingX: '20px',
          paddingY: '20px',
          borderRadius: '12px',
          backgroundColor: 'white',
        }}>
        <Box
          sx={{
            backgroundColor: '#F9F9F9',
            borderRadius: '12px',
          }}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={{marginLeft: '14px', marginRight: '19px', height: '74px'}}>
            <Typography variant="h6">{t('selectAirport')}</Typography>
            <Box display="flex" flexDirection="row" alignItems="center">
              <FlightTakeoffIcon />
              <Autocomplete
                value={airports.find(
                  airport => airport.code === filteredAirport,
                )}
                onChange={(_, newValue) => {
                  setFilteredAirport(newValue?.code ?? '');
                }}
                sx={{width: '100%'}}
                options={airports}
                getOptionLabel={airport => (airport ? airport.name : '')}
                size="small"
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={t('selectAirport')}
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      style: {
                        height: '27px',
                      },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      sx: {
                        height: '27px',
                        paddingY: 'unset !important',
                        paddingLeft: '3px !important',
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
        <Button
          variant="primary"
          type="submit"
          size="small"
          onClick={() => {
            props.filterFobFhd(filteredAirport);
          }}
          style={{marginTop: '24px'}}>
          {t('searchVerb')}
        </Button>
      </Box>
    </Box>
  );
};
