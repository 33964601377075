import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useQuery} from 'react-query';
import {ListNewsQuery, ListNewsQueryVariables} from '../API';
import {listNews} from '../graphql/queries';

export const useNews = () => {
  return useQuery(['news'], async () => {
    const variables: ListNewsQueryVariables = {
      limit: 10000,
    };
    const result = (await API.graphql(
      graphqlOperation(listNews, variables),
    )) as GraphQLResult<ListNewsQuery>;
    return result?.data?.listNews;
  });
};
