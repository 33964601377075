import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import {
  Typography,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Autocomplete,
  TextField,
  Button,
} from '@mui/material';
import React, {FC, useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Status} from '../API';
import {airports} from '../data/airports';

export type ReservationFilterType = {
  departure: string;
  status: Status | undefined;
};

type ReservationFilterProps = {
  filter: ReservationFilterType;
  filterReservation: (filter: ReservationFilterType) => void;
};

export const ReservationFilter: FC<ReservationFilterProps> = (
  props: ReservationFilterProps,
) => {
  const {t} = useTranslation();

  const [departure, setDeparture] = useState(props.filter.departure);
  const [status, setStatus] = useState<Status>(
    props.filter.status ?? Status.PENDING,
  );

  const handleStatus = useCallback(
    (_: React.MouseEvent<HTMLElement>, newValue: Status) => {
      if (newValue !== null) {
        setStatus(newValue);
      }
    },
    [],
  );

  return (
    <Box>
      <Box
        sx={{
          width: '311px',
          paddingX: '20px',
          paddingY: '20px',
          borderRadius: '12px',
          backgroundColor: 'white',
        }}>
        <Box
          sx={{
            backgroundColor: '#F9F9F9',
            borderRadius: '12px',
          }}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={{marginLeft: '14px', marginRight: '19px', height: '74px'}}>
            <Typography variant="h6">{t('originAirport')}</Typography>
            <Box display="flex" flexDirection="row" alignItems="center">
              <FlightTakeoffIcon />
              <Autocomplete
                value={airports.find(airport => airport.code === departure)}
                onChange={(_, newValue) => {
                  setDeparture(newValue?.code ?? '');
                }}
                sx={{width: '100%'}}
                options={airports}
                getOptionLabel={airport => (airport ? airport.name : '')}
                size="small"
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={t('selectAirport')}
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      style: {
                        height: '27px',
                      },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      sx: {
                        height: '27px',
                        paddingY: 'unset !important',
                        paddingLeft: '3px !important',
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
        <Typography mt={3} variant="body1">
          {t('status')}
        </Typography>
        <ToggleButtonGroup
          value={status}
          exclusive
          aria-label="text alignment"
          onChange={handleStatus}
          sx={{marginTop: '8px'}}>
          <ToggleButton
            value={Status.PENDING}
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#1976d2',
                color: 'white',
              },
            }}>
            {t('bookInProcess')}
          </ToggleButton>
          <ToggleButton
            value={Status.APPROVED}
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#1976d2',
                color: 'white',
              },
            }}>
            {t('bookConfirmed')}
          </ToggleButton>
          <ToggleButton
            value={Status.REJECTED}
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#1976d2',
                color: 'white',
              },
            }}>
            {t('failed')}
          </ToggleButton>
        </ToggleButtonGroup>
        <Button
          variant="primary"
          type="submit"
          size="small"
          onClick={() => {
            props.filterReservation({departure, status});
          }}
          style={{marginTop: '24px'}}>
          {t('searchVerb')}
        </Button>
      </Box>
    </Box>
  );
};
