import {CargoInsuranceConditions} from '../API';
import i18n from '../i18n/i18n';

export const getLocalizedCargoInsuranceConditions = (
  cargoInsuranceConditions: CargoInsuranceConditions,
) => {
  if (cargoInsuranceConditions?.comprehensiveInsurance !== null) {
    return i18n.t('comprehensiveInsurance');
  } else if (cargoInsuranceConditions.individualInsurance !== null) {
    return i18n.t('individualInsurance');
  } else if (cargoInsuranceConditions.uninsuredWithInsurance !== null) {
    return i18n.t('noInsurance');
  } else if (cargoInsuranceConditions.othersOption !== null) {
    return i18n.t('other');
  }
};
