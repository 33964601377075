import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanguageIcon from '@mui/icons-material/Language';
import {
  AppBar,
  Toolbar,
  Avatar,
  Box,
  MenuItem,
  Typography,
  Menu,
  Divider,
  Button,
  Stack,
  Badge,
  Link,
} from '@mui/material';
import {Auth} from 'aws-amplify';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {Status} from '../API';
import i18n from '../i18n/i18n';
import {useOAuthUserState} from '../providers/AuthProvider';
import {useForwarderUser} from '../queries/forwarderUser';
import {useReservations} from '../queries/reservation';
import {useShipperUser} from '../queries/shipperUser';

export const Header = () => {
  const {t} = useTranslation();
  const userState = useOAuthUserState();
  const sub = userState.user?.attributes.sub ?? '';

  const {data: forwarder} = useForwarderUser(sub);
  const {data: shipper} = useShipperUser(sub);

  const forwarderHeaders = [
    {
      label: t('quotationsList'),
      href: '/estimateList',
    },
    {
      label: t('exportAndImportCharge'),
      href: '/fobFhd',
    },
  ];
  const shipperHeaders = [
    {
      label: t('searchQuotation'),
      href: '/searchEstimate',
    },
  ];

  const isForwarders = userState.user?.isForwarders;
  const isShippers = userState.user?.isShippers;
  const isAdmins = userState.user?.isAdmins;
  const isManager =
    (forwarder && forwarder.company?.manager?.owner === forwarder.id) ||
    (shipper && shipper.company?.manager?.owner === shipper.id);
  const companyId = userState.user?.isForwarders
    ? forwarder?.companyId
    : shipper?.companyId;

  const headers = isForwarders
    ? forwarderHeaders
    : isShippers
    ? shipperHeaders
    : [];
  const [language, setLanguage] = useState('ja');
  const [anchorElLanguageMenu, setAnchorElLanguageMenu] =
    useState<null | HTMLElement>(null);
  const [anchorElAccountMenu, setAnchorElAccountMenu] =
    useState<null | HTMLElement>(null);
  const {data: reservation} = useReservations(
    companyId ?? '',
    undefined,
    Status.PENDING,
  );

  const handleOpenLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLanguageMenu(event.currentTarget);
  };
  const handleOpenAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAccountMenu(event.currentTarget);
  };
  const handleCloseLanguageMenu = () => setAnchorElLanguageMenu(null);
  const handleCloseAccountMenu = () => setAnchorElAccountMenu(null);
  const navigate = useNavigate();
  const handleClickAccountMenu = useCallback(
    (pathname: string) => {
      handleCloseAccountMenu();
      navigate(pathname);
    },
    [navigate],
  );

  return (
    <AppBar
      sx={{
        background: 'transparent',
        position: 'static',
        boxShadow: 'unset',
        height: 72,
        paddingRight: '0px !important',
      }}>
      <Toolbar sx={{padding: '0 12px 0 36px !important'}}>
        <Button
          onClick={() => {
            navigate('/');
          }}>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#0050B3',
              fontSize: 38,
              textAlign: 'center',
            }}>
            MAST
          </Typography>
        </Button>
        <Stack spacing={4} direction="row" ml={11}>
          {headers.map(({label, href}) => (
            <Button key={label} to={href} component={RouterLink}>
              <Typography
                sx={{
                  fontSize: 16,
                  color: '#494747',
                  fontWeight: 600,
                }}>
                {label}
              </Typography>
            </Button>
          ))}
          {(isForwarders || isShippers) && (
            <Badge
              badgeContent={reservation?.items.length ?? 0}
              sx={{
                '.MuiBadge-badge': {
                  backgroundColor: '#D32F2F',
                  color: '#FFFFFF',
                },
              }}>
              <Button
                key={t('bookConfirmation')}
                to={'/reservationList'}
                component={RouterLink}>
                <Typography
                  sx={{
                    fontSize: 16,
                    color: '#494747',
                    fontWeight: 600,
                  }}>
                  {t('bookConfirmation')}
                </Typography>
              </Button>
            </Badge>
          )}
        </Stack>
        <Box sx={{margin: '0 0 0 auto'}}>
          <Button
            onClick={handleOpenLanguageMenu}
            sx={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              textTransform: 'unset',
            }}>
            <LanguageIcon sx={{color: '#333333'}} />
            <Typography
              component="span"
              sx={{
                textAlign: 'left',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                color: '#333333',
                fontWeight: 600,
                marginLeft: '8px',
              }}>
              {`${language}`}
            </Typography>
            <ExpandMoreIcon sx={{color: '#333333'}} />
          </Button>
          <Menu
            open={Boolean(anchorElLanguageMenu)}
            onClose={handleCloseLanguageMenu}
            anchorEl={anchorElLanguageMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 25,
            }}>
            <MenuItem
              onClick={() => {
                handleCloseLanguageMenu();
                i18n.changeLanguage('ja');
                setLanguage('ja');
              }}>
              <Typography>ja</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseLanguageMenu();
                i18n.changeLanguage('en');
                setLanguage('en');
              }}>
              <Typography>en</Typography>
            </MenuItem>
          </Menu>
        </Box>
        <Box sx={{margin: '0 0 0 7px'}}>
          <Button
            onClick={handleOpenAccountMenu}
            sx={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              textTransform: 'unset',
              paddingX: '18px',
            }}>
            <Avatar
              sx={{
                width: '36px',
                height: '36px',
                marginRight: '8px',
                backgroundColor: '#494747',
              }}
            />
            <Typography
              component="span"
              sx={{
                textAlign: 'left',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                color: '#494747',
                fontWeight: 600,
                marginLeft: '8px',
              }}>
              {userState.user?.isForwarders
                ? `${forwarder?.lastName ?? ''} ${forwarder?.firstName ?? ''}`
                : `${shipper?.lastName ?? ''} ${shipper?.firstName ?? ''}`}
            </Typography>
          </Button>
          <Menu
            open={Boolean(anchorElAccountMenu)}
            onClose={handleCloseAccountMenu}
            anchorEl={anchorElAccountMenu}
            PaperProps={{style: {width: 'fit-content'}}}>
            <MenuItem sx={{flexDirection: 'column', alignItems: 'flex-start'}}>
              <Typography>
                {userState.user?.isForwarders
                  ? `${forwarder?.lastName ?? ''} ${forwarder?.firstName ?? ''}`
                  : `${shipper?.lastName ?? ''} ${shipper?.firstName ?? ''}`}
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 300,
                }}>
                {userState.user?.isForwarders
                  ? `${forwarder?.email ?? ''}`
                  : `${shipper?.email ?? ''}`}
              </Typography>
            </MenuItem>
            <Divider />
            {(isForwarders || isShippers) && (
              <>
                <MenuItem
                  onClick={() => {
                    handleClickAccountMenu('/profile');
                  }}>
                  <Typography>{t('profile')}</Typography>
                </MenuItem>
                <Divider />
              </>
            )}
            <Link
              href="https://cozmic.notion.site/3f158b6aed084d5880574720c4f5829b?pvs=4"
              underline="none"
              target="_blank">
              <MenuItem>
                <Typography>{t('termsOfService')}</Typography>
              </MenuItem>
            </Link>
            <Link
              href="https://cozmic.notion.site/de2c8de607bf471285cdf0f1d46627be"
              underline="none"
              target="_blank">
              <MenuItem>
                <Typography>{t('privacyPolicy')}</Typography>
              </MenuItem>
            </Link>
            <Divider />
            {(isForwarders || isShippers) && (
              <>
                {isManager && (
                  <>
                    <MenuItem
                      onClick={() => handleClickAccountMenu('/companyInfo')}>
                      <Typography>{t('companyProfile')}</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => handleClickAccountMenu('/users')}>
                      <Typography>{t('employeeManagement')}</Typography>
                    </MenuItem>
                    <Divider />
                  </>
                )}
                {isAdmins && (
                  <>
                    <MenuItem
                      onClick={() => {
                        handleClickAccountMenu('/admin/shipperUsers');
                      }}>
                      <Typography>{t('administrator')}</Typography>
                    </MenuItem>
                    <Divider />
                  </>
                )}
              </>
            )}
            <MenuItem
              onClick={() => {
                Auth.signOut();
                navigate('/');
              }}>
              <Typography>{t('signOut')}</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
