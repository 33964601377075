/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createShipperUser = /* GraphQL */ `
  mutation CreateShipperUser(
    $input: CreateShipperUserInput!
    $condition: ModelShipperUserConditionInput
  ) {
    createShipperUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phoneNumber
      workLocation
      division
      position
      area
      role
      companyId
      company {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        category
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        shipperCompanyManagerId
        owner
      }
      postedReviews {
        items {
          id
          title
          description
          rate
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          author {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          shipperUserPostedReviewsId
          forwarderCompanyReceivedReviewsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateShipperUser = /* GraphQL */ `
  mutation UpdateShipperUser(
    $input: UpdateShipperUserInput!
    $condition: ModelShipperUserConditionInput
  ) {
    updateShipperUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phoneNumber
      workLocation
      division
      position
      area
      role
      companyId
      company {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        category
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        shipperCompanyManagerId
        owner
      }
      postedReviews {
        items {
          id
          title
          description
          rate
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          author {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          shipperUserPostedReviewsId
          forwarderCompanyReceivedReviewsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteShipperUser = /* GraphQL */ `
  mutation DeleteShipperUser(
    $input: DeleteShipperUserInput!
    $condition: ModelShipperUserConditionInput
  ) {
    deleteShipperUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phoneNumber
      workLocation
      division
      position
      area
      role
      companyId
      company {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        category
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        shipperCompanyManagerId
        owner
      }
      postedReviews {
        items {
          id
          title
          description
          rate
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          author {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          shipperUserPostedReviewsId
          forwarderCompanyReceivedReviewsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFob = /* GraphQL */ `
  mutation CreateFob(
    $input: CreateFobInput!
    $condition: ModelFobConditionInput
  ) {
    createFob(input: $input, condition: $condition) {
      id
      airport
      forwarderCompanyId
      forwarder {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        iataAgency
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fobs {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fhds {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        forwarderCompanyManagerId
        owner
      }
      customItems {
        items {
          id
          title
          unitPrice
          unitType
          calculationMethod
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          minimum
          maximum
          includeTax
          taxRate
          remark
          fobId
          fhdId
          shippingFeeId
          estimateId
          createdAt
          updatedAt
          customFeeCurrencyId
          owner
        }
        nextToken
      }
      remark
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateFob = /* GraphQL */ `
  mutation UpdateFob(
    $input: UpdateFobInput!
    $condition: ModelFobConditionInput
  ) {
    updateFob(input: $input, condition: $condition) {
      id
      airport
      forwarderCompanyId
      forwarder {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        iataAgency
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fobs {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fhds {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        forwarderCompanyManagerId
        owner
      }
      customItems {
        items {
          id
          title
          unitPrice
          unitType
          calculationMethod
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          minimum
          maximum
          includeTax
          taxRate
          remark
          fobId
          fhdId
          shippingFeeId
          estimateId
          createdAt
          updatedAt
          customFeeCurrencyId
          owner
        }
        nextToken
      }
      remark
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteFob = /* GraphQL */ `
  mutation DeleteFob(
    $input: DeleteFobInput!
    $condition: ModelFobConditionInput
  ) {
    deleteFob(input: $input, condition: $condition) {
      id
      airport
      forwarderCompanyId
      forwarder {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        iataAgency
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fobs {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fhds {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        forwarderCompanyManagerId
        owner
      }
      customItems {
        items {
          id
          title
          unitPrice
          unitType
          calculationMethod
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          minimum
          maximum
          includeTax
          taxRate
          remark
          fobId
          fhdId
          shippingFeeId
          estimateId
          createdAt
          updatedAt
          customFeeCurrencyId
          owner
        }
        nextToken
      }
      remark
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFhd = /* GraphQL */ `
  mutation CreateFhd(
    $input: CreateFhdInput!
    $condition: ModelFhdConditionInput
  ) {
    createFhd(input: $input, condition: $condition) {
      id
      airport
      forwarderCompanyId
      forwarder {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        iataAgency
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fobs {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fhds {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        forwarderCompanyManagerId
        owner
      }
      customItems {
        items {
          id
          title
          unitPrice
          unitType
          calculationMethod
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          minimum
          maximum
          includeTax
          taxRate
          remark
          fobId
          fhdId
          shippingFeeId
          estimateId
          createdAt
          updatedAt
          customFeeCurrencyId
          owner
        }
        nextToken
      }
      remark
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateFhd = /* GraphQL */ `
  mutation UpdateFhd(
    $input: UpdateFhdInput!
    $condition: ModelFhdConditionInput
  ) {
    updateFhd(input: $input, condition: $condition) {
      id
      airport
      forwarderCompanyId
      forwarder {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        iataAgency
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fobs {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fhds {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        forwarderCompanyManagerId
        owner
      }
      customItems {
        items {
          id
          title
          unitPrice
          unitType
          calculationMethod
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          minimum
          maximum
          includeTax
          taxRate
          remark
          fobId
          fhdId
          shippingFeeId
          estimateId
          createdAt
          updatedAt
          customFeeCurrencyId
          owner
        }
        nextToken
      }
      remark
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteFhd = /* GraphQL */ `
  mutation DeleteFhd(
    $input: DeleteFhdInput!
    $condition: ModelFhdConditionInput
  ) {
    deleteFhd(input: $input, condition: $condition) {
      id
      airport
      forwarderCompanyId
      forwarder {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        iataAgency
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fobs {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fhds {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        forwarderCompanyManagerId
        owner
      }
      customItems {
        items {
          id
          title
          unitPrice
          unitType
          calculationMethod
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          minimum
          maximum
          includeTax
          taxRate
          remark
          fobId
          fhdId
          shippingFeeId
          estimateId
          createdAt
          updatedAt
          customFeeCurrencyId
          owner
        }
        nextToken
      }
      remark
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createForwarderReview = /* GraphQL */ `
  mutation CreateForwarderReview(
    $input: CreateForwarderReviewInput!
    $condition: ModelForwarderReviewConditionInput
  ) {
    createForwarderReview(input: $input, condition: $condition) {
      id
      title
      description
      rate
      company {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        category
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        shipperCompanyManagerId
        owner
      }
      author {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          iataAgency
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fobs {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fhds {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          forwarderCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        estimates {
          items {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            cargoType
            yardCutDate
            carrierShedCutDate
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            under45
            over45
            over100
            over300
            over500
            over1000
            fsc
            remark
            customItems {
              nextToken
            }
            forwarderUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            updatedAt
            forwarderUserEstimatesId
            estimateCurrencyId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      forwarderUserPostedReviewsId
      shipperCompanyReceivedReviewsId
      owner
    }
  }
`;
export const updateForwarderReview = /* GraphQL */ `
  mutation UpdateForwarderReview(
    $input: UpdateForwarderReviewInput!
    $condition: ModelForwarderReviewConditionInput
  ) {
    updateForwarderReview(input: $input, condition: $condition) {
      id
      title
      description
      rate
      company {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        category
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        shipperCompanyManagerId
        owner
      }
      author {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          iataAgency
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fobs {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fhds {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          forwarderCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        estimates {
          items {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            cargoType
            yardCutDate
            carrierShedCutDate
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            under45
            over45
            over100
            over300
            over500
            over1000
            fsc
            remark
            customItems {
              nextToken
            }
            forwarderUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            updatedAt
            forwarderUserEstimatesId
            estimateCurrencyId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      forwarderUserPostedReviewsId
      shipperCompanyReceivedReviewsId
      owner
    }
  }
`;
export const deleteForwarderReview = /* GraphQL */ `
  mutation DeleteForwarderReview(
    $input: DeleteForwarderReviewInput!
    $condition: ModelForwarderReviewConditionInput
  ) {
    deleteForwarderReview(input: $input, condition: $condition) {
      id
      title
      description
      rate
      company {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        category
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        shipperCompanyManagerId
        owner
      }
      author {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          iataAgency
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fobs {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fhds {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          forwarderCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        estimates {
          items {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            cargoType
            yardCutDate
            carrierShedCutDate
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            under45
            over45
            over100
            over300
            over500
            over1000
            fsc
            remark
            customItems {
              nextToken
            }
            forwarderUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            updatedAt
            forwarderUserEstimatesId
            estimateCurrencyId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      forwarderUserPostedReviewsId
      shipperCompanyReceivedReviewsId
      owner
    }
  }
`;
export const createShipperReview = /* GraphQL */ `
  mutation CreateShipperReview(
    $input: CreateShipperReviewInput!
    $condition: ModelShipperReviewConditionInput
  ) {
    createShipperReview(input: $input, condition: $condition) {
      id
      title
      description
      rate
      company {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        iataAgency
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fobs {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fhds {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        forwarderCompanyManagerId
        owner
      }
      author {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        area
        role
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          category
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          shipperCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      shipperUserPostedReviewsId
      forwarderCompanyReceivedReviewsId
      owner
    }
  }
`;
export const updateShipperReview = /* GraphQL */ `
  mutation UpdateShipperReview(
    $input: UpdateShipperReviewInput!
    $condition: ModelShipperReviewConditionInput
  ) {
    updateShipperReview(input: $input, condition: $condition) {
      id
      title
      description
      rate
      company {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        iataAgency
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fobs {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fhds {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        forwarderCompanyManagerId
        owner
      }
      author {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        area
        role
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          category
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          shipperCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      shipperUserPostedReviewsId
      forwarderCompanyReceivedReviewsId
      owner
    }
  }
`;
export const deleteShipperReview = /* GraphQL */ `
  mutation DeleteShipperReview(
    $input: DeleteShipperReviewInput!
    $condition: ModelShipperReviewConditionInput
  ) {
    deleteShipperReview(input: $input, condition: $condition) {
      id
      title
      description
      rate
      company {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        iataAgency
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fobs {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fhds {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        forwarderCompanyManagerId
        owner
      }
      author {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        area
        role
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          category
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          shipperCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      shipperUserPostedReviewsId
      forwarderCompanyReceivedReviewsId
      owner
    }
  }
`;
export const createInvitedUser = /* GraphQL */ `
  mutation CreateInvitedUser(
    $input: CreateInvitedUserInput!
    $condition: ModelInvitedUserConditionInput
  ) {
    createInvitedUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      companyId
      isForwarder
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateInvitedUser = /* GraphQL */ `
  mutation UpdateInvitedUser(
    $input: UpdateInvitedUserInput!
    $condition: ModelInvitedUserConditionInput
  ) {
    updateInvitedUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      companyId
      isForwarder
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteInvitedUser = /* GraphQL */ `
  mutation DeleteInvitedUser(
    $input: DeleteInvitedUserInput!
    $condition: ModelInvitedUserConditionInput
  ) {
    deleteInvitedUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      companyId
      isForwarder
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createShippingCustomFee = /* GraphQL */ `
  mutation CreateShippingCustomFee(
    $input: CreateShippingCustomFeeInput!
    $condition: ModelShippingCustomFeeConditionInput
  ) {
    createShippingCustomFee(input: $input, condition: $condition) {
      id
      title
      currency {
        id
        code
        exchangeRateJPY
        createdAt
        updatedAt
        owner
      }
      unitPrice
      unitType
      fee
      taxRate
      maximum
      minimum
      remark
      shippingFee {
        id
        price
        fsc
        minimum
        currency {
          id
          code
          exchangeRateJPY
          createdAt
          updatedAt
          owner
        }
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFeeCurrencyId
        shippingFeeShippingInstructionId
        owner
      }
      shippingFobFee {
        id
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFobFeeShippingInstructionId
        owner
      }
      shippingFhdFee {
        id
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFhdFeeShippingInstructionId
        owner
      }
      createdAt
      updatedAt
      shippingFeeCustomFeesId
      shippingFobFeeCustomFeesId
      shippingFhdFeeCustomFeesId
      shippingCustomFeeCurrencyId
      owner
    }
  }
`;
export const updateShippingCustomFee = /* GraphQL */ `
  mutation UpdateShippingCustomFee(
    $input: UpdateShippingCustomFeeInput!
    $condition: ModelShippingCustomFeeConditionInput
  ) {
    updateShippingCustomFee(input: $input, condition: $condition) {
      id
      title
      currency {
        id
        code
        exchangeRateJPY
        createdAt
        updatedAt
        owner
      }
      unitPrice
      unitType
      fee
      taxRate
      maximum
      minimum
      remark
      shippingFee {
        id
        price
        fsc
        minimum
        currency {
          id
          code
          exchangeRateJPY
          createdAt
          updatedAt
          owner
        }
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFeeCurrencyId
        shippingFeeShippingInstructionId
        owner
      }
      shippingFobFee {
        id
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFobFeeShippingInstructionId
        owner
      }
      shippingFhdFee {
        id
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFhdFeeShippingInstructionId
        owner
      }
      createdAt
      updatedAt
      shippingFeeCustomFeesId
      shippingFobFeeCustomFeesId
      shippingFhdFeeCustomFeesId
      shippingCustomFeeCurrencyId
      owner
    }
  }
`;
export const deleteShippingCustomFee = /* GraphQL */ `
  mutation DeleteShippingCustomFee(
    $input: DeleteShippingCustomFeeInput!
    $condition: ModelShippingCustomFeeConditionInput
  ) {
    deleteShippingCustomFee(input: $input, condition: $condition) {
      id
      title
      currency {
        id
        code
        exchangeRateJPY
        createdAt
        updatedAt
        owner
      }
      unitPrice
      unitType
      fee
      taxRate
      maximum
      minimum
      remark
      shippingFee {
        id
        price
        fsc
        minimum
        currency {
          id
          code
          exchangeRateJPY
          createdAt
          updatedAt
          owner
        }
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFeeCurrencyId
        shippingFeeShippingInstructionId
        owner
      }
      shippingFobFee {
        id
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFobFeeShippingInstructionId
        owner
      }
      shippingFhdFee {
        id
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFhdFeeShippingInstructionId
        owner
      }
      createdAt
      updatedAt
      shippingFeeCustomFeesId
      shippingFobFeeCustomFeesId
      shippingFhdFeeCustomFeesId
      shippingCustomFeeCurrencyId
      owner
    }
  }
`;
export const createShippingFee = /* GraphQL */ `
  mutation CreateShippingFee(
    $input: CreateShippingFeeInput!
    $condition: ModelShippingFeeConditionInput
  ) {
    createShippingFee(input: $input, condition: $condition) {
      id
      price
      fsc
      minimum
      currency {
        id
        code
        exchangeRateJPY
        createdAt
        updatedAt
        owner
      }
      customFees {
        items {
          id
          title
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          unitPrice
          unitType
          fee
          taxRate
          maximum
          minimum
          remark
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          shippingFobFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          shippingFhdFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCustomFeesId
          shippingFobFeeCustomFeesId
          shippingFhdFeeCustomFeesId
          shippingCustomFeeCurrencyId
          owner
        }
        nextToken
      }
      remark
      shippingInstruction {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        yardCutDate
        carrierShedCutDate
        itemName
        packingStyle
        packingStyleDetail
        cargoType
        isSds
        regulated
        regulatedDetail
        paymentCondition
        paymentConditionDetail
        taxPaymentConditions {
          realTimeAccount
          comprehensiveDeferredPayment
          cashOnDeliveryAllowed
          beforePermissionPickup
          othersOption
        }
        cargoInsuranceConditions {
          comprehensiveInsurance
          individualInsurance
          uninsuredWithInsurance
          othersOption
        }
        document {
          invoice
          packingList
          nonApplicabilityCertificate
          msds
          other
          detail
        }
        consigneeContact {
          companyName
          name
          email
          phoneNumber
        }
        shipperContact {
          companyName
          name
          email
          phoneNumber
        }
        cargoInsurance
        cargoInsuranceDetail
        packed
        packedDetail
        collected
        collectedDetail
        delivery
        deliveryDetail
        request
        shipperUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shippingItems {
          width
          length
          height
          weight
          numberOfUnit
          volume
          gw
          volumeWeight
          cw
        }
        transportType
        shippingFee {
          id
          price
          fsc
          minimum
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCurrencyId
          shippingFeeShippingInstructionId
          owner
        }
        fob {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFobFeeShippingInstructionId
          owner
        }
        fhd {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFhdFeeShippingInstructionId
          owner
        }
        reservation {
          id
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          hawb
          status
          forwarderCompanyId
          shipperCompanyId
          departure
          createdAt
          updatedAt
          reservationShippingInstructionId
          owner
        }
        createdAt
        updatedAt
        shippingInstructionShipperUserId
        shippingInstructionShippingFeeId
        shippingInstructionFobId
        shippingInstructionFhdId
        shippingInstructionReservationId
        owner
      }
      createdAt
      updatedAt
      shippingFeeCurrencyId
      shippingFeeShippingInstructionId
      owner
    }
  }
`;
export const updateShippingFee = /* GraphQL */ `
  mutation UpdateShippingFee(
    $input: UpdateShippingFeeInput!
    $condition: ModelShippingFeeConditionInput
  ) {
    updateShippingFee(input: $input, condition: $condition) {
      id
      price
      fsc
      minimum
      currency {
        id
        code
        exchangeRateJPY
        createdAt
        updatedAt
        owner
      }
      customFees {
        items {
          id
          title
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          unitPrice
          unitType
          fee
          taxRate
          maximum
          minimum
          remark
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          shippingFobFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          shippingFhdFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCustomFeesId
          shippingFobFeeCustomFeesId
          shippingFhdFeeCustomFeesId
          shippingCustomFeeCurrencyId
          owner
        }
        nextToken
      }
      remark
      shippingInstruction {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        yardCutDate
        carrierShedCutDate
        itemName
        packingStyle
        packingStyleDetail
        cargoType
        isSds
        regulated
        regulatedDetail
        paymentCondition
        paymentConditionDetail
        taxPaymentConditions {
          realTimeAccount
          comprehensiveDeferredPayment
          cashOnDeliveryAllowed
          beforePermissionPickup
          othersOption
        }
        cargoInsuranceConditions {
          comprehensiveInsurance
          individualInsurance
          uninsuredWithInsurance
          othersOption
        }
        document {
          invoice
          packingList
          nonApplicabilityCertificate
          msds
          other
          detail
        }
        consigneeContact {
          companyName
          name
          email
          phoneNumber
        }
        shipperContact {
          companyName
          name
          email
          phoneNumber
        }
        cargoInsurance
        cargoInsuranceDetail
        packed
        packedDetail
        collected
        collectedDetail
        delivery
        deliveryDetail
        request
        shipperUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shippingItems {
          width
          length
          height
          weight
          numberOfUnit
          volume
          gw
          volumeWeight
          cw
        }
        transportType
        shippingFee {
          id
          price
          fsc
          minimum
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCurrencyId
          shippingFeeShippingInstructionId
          owner
        }
        fob {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFobFeeShippingInstructionId
          owner
        }
        fhd {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFhdFeeShippingInstructionId
          owner
        }
        reservation {
          id
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          hawb
          status
          forwarderCompanyId
          shipperCompanyId
          departure
          createdAt
          updatedAt
          reservationShippingInstructionId
          owner
        }
        createdAt
        updatedAt
        shippingInstructionShipperUserId
        shippingInstructionShippingFeeId
        shippingInstructionFobId
        shippingInstructionFhdId
        shippingInstructionReservationId
        owner
      }
      createdAt
      updatedAt
      shippingFeeCurrencyId
      shippingFeeShippingInstructionId
      owner
    }
  }
`;
export const deleteShippingFee = /* GraphQL */ `
  mutation DeleteShippingFee(
    $input: DeleteShippingFeeInput!
    $condition: ModelShippingFeeConditionInput
  ) {
    deleteShippingFee(input: $input, condition: $condition) {
      id
      price
      fsc
      minimum
      currency {
        id
        code
        exchangeRateJPY
        createdAt
        updatedAt
        owner
      }
      customFees {
        items {
          id
          title
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          unitPrice
          unitType
          fee
          taxRate
          maximum
          minimum
          remark
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          shippingFobFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          shippingFhdFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCustomFeesId
          shippingFobFeeCustomFeesId
          shippingFhdFeeCustomFeesId
          shippingCustomFeeCurrencyId
          owner
        }
        nextToken
      }
      remark
      shippingInstruction {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        yardCutDate
        carrierShedCutDate
        itemName
        packingStyle
        packingStyleDetail
        cargoType
        isSds
        regulated
        regulatedDetail
        paymentCondition
        paymentConditionDetail
        taxPaymentConditions {
          realTimeAccount
          comprehensiveDeferredPayment
          cashOnDeliveryAllowed
          beforePermissionPickup
          othersOption
        }
        cargoInsuranceConditions {
          comprehensiveInsurance
          individualInsurance
          uninsuredWithInsurance
          othersOption
        }
        document {
          invoice
          packingList
          nonApplicabilityCertificate
          msds
          other
          detail
        }
        consigneeContact {
          companyName
          name
          email
          phoneNumber
        }
        shipperContact {
          companyName
          name
          email
          phoneNumber
        }
        cargoInsurance
        cargoInsuranceDetail
        packed
        packedDetail
        collected
        collectedDetail
        delivery
        deliveryDetail
        request
        shipperUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shippingItems {
          width
          length
          height
          weight
          numberOfUnit
          volume
          gw
          volumeWeight
          cw
        }
        transportType
        shippingFee {
          id
          price
          fsc
          minimum
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCurrencyId
          shippingFeeShippingInstructionId
          owner
        }
        fob {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFobFeeShippingInstructionId
          owner
        }
        fhd {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFhdFeeShippingInstructionId
          owner
        }
        reservation {
          id
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          hawb
          status
          forwarderCompanyId
          shipperCompanyId
          departure
          createdAt
          updatedAt
          reservationShippingInstructionId
          owner
        }
        createdAt
        updatedAt
        shippingInstructionShipperUserId
        shippingInstructionShippingFeeId
        shippingInstructionFobId
        shippingInstructionFhdId
        shippingInstructionReservationId
        owner
      }
      createdAt
      updatedAt
      shippingFeeCurrencyId
      shippingFeeShippingInstructionId
      owner
    }
  }
`;
export const createShippingFobFee = /* GraphQL */ `
  mutation CreateShippingFobFee(
    $input: CreateShippingFobFeeInput!
    $condition: ModelShippingFobFeeConditionInput
  ) {
    createShippingFobFee(input: $input, condition: $condition) {
      id
      customFees {
        items {
          id
          title
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          unitPrice
          unitType
          fee
          taxRate
          maximum
          minimum
          remark
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          shippingFobFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          shippingFhdFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCustomFeesId
          shippingFobFeeCustomFeesId
          shippingFhdFeeCustomFeesId
          shippingCustomFeeCurrencyId
          owner
        }
        nextToken
      }
      remark
      shippingInstruction {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        yardCutDate
        carrierShedCutDate
        itemName
        packingStyle
        packingStyleDetail
        cargoType
        isSds
        regulated
        regulatedDetail
        paymentCondition
        paymentConditionDetail
        taxPaymentConditions {
          realTimeAccount
          comprehensiveDeferredPayment
          cashOnDeliveryAllowed
          beforePermissionPickup
          othersOption
        }
        cargoInsuranceConditions {
          comprehensiveInsurance
          individualInsurance
          uninsuredWithInsurance
          othersOption
        }
        document {
          invoice
          packingList
          nonApplicabilityCertificate
          msds
          other
          detail
        }
        consigneeContact {
          companyName
          name
          email
          phoneNumber
        }
        shipperContact {
          companyName
          name
          email
          phoneNumber
        }
        cargoInsurance
        cargoInsuranceDetail
        packed
        packedDetail
        collected
        collectedDetail
        delivery
        deliveryDetail
        request
        shipperUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shippingItems {
          width
          length
          height
          weight
          numberOfUnit
          volume
          gw
          volumeWeight
          cw
        }
        transportType
        shippingFee {
          id
          price
          fsc
          minimum
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCurrencyId
          shippingFeeShippingInstructionId
          owner
        }
        fob {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFobFeeShippingInstructionId
          owner
        }
        fhd {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFhdFeeShippingInstructionId
          owner
        }
        reservation {
          id
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          hawb
          status
          forwarderCompanyId
          shipperCompanyId
          departure
          createdAt
          updatedAt
          reservationShippingInstructionId
          owner
        }
        createdAt
        updatedAt
        shippingInstructionShipperUserId
        shippingInstructionShippingFeeId
        shippingInstructionFobId
        shippingInstructionFhdId
        shippingInstructionReservationId
        owner
      }
      createdAt
      updatedAt
      shippingFobFeeShippingInstructionId
      owner
    }
  }
`;
export const updateShippingFobFee = /* GraphQL */ `
  mutation UpdateShippingFobFee(
    $input: UpdateShippingFobFeeInput!
    $condition: ModelShippingFobFeeConditionInput
  ) {
    updateShippingFobFee(input: $input, condition: $condition) {
      id
      customFees {
        items {
          id
          title
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          unitPrice
          unitType
          fee
          taxRate
          maximum
          minimum
          remark
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          shippingFobFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          shippingFhdFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCustomFeesId
          shippingFobFeeCustomFeesId
          shippingFhdFeeCustomFeesId
          shippingCustomFeeCurrencyId
          owner
        }
        nextToken
      }
      remark
      shippingInstruction {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        yardCutDate
        carrierShedCutDate
        itemName
        packingStyle
        packingStyleDetail
        cargoType
        isSds
        regulated
        regulatedDetail
        paymentCondition
        paymentConditionDetail
        taxPaymentConditions {
          realTimeAccount
          comprehensiveDeferredPayment
          cashOnDeliveryAllowed
          beforePermissionPickup
          othersOption
        }
        cargoInsuranceConditions {
          comprehensiveInsurance
          individualInsurance
          uninsuredWithInsurance
          othersOption
        }
        document {
          invoice
          packingList
          nonApplicabilityCertificate
          msds
          other
          detail
        }
        consigneeContact {
          companyName
          name
          email
          phoneNumber
        }
        shipperContact {
          companyName
          name
          email
          phoneNumber
        }
        cargoInsurance
        cargoInsuranceDetail
        packed
        packedDetail
        collected
        collectedDetail
        delivery
        deliveryDetail
        request
        shipperUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shippingItems {
          width
          length
          height
          weight
          numberOfUnit
          volume
          gw
          volumeWeight
          cw
        }
        transportType
        shippingFee {
          id
          price
          fsc
          minimum
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCurrencyId
          shippingFeeShippingInstructionId
          owner
        }
        fob {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFobFeeShippingInstructionId
          owner
        }
        fhd {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFhdFeeShippingInstructionId
          owner
        }
        reservation {
          id
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          hawb
          status
          forwarderCompanyId
          shipperCompanyId
          departure
          createdAt
          updatedAt
          reservationShippingInstructionId
          owner
        }
        createdAt
        updatedAt
        shippingInstructionShipperUserId
        shippingInstructionShippingFeeId
        shippingInstructionFobId
        shippingInstructionFhdId
        shippingInstructionReservationId
        owner
      }
      createdAt
      updatedAt
      shippingFobFeeShippingInstructionId
      owner
    }
  }
`;
export const deleteShippingFobFee = /* GraphQL */ `
  mutation DeleteShippingFobFee(
    $input: DeleteShippingFobFeeInput!
    $condition: ModelShippingFobFeeConditionInput
  ) {
    deleteShippingFobFee(input: $input, condition: $condition) {
      id
      customFees {
        items {
          id
          title
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          unitPrice
          unitType
          fee
          taxRate
          maximum
          minimum
          remark
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          shippingFobFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          shippingFhdFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCustomFeesId
          shippingFobFeeCustomFeesId
          shippingFhdFeeCustomFeesId
          shippingCustomFeeCurrencyId
          owner
        }
        nextToken
      }
      remark
      shippingInstruction {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        yardCutDate
        carrierShedCutDate
        itemName
        packingStyle
        packingStyleDetail
        cargoType
        isSds
        regulated
        regulatedDetail
        paymentCondition
        paymentConditionDetail
        taxPaymentConditions {
          realTimeAccount
          comprehensiveDeferredPayment
          cashOnDeliveryAllowed
          beforePermissionPickup
          othersOption
        }
        cargoInsuranceConditions {
          comprehensiveInsurance
          individualInsurance
          uninsuredWithInsurance
          othersOption
        }
        document {
          invoice
          packingList
          nonApplicabilityCertificate
          msds
          other
          detail
        }
        consigneeContact {
          companyName
          name
          email
          phoneNumber
        }
        shipperContact {
          companyName
          name
          email
          phoneNumber
        }
        cargoInsurance
        cargoInsuranceDetail
        packed
        packedDetail
        collected
        collectedDetail
        delivery
        deliveryDetail
        request
        shipperUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shippingItems {
          width
          length
          height
          weight
          numberOfUnit
          volume
          gw
          volumeWeight
          cw
        }
        transportType
        shippingFee {
          id
          price
          fsc
          minimum
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCurrencyId
          shippingFeeShippingInstructionId
          owner
        }
        fob {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFobFeeShippingInstructionId
          owner
        }
        fhd {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFhdFeeShippingInstructionId
          owner
        }
        reservation {
          id
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          hawb
          status
          forwarderCompanyId
          shipperCompanyId
          departure
          createdAt
          updatedAt
          reservationShippingInstructionId
          owner
        }
        createdAt
        updatedAt
        shippingInstructionShipperUserId
        shippingInstructionShippingFeeId
        shippingInstructionFobId
        shippingInstructionFhdId
        shippingInstructionReservationId
        owner
      }
      createdAt
      updatedAt
      shippingFobFeeShippingInstructionId
      owner
    }
  }
`;
export const createShippingFhdFee = /* GraphQL */ `
  mutation CreateShippingFhdFee(
    $input: CreateShippingFhdFeeInput!
    $condition: ModelShippingFhdFeeConditionInput
  ) {
    createShippingFhdFee(input: $input, condition: $condition) {
      id
      customFees {
        items {
          id
          title
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          unitPrice
          unitType
          fee
          taxRate
          maximum
          minimum
          remark
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          shippingFobFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          shippingFhdFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCustomFeesId
          shippingFobFeeCustomFeesId
          shippingFhdFeeCustomFeesId
          shippingCustomFeeCurrencyId
          owner
        }
        nextToken
      }
      remark
      shippingInstruction {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        yardCutDate
        carrierShedCutDate
        itemName
        packingStyle
        packingStyleDetail
        cargoType
        isSds
        regulated
        regulatedDetail
        paymentCondition
        paymentConditionDetail
        taxPaymentConditions {
          realTimeAccount
          comprehensiveDeferredPayment
          cashOnDeliveryAllowed
          beforePermissionPickup
          othersOption
        }
        cargoInsuranceConditions {
          comprehensiveInsurance
          individualInsurance
          uninsuredWithInsurance
          othersOption
        }
        document {
          invoice
          packingList
          nonApplicabilityCertificate
          msds
          other
          detail
        }
        consigneeContact {
          companyName
          name
          email
          phoneNumber
        }
        shipperContact {
          companyName
          name
          email
          phoneNumber
        }
        cargoInsurance
        cargoInsuranceDetail
        packed
        packedDetail
        collected
        collectedDetail
        delivery
        deliveryDetail
        request
        shipperUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shippingItems {
          width
          length
          height
          weight
          numberOfUnit
          volume
          gw
          volumeWeight
          cw
        }
        transportType
        shippingFee {
          id
          price
          fsc
          minimum
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCurrencyId
          shippingFeeShippingInstructionId
          owner
        }
        fob {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFobFeeShippingInstructionId
          owner
        }
        fhd {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFhdFeeShippingInstructionId
          owner
        }
        reservation {
          id
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          hawb
          status
          forwarderCompanyId
          shipperCompanyId
          departure
          createdAt
          updatedAt
          reservationShippingInstructionId
          owner
        }
        createdAt
        updatedAt
        shippingInstructionShipperUserId
        shippingInstructionShippingFeeId
        shippingInstructionFobId
        shippingInstructionFhdId
        shippingInstructionReservationId
        owner
      }
      createdAt
      updatedAt
      shippingFhdFeeShippingInstructionId
      owner
    }
  }
`;
export const updateShippingFhdFee = /* GraphQL */ `
  mutation UpdateShippingFhdFee(
    $input: UpdateShippingFhdFeeInput!
    $condition: ModelShippingFhdFeeConditionInput
  ) {
    updateShippingFhdFee(input: $input, condition: $condition) {
      id
      customFees {
        items {
          id
          title
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          unitPrice
          unitType
          fee
          taxRate
          maximum
          minimum
          remark
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          shippingFobFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          shippingFhdFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCustomFeesId
          shippingFobFeeCustomFeesId
          shippingFhdFeeCustomFeesId
          shippingCustomFeeCurrencyId
          owner
        }
        nextToken
      }
      remark
      shippingInstruction {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        yardCutDate
        carrierShedCutDate
        itemName
        packingStyle
        packingStyleDetail
        cargoType
        isSds
        regulated
        regulatedDetail
        paymentCondition
        paymentConditionDetail
        taxPaymentConditions {
          realTimeAccount
          comprehensiveDeferredPayment
          cashOnDeliveryAllowed
          beforePermissionPickup
          othersOption
        }
        cargoInsuranceConditions {
          comprehensiveInsurance
          individualInsurance
          uninsuredWithInsurance
          othersOption
        }
        document {
          invoice
          packingList
          nonApplicabilityCertificate
          msds
          other
          detail
        }
        consigneeContact {
          companyName
          name
          email
          phoneNumber
        }
        shipperContact {
          companyName
          name
          email
          phoneNumber
        }
        cargoInsurance
        cargoInsuranceDetail
        packed
        packedDetail
        collected
        collectedDetail
        delivery
        deliveryDetail
        request
        shipperUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shippingItems {
          width
          length
          height
          weight
          numberOfUnit
          volume
          gw
          volumeWeight
          cw
        }
        transportType
        shippingFee {
          id
          price
          fsc
          minimum
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCurrencyId
          shippingFeeShippingInstructionId
          owner
        }
        fob {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFobFeeShippingInstructionId
          owner
        }
        fhd {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFhdFeeShippingInstructionId
          owner
        }
        reservation {
          id
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          hawb
          status
          forwarderCompanyId
          shipperCompanyId
          departure
          createdAt
          updatedAt
          reservationShippingInstructionId
          owner
        }
        createdAt
        updatedAt
        shippingInstructionShipperUserId
        shippingInstructionShippingFeeId
        shippingInstructionFobId
        shippingInstructionFhdId
        shippingInstructionReservationId
        owner
      }
      createdAt
      updatedAt
      shippingFhdFeeShippingInstructionId
      owner
    }
  }
`;
export const deleteShippingFhdFee = /* GraphQL */ `
  mutation DeleteShippingFhdFee(
    $input: DeleteShippingFhdFeeInput!
    $condition: ModelShippingFhdFeeConditionInput
  ) {
    deleteShippingFhdFee(input: $input, condition: $condition) {
      id
      customFees {
        items {
          id
          title
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          unitPrice
          unitType
          fee
          taxRate
          maximum
          minimum
          remark
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          shippingFobFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          shippingFhdFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCustomFeesId
          shippingFobFeeCustomFeesId
          shippingFhdFeeCustomFeesId
          shippingCustomFeeCurrencyId
          owner
        }
        nextToken
      }
      remark
      shippingInstruction {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        yardCutDate
        carrierShedCutDate
        itemName
        packingStyle
        packingStyleDetail
        cargoType
        isSds
        regulated
        regulatedDetail
        paymentCondition
        paymentConditionDetail
        taxPaymentConditions {
          realTimeAccount
          comprehensiveDeferredPayment
          cashOnDeliveryAllowed
          beforePermissionPickup
          othersOption
        }
        cargoInsuranceConditions {
          comprehensiveInsurance
          individualInsurance
          uninsuredWithInsurance
          othersOption
        }
        document {
          invoice
          packingList
          nonApplicabilityCertificate
          msds
          other
          detail
        }
        consigneeContact {
          companyName
          name
          email
          phoneNumber
        }
        shipperContact {
          companyName
          name
          email
          phoneNumber
        }
        cargoInsurance
        cargoInsuranceDetail
        packed
        packedDetail
        collected
        collectedDetail
        delivery
        deliveryDetail
        request
        shipperUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shippingItems {
          width
          length
          height
          weight
          numberOfUnit
          volume
          gw
          volumeWeight
          cw
        }
        transportType
        shippingFee {
          id
          price
          fsc
          minimum
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCurrencyId
          shippingFeeShippingInstructionId
          owner
        }
        fob {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFobFeeShippingInstructionId
          owner
        }
        fhd {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFhdFeeShippingInstructionId
          owner
        }
        reservation {
          id
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          hawb
          status
          forwarderCompanyId
          shipperCompanyId
          departure
          createdAt
          updatedAt
          reservationShippingInstructionId
          owner
        }
        createdAt
        updatedAt
        shippingInstructionShipperUserId
        shippingInstructionShippingFeeId
        shippingInstructionFobId
        shippingInstructionFhdId
        shippingInstructionReservationId
        owner
      }
      createdAt
      updatedAt
      shippingFhdFeeShippingInstructionId
      owner
    }
  }
`;
export const createShippingInstruction = /* GraphQL */ `
  mutation CreateShippingInstruction(
    $input: CreateShippingInstructionInput!
    $condition: ModelShippingInstructionConditionInput
  ) {
    createShippingInstruction(input: $input, condition: $condition) {
      id
      departure
      arrival
      airline
      flightDate
      directFlight
      leadTime
      useSaf
      yardCutDate
      carrierShedCutDate
      itemName
      packingStyle
      packingStyleDetail
      cargoType
      isSds
      regulated
      regulatedDetail
      paymentCondition
      paymentConditionDetail
      taxPaymentConditions {
        realTimeAccount
        comprehensiveDeferredPayment
        cashOnDeliveryAllowed
        beforePermissionPickup
        othersOption
      }
      cargoInsuranceConditions {
        comprehensiveInsurance
        individualInsurance
        uninsuredWithInsurance
        othersOption
      }
      document {
        invoice
        packingList
        nonApplicabilityCertificate
        msds
        other
        detail
      }
      consigneeContact {
        companyName
        name
        email
        phoneNumber
      }
      shipperContact {
        companyName
        name
        email
        phoneNumber
      }
      cargoInsurance
      cargoInsuranceDetail
      packed
      packedDetail
      collected
      collectedDetail
      delivery
      deliveryDetail
      request
      shipperUser {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        area
        role
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          category
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          shipperCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      shippingItems {
        width
        length
        height
        weight
        numberOfUnit
        volume
        gw
        volumeWeight
        cw
      }
      transportType
      shippingFee {
        id
        price
        fsc
        minimum
        currency {
          id
          code
          exchangeRateJPY
          createdAt
          updatedAt
          owner
        }
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFeeCurrencyId
        shippingFeeShippingInstructionId
        owner
      }
      fob {
        id
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFobFeeShippingInstructionId
        owner
      }
      fhd {
        id
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFhdFeeShippingInstructionId
        owner
      }
      reservation {
        id
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        hawb
        status
        forwarderCompanyId
        shipperCompanyId
        departure
        createdAt
        updatedAt
        reservationShippingInstructionId
        owner
      }
      createdAt
      updatedAt
      shippingInstructionShipperUserId
      shippingInstructionShippingFeeId
      shippingInstructionFobId
      shippingInstructionFhdId
      shippingInstructionReservationId
      owner
    }
  }
`;
export const updateShippingInstruction = /* GraphQL */ `
  mutation UpdateShippingInstruction(
    $input: UpdateShippingInstructionInput!
    $condition: ModelShippingInstructionConditionInput
  ) {
    updateShippingInstruction(input: $input, condition: $condition) {
      id
      departure
      arrival
      airline
      flightDate
      directFlight
      leadTime
      useSaf
      yardCutDate
      carrierShedCutDate
      itemName
      packingStyle
      packingStyleDetail
      cargoType
      isSds
      regulated
      regulatedDetail
      paymentCondition
      paymentConditionDetail
      taxPaymentConditions {
        realTimeAccount
        comprehensiveDeferredPayment
        cashOnDeliveryAllowed
        beforePermissionPickup
        othersOption
      }
      cargoInsuranceConditions {
        comprehensiveInsurance
        individualInsurance
        uninsuredWithInsurance
        othersOption
      }
      document {
        invoice
        packingList
        nonApplicabilityCertificate
        msds
        other
        detail
      }
      consigneeContact {
        companyName
        name
        email
        phoneNumber
      }
      shipperContact {
        companyName
        name
        email
        phoneNumber
      }
      cargoInsurance
      cargoInsuranceDetail
      packed
      packedDetail
      collected
      collectedDetail
      delivery
      deliveryDetail
      request
      shipperUser {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        area
        role
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          category
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          shipperCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      shippingItems {
        width
        length
        height
        weight
        numberOfUnit
        volume
        gw
        volumeWeight
        cw
      }
      transportType
      shippingFee {
        id
        price
        fsc
        minimum
        currency {
          id
          code
          exchangeRateJPY
          createdAt
          updatedAt
          owner
        }
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFeeCurrencyId
        shippingFeeShippingInstructionId
        owner
      }
      fob {
        id
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFobFeeShippingInstructionId
        owner
      }
      fhd {
        id
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFhdFeeShippingInstructionId
        owner
      }
      reservation {
        id
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        hawb
        status
        forwarderCompanyId
        shipperCompanyId
        departure
        createdAt
        updatedAt
        reservationShippingInstructionId
        owner
      }
      createdAt
      updatedAt
      shippingInstructionShipperUserId
      shippingInstructionShippingFeeId
      shippingInstructionFobId
      shippingInstructionFhdId
      shippingInstructionReservationId
      owner
    }
  }
`;
export const deleteShippingInstruction = /* GraphQL */ `
  mutation DeleteShippingInstruction(
    $input: DeleteShippingInstructionInput!
    $condition: ModelShippingInstructionConditionInput
  ) {
    deleteShippingInstruction(input: $input, condition: $condition) {
      id
      departure
      arrival
      airline
      flightDate
      directFlight
      leadTime
      useSaf
      yardCutDate
      carrierShedCutDate
      itemName
      packingStyle
      packingStyleDetail
      cargoType
      isSds
      regulated
      regulatedDetail
      paymentCondition
      paymentConditionDetail
      taxPaymentConditions {
        realTimeAccount
        comprehensiveDeferredPayment
        cashOnDeliveryAllowed
        beforePermissionPickup
        othersOption
      }
      cargoInsuranceConditions {
        comprehensiveInsurance
        individualInsurance
        uninsuredWithInsurance
        othersOption
      }
      document {
        invoice
        packingList
        nonApplicabilityCertificate
        msds
        other
        detail
      }
      consigneeContact {
        companyName
        name
        email
        phoneNumber
      }
      shipperContact {
        companyName
        name
        email
        phoneNumber
      }
      cargoInsurance
      cargoInsuranceDetail
      packed
      packedDetail
      collected
      collectedDetail
      delivery
      deliveryDetail
      request
      shipperUser {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        area
        role
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          category
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          shipperCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      shippingItems {
        width
        length
        height
        weight
        numberOfUnit
        volume
        gw
        volumeWeight
        cw
      }
      transportType
      shippingFee {
        id
        price
        fsc
        minimum
        currency {
          id
          code
          exchangeRateJPY
          createdAt
          updatedAt
          owner
        }
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFeeCurrencyId
        shippingFeeShippingInstructionId
        owner
      }
      fob {
        id
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFobFeeShippingInstructionId
        owner
      }
      fhd {
        id
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFhdFeeShippingInstructionId
        owner
      }
      reservation {
        id
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        hawb
        status
        forwarderCompanyId
        shipperCompanyId
        departure
        createdAt
        updatedAt
        reservationShippingInstructionId
        owner
      }
      createdAt
      updatedAt
      shippingInstructionShipperUserId
      shippingInstructionShippingFeeId
      shippingInstructionFobId
      shippingInstructionFhdId
      shippingInstructionReservationId
      owner
    }
  }
`;
export const createReservation = /* GraphQL */ `
  mutation CreateReservation(
    $input: CreateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    createReservation(input: $input, condition: $condition) {
      id
      shippingInstruction {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        yardCutDate
        carrierShedCutDate
        itemName
        packingStyle
        packingStyleDetail
        cargoType
        isSds
        regulated
        regulatedDetail
        paymentCondition
        paymentConditionDetail
        taxPaymentConditions {
          realTimeAccount
          comprehensiveDeferredPayment
          cashOnDeliveryAllowed
          beforePermissionPickup
          othersOption
        }
        cargoInsuranceConditions {
          comprehensiveInsurance
          individualInsurance
          uninsuredWithInsurance
          othersOption
        }
        document {
          invoice
          packingList
          nonApplicabilityCertificate
          msds
          other
          detail
        }
        consigneeContact {
          companyName
          name
          email
          phoneNumber
        }
        shipperContact {
          companyName
          name
          email
          phoneNumber
        }
        cargoInsurance
        cargoInsuranceDetail
        packed
        packedDetail
        collected
        collectedDetail
        delivery
        deliveryDetail
        request
        shipperUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shippingItems {
          width
          length
          height
          weight
          numberOfUnit
          volume
          gw
          volumeWeight
          cw
        }
        transportType
        shippingFee {
          id
          price
          fsc
          minimum
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCurrencyId
          shippingFeeShippingInstructionId
          owner
        }
        fob {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFobFeeShippingInstructionId
          owner
        }
        fhd {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFhdFeeShippingInstructionId
          owner
        }
        reservation {
          id
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          hawb
          status
          forwarderCompanyId
          shipperCompanyId
          departure
          createdAt
          updatedAt
          reservationShippingInstructionId
          owner
        }
        createdAt
        updatedAt
        shippingInstructionShipperUserId
        shippingInstructionShippingFeeId
        shippingInstructionFobId
        shippingInstructionFhdId
        shippingInstructionReservationId
        owner
      }
      hawb
      status
      forwarderCompanyId
      shipperCompanyId
      departure
      createdAt
      updatedAt
      reservationShippingInstructionId
      owner
    }
  }
`;
export const updateReservation = /* GraphQL */ `
  mutation UpdateReservation(
    $input: UpdateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    updateReservation(input: $input, condition: $condition) {
      id
      shippingInstruction {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        yardCutDate
        carrierShedCutDate
        itemName
        packingStyle
        packingStyleDetail
        cargoType
        isSds
        regulated
        regulatedDetail
        paymentCondition
        paymentConditionDetail
        taxPaymentConditions {
          realTimeAccount
          comprehensiveDeferredPayment
          cashOnDeliveryAllowed
          beforePermissionPickup
          othersOption
        }
        cargoInsuranceConditions {
          comprehensiveInsurance
          individualInsurance
          uninsuredWithInsurance
          othersOption
        }
        document {
          invoice
          packingList
          nonApplicabilityCertificate
          msds
          other
          detail
        }
        consigneeContact {
          companyName
          name
          email
          phoneNumber
        }
        shipperContact {
          companyName
          name
          email
          phoneNumber
        }
        cargoInsurance
        cargoInsuranceDetail
        packed
        packedDetail
        collected
        collectedDetail
        delivery
        deliveryDetail
        request
        shipperUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shippingItems {
          width
          length
          height
          weight
          numberOfUnit
          volume
          gw
          volumeWeight
          cw
        }
        transportType
        shippingFee {
          id
          price
          fsc
          minimum
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCurrencyId
          shippingFeeShippingInstructionId
          owner
        }
        fob {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFobFeeShippingInstructionId
          owner
        }
        fhd {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFhdFeeShippingInstructionId
          owner
        }
        reservation {
          id
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          hawb
          status
          forwarderCompanyId
          shipperCompanyId
          departure
          createdAt
          updatedAt
          reservationShippingInstructionId
          owner
        }
        createdAt
        updatedAt
        shippingInstructionShipperUserId
        shippingInstructionShippingFeeId
        shippingInstructionFobId
        shippingInstructionFhdId
        shippingInstructionReservationId
        owner
      }
      hawb
      status
      forwarderCompanyId
      shipperCompanyId
      departure
      createdAt
      updatedAt
      reservationShippingInstructionId
      owner
    }
  }
`;
export const deleteReservation = /* GraphQL */ `
  mutation DeleteReservation(
    $input: DeleteReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    deleteReservation(input: $input, condition: $condition) {
      id
      shippingInstruction {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        yardCutDate
        carrierShedCutDate
        itemName
        packingStyle
        packingStyleDetail
        cargoType
        isSds
        regulated
        regulatedDetail
        paymentCondition
        paymentConditionDetail
        taxPaymentConditions {
          realTimeAccount
          comprehensiveDeferredPayment
          cashOnDeliveryAllowed
          beforePermissionPickup
          othersOption
        }
        cargoInsuranceConditions {
          comprehensiveInsurance
          individualInsurance
          uninsuredWithInsurance
          othersOption
        }
        document {
          invoice
          packingList
          nonApplicabilityCertificate
          msds
          other
          detail
        }
        consigneeContact {
          companyName
          name
          email
          phoneNumber
        }
        shipperContact {
          companyName
          name
          email
          phoneNumber
        }
        cargoInsurance
        cargoInsuranceDetail
        packed
        packedDetail
        collected
        collectedDetail
        delivery
        deliveryDetail
        request
        shipperUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shippingItems {
          width
          length
          height
          weight
          numberOfUnit
          volume
          gw
          volumeWeight
          cw
        }
        transportType
        shippingFee {
          id
          price
          fsc
          minimum
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCurrencyId
          shippingFeeShippingInstructionId
          owner
        }
        fob {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFobFeeShippingInstructionId
          owner
        }
        fhd {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFhdFeeShippingInstructionId
          owner
        }
        reservation {
          id
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          hawb
          status
          forwarderCompanyId
          shipperCompanyId
          departure
          createdAt
          updatedAt
          reservationShippingInstructionId
          owner
        }
        createdAt
        updatedAt
        shippingInstructionShipperUserId
        shippingInstructionShippingFeeId
        shippingInstructionFobId
        shippingInstructionFhdId
        shippingInstructionReservationId
        owner
      }
      hawb
      status
      forwarderCompanyId
      shipperCompanyId
      departure
      createdAt
      updatedAt
      reservationShippingInstructionId
      owner
    }
  }
`;
export const createCurrency = /* GraphQL */ `
  mutation CreateCurrency(
    $input: CreateCurrencyInput!
    $condition: ModelCurrencyConditionInput
  ) {
    createCurrency(input: $input, condition: $condition) {
      id
      code
      exchangeRateJPY
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCurrency = /* GraphQL */ `
  mutation UpdateCurrency(
    $input: UpdateCurrencyInput!
    $condition: ModelCurrencyConditionInput
  ) {
    updateCurrency(input: $input, condition: $condition) {
      id
      code
      exchangeRateJPY
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCurrency = /* GraphQL */ `
  mutation DeleteCurrency(
    $input: DeleteCurrencyInput!
    $condition: ModelCurrencyConditionInput
  ) {
    deleteCurrency(input: $input, condition: $condition) {
      id
      code
      exchangeRateJPY
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createForwarderUser = /* GraphQL */ `
  mutation CreateForwarderUser(
    $input: CreateForwarderUserInput!
    $condition: ModelForwarderUserConditionInput
  ) {
    createForwarderUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phoneNumber
      workLocation
      division
      position
      companyId
      company {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        iataAgency
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fobs {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fhds {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        forwarderCompanyManagerId
        owner
      }
      postedReviews {
        items {
          id
          title
          description
          rate
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          author {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          forwarderUserPostedReviewsId
          shipperCompanyReceivedReviewsId
          owner
        }
        nextToken
      }
      estimates {
        items {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          cargoType
          yardCutDate
          carrierShedCutDate
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          minimum
          under45
          over45
          over100
          over300
          over500
          over1000
          fsc
          remark
          customItems {
            items {
              id
              title
              unitPrice
              unitType
              calculationMethod
              minimum
              maximum
              includeTax
              taxRate
              remark
              fobId
              fhdId
              shippingFeeId
              estimateId
              createdAt
              updatedAt
              customFeeCurrencyId
              owner
            }
            nextToken
          }
          forwarderUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          status
          createdAt
          updatedAt
          forwarderUserEstimatesId
          estimateCurrencyId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateForwarderUser = /* GraphQL */ `
  mutation UpdateForwarderUser(
    $input: UpdateForwarderUserInput!
    $condition: ModelForwarderUserConditionInput
  ) {
    updateForwarderUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phoneNumber
      workLocation
      division
      position
      companyId
      company {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        iataAgency
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fobs {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fhds {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        forwarderCompanyManagerId
        owner
      }
      postedReviews {
        items {
          id
          title
          description
          rate
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          author {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          forwarderUserPostedReviewsId
          shipperCompanyReceivedReviewsId
          owner
        }
        nextToken
      }
      estimates {
        items {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          cargoType
          yardCutDate
          carrierShedCutDate
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          minimum
          under45
          over45
          over100
          over300
          over500
          over1000
          fsc
          remark
          customItems {
            items {
              id
              title
              unitPrice
              unitType
              calculationMethod
              minimum
              maximum
              includeTax
              taxRate
              remark
              fobId
              fhdId
              shippingFeeId
              estimateId
              createdAt
              updatedAt
              customFeeCurrencyId
              owner
            }
            nextToken
          }
          forwarderUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          status
          createdAt
          updatedAt
          forwarderUserEstimatesId
          estimateCurrencyId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteForwarderUser = /* GraphQL */ `
  mutation DeleteForwarderUser(
    $input: DeleteForwarderUserInput!
    $condition: ModelForwarderUserConditionInput
  ) {
    deleteForwarderUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phoneNumber
      workLocation
      division
      position
      companyId
      company {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        iataAgency
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fobs {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fhds {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        forwarderCompanyManagerId
        owner
      }
      postedReviews {
        items {
          id
          title
          description
          rate
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          author {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          forwarderUserPostedReviewsId
          shipperCompanyReceivedReviewsId
          owner
        }
        nextToken
      }
      estimates {
        items {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          cargoType
          yardCutDate
          carrierShedCutDate
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          minimum
          under45
          over45
          over100
          over300
          over500
          over1000
          fsc
          remark
          customItems {
            items {
              id
              title
              unitPrice
              unitType
              calculationMethod
              minimum
              maximum
              includeTax
              taxRate
              remark
              fobId
              fhdId
              shippingFeeId
              estimateId
              createdAt
              updatedAt
              customFeeCurrencyId
              owner
            }
            nextToken
          }
          forwarderUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          status
          createdAt
          updatedAt
          forwarderUserEstimatesId
          estimateCurrencyId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCustomFee = /* GraphQL */ `
  mutation CreateCustomFee(
    $input: CreateCustomFeeInput!
    $condition: ModelCustomFeeConditionInput
  ) {
    createCustomFee(input: $input, condition: $condition) {
      id
      title
      unitPrice
      unitType
      calculationMethod
      currency {
        id
        code
        exchangeRateJPY
        createdAt
        updatedAt
        owner
      }
      minimum
      maximum
      includeTax
      taxRate
      remark
      fobId
      fhdId
      shippingFeeId
      estimateId
      createdAt
      updatedAt
      customFeeCurrencyId
      owner
    }
  }
`;
export const updateCustomFee = /* GraphQL */ `
  mutation UpdateCustomFee(
    $input: UpdateCustomFeeInput!
    $condition: ModelCustomFeeConditionInput
  ) {
    updateCustomFee(input: $input, condition: $condition) {
      id
      title
      unitPrice
      unitType
      calculationMethod
      currency {
        id
        code
        exchangeRateJPY
        createdAt
        updatedAt
        owner
      }
      minimum
      maximum
      includeTax
      taxRate
      remark
      fobId
      fhdId
      shippingFeeId
      estimateId
      createdAt
      updatedAt
      customFeeCurrencyId
      owner
    }
  }
`;
export const deleteCustomFee = /* GraphQL */ `
  mutation DeleteCustomFee(
    $input: DeleteCustomFeeInput!
    $condition: ModelCustomFeeConditionInput
  ) {
    deleteCustomFee(input: $input, condition: $condition) {
      id
      title
      unitPrice
      unitType
      calculationMethod
      currency {
        id
        code
        exchangeRateJPY
        createdAt
        updatedAt
        owner
      }
      minimum
      maximum
      includeTax
      taxRate
      remark
      fobId
      fhdId
      shippingFeeId
      estimateId
      createdAt
      updatedAt
      customFeeCurrencyId
      owner
    }
  }
`;
export const createForwarderCompany = /* GraphQL */ `
  mutation CreateForwarderCompany(
    $input: CreateForwarderCompanyInput!
    $condition: ModelForwarderCompanyConditionInput
  ) {
    createForwarderCompany(input: $input, condition: $condition) {
      id
      name
      firstName
      lastName
      location
      establishment
      paidInCaptial
      corporateNumber
      iataAgency
      listed
      numberOfEmployees
      companyURL
      manager {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          iataAgency
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fobs {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fhds {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          forwarderCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        estimates {
          items {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            cargoType
            yardCutDate
            carrierShedCutDate
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            under45
            over45
            over100
            over300
            over500
            over1000
            fsc
            remark
            customItems {
              nextToken
            }
            forwarderUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            updatedAt
            forwarderUserEstimatesId
            estimateCurrencyId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      fobs {
        items {
          id
          airport
          forwarderCompanyId
          forwarder {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          customItems {
            items {
              id
              title
              unitPrice
              unitType
              calculationMethod
              minimum
              maximum
              includeTax
              taxRate
              remark
              fobId
              fhdId
              shippingFeeId
              estimateId
              createdAt
              updatedAt
              customFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      fhds {
        items {
          id
          airport
          forwarderCompanyId
          forwarder {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          customItems {
            items {
              id
              title
              unitPrice
              unitType
              calculationMethod
              minimum
              maximum
              includeTax
              taxRate
              remark
              fobId
              fhdId
              shippingFeeId
              estimateId
              createdAt
              updatedAt
              customFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      employees {
        items {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      receivedReviews {
        items {
          id
          title
          description
          rate
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          author {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          shipperUserPostedReviewsId
          forwarderCompanyReceivedReviewsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      forwarderCompanyManagerId
      owner
    }
  }
`;
export const updateForwarderCompany = /* GraphQL */ `
  mutation UpdateForwarderCompany(
    $input: UpdateForwarderCompanyInput!
    $condition: ModelForwarderCompanyConditionInput
  ) {
    updateForwarderCompany(input: $input, condition: $condition) {
      id
      name
      firstName
      lastName
      location
      establishment
      paidInCaptial
      corporateNumber
      iataAgency
      listed
      numberOfEmployees
      companyURL
      manager {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          iataAgency
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fobs {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fhds {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          forwarderCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        estimates {
          items {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            cargoType
            yardCutDate
            carrierShedCutDate
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            under45
            over45
            over100
            over300
            over500
            over1000
            fsc
            remark
            customItems {
              nextToken
            }
            forwarderUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            updatedAt
            forwarderUserEstimatesId
            estimateCurrencyId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      fobs {
        items {
          id
          airport
          forwarderCompanyId
          forwarder {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          customItems {
            items {
              id
              title
              unitPrice
              unitType
              calculationMethod
              minimum
              maximum
              includeTax
              taxRate
              remark
              fobId
              fhdId
              shippingFeeId
              estimateId
              createdAt
              updatedAt
              customFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      fhds {
        items {
          id
          airport
          forwarderCompanyId
          forwarder {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          customItems {
            items {
              id
              title
              unitPrice
              unitType
              calculationMethod
              minimum
              maximum
              includeTax
              taxRate
              remark
              fobId
              fhdId
              shippingFeeId
              estimateId
              createdAt
              updatedAt
              customFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      employees {
        items {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      receivedReviews {
        items {
          id
          title
          description
          rate
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          author {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          shipperUserPostedReviewsId
          forwarderCompanyReceivedReviewsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      forwarderCompanyManagerId
      owner
    }
  }
`;
export const deleteForwarderCompany = /* GraphQL */ `
  mutation DeleteForwarderCompany(
    $input: DeleteForwarderCompanyInput!
    $condition: ModelForwarderCompanyConditionInput
  ) {
    deleteForwarderCompany(input: $input, condition: $condition) {
      id
      name
      firstName
      lastName
      location
      establishment
      paidInCaptial
      corporateNumber
      iataAgency
      listed
      numberOfEmployees
      companyURL
      manager {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          iataAgency
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fobs {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fhds {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          forwarderCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        estimates {
          items {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            cargoType
            yardCutDate
            carrierShedCutDate
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            under45
            over45
            over100
            over300
            over500
            over1000
            fsc
            remark
            customItems {
              nextToken
            }
            forwarderUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            updatedAt
            forwarderUserEstimatesId
            estimateCurrencyId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      fobs {
        items {
          id
          airport
          forwarderCompanyId
          forwarder {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          customItems {
            items {
              id
              title
              unitPrice
              unitType
              calculationMethod
              minimum
              maximum
              includeTax
              taxRate
              remark
              fobId
              fhdId
              shippingFeeId
              estimateId
              createdAt
              updatedAt
              customFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      fhds {
        items {
          id
          airport
          forwarderCompanyId
          forwarder {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          customItems {
            items {
              id
              title
              unitPrice
              unitType
              calculationMethod
              minimum
              maximum
              includeTax
              taxRate
              remark
              fobId
              fhdId
              shippingFeeId
              estimateId
              createdAt
              updatedAt
              customFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      employees {
        items {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      receivedReviews {
        items {
          id
          title
          description
          rate
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          author {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          shipperUserPostedReviewsId
          forwarderCompanyReceivedReviewsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      forwarderCompanyManagerId
      owner
    }
  }
`;
export const createShipperCompany = /* GraphQL */ `
  mutation CreateShipperCompany(
    $input: CreateShipperCompanyInput!
    $condition: ModelShipperCompanyConditionInput
  ) {
    createShipperCompany(input: $input, condition: $condition) {
      id
      name
      firstName
      lastName
      location
      establishment
      paidInCaptial
      corporateNumber
      category
      listed
      numberOfEmployees
      companyURL
      manager {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        area
        role
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          category
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          shipperCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      employees {
        items {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      receivedReviews {
        items {
          id
          title
          description
          rate
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          author {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          forwarderUserPostedReviewsId
          shipperCompanyReceivedReviewsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      shipperCompanyManagerId
      owner
    }
  }
`;
export const updateShipperCompany = /* GraphQL */ `
  mutation UpdateShipperCompany(
    $input: UpdateShipperCompanyInput!
    $condition: ModelShipperCompanyConditionInput
  ) {
    updateShipperCompany(input: $input, condition: $condition) {
      id
      name
      firstName
      lastName
      location
      establishment
      paidInCaptial
      corporateNumber
      category
      listed
      numberOfEmployees
      companyURL
      manager {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        area
        role
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          category
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          shipperCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      employees {
        items {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      receivedReviews {
        items {
          id
          title
          description
          rate
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          author {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          forwarderUserPostedReviewsId
          shipperCompanyReceivedReviewsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      shipperCompanyManagerId
      owner
    }
  }
`;
export const deleteShipperCompany = /* GraphQL */ `
  mutation DeleteShipperCompany(
    $input: DeleteShipperCompanyInput!
    $condition: ModelShipperCompanyConditionInput
  ) {
    deleteShipperCompany(input: $input, condition: $condition) {
      id
      name
      firstName
      lastName
      location
      establishment
      paidInCaptial
      corporateNumber
      category
      listed
      numberOfEmployees
      companyURL
      manager {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        area
        role
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          category
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          shipperCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      employees {
        items {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      receivedReviews {
        items {
          id
          title
          description
          rate
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          author {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          forwarderUserPostedReviewsId
          shipperCompanyReceivedReviewsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      shipperCompanyManagerId
      owner
    }
  }
`;
export const createEstimate = /* GraphQL */ `
  mutation CreateEstimate(
    $input: CreateEstimateInput!
    $condition: ModelEstimateConditionInput
  ) {
    createEstimate(input: $input, condition: $condition) {
      id
      departure
      arrival
      airline
      flightDate
      directFlight
      leadTime
      useSaf
      cargoType
      yardCutDate
      carrierShedCutDate
      currency {
        id
        code
        exchangeRateJPY
        createdAt
        updatedAt
        owner
      }
      minimum
      under45
      over45
      over100
      over300
      over500
      over1000
      fsc
      remark
      customItems {
        items {
          id
          title
          unitPrice
          unitType
          calculationMethod
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          minimum
          maximum
          includeTax
          taxRate
          remark
          fobId
          fhdId
          shippingFeeId
          estimateId
          createdAt
          updatedAt
          customFeeCurrencyId
          owner
        }
        nextToken
      }
      forwarderUser {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          iataAgency
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fobs {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fhds {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          forwarderCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        estimates {
          items {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            cargoType
            yardCutDate
            carrierShedCutDate
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            under45
            over45
            over100
            over300
            over500
            over1000
            fsc
            remark
            customItems {
              nextToken
            }
            forwarderUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            updatedAt
            forwarderUserEstimatesId
            estimateCurrencyId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      status
      createdAt
      updatedAt
      forwarderUserEstimatesId
      estimateCurrencyId
      owner
    }
  }
`;
export const updateEstimate = /* GraphQL */ `
  mutation UpdateEstimate(
    $input: UpdateEstimateInput!
    $condition: ModelEstimateConditionInput
  ) {
    updateEstimate(input: $input, condition: $condition) {
      id
      departure
      arrival
      airline
      flightDate
      directFlight
      leadTime
      useSaf
      cargoType
      yardCutDate
      carrierShedCutDate
      currency {
        id
        code
        exchangeRateJPY
        createdAt
        updatedAt
        owner
      }
      minimum
      under45
      over45
      over100
      over300
      over500
      over1000
      fsc
      remark
      customItems {
        items {
          id
          title
          unitPrice
          unitType
          calculationMethod
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          minimum
          maximum
          includeTax
          taxRate
          remark
          fobId
          fhdId
          shippingFeeId
          estimateId
          createdAt
          updatedAt
          customFeeCurrencyId
          owner
        }
        nextToken
      }
      forwarderUser {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          iataAgency
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fobs {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fhds {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          forwarderCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        estimates {
          items {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            cargoType
            yardCutDate
            carrierShedCutDate
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            under45
            over45
            over100
            over300
            over500
            over1000
            fsc
            remark
            customItems {
              nextToken
            }
            forwarderUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            updatedAt
            forwarderUserEstimatesId
            estimateCurrencyId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      status
      createdAt
      updatedAt
      forwarderUserEstimatesId
      estimateCurrencyId
      owner
    }
  }
`;
export const deleteEstimate = /* GraphQL */ `
  mutation DeleteEstimate(
    $input: DeleteEstimateInput!
    $condition: ModelEstimateConditionInput
  ) {
    deleteEstimate(input: $input, condition: $condition) {
      id
      departure
      arrival
      airline
      flightDate
      directFlight
      leadTime
      useSaf
      cargoType
      yardCutDate
      carrierShedCutDate
      currency {
        id
        code
        exchangeRateJPY
        createdAt
        updatedAt
        owner
      }
      minimum
      under45
      over45
      over100
      over300
      over500
      over1000
      fsc
      remark
      customItems {
        items {
          id
          title
          unitPrice
          unitType
          calculationMethod
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          minimum
          maximum
          includeTax
          taxRate
          remark
          fobId
          fhdId
          shippingFeeId
          estimateId
          createdAt
          updatedAt
          customFeeCurrencyId
          owner
        }
        nextToken
      }
      forwarderUser {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          iataAgency
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fobs {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fhds {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          forwarderCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        estimates {
          items {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            cargoType
            yardCutDate
            carrierShedCutDate
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            under45
            over45
            over100
            over300
            over500
            over1000
            fsc
            remark
            customItems {
              nextToken
            }
            forwarderUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            updatedAt
            forwarderUserEstimatesId
            estimateCurrencyId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      status
      createdAt
      updatedAt
      forwarderUserEstimatesId
      estimateCurrencyId
      owner
    }
  }
`;
export const createNews = /* GraphQL */ `
  mutation CreateNews(
    $input: CreateNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    createNews(input: $input, condition: $condition) {
      id
      title
      newsDate
      newsContent
      mediaUrl
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateNews = /* GraphQL */ `
  mutation UpdateNews(
    $input: UpdateNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    updateNews(input: $input, condition: $condition) {
      id
      title
      newsDate
      newsContent
      mediaUrl
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteNews = /* GraphQL */ `
  mutation DeleteNews(
    $input: DeleteNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    deleteNews(input: $input, condition: $condition) {
      id
      title
      newsDate
      newsContent
      mediaUrl
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createMarketConditionsWithSaf = /* GraphQL */ `
  mutation CreateMarketConditionsWithSaf(
    $input: CreateMarketConditionsWithSafInput!
    $condition: ModelMarketConditionsWithSafConditionInput
  ) {
    createMarketConditionsWithSaf(input: $input, condition: $condition) {
      id
      departure
      arrival
      flightDate
      marketPrice
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateMarketConditionsWithSaf = /* GraphQL */ `
  mutation UpdateMarketConditionsWithSaf(
    $input: UpdateMarketConditionsWithSafInput!
    $condition: ModelMarketConditionsWithSafConditionInput
  ) {
    updateMarketConditionsWithSaf(input: $input, condition: $condition) {
      id
      departure
      arrival
      flightDate
      marketPrice
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteMarketConditionsWithSaf = /* GraphQL */ `
  mutation DeleteMarketConditionsWithSaf(
    $input: DeleteMarketConditionsWithSafInput!
    $condition: ModelMarketConditionsWithSafConditionInput
  ) {
    deleteMarketConditionsWithSaf(input: $input, condition: $condition) {
      id
      departure
      arrival
      flightDate
      marketPrice
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createMarketConditionsWithoutSaf = /* GraphQL */ `
  mutation CreateMarketConditionsWithoutSaf(
    $input: CreateMarketConditionsWithoutSafInput!
    $condition: ModelMarketConditionsWithoutSafConditionInput
  ) {
    createMarketConditionsWithoutSaf(input: $input, condition: $condition) {
      id
      departure
      arrival
      flightDate
      marketPrice
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateMarketConditionsWithoutSaf = /* GraphQL */ `
  mutation UpdateMarketConditionsWithoutSaf(
    $input: UpdateMarketConditionsWithoutSafInput!
    $condition: ModelMarketConditionsWithoutSafConditionInput
  ) {
    updateMarketConditionsWithoutSaf(input: $input, condition: $condition) {
      id
      departure
      arrival
      flightDate
      marketPrice
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteMarketConditionsWithoutSaf = /* GraphQL */ `
  mutation DeleteMarketConditionsWithoutSaf(
    $input: DeleteMarketConditionsWithoutSafInput!
    $condition: ModelMarketConditionsWithoutSafConditionInput
  ) {
    deleteMarketConditionsWithoutSaf(input: $input, condition: $condition) {
      id
      departure
      arrival
      flightDate
      marketPrice
      createdAt
      updatedAt
      owner
    }
  }
`;
