import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery} from 'react-query';
import {
  CreateFhdMutation,
  CreateFhdMutationVariables,
  DeleteFhdMutation,
  DeleteFhdMutationVariables,
  FhdsByForwarderCompanyIdQuery,
  FhdsByForwarderCompanyIdQueryVariables,
  GetFhdQuery,
  GetFhdQueryVariables,
  ModelFhdFilterInput,
  UpdateFhdMutation,
  UpdateFhdMutationVariables,
} from '../API';
import {createFhd, deleteFhd, updateFhd} from '../graphql/mutations';
import {fhdsByForwarderCompanyId, getFhd} from '../graphql/queries';

export const useFhds = (companyId: string, arrival?: string) => {
  return useQuery(['fhds', companyId, arrival], async () => {
    const filter: ModelFhdFilterInput = {};
    if (arrival) {
      filter.airport = {
        eq: arrival,
      };
    }
    const variables: FhdsByForwarderCompanyIdQueryVariables = {
      forwarderCompanyId: companyId,
      limit: 10000,
      filter,
    };
    const result = (await API.graphql(
      graphqlOperation(fhdsByForwarderCompanyId, variables),
    )) as GraphQLResult<FhdsByForwarderCompanyIdQuery>;
    return result?.data?.fhdsByForwarderCompanyId?.items;
  });
};

export const useFirstFhds = (companyId: string, arrival?: string) => {
  return useQuery(['fhds', companyId, arrival], async () => {
    const filter: ModelFhdFilterInput = {};
    if (arrival) {
      filter.airport = {
        eq: arrival,
      };
    }
    const variables: FhdsByForwarderCompanyIdQueryVariables = {
      forwarderCompanyId: companyId,
      limit: 10000,
      filter,
    };
    const result = (await API.graphql(
      graphqlOperation(fhdsByForwarderCompanyId, variables),
    )) as GraphQLResult<FhdsByForwarderCompanyIdQuery>;
    return result?.data?.fhdsByForwarderCompanyId?.items?.[0];
  });
};

export const useFhd = (id: string) => {
  return useQuery(
    ['fhd', id],
    async () => {
      const variable: GetFhdQueryVariables = {
        id,
      };
      const result = (await API.graphql(
        graphqlOperation(getFhd, variable),
      )) as GraphQLResult<GetFhdQuery>;
      return result.data?.getFhd;
    },
    {
      enabled: !!id,
    },
  );
};

export const useCreateFhdMutation = () => {
  return useMutation(async (variables: CreateFhdMutationVariables) => {
    const fhd = (await API.graphql(
      graphqlOperation(createFhd, variables),
    )) as GraphQLResult<CreateFhdMutation>;
    return fhd.data?.createFhd;
  });
};

export const useUpdateFhdMutation = () => {
  return useMutation(async (variables: UpdateFhdMutationVariables) => {
    const fhd = (await API.graphql(
      graphqlOperation(updateFhd, variables),
    )) as GraphQLResult<UpdateFhdMutation>;
    return fhd.data?.updateFhd;
  });
};

export const useDeleteFhdMutation = () => {
  return useMutation(async (variables: DeleteFhdMutationVariables) => {
    const fhd = (await API.graphql(
      graphqlOperation(deleteFhd, variables),
    )) as GraphQLResult<DeleteFhdMutation>;
    return fhd.data?.deleteFhd;
  });
};
