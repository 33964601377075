import {CircularProgress, Button} from '@mui/material';
import React from 'react';

interface LoadingButtonProps {
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  size?: string;
  children?: React.ReactNode;
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  onClick,
  disabled = false,
  isLoading = false,
  children,
  size = 'small',
}) => {
  return (
    <Button
      size={size === 'small' || size === 'large' ? size : undefined}
      variant={isLoading ? 'outlined' : 'primary'}
      onClick={onClick}
      disabled={disabled || isLoading}>
      {isLoading ? <CircularProgress size={24} color="inherit" /> : children}
    </Button>
  );
};

export default LoadingButton;
