/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sendInvitation = /* GraphQL */ `
  query SendInvitation($event: SendInvitationEventInput!) {
    sendInvitation(event: $event) {
      statusCode
      body
    }
  }
`;
export const getShipperUser = /* GraphQL */ `
  query GetShipperUser($id: ID!) {
    getShipperUser(id: $id) {
      id
      firstName
      lastName
      email
      phoneNumber
      workLocation
      division
      position
      area
      role
      companyId
      company {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        category
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        shipperCompanyManagerId
        owner
      }
      postedReviews {
        items {
          id
          title
          description
          rate
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          author {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          shipperUserPostedReviewsId
          forwarderCompanyReceivedReviewsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listShipperUsers = /* GraphQL */ `
  query ListShipperUsers(
    $filter: ModelShipperUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShipperUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        area
        role
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          category
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          shipperCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFob = /* GraphQL */ `
  query GetFob($id: ID!) {
    getFob(id: $id) {
      id
      airport
      forwarderCompanyId
      forwarder {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        iataAgency
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fobs {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fhds {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        forwarderCompanyManagerId
        owner
      }
      customItems {
        items {
          id
          title
          unitPrice
          unitType
          calculationMethod
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          minimum
          maximum
          includeTax
          taxRate
          remark
          fobId
          fhdId
          shippingFeeId
          estimateId
          createdAt
          updatedAt
          customFeeCurrencyId
          owner
        }
        nextToken
      }
      remark
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listFobs = /* GraphQL */ `
  query ListFobs(
    $filter: ModelFobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        airport
        forwarderCompanyId
        forwarder {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          iataAgency
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fobs {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fhds {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          forwarderCompanyManagerId
          owner
        }
        customItems {
          items {
            id
            title
            unitPrice
            unitType
            calculationMethod
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            maximum
            includeTax
            taxRate
            remark
            fobId
            fhdId
            shippingFeeId
            estimateId
            createdAt
            updatedAt
            customFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFhd = /* GraphQL */ `
  query GetFhd($id: ID!) {
    getFhd(id: $id) {
      id
      airport
      forwarderCompanyId
      forwarder {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        iataAgency
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fobs {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fhds {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        forwarderCompanyManagerId
        owner
      }
      customItems {
        items {
          id
          title
          unitPrice
          unitType
          calculationMethod
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          minimum
          maximum
          includeTax
          taxRate
          remark
          fobId
          fhdId
          shippingFeeId
          estimateId
          createdAt
          updatedAt
          customFeeCurrencyId
          owner
        }
        nextToken
      }
      remark
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listFhds = /* GraphQL */ `
  query ListFhds(
    $filter: ModelFhdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFhds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        airport
        forwarderCompanyId
        forwarder {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          iataAgency
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fobs {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fhds {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          forwarderCompanyManagerId
          owner
        }
        customItems {
          items {
            id
            title
            unitPrice
            unitType
            calculationMethod
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            maximum
            includeTax
            taxRate
            remark
            fobId
            fhdId
            shippingFeeId
            estimateId
            createdAt
            updatedAt
            customFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getForwarderReview = /* GraphQL */ `
  query GetForwarderReview($id: ID!) {
    getForwarderReview(id: $id) {
      id
      title
      description
      rate
      company {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        category
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        shipperCompanyManagerId
        owner
      }
      author {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          iataAgency
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fobs {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fhds {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          forwarderCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        estimates {
          items {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            cargoType
            yardCutDate
            carrierShedCutDate
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            under45
            over45
            over100
            over300
            over500
            over1000
            fsc
            remark
            customItems {
              nextToken
            }
            forwarderUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            updatedAt
            forwarderUserEstimatesId
            estimateCurrencyId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      forwarderUserPostedReviewsId
      shipperCompanyReceivedReviewsId
      owner
    }
  }
`;
export const listForwarderReviews = /* GraphQL */ `
  query ListForwarderReviews(
    $filter: ModelForwarderReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForwarderReviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        rate
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          category
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          shipperCompanyManagerId
          owner
        }
        author {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        forwarderUserPostedReviewsId
        shipperCompanyReceivedReviewsId
        owner
      }
      nextToken
    }
  }
`;
export const getShipperReview = /* GraphQL */ `
  query GetShipperReview($id: ID!) {
    getShipperReview(id: $id) {
      id
      title
      description
      rate
      company {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        iataAgency
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fobs {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fhds {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        forwarderCompanyManagerId
        owner
      }
      author {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        area
        role
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          category
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          shipperCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      shipperUserPostedReviewsId
      forwarderCompanyReceivedReviewsId
      owner
    }
  }
`;
export const listShipperReviews = /* GraphQL */ `
  query ListShipperReviews(
    $filter: ModelShipperReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShipperReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        rate
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          iataAgency
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fobs {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fhds {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          forwarderCompanyManagerId
          owner
        }
        author {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        shipperUserPostedReviewsId
        forwarderCompanyReceivedReviewsId
        owner
      }
      nextToken
    }
  }
`;
export const getInvitedUser = /* GraphQL */ `
  query GetInvitedUser($email: String!) {
    getInvitedUser(email: $email) {
      id
      firstName
      lastName
      email
      companyId
      isForwarder
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listInvitedUsers = /* GraphQL */ `
  query ListInvitedUsers(
    $email: String
    $filter: ModelInvitedUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInvitedUsers(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        firstName
        lastName
        email
        companyId
        isForwarder
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getShippingCustomFee = /* GraphQL */ `
  query GetShippingCustomFee($id: ID!) {
    getShippingCustomFee(id: $id) {
      id
      title
      currency {
        id
        code
        exchangeRateJPY
        createdAt
        updatedAt
        owner
      }
      unitPrice
      unitType
      fee
      taxRate
      maximum
      minimum
      remark
      shippingFee {
        id
        price
        fsc
        minimum
        currency {
          id
          code
          exchangeRateJPY
          createdAt
          updatedAt
          owner
        }
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFeeCurrencyId
        shippingFeeShippingInstructionId
        owner
      }
      shippingFobFee {
        id
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFobFeeShippingInstructionId
        owner
      }
      shippingFhdFee {
        id
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFhdFeeShippingInstructionId
        owner
      }
      createdAt
      updatedAt
      shippingFeeCustomFeesId
      shippingFobFeeCustomFeesId
      shippingFhdFeeCustomFeesId
      shippingCustomFeeCurrencyId
      owner
    }
  }
`;
export const listShippingCustomFees = /* GraphQL */ `
  query ListShippingCustomFees(
    $filter: ModelShippingCustomFeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShippingCustomFees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        currency {
          id
          code
          exchangeRateJPY
          createdAt
          updatedAt
          owner
        }
        unitPrice
        unitType
        fee
        taxRate
        maximum
        minimum
        remark
        shippingFee {
          id
          price
          fsc
          minimum
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCurrencyId
          shippingFeeShippingInstructionId
          owner
        }
        shippingFobFee {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFobFeeShippingInstructionId
          owner
        }
        shippingFhdFee {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFhdFeeShippingInstructionId
          owner
        }
        createdAt
        updatedAt
        shippingFeeCustomFeesId
        shippingFobFeeCustomFeesId
        shippingFhdFeeCustomFeesId
        shippingCustomFeeCurrencyId
        owner
      }
      nextToken
    }
  }
`;
export const getShippingFee = /* GraphQL */ `
  query GetShippingFee($id: ID!) {
    getShippingFee(id: $id) {
      id
      price
      fsc
      minimum
      currency {
        id
        code
        exchangeRateJPY
        createdAt
        updatedAt
        owner
      }
      customFees {
        items {
          id
          title
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          unitPrice
          unitType
          fee
          taxRate
          maximum
          minimum
          remark
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          shippingFobFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          shippingFhdFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCustomFeesId
          shippingFobFeeCustomFeesId
          shippingFhdFeeCustomFeesId
          shippingCustomFeeCurrencyId
          owner
        }
        nextToken
      }
      remark
      shippingInstruction {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        yardCutDate
        carrierShedCutDate
        itemName
        packingStyle
        packingStyleDetail
        cargoType
        isSds
        regulated
        regulatedDetail
        paymentCondition
        paymentConditionDetail
        taxPaymentConditions {
          realTimeAccount
          comprehensiveDeferredPayment
          cashOnDeliveryAllowed
          beforePermissionPickup
          othersOption
        }
        cargoInsuranceConditions {
          comprehensiveInsurance
          individualInsurance
          uninsuredWithInsurance
          othersOption
        }
        document {
          invoice
          packingList
          nonApplicabilityCertificate
          msds
          other
          detail
        }
        consigneeContact {
          companyName
          name
          email
          phoneNumber
        }
        shipperContact {
          companyName
          name
          email
          phoneNumber
        }
        cargoInsurance
        cargoInsuranceDetail
        packed
        packedDetail
        collected
        collectedDetail
        delivery
        deliveryDetail
        request
        shipperUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shippingItems {
          width
          length
          height
          weight
          numberOfUnit
          volume
          gw
          volumeWeight
          cw
        }
        transportType
        shippingFee {
          id
          price
          fsc
          minimum
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCurrencyId
          shippingFeeShippingInstructionId
          owner
        }
        fob {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFobFeeShippingInstructionId
          owner
        }
        fhd {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFhdFeeShippingInstructionId
          owner
        }
        reservation {
          id
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          hawb
          status
          forwarderCompanyId
          shipperCompanyId
          departure
          createdAt
          updatedAt
          reservationShippingInstructionId
          owner
        }
        createdAt
        updatedAt
        shippingInstructionShipperUserId
        shippingInstructionShippingFeeId
        shippingInstructionFobId
        shippingInstructionFhdId
        shippingInstructionReservationId
        owner
      }
      createdAt
      updatedAt
      shippingFeeCurrencyId
      shippingFeeShippingInstructionId
      owner
    }
  }
`;
export const listShippingFees = /* GraphQL */ `
  query ListShippingFees(
    $filter: ModelShippingFeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShippingFees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        price
        fsc
        minimum
        currency {
          id
          code
          exchangeRateJPY
          createdAt
          updatedAt
          owner
        }
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFeeCurrencyId
        shippingFeeShippingInstructionId
        owner
      }
      nextToken
    }
  }
`;
export const getShippingFobFee = /* GraphQL */ `
  query GetShippingFobFee($id: ID!) {
    getShippingFobFee(id: $id) {
      id
      customFees {
        items {
          id
          title
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          unitPrice
          unitType
          fee
          taxRate
          maximum
          minimum
          remark
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          shippingFobFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          shippingFhdFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCustomFeesId
          shippingFobFeeCustomFeesId
          shippingFhdFeeCustomFeesId
          shippingCustomFeeCurrencyId
          owner
        }
        nextToken
      }
      remark
      shippingInstruction {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        yardCutDate
        carrierShedCutDate
        itemName
        packingStyle
        packingStyleDetail
        cargoType
        isSds
        regulated
        regulatedDetail
        paymentCondition
        paymentConditionDetail
        taxPaymentConditions {
          realTimeAccount
          comprehensiveDeferredPayment
          cashOnDeliveryAllowed
          beforePermissionPickup
          othersOption
        }
        cargoInsuranceConditions {
          comprehensiveInsurance
          individualInsurance
          uninsuredWithInsurance
          othersOption
        }
        document {
          invoice
          packingList
          nonApplicabilityCertificate
          msds
          other
          detail
        }
        consigneeContact {
          companyName
          name
          email
          phoneNumber
        }
        shipperContact {
          companyName
          name
          email
          phoneNumber
        }
        cargoInsurance
        cargoInsuranceDetail
        packed
        packedDetail
        collected
        collectedDetail
        delivery
        deliveryDetail
        request
        shipperUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shippingItems {
          width
          length
          height
          weight
          numberOfUnit
          volume
          gw
          volumeWeight
          cw
        }
        transportType
        shippingFee {
          id
          price
          fsc
          minimum
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCurrencyId
          shippingFeeShippingInstructionId
          owner
        }
        fob {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFobFeeShippingInstructionId
          owner
        }
        fhd {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFhdFeeShippingInstructionId
          owner
        }
        reservation {
          id
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          hawb
          status
          forwarderCompanyId
          shipperCompanyId
          departure
          createdAt
          updatedAt
          reservationShippingInstructionId
          owner
        }
        createdAt
        updatedAt
        shippingInstructionShipperUserId
        shippingInstructionShippingFeeId
        shippingInstructionFobId
        shippingInstructionFhdId
        shippingInstructionReservationId
        owner
      }
      createdAt
      updatedAt
      shippingFobFeeShippingInstructionId
      owner
    }
  }
`;
export const listShippingFobFees = /* GraphQL */ `
  query ListShippingFobFees(
    $filter: ModelShippingFobFeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShippingFobFees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFobFeeShippingInstructionId
        owner
      }
      nextToken
    }
  }
`;
export const getShippingFhdFee = /* GraphQL */ `
  query GetShippingFhdFee($id: ID!) {
    getShippingFhdFee(id: $id) {
      id
      customFees {
        items {
          id
          title
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          unitPrice
          unitType
          fee
          taxRate
          maximum
          minimum
          remark
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          shippingFobFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          shippingFhdFee {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCustomFeesId
          shippingFobFeeCustomFeesId
          shippingFhdFeeCustomFeesId
          shippingCustomFeeCurrencyId
          owner
        }
        nextToken
      }
      remark
      shippingInstruction {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        yardCutDate
        carrierShedCutDate
        itemName
        packingStyle
        packingStyleDetail
        cargoType
        isSds
        regulated
        regulatedDetail
        paymentCondition
        paymentConditionDetail
        taxPaymentConditions {
          realTimeAccount
          comprehensiveDeferredPayment
          cashOnDeliveryAllowed
          beforePermissionPickup
          othersOption
        }
        cargoInsuranceConditions {
          comprehensiveInsurance
          individualInsurance
          uninsuredWithInsurance
          othersOption
        }
        document {
          invoice
          packingList
          nonApplicabilityCertificate
          msds
          other
          detail
        }
        consigneeContact {
          companyName
          name
          email
          phoneNumber
        }
        shipperContact {
          companyName
          name
          email
          phoneNumber
        }
        cargoInsurance
        cargoInsuranceDetail
        packed
        packedDetail
        collected
        collectedDetail
        delivery
        deliveryDetail
        request
        shipperUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shippingItems {
          width
          length
          height
          weight
          numberOfUnit
          volume
          gw
          volumeWeight
          cw
        }
        transportType
        shippingFee {
          id
          price
          fsc
          minimum
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCurrencyId
          shippingFeeShippingInstructionId
          owner
        }
        fob {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFobFeeShippingInstructionId
          owner
        }
        fhd {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFhdFeeShippingInstructionId
          owner
        }
        reservation {
          id
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          hawb
          status
          forwarderCompanyId
          shipperCompanyId
          departure
          createdAt
          updatedAt
          reservationShippingInstructionId
          owner
        }
        createdAt
        updatedAt
        shippingInstructionShipperUserId
        shippingInstructionShippingFeeId
        shippingInstructionFobId
        shippingInstructionFhdId
        shippingInstructionReservationId
        owner
      }
      createdAt
      updatedAt
      shippingFhdFeeShippingInstructionId
      owner
    }
  }
`;
export const listShippingFhdFees = /* GraphQL */ `
  query ListShippingFhdFees(
    $filter: ModelShippingFhdFeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShippingFhdFees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFhdFeeShippingInstructionId
        owner
      }
      nextToken
    }
  }
`;
export const getShippingInstruction = /* GraphQL */ `
  query GetShippingInstruction($id: ID!) {
    getShippingInstruction(id: $id) {
      id
      departure
      arrival
      airline
      flightDate
      directFlight
      leadTime
      useSaf
      yardCutDate
      carrierShedCutDate
      itemName
      packingStyle
      packingStyleDetail
      cargoType
      isSds
      regulated
      regulatedDetail
      paymentCondition
      paymentConditionDetail
      taxPaymentConditions {
        realTimeAccount
        comprehensiveDeferredPayment
        cashOnDeliveryAllowed
        beforePermissionPickup
        othersOption
      }
      cargoInsuranceConditions {
        comprehensiveInsurance
        individualInsurance
        uninsuredWithInsurance
        othersOption
      }
      document {
        invoice
        packingList
        nonApplicabilityCertificate
        msds
        other
        detail
      }
      consigneeContact {
        companyName
        name
        email
        phoneNumber
      }
      shipperContact {
        companyName
        name
        email
        phoneNumber
      }
      cargoInsurance
      cargoInsuranceDetail
      packed
      packedDetail
      collected
      collectedDetail
      delivery
      deliveryDetail
      request
      shipperUser {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        area
        role
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          category
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          shipperCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      shippingItems {
        width
        length
        height
        weight
        numberOfUnit
        volume
        gw
        volumeWeight
        cw
      }
      transportType
      shippingFee {
        id
        price
        fsc
        minimum
        currency {
          id
          code
          exchangeRateJPY
          createdAt
          updatedAt
          owner
        }
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFeeCurrencyId
        shippingFeeShippingInstructionId
        owner
      }
      fob {
        id
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFobFeeShippingInstructionId
        owner
      }
      fhd {
        id
        customFees {
          items {
            id
            title
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            unitPrice
            unitType
            fee
            taxRate
            maximum
            minimum
            remark
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            shippingFobFee {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            shippingFhdFee {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCustomFeesId
            shippingFobFeeCustomFeesId
            shippingFhdFeeCustomFeesId
            shippingCustomFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        createdAt
        updatedAt
        shippingFhdFeeShippingInstructionId
        owner
      }
      reservation {
        id
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        hawb
        status
        forwarderCompanyId
        shipperCompanyId
        departure
        createdAt
        updatedAt
        reservationShippingInstructionId
        owner
      }
      createdAt
      updatedAt
      shippingInstructionShipperUserId
      shippingInstructionShippingFeeId
      shippingInstructionFobId
      shippingInstructionFhdId
      shippingInstructionReservationId
      owner
    }
  }
`;
export const listShippingInstructions = /* GraphQL */ `
  query ListShippingInstructions(
    $filter: ModelShippingInstructionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShippingInstructions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        yardCutDate
        carrierShedCutDate
        itemName
        packingStyle
        packingStyleDetail
        cargoType
        isSds
        regulated
        regulatedDetail
        paymentCondition
        paymentConditionDetail
        taxPaymentConditions {
          realTimeAccount
          comprehensiveDeferredPayment
          cashOnDeliveryAllowed
          beforePermissionPickup
          othersOption
        }
        cargoInsuranceConditions {
          comprehensiveInsurance
          individualInsurance
          uninsuredWithInsurance
          othersOption
        }
        document {
          invoice
          packingList
          nonApplicabilityCertificate
          msds
          other
          detail
        }
        consigneeContact {
          companyName
          name
          email
          phoneNumber
        }
        shipperContact {
          companyName
          name
          email
          phoneNumber
        }
        cargoInsurance
        cargoInsuranceDetail
        packed
        packedDetail
        collected
        collectedDetail
        delivery
        deliveryDetail
        request
        shipperUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shippingItems {
          width
          length
          height
          weight
          numberOfUnit
          volume
          gw
          volumeWeight
          cw
        }
        transportType
        shippingFee {
          id
          price
          fsc
          minimum
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCurrencyId
          shippingFeeShippingInstructionId
          owner
        }
        fob {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFobFeeShippingInstructionId
          owner
        }
        fhd {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFhdFeeShippingInstructionId
          owner
        }
        reservation {
          id
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          hawb
          status
          forwarderCompanyId
          shipperCompanyId
          departure
          createdAt
          updatedAt
          reservationShippingInstructionId
          owner
        }
        createdAt
        updatedAt
        shippingInstructionShipperUserId
        shippingInstructionShippingFeeId
        shippingInstructionFobId
        shippingInstructionFhdId
        shippingInstructionReservationId
        owner
      }
      nextToken
    }
  }
`;
export const getReservation = /* GraphQL */ `
  query GetReservation($id: ID!) {
    getReservation(id: $id) {
      id
      shippingInstruction {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        yardCutDate
        carrierShedCutDate
        itemName
        packingStyle
        packingStyleDetail
        cargoType
        isSds
        regulated
        regulatedDetail
        paymentCondition
        paymentConditionDetail
        taxPaymentConditions {
          realTimeAccount
          comprehensiveDeferredPayment
          cashOnDeliveryAllowed
          beforePermissionPickup
          othersOption
        }
        cargoInsuranceConditions {
          comprehensiveInsurance
          individualInsurance
          uninsuredWithInsurance
          othersOption
        }
        document {
          invoice
          packingList
          nonApplicabilityCertificate
          msds
          other
          detail
        }
        consigneeContact {
          companyName
          name
          email
          phoneNumber
        }
        shipperContact {
          companyName
          name
          email
          phoneNumber
        }
        cargoInsurance
        cargoInsuranceDetail
        packed
        packedDetail
        collected
        collectedDetail
        delivery
        deliveryDetail
        request
        shipperUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        shippingItems {
          width
          length
          height
          weight
          numberOfUnit
          volume
          gw
          volumeWeight
          cw
        }
        transportType
        shippingFee {
          id
          price
          fsc
          minimum
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFeeCurrencyId
          shippingFeeShippingInstructionId
          owner
        }
        fob {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFobFeeShippingInstructionId
          owner
        }
        fhd {
          id
          customFees {
            items {
              id
              title
              unitPrice
              unitType
              fee
              taxRate
              maximum
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCustomFeesId
              shippingFobFeeCustomFeesId
              shippingFhdFeeCustomFeesId
              shippingCustomFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          createdAt
          updatedAt
          shippingFhdFeeShippingInstructionId
          owner
        }
        reservation {
          id
          shippingInstruction {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            yardCutDate
            carrierShedCutDate
            itemName
            packingStyle
            packingStyleDetail
            cargoType
            isSds
            regulated
            regulatedDetail
            paymentCondition
            paymentConditionDetail
            taxPaymentConditions {
              realTimeAccount
              comprehensiveDeferredPayment
              cashOnDeliveryAllowed
              beforePermissionPickup
              othersOption
            }
            cargoInsuranceConditions {
              comprehensiveInsurance
              individualInsurance
              uninsuredWithInsurance
              othersOption
            }
            document {
              invoice
              packingList
              nonApplicabilityCertificate
              msds
              other
              detail
            }
            consigneeContact {
              companyName
              name
              email
              phoneNumber
            }
            shipperContact {
              companyName
              name
              email
              phoneNumber
            }
            cargoInsurance
            cargoInsuranceDetail
            packed
            packedDetail
            collected
            collectedDetail
            delivery
            deliveryDetail
            request
            shipperUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            shippingItems {
              width
              length
              height
              weight
              numberOfUnit
              volume
              gw
              volumeWeight
              cw
            }
            transportType
            shippingFee {
              id
              price
              fsc
              minimum
              remark
              createdAt
              updatedAt
              shippingFeeCurrencyId
              shippingFeeShippingInstructionId
              owner
            }
            fob {
              id
              remark
              createdAt
              updatedAt
              shippingFobFeeShippingInstructionId
              owner
            }
            fhd {
              id
              remark
              createdAt
              updatedAt
              shippingFhdFeeShippingInstructionId
              owner
            }
            reservation {
              id
              hawb
              status
              forwarderCompanyId
              shipperCompanyId
              departure
              createdAt
              updatedAt
              reservationShippingInstructionId
              owner
            }
            createdAt
            updatedAt
            shippingInstructionShipperUserId
            shippingInstructionShippingFeeId
            shippingInstructionFobId
            shippingInstructionFhdId
            shippingInstructionReservationId
            owner
          }
          hawb
          status
          forwarderCompanyId
          shipperCompanyId
          departure
          createdAt
          updatedAt
          reservationShippingInstructionId
          owner
        }
        createdAt
        updatedAt
        shippingInstructionShipperUserId
        shippingInstructionShippingFeeId
        shippingInstructionFobId
        shippingInstructionFhdId
        shippingInstructionReservationId
        owner
      }
      hawb
      status
      forwarderCompanyId
      shipperCompanyId
      departure
      createdAt
      updatedAt
      reservationShippingInstructionId
      owner
    }
  }
`;
export const listReservations = /* GraphQL */ `
  query ListReservations(
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shippingInstruction {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          yardCutDate
          carrierShedCutDate
          itemName
          packingStyle
          packingStyleDetail
          cargoType
          isSds
          regulated
          regulatedDetail
          paymentCondition
          paymentConditionDetail
          taxPaymentConditions {
            realTimeAccount
            comprehensiveDeferredPayment
            cashOnDeliveryAllowed
            beforePermissionPickup
            othersOption
          }
          cargoInsuranceConditions {
            comprehensiveInsurance
            individualInsurance
            uninsuredWithInsurance
            othersOption
          }
          document {
            invoice
            packingList
            nonApplicabilityCertificate
            msds
            other
            detail
          }
          consigneeContact {
            companyName
            name
            email
            phoneNumber
          }
          shipperContact {
            companyName
            name
            email
            phoneNumber
          }
          cargoInsurance
          cargoInsuranceDetail
          packed
          packedDetail
          collected
          collectedDetail
          delivery
          deliveryDetail
          request
          shipperUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          shippingItems {
            width
            length
            height
            weight
            numberOfUnit
            volume
            gw
            volumeWeight
            cw
          }
          transportType
          shippingFee {
            id
            price
            fsc
            minimum
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFeeCurrencyId
            shippingFeeShippingInstructionId
            owner
          }
          fob {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFobFeeShippingInstructionId
            owner
          }
          fhd {
            id
            customFees {
              nextToken
            }
            remark
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            createdAt
            updatedAt
            shippingFhdFeeShippingInstructionId
            owner
          }
          reservation {
            id
            shippingInstruction {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              yardCutDate
              carrierShedCutDate
              itemName
              packingStyle
              packingStyleDetail
              cargoType
              isSds
              regulated
              regulatedDetail
              paymentCondition
              paymentConditionDetail
              cargoInsurance
              cargoInsuranceDetail
              packed
              packedDetail
              collected
              collectedDetail
              delivery
              deliveryDetail
              request
              transportType
              createdAt
              updatedAt
              shippingInstructionShipperUserId
              shippingInstructionShippingFeeId
              shippingInstructionFobId
              shippingInstructionFhdId
              shippingInstructionReservationId
              owner
            }
            hawb
            status
            forwarderCompanyId
            shipperCompanyId
            departure
            createdAt
            updatedAt
            reservationShippingInstructionId
            owner
          }
          createdAt
          updatedAt
          shippingInstructionShipperUserId
          shippingInstructionShippingFeeId
          shippingInstructionFobId
          shippingInstructionFhdId
          shippingInstructionReservationId
          owner
        }
        hawb
        status
        forwarderCompanyId
        shipperCompanyId
        departure
        createdAt
        updatedAt
        reservationShippingInstructionId
        owner
      }
      nextToken
    }
  }
`;
export const shipperUsersByCompanyId = /* GraphQL */ `
  query ShipperUsersByCompanyId(
    $companyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelShipperUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipperUsersByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        area
        role
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          category
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          shipperCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const fobsByForwarderCompanyId = /* GraphQL */ `
  query FobsByForwarderCompanyId(
    $forwarderCompanyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fobsByForwarderCompanyId(
      forwarderCompanyId: $forwarderCompanyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        airport
        forwarderCompanyId
        forwarder {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          iataAgency
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fobs {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fhds {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          forwarderCompanyManagerId
          owner
        }
        customItems {
          items {
            id
            title
            unitPrice
            unitType
            calculationMethod
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            maximum
            includeTax
            taxRate
            remark
            fobId
            fhdId
            shippingFeeId
            estimateId
            createdAt
            updatedAt
            customFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const fhdsByForwarderCompanyId = /* GraphQL */ `
  query FhdsByForwarderCompanyId(
    $forwarderCompanyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFhdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fhdsByForwarderCompanyId(
      forwarderCompanyId: $forwarderCompanyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        airport
        forwarderCompanyId
        forwarder {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          iataAgency
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fobs {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fhds {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          forwarderCompanyManagerId
          owner
        }
        customItems {
          items {
            id
            title
            unitPrice
            unitType
            calculationMethod
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            maximum
            includeTax
            taxRate
            remark
            fobId
            fhdId
            shippingFeeId
            estimateId
            createdAt
            updatedAt
            customFeeCurrencyId
            owner
          }
          nextToken
        }
        remark
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const invitedUsersByCompanyId = /* GraphQL */ `
  query InvitedUsersByCompanyId(
    $companyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitedUsersByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        companyId
        isForwarder
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCurrency = /* GraphQL */ `
  query GetCurrency($id: ID!) {
    getCurrency(id: $id) {
      id
      code
      exchangeRateJPY
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCurrencies = /* GraphQL */ `
  query ListCurrencies(
    $filter: ModelCurrencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurrencies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        exchangeRateJPY
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getForwarderUser = /* GraphQL */ `
  query GetForwarderUser($id: ID!) {
    getForwarderUser(id: $id) {
      id
      firstName
      lastName
      email
      phoneNumber
      workLocation
      division
      position
      companyId
      company {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        iataAgency
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fobs {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fhds {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        forwarderCompanyManagerId
        owner
      }
      postedReviews {
        items {
          id
          title
          description
          rate
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          author {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          forwarderUserPostedReviewsId
          shipperCompanyReceivedReviewsId
          owner
        }
        nextToken
      }
      estimates {
        items {
          id
          departure
          arrival
          airline
          flightDate
          directFlight
          leadTime
          useSaf
          cargoType
          yardCutDate
          carrierShedCutDate
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          minimum
          under45
          over45
          over100
          over300
          over500
          over1000
          fsc
          remark
          customItems {
            items {
              id
              title
              unitPrice
              unitType
              calculationMethod
              minimum
              maximum
              includeTax
              taxRate
              remark
              fobId
              fhdId
              shippingFeeId
              estimateId
              createdAt
              updatedAt
              customFeeCurrencyId
              owner
            }
            nextToken
          }
          forwarderUser {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          status
          createdAt
          updatedAt
          forwarderUserEstimatesId
          estimateCurrencyId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listForwarderUsers = /* GraphQL */ `
  query ListForwarderUsers(
    $filter: ModelForwarderUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForwarderUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          iataAgency
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fobs {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fhds {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          forwarderCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        estimates {
          items {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            cargoType
            yardCutDate
            carrierShedCutDate
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            under45
            over45
            over100
            over300
            over500
            over1000
            fsc
            remark
            customItems {
              nextToken
            }
            forwarderUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            updatedAt
            forwarderUserEstimatesId
            estimateCurrencyId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const forwarderUsersByCompanyId = /* GraphQL */ `
  query ForwarderUsersByCompanyId(
    $companyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelForwarderUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    forwarderUsersByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          iataAgency
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fobs {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fhds {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          forwarderCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        estimates {
          items {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            cargoType
            yardCutDate
            carrierShedCutDate
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            under45
            over45
            over100
            over300
            over500
            over1000
            fsc
            remark
            customItems {
              nextToken
            }
            forwarderUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            updatedAt
            forwarderUserEstimatesId
            estimateCurrencyId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCustomFee = /* GraphQL */ `
  query GetCustomFee($id: ID!) {
    getCustomFee(id: $id) {
      id
      title
      unitPrice
      unitType
      calculationMethod
      currency {
        id
        code
        exchangeRateJPY
        createdAt
        updatedAt
        owner
      }
      minimum
      maximum
      includeTax
      taxRate
      remark
      fobId
      fhdId
      shippingFeeId
      estimateId
      createdAt
      updatedAt
      customFeeCurrencyId
      owner
    }
  }
`;
export const listCustomFees = /* GraphQL */ `
  query ListCustomFees(
    $filter: ModelCustomFeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomFees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        unitPrice
        unitType
        calculationMethod
        currency {
          id
          code
          exchangeRateJPY
          createdAt
          updatedAt
          owner
        }
        minimum
        maximum
        includeTax
        taxRate
        remark
        fobId
        fhdId
        shippingFeeId
        estimateId
        createdAt
        updatedAt
        customFeeCurrencyId
        owner
      }
      nextToken
    }
  }
`;
export const customFeesByFobId = /* GraphQL */ `
  query CustomFeesByFobId(
    $fobId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomFeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customFeesByFobId(
      fobId: $fobId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        unitPrice
        unitType
        calculationMethod
        currency {
          id
          code
          exchangeRateJPY
          createdAt
          updatedAt
          owner
        }
        minimum
        maximum
        includeTax
        taxRate
        remark
        fobId
        fhdId
        shippingFeeId
        estimateId
        createdAt
        updatedAt
        customFeeCurrencyId
        owner
      }
      nextToken
    }
  }
`;
export const customFeesByFhdId = /* GraphQL */ `
  query CustomFeesByFhdId(
    $fhdId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomFeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customFeesByFhdId(
      fhdId: $fhdId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        unitPrice
        unitType
        calculationMethod
        currency {
          id
          code
          exchangeRateJPY
          createdAt
          updatedAt
          owner
        }
        minimum
        maximum
        includeTax
        taxRate
        remark
        fobId
        fhdId
        shippingFeeId
        estimateId
        createdAt
        updatedAt
        customFeeCurrencyId
        owner
      }
      nextToken
    }
  }
`;
export const customFeesByShippingFeeId = /* GraphQL */ `
  query CustomFeesByShippingFeeId(
    $shippingFeeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomFeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customFeesByShippingFeeId(
      shippingFeeId: $shippingFeeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        unitPrice
        unitType
        calculationMethod
        currency {
          id
          code
          exchangeRateJPY
          createdAt
          updatedAt
          owner
        }
        minimum
        maximum
        includeTax
        taxRate
        remark
        fobId
        fhdId
        shippingFeeId
        estimateId
        createdAt
        updatedAt
        customFeeCurrencyId
        owner
      }
      nextToken
    }
  }
`;
export const customFeesByEstimateId = /* GraphQL */ `
  query CustomFeesByEstimateId(
    $estimateId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomFeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customFeesByEstimateId(
      estimateId: $estimateId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        unitPrice
        unitType
        calculationMethod
        currency {
          id
          code
          exchangeRateJPY
          createdAt
          updatedAt
          owner
        }
        minimum
        maximum
        includeTax
        taxRate
        remark
        fobId
        fhdId
        shippingFeeId
        estimateId
        createdAt
        updatedAt
        customFeeCurrencyId
        owner
      }
      nextToken
    }
  }
`;
export const getForwarderCompany = /* GraphQL */ `
  query GetForwarderCompany($id: ID!) {
    getForwarderCompany(id: $id) {
      id
      name
      firstName
      lastName
      location
      establishment
      paidInCaptial
      corporateNumber
      iataAgency
      listed
      numberOfEmployees
      companyURL
      manager {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          iataAgency
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fobs {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fhds {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          forwarderCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        estimates {
          items {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            cargoType
            yardCutDate
            carrierShedCutDate
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            under45
            over45
            over100
            over300
            over500
            over1000
            fsc
            remark
            customItems {
              nextToken
            }
            forwarderUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            updatedAt
            forwarderUserEstimatesId
            estimateCurrencyId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      fobs {
        items {
          id
          airport
          forwarderCompanyId
          forwarder {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          customItems {
            items {
              id
              title
              unitPrice
              unitType
              calculationMethod
              minimum
              maximum
              includeTax
              taxRate
              remark
              fobId
              fhdId
              shippingFeeId
              estimateId
              createdAt
              updatedAt
              customFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      fhds {
        items {
          id
          airport
          forwarderCompanyId
          forwarder {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          customItems {
            items {
              id
              title
              unitPrice
              unitType
              calculationMethod
              minimum
              maximum
              includeTax
              taxRate
              remark
              fobId
              fhdId
              shippingFeeId
              estimateId
              createdAt
              updatedAt
              customFeeCurrencyId
              owner
            }
            nextToken
          }
          remark
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      employees {
        items {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      receivedReviews {
        items {
          id
          title
          description
          rate
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          author {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          shipperUserPostedReviewsId
          forwarderCompanyReceivedReviewsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      forwarderCompanyManagerId
      owner
    }
  }
`;
export const listForwarderCompanies = /* GraphQL */ `
  query ListForwarderCompanies(
    $filter: ModelForwarderCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForwarderCompanies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        iataAgency
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        fobs {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        fhds {
          items {
            id
            airport
            forwarderCompanyId
            forwarder {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            customItems {
              nextToken
            }
            remark
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        forwarderCompanyManagerId
        owner
      }
      nextToken
    }
  }
`;
export const getShipperCompany = /* GraphQL */ `
  query GetShipperCompany($id: ID!) {
    getShipperCompany(id: $id) {
      id
      name
      firstName
      lastName
      location
      establishment
      paidInCaptial
      corporateNumber
      category
      listed
      numberOfEmployees
      companyURL
      manager {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        area
        role
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          category
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          shipperCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            shipperUserPostedReviewsId
            forwarderCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      employees {
        items {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      receivedReviews {
        items {
          id
          title
          description
          rate
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          author {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          forwarderUserPostedReviewsId
          shipperCompanyReceivedReviewsId
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      shipperCompanyManagerId
      owner
    }
  }
`;
export const listShipperCompanies = /* GraphQL */ `
  query ListShipperCompanies(
    $filter: ModelShipperCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShipperCompanies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        firstName
        lastName
        location
        establishment
        paidInCaptial
        corporateNumber
        category
        listed
        numberOfEmployees
        companyURL
        manager {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          area
          role
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            category
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              area
              role
              companyId
              createdAt
              updatedAt
              owner
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            shipperCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        employees {
          items {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            area
            role
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        receivedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        shipperCompanyManagerId
        owner
      }
      nextToken
    }
  }
`;
export const getEstimate = /* GraphQL */ `
  query GetEstimate($id: ID!) {
    getEstimate(id: $id) {
      id
      departure
      arrival
      airline
      flightDate
      directFlight
      leadTime
      useSaf
      cargoType
      yardCutDate
      carrierShedCutDate
      currency {
        id
        code
        exchangeRateJPY
        createdAt
        updatedAt
        owner
      }
      minimum
      under45
      over45
      over100
      over300
      over500
      over1000
      fsc
      remark
      customItems {
        items {
          id
          title
          unitPrice
          unitType
          calculationMethod
          currency {
            id
            code
            exchangeRateJPY
            createdAt
            updatedAt
            owner
          }
          minimum
          maximum
          includeTax
          taxRate
          remark
          fobId
          fhdId
          shippingFeeId
          estimateId
          createdAt
          updatedAt
          customFeeCurrencyId
          owner
        }
        nextToken
      }
      forwarderUser {
        id
        firstName
        lastName
        email
        phoneNumber
        workLocation
        division
        position
        companyId
        company {
          id
          name
          firstName
          lastName
          location
          establishment
          paidInCaptial
          corporateNumber
          iataAgency
          listed
          numberOfEmployees
          companyURL
          manager {
            id
            firstName
            lastName
            email
            phoneNumber
            workLocation
            division
            position
            companyId
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              iataAgency
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              forwarderCompanyManagerId
              owner
            }
            postedReviews {
              nextToken
            }
            estimates {
              nextToken
            }
            createdAt
            updatedAt
            owner
          }
          fobs {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          fhds {
            items {
              id
              airport
              forwarderCompanyId
              remark
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          employees {
            items {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          receivedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              shipperUserPostedReviewsId
              forwarderCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          forwarderCompanyManagerId
          owner
        }
        postedReviews {
          items {
            id
            title
            description
            rate
            company {
              id
              name
              firstName
              lastName
              location
              establishment
              paidInCaptial
              corporateNumber
              category
              listed
              numberOfEmployees
              companyURL
              createdAt
              updatedAt
              shipperCompanyManagerId
              owner
            }
            author {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            createdAt
            updatedAt
            forwarderUserPostedReviewsId
            shipperCompanyReceivedReviewsId
            owner
          }
          nextToken
        }
        estimates {
          items {
            id
            departure
            arrival
            airline
            flightDate
            directFlight
            leadTime
            useSaf
            cargoType
            yardCutDate
            carrierShedCutDate
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            under45
            over45
            over100
            over300
            over500
            over1000
            fsc
            remark
            customItems {
              nextToken
            }
            forwarderUser {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            status
            createdAt
            updatedAt
            forwarderUserEstimatesId
            estimateCurrencyId
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      status
      createdAt
      updatedAt
      forwarderUserEstimatesId
      estimateCurrencyId
      owner
    }
  }
`;
export const listEstimates = /* GraphQL */ `
  query ListEstimates(
    $filter: ModelEstimateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEstimates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        cargoType
        yardCutDate
        carrierShedCutDate
        currency {
          id
          code
          exchangeRateJPY
          createdAt
          updatedAt
          owner
        }
        minimum
        under45
        over45
        over100
        over300
        over500
        over1000
        fsc
        remark
        customItems {
          items {
            id
            title
            unitPrice
            unitType
            calculationMethod
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            maximum
            includeTax
            taxRate
            remark
            fobId
            fhdId
            shippingFeeId
            estimateId
            createdAt
            updatedAt
            customFeeCurrencyId
            owner
          }
          nextToken
        }
        forwarderUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        status
        createdAt
        updatedAt
        forwarderUserEstimatesId
        estimateCurrencyId
        owner
      }
      nextToken
    }
  }
`;
export const listEstimatesByDeparture = /* GraphQL */ `
  query ListEstimatesByDeparture(
    $departure: String!
    $sortDirection: ModelSortDirection
    $filter: ModelEstimateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEstimatesByDeparture(
      departure: $departure
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        cargoType
        yardCutDate
        carrierShedCutDate
        currency {
          id
          code
          exchangeRateJPY
          createdAt
          updatedAt
          owner
        }
        minimum
        under45
        over45
        over100
        over300
        over500
        over1000
        fsc
        remark
        customItems {
          items {
            id
            title
            unitPrice
            unitType
            calculationMethod
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            maximum
            includeTax
            taxRate
            remark
            fobId
            fhdId
            shippingFeeId
            estimateId
            createdAt
            updatedAt
            customFeeCurrencyId
            owner
          }
          nextToken
        }
        forwarderUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        status
        createdAt
        updatedAt
        forwarderUserEstimatesId
        estimateCurrencyId
        owner
      }
      nextToken
    }
  }
`;
export const listFollowsByArrival = /* GraphQL */ `
  query ListFollowsByArrival(
    $arrival: String!
    $sortDirection: ModelSortDirection
    $filter: ModelEstimateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollowsByArrival(
      arrival: $arrival
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        cargoType
        yardCutDate
        carrierShedCutDate
        currency {
          id
          code
          exchangeRateJPY
          createdAt
          updatedAt
          owner
        }
        minimum
        under45
        over45
        over100
        over300
        over500
        over1000
        fsc
        remark
        customItems {
          items {
            id
            title
            unitPrice
            unitType
            calculationMethod
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            maximum
            includeTax
            taxRate
            remark
            fobId
            fhdId
            shippingFeeId
            estimateId
            createdAt
            updatedAt
            customFeeCurrencyId
            owner
          }
          nextToken
        }
        forwarderUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        status
        createdAt
        updatedAt
        forwarderUserEstimatesId
        estimateCurrencyId
        owner
      }
      nextToken
    }
  }
`;
export const listEstimatesByFlightDate = /* GraphQL */ `
  query ListEstimatesByFlightDate(
    $flightDate: AWSDate!
    $sortDirection: ModelSortDirection
    $filter: ModelEstimateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEstimatesByFlightDate(
      flightDate: $flightDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departure
        arrival
        airline
        flightDate
        directFlight
        leadTime
        useSaf
        cargoType
        yardCutDate
        carrierShedCutDate
        currency {
          id
          code
          exchangeRateJPY
          createdAt
          updatedAt
          owner
        }
        minimum
        under45
        over45
        over100
        over300
        over500
        over1000
        fsc
        remark
        customItems {
          items {
            id
            title
            unitPrice
            unitType
            calculationMethod
            currency {
              id
              code
              exchangeRateJPY
              createdAt
              updatedAt
              owner
            }
            minimum
            maximum
            includeTax
            taxRate
            remark
            fobId
            fhdId
            shippingFeeId
            estimateId
            createdAt
            updatedAt
            customFeeCurrencyId
            owner
          }
          nextToken
        }
        forwarderUser {
          id
          firstName
          lastName
          email
          phoneNumber
          workLocation
          division
          position
          companyId
          company {
            id
            name
            firstName
            lastName
            location
            establishment
            paidInCaptial
            corporateNumber
            iataAgency
            listed
            numberOfEmployees
            companyURL
            manager {
              id
              firstName
              lastName
              email
              phoneNumber
              workLocation
              division
              position
              companyId
              createdAt
              updatedAt
              owner
            }
            fobs {
              nextToken
            }
            fhds {
              nextToken
            }
            employees {
              nextToken
            }
            receivedReviews {
              nextToken
            }
            createdAt
            updatedAt
            forwarderCompanyManagerId
            owner
          }
          postedReviews {
            items {
              id
              title
              description
              rate
              createdAt
              updatedAt
              forwarderUserPostedReviewsId
              shipperCompanyReceivedReviewsId
              owner
            }
            nextToken
          }
          estimates {
            items {
              id
              departure
              arrival
              airline
              flightDate
              directFlight
              leadTime
              useSaf
              cargoType
              yardCutDate
              carrierShedCutDate
              minimum
              under45
              over45
              over100
              over300
              over500
              over1000
              fsc
              remark
              status
              createdAt
              updatedAt
              forwarderUserEstimatesId
              estimateCurrencyId
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          owner
        }
        status
        createdAt
        updatedAt
        forwarderUserEstimatesId
        estimateCurrencyId
        owner
      }
      nextToken
    }
  }
`;
export const getNews = /* GraphQL */ `
  query GetNews($id: ID!) {
    getNews(id: $id) {
      id
      title
      newsDate
      newsContent
      mediaUrl
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listNews = /* GraphQL */ `
  query ListNews(
    $filter: ModelNewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        newsDate
        newsContent
        mediaUrl
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getMarketConditionsWithSaf = /* GraphQL */ `
  query GetMarketConditionsWithSaf($id: ID!) {
    getMarketConditionsWithSaf(id: $id) {
      id
      departure
      arrival
      flightDate
      marketPrice
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listMarketConditionsWithSafs = /* GraphQL */ `
  query ListMarketConditionsWithSafs(
    $filter: ModelMarketConditionsWithSafFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarketConditionsWithSafs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departure
        arrival
        flightDate
        marketPrice
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getMarketConditionsWithoutSaf = /* GraphQL */ `
  query GetMarketConditionsWithoutSaf($id: ID!) {
    getMarketConditionsWithoutSaf(id: $id) {
      id
      departure
      arrival
      flightDate
      marketPrice
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listMarketConditionsWithoutSafs = /* GraphQL */ `
  query ListMarketConditionsWithoutSafs(
    $filter: ModelMarketConditionsWithoutSafFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarketConditionsWithoutSafs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departure
        arrival
        flightDate
        marketPrice
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
