import styled from '@emotion/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton, Stack} from '@mui/material';
import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {TextField} from './TextField';
import {roundUpToNearestHalf} from '../screens/Shipper/SearchEstimateResultCompare';
import {roundToDecimal} from '../utils/roundToDecimal';

interface InputEstimateItemsProps {
  onValueChange: (newValue: Item[]) => void;
  onItemsDisableChange: (newValue: boolean) => void;
}

export interface Item {
  id: number;
  width: number;
  length: number;
  height: number;
  weight: number;
  numberOfUnit: number;
  gw: number;
  volume: number;
  volumeWeight: number;
  cw: number;
}

const calcGrossWeight = (weight: number, numberOfUnit: number) => {
  return weight * numberOfUnit;
};

const calcVolume = (
  width: number,
  length: number,
  height: number,
  numberOfUnit: number,
) => {
  return (width * length * height * numberOfUnit) / 1000000;
};

const calcVolumeWeight = (
  width: number,
  length: number,
  height: number,
  numberOfUnit: number,
) => {
  return ((width * length * height) / 6000) * numberOfUnit;
};

const calcChargeableWeight = (volumeWeight: number, grossWeight: number) => {
  return volumeWeight > grossWeight ? volumeWeight : grossWeight;
};

export const InputEstimateItems: React.FC<InputEstimateItemsProps> = ({
  onValueChange,
  onItemsDisableChange,
}) => {
  const {t} = useTranslation();
  const [index, setIndex] = useState(0);
  const [items, setItems] = useState<Item[]>([
    {
      id: 0,
      width: 0,
      length: 0,
      height: 0,
      weight: 0,
      numberOfUnit: 0,
      gw: 0,
      volume: 0,
      volumeWeight: 0,
      cw: 0,
    },
  ]);
  const [totalCw, setTotalCw] = useState(0);

  useEffect(() => {
    // 条件を満たす場合、検索ボタンを無効化
    onItemsDisableChange(
      items.some(item => {
        return (
          item.width === 0 ||
          item.length === 0 ||
          item.height === 0 ||
          item.weight === 0 ||
          item.numberOfUnit === 0 ||
          // 初期値が削除された場合のNaNチェック
          isNaN(item.width) ||
          isNaN(item.length) ||
          isNaN(item.height) ||
          isNaN(item.weight) ||
          isNaN(item.numberOfUnit)
        );
      }),
    );
  }, [onItemsDisableChange, items]);

  const handleInputChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      propertyName: keyof Item,
      itemId: number,
    ) => {
      setItems(prev => {
        const updatedItems = prev.map(item => {
          if (item.id !== itemId) {
            return item;
          }
          if (propertyName === 'numberOfUnit') {
            item.numberOfUnit = parseInt(event.target.value);
          }
          const newValue = roundToDecimal(event.target.value, 1);
          if (propertyName === 'width') {
            item.width = newValue;
          } else if (propertyName === 'length') {
            item.length = newValue;
          } else if (propertyName === 'height') {
            item.height = newValue;
          } else if (propertyName === 'weight') {
            item.weight = newValue;
          }
          item.gw = roundToDecimal(
            calcGrossWeight(item.weight, item.numberOfUnit),
            1,
          );
          item.volume = roundToDecimal(
            calcVolume(item.width, item.length, item.height, item.numberOfUnit),
            2,
          );
          item.volumeWeight = roundToDecimal(
            calcVolumeWeight(
              item.width,
              item.length,
              item.height,
              item.numberOfUnit,
            ),
            1,
          );
          item.cw = roundToDecimal(
            calcChargeableWeight(item.volumeWeight, item.gw),
            1,
          );
          return item;
        });
        onValueChange(updatedItems);
        return updatedItems;
      });
    },
    [onValueChange],
  );

  const addRow = useCallback(() => {
    setIndex(prev => prev + 1);
    const newIndex = index + 1;
    setItems(prevItems => [
      ...prevItems,
      {
        id: newIndex,
        width: 0,
        length: 0,
        height: 0,
        weight: 0,
        numberOfUnit: 0,
        gw: 0,
        volume: 0,
        volumeWeight: 0,
        cw: 0,
      },
    ]);
    return newIndex;
  }, [index]);

  const removeRow = useCallback((itemId: number) => {
    setItems(prev => {
      return prev.filter(item => item.id !== itemId);
    });
  }, []);

  useEffect(() => {
    // 金額計算のため貨物のcw合計値,gw合計値,個数合計を計算
    const totalVolumWeight = items.reduce((sum, currentItem) => {
      return sum + (currentItem.volumeWeight || 0);
    }, 0);
    const totalGw = items.reduce((sum, currentItem) => {
      return sum + (currentItem.gw || 0);
    }, 0);
    const totalCw = roundUpToNearestHalf(Math.max(totalGw, totalVolumWeight));

    setTotalCw(totalCw);
  }, [items]);

  return (
    <>
      <StyledTable>
        <thead>
          <tr>
            <th>{t('length')}</th>
            <th>{t('width')}</th>
            <th>{t('height')}</th>
            <th>{t('weight')}</th>
            <th>{t('numberOfPackage')}</th>
            <th>{t('grossWeight')}</th>
            <th>{t('grossVolume')}</th>
            <th>{t('volumeWeight')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items.map((item: Item) => (
            <tr key={item.id}>
              <td>
                {/* HACK: コンポーネント化 */}
                <TextField
                  name="width"
                  onChange={event => handleInputChange(event, 'width', item.id)}
                  value={item.width?.toString()}
                  type="number"
                  InputProps={{
                    inputProps: {min: 0},
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </td>
              <td>
                <TextField
                  name="length"
                  onChange={event =>
                    handleInputChange(event, 'length', item.id)
                  }
                  value={item.length?.toString()}
                  type="number"
                  InputProps={{
                    inputProps: {min: 0},
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </td>
              <td>
                <TextField
                  name="height"
                  onChange={event =>
                    handleInputChange(event, 'height', item.id)
                  }
                  value={item.height?.toString()}
                  type="number"
                  InputProps={{
                    inputProps: {min: 0},
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </td>
              <td>
                <TextField
                  name="weight"
                  onChange={event =>
                    handleInputChange(event, 'weight', item.id)
                  }
                  value={item.weight?.toString()}
                  type="number"
                  InputProps={{
                    inputProps: {min: 0},
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </td>
              <td>
                <TextField
                  name="numberOfUnit"
                  onChange={event =>
                    handleInputChange(event, 'numberOfUnit', item.id)
                  }
                  value={item.numberOfUnit?.toString()}
                  type="number"
                  InputProps={{
                    inputProps: {min: 0},
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </td>
              <td>
                <TextField
                  name="gw"
                  value={item.gw?.toString()}
                  type="number"
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </td>
              <td>
                <TextField
                  name="volume"
                  value={item.volume?.toString()}
                  type="number"
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </td>
              <td>
                <TextField
                  name="volumeWeight"
                  value={item.volumeWeight?.toString()}
                  type="number"
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </td>
              <td>
                {items.length === 1 ? null : (
                  <IconButton
                    aria-label="delete"
                    size="large"
                    onClick={() => {
                      removeRow(item.id);
                    }}>
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
      <Stack flexDirection="row" justifyContent="space-between">
        <StyledButton onClick={addRow}>{t('addItem')}</StyledButton>
        <Stack flexDirection="row" alignItems="center">
          <StyledTable>
            <th style={{paddingRight: '40px'}}>{t('chargeableWeight')}</th>
            <td style={{paddingRight: '40px'}}>{totalCw}</td>
          </StyledTable>
        </Stack>
      </Stack>
    </>
  );
};

const StyledTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  tr {
    padding-top: 8px;
    border-bottom: 1px solid #d9d9d9;
  }
  th {
    padding: 4px 10px;
    font-size: 13px;
    text-align: left;
    font-weight: normal;
    border-bottom: 1px solid #d9d9d9;
  }
  td {
    width: 10%;
    padding: 8px;
    border-bottom: 1px solid #d9d9d9;
    &:last-child {
      width: 5%;
    }
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background-color: #e9eaee;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
    border-radius: 50%;
    transition: 0.2s;
    &:hover {
      opacity: 0.6;
    }
  }
`;

const StyledButton = styled.button`
  width: 100px;
  padding: 4px;
  color: #1976d2;
  background: none;
  border: none;
  cursor: pointer;
  &::before {
    content: '+';
    display: inline-block;
    margin-right: 5px;
    color: #1976d2;
    font-size: 18px;
  }
  &:hover {
    opacity: 0.7;
  }
`;
