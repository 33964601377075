import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery} from 'react-query';
import {
  CreateMarketConditionsWithoutSafMutation,
  CreateMarketConditionsWithoutSafMutationVariables,
  DeleteMarketConditionsWithoutSafMutation,
  DeleteMarketConditionsWithoutSafMutationVariables,
  ListMarketConditionsWithoutSafsQuery,
  ListMarketConditionsWithoutSafsQueryVariables,
  ModelMarketConditionsWithSafFilterInput,
  UpdateMarketConditionsWithoutSafMutation,
  UpdateMarketConditionsWithoutSafMutationVariables,
} from '../API';
import {
  createMarketConditionsWithoutSaf,
  deleteMarketConditionsWithoutSaf,
  updateMarketConditionsWithoutSaf,
} from '../graphql/mutations';
import {listMarketConditionsWithoutSafs} from '../graphql/queries';

export const useMarketConditionsWithoutSafs = (arrival?: string) => {
  return useQuery(['marketConditionsWithoutSaf', arrival], async () => {
    const filter: ModelMarketConditionsWithSafFilterInput = {};
    if (arrival) {
      filter.arrival = {eq: arrival};
    }
    const variables: ListMarketConditionsWithoutSafsQueryVariables = {
      limit: 10000,
      filter,
    };
    const result = (await API.graphql(
      graphqlOperation(listMarketConditionsWithoutSafs, variables),
    )) as GraphQLResult<ListMarketConditionsWithoutSafsQuery>;
    return result?.data?.listMarketConditionsWithoutSafs;
  });
};

export const useCreateMarketConditionsWithoutSafMutation = () => {
  return useMutation(
    async (variables: CreateMarketConditionsWithoutSafMutationVariables) => {
      const user = (await API.graphql(
        graphqlOperation(createMarketConditionsWithoutSaf, variables),
      )) as GraphQLResult<CreateMarketConditionsWithoutSafMutation>;
      return user.data?.createMarketConditionsWithoutSaf;
    },
  );
};

export const useUpdateMarketConditionsWithoutSafMutation = () => {
  return useMutation(
    async (variables: UpdateMarketConditionsWithoutSafMutationVariables) => {
      const user = (await API.graphql(
        graphqlOperation(updateMarketConditionsWithoutSaf, variables),
      )) as GraphQLResult<UpdateMarketConditionsWithoutSafMutation>;
      return user.data?.updateMarketConditionsWithoutSaf;
    },
  );
};

export const useDeleteMarketConditionsWithoutSafMutation = () => {
  return useMutation(
    async (variables: DeleteMarketConditionsWithoutSafMutationVariables) => {
      const user = (await API.graphql(
        graphqlOperation(deleteMarketConditionsWithoutSaf, variables),
      )) as GraphQLResult<DeleteMarketConditionsWithoutSafMutation>;
      return user.data?.deleteMarketConditionsWithoutSaf;
    },
  );
};
