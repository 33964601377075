import {Stack, Typography, Grid} from '@mui/material';
import {useState, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Reservation, Status} from '../../API';
import {ReservationDetail} from '../../components/ReservationDetail';
import {ReservationFilter} from '../../components/ReservationFilter';
import {ReservationFilterType} from '../../components/ReservationFilter';
import {ReservationSummary} from '../../components/ReservationSummary';
import {useOAuthUserState} from '../../providers/AuthProvider';
import {
  useReservations,
  useDeleteReservationMutation,
} from '../../queries/reservation';
import {useShipperUser} from '../../queries/shipperUser';
import {isNonNull} from '../../utils/isEmpty';

export const ReservationListScreen = () => {
  const {t} = useTranslation();
  const userState = useOAuthUserState();
  const sub = userState.user?.attributes.sub ?? '';
  const {data: shipper} = useShipperUser(sub);

  const [departure, setDeparture] = useState('');
  const [status, setStatus] = useState<Status | undefined>(undefined);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [selectedReservationId, setSelectedReservationId] = useState('');
  const [sortedReservations, setSortedReservations] = useState<
    Reservation[] | null
  >(null);

  const {data: reservations} = useReservations(
    shipper?.companyId ?? '',
    departure,
    status,
  );

  const {mutateAsync: deleteReservationMutation} =
    useDeleteReservationMutation();

  const filterReservation = useCallback((filter: ReservationFilterType) => {
    setDeparture(filter.departure);
    setStatus(filter.status);
  }, []);
  const openDetail = useCallback((id: string, open: boolean) => {
    setIsDetailOpen(open);
    setSelectedReservationId(id);
  }, []);
  const cancelReservation = useCallback(
    (id: string) => {
      deleteReservationMutation({
        input: {
          id,
        },
      });
    },
    [deleteReservationMutation],
  );

  useEffect(() => {
    if (reservations?.items) {
      const sortedItems = [...reservations.items].sort((a, b) => {
        return (b?.shippingInstruction?.flightDate ?? '').localeCompare(
          a?.shippingInstruction?.flightDate ?? '',
        );
      });
      setSortedReservations(sortedItems as Reservation[]);
    } else {
      setSortedReservations(null);
    }
  }, [reservations]);

  return sortedReservations ? (
    <>
      <Grid container>
        <Grid item xs={4}>
          <ReservationFilter
            filter={{departure, status}}
            filterReservation={filterReservation}
          />
        </Grid>
        <Grid item xs={8}>
          <Stack ml={7} spacing={3}>
            {sortedReservations?.filter(isNonNull).map(reservation => {
              return (
                <ReservationSummary
                  id={reservation.id}
                  setIsDetailOpen={openDetail}
                  cancelReservation={cancelReservation}
                />
              );
            })}
            {sortedReservations?.length === 0 && (
              <Typography variant="h3">{t('noFilteredReservation')}</Typography>
            )}
          </Stack>
        </Grid>
      </Grid>
      <ReservationDetail
        isDetailOpen={isDetailOpen}
        reservationId={selectedReservationId}
        setIsDetailOpen={openDetail}
        cancelReservation={cancelReservation}
      />
    </>
  ) : null;
};
