import {Button, Container, Grid, Stack, Typography} from '@mui/material';
import {Auth} from 'aws-amplify';
import {useEffect, useMemo, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {LabelTitle} from '../../components/LabelTitle';
import {TextField} from '../../components/TextField';
import Inner from '../../layouts/Inner';

interface FormInput {
  email: string;
}

export const ForgotPasswordScreen = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: {errors, isDirty},
  } = useForm<FormInput>({mode: 'onBlur'});
  const [sendError, setSendError] = useState(false);
  const onSubmit: SubmitHandler<FormInput> = data => {
    Auth.forgotPassword(data.email)
      .then(_data => {
        navigate('/resetPassword', {state: {email: data.email}});
      })
      .catch(() => {
        setSendError(true);
      });
  };
  const disabled = useMemo(
    () => !isDirty || !!errors.email?.type,
    [errors.email?.type, isDirty],
  );

  useEffect(() => {
    document.title = `${t('personWhoForgotPassword')} | MAST`;
  }, [t]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      sx={{minHeight: '100vh'}}>
      <Container maxWidth="md">
        <Typography mb={3} variant="h1">
          MAST
        </Typography>
        <Inner>
          <Stack spacing={4}>
            <Typography textAlign="center" variant="h3">
              {t('personWhoForgotPassword')}
            </Typography>
            <Typography textAlign="center">
              {t('enterEmail')}
              <br />
              {t('emailWillBeSent')}
            </Typography>
            {sendError ? (
              <Typography variant="error" textAlign="center">
                {t('noMatching')}
                <br />
                {t('doubleCheckEmail')}
              </Typography>
            ) : null}
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={2}>
                <LabelTitle>{t('email')}</LabelTitle>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  placeholder={t('email')}
                  sx={{width: '100%'}}
                  error={!!errors.email}
                  {...register('email', {required: true})}
                  helperText={errors.email ? t('enterEmail') : ''}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="center">
              <Button
                variant="primary"
                type="submit"
                disabled={disabled}
                onClick={handleSubmit(onSubmit)}
                size="large">
                {t('send')}
              </Button>
            </Grid>
          </Stack>
        </Inner>
      </Container>
    </Grid>
  );
};
