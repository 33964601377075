import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanguageIcon from '@mui/icons-material/Language';
import {
  AppBar,
  Toolbar,
  Avatar,
  Box,
  MenuItem,
  Typography,
  Menu,
  Button,
  Link,
} from '@mui/material';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import i18n from '../i18n/i18n';

export const AuthHeader = () => {
  const {t} = useTranslation();
  const [language, setLanguage] = useState('ja');
  const [anchorElLanguageMenu, setAnchorElLanguageMenu] =
    useState<null | HTMLElement>(null);
  const [anchorElAccountMenu, setAnchorElAccountMenu] =
    useState<null | HTMLElement>(null);

  const handleOpenLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLanguageMenu(event.currentTarget);
  };
  const handleOpenAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAccountMenu(event.currentTarget);
  };
  const handleCloseLanguageMenu = () => setAnchorElLanguageMenu(null);
  const handleCloseAccountMenu = () => setAnchorElAccountMenu(null);

  return (
    <AppBar
      sx={{
        background: 'transparent',
        position: 'static',
        boxShadow: 'unset',
        height: 72,
        paddingRight: '0px !important',
      }}>
      <Toolbar sx={{padding: '0 12px 0 36px !important'}}>
        <Box sx={{margin: '0 0 0 auto'}}>
          <Button
            onClick={handleOpenLanguageMenu}
            sx={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              textTransform: 'unset',
            }}>
            <LanguageIcon sx={{color: '#333333'}} />
            <Typography
              component="span"
              sx={{
                textAlign: 'left',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                color: '#333333',
                fontWeight: 600,
                marginLeft: '8px',
              }}>
              {`${language}`}
            </Typography>
            <ExpandMoreIcon sx={{color: '#333333'}} />
          </Button>
          <Menu
            open={Boolean(anchorElLanguageMenu)}
            onClose={handleCloseLanguageMenu}
            anchorEl={anchorElLanguageMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 25,
            }}>
            <MenuItem
              onClick={() => {
                handleCloseLanguageMenu();
                i18n.changeLanguage('ja');
                setLanguage('ja');
              }}>
              <Typography>ja</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseLanguageMenu();
                i18n.changeLanguage('en');
                setLanguage('en');
              }}>
              <Typography>en</Typography>
            </MenuItem>
          </Menu>
        </Box>
        <Box sx={{margin: '0 0 0 7px'}}>
          <Button
            onClick={handleOpenAccountMenu}
            sx={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              textTransform: 'unset',
              paddingX: '18px',
            }}>
            <Avatar
              sx={{
                width: '36px',
                height: '36px',
                marginRight: '8px',
                backgroundColor: '#494747',
              }}
            />
          </Button>
          <Menu
            open={Boolean(anchorElAccountMenu)}
            onClose={handleCloseAccountMenu}
            anchorEl={anchorElAccountMenu}
            PaperProps={{style: {width: 'fit-content'}}}>
            <Link
              href="https://cozmic.notion.site/3f158b6aed084d5880574720c4f5829b?pvs=4"
              underline="none"
              target="_blank">
              <MenuItem>
                <Typography>{t('termsOfService')}</Typography>
              </MenuItem>
            </Link>
            <Link
              href="https://cozmic.notion.site/de2c8de607bf471285cdf0f1d46627be"
              underline="none"
              target="_blank">
              <MenuItem>
                <Typography>{t('privacyPolicy')}</Typography>
              </MenuItem>
            </Link>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
