import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery} from 'react-query';
import {
  CreateCustomFeeMutation,
  CreateCustomFeeMutationVariables,
  DeleteCustomFeeMutation,
  DeleteCustomFeeMutationVariables,
  ListCustomFeesQuery,
  ListCustomFeesQueryVariables,
  UpdateCustomFeeMutation,
  UpdateCustomFeeMutationVariables,
  GetCustomFeeQueryVariables,
  GetCustomFeeQuery,
} from '../API';
import {
  createCustomFee,
  deleteCustomFee,
  updateCustomFee,
} from '../graphql/mutations';
import {listCustomFees, getCustomFee} from '../graphql/queries';

export const useCustomFees = () => {
  return useQuery(['customFees'], async () => {
    const variables: ListCustomFeesQueryVariables = {
      limit: 10000,
    };
    const result = (await API.graphql(
      graphqlOperation(listCustomFees, variables),
    )) as GraphQLResult<ListCustomFeesQuery>;
    return result?.data?.listCustomFees;
  });
};

export const useCustomFee = (id: string) => {
  return useQuery(
    ['customFee', id],
    async () => {
      const variable: GetCustomFeeQueryVariables = {
        id,
      };
      const result = (await API.graphql(
        graphqlOperation(getCustomFee, variable),
      )) as GraphQLResult<GetCustomFeeQuery>;
      return result.data?.getCustomFee;
    },
    {
      enabled: !!id,
    },
  );
};

export const useCreateCustomFeeMutation = () => {
  return useMutation(async (variables: CreateCustomFeeMutationVariables) => {
    const customFee = (await API.graphql(
      graphqlOperation(createCustomFee, variables),
    )) as GraphQLResult<CreateCustomFeeMutation>;
    return customFee.data?.createCustomFee;
  });
};

export const useUpdateCustomFeeMutation = () => {
  return useMutation(async (variables: UpdateCustomFeeMutationVariables) => {
    const customFee = (await API.graphql(
      graphqlOperation(updateCustomFee, variables),
    )) as GraphQLResult<UpdateCustomFeeMutation>;
    return customFee.data?.updateCustomFee;
  });
};

export const useDeleteCustomFeeMutation = () => {
  return useMutation(async (variables: DeleteCustomFeeMutationVariables) => {
    const customFee = (await API.graphql(
      graphqlOperation(deleteCustomFee, variables),
    )) as GraphQLResult<DeleteCustomFeeMutation>;
    return customFee.data?.deleteCustomFee;
  });
};
