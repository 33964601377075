import {calculateItemFee} from './calculateItemFee';
import {calculateTaxFee} from './calculateTax';
import {roundToDecimal} from './roundToDecimal';
import {CustomFee} from '../API';

// カスタム項目全ての料金を計算
export const calculateCustomFeeTax = (
  customItems: CustomFee[],
  totalCw: number,
  totalGw: number,
  unit: number,
) => {
  return customItems.reduce((total, item) => {
    const itemFee = roundToDecimal(
      calculateTaxFee(
        calculateItemFee(
          item.unitPrice,
          totalCw,
          totalGw,
          unit,
          item.calculationMethod,
          item.minimum,
          item.maximum,
        ) * (item.currency?.exchangeRateJPY ?? 0),
        true,
        item.includeTax,
        item.taxRate,
      ),
      0,
    );
    return total + itemFee;
  }, 0);
};
