import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery} from 'react-query';
import {
  ListShippingCustomFeesQueryVariables,
  ListShippingCustomFeesQuery,
  GetShippingCustomFeeQueryVariables,
  GetShippingCustomFeeQuery,
  CreateShippingCustomFeeMutationVariables,
  CreateShippingCustomFeeMutation,
  UpdateShippingCustomFeeMutationVariables,
  UpdateShippingCustomFeeMutation,
  DeleteShippingCustomFeeMutationVariables,
  DeleteShippingCustomFeeMutation,
} from '../API';
import {
  createShippingCustomFee,
  deleteShippingCustomFee,
  updateShippingCustomFee,
} from '../graphql/mutations';
import {listShippingCustomFees, getShippingCustomFee} from '../graphql/queries';

export const useShippingCustomFees = () => {
  return useQuery(['ShippingCustomFees'], async () => {
    const variables: ListShippingCustomFeesQueryVariables = {
      limit: 10000,
    };
    const result = (await API.graphql(
      graphqlOperation(listShippingCustomFees, variables),
    )) as GraphQLResult<ListShippingCustomFeesQuery>;
    return result?.data?.listShippingCustomFees;
  });
};

export const useShippingCustomFee = (id: string) => {
  return useQuery(
    ['ShippingCustomFee', id],
    async () => {
      const variable: GetShippingCustomFeeQueryVariables = {
        id,
      };
      const result = (await API.graphql(
        graphqlOperation(getShippingCustomFee, variable),
      )) as GraphQLResult<GetShippingCustomFeeQuery>;
      return result.data?.getShippingCustomFee;
    },
    {
      enabled: !!id,
    },
  );
};

export const useCreateShippingCustomFeeMutation = () => {
  return useMutation(
    async (variables: CreateShippingCustomFeeMutationVariables) => {
      const shippingCustomFee = (await API.graphql(
        graphqlOperation(createShippingCustomFee, variables),
      )) as GraphQLResult<CreateShippingCustomFeeMutation>;
      return shippingCustomFee.data?.createShippingCustomFee;
    },
  );
};

export const useUpdateShippingCustomFeeMutation = () => {
  return useMutation(
    async (variables: UpdateShippingCustomFeeMutationVariables) => {
      const shippingCustomFee = (await API.graphql(
        graphqlOperation(updateShippingCustomFee, variables),
      )) as GraphQLResult<UpdateShippingCustomFeeMutation>;
      return shippingCustomFee.data?.updateShippingCustomFee;
    },
  );
};

export const useDeleteShippingCustomFeeMutation = () => {
  return useMutation(
    async (variables: DeleteShippingCustomFeeMutationVariables) => {
      const shippingCustomFee = (await API.graphql(
        graphqlOperation(deleteShippingCustomFee, variables),
      )) as GraphQLResult<DeleteShippingCustomFeeMutation>;
      return shippingCustomFee.data?.deleteShippingCustomFee;
    },
  );
};
