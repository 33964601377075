import AddIcon from '@mui/icons-material/Add';
import 'react-phone-input-2/lib/style.css';
import {
  Stack,
  Typography,
  Box,
  Dialog,
  Autocomplete,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Snackbar,
  Alert,
  AlertTitle,
  Grid,
} from '@mui/material';
import React, {useState, useEffect, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {FobFhdFilter} from '../../components/FobFhdFilter';
import {Title} from '../../components/Title';
import {airports} from '../../data/airports';
import {PlaneIcon} from '../../images/PlaneIcon';
import {useOAuthUserState} from '../../providers/AuthProvider';
import {useFhds} from '../../queries/fhd';
import {useFobs} from '../../queries/fob';
import {useForwarderUser} from '../../queries/forwarderUser';
import {isNonNull} from '../../utils/isEmpty';

export const FobFhdListScreen = () => {
  const {t} = useTranslation();
  const userState = useOAuthUserState();
  const sub = userState.user?.attributes.sub ?? '';
  const {data: forwarder} = useForwarderUser(sub);
  const navigate = useNavigate();
  const [filteredAirport, setFilteredAirport] = useState('');
  const {data: fobs} = useFobs(forwarder?.companyId ?? '', filteredAirport);
  const {data: fhds} = useFhds(forwarder?.companyId ?? '', filteredAirport);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<{
    title: string;
    message: string;
  }>({title: 'tea', message: 'ta'});

  const [isOpen, setIsOpen] = useState(false);
  const [airport, setAirport] = useState<{
    id: number;
    name: string;
    code: string;
  } | null>(null);
  const [feeType, setFeeType] = useState('fob');

  const handleFeeType = (
    _: React.MouseEvent<HTMLElement>,
    newValue: string,
  ) => {
    if (newValue !== null) {
      setFeeType(newValue);
    }
  };

  const filterFobFhd = useCallback((filteredAirport: string) => {
    setFilteredAirport(filteredAirport);
  }, []);

  const onSubmit = () => {
    if (airport && feeType === 'fob') {
      if (fobs?.map(fob => fob?.airport).includes(airport.code)) {
        setAlertMessage({
          title: t('exportChargeDuplicate'),
          message: `${airport.name}${t('cannotRegisterExportCharge')}`,
        });
        setAlertOpen(true);
        return;
      }
      navigate('/createFob', {state: {airport}});
    } else {
      if (airport && fhds?.map(fhd => fhd?.airport).includes(airport.code)) {
        setAlertMessage({
          title: t('importChargeDuplicate'),
          message: `${airport.name}${t('cannotRegisterImportCharge')}`,
        });
        setAlertOpen(true);
        return;
      }
      navigate('/createFhd', {state: {airport}});
    }
  };

  const handleCardClick = (type: 'fob' | 'fhd', id: string) => {
    if (type === 'fob') {
      navigate('/updateFob', {state: {id}});
    } else {
      navigate('/updateFhd', {state: {id}});
    }
  };

  useEffect(() => {
    document.title = `${t('fobFhdList')} | MAST`;
  }, [t]);

  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          <FobFhdFilter
            filteredAirport={filteredAirport}
            filterFobFhd={filterFobFhd}
          />
        </Grid>
        <Grid item xs={8}>
          <Stack alignItems="center" flexDirection="column">
            <Stack justifyContent="start" width="100%">
              <Stack flexDirection="row" alignItems="center" mb={3}>
                <Title title={t('exportAndImportCharge')} />
                <Button
                  variant="outlined"
                  type="submit"
                  style={{marginLeft: '32px'}}
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  startIcon={<AddIcon />}>
                  {t('newRegistration')}
                </Button>
              </Stack>
              <Stack flexDirection="row" flexWrap="wrap">
                {fobs?.filter(isNonNull).map(item => {
                  if (item && item.airport) {
                    return (
                      <Box
                        sx={{
                          width: '245px',
                          height: '179px',
                          borderRadius: '12px',
                          marginRight: '24px',
                          marginBottom: '24px',
                          backgroundColor: '#E9EAEE',
                        }}
                        key={item.id}
                        onClick={() => {
                          handleCardClick('fob', item.id);
                        }}>
                        <Stack flexDirection="column" alignItems="center">
                          <PlaneIcon
                            style={{
                              width: '40px',
                              height: '37px',
                              marginTop: '46px',
                              marginBottom: '51px',
                            }}
                          />
                          <Box
                            sx={{
                              width: '100%',
                              height: '45px',
                              borderBottomRightRadius: '12px',
                              borderBottomLeftRadius: '12px',
                              backgroundColor: '#333333B2',
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                            <Typography
                              mx="12px"
                              sx={{
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '21px',
                                color: '#FFFFFF',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                              }}>
                              {t('exportCharge') +
                                ` ${
                                  airports.find(
                                    airport => airport.code === item.airport,
                                  )?.name ?? ''
                                }`}
                            </Typography>
                          </Box>
                        </Stack>
                      </Box>
                    );
                  }
                  return <></>;
                })}
                {fhds?.filter(isNonNull).map(item => {
                  if (item && item.airport) {
                    return (
                      <Box
                        sx={{
                          width: '245px',
                          height: '179px',
                          borderRadius: '12px',
                          marginRight: '24px',
                          marginBottom: '24px',
                          backgroundColor: '#E9EAEE',
                        }}
                        key={item.id}
                        onClick={() => {
                          handleCardClick('fhd', item.id);
                        }}>
                        <Stack flexDirection="column" alignItems="center">
                          <PlaneIcon
                            style={{
                              width: '40px',
                              height: '37px',
                              marginTop: '46px',
                              marginBottom: '51px',
                            }}
                          />
                          <Box
                            sx={{
                              width: '100%',
                              height: '45px',
                              borderBottomRightRadius: '12px',
                              borderBottomLeftRadius: '12px',
                              backgroundColor: '#333333B2',
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                            <Typography
                              mx="12px"
                              sx={{
                                lineHeight: '21px',
                                color: '#FFFFFF',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                              }}>
                              {t('importCharge') +
                                ` ${
                                  airports.find(
                                    airport => airport.code === item.airport,
                                  )?.name ?? ''
                                }`}
                            </Typography>
                          </Box>
                        </Stack>
                      </Box>
                    );
                  }
                  return <></>;
                })}
              </Stack>
            </Stack>
          </Stack>
          <Dialog
            onClose={() => {
              setIsOpen(false);
            }}
            open={isOpen}>
            <Stack mx={5} my={5} flexDirection="column" alignItems="center">
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 500,
                  lineHeight: '26px',
                  textAlign: 'center',
                }}>
                {t('exportAndImportCharge')} {t('newAddition')}
              </Typography>
              <Stack flexDirection="column" mt={4}>
                <Stack flexDirection="row">
                  <Typography variant="h5">{t('airportName')}</Typography>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '18px',
                      color: '#DA0000',
                      marginLeft: '12px',
                    }}>
                    {t('requiredField')}
                  </Typography>
                </Stack>
                <Autocomplete
                  value={airport}
                  onChange={(_, newValue) => {
                    setAirport(newValue ?? null);
                  }}
                  sx={{width: '452px', marginTop: '8px'}}
                  options={airports}
                  getOptionLabel={airport => (airport ? airport.name : '')}
                  size="small"
                  renderInput={params => <TextField {...params} />}
                />
              </Stack>
              <ToggleButtonGroup
                value={feeType}
                exclusive
                onChange={handleFeeType}
                aria-label="text alignment"
                sx={{height: '36px', marginTop: '24px'}}>
                <ToggleButton
                  value="fob"
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: '#1976d2',
                      color: 'white',
                    },
                  }}>
                  {t('exportCharge')}
                </ToggleButton>
                <ToggleButton
                  value="fhd"
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: '#1976d2',
                      color: 'white',
                    },
                  }}>
                  {t('importCharge')}
                </ToggleButton>
              </ToggleButtonGroup>
              <Stack flexDirection="row" mt={5} alignItems={'center'}>
                <Button
                  variant="outlined"
                  sx={{width: '180px', height: '45px'}}
                  onClick={() => {
                    setAirport(null);
                    setIsOpen(false);
                  }}>
                  {t('cancel')}
                </Button>
                <Button
                  sx={{marginLeft: '12px', width: '180px', height: '45px'}}
                  variant="outlined"
                  type="submit"
                  disabled={airport === null}
                  onClick={onSubmit}>
                  {t('newAddition')}
                </Button>
              </Stack>
            </Stack>
          </Dialog>
          <Snackbar
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            style={{top: '72px'}}
            open={alertOpen}
            autoHideDuration={6000}
            onClose={() => {
              setAlertOpen(false);
            }}>
            <Alert
              severity="warning"
              sx={{width: '588px'}}
              onClose={() => {
                setAlertOpen(false);
              }}>
              <Stack spacing={2}>
                <AlertTitle>{alertMessage.title}</AlertTitle>
                {alertMessage.message}
              </Stack>
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
    </>
  );
};
