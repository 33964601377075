import {Button, Stack, Typography} from '@mui/material';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import Inner from '../../layouts/Inner';
import {useForwarderCompany} from '../../queries/forwarderCompany';

export const ReserveConfirmationScreen = () => {
  const {t} = useTranslation();

  const navigate = useNavigate();
  const onSubmit = useCallback(() => {
    navigate('/', {});
  }, [navigate]);

  const location = useLocation();
  const forwarderCompanyId = location.state.forwarderCompanyId;
  const {data: forwarderCompany} = useForwarderCompany(forwarderCompanyId);

  return (
    <Stack
      pb={10}
      sx={{
        width: '700px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}>
      <Typography variant="h2" mb={3}>
        {t('si')}
      </Typography>
      <Inner>
        <Typography>
          {t('appreciation')} <br></br>
          {t('waitForContact', {forwarderCompany: forwarderCompany?.name})}
        </Typography>
        <Stack flexDirection="column" gap="16px" mt={5}>
          <Typography variant="h4">{t('procedureFlow')}</Typography>
          <Typography>
            (1) {t('tentativeBookingStatus')} <br></br>
            (2) {t('officiallyCompletedAfterHawb')} <br></br>
            (3) {t('checkBookingStatus')} <br></br>
            (4) {t('assignGrade')}
          </Typography>
        </Stack>
        <Button
          variant="primary"
          type="submit"
          size="large"
          style={{marginTop: '24px'}}
          onClick={onSubmit}>
          {t('backToTop')}
        </Button>
      </Inner>
    </Stack>
  );
};
