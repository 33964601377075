import i18n from '../i18n/i18n';

export const getLocalizedRegulated = (
  regulated: string,
  regulatedDetail: string,
) => {
  if (regulated === 'true') {
    return i18n.t('subjectToExportTrade');
  } else if (regulated === 'false') {
    return i18n.t('nonApplicableToExportTrade');
  } else {
    return regulatedDetail;
  }
};
