import {calculateCustomFee} from './calculateCustomFee';
import {isNonNull} from './isEmpty';
import {roundToDecimal} from './roundToDecimal';
import {CustomFee} from '../API';

// 運賃, FSC, カスタム項目を合計し航空運賃を計算
export const calculateFee = (
  totalCw: number,
  totalGw: number,
  unit: number,
  over1000?: number,
  over500?: number,
  over300?: number,
  over100?: number,
  over45?: number,
  under45?: number,
  fsc?: number,
  minimum?: number,
  customItems?: Array<CustomFee | null> | null,
  exchangeRateJPY?: number,
  includeTax = false,
) => {
  const basePrice =
    totalCw >= 1000
      ? over1000 ?? 0
      : totalCw >= 500
      ? over500 ?? 0
      : totalCw >= 300
      ? over300 ?? 0
      : totalCw >= 100
      ? over100 ?? 0
      : totalCw >= 45
      ? over45 ?? 0
      : under45 ?? 0;
  const airfreightPrice = Math.max(basePrice * totalCw, minimum ?? 0);
  const airfreightPriceYen = roundToDecimal(
    airfreightPrice * (exchangeRateJPY ?? 0),
    0,
  );

  const fscPrice = (fsc ?? 0) * totalCw;
  const fscPriceYen = roundToDecimal(fscPrice * (exchangeRateJPY ?? 0), 0);

  const customFee = roundToDecimal(
    calculateCustomFee(
      customItems?.filter(isNonNull) ?? [],
      totalCw,
      totalGw,
      unit,
      includeTax,
    ) ?? 0,
    0,
  );
  return airfreightPriceYen + fscPriceYen + customFee;
};
