import {Stack, Typography, TextField} from '@mui/material';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {NumericFormat} from 'react-number-format';

interface LabelNumericFormatProps {
  title?: string;
  isRequired?: boolean;
  value?: number | null;
  unit?: string;
  onChange: (newValue: number) => void;
  error?: boolean;
  helperText?: string;
}

export const LabelNumericFormat: FC<LabelNumericFormatProps> = ({
  title,
  isRequired,
  value,
  unit,
  onChange,
  error,
  helperText,
  ...textFieldProps
}) => {
  const {t} = useTranslation();

  return (
    <Stack direction="column" gap="9px">
      <Stack direction="row" gap="12px">
        {title && (
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '21px',
              color: '#181C32',
            }}>
            {title}
          </Typography>
        )}
        {isRequired && (
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 300,
              lineHeight: '18px',
              color: '#DA0000',
            }}>
            {t('requiredField')}
          </Typography>
        )}
      </Stack>
      <Stack direction="row" gap="4px" alignItems="center">
        <NumericFormat
          value={value}
          onValueChange={values => {
            const newValue = parseFloat(values.value);
            onChange(newValue);
          }}
          inputProps={{style: {textAlign: 'right'}}}
          customInput={TextField}
          size="small"
          variant="outlined"
          InputLabelProps={{shrink: true}}
          sx={{textAlign: 'right'}}
          error={error}
          helperText={helperText}
          {...textFieldProps}
        />
        {unit && (
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 300,
              lineHeight: '21px',
              color: '#181C32',
            }}>
            {unit}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
