import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {
  ListReservationsQuery,
  ListReservationsQueryVariables,
  GetReservationQueryVariables,
  GetReservationQuery,
  CreateReservationMutationVariables,
  CreateReservationMutation,
  UpdateReservationMutationVariables,
  UpdateReservationMutation,
  DeleteReservationMutationVariables,
  DeleteReservationMutation,
  ModelReservationFilterInput,
  Status,
} from '../API';
import {
  createReservation,
  deleteReservation,
  updateReservation,
} from '../graphql/mutations';
import {getReservation, listReservations} from '../graphql/queries';

export const useReservations = (
  companyId: string,
  departure?: string,
  status?: Status,
) => {
  return useQuery(['reservation', companyId, departure, status], async () => {
    const filter: ModelReservationFilterInput = {
      or: [
        {forwarderCompanyId: {eq: companyId}},
        {shipperCompanyId: {eq: companyId}},
      ],
    };
    if (departure) {
      filter.departure = {
        eq: departure,
      };
    }
    if (status) {
      filter.status = {
        eq: status,
      };
    }
    const variables: ListReservationsQueryVariables = {
      limit: 10000,
      filter,
    };
    const result = (await API.graphql(
      graphqlOperation(listReservations, variables),
    )) as GraphQLResult<ListReservationsQuery>;
    return result?.data?.listReservations;
  });
};

export const useReservation = (id: string) => {
  return useQuery(
    ['reservation', id],
    async () => {
      const variable: GetReservationQueryVariables = {
        id,
      };
      const result = (await API.graphql(
        graphqlOperation(getReservation, variable),
      )) as GraphQLResult<GetReservationQuery>;
      return result.data?.getReservation;
    },
    {
      enabled: !!id,
    },
  );
};

export const useCreateReservationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (variables: CreateReservationMutationVariables) => {
      const reservation = (await API.graphql(
        graphqlOperation(createReservation, variables),
      )) as GraphQLResult<CreateReservationMutation>;
      return reservation.data?.createReservation;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
    },
  );
};

export const useUpdateReservationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (variables: UpdateReservationMutationVariables) => {
      const reservation = (await API.graphql(
        graphqlOperation(updateReservation, variables),
      )) as GraphQLResult<UpdateReservationMutation>;
      return reservation.data?.updateReservation;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
    },
  );
};

export const useDeleteReservationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (variables: DeleteReservationMutationVariables) => {
      const reservation = (await API.graphql(
        graphqlOperation(deleteReservation, variables),
      )) as GraphQLResult<DeleteReservationMutation>;
      return reservation.data?.deleteReservation;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
    },
  );
};
