import {Dialog, Typography, Stack, Box, Button} from '@mui/material';
import * as Sentry from '@sentry/react';
import {useState, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {LabelRating} from '../../components/LabelRating';
import {LabelTextField} from '../../components/LabelTextField';
import {useOAuthUserState} from '../../providers/AuthProvider';
import {
  useCreateShipperReviewMutation,
  useCreateForwarderReviewMutation,
} from '../../queries/review';

interface ReviewProps {
  isOpen: boolean;
  companyReceivedReviewsId: string;
  setIsOpen: (open: boolean) => void;
}

export const Review: React.FC<ReviewProps> = ({
  isOpen,
  companyReceivedReviewsId,
  setIsOpen,
}) => {
  const navigate = useNavigate();
  const userState = useOAuthUserState();
  const {t} = useTranslation();
  const [rate, setRate] = useState(0);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const {mutateAsync: createShipperReviewMutation} =
    useCreateShipperReviewMutation();
  const {mutateAsync: createForwarderReviewMutation} =
    useCreateForwarderReviewMutation();

  const createReview = useCallback(() => {
    if (userState.user?.isForwarders) {
      createForwarderReviewMutation(
        {
          input: {
            title,
            description,
            rate,
            forwarderUserPostedReviewsId: userState.user?.attributes.sub,
            shipperCompanyReceivedReviewsId: companyReceivedReviewsId,
          },
        },
        {
          onError: err => {
            Sentry.captureException(err);
            return navigate('error');
          },
        },
      );
    } else {
      createShipperReviewMutation(
        {
          input: {
            title,
            description,
            rate,
            shipperUserPostedReviewsId: userState.user?.attributes.sub,
            forwarderCompanyReceivedReviewsId: companyReceivedReviewsId,
          },
        },
        {
          onError: err => {
            Sentry.captureException(err);
            return navigate('error');
          },
        },
      );
    }
    setRate(0);
    setTitle('');
    setDescription('');
    setIsOpen(false);
  }, [
    companyReceivedReviewsId,
    createForwarderReviewMutation,
    createShipperReviewMutation,
    description,
    navigate,
    rate,
    setIsOpen,
    title,
    userState.user?.attributes.sub,
    userState.user?.isForwarders,
  ]);

  useEffect(() => {
    document.title = `${t('review')} | MAST`;
  }, [t]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setRate(0);
        setTitle('');
        setDescription('');
        setIsOpen(false);
      }}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '12px',
          width: '544px',
        },
      }}>
      <Stack
        mx={5}
        my={5}
        spacing={3}
        justifyContent="center"
        alignItems="center">
        <Typography variant="h3">{t('postReview')}</Typography>
        <LabelRating
          value={rate}
          title={t('review')}
          isRequired={true}
          sx={{width: '452px'}}
          onChange={(_, newValue) => {
            if (!newValue) return;
            setRate(newValue);
          }}
        />
        <LabelTextField
          value={title}
          title={t('title')}
          isRequired={true}
          sx={{width: '452px'}}
          onChange={newValue => {
            if (!newValue) return;
            setTitle(newValue.target.value);
          }}
        />
        <LabelTextField
          title={t('content')}
          multiline={true}
          minRows={3}
          sx={{width: '452px'}}
          onChange={newValue => {
            if (!newValue) return;
            setDescription(newValue.target.value);
          }}
        />
        <Box display="flex" flexDirection="row">
          <Button
            variant="outlined"
            style={{width: '180px', height: '45px'}}
            onClick={() => {
              setRate(0);
              setTitle('');
              setDescription('');
              setIsOpen(false);
            }}>
            {t('cancel')}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={createReview}
            disabled={title === ''}
            style={{width: '180px', marginLeft: '24px'}}>
            {t('register')}
          </Button>
        </Box>
      </Stack>
    </Dialog>
  );
};
