import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery} from 'react-query';
import {
  CreateCurrencyMutation,
  CreateCurrencyMutationVariables,
  DeleteCurrencyMutation,
  DeleteCurrencyMutationVariables,
  ListCurrenciesQuery,
  ListCurrenciesQueryVariables,
  UpdateCurrencyMutation,
  UpdateCurrencyMutationVariables,
} from '../API';
import {
  createCurrency,
  deleteCurrency,
  updateCurrency,
} from '../graphql/mutations';
import {listCurrencies} from '../graphql/queries';

export const useCurrencies = () => {
  return useQuery(['currencies'], async () => {
    const variables: ListCurrenciesQueryVariables = {
      limit: 10000,
    };
    const result = (await API.graphql(
      graphqlOperation(listCurrencies, variables),
    )) as GraphQLResult<ListCurrenciesQuery>;
    return result?.data?.listCurrencies;
  });
};

export const useCreateCurrencyMutation = () => {
  return useMutation(async (variables: CreateCurrencyMutationVariables) => {
    const user = (await API.graphql(
      graphqlOperation(createCurrency, variables),
    )) as GraphQLResult<CreateCurrencyMutation>;
    return user.data?.createCurrency;
  });
};

export const useUpdateCurrencyMutation = () => {
  return useMutation(async (variables: UpdateCurrencyMutationVariables) => {
    const user = (await API.graphql(
      graphqlOperation(updateCurrency, variables),
    )) as GraphQLResult<UpdateCurrencyMutation>;
    return user.data?.updateCurrency;
  });
};

export const useDeleteCurrencyMutation = () => {
  return useMutation(async (variables: DeleteCurrencyMutationVariables) => {
    const user = (await API.graphql(
      graphqlOperation(deleteCurrency, variables),
    )) as GraphQLResult<DeleteCurrencyMutation>;
    return user.data?.deleteCurrency;
  });
};
