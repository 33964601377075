import {Typography} from '@mui/material';
import {Merge, FieldError, FieldErrorsImpl} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {LabelTextField} from './LabelTextField';
import {ContactInput} from '../API';

type ConsigneeContactProps = {
  consigneeContact: ContactInput | null;
  handleContactChange: (newContact: Partial<ContactInput>) => void;
  errors?:
    | Merge<
        FieldError,
        FieldErrorsImpl<{
          name: string;
          email: string;
          companyName: string;
          phoneNumber: string;
        }>
      >
    | undefined;
};

export const ConsigneeContact: React.FC<ConsigneeContactProps> = ({
  consigneeContact,
  handleContactChange,
  errors,
}) => {
  const {t} = useTranslation();
  return (
    <>
      <Typography variant="h3">{t('consignee')}</Typography>
      <LabelTextField
        title={t('companyName')}
        value={consigneeContact?.companyName}
        isRequired={true}
        error={!!errors?.companyName}
        onChange={event => {
          handleContactChange({
            ...consigneeContact,
            companyName: event.target.value,
          });
        }}
      />
      {errors?.companyName && (
        <Typography variant="error">{t('requiredFieldVaridation')}</Typography>
      )}
      <LabelTextField
        title={t('personInCharge')}
        value={consigneeContact?.name}
        isRequired={true}
        error={!!errors?.name}
        onChange={event => {
          handleContactChange({
            ...consigneeContact,
            name: event.target.value,
          });
        }}
      />
      {errors?.name && (
        <Typography variant="error">{t('requiredFieldVaridation')}</Typography>
      )}
      <LabelTextField
        title={t('email')}
        value={consigneeContact?.email}
        isRequired={true}
        error={!!errors?.email}
        onChange={event => {
          handleContactChange({
            ...consigneeContact,
            email: event.target.value,
          });
        }}
      />
      {errors?.email && (
        <Typography variant="error">{t('enterEmailCorrectly')}</Typography>
      )}
      <LabelTextField
        title={t('phoneNumber')}
        value={consigneeContact?.phoneNumber}
        isRequired={true}
        error={!!errors?.phoneNumber}
        onChange={event => {
          handleContactChange({
            ...consigneeContact,
            phoneNumber: event.target.value,
          });
        }}
      />
      {errors?.phoneNumber && (
        <Typography variant="error">
          {t('enterPhoneNumberCorrectly')}
        </Typography>
      )}
    </>
  );
};
