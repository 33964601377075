import {
  Stack,
  Grid,
  Box,
  Typography,
  TextField,
  Autocomplete,
  Button,
} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {Dayjs} from 'dayjs';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {NewsList} from './NewsList';
import {CalculationMethod, CargoType} from '../../API';
import {Airports, InputAirport} from '../../components/InputAirport';
import {LabelSelect} from '../../components/LabelSelect';
import {MarketConditionsChart} from '../../components/MarketConditionsChart';
import {airlines} from '../../data/airlines';
import Inner from '../../layouts/Inner';
import {useCurrencies} from '../../queries/currency';
import {getLocalizedLeadTime} from '../../utils/getLocalizedLeadTime';
import {toAWSDate} from '../../utils/toAWSDate';

export const ForwarderHomeScreen = () => {
  const {t} = useTranslation();
  const {data: currencies} = useCurrencies();
  const [departure, setDeparture] = useState('');
  const [arrival, setArrival] = useState('');
  const [airline, setAirline] = useState('');
  const [flightDate, setFlightDate] = useState('');
  const [directFlight, setDirectFlight] = useState(true);
  const [leadTime, setLeadTime] = useState(0);

  const navigate = useNavigate();
  const leadTimeOptions = [0, 1, 2, 3, 4, 5, 6, 7];

  const handleAirportChange = useCallback((newValue: Airports) => {
    setDeparture(newValue?.departure ?? '');
    setArrival(newValue?.arrival ?? '');
  }, []);

  useEffect(() => {
    document.title = `MAST`;
  }, []);

  const onSubmit = () => {
    navigate('/createEstimate', {
      state: {
        estimate: {
          id: '',
          departure,
          arrival,
          airline,
          flightDate,
          directFlight,
          leadTime,
          useSaf: false,
          cargoType: CargoType.NORMAL,
          yardCutDate: '',
          carrierShedCutDate: '',
          estimateCurrencyId: currencies?.items[0]?.id,
          minimum: 0,
          under45: 0,
          over45: 0,
          over100: 0,
          over300: 0,
          over500: 0,
          over1000: 0,
          fsc: 0,
          remark: null,
          customItems: [
            {
              id: '',
              title: '',
              unitPrice: 0,
              unitType: t('shipment'),
              calculationMethod: CalculationMethod.PER_SHIPMENT,
              customFeeCurrencyId: '',
              minimum: null,
              maximum: null,
              includeTax: true,
              taxRate: 0.0,
              remark: '',
            },
          ],
        },
      },
    });
  };

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Typography variant="h2" mb={2}>
            {t('quotationPage')}
          </Typography>
          <Inner sx={{padding: '20px'}}>
            <Stack direction="column" justifyContent="center" spacing={3}>
              <InputAirport onValueChange={handleAirportChange} />
              <Box
                px="20px"
                py={2}
                sx={{
                  backgroundColor: '#F0F0F3',
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: '10px',
                }}>
                <Grid container spacing="12px">
                  <Grid item xs={6}>
                    <Stack direction="column" width="100%">
                      <Typography variant="h5">{t('airline')}</Typography>
                      <Autocomplete
                        value={airlines.find(v => v.code === airline)}
                        onChange={(_, newValue) => {
                          setAirline(newValue?.code ?? '');
                        }}
                        options={airlines}
                        getOptionLabel={airline =>
                          airline ? airline.name : ''
                        }
                        size="small"
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder={t('selectAirline')}
                            inputProps={{
                              ...params.inputProps,
                              sx: {
                                paddingY: '5px !important',
                                paddingLeft: '9px !important',
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="column" width="100%">
                      <Typography variant="h5">{t('etd')}</Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format="YYYY/M/D"
                          slotProps={{
                            textField: {
                              size: 'small',
                            },
                          }}
                          onChange={(date: Dayjs | null) => {
                            if (date) {
                              setFlightDate(toAWSDate(date.toDate()));
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Box
                px="20px"
                py={2}
                sx={{
                  backgroundColor: '#F0F0F3',
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: '10px',
                }}>
                <Stack direction="column" justifyContent="center" width="100%">
                  <Grid container spacing="12px">
                    <Grid item xs={6}>
                      <LabelSelect
                        title={t('flightType')}
                        value={directFlight}
                        options={[
                          {value: true, label: t('directFlight')},
                          {value: false, label: t('connectingFlight')},
                        ]}
                        onChange={event =>
                          setDirectFlight(event.target.value as boolean)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LabelSelect
                        title={t('leadTime')}
                        value={leadTime}
                        options={leadTimeOptions.map(value => ({
                          value,
                          label: getLocalizedLeadTime(value),
                        }))}
                        onChange={event =>
                          setLeadTime(event.target.value as number)
                        }
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            </Stack>
            <Stack mt="20px" alignItems="center">
              <Button
                variant="primary"
                type="submit"
                size="small"
                onClick={onSubmit}>
                {t('creation')}
              </Button>
            </Stack>
          </Inner>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h2" mb={2}>
            {t('airfreightIndex')}
          </Typography>
          <Inner
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '20px',
              height: '370px',
            }}>
            <MarketConditionsChart />
          </Inner>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" mb={2}>
            {t('newsFlash')}
          </Typography>
          <NewsList />
        </Grid>
      </Grid>
    </>
  );
};
