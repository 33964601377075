import {Box, SxProps} from '@mui/material';
import {FC} from 'react';

interface InnerProps {
  sx?: SxProps;
  children?: React.ReactNode;
}
const Inner: FC<InnerProps> = ({sx = {}, children}) => {
  const defaultSx: SxProps = {
    background: '#fff',
    borderRadius: '12px',
    padding: '40px',
    boxShadow: '0px 2px 13px rgba(204, 204, 204, 0.3)',
  };
  return <Box sx={{...defaultSx, ...sx}}>{children}</Box>;
};

export default Inner;
