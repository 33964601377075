import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import {Box, Stack, Divider, TextField, Autocomplete} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {airports} from '../data/airports';

export type Airports = {
  departure?: string;
  arrival?: string;
} | null;

interface InputAirportProps {
  onValueChange: (newValue: Airports) => void;
  defaultDeparture?: string;
  defaultArrival?: string;
}

export const InputAirport: React.FC<InputAirportProps> = ({
  onValueChange,
  defaultDeparture,
  defaultArrival,
}) => {
  const {t} = useTranslation();
  const [, setFlightData] = useState<Airports>({
    departure: defaultDeparture || '',
    arrival: defaultArrival || '',
  });

  const handleDepartureAirportChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: {
      readonly id: number;
      readonly name: string;
      readonly code: string;
    } | null,
  ) => {
    const newDeparture = value?.code;
    setFlightData(prev => {
      const updatedFlightData = {
        ...prev,
        departure: newDeparture,
      };
      onValueChange(updatedFlightData);
      return updatedFlightData;
    });
  };

  const handleArrivalAirportChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: {
      readonly id: number;
      readonly name: string;
      readonly code: string;
    } | null,
  ) => {
    const newArrival = value?.code;
    setFlightData(prev => {
      const updatedFlightData = {
        ...prev,
        arrival: newArrival,
      };
      onValueChange(updatedFlightData);
      return updatedFlightData;
    });
  };

  function findAirportByCode(code?: string) {
    const airport = airports.find(airport => airport.code === code);
    return (
      airport || {
        id: 0,
        name: '',
        code: '',
      }
    );
  }

  const departureAirportInfo = findAirportByCode(defaultDeparture);
  const arrivalAirportInfo = findAirportByCode(defaultArrival);

  return (
    <Box
      sx={{
        backgroundColor: '#F0F0F3',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '10px',
      }}>
      <Stack
        direction="row"
        alignItems="center"
        height="100%"
        width="100%"
        spacing="20px"
        mx="20px">
        <Box
          sx={{
            width: '100%',
            paddingTop: '16px',
            paddingBottom: '8px',
          }}>
          <Autocomplete
            onChange={handleDepartureAirportChange}
            options={airports}
            defaultValue={{
              id: departureAirportInfo?.id,
              name: departureAirportInfo?.name,
              code: defaultDeparture !== undefined ? defaultDeparture : '',
            }}
            getOptionLabel={airport => (airport ? airport.name : '')}
            size="small"
            renderInput={params => (
              <TextField
                {...params}
                size="small"
                id="input-with-icon-textfield"
                label={t('originAirport')}
                placeholder={t('enterAirportName')}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <FlightTakeoffIcon
                      sx={{
                        marginRight: '4px',
                        paddingBottom: '5px',
                        fontSize: '19px',
                      }}
                    />
                  ),
                  disableUnderline: true,
                }}
                variant="standard"
                InputLabelProps={{
                  sx: {fontSize: 16, fontWeight: 500},
                }}
                fullWidth
              />
            )}
          />
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box
          sx={{
            width: '100%',
            paddingTop: '16px',
            paddingBottom: '8px',
          }}>
          <Autocomplete
            onChange={handleArrivalAirportChange}
            options={airports}
            defaultValue={{
              id: arrivalAirportInfo.id,
              name: arrivalAirportInfo.name,
              code: defaultArrival !== undefined ? defaultArrival : '',
            }}
            getOptionLabel={airport => (airport ? airport.name : '')}
            size="small"
            renderInput={params => (
              <TextField
                {...params}
                size="small"
                id="input-with-icon-textfield"
                label={t('destinationAirport')}
                placeholder={t('enterAirportName')}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <FlightLandIcon
                      sx={{
                        marginRight: '4px',
                        paddingBottom: '5px',
                        fontSize: '19px',
                      }}
                    />
                  ),
                  disableUnderline: true,
                }}
                variant="standard"
                InputLabelProps={{
                  sx: {fontSize: 16, fontWeight: 500},
                }}
                fullWidth
              />
            )}
          />
        </Box>
      </Stack>
    </Box>
  );
};
