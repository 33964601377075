import {Box, Typography} from '@mui/material';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Status} from '../API';

interface StatusChipProps {
  status: Status | null | undefined;
}

export const StatusChip: FC<StatusChipProps> = (props: StatusChipProps) => {
  const {t} = useTranslation();

  if (!props.status) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        paddingX: '17px',
        paddingY: '9.5px',
        maxHeight: '17px',
        border: `1px solid ${
          props.status === Status.APPROVED
            ? '#0050B3'
            : props.status === Status.PENDING
            ? '#2E7D32'
            : '#FF5F5F'
        }`,
        borderRadius: '8px',
      }}>
      <Typography
        variant="h6"
        color={
          props.status === Status.APPROVED
            ? '#0050B3'
            : props.status === Status.PENDING
            ? '#2E7D32'
            : '#FF5F5F'
        }>
        {props.status === Status.APPROVED
          ? t('bookConfirmed')
          : props.status === Status.PENDING
          ? t('bookInProcess')
          : t('failed')}
      </Typography>
    </Box>
  );
};
