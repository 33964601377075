import {
  Stack,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  RadioGroupProps,
  TextFieldProps,
} from '@mui/material';
import React, {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';

type LabelRadioProps = {
  labelTitle: ReactNode;
  isRequired?: boolean;
  error?: boolean;
  errorMessage?: string;
  options: {
    value?: string | number | boolean;
    label?: string;
    isTextRequired?: boolean;
    textFieldProps?: TextFieldProps;
    isDisabled?: boolean;
  }[];
} & RadioGroupProps;

export const LabelRadio: React.FC<LabelRadioProps> = ({
  labelTitle,
  isRequired,
  options,
  error,
  errorMessage,
  ...radioGroupProps
}) => {
  const {t} = useTranslation();

  return (
    <Stack direction="column" gap="9px">
      <Stack direction="row" gap="12px">
        {labelTitle}
        {isRequired && (
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 300,
              lineHeight: '18px',
              color: '#DA0000',
            }}>
            {t('requiredField')}
          </Typography>
        )}
      </Stack>
      <RadioGroup sx={{pl: '10px'}} {...radioGroupProps}>
        {options.map(option => {
          if (option) {
            return (
              <>
                <FormControlLabel
                  value={option.value}
                  label={option.label}
                  control={<Radio disabled={option.isDisabled} />}
                />
                {option.isTextRequired && (
                  <TextField sx={{pl: '30px'}} {...option.textFieldProps} />
                )}
              </>
            );
          } else {
            return null;
          }
        })}
      </RadioGroup>
      {error && (
        <Typography variant="error">
          {errorMessage ?? t('requiredFieldVaridation')}
        </Typography>
      )}
    </Stack>
  );
};
