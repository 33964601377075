import 'react-phone-input-2/lib/style.css';
import {zodResolver} from '@hookform/resolvers/zod';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import {
  Stack,
  Typography,
  TextField,
  Divider,
  Box,
  Autocomplete,
  Snackbar,
  Alert,
  AlertTitle,
  Button,
  Grid,
} from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';
import {useState, useEffect} from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {z} from 'zod';
import {CalculationMethod, CargoType, Estimate} from '../../API';
import {CustomItems} from '../../components/CustomItems';
import {LabelDatePicker} from '../../components/LabelDatePicker';
import {LabelNumericFormat} from '../../components/LabelNumericFormat';
import {LabelSelect} from '../../components/LabelSelect';
import {LabelTextField} from '../../components/LabelTextField';
import {Title} from '../../components/Title';
import {airlines} from '../../data/airlines';
import {airports} from '../../data/airports';
import Inner from '../../layouts/Inner';
import {useOAuthUserState} from '../../providers/AuthProvider';
import {useCurrencies} from '../../queries/currency';
import {useFhds} from '../../queries/fhd';
import {useFobs} from '../../queries/fob';
import {useForwarderUser} from '../../queries/forwarderUser';
import {OmitedCustomFee} from '../../types/index';
import {getLocalizedCargoType} from '../../utils/getLocalizedCargoType';
import {getLocalizedLeadTime} from '../../utils/getLocalizedLeadTime';
import {toAWSDate} from '../../utils/toAWSDate';

export type EstimateInputForm = Omit<
  Estimate,
  '__typename' | 'id' | 'status' | 'forwarderUser'
>;

export const formInput = z.object({
  id: z.string(),
  departure: z.string().min(1),
  arrival: z.string().min(1),
  airline: z.string().min(1),
  flightDate: z.string().min(1),
  directFlight: z.boolean(),
  leadTime: z.number().min(0).max(7),
  useSaf: z.boolean(),
  cargoType: z.enum([
    CargoType.NORMAL,
    CargoType.LITHIUM,
    CargoType.CLASS1_TO_9,
  ]),
  yardCutDate: z.string().min(1),
  carrierShedCutDate: z.string().min(1),
  estimateCurrencyId: z.string().min(1),
  minimum: z.number().min(0),
  under45: z.number().min(0),
  over45: z.number().min(0),
  over100: z.number().min(0),
  over300: z.number().min(0),
  over500: z.number().min(0),
  over1000: z.number().min(0),
  fsc: z.number().min(0),
  remark: z.string().nullable(),
  customItems: z.array(
    z
      .object({
        id: z.string(),
        title: z.string().min(1),
        unitPrice: z.number().min(0),
        unitType: z.string(),
        calculationMethod: z.enum([
          CalculationMethod.ACTUAL_COST,
          CalculationMethod.PER_CW,
          CalculationMethod.PER_GW,
          CalculationMethod.PER_SHIPMENT,
          CalculationMethod.PER_UNIT,
        ]),
        customFeeCurrencyId: z.string().min(1),
        minimum: z.number().min(0).nullable(),
        maximum: z.number().min(0).nullable(),
        includeTax: z.boolean(),
        taxRate: z.number().min(0).nullable(),
        remark: z.string().nullable(),
      })
      .nullable(),
  ),
});

export type FormInput = z.infer<typeof formInput>;

export const CreateEstimateScreen = () => {
  const {t} = useTranslation();
  const userState = useOAuthUserState();
  const sub = userState.user?.attributes.sub ?? '';
  const {data: forwarder} = useForwarderUser(sub);
  const navigate = useNavigate();
  const {data: fobs} = useFobs(forwarder?.companyId ?? '');
  const {data: fhds} = useFhds(forwarder?.companyId ?? '');
  const {data: currencies} = useCurrencies();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessages, setAlertMessages] = useState<
    {title: string; message: string}[]
  >([]);
  const location = useLocation();
  const defaultEstimate = location.state?.estimate ?? '';

  const methods = useForm<FormInput>({
    defaultValues: defaultEstimate
      ? {
          ...defaultEstimate,
          customItems: defaultEstimate.customItems
            ? defaultEstimate.customItems.map((item: OmitedCustomFee) => ({
                id: item.id,
                title: item.title,
                unitPrice: item.unitPrice,
                unitType: item.unitType,
                calculationMethod: item.calculationMethod,
                customFeeCurrencyId: item.customFeeCurrencyId,
                minimum: item.minimum,
                maximum: item.maximum,
                includeTax: item.includeTax,
                taxRate: item.taxRate,
                remark: item.remark,
              }))
            : [
                {
                  id: '',
                  title: '',
                  unitPrice: 0,
                  unitType: t('shipment'),
                  calculationMethod: CalculationMethod.PER_SHIPMENT,
                  customFeeCurrencyId: '',
                  minimum: null,
                  maximum: null,
                  includeTax: true,
                  taxRate: 0.0,
                  remark: '',
                },
              ],
        }
      : {
          id: '',
          departure: '',
          arrival: '',
          airline: '',
          flightDate: '',
          directFlight: true,
          leadTime: 0,
          useSaf: false,
          cargoType: CargoType.NORMAL,
          yardCutDate: '',
          carrierShedCutDate: '',
          estimateCurrencyId: currencies?.items[0]?.id,
          minimum: 0,
          under45: 0,
          over45: 0,
          over100: 0,
          over300: 0,
          over500: 0,
          over1000: 0,
          fsc: 0,
          remark: null,
          customItems: [
            {
              id: '',
              title: '',
              unitPrice: 0,
              unitType: t('shipment'),
              calculationMethod: CalculationMethod.PER_SHIPMENT,
              customFeeCurrencyId: '',
              minimum: null,
              maximum: null,
              includeTax: true,
              taxRate: 0.0,
              remark: '',
            },
          ],
        },
    mode: 'all',
    criteriaMode: 'all',
    resolver: zodResolver(formInput),
  });

  const {
    control,
    setValue,
    formState: {errors},
    handleSubmit,
  } = methods;

  const onSubmit: SubmitHandler<FormInput> = data => {
    // FOBFHDが設定されていない空港を設定した場合アラートを表示
    setAlertMessages([]);
    if (!fobs?.some(fob => fob?.airport === data.departure)) {
      setAlertOpen(true);
      setAlertMessages(prevMessages => [
        ...prevMessages,
        {
          title: t('exportChargeNotSet'),
          message:
            `${
              airports.find(airport => airport.code === data.departure)?.name ??
              ''
            }` + t('cannotRegisterExport'),
        },
      ]);
    }
    if (!fhds?.some(fhd => fhd?.airport === data.arrival)) {
      setAlertOpen(true);
      setAlertMessages(prevMessages => [
        ...prevMessages,
        {
          title: t('importChargeNotSet'),
          message:
            `${
              airports.find(airport => airport.code === data.arrival)?.name ??
              ''
            }` + t('cannotRegisterImport'),
        },
      ]);
    }
    if (
      !fobs?.some(fob => fob?.airport === data.departure) ||
      !fhds?.some(fhd => fhd?.airport === data.arrival)
    ) {
      return;
    }
    navigate('/createEstimateConfirmation', {
      state: {
        estimate: data,
      },
    });
  };

  useEffect(() => {
    document.title = `${t('quotationRegistration')} | MAST`;
  }, [t]);

  const cargoTypeOptions = [CargoType.NORMAL];

  const leadTimeOptions = [0, 1, 2, 3, 4, 5, 6, 7];

  return (
    <>
      <FormProvider {...methods}>
        <Stack ml={20} alignItems="start">
          <Title title={t('quotationRegistration')} />
          <Stack
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mt={3}>
            <Inner>
              <Stack spacing={3}>
                <Stack
                  flexDirection="column"
                  sx={{
                    gap: '24px',
                  }}>
                  <Stack
                    flexDirection="column"
                    sx={{
                      width: '494px',
                      gap: '24px',
                    }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      border={1}
                      borderRadius="10px"
                      borderColor="#C4C4C4">
                      <Box
                        display="flex"
                        flexDirection="column"
                        sx={{
                          marginY: '15px',
                          marginLeft: '14px',
                          marginRight: '19px',
                        }}>
                        <Typography variant="h6">
                          {t('originAirport')}
                        </Typography>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center">
                          <FlightTakeoffIcon
                            sx={{
                              fontSize: '19px',
                            }}
                          />
                          <Controller
                            name="departure"
                            control={control}
                            render={({field}) => (
                              <Autocomplete
                                value={airports.find(
                                  airport => airport.code === field.value,
                                )}
                                onChange={(_, value) => {
                                  field.onChange(value?.code ?? '');
                                }}
                                sx={{width: '191px'}}
                                options={airports}
                                getOptionLabel={airport =>
                                  airport ? airport.name : ''
                                }
                                size="small"
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    placeholder={t('selectAirport')}
                                    variant="standard"
                                    InputProps={{
                                      ...params.InputProps,
                                      disableUnderline: true,
                                      style: {
                                        height: '27px',
                                      },
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      sx: {
                                        height: '27px',
                                        paddingY: 'unset !important',
                                        paddingLeft: '3px !important',
                                      },
                                    }}
                                    error={!!errors.departure}
                                    helperText={errors.departure?.message}
                                  />
                                )}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                      <Divider orientation="vertical" flexItem />
                      <Box
                        display="flex"
                        flexDirection="column"
                        sx={{
                          marginY: '15px',
                          marginLeft: '14px',
                          marginRight: '19px',
                        }}>
                        <Typography variant="h6">
                          {t('destinationAirport')}
                        </Typography>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center">
                          <FlightLandIcon
                            sx={{
                              fontSize: '19px',
                            }}
                          />
                          <Controller
                            name="arrival"
                            control={control}
                            render={({field}) => (
                              <Autocomplete
                                value={airports.find(
                                  airport => airport.code === field.value,
                                )}
                                onChange={(_, value) => {
                                  field.onChange(value?.code ?? '');
                                }}
                                sx={{width: '191px'}}
                                options={airports}
                                getOptionLabel={airport =>
                                  airport ? airport.name : ''
                                }
                                size="small"
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    placeholder={t('selectAirport')}
                                    variant="standard"
                                    InputProps={{
                                      ...params.InputProps,
                                      disableUnderline: true,
                                      style: {
                                        height: '27px',
                                      },
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      sx: {
                                        height: '27px',
                                        paddingY: 'unset !important',
                                        paddingLeft: '3px !important',
                                      },
                                    }}
                                    error={!!errors.arrival}
                                    helperText={errors.arrival?.message}
                                  />
                                )}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="494px">
                      <Grid container spacing="12px">
                        <Grid item xs={6}>
                          <Stack
                            direction="column"
                            width="100%"
                            height="100%"
                            gap="9px">
                            <Typography variant="h5">{t('airline')}</Typography>
                            <Controller
                              name="airline"
                              control={control}
                              render={({field}) => (
                                <Autocomplete
                                  value={airlines.find(
                                    airline => airline.code === field.value,
                                  )}
                                  onChange={(_, value) => {
                                    field.onChange(value?.code ?? '');
                                  }}
                                  sx={{width: '241px'}}
                                  options={airlines}
                                  getOptionLabel={airline =>
                                    airline ? airline.name : ''
                                  }
                                  size="small"
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      placeholder={t('selectAirline')}
                                      inputProps={{
                                        ...params.inputProps,
                                        sx: {
                                          paddingLeft: '9px !important',
                                          paddingY: '5px !important',
                                        },
                                      }}
                                      error={!!errors.airline}
                                      helperText={errors.airline?.message}
                                    />
                                  )}
                                />
                              )}
                            />
                          </Stack>
                        </Grid>
                        <Controller
                          name="flightDate"
                          control={control}
                          render={({field, fieldState}) => (
                            <Grid item xs={6}>
                              <LabelDatePicker
                                title={t('etd')}
                                value={dayjs(field.value)}
                                onChange={(date: Dayjs | null) => {
                                  if (date) {
                                    field.onChange(toAWSDate(date.toDate()));
                                  }
                                }}
                                error={fieldState.error?.message}
                              />
                            </Grid>
                          )}
                        />
                      </Grid>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="494px">
                      <Grid container spacing="13px">
                        <Controller
                          name="directFlight"
                          control={control}
                          render={({field}) => (
                            <Grid item xs={4}>
                              <LabelSelect
                                title={t('flightType')}
                                {...field}
                                options={[
                                  {value: true, label: t('directFlight')},
                                  {value: false, label: t('connectingFlight')},
                                ]}
                              />
                            </Grid>
                          )}
                        />
                        <Controller
                          name="leadTime"
                          control={control}
                          render={({field}) => (
                            <Grid item xs={4}>
                              <LabelSelect
                                title={t('leadTime')}
                                {...field}
                                options={leadTimeOptions.map(value => ({
                                  value,
                                  label: getLocalizedLeadTime(value),
                                }))}
                              />
                            </Grid>
                          )}
                        />
                        <Controller
                          name="useSaf"
                          control={control}
                          render={({field}) => (
                            <Grid item xs={4}>
                              <LabelSelect
                                title={t('saf')}
                                {...field}
                                options={[
                                  {value: true, label: t('use')},
                                  {value: false, label: t('nonUse')},
                                ]}
                              />
                            </Grid>
                          )}
                        />
                      </Grid>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="494px">
                      <Grid container>
                        <Grid item xs={12}>
                          <Controller
                            name="cargoType"
                            control={control}
                            render={({field}) => (
                              <LabelSelect
                                title={t('commodityTypeExport')}
                                {...field}
                                options={cargoTypeOptions.map(value => ({
                                  value,
                                  label: getLocalizedCargoType(value),
                                }))}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="494px">
                      <Grid container spacing="12px">
                        <Controller
                          name="yardCutDate"
                          control={control}
                          render={({field, fieldState}) => (
                            <Grid item xs={6}>
                              <LabelDatePicker
                                title={t('ownWarehouse') + ' ' + t('cut')}
                                value={dayjs(field.value)}
                                onChange={(date: Dayjs | null) => {
                                  if (date) {
                                    field.onChange(toAWSDate(date.toDate()));
                                  }
                                }}
                                error={fieldState.error?.message}
                              />
                            </Grid>
                          )}
                        />
                        <Controller
                          name="carrierShedCutDate"
                          control={control}
                          render={({field, fieldState}) => (
                            <Grid item xs={6}>
                              <LabelDatePicker
                                title={t('airlineWarehouseDirectCut')}
                                value={dayjs(field.value)}
                                onChange={(date: Dayjs | null) => {
                                  if (date) {
                                    field.onChange(toAWSDate(date.toDate()));
                                  }
                                }}
                                error={fieldState.error?.message}
                              />
                            </Grid>
                          )}
                        />
                      </Grid>
                    </Stack>
                  </Stack>
                  <Stack
                    sx={{
                      width: '125px',
                    }}>
                    <Controller
                      name="estimateCurrencyId"
                      control={control}
                      render={({field}) => (
                        <LabelSelect
                          title={t('currency')}
                          value={field.value}
                          isRequired={true}
                          onChange={event => {
                            const selectedCurrencyId = event.target.value;
                            const selectedCurrency = currencies?.items.find(
                              currency => currency?.id === selectedCurrencyId,
                            );

                            if (selectedCurrency) {
                              setValue(
                                'estimateCurrencyId',
                                selectedCurrency.id,
                              );
                            }
                          }}
                          options={
                            currencies?.items?.map(item => ({
                              value: item?.id,
                              label: item?.code,
                            })) || []
                          }
                          error={!!errors.estimateCurrencyId}
                        />
                      )}
                    />
                  </Stack>
                  <Stack
                    flexDirection="column"
                    justifyContent="space-between"
                    sx={{
                      gap: '16px',
                    }}>
                    <Stack direction="row" alignItems="center">
                      <Grid container spacing="13px">
                        <Grid item xs={4}>
                          <Controller
                            name="minimum"
                            control={control}
                            render={({field}) => (
                              <LabelNumericFormat
                                title={t('minimum')}
                                isRequired={true}
                                value={field.value}
                                onChange={field.onChange}
                                error={!!errors.minimum}
                                helperText={errors.minimum?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name="under45"
                            control={control}
                            render={({field}) => (
                              <LabelNumericFormat
                                title={t('minusFortyFive')}
                                isRequired={true}
                                value={field.value}
                                onChange={field.onChange}
                                error={!!errors.under45}
                                helperText={errors.under45?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name="over45"
                            control={control}
                            render={({field}) => (
                              <LabelNumericFormat
                                title={t('plusFortyFive')}
                                isRequired={true}
                                value={field.value}
                                onChange={field.onChange}
                                error={!!errors.over45}
                                helperText={errors.over45?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <Grid container spacing="13px">
                        <Grid item xs={4}>
                          <Controller
                            name="over100"
                            control={control}
                            render={({field}) => (
                              <LabelNumericFormat
                                title={t('plusHundred')}
                                isRequired={true}
                                value={field.value}
                                onChange={field.onChange}
                                error={!!errors.over100}
                                helperText={errors.over100?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name="over300"
                            control={control}
                            render={({field}) => (
                              <LabelNumericFormat
                                title={t('plusThreeHundred')}
                                isRequired={true}
                                value={field.value}
                                onChange={field.onChange}
                                error={!!errors.over300}
                                helperText={errors.over300?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name="over500"
                            control={control}
                            render={({field}) => (
                              <LabelNumericFormat
                                title={t('plusFiveHundred')}
                                isRequired={true}
                                value={field.value}
                                onChange={field.onChange}
                                error={!!errors.over500}
                                helperText={errors.over500?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <Grid container spacing="13px">
                        <Grid item xs={4}>
                          <Controller
                            name="over1000"
                            control={control}
                            render={({field}) => (
                              <LabelNumericFormat
                                title={t('plusThousand')}
                                isRequired={true}
                                value={field.value}
                                onChange={field.onChange}
                                error={!!errors.over1000}
                                helperText={errors.over1000?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name="fsc"
                            control={control}
                            render={({field}) => (
                              <LabelNumericFormat
                                title={t('fsc')}
                                isRequired={true}
                                value={field.value}
                                onChange={field.onChange}
                                error={!!errors.fsc}
                                helperText={errors.fsc?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                </Stack>
                <Typography variant="h5">{t('customizedItem')}</Typography>
                <CustomItems minimumItems={0} />
                <Box width="100%">
                  <Controller
                    name="remark"
                    control={control}
                    render={({field}) => (
                      <LabelTextField
                        title={t('remark')}
                        {...field}
                        multiline={true}
                        minRows={3}
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  sx={{
                    width: '564px',
                  }}>
                  <Button
                    variant="gray"
                    size="large"
                    onClick={() => {
                      navigate('/estimateList');
                    }}>
                    {t('back')}
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    size="large"
                    style={{marginLeft: '24px'}}
                    onClick={handleSubmit(onSubmit)}>
                    {t('next')}
                  </Button>
                </Stack>
              </Stack>
            </Inner>
          </Stack>
        </Stack>
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          style={{top: '72px'}}
          open={alertOpen}
          autoHideDuration={6000}
          onClose={() => {
            setAlertOpen(false);
          }}>
          <Alert
            severity="warning"
            sx={{width: '588px'}}
            onClose={() => {
              setAlertOpen(false);
            }}>
            <Stack spacing={2}>
              {alertMessages.map(item => {
                return (
                  <>
                    <AlertTitle>{item.title}</AlertTitle>
                    {item.message}
                  </>
                );
              })}
            </Stack>
          </Alert>
        </Snackbar>
      </FormProvider>
    </>
  );
};
