export const japanAirports: string[] = [
  'CTS',
  'FUK',
  'HND',
  'KIX',
  'KKJ',
  'NGO',
  'NRT',
  'OKA',
  'OSA',
  'SPK',
];
