import {Stack, Grid, Box, Typography, Button} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {NewsList} from './NewsList';
import {Airports, InputAirport} from '../../components/InputAirport';
import {MarketConditionsChart} from '../../components/MarketConditionsChart';
import {SelectExportCharges} from '../../components/SelectExportCharges';
import Inner from '../../layouts/Inner';

export const ShipperHomeScreen = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [departure, setDeparture] = useState('');
  const [arrival, setArrival] = useState('');
  const [charge, setCharge] = useState('');

  const handleAirportChange = useCallback((newValue: Airports) => {
    setDeparture(newValue?.departure ?? '');
    setArrival(newValue?.arrival ?? '');
  }, []);

  const handleChangeAlignment = useCallback((newValue: string) => {
    setCharge(newValue);
  }, []);

  const onSubmit = useCallback(() => {
    navigate('/searchEstimate', {
      state: {
        departure,
        arrival,
        charge,
      },
    });
  }, [arrival, charge, departure, navigate]);

  useEffect(() => {
    document.title = `MAST`;
  }, [t]);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Typography variant="h2" mb={2}>
            {t('searchQuotation')}
          </Typography>
          <Inner sx={{padding: '20px'}}>
            <Stack
              pb="20px"
              direction="column"
              justifyContent="center"
              spacing={3}>
              <InputAirport onValueChange={handleAirportChange} />
              <Box
                sx={{
                  backgroundColor: '#F0F0F3',
                  display: 'flex',
                  padding: '24px 0',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  borderRadius: '10px',
                }}>
                <Stack
                  px="20px"
                  py="20px"
                  direction="column"
                  justifyContent="center"
                  width="calc(100% - 40px)">
                  <Typography variant="h6" mb={1}>
                    {t('selectPriceConfirmationRange')}
                  </Typography>
                  <SelectExportCharges onValueChange={handleChangeAlignment} />
                </Stack>
              </Box>
            </Stack>
            <Stack alignItems="center">
              <Button
                variant="primary"
                type="submit"
                size="large"
                onClick={onSubmit}>
                {t('searchNoun')}
              </Button>
            </Stack>
          </Inner>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h2" mb={2}>
            {t('airfreightIndex')}
          </Typography>
          <Inner
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '20px',
              height: '370px',
            }}>
            <MarketConditionsChart />
          </Inner>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" mb={2}>
            {t('newsFlash')}
          </Typography>
          <NewsList />
        </Grid>
      </Grid>
    </>
  );
};
