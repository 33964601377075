import {Box, Stack, Typography, Divider, Button} from '@mui/material';
import dayjs from 'dayjs';
import {t} from 'i18next';
import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {DepartureArrivalAirports} from './DepartureArrivalAirports';
import {ForwarderCompanyRate} from './ForwarderCompanyRate';
import {Item} from './InputEstimateItems';
import {LabelValue} from './LabelValue';
import {TransportType} from '../API';
import {japanAirports} from '../data/japanAirports';
import Inner from '../layouts/Inner';
import {useEstimate} from '../queries/estimate';
import {useFirstFhds} from '../queries/fhd';
import {useFirstFobs} from '../queries/fob';
import {calculateCustomFee} from '../utils/calculateCustomFee';
import {calculateFee} from '../utils/calculateFee';
import {getAirlineNameByCode} from '../utils/getAirlineNameByCode';
import {getLocalizedLeadTime} from '../utils/getLocalizedLeadTime';
import {isNonNull} from '../utils/isEmpty';
import {roundToDecimal} from '../utils/roundToDecimal';

type EstimateSummaryProps = {
  estimateId?: string;
  totalCw: number;
  searchItems: Item[];
  totalGw: number;
  unit: number;
  charge: string;
  setIsDetailOpen: Dispatch<SetStateAction<boolean>>;
  showDetail: (estimateId: string) => void;
  setSelectedEstimateId: Dispatch<SetStateAction<string | null>>;
};

export const EstimateSummary: FC<EstimateSummaryProps> = props => {
  const showDetailHandler = (estimateId: string) => {
    props.showDetail(estimateId);
    props.setIsDetailOpen(true);
    props.setSelectedEstimateId(estimateId);
  };
  const navigate = useNavigate();

  const {data: estimate} = useEstimate(props.estimateId ?? '');

  const {data: fob} = useFirstFobs(
    estimate?.forwarderUser.companyId ?? '',
    estimate?.departure ?? '',
  );

  const {data: fhd} = useFirstFhds(
    estimate?.forwarderUser.companyId ?? '',
    estimate?.arrival,
  );

  const [shippingFee, setShippingFee] = useState(0);
  const [shippingFobFee, setShippingFobFee] = useState(0);
  const [shippingFhdFee, setShippingFhdFee] = useState(0);
  const [shippingFeeIncludeTax, setShippingFeeIncludeTax] = useState(0);
  const [shippingFobFeeIncludeTax, setShippingFobFeeIncludeTax] = useState(0);
  const [shippingFhdFeeIncludeTax, setShippingFhdFeeIncludeTax] = useState(0);

  const [totalFeeIncludeTax, setTotalFeeIncludeTax] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [totalFeeTax, setTotalFeeTax] = useState(0);

  useEffect(() => {
    setShippingFee(
      roundToDecimal(
        calculateFee(
          props.totalCw,
          props.totalGw,
          props.unit,
          estimate?.over1000,
          estimate?.over500,
          estimate?.over300,
          estimate?.over100,
          estimate?.over45,
          estimate?.under45,
          estimate?.fsc,
          estimate?.minimum,
          estimate?.customItems?.items,
          estimate?.currency?.exchangeRateJPY,
        ),
        0,
      ),
    );
    setShippingFobFee(
      roundToDecimal(
        calculateCustomFee(
          fob?.customItems?.items?.filter(isNonNull) ?? [],
          props.totalCw,
          props.totalGw,
          props.unit,
        ),
        0,
      ),
    );
    setShippingFhdFee(
      roundToDecimal(
        calculateCustomFee(
          fhd?.customItems?.items?.filter(isNonNull) ?? [],
          props.totalCw,
          props.totalGw,
          props.unit,
        ),
        0,
      ),
    );
    setShippingFeeIncludeTax(
      roundToDecimal(
        calculateFee(
          props.totalCw,
          props.totalGw,
          props.unit,
          estimate?.over1000,
          estimate?.over500,
          estimate?.over300,
          estimate?.over100,
          estimate?.over45,
          estimate?.under45,
          estimate?.fsc,
          estimate?.minimum,
          estimate?.customItems?.items,
          estimate?.currency?.exchangeRateJPY,
          true,
        ),
        0,
      ),
    );
    setShippingFobFeeIncludeTax(
      roundToDecimal(
        calculateCustomFee(
          fob?.customItems?.items?.filter(isNonNull) ?? [],
          props.totalCw,
          props.totalGw,
          props.unit,
          true,
        ),
        0,
      ),
    );
    setShippingFhdFeeIncludeTax(
      roundToDecimal(
        calculateCustomFee(
          fhd?.customItems?.items?.filter(isNonNull) ?? [],
          props.totalCw,
          props.totalGw,
          props.unit,
          true,
        ),
        0,
      ),
    );
  }, [estimate, fhd, fob, props]);

  // 料金確認範囲によって表示総額を変更
  // 税も含んだ総額
  useEffect(() => {
    setTotalFeeIncludeTax(
      props.charge === TransportType.TRILATERAL
        ? shippingFeeIncludeTax +
            shippingFobFeeIncludeTax +
            shippingFhdFeeIncludeTax
        : props.charge === TransportType.AIR_EXPORT
        ? shippingFeeIncludeTax + shippingFobFeeIncludeTax
        : shippingFeeIncludeTax,
    );
  }, [
    props,
    shippingFeeIncludeTax,
    shippingFhdFeeIncludeTax,
    shippingFobFeeIncludeTax,
  ]);
  useEffect(() => {
    // 税を含まない合計
    setTotalFee(
      props.charge === TransportType.TRILATERAL
        ? shippingFee + shippingFobFee + shippingFhdFee
        : props.charge === TransportType.AIR_EXPORT
        ? shippingFee + shippingFobFee
        : shippingFee,
    );
  }, [props, shippingFee, shippingFhdFee, shippingFobFee]);
  useEffect(() => {
    // 税のみ
    setTotalFeeTax(totalFeeIncludeTax - totalFee);
  }, [totalFee, totalFeeIncludeTax]);

  return (
    <Inner sx={{padding: '20px'}}>
      <Stack spacing={3} mb={3}>
        <Stack direction="row" spacing={1}>
          <Box
            sx={{
              backgroundColor: estimate?.directFlight ? '#DE6E54' : '#33BDD4',
              paddingX: '10px',
              paddingY: '5px',
              borderRadius: '6px',
              color: '#333333',
            }}>
            <Typography variant="h6" color="white">
              {estimate?.directFlight
                ? t('directFlight')
                : t('connectingFlight')}
            </Typography>
          </Box>
          {estimate?.useSaf && (
            <Box
              sx={{
                backgroundColor: '#2E7D32',
                paddingX: '10px',
                paddingY: '5px',
                borderRadius: '6px',
                color: '#333333',
              }}>
              <Typography variant="h6" color="white">
                SAF
              </Typography>
            </Box>
          )}
        </Stack>
        <ForwarderCompanyRate
          forwarderCompanyId={estimate?.forwarderUser.companyId}
        />
        <Stack spacing={1}>
          <DepartureArrivalAirports
            departure={estimate?.departure}
            arrival={estimate?.arrival}
          />
          <LabelValue
            title={`${t('etd')}:`}
            titleWeight="600"
            value={dayjs(estimate?.flightDate).format('YYYY/M/D') ?? ''}
            row
          />
        </Stack>
      </Stack>
      <LabelValue
        title={t('totalFee')}
        value={`¥ ${totalFeeIncludeTax.toLocaleString()}`}
        valueWeight="700"
        valueSize="24px"
        width="260px"
        color="#3F6CDF"
      />
      <Divider
        style={{
          border: 'solid 0.5px #DADCE3',
          marginBottom: '16px',
        }}
      />
      {/* 料金確認範囲が"航空運賃"以外で航空運賃,輸入料金を表示 航空運賃のみの場合は総額=航空運賃 */}
      {props.charge !== TransportType.AIR_IMPORT && (
        <>
          <LabelValue
            title={t('airfreight')}
            value={`¥ ${shippingFee.toLocaleString()}`}
            valueWeight="700"
            width="238px"
            color="#3F6CDF"
          />
          <Divider
            style={{
              border: 'solid 0.5px #DADCE3',
              marginBottom: '16px',
            }}
          />
          <LabelValue
            title={t('exportCharge')}
            value={`¥ ${shippingFobFee.toLocaleString()}`}
            valueWeight="700"
            width="238px"
            color="#3F6CDF"
          />
          <Divider
            style={{
              border: 'solid 0.5px #DADCE3',
              marginBottom: '16px',
            }}
          />
        </>
      )}
      {/* 料金確認範囲が"航空運賃+輸出料金+輸入料金"の場合のみ輸入料金を表示 */}
      {props.charge === TransportType.TRILATERAL && (
        <>
          <LabelValue
            title={t('importCharge')}
            value={`¥ ${shippingFhdFee.toLocaleString()}`}
            valueWeight="700"
            width="238px"
            color="#3F6CDF"
          />
          <Divider
            style={{
              border: 'solid 0.5px #DADCE3',
              marginBottom: '16px',
            }}
          />
        </>
      )}
      <LabelValue
        title={t('airline')}
        value={getAirlineNameByCode(estimate?.airline ?? '') ?? ''}
        width="238px"
      />
      <Divider
        style={{
          border: 'solid 0.5px #DADCE3',
          marginBottom: '16px',
        }}
      />
      <LabelValue
        title={t('leadTime')}
        value={getLocalizedLeadTime(estimate?.leadTime)}
        width="238px"
      />
      <Divider
        style={{
          border: 'solid 0.5px #DADCE3',
          marginBottom: '16px',
        }}
      />
      <LabelValue
        title={t('fwdWarehouse') + ' ' + t('cut')}
        value={dayjs(estimate?.yardCutDate).format('YYYY/M/D') ?? ''}
        width="238px"
      />
      <Divider
        style={{
          border: 'solid 0.5px #DADCE3',
          marginBottom: '16px',
        }}
      />
      <LabelValue
        title={t('airlineWarehouseDirectCut')}
        value={dayjs(estimate?.carrierShedCutDate).format('YYYY/M/D') ?? ''}
        width="238px"
      />
      <Stack direction="row" spacing={1}>
        <Button
          variant="gray"
          onClick={() => {
            showDetailHandler(estimate?.id ?? '');
          }}>
          {t('viewPriceDetails')}
        </Button>

        <Button
          variant="primary"
          onClick={() => {
            const navigatePath = japanAirports.includes(
              estimate?.departure ?? '',
            )
              ? '/createSIJpnDeparture'
              : japanAirports.includes(estimate?.arrival ?? '')
              ? '/createSIJpnArrival'
              : '/createSIOthers';

            navigate(navigatePath, {
              state: {
                estimateId: props.estimateId,
                totalCw: props.totalCw,
                totalGw: props.totalGw,
                unit: props.unit,
                totalFee,
                totalFeeTax,
                totalFeeIncludeTax,
                searchItems: props.searchItems,
                charge: props.charge,
              },
            });
          }}>
          {t('goToBookingProcedure')}
        </Button>
      </Stack>
    </Inner>
  );
};
