import {Stack, Typography, Button} from '@mui/material';
import React, {useState, useCallback, ComponentProps} from 'react';

type Props = {
  text: string;
  maxLength: number;
} & ComponentProps<typeof Typography>;

export const ExpandableText = ({text, maxLength, ...props}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpandClick = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);
  const displayText =
    text.length <= maxLength || isExpanded
      ? text
      : text.substring(0, maxLength) + '...';

  return (
    <>
      <Typography variant="body2" sx={{wordBreak: 'break-word'}} {...props}>
        {displayText}
      </Typography>
      {text.length > maxLength ? (
        <Stack alignItems="flex-end">
          <Button variant="primary" onClick={handleExpandClick}>
            {isExpanded ? '-省略する' : '+全て読む'}
          </Button>
        </Stack>
      ) : null}
    </>
  );
};
