import 'react-phone-input-2/lib/style.css';
import {zodResolver} from '@hookform/resolvers/zod';
import {Stack, Button} from '@mui/material';
import * as Sentry from '@sentry/react';
import {useCallback, useEffect} from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {z} from 'zod';
import {CalculationMethod} from '../../API';
import {CustomItems} from '../../components/CustomItems';
import {LabelTextField} from '../../components/LabelTextField';
import {Title} from '../../components/Title';
import {useOAuthUserState} from '../../providers/AuthProvider';
import {useCreateCustomFeeMutation} from '../../queries/customFee';
import {useCreateFobMutation} from '../../queries/fob';
import {useForwarderUser} from '../../queries/forwarderUser';
import {assert} from '../../utils/assert';

export const formInput = z.object({
  remark: z.string().nullable(),
  customItems: z.array(
    z
      .object({
        id: z.string(),
        title: z.string().min(1),
        unitPrice: z.number().min(0),
        unitType: z.string(),
        calculationMethod: z.enum([
          CalculationMethod.ACTUAL_COST,
          CalculationMethod.PER_CW,
          CalculationMethod.PER_GW,
          CalculationMethod.PER_SHIPMENT,
          CalculationMethod.PER_UNIT,
        ]),
        customFeeCurrencyId: z.string().min(1),
        minimum: z.number().min(0).nullable(),
        maximum: z.number().min(0).nullable(),
        includeTax: z.boolean(),
        taxRate: z.number().min(0).nullable(),
        remark: z.string().nullable(),
      })
      .nullable(),
  ),
});

export type FormInput = z.infer<typeof formInput>;

export const CreateFobScreen = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useOAuthUserState();
  const sub = userState.user?.attributes.sub ?? '';
  const {data: forwarder} = useForwarderUser(sub);
  const {mutateAsync: createFobMutation} = useCreateFobMutation();
  const {mutateAsync: createCustomFeeMutation} = useCreateCustomFeeMutation();
  const state = location.state as {
    airport: {
      id: number;
      name: string;
      code: string;
    };
  };
  const methods = useForm<FormInput>({
    defaultValues: {
      remark: null,
      customItems: [
        {
          id: '',
          title: '',
          unitPrice: undefined,
          unitType: '',
          calculationMethod: CalculationMethod.PER_SHIPMENT,
          customFeeCurrencyId: '',
          minimum: null,
          maximum: null,
          includeTax: true,
          taxRate: 0.0,
          remark: '',
        },
      ],
    },
    mode: 'all',
    criteriaMode: 'all',
    resolver: zodResolver(formInput),
  });

  const {control, handleSubmit} = methods;

  const createCustomFees: SubmitHandler<FormInput> = useCallback(
    async data => {
      try {
        assert(
          forwarder && forwarder.companyId,
          'An error occurs because forwarder.companyId is undefined in CreateFobScreen',
        );
        await createFobMutation(
          {
            input: {
              airport: state.airport.code,
              forwarderCompanyId: forwarder.companyId,
              remark: data.remark,
            },
          },
          {
            onError: err => {
              Sentry.captureException(err);
              return navigate('error');
            },
          },
        ).then(async response => {
          assert(
            response,
            'An error occurs because response is undefined in CreateFobScreen',
          );
          for (const customItem of data.customItems) {
            assert(
              customItem,
              'An error occurs because customItem is undefined in CreateFobScreen',
            );
            try {
              await createCustomFeeMutation(
                {
                  input: {
                    title: customItem.title,
                    unitPrice: customItem.unitPrice,
                    unitType: customItem.unitType,
                    calculationMethod: customItem.calculationMethod,
                    minimum: customItem.minimum,
                    maximum: customItem.maximum,
                    includeTax: customItem.includeTax,
                    taxRate: customItem.taxRate,
                    remark: customItem.remark,
                    fobId: response.id,
                    customFeeCurrencyId: customItem.customFeeCurrencyId,
                  },
                },
                {
                  onError: err => {
                    Sentry.captureException(err);
                    return navigate('error');
                  },
                },
              );
            } catch (err) {
              Sentry.captureException(err);
              return navigate('error');
            }
          }
          navigate('/fobFhd');
        });
      } catch {
        navigate('error');
      }
    },
    [
      createCustomFeeMutation,
      createFobMutation,
      forwarder,
      navigate,
      state.airport.code,
    ],
  );

  useEffect(() => {
    document.title = `${t('createFob')} | MAST`;
  }, [t]);

  return (
    <>
      <FormProvider {...methods}>
        <Stack ml={20} alignItems="start" flexDirection="column">
          <Title title={`${t('exportCharge')} ${t('newAddition')}`} />
          <CustomItems minimumItems={1} />
          <Stack
            flexDirection="column"
            alignContent="center"
            sx={{
              width: '690px',
              display: 'flex',
              gap: '10px',
            }}>
            <Controller
              name="remark"
              control={control}
              render={({field}) => (
                <LabelTextField
                  title={t('remark')}
                  {...field}
                  multiline={true}
                  minRows={3}
                  fullWidth
                />
              )}
            />
          </Stack>
          <Stack flexDirection="row" mt={5}>
            <Button
              variant="gray"
              size="large"
              onClick={() => {
                navigate('/fobFhd');
              }}>
              {t('cancel')}
            </Button>
            <Button
              variant="primary"
              type="submit"
              size="large"
              style={{marginLeft: '24px'}}
              onClick={handleSubmit(createCustomFees)}>
              {t('newAddition')}
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
    </>
  );
};
