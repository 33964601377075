import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery} from 'react-query';
import {
  CreateMarketConditionsWithSafMutation,
  CreateMarketConditionsWithSafMutationVariables,
  DeleteMarketConditionsWithSafMutation,
  DeleteMarketConditionsWithSafMutationVariables,
  ListMarketConditionsWithSafsQuery,
  ListMarketConditionsWithSafsQueryVariables,
  ModelMarketConditionsWithoutSafFilterInput,
  UpdateMarketConditionsWithSafMutation,
  UpdateMarketConditionsWithSafMutationVariables,
} from '../API';
import {
  createMarketConditionsWithSaf,
  deleteMarketConditionsWithSaf,
  updateMarketConditionsWithSaf,
} from '../graphql/mutations';
import {listMarketConditionsWithSafs} from '../graphql/queries';

export const useMarketConditionsWithSafs = (arrival?: string) => {
  return useQuery(['marketConditionsWithSaf', arrival], async () => {
    const filter: ModelMarketConditionsWithoutSafFilterInput = {};
    if (arrival) {
      filter.arrival = {eq: arrival};
    }
    const variables: ListMarketConditionsWithSafsQueryVariables = {
      limit: 10000,
      filter,
    };
    const result = (await API.graphql(
      graphqlOperation(listMarketConditionsWithSafs, variables),
    )) as GraphQLResult<ListMarketConditionsWithSafsQuery>;
    return result?.data?.listMarketConditionsWithSafs;
  });
};

export const useCreateMarketConditionsWithSafMutation = () => {
  return useMutation(
    async (variables: CreateMarketConditionsWithSafMutationVariables) => {
      const user = (await API.graphql(
        graphqlOperation(createMarketConditionsWithSaf, variables),
      )) as GraphQLResult<CreateMarketConditionsWithSafMutation>;
      return user.data?.createMarketConditionsWithSaf;
    },
  );
};

export const useUpdateMarketConditionsWithSafMutation = () => {
  return useMutation(
    async (variables: UpdateMarketConditionsWithSafMutationVariables) => {
      const user = (await API.graphql(
        graphqlOperation(updateMarketConditionsWithSaf, variables),
      )) as GraphQLResult<UpdateMarketConditionsWithSafMutation>;
      return user.data?.updateMarketConditionsWithSaf;
    },
  );
};

export const useDeleteMarketConditionsWithSafMutation = () => {
  return useMutation(
    async (variables: DeleteMarketConditionsWithSafMutationVariables) => {
      const user = (await API.graphql(
        graphqlOperation(deleteMarketConditionsWithSaf, variables),
      )) as GraphQLResult<DeleteMarketConditionsWithSafMutation>;
      return user.data?.deleteMarketConditionsWithSaf;
    },
  );
};
