import {CargoType} from '../API';
import i18n from '../i18n/i18n';

export const getLocalizedCargoType = (cargoType: CargoType) => {
  return {
    [CargoType.NORMAL]: () => i18n.t('nonDangerousGoods'),
    [CargoType.LITHIUM]: () => i18n.t('dangerousGoodsLithium'),
    [CargoType.CLASS1_TO_9]: () => i18n.t('dangerousGoodsClass'),
  }[cargoType]();
};
