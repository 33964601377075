import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery} from 'react-query';
import {
  CreateShippingInstructionMutation,
  CreateShippingInstructionMutationVariables,
  DeleteShippingInstructionMutation,
  DeleteShippingInstructionMutationVariables,
  ListShippingInstructionsQuery,
  ListShippingInstructionsQueryVariables,
  UpdateShippingInstructionMutation,
  UpdateShippingInstructionMutationVariables,
  GetShippingInstructionQuery,
  GetShippingInstructionQueryVariables,
} from '../API';
import {
  createShippingInstruction,
  deleteShippingInstruction,
  updateShippingInstruction,
} from '../graphql/mutations';
import {
  listShippingInstructions,
  getShippingInstruction,
} from '../graphql/queries';

export const useShippingInstructions = () => {
  return useQuery(['shippingInstructions'], async () => {
    const variables: ListShippingInstructionsQueryVariables = {
      limit: 10000,
    };
    const result = (await API.graphql(
      graphqlOperation(listShippingInstructions, variables),
    )) as GraphQLResult<ListShippingInstructionsQuery>;
    return result?.data?.listShippingInstructions;
  });
};

export const useShippingInstruction = (id: string) => {
  return useQuery(
    ['shippingInstruction', id],
    async () => {
      const variable: GetShippingInstructionQueryVariables = {
        id,
      };
      const result = (await API.graphql(
        graphqlOperation(getShippingInstruction, variable),
      )) as GraphQLResult<GetShippingInstructionQuery>;
      return result.data?.getShippingInstruction;
    },
    {
      enabled: !!id,
    },
  );
};

export const useCreateShippingInstructionMutation = () => {
  return useMutation(
    async (variables: CreateShippingInstructionMutationVariables) => {
      const shippingInstruction = (await API.graphql(
        graphqlOperation(createShippingInstruction, variables),
      )) as GraphQLResult<CreateShippingInstructionMutation>;
      return shippingInstruction.data?.createShippingInstruction;
    },
  );
};

export const useUpdateShippingInstructionMutation = () => {
  return useMutation(
    async (variables: UpdateShippingInstructionMutationVariables) => {
      const shippingInstruction = (await API.graphql(
        graphqlOperation(updateShippingInstruction, variables),
      )) as GraphQLResult<UpdateShippingInstructionMutation>;
      return shippingInstruction.data?.updateShippingInstruction;
    },
  );
};

export const useDeleteShippingInstructionMutation = () => {
  return useMutation(
    async (variables: DeleteShippingInstructionMutationVariables) => {
      const shippingInstruction = (await API.graphql(
        graphqlOperation(deleteShippingInstruction, variables),
      )) as GraphQLResult<DeleteShippingInstructionMutation>;
      return shippingInstruction.data?.deleteShippingInstruction;
    },
  );
};
