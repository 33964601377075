import {Box, Container} from '@mui/material';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {AdminScreen} from './Admin/Admin';
import {CompleteResetPasswordScreen} from './Authentication/CompleteResetPassword';
import {ForgotPasswordScreen} from './Authentication/ForgotPassword';
import {RegisterInvitedUserScreen} from './Authentication/RegisterInvitedUser';
import {ResetPasswordScreen} from './Authentication/ResetPassword';
import {SignInScreen} from './Authentication/SignIn';
import {SignUpScreen} from './Authentication/SignUp';
import {SignUpForwarderCompanyInfoScreen} from './Authentication/SignUpForwarderCompanyInfo';
import {SignUpShipperCompanyInfoScreen} from './Authentication/SignUpShipperCompanyInfo';
import {SignUpVerificationScreen} from './Authentication/SignUpVerification';
import {ErrorScreen} from './Error';
import {CreateEstimateScreen} from './Forwarder/CreateEstimate';
import {CreateEstimateConfirmationScreen} from './Forwarder/CreateEstimateConfirmation';
import {CreateFhdScreen} from './Forwarder/CreateFhd';
import {CreateFobScreen} from './Forwarder/CreateFob';
import {EstimateListScreen} from './Forwarder/EstimateList';
import {FobFhdListScreen} from './Forwarder/FobFhdList';
import {ReservationListScreen as ForwarderReservationListScreen} from './Forwarder/ReservationList';
import {UpdateEstimateScreen} from './Forwarder/UpdateEstimate';
import {UpdateFhdScreen} from './Forwarder/UpdateFhd';
import {UpdateFobScreen} from './Forwarder/UpdateFob';
import {ForwarderHomeScreen} from './Home/ForwarderHome';
import {PendingScreen} from './Home/Pending';
import {ShipperHomeScreen} from './Home/ShipperHome';
import {EmployeesScreen} from './Manager/Employees';
import {ForwarderCompanyInfoScreen} from './Manager/ForwarderCompanyInfo';
import {ShipperCompanyInfoScreen} from './Manager/ShipperCompanyInfo';
import {NotFoundErrorScreen} from './NotFoundError';
import {ForwarderCompanyProfileScreen} from './Profile/ForwarderCompanyProfile';
import {ForwarderProfileScreen} from './Profile/ForwarderProfile';
import {ShipperCompanyProfileScreen} from './Profile/ShipperCompanyProfile';
import {ShipperProfileScreen} from './Profile/ShipperProfile';
import {CreateSIConfirmationScreen} from './Shipper/CreateSIConfirmation';
import {CreateSIJpnArrivalScreen} from './Shipper/CreateSIJpnArrival';
import {CreateSIJpnDepartureScreen} from './Shipper/CreateSIJpnDeparture';
import {CreateSIOthersScreen} from './Shipper/CreateSIOthers';
import {ReservationListScreen as ShipperReservationListScreen} from './Shipper/ReservationList';
import {ReserveConfirmationScreen} from './Shipper/ReserveConfirmation';
import {SearchEstimateScreen} from './Shipper/SearchEstimate';
import {SearchEstimateResultCompareScreen} from './Shipper/SearchEstimateResultCompare';
import {SearchEstimateResultsScreen} from './Shipper/SearchEstimateResults';
import {AuthHeader} from '../components/AuthHeader';
import {Header} from '../components/Header';
import {useIsMobile} from '../hooks/useIsMobile';
import {useOAuthUserState} from '../providers/AuthProvider';
import {useForwarderUser} from '../queries/forwarderUser';
import {useInvitedUser} from '../queries/invitedUser';
import {useShipperUser} from '../queries/shipperUser';
import ScrollToTop from '../utils/scrollToTop';

export interface RootStackParamList {
  ResetPasswordScreen: {email: string};
}

export const Screens = () => {
  const userState = useOAuthUserState();
  const email = userState.user?.attributes.email ?? '';
  const sub = userState.user?.attributes.sub ?? '';
  const {data: forwarder, isLoading: isLoadingForwarder} =
    useForwarderUser(sub);
  const {data: shipper, isLoading: isLoadingShipper} = useShipperUser(sub);
  const {data: invitedUser, isLoading: isLoadingInvited} =
    useInvitedUser(email);
  const isMobile = useIsMobile();
  const companyType = localStorage.getItem('companyType') ?? '';

  const DefaultScreens = (
    <>
      <Route path="/error" element={<ErrorScreen />} />
      <Route path="*" element={<NotFoundErrorScreen />} />
    </>
  );

  if (
    userState.state === 'loading' ||
    isLoadingForwarder ||
    isLoadingShipper ||
    isLoadingInvited
  ) {
    return null;
  }

  const containerSx = {
    padding: '112px 36px 36px !important',
    minHeight: 'calc(100vh - 22px)',
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      {isMobile ? (
        <></>
      ) : userState.user ? (
        <Box
          sx={{
            background: '#f2f2f2',
            minHeight: '100vh',
            minWidth: '100vw',
          }}>
          {userState.user.isForwarders ? (
            <>
              <Header />
              <Routes>
                <Route
                  index
                  element={
                    <Container sx={containerSx}>
                      <ForwarderHomeScreen />
                    </Container>
                  }
                />
                <Route
                  path="users"
                  element={
                    <Container sx={containerSx}>
                      <EmployeesScreen />
                    </Container>
                  }
                />
                <Route
                  path="/companyInfo"
                  element={
                    <Container sx={containerSx}>
                      <ForwarderCompanyInfoScreen />
                    </Container>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <Container sx={containerSx}>
                      <ForwarderProfileScreen />
                    </Container>
                  }
                />
                <Route
                  path="/fobFhd"
                  element={
                    <Container sx={containerSx}>
                      <FobFhdListScreen />
                    </Container>
                  }
                />
                <Route
                  path="/createEstimate"
                  element={
                    <Container sx={containerSx}>
                      <CreateEstimateScreen />
                    </Container>
                  }
                />
                <Route
                  path="/createEstimateConfirmation"
                  element={
                    <Container sx={containerSx}>
                      <CreateEstimateConfirmationScreen />
                    </Container>
                  }
                />
                <Route
                  path="/createFob"
                  element={
                    <Container sx={containerSx}>
                      <CreateFobScreen />
                    </Container>
                  }
                />
                <Route
                  path="/createFhd"
                  element={
                    <Container sx={containerSx}>
                      <CreateFhdScreen />
                    </Container>
                  }
                />
                <Route
                  path="/updateFob"
                  element={
                    <Container sx={containerSx}>
                      <UpdateFobScreen />
                    </Container>
                  }
                />
                <Route
                  path="/updateFhd"
                  element={
                    <Container sx={containerSx}>
                      <UpdateFhdScreen />
                    </Container>
                  }
                />
                <Route
                  path="/estimateList"
                  element={
                    <Container sx={containerSx}>
                      <EstimateListScreen />
                    </Container>
                  }
                />
                <Route
                  path="/updateEstimate"
                  element={
                    <Container sx={containerSx}>
                      <UpdateEstimateScreen />
                    </Container>
                  }
                />
                <Route
                  path="/forwarderCompanyProfile"
                  element={
                    <Container sx={containerSx}>
                      <ForwarderCompanyProfileScreen />
                    </Container>
                  }
                />
                <Route
                  path="/shipperCompanyProfile"
                  element={
                    <Container sx={containerSx}>
                      <ShipperCompanyProfileScreen />
                    </Container>
                  }
                />
                <Route
                  path="/reservationList"
                  element={
                    <Container sx={containerSx}>
                      <ForwarderReservationListScreen />
                    </Container>
                  }
                />
                {userState.user.isAdmins && (
                  <>
                    <Route index element={<AdminScreen />} />
                    <Route path="admin/*" element={<AdminScreen />} />
                  </>
                )}
                {DefaultScreens}
              </Routes>
            </>
          ) : userState.user.isShippers ? (
            <>
              <Header />
              <Routes>
                <Route
                  index
                  element={
                    <Container sx={containerSx}>
                      <ShipperHomeScreen />
                    </Container>
                  }
                />
                <Route
                  path="users"
                  element={
                    <Container sx={containerSx}>
                      <EmployeesScreen />
                    </Container>
                  }
                />
                <Route
                  path="/companyInfo"
                  element={
                    <Container sx={containerSx}>
                      <ShipperCompanyInfoScreen />
                    </Container>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <Container sx={containerSx}>
                      <ShipperProfileScreen />
                    </Container>
                  }
                />
                <Route
                  path="/createSIJpnArrival"
                  element={
                    <Container sx={containerSx}>
                      <CreateSIJpnArrivalScreen />
                    </Container>
                  }
                />
                <Route
                  path="/createSIJpnDeparture"
                  element={
                    <Container sx={containerSx}>
                      <CreateSIJpnDepartureScreen />
                    </Container>
                  }
                />
                <Route
                  path="/createSIOthers"
                  element={
                    <Container sx={containerSx}>
                      <CreateSIOthersScreen />
                    </Container>
                  }
                />
                <Route
                  path="/createSIConfirmation"
                  element={
                    <Container sx={containerSx}>
                      <CreateSIConfirmationScreen />
                    </Container>
                  }
                />
                <Route
                  path="/reserveConfirmation"
                  element={
                    <Container sx={containerSx}>
                      <ReserveConfirmationScreen />
                    </Container>
                  }
                />
                <Route
                  path="/searchEstimate"
                  element={
                    <Container sx={containerSx}>
                      <SearchEstimateScreen />
                    </Container>
                  }
                />
                <Route
                  path="/searchEstimate/results"
                  element={
                    <Container sx={containerSx}>
                      <SearchEstimateResultsScreen />
                    </Container>
                  }
                />
                <Route
                  path="/searchEstimate/results/compare"
                  element={
                    <Container sx={containerSx}>
                      <SearchEstimateResultCompareScreen />
                    </Container>
                  }
                />
                <Route
                  path="/forwarderCompanyProfile"
                  element={
                    <Container sx={containerSx}>
                      <ForwarderCompanyProfileScreen />
                    </Container>
                  }
                />
                <Route
                  path="/shipperCompanyProfile"
                  element={
                    <Container sx={containerSx}>
                      <ShipperCompanyProfileScreen />
                    </Container>
                  }
                />
                <Route
                  path="/reservationList"
                  element={
                    <Container sx={containerSx}>
                      <ShipperReservationListScreen />
                    </Container>
                  }
                />
                {userState.user.isAdmins && (
                  <>
                    <Route index element={<AdminScreen />} />
                    <Route path="admin/*" element={<AdminScreen />} />
                  </>
                )}
                {DefaultScreens}
              </Routes>
            </>
          ) : forwarder?.company || shipper?.company ? (
            <>
              <Header />
              <Container
                sx={{
                  padding: '112px 36px 36px !important',
                  minHeight: 'calc(100vh - 22px)',
                }}>
                <Routes>
                  <Route index element={<PendingScreen />} />
                </Routes>
              </Container>
            </>
          ) : invitedUser ? (
            <Routes>
              <Route index element={<RegisterInvitedUserScreen />} />
            </Routes>
          ) : (
            <Routes>
              <Route
                index={companyType === 'Forwarder'}
                element={<SignUpForwarderCompanyInfoScreen />}
              />
              <Route
                index={companyType === 'Shipper'}
                element={<SignUpShipperCompanyInfoScreen />}
              />
              {DefaultScreens}
            </Routes>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            background: '#f2f2f2',
            minHeight: '100vh',
            minWidth: '100vw',
          }}>
          <AuthHeader />
          <Routes>
            <Route index element={<SignInScreen />} />
            <Route
              path="/completeResetPassword"
              element={<CompleteResetPasswordScreen />}
            />
            <Route path="/forgotPassword" element={<ForgotPasswordScreen />} />
            <Route path="/resetPassword" element={<ResetPasswordScreen />} />
            <Route path="/signIn" element={<SignInScreen />} />
            <Route path="/signUp" element={<SignUpScreen />} />
            <Route
              path="/signUpVerification"
              element={<SignUpVerificationScreen />}
            />
            {DefaultScreens}
          </Routes>
        </Box>
      )}
    </BrowserRouter>
  );
};
