import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery} from 'react-query';
import {
  GetShippingFeeQueryVariables,
  GetShippingFeeQuery,
  CreateShippingFeeMutationVariables,
  CreateShippingFeeMutation,
  UpdateShippingFeeMutationVariables,
  UpdateShippingFeeMutation,
  DeleteShippingFeeMutationVariables,
  DeleteShippingFeeMutation,
  ListShippingFeesQueryVariables,
  ListShippingFeesQuery,
} from '../API';
import {
  createShippingFee,
  deleteShippingFee,
  updateShippingFee,
} from '../graphql/mutations';
import {getShippingFee, listShippingFees} from '../graphql/queries';

export const useShippingFees = () => {
  return useQuery(['shippingFees'], async () => {
    const variables: ListShippingFeesQueryVariables = {
      limit: 10000,
    };
    const result = (await API.graphql(
      graphqlOperation(listShippingFees, variables),
    )) as GraphQLResult<ListShippingFeesQuery>;
    return result?.data?.listShippingFees;
  });
};

export const useShippingFee = (id: string) => {
  return useQuery(
    ['shippingFee', id],
    async () => {
      const variable: GetShippingFeeQueryVariables = {
        id,
      };
      const result = (await API.graphql(
        graphqlOperation(getShippingFee, variable),
      )) as GraphQLResult<GetShippingFeeQuery>;
      return result.data?.getShippingFee;
    },
    {
      enabled: !!id,
    },
  );
};

export const useCreateShippingFeeMutation = () => {
  return useMutation(async (variables: CreateShippingFeeMutationVariables) => {
    const shippingFee = (await API.graphql(
      graphqlOperation(createShippingFee, variables),
    )) as GraphQLResult<CreateShippingFeeMutation>;
    return shippingFee.data?.createShippingFee;
  });
};

export const useUpdateShippingFeeMutation = () => {
  return useMutation(async (variables: UpdateShippingFeeMutationVariables) => {
    const shippingFee = (await API.graphql(
      graphqlOperation(updateShippingFee, variables),
    )) as GraphQLResult<UpdateShippingFeeMutation>;
    return shippingFee.data?.updateShippingFee;
  });
};

export const useDeleteShippingFeeMutation = () => {
  return useMutation(async (variables: DeleteShippingFeeMutationVariables) => {
    const shippingFee = (await API.graphql(
      graphqlOperation(deleteShippingFee, variables),
    )) as GraphQLResult<DeleteShippingFeeMutation>;
    return shippingFee.data?.deleteShippingFee;
  });
};
