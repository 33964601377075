import {Stack, Box, Typography, Dialog} from '@mui/material';
import dayjs from 'dayjs';
import {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {News} from '../../API';
import {useNews} from '../../queries/news';
import {isNonNull} from '../../utils/isEmpty';

export const NewsList = () => {
  const {t} = useTranslation();
  const {data: newsList} = useNews();
  const [selectedNews, setSelectedNews] = useState<null | News>(null);

  useEffect(() => {
    newsList?.items.sort((a, b) => {
      if (a && b) {
        return dayjs(a.newsDate).isBefore(dayjs(b.newsDate)) ? 1 : -1;
      } else {
        return -1;
      }
    });
  }, [newsList]);

  useEffect(() => {
    document.title = `${t('newsFlash')} | MAST`;
  }, [t]);

  return (
    <>
      <Stack flexDirection="row" flexWrap="wrap" sx={{gap: 4}}>
        {newsList?.items.filter(isNonNull).map(news => {
          return (
            <Box
              sx={{
                width: '254px',
                height: '190.5px',
                position: 'relative',
              }}
              key={news.id}
              onClick={() => setSelectedNews(news)}>
              <Box
                component="img"
                sx={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '12px',
                }}
                src={news.mediaUrl ?? ''}
              />
              <Box
                sx={{
                  position: 'absolute',
                  borderBottomRightRadius: '12px',
                  borderBottomLeftRadius: '12px',
                  width: '100%',
                  height: '57px',
                  bottom: '0px',
                  background: 'rgba(51,51,51,0.7)',
                }}>
                <Box sx={{marginX: '12px'}}>
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                    mt={1}
                    color="white">
                    {news.title}
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: '4px',
                      fontSize: '12px',
                      fontWeight: '300',
                      lineHeight: '15.6px',
                    }}
                    color="white">
                    {dayjs(news.newsDate).format('YYYY/M/D')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Stack>
      {selectedNews ? (
        <Dialog
          open={Boolean(selectedNews)}
          sx={{
            '& .MuiDialog-paper': {
              maxWidth: '654px',
              borderRadius: '12px',
            },
          }}
          onClose={() => setSelectedNews(null)}>
          <Box
            component="img"
            sx={{
              width: '100%',
              height: '354px',
              borderTopRightRadius: '12px',
              borderTopLeftRadius: '12px',
            }}
            src={selectedNews.mediaUrl ?? ''}
          />
          <Stack spacing={2} sx={{padding: '24px'}}>
            <Typography variant="h2" mt={2}>
              {selectedNews.title}
            </Typography>
            <Typography mt={1}>
              {dayjs(selectedNews.newsDate).format('YYYY/M/D')}
            </Typography>
            <Typography>{selectedNews.newsContent}</Typography>
          </Stack>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
};
