import {TextField, Stack, Typography, Link, Grid, Button} from '@mui/material';
import * as Sentry from '@sentry/react';
import {Auth} from 'aws-amplify';
import {useEffect, useState} from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {ShipperRole} from '../../API';
import Inner from '../../layouts/Inner';
import {useCreateForwarderUserMutation} from '../../queries/forwarderUser';
import {useCreateShipperUserMutation} from '../../queries/shipperUser';

interface FormInput {
  code: string;
}

export const SignUpVerificationScreen = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const state = location.state as {
    email: string;
    password: string;
  };
  const firstName = localStorage.getItem('firstName') ?? '';
  const lastName = localStorage.getItem('lastName') ?? '';
  const companyType = localStorage.getItem('companyType') ?? '';
  const workLocation = localStorage.getItem('workLocation') ?? '';
  const position = localStorage.getItem('position') ?? '';
  const division = localStorage.getItem('division') ?? '';
  const phoneNumber = localStorage.getItem('phoneNumber') ?? '';

  const navigate = useNavigate();
  const [codeMismatchException, setCodeMismatchException] = useState(false);
  const {mutate: createShipperUserMutation} = useCreateShipperUserMutation();
  const {mutate: createForwarderUserMutation} =
    useCreateForwarderUserMutation();

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormInput>({
    mode: 'all',
  });

  const onSubmit: SubmitHandler<FormInput> = async data => {
    await Auth.confirmSignUp(state.email, data.code)
      .then(async () => {
        await Auth.signIn(state.email, state.password).then(
          async (data: {
            attributes: {
              sub: string;
              email: string;
            };
          }) => {
            if (companyType === 'Shipper') {
              createShipperUserMutation(
                {
                  input: {
                    id: data.attributes.sub,
                    email: data.attributes.email,
                    firstName: firstName,
                    lastName: lastName,
                    workLocation: workLocation,
                    position: position,
                    division: division,
                    phoneNumber: phoneNumber,
                    area: '',
                    role: ShipperRole.OTHER,
                  },
                },
                {
                  onError: async err => {
                    Sentry.captureException(err);
                    return;
                  },
                },
              );
            } else if (companyType === 'Forwarder') {
              createForwarderUserMutation(
                {
                  input: {
                    id: data.attributes.sub,
                    email: data.attributes.email,
                    firstName: firstName,
                    lastName: lastName,
                    workLocation: workLocation,
                    position: position,
                    division: division,
                    phoneNumber: phoneNumber,
                  },
                },
                {
                  onError: async err => {
                    Sentry.captureException(err);
                    return;
                  },
                },
              );
            } else {
              Sentry.captureException(
                'companyType is not Shipper or Forwarder',
              );
            }
          },
        );
      })
      .catch(err => {
        Sentry.captureException(err);
        setCodeMismatchException(true);
      })
      .finally(() => {
        navigate('/');
      });
  };

  useEffect(() => {
    document.title = `${t('createAccount')} | MAST`;
  }, [t]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      sx={{minHeight: '100vh'}}>
      <Typography mb={3} variant="h1">
        MAST
      </Typography>
      <Inner>
        <Typography align="center" sx={{fontSize: '20px'}}>
          {t('verificationSent')}
        </Typography>
        <Typography align="center" sx={{mt: '24px'}}>
          {t('verificationSentToEmailAddress')}
          <br />
          {t('enterReceivedVerification')}
        </Typography>
        <Stack
          direction="row"
          spacing={3}
          alignItems="center"
          justifyContent="center"
          sx={{mt: '30px'}}>
          <Typography align="center">{t('verificationCode')}</Typography>
          <Stack
            sx={{
              width: '452px',
              display: 'flex',
              flexDirection: 'column',
            }}>
            <TextField
              fullWidth
              error={!!(errors.code || codeMismatchException)}
              {...register('code', {
                required: true,
                pattern: /^(?=.*?\d)[\d]{1,}$/,
              })}
            />
            {errors.code || codeMismatchException ? (
              <Typography variant="error">{t('verificationFailed')}</Typography>
            ) : null}
          </Stack>
        </Stack>
        <Stack
          sx={{
            width: '606px',
            display: 'flex',
            justifyContent: 'right',
          }}>
          <Link
            align="right"
            sx={{mt: '5px', cursor: 'pointer'}}
            onClick={() => {
              Auth.resendSignUp(state.email);
            }}>
            <Typography variant="body2" color="primary">
              {t('resendVerification')}
            </Typography>
          </Link>
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          sx={{width: '658px', mt: '24px'}}>
          <Button
            size="large"
            variant="primary"
            type="submit"
            disabled={!!errors.code}
            onClick={handleSubmit(onSubmit)}>
            {t('next')}
          </Button>
        </Stack>
      </Inner>
    </Grid>
  );
};
