import i18n from '../i18n/i18n';

export const getLocalizedLeadTime = (leadTime: number | undefined) => {
  if (leadTime === 0) {
    return i18n.t('zeroDay');
  } else if (leadTime === 1) {
    return i18n.t('oneDay');
  } else if (leadTime === 2) {
    return i18n.t('twoDays');
  } else if (leadTime === 3) {
    return i18n.t('threeDays');
  } else if (leadTime === 4) {
    return i18n.t('fourDays');
  } else if (leadTime === 5) {
    return i18n.t('fiveDays');
  } else if (leadTime === 6) {
    return i18n.t('sixDays');
  } else if (leadTime === 7) {
    return i18n.t('sevenDays');
  } else {
    return i18n.t('zeroDay');
  }
};
