import {Stack, Typography} from '@mui/material';
import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LabelValue} from './LabelValue';
import {StyledTable} from './StyledTable';
import {Fob} from '../API';
import {useFirstFobs} from '../queries/fob';
import {calculateCustomFee} from '../utils/calculateCustomFee';
import {calculateItemFee} from '../utils/calculateItemFee';
import {isNonNull} from '../utils/isEmpty';
import {roundToDecimal} from '../utils/roundToDecimal';

interface FobDetailProps {
  companyId: string;
  departure: string;
  totalCw: number;
  totalGw: number;
  unit: number;
}

export const FobDetail: FC<FobDetailProps> = props => {
  const {t} = useTranslation();
  const {data: fob} = useFirstFobs(props.companyId, props.departure);

  const fobFee = roundToDecimal(
    calculateCustomFee(
      fob?.customItems?.items?.filter(isNonNull) ?? [],
      props.totalCw,
      props.totalGw,
      props.unit,
    ),
    0,
  );

  const [sortedFob, setSortedFob] = useState<Fob | null>(null);

  useEffect(() => {
    if (fob?.customItems?.items) {
      const sortedItems = [...fob.customItems.items].sort(
        (a, b) =>
          new Date(a?.createdAt ?? '').getTime() -
          new Date(b?.createdAt ?? '').getTime(),
      );
      const sortedFob = {
        ...fob,
        customItems: {
          ...fob.customItems,
          items: sortedItems,
        },
      } as Fob;
      setSortedFob(sortedFob);
    } else {
      setSortedFob(null);
    }
  }, [fob]);
  return (
    <Stack spacing={5}>
      <Stack spacing={3}>
        <Typography variant="h2">{t('exportCharge')}</Typography>
        <StyledTable>
          <tr>
            <th>{t('ItemName')}</th>
            <th>{t('currency')}</th>
            <th>{t('unitPrice')}</th>
            <th>{t('unit')}</th>
            <th>{t('minimum')}</th>
            <th>{t('maximum')}</th>
            <th>{t('estimatedAmount')}</th>
            <th>{t('exchangeRate')}</th>
            <th>{t('yenCurrency')}</th>
            <th>{t('remark')}</th>
          </tr>
          <>
            {sortedFob?.customItems?.items.filter(isNonNull).map(item => (
              <tr>
                <th>{`${item.title}${item.includeTax ? '*' : ''}`}</th>
                <td>{item.currency?.code}</td>
                <td>{item.unitPrice.toLocaleString()}</td>
                <td>{item.unitType}</td>
                <td>{item.minimum?.toLocaleString()}</td>
                <td>{item.maximum?.toLocaleString()}</td>
                <td>
                  {roundToDecimal(
                    calculateItemFee(
                      item.unitPrice,
                      props.totalCw,
                      props.totalGw,
                      props.unit,
                      item.calculationMethod,
                      item.minimum,
                      item.maximum,
                    ),
                    0,
                  ).toLocaleString()}
                </td>
                <td>
                  {roundToDecimal(
                    item.currency?.exchangeRateJPY ?? 0,
                    2,
                  ).toLocaleString()}
                </td>
                <td>
                  {roundToDecimal(
                    (item.currency?.exchangeRateJPY ?? 0) *
                      calculateItemFee(
                        item.unitPrice,
                        props.totalCw,
                        props.totalGw,
                        props.unit,
                        item.calculationMethod,
                        item.minimum,
                        item.maximum,
                      ),
                    0,
                  ).toLocaleString()}
                </td>
                <td>{item.remark}</td>
              </tr>
            ))}
          </>
          <tr>
            <th></th>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <th>{t('subtotal')}</th>
            <td>{fobFee.toLocaleString()}</td>
            <td></td>
          </tr>
        </StyledTable>
        <LabelValue title={t('remark')} value={fob?.remark} />
      </Stack>
    </Stack>
  );
};
