import {Checkbox, FormControlLabel, TextField, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {LabelTextField} from './LabelTextField';

export type OptionsInput = {
  cargoInsurance?: boolean | null;
  cargoInsuranceDetail?: string | null;
  packed?: boolean | null;
  packedDetail?: string | null;
  collected?: boolean | null;
  collectedDetail?: string | null;
  delivery?: boolean | null;
  deliveryDetail?: string | null;
  request?: string | null;
};
type CreateSIOptionsProps = {
  options: OptionsInput | null;
  handleOptionsChange: (newOptions: OptionsInput) => void;
};

export const CreateSIOptions: React.FC<CreateSIOptionsProps> = ({
  options,
  handleOptionsChange,
}) => {
  const {t} = useTranslation();
  return (
    <>
      <Typography variant="h3">{t('options')}</Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={options?.cargoInsurance || false}
            onChange={() => {
              handleOptionsChange({
                ...options,
                cargoInsurance: !options?.cargoInsurance,
              });
            }}
          />
        }
        label={t('cargoInsurance')}
      />
      <TextField
        size="small"
        id="input-textfield"
        variant="outlined"
        fullWidth
        placeholder={t('invoiceValue')}
        disabled={!options?.cargoInsurance}
        value={options?.cargoInsuranceDetail ?? ''}
        onChange={event => {
          handleOptionsChange({
            ...options,
            cargoInsuranceDetail: event.target.value,
          });
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={options?.packed || false}
            onChange={() => {
              handleOptionsChange({
                ...options,
                packed: !options?.packed,
              });
            }}
          />
        }
        label={t('packingWork')}
      />
      <TextField
        size="small"
        id="input-textfield"
        variant="outlined"
        fullWidth
        placeholder={t('packingSpecifications')}
        disabled={!options?.packed}
        value={options?.packedDetail ?? ''}
        onChange={event => {
          handleOptionsChange({
            ...options,
            packedDetail: event.target.value,
          });
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={options?.collected || false}
            onChange={() => {
              handleOptionsChange({
                ...options,
                collected: !options?.collected,
              });
            }}
          />
        }
        label={t('departurePickupLocation')}
      />
      <TextField
        size="small"
        id="input-textfield"
        variant="outlined"
        fullWidth
        placeholder={t('toAddressPICTelephone')}
        disabled={!options?.collected}
        value={options?.collectedDetail ?? ''}
        onChange={event => {
          handleOptionsChange({
            ...options,
            collectedDetail: event.target.value,
          });
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={options?.delivery || false}
            onChange={() => {
              handleOptionsChange({
                ...options,
                delivery: !options?.delivery,
              });
            }}
          />
        }
        label={t('arrivalDeliveryLocation')}
      />
      <TextField
        size="small"
        id="input-textfield"
        variant="outlined"
        fullWidth
        placeholder={t('fromAddressPICTelephone')}
        disabled={!options?.delivery}
        value={options?.deliveryDetail ?? ''}
        onChange={event => {
          handleOptionsChange({
            ...options,
            deliveryDetail: event.target.value,
          });
        }}
      />
      <LabelTextField
        title={t('otherRequests')}
        value={options?.request}
        onChange={event => {
          handleOptionsChange({
            ...options,
            request: event.target.value,
          });
        }}
        multiline={true}
        minRows={3}
        sx={{width: '656px'}}
      />
    </>
  );
};
