import {Box, Typography} from '@mui/material';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import Inner from '../../layouts/Inner';

export const PendingScreen = () => {
  const {t} = useTranslation();
  useEffect(() => {
    document.title = `${t('registrationStarted')} | MAST`;
  }, [t]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Typography mb={3} variant="h1">
        MAST
      </Typography>
      <Inner>
        <Typography variant="h2" align="center">
          {t('registrationStarted')}
        </Typography>
        <Typography variant="body2" textAlign="center" mt="16px">
          {t('notificationWillBeSent')}
          <br />
          {t('wait')}
        </Typography>
      </Inner>
    </Box>
  );
};
