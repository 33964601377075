import {Stack, Box, Typography, Button} from '@mui/material';
import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {DepartureArrivalAirports} from './DepartureArrivalAirports';
import {LabelValue} from './LabelValue';
import {StatusChip} from './StatusChip';
import {Status, TransportType} from '../API';
import {useOAuthUserState} from '../providers/AuthProvider';
import {useForwarderCompany} from '../queries/forwarderCompany';
import {useReservation} from '../queries/reservation';
import {useShipperCompany} from '../queries/shipperCompany';
import {useShippingFee} from '../queries/shippingFee';
import {useShippingFhdFee} from '../queries/shippingFhdFee';
import {useShippingFobFee} from '../queries/shippingFobFee';
import {Review} from '../screens/Profile/Review';
import {roundUpToNearestHalf} from '../screens/Shipper/SearchEstimateResultCompare';
import {roundToDecimal} from '../utils/roundToDecimal';

type ReservationSummaryProps = {
  id: string;
  setIsConfirmOpen?: (id: string, open: boolean) => void;
  setIsDetailOpen: (id: string, open: boolean) => void;
  rejectReservation?: (id: string) => void;
  cancelReservation?: (id: string) => void;
};

export const ReservationSummary: FC<ReservationSummaryProps> = props => {
  const {t} = useTranslation();
  const userState = useOAuthUserState();
  const navigate = useNavigate();

  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const {data: reservation} = useReservation(props.id);
  const {data: shippingFee} = useShippingFee(
    reservation?.shippingInstruction?.shippingFee.id ?? '',
  );
  const {data: shippingFobFee} = useShippingFobFee(
    reservation?.shippingInstruction?.shippingInstructionFobId ?? '',
  );
  const {data: shippingFhdFee} = useShippingFhdFee(
    reservation?.shippingInstruction?.shippingInstructionFhdId ?? '',
  );

  const reviewId = userState.user?.isForwarders
    ? reservation?.shipperCompanyId
    : reservation?.forwarderCompanyId;

  //totalCwが必要
  const totalVolumWeight =
    reservation?.shippingInstruction?.shippingItems?.reduce(
      (sum, currentItem) => {
        return sum + (currentItem?.volumeWeight || 0);
      },
      0,
    );
  const totalGw = reservation?.shippingInstruction?.shippingItems?.reduce(
    (sum, currentItem) => {
      return sum + (currentItem?.gw || 0);
    },
    0,
  );
  const totalCw = roundUpToNearestHalf(
    Math.max(totalGw ?? 0, totalVolumWeight ?? 0),
  );

  //航空運賃の合計
  const basePrice = shippingFee?.price ?? 0;
  const airfreightPrice = Math.max(
    basePrice * totalCw,
    shippingFee?.minimum ?? 0,
  );
  const airfreightPriceYen = roundToDecimal(
    airfreightPrice * (shippingFee?.currency?.exchangeRateJPY ?? 0),
    0,
  );

  const fsc = shippingFee?.fsc ?? 0;
  const fscPrice = fsc * totalCw;
  const fscPriceYen = roundToDecimal(
    fscPrice * (shippingFee?.currency?.exchangeRateJPY ?? 0),
    0,
  );
  const totalCustomFee =
    shippingFee?.customFees?.items.reduce((total, item) => {
      return (
        total +
        roundToDecimal(
          (item?.currency?.exchangeRateJPY ?? 0) * (item?.fee ?? 0),
          0,
        )
      );
    }, 0) ?? 0;

  const totalAirFee = airfreightPriceYen + fscPriceYen + totalCustomFee;

  //輸出料金の合計
  const totalFobFee =
    shippingFobFee?.customFees?.items.reduce((total, item) => {
      return (
        total +
        roundToDecimal(
          (item?.currency?.exchangeRateJPY ?? 0) * (item?.fee ?? 0),
          0,
        )
      );
    }, 0) ?? 0;

  //輸入料金の合計
  const totalFhdFee =
    shippingFhdFee?.customFees?.items.reduce((total, item) => {
      return (
        total +
        roundToDecimal(
          (item?.currency?.exchangeRateJPY ?? 0) * (item?.fee ?? 0),
          0,
        )
      );
    }, 0) ?? 0;

  //合計
  const totalFee =
    reservation?.shippingInstruction?.transportType === TransportType.TRILATERAL
      ? totalAirFee + totalFobFee + totalFhdFee
      : reservation?.shippingInstruction?.transportType ===
        TransportType.AIR_EXPORT
      ? totalAirFee + totalFobFee
      : totalAirFee;

  //航空運賃の消費税
  const totalCustomFeeTax =
    shippingFee?.customFees?.items.reduce((total, item) => {
      return (
        total +
        roundToDecimal(
          ((item?.taxRate ?? 0) / 100) *
            (item?.currency?.exchangeRateJPY ?? 0) *
            (item?.fee ?? 0),
          0,
        )
      );
    }, 0) ?? 0;

  //輸出料金の消費税
  const totalFobFeeTax =
    shippingFobFee?.customFees?.items.reduce((total, item) => {
      return (
        total +
        roundToDecimal(
          ((item?.taxRate ?? 0) / 100) *
            (item?.currency?.exchangeRateJPY ?? 0) *
            (item?.fee ?? 0),
          0,
        )
      );
    }, 0) ?? 0;

  //輸入料金の消費税
  const totalFhdFeeTax =
    shippingFhdFee?.customFees?.items.reduce((total, item) => {
      return (
        total +
        roundToDecimal(
          ((item?.taxRate ?? 0) / 100) *
            (item?.currency?.exchangeRateJPY ?? 0) *
            (item?.fee ?? 0),
          0,
        )
      );
    }, 0) ?? 0;

  //合計消費税
  const totalTax =
    reservation?.shippingInstruction?.transportType === TransportType.TRILATERAL
      ? totalCustomFeeTax + totalFobFeeTax + totalFhdFeeTax
      : reservation?.shippingInstruction?.transportType ===
        TransportType.AIR_EXPORT
      ? totalCustomFeeTax + totalFobFeeTax
      : totalCustomFeeTax;

  //総額
  const totalFeeIncludeTax = totalFee + totalTax;
  const {data: forwarderCompany} = useForwarderCompany(
    reservation?.forwarderCompanyId || '',
  );

  const {data: shipperCompany} = useShipperCompany(
    reservation?.shipperCompanyId || '',
  );

  const companyName = userState.user?.isForwarders
    ? shipperCompany?.name
    : forwarderCompany?.name;

  return (
    <>
      <Stack
        sx={{
          width: '100%',
          paddingX: '20px',
          paddingY: '20px',
          background: 'white',
          borderRadius: '12px',
          color: '#333333',
        }}
        spacing={3}>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row">
          <Box alignItems="center" display="flex" flexDirection="row">
            <StatusChip status={reservation?.status} />
            <Typography
              ml={1}
              variant="h5"
              onClick={() => {
                navigate(
                  userState.user?.isForwarders
                    ? '/shipperCompanyProfile'
                    : '/forwarderCompanyProfile',
                  {
                    state: {
                      companyId: userState.user?.isForwarders
                        ? shipperCompany?.id
                        : forwarderCompany?.id,
                    },
                  },
                );
              }}
              style={{cursor: 'pointer'}}>
              {companyName}
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: reservation?.shippingInstruction?.directFlight
                ? '#DE6E54'
                : '#33BDD4',
              paddingX: '10px',
              paddingY: '5px',
              borderRadius: '6px',
              color: '#333333',
            }}>
            <Typography variant="h6" color="white">
              {reservation?.shippingInstruction?.directFlight
                ? t('directFlight')
                : t('connectingFlight')}
            </Typography>
          </Box>
        </Stack>
        <Stack flexDirection="column" spacing={2}>
          <DepartureArrivalAirports
            departure={reservation?.shippingInstruction?.departure}
            arrival={reservation?.shippingInstruction?.arrival}
          />
          <LabelValue
            title={`${t('airline')}:`}
            titleWeight="600"
            value={reservation?.shippingInstruction?.airline}
            row
            rowValueLeftMaring="16px"
          />
          <LabelValue
            title={`${t('etd')}:`}
            titleWeight="600"
            value={reservation?.shippingInstruction?.flightDate}
            row
            rowValueLeftMaring="16px"
          />
          <LabelValue
            title={`${t('fwdWarehouse') + ' ' + t('cut')}:`}
            titleWeight="600"
            value={reservation?.shippingInstruction?.yardCutDate}
            row
            rowValueLeftMaring="16px"
          />
          <LabelValue
            title={`${t('airlineWarehouseDirectCut')}:`}
            titleWeight="600"
            value={reservation?.shippingInstruction?.carrierShedCutDate}
            row
            rowValueLeftMaring="16px"
          />
          {reservation?.status === Status.APPROVED && (
            <LabelValue
              title={`${t('hawb')}:`}
              titleWeight="600"
              value={reservation?.hawb}
              row
              rowValueLeftMaring="16px"
            />
          )}
        </Stack>
        <Stack alignItems="end">
          <Typography variant="h1" color="#2196F3">
            {`¥${roundToDecimal(totalFeeIncludeTax, 0).toLocaleString() ?? 0}`}
          </Typography>
        </Stack>
        {userState.user?.isForwarders && (
          <>
            <Stack flexDirection="row" justifyContent="end">
              <Button
                variant="gray"
                onClick={() => props.setIsDetailOpen(props.id, true)}>
                {t('viewSI')}
              </Button>
              {reservation?.status === Status.APPROVED ? (
                <Button
                  variant="outlined"
                  style={{
                    marginLeft: '16px',
                  }}
                  onClick={() => {
                    setIsReviewOpen(true);
                  }}>
                  {t('reviewCustomer')}
                </Button>
              ) : null}
            </Stack>
            {reservation?.status === Status.PENDING ? (
              <Stack flexDirection="row" justifyContent="end">
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    if (props.setIsConfirmOpen)
                      props.setIsConfirmOpen(props.id, true);
                  }}>
                  {t('approve')}
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  style={{
                    marginLeft: '16px',
                    color: '#FF5F5F',
                    border: '1px solid #FF5F5F',
                  }}
                  onClick={() => {
                    if (props.rejectReservation)
                      props.rejectReservation(props.id);
                  }}>
                  {t('decline')}
                </Button>
              </Stack>
            ) : null}
          </>
        )}
        {userState.user?.isShippers && (
          <>
            <Stack flexDirection="row" justifyContent="end">
              <Button
                variant="gray"
                onClick={() => props.setIsDetailOpen(props.id, true)}>
                {t('viewSI')}
              </Button>
            </Stack>
            <Stack flexDirection="row" justifyContent="end">
              {reservation?.status === Status.APPROVED ? (
                <Button
                  variant="outlined"
                  style={{
                    marginLeft: '16px',
                  }}
                  onClick={() => {
                    setIsReviewOpen(true);
                  }}>
                  {t('reviewCustomer')}
                </Button>
              ) : null}
              {reservation?.status !== Status.REJECTED ? (
                <Button
                  variant="outlined"
                  size="small"
                  disabled={reservation?.status === Status.APPROVED}
                  sx={{
                    marginLeft: '16px',
                    borderColor: 'red',
                    color: 'red',
                    '&:disabled': {
                      color: '#FFFFFF',
                      backgroundColor: '#DADCE3',
                      borderColor: '#DADCE3',
                    },
                  }}
                  onClick={() => {
                    if (props.cancelReservation)
                      props.cancelReservation(props.id);
                  }}>
                  {t('cancel')}
                </Button>
              ) : null}
            </Stack>
          </>
        )}
      </Stack>
      <Review
        isOpen={isReviewOpen}
        companyReceivedReviewsId={reviewId ?? ''}
        setIsOpen={setIsReviewOpen}
      />
    </>
  );
};
