import {Stack, Typography} from '@mui/material';
import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LabelValue} from './LabelValue';
import {StyledTable} from './StyledTable';
import {Fhd} from '../API';
import {useFirstFhds} from '../queries/fhd';
import {calculateCustomFee} from '../utils/calculateCustomFee';
import {calculateItemFee} from '../utils/calculateItemFee';
import {isNonNull} from '../utils/isEmpty';
import {roundToDecimal} from '../utils/roundToDecimal';

interface FhdDetailProps {
  companyId: string;
  arrival: string;
  totalCw: number;
  totalGw: number;
  unit: number;
}

export const FhdDetail: FC<FhdDetailProps> = props => {
  const {t} = useTranslation();
  const {data: fhd} = useFirstFhds(props.companyId, props.arrival);

  const fhdFee = roundToDecimal(
    calculateCustomFee(
      fhd?.customItems?.items?.filter(isNonNull) ?? [],
      props.totalCw,
      props.totalGw,
      props.unit,
    ),
    2,
  );

  const [sortedFhd, setSortedFhd] = useState<Fhd | null>(null);

  useEffect(() => {
    if (fhd?.customItems?.items) {
      const sortedItems = [...fhd.customItems.items].sort(
        (a, b) =>
          new Date(a?.createdAt ?? '').getTime() -
          new Date(b?.createdAt ?? '').getTime(),
      );
      const sortedFhd = {
        ...fhd,
        customItems: {
          ...fhd.customItems,
          items: sortedItems,
        },
      } as Fhd;
      setSortedFhd(sortedFhd);
    } else {
      setSortedFhd(null);
    }
  }, [fhd]);

  return (
    <Stack spacing={5}>
      <Stack spacing={3}>
        <Typography variant="h2">{t('importCharge')}</Typography>
        <StyledTable>
          <tr>
            <th>{t('ItemName')}</th>
            <th>{t('currency')}</th>
            <th>{t('unitPrice')}</th>
            <th>{t('unit')}</th>
            <th>{t('minimum')}</th>
            <th>{t('maximum')}</th>
            <th>{t('estimatedAmount')}</th>
            <th>{t('exchangeRate')}</th>
            <th>{t('yenCurrency')}</th>
            <th>{t('remark')}</th>
          </tr>
          <>
            {sortedFhd?.customItems?.items.filter(isNonNull).map(item => {
              return (
                <tr>
                  <th>{`${item.title}${item.includeTax ? '*' : ''}`}</th>
                  <td>{item.currency?.code}</td>
                  <td>{item.unitPrice.toLocaleString()}</td>
                  <td>{item.unitType}</td>
                  <td>{item.minimum?.toLocaleString()}</td>
                  <td>{item.maximum?.toLocaleString()}</td>
                  <td>
                    {roundToDecimal(
                      calculateItemFee(
                        item.unitPrice,
                        props.totalCw,
                        props.totalGw,
                        props.unit,
                        item.calculationMethod,
                        item.minimum,
                        item.maximum,
                      ),
                      0,
                    ).toLocaleString()}
                  </td>
                  <td>
                    {roundToDecimal(
                      item.currency?.exchangeRateJPY ?? 0,
                      2,
                    ).toLocaleString()}
                  </td>
                  <td>
                    {roundToDecimal(
                      (item.currency?.exchangeRateJPY ?? 0) *
                        calculateItemFee(
                          item.unitPrice,
                          props.totalCw,
                          props.totalGw,
                          props.unit,
                          item.calculationMethod,
                          item.minimum,
                          item.maximum,
                        ),
                      0,
                    ).toLocaleString()}
                  </td>
                  <td>{item.remark}</td>
                </tr>
              );
            })}
          </>
          <tr>
            <th></th>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <th>{t('subtotal')}</th>
            <td>{fhdFee.toLocaleString()}</td>
            <td></td>
          </tr>
        </StyledTable>
        <LabelValue title={t('remark')} value={fhd?.remark} />
      </Stack>
    </Stack>
  );
};
