import {TaxPaymentConditions} from '../API';
import i18n from '../i18n/i18n';

export const getLocalizedTaxPaymentConditions = (
  taxPaymentConditions: TaxPaymentConditions,
) => {
  if (taxPaymentConditions?.realTimeAccount !== null) {
    return i18n.t('realTimeAccount');
  } else if (taxPaymentConditions.comprehensiveDeferredPayment !== null) {
    return i18n.t('customsDeferredPayment');
  } else if (taxPaymentConditions.cashOnDeliveryAllowed !== null) {
    return i18n.t('cod');
  } else if (taxPaymentConditions.beforePermissionPickup !== null) {
    return i18n.t('bp');
  } else if (taxPaymentConditions.othersOption !== null) {
    return i18n.t('other');
  }
};
