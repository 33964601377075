import {Stack, Box, Typography, Divider, Drawer, Button} from '@mui/material';
import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {DepartureArrivalAirports} from './DepartureArrivalAirports';
import {LabelValue} from './LabelValue';
import {ShippingFeeDetail} from './ShippingFeeDetail';
import {ShippingFhdDetail} from './ShippingFhdDetail';
import {ShippingFobDetail} from './ShippingFobDetail';
import {StatusChip} from './StatusChip';
import {StyledTable} from './StyledTable';
import {Status, TransportType} from '../API';
import {japanAirports} from '../data/japanAirports';
import {useOAuthUserState} from '../providers/AuthProvider';
import {useForwarderCompany} from '../queries/forwarderCompany';
import {useReservation} from '../queries/reservation';
import {useShipperCompany} from '../queries/shipperCompany';
import {useShippingFee} from '../queries/shippingFee';
import {useShippingFhdFee} from '../queries/shippingFhdFee';
import {useShippingFobFee} from '../queries/shippingFobFee';
import {Review} from '../screens/Profile/Review';
import {roundUpToNearestHalf} from '../screens/Shipper/SearchEstimateResultCompare';
import {getLocalizedCargoInsuranceConditions} from '../utils/getLocalizedCargoInsuranceConditions';
import {getLocalizedCargoType} from '../utils/getLocalizedCargoType';
import {getLocalizedLeadTime} from '../utils/getLocalizedLeadTime';
import {getLocalizedPackingStyle} from '../utils/getLocalizedPackingStyle';
import {getLocalizedPaymentCondition} from '../utils/getLocalizedPaymentCondition';
import {getLocalizedRegulated} from '../utils/getLocalizedRegulated';
import {getLocalizedTaxPaymentConditions} from '../utils/getLocalizedTaxPaymentConditions';
import {roundToDecimal} from '../utils/roundToDecimal';

type ReservationDetailProps = {
  isDetailOpen: boolean;
  reservationId: string;
  reviewId?: string;
  setIsConfirmOpen?: (id: string, open: boolean) => void;
  rejectReservation?: (id: string) => void;
  cancelReservation?: (id: string) => void;
  setIsDetailOpen: (id: string, open: boolean) => void;
};

export const ReservationDetail: FC<ReservationDetailProps> = (
  props: ReservationDetailProps,
) => {
  const userState = useOAuthUserState();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [isReviewOpen, setIsReviewOpen] = useState(false);

  const {data: reservation} = useReservation(props.reservationId);
  const {data: shippingFee} = useShippingFee(
    reservation?.shippingInstruction?.shippingFee.id ?? '',
  );
  const {data: shippingFobFee} = useShippingFobFee(
    reservation?.shippingInstruction?.shippingInstructionFobId ?? '',
  );
  const {data: shippingFhdFee} = useShippingFhdFee(
    reservation?.shippingInstruction?.shippingInstructionFhdId ?? '',
  );

  const {data: forwarderCompany} = useForwarderCompany(
    reservation?.forwarderCompanyId || '',
  );

  const {data: shipperCompany} = useShipperCompany(
    reservation?.shipperCompanyId || '',
  );

  const companyName = userState.user?.isForwarders
    ? shipperCompany?.name
    : forwarderCompany?.name;

  if (!reservation?.shippingInstruction) {
    return null;
  }

  //totalCwが必要
  const totalVolumWeight =
    reservation.shippingInstruction?.shippingItems?.reduce(
      (sum, currentItem) => {
        return sum + (currentItem?.volumeWeight || 0);
      },
      0,
    );
  const totalGw = reservation.shippingInstruction?.shippingItems?.reduce(
    (sum, currentItem) => {
      return sum + (currentItem?.gw || 0);
    },
    0,
  );
  const totalCw = roundUpToNearestHalf(
    Math.max(totalGw ?? 0, totalVolumWeight ?? 0),
  );

  //航空運賃の合計
  const basePrice = shippingFee?.price ?? 0;
  const airfreightPrice = Math.max(
    basePrice * totalCw,
    shippingFee?.minimum ?? 0,
  );
  const airfreightPriceYen = roundToDecimal(
    airfreightPrice * (shippingFee?.currency?.exchangeRateJPY ?? 0),
    0,
  );

  const fsc = shippingFee?.fsc ?? 0;
  const fscPrice = fsc * totalCw;
  const fscPriceYen = roundToDecimal(
    fscPrice * (shippingFee?.currency?.exchangeRateJPY ?? 0),
    0,
  );
  const totalCustomFee =
    shippingFee?.customFees?.items.reduce((total, item) => {
      return (
        total +
        roundToDecimal(
          (item?.currency?.exchangeRateJPY ?? 0) * (item?.fee ?? 0),
          0,
        )
      );
    }, 0) ?? 0;

  const totalAirFee = airfreightPriceYen + fscPriceYen + totalCustomFee;

  //輸出料金の合計
  const totalFobFee =
    shippingFobFee?.customFees?.items.reduce((total, item) => {
      return (
        total +
        roundToDecimal(
          (item?.currency?.exchangeRateJPY ?? 0) * (item?.fee ?? 0),
          0,
        )
      );
    }, 0) ?? 0;

  //輸入料金の合計
  const totalFhdFee =
    shippingFhdFee?.customFees?.items.reduce((total, item) => {
      return (
        total +
        roundToDecimal(
          (item?.currency?.exchangeRateJPY ?? 0) * (item?.fee ?? 0),
          0,
        )
      );
    }, 0) ?? 0;

  //合計
  const totalFee =
    reservation.shippingInstruction.transportType === TransportType.TRILATERAL
      ? totalAirFee + totalFobFee + totalFhdFee
      : reservation.shippingInstruction.transportType ===
        TransportType.AIR_EXPORT
      ? totalAirFee + totalFobFee
      : totalAirFee;

  //航空運賃の消費税
  const totalCustomFeeTax =
    shippingFee?.customFees?.items.reduce((total, item) => {
      return (
        total +
        roundToDecimal(
          ((item?.taxRate ?? 0) / 100) *
            (item?.currency?.exchangeRateJPY ?? 0) *
            (item?.fee ?? 0),
          0,
        )
      );
    }, 0) ?? 0;

  //輸出料金の消費税
  const totalFobFeeTax =
    shippingFobFee?.customFees?.items.reduce((total, item) => {
      return (
        total +
        roundToDecimal(
          ((item?.taxRate ?? 0) / 100) *
            (item?.currency?.exchangeRateJPY ?? 0) *
            (item?.fee ?? 0),
          0,
        )
      );
    }, 0) ?? 0;

  //輸入料金の消費税
  const totalFhdFeeTax =
    shippingFhdFee?.customFees?.items.reduce((total, item) => {
      return (
        total +
        roundToDecimal(
          ((item?.taxRate ?? 0) / 100) *
            (item?.currency?.exchangeRateJPY ?? 0) *
            (item?.fee ?? 0),
          0,
        )
      );
    }, 0) ?? 0;

  //合計消費税
  const totalTax =
    reservation.shippingInstruction.transportType === TransportType.TRILATERAL
      ? totalCustomFeeTax + totalFobFeeTax + totalFhdFeeTax
      : reservation.shippingInstruction.transportType ===
        TransportType.AIR_EXPORT
      ? totalCustomFeeTax + totalFobFeeTax
      : totalCustomFeeTax;

  //総額
  const totalFeeIncludeTax = totalFee + totalTax;

  return (
    <>
      <Drawer
        anchor="right"
        open={props.isDetailOpen}
        onClose={() => props.setIsDetailOpen('', false)}>
        <Box width="1128px" mx={5} my={5}>
          <Stack
            flexDirection="row"
            mb={3}
            justifyContent="space-between"
            alignItems="center">
            <Box alignItems="center" display="flex" flexDirection="row">
              {reservation.status && <StatusChip status={reservation.status} />}
              <Typography
                ml={1}
                variant="h5"
                onClick={() => {
                  navigate(
                    userState.user?.isForwarders
                      ? '/shipperCompanyProfile'
                      : '/forwarderCompanyProfile',
                    {
                      state: {
                        companyId: userState.user?.isForwarders
                          ? shipperCompany?.id
                          : forwarderCompany?.id,
                      },
                    },
                  );
                }}
                style={{cursor: 'pointer'}}>
                {companyName}
              </Typography>
            </Box>
          </Stack>
          <Stack flexDirection="column" spacing={5}>
            <Stack flexDirection="column" spacing={1.5}>
              <Stack flexDirection="column" spacing={1}>
                <Typography variant="h5">{t('reservationID')}</Typography>
                <Typography variant="h5">{props.reservationId}</Typography>
              </Stack>
              {reservation.status === Status.APPROVED && (
                <Stack flexDirection="column" spacing={1}>
                  <Typography variant="h5">{t('hawb')}</Typography>
                  <Typography variant="h5">{reservation.hawb}</Typography>
                </Stack>
              )}
              <Divider />
              <DepartureArrivalAirports
                departure={reservation.shippingInstruction.departure}
                arrival={reservation.shippingInstruction.arrival}
              />
              <LabelValue
                title={`${t('airline')}:`}
                titleWeight="600"
                value={reservation.shippingInstruction.airline}
                row
                rowValueLeftMaring="16px"
              />
              <LabelValue
                title={`${t('etd')}:`}
                titleWeight="600"
                value={reservation.shippingInstruction.flightDate}
                row
                rowValueLeftMaring="16px"
              />
              <LabelValue
                title={`${t('flightType')}:`}
                titleWeight="600"
                value={
                  reservation.shippingInstruction.directFlight
                    ? t('directFlight')
                    : t('connectingFlight')
                }
                row
                rowValueLeftMaring="16px"
              />
              <LabelValue
                title={`${t('leadTime')}:`}
                titleWeight="600"
                value={getLocalizedLeadTime(
                  reservation.shippingInstruction.leadTime,
                )}
                row
                rowValueLeftMaring="16px"
              />
              <LabelValue
                title={`${t('saf')}:`}
                titleWeight="600"
                value={
                  reservation.shippingInstruction.useSaf
                    ? t('use')
                    : t('nonUse')
                }
                row
                rowValueLeftMaring="16px"
              />
              <LabelValue
                title={`${t('logisticsCompanyWarehouse')}:`}
                titleWeight="600"
                value={reservation.shippingInstruction.yardCutDate}
                row
                rowValueLeftMaring="16px"
              />
              <LabelValue
                title={`${t('airlineWarehouseDirectCut')}:`}
                titleWeight="600"
                value={reservation.shippingInstruction.carrierShedCutDate}
                row
                rowValueLeftMaring="16px"
              />
            </Stack>
            <Stack flexDirection="column" mt={5} spacing={1.5}>
              <Typography variant="h3">{t('cargoDetails')}</Typography>
              <Stack flexDirection="column" spacing={1}>
                <LabelValue
                  title={`${t('productName')}:`}
                  titleWeight="600"
                  value={reservation.shippingInstruction.itemName}
                  row
                  rowValueLeftMaring="16px"
                />
                <LabelValue
                  title={`${t('packingType')}:`}
                  titleWeight="600"
                  value={getLocalizedPackingStyle(
                    reservation.shippingInstruction.packingStyle ?? '',
                    reservation.shippingInstruction.packingStyleDetail ?? '',
                  )}
                  row
                  rowValueLeftMaring="16px"
                />
                <StyledTable>
                  <tr>
                    <th>{t('length')}</th>
                    <th>{t('width')}</th>
                    <th>{t('height')}</th>
                    <th>{t('weight')}</th>
                    <th>{t('numberOfPackage')}</th>
                    <th>{t('grossWeight')}</th>
                    <th>{t('grossVolume')}</th>
                    <th>{t('volumeWeight')}</th>
                  </tr>
                  {reservation.shippingInstruction.shippingItems?.map(
                    (shippingItem, index) => (
                      <tr key={index}>
                        <td>{shippingItem?.width}</td>
                        <td>{shippingItem?.length}</td>
                        <td>{shippingItem?.height}</td>
                        <td>{shippingItem?.weight}</td>
                        <td>{shippingItem?.numberOfUnit}</td>
                        <td>{shippingItem?.gw}</td>
                        <td>{shippingItem?.volume}</td>
                        <td>{shippingItem?.volumeWeight}</td>
                      </tr>
                    ),
                  ) ?? null}
                </StyledTable>
                <Stack
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center">
                  <StyledTable>
                    <th style={{paddingRight: '40px'}}>
                      {t('chargeableWeight')}
                    </th>
                    <td style={{paddingRight: '40px'}}>{totalCw}</td>
                  </StyledTable>
                </Stack>
              </Stack>
              <LabelValue
                title={`${t('commodityTypeExport')}:`}
                titleWeight="600"
                value={getLocalizedCargoType(
                  reservation.shippingInstruction.cargoType,
                )}
                sx={{marginTop: '12px'}}
                row
                rowValueLeftMaring="16px"
              />
              <LabelValue
                title={`${t('sds')}:`}
                titleWeight="600"
                value={
                  reservation.shippingInstruction.isSds
                    ? t('possible')
                    : t('impossible')
                }
                row
                rowValueLeftMaring="16px"
              />
              {japanAirports.includes(
                reservation.shippingInstruction.departure ?? '',
              ) ? (
                <LabelValue
                  title={`${t('laws')}:`}
                  titleWeight="600"
                  value={getLocalizedRegulated(
                    reservation.shippingInstruction.regulated,
                    reservation.shippingInstruction.regulatedDetail ?? '',
                  )}
                  row
                  rowValueLeftMaring="16px"
                />
              ) : null}
              <LabelValue
                title={`${t('preferredPaymentsTerms')}:`}
                titleWeight="600"
                value={getLocalizedPaymentCondition(
                  reservation.shippingInstruction.paymentCondition,
                )}
                row
                rowValueLeftMaring="16px"
              />
              <Typography>
                {reservation.shippingInstruction.paymentConditionDetail}
              </Typography>
              {japanAirports.includes(
                reservation.shippingInstruction.arrival,
              ) ? (
                <>
                  <LabelValue
                    title={`${t('preferredPaymentsTermsImportDuty')}:`}
                    titleWeight="600"
                    value={getLocalizedTaxPaymentConditions(
                      reservation.shippingInstruction.taxPaymentConditions,
                    )}
                    row
                    rowValueLeftMaring="16px"
                  />
                  <Typography>
                    {(() => {
                      if (
                        reservation.shippingInstruction.taxPaymentConditions
                          .realTimeAccount !== null
                      ) {
                        return reservation.shippingInstruction
                          .taxPaymentConditions.realTimeAccount;
                      } else if (
                        reservation.shippingInstruction.taxPaymentConditions
                          .comprehensiveDeferredPayment !== null
                      ) {
                        return reservation.shippingInstruction
                          .taxPaymentConditions.comprehensiveDeferredPayment;
                      } else if (
                        reservation.shippingInstruction.taxPaymentConditions
                          .othersOption !== null
                      ) {
                        return reservation.shippingInstruction
                          .taxPaymentConditions.othersOption;
                      }
                    })()}
                  </Typography>
                  {reservation.shippingInstruction.cargoInsuranceConditions && (
                    <LabelValue
                      title={`${t('cargoInsuranceImport')}:`}
                      titleWeight="600"
                      value={getLocalizedCargoInsuranceConditions(
                        reservation.shippingInstruction
                          .cargoInsuranceConditions,
                      )}
                      row
                      rowValueLeftMaring="16px"
                    />
                  )}
                  <Typography>
                    {(() => {
                      if (
                        reservation.shippingInstruction.cargoInsuranceConditions
                          ?.comprehensiveInsurance !== null
                      ) {
                        return reservation.shippingInstruction
                          .cargoInsuranceConditions?.comprehensiveInsurance;
                      } else if (
                        reservation.shippingInstruction.cargoInsuranceConditions
                          ?.othersOption !== null
                      ) {
                        return reservation.shippingInstruction
                          .cargoInsuranceConditions?.othersOption;
                      }
                    })()}
                  </Typography>
                </>
              ) : null}
            </Stack>
            {japanAirports.includes(
              reservation.shippingInstruction.departure,
            ) ? (
              <Stack flexDirection="column" spacing={1.5}>
                <Typography variant="h3">{t('dispatchDocuments')}</Typography>
                <Stack flexDirection="column" spacing={1}>
                  <LabelValue
                    title={`${t('invoice')}:`}
                    titleWeight="600"
                    value={
                      reservation.shippingInstruction.document?.invoice
                        ? t('possible')
                        : t('impossible')
                    }
                    row
                    rowValueLeftMaring="16px"
                  />
                  <LabelValue
                    title={`${t('packingList')}:`}
                    titleWeight="600"
                    value={
                      reservation.shippingInstruction.document?.packingList
                        ? t('possible')
                        : t('impossible')
                    }
                    row
                    rowValueLeftMaring="16px"
                  />
                  <LabelValue
                    title={`${t('nonWoodCertificate')}:`}
                    titleWeight="600"
                    value={
                      reservation.shippingInstruction.document
                        ?.nonApplicabilityCertificate
                        ? t('possible')
                        : t('impossible')
                    }
                    row
                    rowValueLeftMaring="16px"
                  />
                  <LabelValue
                    title={`${t('originCertificate')}:`}
                    titleWeight="600"
                    value={
                      reservation.shippingInstruction.document?.msds
                        ? t('possible')
                        : t('impossible')
                    }
                    row
                    rowValueLeftMaring="16px"
                  />
                  <LabelValue
                    title={`${t('other')}:`}
                    titleWeight="600"
                    value={
                      reservation.shippingInstruction.document?.other
                        ? reservation.shippingInstruction.document.detail
                        : t('impossible')
                    }
                    row
                    rowValueLeftMaring="16px"
                  />
                </Stack>
              </Stack>
            ) : null}
            <Stack flexDirection="column" spacing={2}>
              <Typography variant="h3">{t('options')}</Typography>
              <Stack flexDirection="column" spacing={1}>
                <LabelValue
                  title={`${t('cargoInsurance')}:`}
                  titleWeight="600"
                  value={
                    reservation.shippingInstruction.cargoInsurance
                      ? t('possible')
                      : t('impossible')
                  }
                  row
                  rowValueLeftMaring="16px"
                />
                <Typography>
                  {reservation.shippingInstruction.cargoInsuranceDetail ?? ''}
                </Typography>
                <LabelValue
                  title={`${t('packingWork')}:`}
                  titleWeight="600"
                  value={
                    reservation.shippingInstruction.packed
                      ? t('possible')
                      : t('impossible')
                  }
                  row
                  rowValueLeftMaring="16px"
                />
                <Typography>
                  {reservation.shippingInstruction.packedDetail ?? ''}
                </Typography>
                <LabelValue
                  title={`${
                    japanAirports.includes(
                      reservation.shippingInstruction.departure ?? '',
                    )
                      ? t('pickupLocation')
                      : japanAirports.includes(
                          reservation.shippingInstruction.arrival ?? '',
                        )
                      ? t('domesticDeliveryLocation')
                      : t('departurePickupLocation')
                  }:`}
                  titleWeight="600"
                  value={
                    reservation.shippingInstruction.collected
                      ? t('possible')
                      : t('impossible')
                  }
                  row
                  rowValueLeftMaring="16px"
                />
                <Typography>
                  {reservation.shippingInstruction.collectedDetail ?? ''}
                </Typography>
                <LabelValue
                  title={`${
                    japanAirports.includes(
                      reservation.shippingInstruction.departure ?? '',
                    )
                      ? t('deliveryLocation')
                      : japanAirports.includes(
                          reservation.shippingInstruction.arrival ?? '',
                        )
                      ? t('internationalPickupLocation')
                      : t('arrivalDeliveryLocation')
                  }:`}
                  titleWeight="600"
                  value={
                    reservation.shippingInstruction.delivery
                      ? t('possible')
                      : t('impossible')
                  }
                  row
                  rowValueLeftMaring="16px"
                />
                <Typography>
                  {reservation.shippingInstruction.deliveryDetail ?? ''}
                </Typography>
                <Typography>
                  {reservation.shippingInstruction.request}
                </Typography>
              </Stack>
              <Divider
                style={{
                  marginTop: '32px',
                }}
              />
            </Stack>
            <Stack flexDirection="column" spacing={2}>
              <Stack flexDirection="column" spacing={1}>
                <Typography variant="h3">{t('orderingManager')}</Typography>
                <LabelValue
                  title={`${t('companyName')}:`}
                  titleSize="14px"
                  titleWeight="300"
                  value={
                    reservation.shippingInstruction.shipperUser.company?.name
                  }
                  valueSize="14px"
                  valueWeight="300"
                  row
                  rowValueLeftMaring="8px"
                />
                <LabelValue
                  title={`${t('personInCharge')}:`}
                  titleSize="14px"
                  titleWeight="300"
                  value={`${reservation.shippingInstruction.shipperUser.lastName} ${reservation.shippingInstruction.shipperUser.firstName}`}
                  valueSize="14px"
                  valueWeight="300"
                  row
                  rowValueLeftMaring="8px"
                />
                <LabelValue
                  title={`${t('email')}:`}
                  titleSize="14px"
                  titleWeight="300"
                  value={reservation.shippingInstruction.shipperUser.email}
                  valueSize="14px"
                  valueWeight="300"
                  row
                  rowValueLeftMaring="8px"
                />
                <LabelValue
                  title={`${t('phoneNumber')}:`}
                  titleSize="14px"
                  titleWeight="300"
                  value={
                    reservation.shippingInstruction.shipperUser.phoneNumber
                  }
                  valueSize="14px"
                  valueWeight="300"
                  row
                  rowValueLeftMaring="8px"
                />
              </Stack>
              {japanAirports.includes(
                reservation.shippingInstruction.departure ?? '',
              ) ? null : (
                <Stack flexDirection="column" spacing={1}>
                  <Typography variant="h3">{t('shipper')}</Typography>
                  <LabelValue
                    title={`${t('companyName')}:`}
                    titleSize="14px"
                    titleWeight="300"
                    value={
                      reservation.shippingInstruction.shipperContact
                        ?.companyName
                    }
                    valueSize="14px"
                    valueWeight="300"
                    row
                    rowValueLeftMaring="8px"
                  />
                  <LabelValue
                    title={`${t('personInCharge')}:`}
                    titleSize="14px"
                    titleWeight="300"
                    value={reservation.shippingInstruction.shipperContact?.name}
                    valueSize="14px"
                    valueWeight="300"
                    row
                    rowValueLeftMaring="8px"
                  />
                  <LabelValue
                    title={`${t('email')}:`}
                    titleSize="14px"
                    titleWeight="300"
                    value={
                      reservation.shippingInstruction.shipperContact?.email
                    }
                    valueSize="14px"
                    valueWeight="300"
                    row
                    rowValueLeftMaring="8px"
                  />
                  <LabelValue
                    title={`${t('phoneNumber')}:`}
                    titleSize="14px"
                    titleWeight="300"
                    value={
                      reservation.shippingInstruction.shipperContact
                        ?.phoneNumber
                    }
                    valueSize="14px"
                    valueWeight="300"
                    row
                    rowValueLeftMaring="8px"
                  />
                </Stack>
              )}
              {japanAirports.includes(
                reservation.shippingInstruction.arrival ?? '',
              ) ? null : (
                <Stack flexDirection="column" spacing={1}>
                  <Typography variant="h3">{t('consignee')}</Typography>
                  <LabelValue
                    title={`${t('companyName')}:`}
                    titleSize="14px"
                    titleWeight="300"
                    value={
                      reservation?.shippingInstruction?.consigneeContact
                        ?.companyName
                    }
                    valueSize="14px"
                    valueWeight="300"
                    row
                    rowValueLeftMaring="8px"
                  />
                  <LabelValue
                    title={`${t('personInCharge')}:`}
                    titleSize="14px"
                    titleWeight="300"
                    value={
                      reservation.shippingInstruction.consigneeContact?.name
                    }
                    valueSize="14px"
                    valueWeight="300"
                    row
                    rowValueLeftMaring="8px"
                  />
                  <LabelValue
                    title={`${t('email')}:`}
                    titleSize="14px"
                    titleWeight="300"
                    value={
                      reservation.shippingInstruction.consigneeContact?.email
                    }
                    valueSize="14px"
                    valueWeight="300"
                    row
                    rowValueLeftMaring="8px"
                  />
                  <LabelValue
                    title={`${t('phoneNumber')}:`}
                    titleSize="14px"
                    titleWeight="300"
                    value={
                      reservation.shippingInstruction.consigneeContact
                        ?.phoneNumber
                    }
                    valueSize="14px"
                    valueWeight="300"
                    row
                    rowValueLeftMaring="8px"
                  />
                </Stack>
              )}
            </Stack>
            <Stack spacing={7}>
              <ShippingFeeDetail
                shippingFeeId={reservation.shippingInstruction.shippingFee.id}
                totalCw={totalCw}
              />
              {reservation.shippingInstruction.transportType !==
                TransportType.AIR_IMPORT && (
                <ShippingFobDetail
                  shippingFobFeeId={
                    reservation.shippingInstruction.shippingInstructionFobId
                  }
                />
              )}
              {reservation.shippingInstruction.transportType ===
                TransportType.TRILATERAL && (
                <ShippingFhdDetail
                  shippingFhdFeeId={
                    reservation.shippingInstruction.shippingInstructionFhdId
                  }
                />
              )}
            </Stack>
          </Stack>
          <LabelValue
            title={t('excludingTax')}
            value={`¥${totalFee.toLocaleString()}`}
            titleSize="18px"
            titleWeight="600"
            valueWeight="600"
            valueSize="30px"
            width="260px"
            color="#3F6CDF"
            row
            rowValueLeftMaring="51px"
            sx={{marginTop: '36px'}}
          />
          <LabelValue
            title={t('consumptionTax')}
            value={`¥${totalTax.toLocaleString()}`}
            titleSize="18px"
            titleWeight="600"
            valueWeight="600"
            valueSize="30px"
            width="260px"
            color="#3F6CDF"
            row
            rowValueLeftMaring="51px"
            sx={{marginTop: '36px'}}
          />
          <LabelValue
            title={t('totalFee')}
            value={`¥${totalFeeIncludeTax.toLocaleString()}`}
            titleSize="18px"
            titleWeight="600"
            valueWeight="600"
            valueSize="30px"
            width="260px"
            color="#3F6CDF"
            row
            rowValueLeftMaring="51px"
            sx={{marginTop: '36px'}}
          />
          {userState.user?.isForwarders &&
            reservation.status === Status.PENDING && (
              <>
                <Stack flexDirection="row" justifyContent="flex-end" mt={3}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      if (props.setIsConfirmOpen)
                        props.setIsConfirmOpen(reservation.id, true);
                    }}>
                    {t('approve')}
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      marginLeft: '16px',
                      marginRight: '55px',
                      color: '#FF5F5F',
                      border: '1px solid #FF5F5F',
                    }}
                    onClick={() => {
                      if (props.rejectReservation)
                        props.rejectReservation(reservation.id);
                    }}>
                    {t('decline')}
                  </Button>
                </Stack>
              </>
            )}
          {userState.user?.isShippers &&
            reservation.status !== Status.REJECTED && (
              <>
                <Stack flexDirection="row" justifyContent="flex-end" mt={3}>
                  {reservation.status === Status.APPROVED && (
                    <Button
                      variant="outlined"
                      style={{
                        marginLeft: '16px',
                      }}
                      onClick={() => {
                        setIsReviewOpen(true);
                      }}>
                      {t('reviewCustomer')}
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    size="small"
                    disabled={reservation.status === Status.APPROVED}
                    sx={{
                      marginLeft: '16px',
                      borderColor: 'red',
                      color: 'red',
                      '&:disabled': {
                        color: '#FFFFFF',
                        backgroundColor: '#DADCE3',
                        borderColor: '#DADCE3',
                      },
                    }}
                    onClick={() => {
                      if (props.cancelReservation)
                        props.cancelReservation(reservation.id);
                    }}>
                    {t('cancel')}
                  </Button>
                </Stack>
              </>
            )}
        </Box>
      </Drawer>
      <Review
        isOpen={isReviewOpen}
        companyReceivedReviewsId={props.reviewId ?? ''}
        setIsOpen={setIsReviewOpen}
      />
    </>
  );
};
