import {Visibility, VisibilityOff} from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  Modal,
  Link,
  Box,
  TextField,
  Container,
  Grid,
  Button,
} from '@mui/material';
import {CognitoUser} from 'amazon-cognito-identity-js';
import {Auth} from 'aws-amplify';
import {useEffect, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {LabelTitle} from '../../components/LabelTitle';
import {PasswordValidation} from '../../components/PasswordValidation';
import Inner from '../../layouts/Inner';
import {combineAlphanumericCharacters} from '../../utils/combineAlphanumericCharactersRegex';

interface FormInput {
  email: string;
  password: string;
}

export const SignInScreen = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormInput>();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [confirm, setConfirm] = useState(true);
  const [submitError, setSubmitError] = useState(false);
  const [user, setUser] = useState<CognitoUser | null>(null);

  const onSubmit: SubmitHandler<FormInput> = data => {
    Auth.signIn({
      username: data.email,
      password: data.password,
    })
      .then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setConfirm(false);
          setUser(user);
        } else {
          navigate('/');
        }
      })
      .catch(() => {
        setAuthError(true);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const location = useLocation();
  const state = location.state as {leave: boolean};
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (state?.leave) {
      setOpen(true);
    }
  }, [state]);

  useEffect(() => {
    document.title = `${t('login')} | MAST`;
  }, [t]);

  interface ConfirmationFormInput {
    newPassword: string;
    confirmationNewPassword: string;
  }

  const {
    register: confirmationRegister,
    handleSubmit: handleConfirmationSubmit,
    formState: {errors: confirmationErrors, dirtyFields},
    getValues,
    trigger,
  } = useForm<ConfirmationFormInput>({
    mode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: false,
  });

  const onConfirmationSubmit: SubmitHandler<ConfirmationFormInput> = data => {
    Auth.completeNewPassword(user, data.newPassword)
      .then(() => {
        setConfirm(true);
        navigate('/');
      })
      .catch(() => {
        setSubmitError(true);
      });
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        sx={{minHeight: 'calc(100vh - 72px)'}}>
        <Container maxWidth="sm">
          <Typography mb={3} variant="h1">
            MAST
          </Typography>
          <Inner>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3} alignItems="center" justifyContent="center">
                {authError ? (
                  <Typography variant="error" pb="8px" align="center">
                    {t('incorrectEmail')}
                    <br />
                    {t('again')}
                  </Typography>
                ) : null}
                <TextField
                  sx={{width: '100%'}}
                  error={!!errors.email}
                  label={t('email')}
                  variant="outlined"
                  type="email"
                  {...register('email', {required: true})}
                  helperText={errors.email ? t('enterEmail') : ''}
                />
                <Stack sx={{width: '100%'}} spacing={1}>
                  <TextField
                    error={!!errors.password}
                    label={t('password')}
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="on"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...register('password', {required: true})}
                    helperText={errors.password ? t('enterPassword') : ''}
                  />
                  <Box sx={{display: 'flex', justifyContent: 'end'}}>
                    <Link
                      sx={{cursor: 'pointer', fontSize: '14px'}}
                      underline="hover"
                      onClick={() => {
                        return navigate('/forgotPassword');
                      }}>
                      {t('forgotPassword')}
                    </Link>
                  </Box>
                </Stack>
                <Button variant="primary" type="submit" fullWidth>
                  {t('login')}
                </Button>
                <Link
                  sx={{cursor: 'pointer'}}
                  underline="hover"
                  onClick={() => {
                    return navigate('/signUp');
                  }}>
                  {t('firstTime')}
                </Link>
              </Stack>
            </form>
          </Inner>
        </Container>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Stack
          alignItems={'center'}
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'max-content',
            bgcolor: 'background.paper',
            borderRadius: '12px',
            p: '52px',
          }}>
          <CheckCircleOutlineIcon sx={{fontSize: '75px'}} />
          <Typography
            textAlign={'center'}
            sx={{fontSize: '20px', fontWeight: '600', mb: '24px'}}>
            {t('widthdrawalDone')}
          </Typography>
          <Typography textAlign={'center'} mb={5}>
            {t('thankYouForVisiting')}
            <br />
            {t('checkWithdrawalEmail')}
            <br />
            {t('lookForward')}
          </Typography>
          <Button
            variant="primary"
            type="submit"
            fullWidth
            onClick={handleClose}>
            {t('close')}
          </Button>
        </Stack>
      </Modal>
      <Modal
        open={!confirm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Stack
          alignItems={'center'}
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'max-content',
            bgcolor: 'background.paper',
            borderRadius: '12px',
            p: '52px',
          }}>
          <Typography
            textAlign="center"
            sx={{
              fontStyle: 'normal',
              fontSize: '20px',
              LineHeight: '26px',
            }}>
            {t('changePassword')}
          </Typography>
          {submitError ? (
            <Typography variant="error" mb="24px" textAlign="center">
              {t('wrongInformation')}
              <br />
              {t('checkAgain')}
            </Typography>
          ) : null}
          <Stack spacing={3} mt={3}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
              sx={{
                position: 'relative',
              }}>
              <LabelTitle isRequired sx={{marginTop: '16px'}}>
                {t('newPassword')}
              </LabelTitle>
              <Stack>
                <TextField
                  error={!!confirmationErrors.newPassword}
                  fullWidth
                  aria-label="password"
                  label={t('newPassword')}
                  variant="outlined"
                  sx={{
                    width: '408px',
                    position: 'relative',
                  }}
                  type={showNewPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}>
                          {showNewPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...confirmationRegister('newPassword', {
                    required: true,
                    onBlur: () => {
                      if (getValues('confirmationNewPassword')) {
                        trigger('confirmationNewPassword');
                      }
                    },
                    minLength: 8,
                    pattern: combineAlphanumericCharacters,
                  })}
                />
                <Box
                  sx={{
                    marginTop: '16px',
                  }}>
                  <PasswordValidation
                    wordCountError={
                      !!confirmationErrors.newPassword?.types?.required ||
                      !!confirmationErrors.newPassword?.types?.minLength ||
                      !dirtyFields.newPassword
                    }
                    alphanumericError={
                      !!confirmationErrors.newPassword?.types?.required ||
                      !!confirmationErrors.newPassword?.types?.pattern ||
                      !dirtyFields.newPassword
                    }
                  />
                </Box>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
              sx={{
                position: 'relative',
              }}>
              <LabelTitle isRequired sx={{marginTop: '16px'}}>
                {t('newPasswordConfirmation')}
              </LabelTitle>
              <Stack>
                <TextField
                  error={!!confirmationErrors.newPassword}
                  fullWidth
                  aria-label="password"
                  label={t('newPasswordConfirmation')}
                  variant="outlined"
                  sx={{
                    width: '408px',
                    position: 'relative',
                  }}
                  type={showNewPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}>
                          {showNewPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...confirmationRegister('confirmationNewPassword', {
                    required: true,
                    validate: value => {
                      return (
                        value === getValues('newPassword') || t('emailNotMatch')
                      );
                    },
                    minLength: 8,
                    pattern: combineAlphanumericCharacters,
                  })}
                />
                <Box
                  sx={{
                    marginTop: '16px',
                  }}>
                  <PasswordValidation
                    wordCountError={
                      !!confirmationErrors.confirmationNewPassword?.types
                        ?.required ||
                      !!confirmationErrors.confirmationNewPassword?.types
                        ?.minLength ||
                      !dirtyFields.confirmationNewPassword
                    }
                    alphanumericError={
                      !!confirmationErrors.confirmationNewPassword?.types
                        ?.required ||
                      !!confirmationErrors.confirmationNewPassword?.types
                        ?.pattern ||
                      !dirtyFields.confirmationNewPassword
                    }
                  />
                </Box>
              </Stack>
            </Stack>
            <Grid container alignItems="center" justifyContent="center">
              <Button
                variant="primary"
                type="submit"
                fullWidth
                onClick={handleConfirmationSubmit(onConfirmationSubmit)}>
                {t('change')}
              </Button>
            </Grid>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};
