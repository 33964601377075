import {Stack, Typography, Button} from '@mui/material';
import {Auth} from 'aws-amplify';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

export const ErrorScreen = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  return (
    <Stack alignItems="center" justifyContent="center">
      <Typography sx={{fontSize: '160px', fontWeight: '600', color: '#DADCE3'}}>
        {t('error')}
      </Typography>
      <Typography variant="h3">{t('errorOccured')}</Typography>
      <Typography mt={2}>{t('refresh')}</Typography>
      <Button
        style={{marginTop: '40px'}}
        variant="primary"
        type="submit"
        size="large"
        onClick={() => {
          Auth.signOut();
          navigate('/');
        }}>
        {t('toHome')}
      </Button>
    </Stack>
  );
};
