import styled from '@emotion/styled';
import 'react-phone-input-2/lib/style.css';
import FlightIcon from '@mui/icons-material/Flight';
import {Button, Stack, Typography} from '@mui/material';
import {useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {Item} from '../../components/InputEstimateItems';
import {useEstimates} from '../../queries/estimate';
import {getAirlineNameByCode} from '../../utils/getAirlineNameByCode';

const createDatesHeader = (startDate: Date, endDate: Date) => {
  const {t} = useTranslation();
  const daysOfWeek = [
    t('sunday'),
    t('monday'),
    t('tuesday'),
    t('wednesday'),
    t('thursday'),
    t('friday'),
    t('saturday'),
  ];
  const dateHeaders = [];
  for (
    let date = new Date(startDate);
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    const dayOfWeek = daysOfWeek[date.getDay()];
    const formattedDate = date.toLocaleDateString(undefined, {
      month: 'numeric',
      day: 'numeric',
    });
    const color =
      dayOfWeek === t('saturday')
        ? '#2196F3'
        : dayOfWeek === t('sunday')
        ? '#FF5F5F'
        : undefined;
    dateHeaders.push(
      <th key={date.toISOString()} style={{color}}>
        <span>
          {dayOfWeek}
          <br />
        </span>
        {formattedDate}
      </th>,
    );
  }
  return dateHeaders;
};
interface GroupedEstimates {
  [airlineCode: string]: {
    counts: number[];
    flightDates: (Date | null)[];
  };
}

export const SearchEstimateResultsScreen = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const location = useLocation();

  const state = location.state as {
    departure: string;
    arrival: string;
    cargoType: string;
    charge: string;
    items: Item[];
  };

  const startDate = new Date(
    new Date().setHours(0, 0, 0, 0) + 1000 * 60 * 60 * 24 * 4,
  );
  const endDate = new Date(startDate.getTime() + 1000 * 60 * 60 * 24 * 13);

  const dateHeaders = createDatesHeader(startDate, endDate);
  const {data, isLoading} = useEstimates({
    departure: state.departure,
    arrival: state.arrival,
    startDate,
    endDate,
  });

  const groupedEstimates: GroupedEstimates = (data?.items ?? []).reduce(
    (acc, estimate) => {
      const flightDate = new Date(estimate?.flightDate ?? '');
      const airlineCode = estimate?.airline ?? '';
      if (!acc[airlineCode]) {
        acc[airlineCode] = {
          counts: Array.from({length: 14}, () => 0),
          flightDates: Array.from({length: 14}, () => null),
        };
      }
      const dayDifference = Math.floor(
        (flightDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
      );
      acc[airlineCode].counts[dayDifference]++;
      acc[airlineCode].flightDates[dayDifference] = flightDate;
      return acc;
    },
    {} as GroupedEstimates,
  );

  const handleResultClick = useCallback(
    (flightDate: Date, airlineCode: string) => {
      navigate('/searchEstimate/results/compare', {
        state: {
          departure: state.departure,
          arrival: state.arrival,
          flightDate: flightDate,
          airlineCode: airlineCode,
          charge: state.charge,
          searchItems: state.items,
        },
      });
    },
    [navigate, state.arrival, state.charge, state.departure, state.items],
  );

  useEffect(() => {
    document.title = `${t('quotationsList')} | MAST`;
  }, [t]);

  return (
    <Stack
      pb={10}
      sx={{
        marginLeft: 'auto',
        marginRight: 'auto',
      }}>
      <Stack
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
        spacing={2}
        mb={3}>
        <Typography variant="h2">{t('searchQuotation')}</Typography>
        <Button
          variant="primary"
          type="submit"
          onClick={() => navigate('/searchEstimate')}>
          {t('searchAgain')}
        </Button>
      </Stack>
      {!isLoading && (!data || data?.items.length === 0) ? (
        <Stack spacing={2}>
          <Typography variant="h3">{t('noApplicableQuotation')}</Typography>
          <Typography>{t('pleaseSearchAgain')}</Typography>
          <Button
            variant="primary"
            type="submit"
            onClick={() => navigate('/searchEstimate')}>
            {t('searchAgain')}
          </Button>
        </Stack>
      ) : (
        <div style={{overflowX: 'auto'}}>
          <StyledTable>
            <thead>
              <tr>
                <th>Airlines</th>
                {dateHeaders}
              </tr>
            </thead>
            <tbody>
              {Object.keys(groupedEstimates).map(airlineCode => (
                <tr key={airlineCode}>
                  <td>
                    <span>{getAirlineNameByCode(airlineCode)}</span>
                  </td>
                  {groupedEstimates[airlineCode].counts.map((count, index) => {
                    const flightDate =
                      groupedEstimates[airlineCode].flightDates[index];
                    return (
                      <td key={index}>
                        {count !== 0 && flightDate !== null && (
                          <button
                            type="button"
                            onClick={() => {
                              handleResultClick(flightDate, airlineCode);
                            }}>
                            <FlightIcon
                              sx={{
                                fontSize: '22px',
                              }}
                            />
                            {count}
                          </button>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </div>
      )}
    </Stack>
  );
};

const StyledTable = styled.table`
  border-spacing: 2px;
  tr {
    padding-top: 8px;
  }
  th {
    padding: 4px 10px;
    font-size: 12px;
    text-align: center;
    font-weight: normal;
    background-color: #dadce3;
    span {
      font-size: 16px;
      font-weight: bold;
    }
  }
  td {
    min-width: 60px;
    background-color: #fff;
    span {
      padding: 0 5px;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 8px 0;
      font-size: 18px;
      background: none;
      border: none;
      cursor: pointer;
      transition: box-shadow 0.2s;
      &:hover {
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      }
    }
  }
`;
