/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateShipperUserInput = {
  id?: string | null,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  workLocation: string,
  division: string,
  position?: string | null,
  area: string,
  role: ShipperRole,
  companyId?: string | null,
};

export enum ShipperRole {
  AIR_EXPORT = "AIR_EXPORT",
  AIR_IMPORT = "AIR_IMPORT",
  SEA_EXPORT = "SEA_EXPORT",
  SEA_IMPORT = "SEA_IMPORT",
  WAREHOUSE = "WAREHOUSE",
  TRUCK = "TRUCK",
  OTHER = "OTHER",
}


export type ModelShipperUserConditionInput = {
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  workLocation?: ModelStringInput | null,
  division?: ModelStringInput | null,
  position?: ModelStringInput | null,
  area?: ModelStringInput | null,
  role?: ModelShipperRoleInput | null,
  companyId?: ModelIDInput | null,
  and?: Array< ModelShipperUserConditionInput | null > | null,
  or?: Array< ModelShipperUserConditionInput | null > | null,
  not?: ModelShipperUserConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelShipperRoleInput = {
  eq?: ShipperRole | null,
  ne?: ShipperRole | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ShipperUser = {
  __typename: "ShipperUser",
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  workLocation: string,
  division: string,
  position?: string | null,
  area: string,
  role: ShipperRole,
  companyId?: string | null,
  company?: ShipperCompany | null,
  postedReviews?: ModelShipperReviewConnection | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ShipperCompany = {
  __typename: "ShipperCompany",
  id: string,
  name: string,
  firstName: string,
  lastName: string,
  location: string,
  establishment: string,
  paidInCaptial: number,
  corporateNumber: string,
  category: string,
  listed: boolean,
  numberOfEmployees: NumberOfEmployees,
  companyURL: string,
  manager: ShipperUser,
  employees?: ModelShipperUserConnection | null,
  receivedReviews?: ModelForwarderReviewConnection | null,
  createdAt: string,
  updatedAt: string,
  shipperCompanyManagerId: string,
  owner?: string | null,
};

export enum NumberOfEmployees {
  UNDER5 = "UNDER5",
  UNDER20 = "UNDER20",
  UNDER100 = "UNDER100",
  UNDER300 = "UNDER300",
  OVER300 = "OVER300",
}


export type ModelShipperUserConnection = {
  __typename: "ModelShipperUserConnection",
  items:  Array<ShipperUser | null >,
  nextToken?: string | null,
};

export type ModelForwarderReviewConnection = {
  __typename: "ModelForwarderReviewConnection",
  items:  Array<ForwarderReview | null >,
  nextToken?: string | null,
};

export type ForwarderReview = {
  __typename: "ForwarderReview",
  id: string,
  title: string,
  description: string,
  rate: number,
  company?: ShipperCompany | null,
  author?: ForwarderUser | null,
  createdAt: string,
  updatedAt: string,
  forwarderUserPostedReviewsId?: string | null,
  shipperCompanyReceivedReviewsId?: string | null,
  owner?: string | null,
};

export type ForwarderUser = {
  __typename: "ForwarderUser",
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  workLocation: string,
  division: string,
  position?: string | null,
  companyId?: string | null,
  company?: ForwarderCompany | null,
  postedReviews?: ModelForwarderReviewConnection | null,
  estimates?: ModelEstimateConnection | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ForwarderCompany = {
  __typename: "ForwarderCompany",
  id: string,
  name: string,
  firstName: string,
  lastName: string,
  location: string,
  establishment: string,
  paidInCaptial: number,
  corporateNumber: string,
  iataAgency: string,
  listed: boolean,
  numberOfEmployees: NumberOfEmployees,
  companyURL: string,
  manager: ForwarderUser,
  fobs?: ModelFobConnection | null,
  fhds?: ModelFhdConnection | null,
  employees?: ModelForwarderUserConnection | null,
  receivedReviews?: ModelShipperReviewConnection | null,
  createdAt: string,
  updatedAt: string,
  forwarderCompanyManagerId: string,
  owner?: string | null,
};

export type ModelFobConnection = {
  __typename: "ModelFobConnection",
  items:  Array<Fob | null >,
  nextToken?: string | null,
};

export type Fob = {
  __typename: "Fob",
  id: string,
  airport: string,
  forwarderCompanyId: string,
  forwarder?: ForwarderCompany | null,
  customItems?: ModelCustomFeeConnection | null,
  remark?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ModelCustomFeeConnection = {
  __typename: "ModelCustomFeeConnection",
  items:  Array<CustomFee | null >,
  nextToken?: string | null,
};

export type CustomFee = {
  __typename: "CustomFee",
  id: string,
  title: string,
  unitPrice: number,
  unitType: string,
  calculationMethod: CalculationMethod,
  currency?: Currency | null,
  minimum?: number | null,
  maximum?: number | null,
  includeTax?: boolean | null,
  taxRate?: number | null,
  remark?: string | null,
  fobId?: string | null,
  fhdId?: string | null,
  shippingFeeId?: string | null,
  estimateId?: string | null,
  createdAt: string,
  updatedAt: string,
  customFeeCurrencyId?: string | null,
  owner?: string | null,
};

export enum CalculationMethod {
  PER_SHIPMENT = "PER_SHIPMENT",
  PER_UNIT = "PER_UNIT",
  PER_CW = "PER_CW",
  PER_GW = "PER_GW",
  ACTUAL_COST = "ACTUAL_COST",
}


export type Currency = {
  __typename: "Currency",
  id: string,
  code: string,
  exchangeRateJPY: number,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ModelFhdConnection = {
  __typename: "ModelFhdConnection",
  items:  Array<Fhd | null >,
  nextToken?: string | null,
};

export type Fhd = {
  __typename: "Fhd",
  id: string,
  airport: string,
  forwarderCompanyId: string,
  forwarder?: ForwarderCompany | null,
  customItems?: ModelCustomFeeConnection | null,
  remark?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ModelForwarderUserConnection = {
  __typename: "ModelForwarderUserConnection",
  items:  Array<ForwarderUser | null >,
  nextToken?: string | null,
};

export type ModelShipperReviewConnection = {
  __typename: "ModelShipperReviewConnection",
  items:  Array<ShipperReview | null >,
  nextToken?: string | null,
};

export type ShipperReview = {
  __typename: "ShipperReview",
  id: string,
  title: string,
  description: string,
  rate: number,
  company?: ForwarderCompany | null,
  author?: ShipperUser | null,
  createdAt: string,
  updatedAt: string,
  shipperUserPostedReviewsId?: string | null,
  forwarderCompanyReceivedReviewsId?: string | null,
  owner?: string | null,
};

export type ModelEstimateConnection = {
  __typename: "ModelEstimateConnection",
  items:  Array<Estimate | null >,
  nextToken?: string | null,
};

export type Estimate = {
  __typename: "Estimate",
  id: string,
  departure: string,
  arrival: string,
  airline: string,
  flightDate: string,
  directFlight: boolean,
  leadTime: number,
  useSaf: boolean,
  cargoType: CargoType,
  yardCutDate: string,
  carrierShedCutDate: string,
  currency?: Currency | null,
  minimum: number,
  under45: number,
  over45: number,
  over100: number,
  over300: number,
  over500: number,
  over1000: number,
  fsc: number,
  remark?: string | null,
  customItems?: ModelCustomFeeConnection | null,
  forwarderUser: ForwarderUser,
  status: EstimateStatus,
  createdAt: string,
  updatedAt: string,
  forwarderUserEstimatesId?: string | null,
  estimateCurrencyId?: string | null,
  owner?: string | null,
};

export enum CargoType {
  NORMAL = "NORMAL",
  LITHIUM = "LITHIUM",
  CLASS1_TO_9 = "CLASS1_TO_9",
}


export enum EstimateStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}


export type UpdateShipperUserInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  workLocation?: string | null,
  division?: string | null,
  position?: string | null,
  area?: string | null,
  role?: ShipperRole | null,
  companyId?: string | null,
};

export type DeleteShipperUserInput = {
  id: string,
};

export type CreateFobInput = {
  id?: string | null,
  airport: string,
  forwarderCompanyId: string,
  remark?: string | null,
};

export type ModelFobConditionInput = {
  airport?: ModelStringInput | null,
  forwarderCompanyId?: ModelIDInput | null,
  remark?: ModelStringInput | null,
  and?: Array< ModelFobConditionInput | null > | null,
  or?: Array< ModelFobConditionInput | null > | null,
  not?: ModelFobConditionInput | null,
};

export type UpdateFobInput = {
  id: string,
  airport?: string | null,
  forwarderCompanyId?: string | null,
  remark?: string | null,
};

export type DeleteFobInput = {
  id: string,
};

export type CreateFhdInput = {
  id?: string | null,
  airport: string,
  forwarderCompanyId: string,
  remark?: string | null,
};

export type ModelFhdConditionInput = {
  airport?: ModelStringInput | null,
  forwarderCompanyId?: ModelIDInput | null,
  remark?: ModelStringInput | null,
  and?: Array< ModelFhdConditionInput | null > | null,
  or?: Array< ModelFhdConditionInput | null > | null,
  not?: ModelFhdConditionInput | null,
};

export type UpdateFhdInput = {
  id: string,
  airport?: string | null,
  forwarderCompanyId?: string | null,
  remark?: string | null,
};

export type DeleteFhdInput = {
  id: string,
};

export type CreateForwarderReviewInput = {
  id?: string | null,
  title: string,
  description: string,
  rate: number,
  forwarderUserPostedReviewsId?: string | null,
  shipperCompanyReceivedReviewsId?: string | null,
};

export type ModelForwarderReviewConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  rate?: ModelIntInput | null,
  and?: Array< ModelForwarderReviewConditionInput | null > | null,
  or?: Array< ModelForwarderReviewConditionInput | null > | null,
  not?: ModelForwarderReviewConditionInput | null,
  forwarderUserPostedReviewsId?: ModelIDInput | null,
  shipperCompanyReceivedReviewsId?: ModelIDInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateForwarderReviewInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  rate?: number | null,
  forwarderUserPostedReviewsId?: string | null,
  shipperCompanyReceivedReviewsId?: string | null,
};

export type DeleteForwarderReviewInput = {
  id: string,
};

export type CreateShipperReviewInput = {
  id?: string | null,
  title: string,
  description: string,
  rate: number,
  shipperUserPostedReviewsId?: string | null,
  forwarderCompanyReceivedReviewsId?: string | null,
};

export type ModelShipperReviewConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  rate?: ModelIntInput | null,
  and?: Array< ModelShipperReviewConditionInput | null > | null,
  or?: Array< ModelShipperReviewConditionInput | null > | null,
  not?: ModelShipperReviewConditionInput | null,
  shipperUserPostedReviewsId?: ModelIDInput | null,
  forwarderCompanyReceivedReviewsId?: ModelIDInput | null,
};

export type UpdateShipperReviewInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  rate?: number | null,
  shipperUserPostedReviewsId?: string | null,
  forwarderCompanyReceivedReviewsId?: string | null,
};

export type DeleteShipperReviewInput = {
  id: string,
};

export type CreateInvitedUserInput = {
  id?: string | null,
  firstName: string,
  lastName: string,
  email: string,
  companyId: string,
  isForwarder: boolean,
};

export type ModelInvitedUserConditionInput = {
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  companyId?: ModelIDInput | null,
  isForwarder?: ModelBooleanInput | null,
  and?: Array< ModelInvitedUserConditionInput | null > | null,
  or?: Array< ModelInvitedUserConditionInput | null > | null,
  not?: ModelInvitedUserConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type InvitedUser = {
  __typename: "InvitedUser",
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  companyId: string,
  isForwarder: boolean,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateInvitedUserInput = {
  id?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email: string,
  companyId?: string | null,
  isForwarder?: boolean | null,
};

export type DeleteInvitedUserInput = {
  email: string,
};

export type CreateShippingCustomFeeInput = {
  id?: string | null,
  title: string,
  unitPrice: number,
  unitType: string,
  fee: number,
  taxRate?: number | null,
  maximum?: number | null,
  minimum?: number | null,
  remark: string,
  shippingFeeCustomFeesId?: string | null,
  shippingFobFeeCustomFeesId?: string | null,
  shippingFhdFeeCustomFeesId?: string | null,
  shippingCustomFeeCurrencyId: string,
};

export type ModelShippingCustomFeeConditionInput = {
  title?: ModelStringInput | null,
  unitPrice?: ModelFloatInput | null,
  unitType?: ModelStringInput | null,
  fee?: ModelFloatInput | null,
  taxRate?: ModelFloatInput | null,
  maximum?: ModelFloatInput | null,
  minimum?: ModelFloatInput | null,
  remark?: ModelStringInput | null,
  and?: Array< ModelShippingCustomFeeConditionInput | null > | null,
  or?: Array< ModelShippingCustomFeeConditionInput | null > | null,
  not?: ModelShippingCustomFeeConditionInput | null,
  shippingFeeCustomFeesId?: ModelIDInput | null,
  shippingFobFeeCustomFeesId?: ModelIDInput | null,
  shippingFhdFeeCustomFeesId?: ModelIDInput | null,
  shippingCustomFeeCurrencyId?: ModelIDInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ShippingCustomFee = {
  __typename: "ShippingCustomFee",
  id: string,
  title: string,
  currency: Currency,
  unitPrice: number,
  unitType: string,
  fee: number,
  taxRate?: number | null,
  maximum?: number | null,
  minimum?: number | null,
  remark: string,
  shippingFee?: ShippingFee | null,
  shippingFobFee?: ShippingFobFee | null,
  shippingFhdFee?: ShippingFhdFee | null,
  createdAt: string,
  updatedAt: string,
  shippingFeeCustomFeesId?: string | null,
  shippingFobFeeCustomFeesId?: string | null,
  shippingFhdFeeCustomFeesId?: string | null,
  shippingCustomFeeCurrencyId: string,
  owner?: string | null,
};

export type ShippingFee = {
  __typename: "ShippingFee",
  id: string,
  price?: number | null,
  fsc?: number | null,
  minimum?: number | null,
  currency?: Currency | null,
  customFees?: ModelShippingCustomFeeConnection | null,
  remark?: string | null,
  shippingInstruction?: ShippingInstruction | null,
  createdAt: string,
  updatedAt: string,
  shippingFeeCurrencyId?: string | null,
  shippingFeeShippingInstructionId?: string | null,
  owner?: string | null,
};

export type ModelShippingCustomFeeConnection = {
  __typename: "ModelShippingCustomFeeConnection",
  items:  Array<ShippingCustomFee | null >,
  nextToken?: string | null,
};

export type ShippingInstruction = {
  __typename: "ShippingInstruction",
  id: string,
  departure: string,
  arrival: string,
  airline: string,
  flightDate: string,
  directFlight: boolean,
  leadTime: number,
  useSaf: boolean,
  yardCutDate: string,
  carrierShedCutDate: string,
  itemName: string,
  packingStyle: string,
  packingStyleDetail?: string | null,
  cargoType: CargoType,
  isSds?: boolean | null,
  regulated: string,
  regulatedDetail?: string | null,
  paymentCondition: PaymentCondition,
  paymentConditionDetail?: string | null,
  taxPaymentConditions: TaxPaymentConditions,
  cargoInsuranceConditions?: CargoInsuranceConditions | null,
  document?: Document | null,
  consigneeContact?: Contact | null,
  shipperContact?: Contact | null,
  cargoInsurance: boolean,
  cargoInsuranceDetail?: string | null,
  packed: boolean,
  packedDetail?: string | null,
  collected: boolean,
  collectedDetail?: string | null,
  delivery: boolean,
  deliveryDetail?: string | null,
  request?: string | null,
  shipperUser: ShipperUser,
  shippingItems?:  Array<ShippingItem | null > | null,
  transportType: TransportType,
  shippingFee: ShippingFee,
  fob?: ShippingFobFee | null,
  fhd?: ShippingFhdFee | null,
  reservation?: Reservation | null,
  createdAt: string,
  updatedAt: string,
  shippingInstructionShipperUserId: string,
  shippingInstructionShippingFeeId: string,
  shippingInstructionFobId?: string | null,
  shippingInstructionFhdId?: string | null,
  shippingInstructionReservationId?: string | null,
  owner?: string | null,
};

export enum PaymentCondition {
  EOM_NEXT_MONTH_MAX_60 = "EOM_NEXT_MONTH_MAX_60",
  MID_MONTH_EOM_MAX_30 = "MID_MONTH_EOM_MAX_30",
  PAYMENT_DEADLINE_10BD = "PAYMENT_DEADLINE_10BD",
  PAYMENT_DEADLINE_5BD = "PAYMENT_DEADLINE_5BD",
  CASH_ON_DELIVERY = "CASH_ON_DELIVERY",
  CREDIT_REGISTERED_WITH_HISTORY = "CREDIT_REGISTERED_WITH_HISTORY",
}


export type TaxPaymentConditions = {
  __typename: "TaxPaymentConditions",
  realTimeAccount?: string | null,
  comprehensiveDeferredPayment?: string | null,
  cashOnDeliveryAllowed?: boolean | null,
  beforePermissionPickup?: boolean | null,
  othersOption?: string | null,
};

export type CargoInsuranceConditions = {
  __typename: "CargoInsuranceConditions",
  comprehensiveInsurance?: string | null,
  individualInsurance?: boolean | null,
  uninsuredWithInsurance?: boolean | null,
  othersOption?: string | null,
};

export type Document = {
  __typename: "Document",
  invoice?: boolean | null,
  packingList?: boolean | null,
  nonApplicabilityCertificate?: boolean | null,
  msds?: boolean | null,
  other?: boolean | null,
  detail?: string | null,
};

export type Contact = {
  __typename: "Contact",
  companyName: string,
  name: string,
  email: string,
  phoneNumber: string,
};

export type ShippingItem = {
  __typename: "ShippingItem",
  width: number,
  length: number,
  height: number,
  weight: number,
  numberOfUnit: number,
  volume: number,
  gw: number,
  volumeWeight: number,
  cw: number,
};

export enum TransportType {
  AIR_EXPORT = "AIR_EXPORT",
  AIR_IMPORT = "AIR_IMPORT",
  TRILATERAL = "TRILATERAL",
}


export type ShippingFobFee = {
  __typename: "ShippingFobFee",
  id: string,
  customFees?: ModelShippingCustomFeeConnection | null,
  remark?: string | null,
  shippingInstruction?: ShippingInstruction | null,
  createdAt: string,
  updatedAt: string,
  shippingFobFeeShippingInstructionId?: string | null,
  owner?: string | null,
};

export type ShippingFhdFee = {
  __typename: "ShippingFhdFee",
  id: string,
  customFees?: ModelShippingCustomFeeConnection | null,
  remark?: string | null,
  shippingInstruction?: ShippingInstruction | null,
  createdAt: string,
  updatedAt: string,
  shippingFhdFeeShippingInstructionId?: string | null,
  owner?: string | null,
};

export type Reservation = {
  __typename: "Reservation",
  id: string,
  shippingInstruction?: ShippingInstruction | null,
  hawb?: string | null,
  status?: Status | null,
  forwarderCompanyId?: string | null,
  shipperCompanyId?: string | null,
  departure?: string | null,
  createdAt: string,
  updatedAt: string,
  reservationShippingInstructionId?: string | null,
  owner?: string | null,
};

export enum Status {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}


export type UpdateShippingCustomFeeInput = {
  id: string,
  title?: string | null,
  unitPrice?: number | null,
  unitType?: string | null,
  fee?: number | null,
  taxRate?: number | null,
  maximum?: number | null,
  minimum?: number | null,
  remark?: string | null,
  shippingFeeCustomFeesId?: string | null,
  shippingFobFeeCustomFeesId?: string | null,
  shippingFhdFeeCustomFeesId?: string | null,
  shippingCustomFeeCurrencyId?: string | null,
};

export type DeleteShippingCustomFeeInput = {
  id: string,
};

export type CreateShippingFeeInput = {
  id?: string | null,
  price?: number | null,
  fsc?: number | null,
  minimum?: number | null,
  remark?: string | null,
  shippingFeeCurrencyId?: string | null,
  shippingFeeShippingInstructionId?: string | null,
};

export type ModelShippingFeeConditionInput = {
  price?: ModelFloatInput | null,
  fsc?: ModelFloatInput | null,
  minimum?: ModelFloatInput | null,
  remark?: ModelStringInput | null,
  and?: Array< ModelShippingFeeConditionInput | null > | null,
  or?: Array< ModelShippingFeeConditionInput | null > | null,
  not?: ModelShippingFeeConditionInput | null,
  shippingFeeCurrencyId?: ModelIDInput | null,
  shippingFeeShippingInstructionId?: ModelIDInput | null,
};

export type UpdateShippingFeeInput = {
  id: string,
  price?: number | null,
  fsc?: number | null,
  minimum?: number | null,
  remark?: string | null,
  shippingFeeCurrencyId?: string | null,
  shippingFeeShippingInstructionId?: string | null,
};

export type DeleteShippingFeeInput = {
  id: string,
};

export type CreateShippingFobFeeInput = {
  id?: string | null,
  remark?: string | null,
  shippingFobFeeShippingInstructionId?: string | null,
};

export type ModelShippingFobFeeConditionInput = {
  remark?: ModelStringInput | null,
  and?: Array< ModelShippingFobFeeConditionInput | null > | null,
  or?: Array< ModelShippingFobFeeConditionInput | null > | null,
  not?: ModelShippingFobFeeConditionInput | null,
  shippingFobFeeShippingInstructionId?: ModelIDInput | null,
};

export type UpdateShippingFobFeeInput = {
  id: string,
  remark?: string | null,
  shippingFobFeeShippingInstructionId?: string | null,
};

export type DeleteShippingFobFeeInput = {
  id: string,
};

export type CreateShippingFhdFeeInput = {
  id?: string | null,
  remark?: string | null,
  shippingFhdFeeShippingInstructionId?: string | null,
};

export type ModelShippingFhdFeeConditionInput = {
  remark?: ModelStringInput | null,
  and?: Array< ModelShippingFhdFeeConditionInput | null > | null,
  or?: Array< ModelShippingFhdFeeConditionInput | null > | null,
  not?: ModelShippingFhdFeeConditionInput | null,
  shippingFhdFeeShippingInstructionId?: ModelIDInput | null,
};

export type UpdateShippingFhdFeeInput = {
  id: string,
  remark?: string | null,
  shippingFhdFeeShippingInstructionId?: string | null,
};

export type DeleteShippingFhdFeeInput = {
  id: string,
};

export type CreateShippingInstructionInput = {
  id?: string | null,
  departure: string,
  arrival: string,
  airline: string,
  flightDate: string,
  directFlight: boolean,
  leadTime: number,
  useSaf: boolean,
  yardCutDate: string,
  carrierShedCutDate: string,
  itemName: string,
  packingStyle: string,
  packingStyleDetail?: string | null,
  cargoType: CargoType,
  isSds?: boolean | null,
  regulated: string,
  regulatedDetail?: string | null,
  paymentCondition: PaymentCondition,
  paymentConditionDetail?: string | null,
  taxPaymentConditions: TaxPaymentConditionsInput,
  cargoInsuranceConditions?: CargoInsuranceConditionsInput | null,
  document?: DocumentInput | null,
  consigneeContact?: ContactInput | null,
  shipperContact?: ContactInput | null,
  cargoInsurance: boolean,
  cargoInsuranceDetail?: string | null,
  packed: boolean,
  packedDetail?: string | null,
  collected: boolean,
  collectedDetail?: string | null,
  delivery: boolean,
  deliveryDetail?: string | null,
  request?: string | null,
  shippingItems?: Array< ShippingItemInput | null > | null,
  transportType: TransportType,
  shippingInstructionShipperUserId: string,
  shippingInstructionShippingFeeId: string,
  shippingInstructionFobId?: string | null,
  shippingInstructionFhdId?: string | null,
  shippingInstructionReservationId?: string | null,
};

export type TaxPaymentConditionsInput = {
  realTimeAccount?: string | null,
  comprehensiveDeferredPayment?: string | null,
  cashOnDeliveryAllowed?: boolean | null,
  beforePermissionPickup?: boolean | null,
  othersOption?: string | null,
};

export type CargoInsuranceConditionsInput = {
  comprehensiveInsurance?: string | null,
  individualInsurance?: boolean | null,
  uninsuredWithInsurance?: boolean | null,
  othersOption?: string | null,
};

export type DocumentInput = {
  invoice?: boolean | null,
  packingList?: boolean | null,
  nonApplicabilityCertificate?: boolean | null,
  msds?: boolean | null,
  other?: boolean | null,
  detail?: string | null,
};

export type ContactInput = {
  companyName: string,
  name: string,
  email: string,
  phoneNumber: string,
};

export type ShippingItemInput = {
  width: number,
  length: number,
  height: number,
  weight: number,
  numberOfUnit: number,
  volume: number,
  gw: number,
  volumeWeight: number,
  cw: number,
};

export type ModelShippingInstructionConditionInput = {
  departure?: ModelStringInput | null,
  arrival?: ModelStringInput | null,
  airline?: ModelStringInput | null,
  flightDate?: ModelStringInput | null,
  directFlight?: ModelBooleanInput | null,
  leadTime?: ModelIntInput | null,
  useSaf?: ModelBooleanInput | null,
  yardCutDate?: ModelStringInput | null,
  carrierShedCutDate?: ModelStringInput | null,
  itemName?: ModelStringInput | null,
  packingStyle?: ModelStringInput | null,
  packingStyleDetail?: ModelStringInput | null,
  cargoType?: ModelCargoTypeInput | null,
  isSds?: ModelBooleanInput | null,
  regulated?: ModelStringInput | null,
  regulatedDetail?: ModelStringInput | null,
  paymentCondition?: ModelPaymentConditionInput | null,
  paymentConditionDetail?: ModelStringInput | null,
  cargoInsurance?: ModelBooleanInput | null,
  cargoInsuranceDetail?: ModelStringInput | null,
  packed?: ModelBooleanInput | null,
  packedDetail?: ModelStringInput | null,
  collected?: ModelBooleanInput | null,
  collectedDetail?: ModelStringInput | null,
  delivery?: ModelBooleanInput | null,
  deliveryDetail?: ModelStringInput | null,
  request?: ModelStringInput | null,
  transportType?: ModelTransportTypeInput | null,
  and?: Array< ModelShippingInstructionConditionInput | null > | null,
  or?: Array< ModelShippingInstructionConditionInput | null > | null,
  not?: ModelShippingInstructionConditionInput | null,
  shippingInstructionShipperUserId?: ModelIDInput | null,
  shippingInstructionShippingFeeId?: ModelIDInput | null,
  shippingInstructionFobId?: ModelIDInput | null,
  shippingInstructionFhdId?: ModelIDInput | null,
  shippingInstructionReservationId?: ModelIDInput | null,
};

export type ModelCargoTypeInput = {
  eq?: CargoType | null,
  ne?: CargoType | null,
};

export type ModelPaymentConditionInput = {
  eq?: PaymentCondition | null,
  ne?: PaymentCondition | null,
};

export type ModelTransportTypeInput = {
  eq?: TransportType | null,
  ne?: TransportType | null,
};

export type UpdateShippingInstructionInput = {
  id: string,
  departure?: string | null,
  arrival?: string | null,
  airline?: string | null,
  flightDate?: string | null,
  directFlight?: boolean | null,
  leadTime?: number | null,
  useSaf?: boolean | null,
  yardCutDate?: string | null,
  carrierShedCutDate?: string | null,
  itemName?: string | null,
  packingStyle?: string | null,
  packingStyleDetail?: string | null,
  cargoType?: CargoType | null,
  isSds?: boolean | null,
  regulated?: string | null,
  regulatedDetail?: string | null,
  paymentCondition?: PaymentCondition | null,
  paymentConditionDetail?: string | null,
  taxPaymentConditions?: TaxPaymentConditionsInput | null,
  cargoInsuranceConditions?: CargoInsuranceConditionsInput | null,
  document?: DocumentInput | null,
  consigneeContact?: ContactInput | null,
  shipperContact?: ContactInput | null,
  cargoInsurance?: boolean | null,
  cargoInsuranceDetail?: string | null,
  packed?: boolean | null,
  packedDetail?: string | null,
  collected?: boolean | null,
  collectedDetail?: string | null,
  delivery?: boolean | null,
  deliveryDetail?: string | null,
  request?: string | null,
  shippingItems?: Array< ShippingItemInput | null > | null,
  transportType?: TransportType | null,
  shippingInstructionShipperUserId?: string | null,
  shippingInstructionShippingFeeId?: string | null,
  shippingInstructionFobId?: string | null,
  shippingInstructionFhdId?: string | null,
  shippingInstructionReservationId?: string | null,
};

export type DeleteShippingInstructionInput = {
  id: string,
};

export type CreateReservationInput = {
  id?: string | null,
  hawb?: string | null,
  status?: Status | null,
  forwarderCompanyId?: string | null,
  shipperCompanyId?: string | null,
  departure?: string | null,
  reservationShippingInstructionId?: string | null,
};

export type ModelReservationConditionInput = {
  hawb?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  forwarderCompanyId?: ModelStringInput | null,
  shipperCompanyId?: ModelStringInput | null,
  departure?: ModelStringInput | null,
  and?: Array< ModelReservationConditionInput | null > | null,
  or?: Array< ModelReservationConditionInput | null > | null,
  not?: ModelReservationConditionInput | null,
  reservationShippingInstructionId?: ModelIDInput | null,
};

export type ModelStatusInput = {
  eq?: Status | null,
  ne?: Status | null,
};

export type UpdateReservationInput = {
  id: string,
  hawb?: string | null,
  status?: Status | null,
  forwarderCompanyId?: string | null,
  shipperCompanyId?: string | null,
  departure?: string | null,
  reservationShippingInstructionId?: string | null,
};

export type DeleteReservationInput = {
  id: string,
};

export type CreateCurrencyInput = {
  id?: string | null,
  code: string,
  exchangeRateJPY: number,
};

export type ModelCurrencyConditionInput = {
  code?: ModelStringInput | null,
  exchangeRateJPY?: ModelFloatInput | null,
  and?: Array< ModelCurrencyConditionInput | null > | null,
  or?: Array< ModelCurrencyConditionInput | null > | null,
  not?: ModelCurrencyConditionInput | null,
};

export type UpdateCurrencyInput = {
  id: string,
  code?: string | null,
  exchangeRateJPY?: number | null,
};

export type DeleteCurrencyInput = {
  id: string,
};

export type CreateForwarderUserInput = {
  id?: string | null,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  workLocation: string,
  division: string,
  position?: string | null,
  companyId?: string | null,
};

export type ModelForwarderUserConditionInput = {
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  workLocation?: ModelStringInput | null,
  division?: ModelStringInput | null,
  position?: ModelStringInput | null,
  companyId?: ModelIDInput | null,
  and?: Array< ModelForwarderUserConditionInput | null > | null,
  or?: Array< ModelForwarderUserConditionInput | null > | null,
  not?: ModelForwarderUserConditionInput | null,
};

export type UpdateForwarderUserInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  workLocation?: string | null,
  division?: string | null,
  position?: string | null,
  companyId?: string | null,
};

export type DeleteForwarderUserInput = {
  id: string,
};

export type CreateCustomFeeInput = {
  id?: string | null,
  title: string,
  unitPrice: number,
  unitType: string,
  calculationMethod: CalculationMethod,
  minimum?: number | null,
  maximum?: number | null,
  includeTax?: boolean | null,
  taxRate?: number | null,
  remark?: string | null,
  fobId?: string | null,
  fhdId?: string | null,
  shippingFeeId?: string | null,
  estimateId?: string | null,
  customFeeCurrencyId?: string | null,
};

export type ModelCustomFeeConditionInput = {
  title?: ModelStringInput | null,
  unitPrice?: ModelFloatInput | null,
  unitType?: ModelStringInput | null,
  calculationMethod?: ModelCalculationMethodInput | null,
  minimum?: ModelFloatInput | null,
  maximum?: ModelFloatInput | null,
  includeTax?: ModelBooleanInput | null,
  taxRate?: ModelFloatInput | null,
  remark?: ModelStringInput | null,
  fobId?: ModelIDInput | null,
  fhdId?: ModelIDInput | null,
  shippingFeeId?: ModelIDInput | null,
  estimateId?: ModelIDInput | null,
  and?: Array< ModelCustomFeeConditionInput | null > | null,
  or?: Array< ModelCustomFeeConditionInput | null > | null,
  not?: ModelCustomFeeConditionInput | null,
  customFeeCurrencyId?: ModelIDInput | null,
};

export type ModelCalculationMethodInput = {
  eq?: CalculationMethod | null,
  ne?: CalculationMethod | null,
};

export type UpdateCustomFeeInput = {
  id: string,
  title?: string | null,
  unitPrice?: number | null,
  unitType?: string | null,
  calculationMethod?: CalculationMethod | null,
  minimum?: number | null,
  maximum?: number | null,
  includeTax?: boolean | null,
  taxRate?: number | null,
  remark?: string | null,
  fobId?: string | null,
  fhdId?: string | null,
  shippingFeeId?: string | null,
  estimateId?: string | null,
  customFeeCurrencyId?: string | null,
};

export type DeleteCustomFeeInput = {
  id: string,
};

export type CreateForwarderCompanyInput = {
  id?: string | null,
  name: string,
  firstName: string,
  lastName: string,
  location: string,
  establishment: string,
  paidInCaptial: number,
  corporateNumber: string,
  iataAgency: string,
  listed: boolean,
  numberOfEmployees: NumberOfEmployees,
  companyURL: string,
  forwarderCompanyManagerId: string,
};

export type ModelForwarderCompanyConditionInput = {
  name?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  location?: ModelStringInput | null,
  establishment?: ModelStringInput | null,
  paidInCaptial?: ModelIntInput | null,
  corporateNumber?: ModelStringInput | null,
  iataAgency?: ModelStringInput | null,
  listed?: ModelBooleanInput | null,
  numberOfEmployees?: ModelNumberOfEmployeesInput | null,
  companyURL?: ModelStringInput | null,
  and?: Array< ModelForwarderCompanyConditionInput | null > | null,
  or?: Array< ModelForwarderCompanyConditionInput | null > | null,
  not?: ModelForwarderCompanyConditionInput | null,
  forwarderCompanyManagerId?: ModelIDInput | null,
};

export type ModelNumberOfEmployeesInput = {
  eq?: NumberOfEmployees | null,
  ne?: NumberOfEmployees | null,
};

export type UpdateForwarderCompanyInput = {
  id: string,
  name?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  location?: string | null,
  establishment?: string | null,
  paidInCaptial?: number | null,
  corporateNumber?: string | null,
  iataAgency?: string | null,
  listed?: boolean | null,
  numberOfEmployees?: NumberOfEmployees | null,
  companyURL?: string | null,
  forwarderCompanyManagerId?: string | null,
};

export type DeleteForwarderCompanyInput = {
  id: string,
};

export type CreateShipperCompanyInput = {
  id?: string | null,
  name: string,
  firstName: string,
  lastName: string,
  location: string,
  establishment: string,
  paidInCaptial: number,
  corporateNumber: string,
  category: string,
  listed: boolean,
  numberOfEmployees: NumberOfEmployees,
  companyURL: string,
  shipperCompanyManagerId: string,
};

export type ModelShipperCompanyConditionInput = {
  name?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  location?: ModelStringInput | null,
  establishment?: ModelStringInput | null,
  paidInCaptial?: ModelIntInput | null,
  corporateNumber?: ModelStringInput | null,
  category?: ModelStringInput | null,
  listed?: ModelBooleanInput | null,
  numberOfEmployees?: ModelNumberOfEmployeesInput | null,
  companyURL?: ModelStringInput | null,
  and?: Array< ModelShipperCompanyConditionInput | null > | null,
  or?: Array< ModelShipperCompanyConditionInput | null > | null,
  not?: ModelShipperCompanyConditionInput | null,
  shipperCompanyManagerId?: ModelIDInput | null,
};

export type UpdateShipperCompanyInput = {
  id: string,
  name?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  location?: string | null,
  establishment?: string | null,
  paidInCaptial?: number | null,
  corporateNumber?: string | null,
  category?: string | null,
  listed?: boolean | null,
  numberOfEmployees?: NumberOfEmployees | null,
  companyURL?: string | null,
  shipperCompanyManagerId?: string | null,
};

export type DeleteShipperCompanyInput = {
  id: string,
};

export type CreateEstimateInput = {
  id?: string | null,
  departure: string,
  arrival: string,
  airline: string,
  flightDate: string,
  directFlight: boolean,
  leadTime: number,
  useSaf: boolean,
  cargoType: CargoType,
  yardCutDate: string,
  carrierShedCutDate: string,
  minimum: number,
  under45: number,
  over45: number,
  over100: number,
  over300: number,
  over500: number,
  over1000: number,
  fsc: number,
  remark?: string | null,
  status: EstimateStatus,
  forwarderUserEstimatesId?: string | null,
  estimateCurrencyId?: string | null,
};

export type ModelEstimateConditionInput = {
  departure?: ModelStringInput | null,
  arrival?: ModelStringInput | null,
  airline?: ModelStringInput | null,
  flightDate?: ModelStringInput | null,
  directFlight?: ModelBooleanInput | null,
  leadTime?: ModelIntInput | null,
  useSaf?: ModelBooleanInput | null,
  cargoType?: ModelCargoTypeInput | null,
  yardCutDate?: ModelStringInput | null,
  carrierShedCutDate?: ModelStringInput | null,
  minimum?: ModelFloatInput | null,
  under45?: ModelFloatInput | null,
  over45?: ModelFloatInput | null,
  over100?: ModelFloatInput | null,
  over300?: ModelFloatInput | null,
  over500?: ModelFloatInput | null,
  over1000?: ModelFloatInput | null,
  fsc?: ModelFloatInput | null,
  remark?: ModelStringInput | null,
  status?: ModelEstimateStatusInput | null,
  and?: Array< ModelEstimateConditionInput | null > | null,
  or?: Array< ModelEstimateConditionInput | null > | null,
  not?: ModelEstimateConditionInput | null,
  forwarderUserEstimatesId?: ModelIDInput | null,
  estimateCurrencyId?: ModelIDInput | null,
};

export type ModelEstimateStatusInput = {
  eq?: EstimateStatus | null,
  ne?: EstimateStatus | null,
};

export type UpdateEstimateInput = {
  id: string,
  departure?: string | null,
  arrival?: string | null,
  airline?: string | null,
  flightDate?: string | null,
  directFlight?: boolean | null,
  leadTime?: number | null,
  useSaf?: boolean | null,
  cargoType?: CargoType | null,
  yardCutDate?: string | null,
  carrierShedCutDate?: string | null,
  minimum?: number | null,
  under45?: number | null,
  over45?: number | null,
  over100?: number | null,
  over300?: number | null,
  over500?: number | null,
  over1000?: number | null,
  fsc?: number | null,
  remark?: string | null,
  status?: EstimateStatus | null,
  forwarderUserEstimatesId?: string | null,
  estimateCurrencyId?: string | null,
};

export type DeleteEstimateInput = {
  id: string,
};

export type CreateNewsInput = {
  id?: string | null,
  title: string,
  newsDate: string,
  newsContent?: string | null,
  mediaUrl?: string | null,
};

export type ModelNewsConditionInput = {
  title?: ModelStringInput | null,
  newsDate?: ModelStringInput | null,
  newsContent?: ModelStringInput | null,
  mediaUrl?: ModelStringInput | null,
  and?: Array< ModelNewsConditionInput | null > | null,
  or?: Array< ModelNewsConditionInput | null > | null,
  not?: ModelNewsConditionInput | null,
};

export type News = {
  __typename: "News",
  id: string,
  title: string,
  newsDate: string,
  newsContent?: string | null,
  mediaUrl?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateNewsInput = {
  id: string,
  title?: string | null,
  newsDate?: string | null,
  newsContent?: string | null,
  mediaUrl?: string | null,
};

export type DeleteNewsInput = {
  id: string,
};

export type CreateMarketConditionsWithSafInput = {
  id?: string | null,
  departure: string,
  arrival: string,
  flightDate: string,
  marketPrice: number,
};

export type ModelMarketConditionsWithSafConditionInput = {
  departure?: ModelStringInput | null,
  arrival?: ModelStringInput | null,
  flightDate?: ModelStringInput | null,
  marketPrice?: ModelIntInput | null,
  and?: Array< ModelMarketConditionsWithSafConditionInput | null > | null,
  or?: Array< ModelMarketConditionsWithSafConditionInput | null > | null,
  not?: ModelMarketConditionsWithSafConditionInput | null,
};

export type MarketConditionsWithSaf = {
  __typename: "MarketConditionsWithSaf",
  id: string,
  departure: string,
  arrival: string,
  flightDate: string,
  marketPrice: number,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateMarketConditionsWithSafInput = {
  id: string,
  departure?: string | null,
  arrival?: string | null,
  flightDate?: string | null,
  marketPrice?: number | null,
};

export type DeleteMarketConditionsWithSafInput = {
  id: string,
};

export type CreateMarketConditionsWithoutSafInput = {
  id?: string | null,
  departure: string,
  arrival: string,
  flightDate: string,
  marketPrice: number,
};

export type ModelMarketConditionsWithoutSafConditionInput = {
  departure?: ModelStringInput | null,
  arrival?: ModelStringInput | null,
  flightDate?: ModelStringInput | null,
  marketPrice?: ModelIntInput | null,
  and?: Array< ModelMarketConditionsWithoutSafConditionInput | null > | null,
  or?: Array< ModelMarketConditionsWithoutSafConditionInput | null > | null,
  not?: ModelMarketConditionsWithoutSafConditionInput | null,
};

export type MarketConditionsWithoutSaf = {
  __typename: "MarketConditionsWithoutSaf",
  id: string,
  departure: string,
  arrival: string,
  flightDate: string,
  marketPrice: number,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateMarketConditionsWithoutSafInput = {
  id: string,
  departure?: string | null,
  arrival?: string | null,
  flightDate?: string | null,
  marketPrice?: number | null,
};

export type DeleteMarketConditionsWithoutSafInput = {
  id: string,
};

export type SendInvitationEventInput = {
  to: string,
};

export type StatusResult = {
  __typename: "StatusResult",
  statusCode?: number | null,
  body?: string | null,
};

export type ModelShipperUserFilterInput = {
  id?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  workLocation?: ModelStringInput | null,
  division?: ModelStringInput | null,
  position?: ModelStringInput | null,
  area?: ModelStringInput | null,
  role?: ModelShipperRoleInput | null,
  companyId?: ModelIDInput | null,
  and?: Array< ModelShipperUserFilterInput | null > | null,
  or?: Array< ModelShipperUserFilterInput | null > | null,
  not?: ModelShipperUserFilterInput | null,
};

export type ModelFobFilterInput = {
  id?: ModelIDInput | null,
  airport?: ModelStringInput | null,
  forwarderCompanyId?: ModelIDInput | null,
  remark?: ModelStringInput | null,
  and?: Array< ModelFobFilterInput | null > | null,
  or?: Array< ModelFobFilterInput | null > | null,
  not?: ModelFobFilterInput | null,
};

export type ModelFhdFilterInput = {
  id?: ModelIDInput | null,
  airport?: ModelStringInput | null,
  forwarderCompanyId?: ModelIDInput | null,
  remark?: ModelStringInput | null,
  and?: Array< ModelFhdFilterInput | null > | null,
  or?: Array< ModelFhdFilterInput | null > | null,
  not?: ModelFhdFilterInput | null,
};

export type ModelForwarderReviewFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  rate?: ModelIntInput | null,
  and?: Array< ModelForwarderReviewFilterInput | null > | null,
  or?: Array< ModelForwarderReviewFilterInput | null > | null,
  not?: ModelForwarderReviewFilterInput | null,
  forwarderUserPostedReviewsId?: ModelIDInput | null,
  shipperCompanyReceivedReviewsId?: ModelIDInput | null,
};

export type ModelShipperReviewFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  rate?: ModelIntInput | null,
  and?: Array< ModelShipperReviewFilterInput | null > | null,
  or?: Array< ModelShipperReviewFilterInput | null > | null,
  not?: ModelShipperReviewFilterInput | null,
  shipperUserPostedReviewsId?: ModelIDInput | null,
  forwarderCompanyReceivedReviewsId?: ModelIDInput | null,
};

export type ModelInvitedUserFilterInput = {
  id?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  companyId?: ModelIDInput | null,
  isForwarder?: ModelBooleanInput | null,
  and?: Array< ModelInvitedUserFilterInput | null > | null,
  or?: Array< ModelInvitedUserFilterInput | null > | null,
  not?: ModelInvitedUserFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelInvitedUserConnection = {
  __typename: "ModelInvitedUserConnection",
  items:  Array<InvitedUser | null >,
  nextToken?: string | null,
};

export type ModelShippingCustomFeeFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  unitPrice?: ModelFloatInput | null,
  unitType?: ModelStringInput | null,
  fee?: ModelFloatInput | null,
  taxRate?: ModelFloatInput | null,
  maximum?: ModelFloatInput | null,
  minimum?: ModelFloatInput | null,
  remark?: ModelStringInput | null,
  and?: Array< ModelShippingCustomFeeFilterInput | null > | null,
  or?: Array< ModelShippingCustomFeeFilterInput | null > | null,
  not?: ModelShippingCustomFeeFilterInput | null,
  shippingFeeCustomFeesId?: ModelIDInput | null,
  shippingFobFeeCustomFeesId?: ModelIDInput | null,
  shippingFhdFeeCustomFeesId?: ModelIDInput | null,
  shippingCustomFeeCurrencyId?: ModelIDInput | null,
};

export type ModelShippingFeeFilterInput = {
  id?: ModelIDInput | null,
  price?: ModelFloatInput | null,
  fsc?: ModelFloatInput | null,
  minimum?: ModelFloatInput | null,
  remark?: ModelStringInput | null,
  and?: Array< ModelShippingFeeFilterInput | null > | null,
  or?: Array< ModelShippingFeeFilterInput | null > | null,
  not?: ModelShippingFeeFilterInput | null,
  shippingFeeCurrencyId?: ModelIDInput | null,
  shippingFeeShippingInstructionId?: ModelIDInput | null,
};

export type ModelShippingFeeConnection = {
  __typename: "ModelShippingFeeConnection",
  items:  Array<ShippingFee | null >,
  nextToken?: string | null,
};

export type ModelShippingFobFeeFilterInput = {
  id?: ModelIDInput | null,
  remark?: ModelStringInput | null,
  and?: Array< ModelShippingFobFeeFilterInput | null > | null,
  or?: Array< ModelShippingFobFeeFilterInput | null > | null,
  not?: ModelShippingFobFeeFilterInput | null,
  shippingFobFeeShippingInstructionId?: ModelIDInput | null,
};

export type ModelShippingFobFeeConnection = {
  __typename: "ModelShippingFobFeeConnection",
  items:  Array<ShippingFobFee | null >,
  nextToken?: string | null,
};

export type ModelShippingFhdFeeFilterInput = {
  id?: ModelIDInput | null,
  remark?: ModelStringInput | null,
  and?: Array< ModelShippingFhdFeeFilterInput | null > | null,
  or?: Array< ModelShippingFhdFeeFilterInput | null > | null,
  not?: ModelShippingFhdFeeFilterInput | null,
  shippingFhdFeeShippingInstructionId?: ModelIDInput | null,
};

export type ModelShippingFhdFeeConnection = {
  __typename: "ModelShippingFhdFeeConnection",
  items:  Array<ShippingFhdFee | null >,
  nextToken?: string | null,
};

export type ModelShippingInstructionFilterInput = {
  id?: ModelIDInput | null,
  departure?: ModelStringInput | null,
  arrival?: ModelStringInput | null,
  airline?: ModelStringInput | null,
  flightDate?: ModelStringInput | null,
  directFlight?: ModelBooleanInput | null,
  leadTime?: ModelIntInput | null,
  useSaf?: ModelBooleanInput | null,
  yardCutDate?: ModelStringInput | null,
  carrierShedCutDate?: ModelStringInput | null,
  itemName?: ModelStringInput | null,
  packingStyle?: ModelStringInput | null,
  packingStyleDetail?: ModelStringInput | null,
  cargoType?: ModelCargoTypeInput | null,
  isSds?: ModelBooleanInput | null,
  regulated?: ModelStringInput | null,
  regulatedDetail?: ModelStringInput | null,
  paymentCondition?: ModelPaymentConditionInput | null,
  paymentConditionDetail?: ModelStringInput | null,
  cargoInsurance?: ModelBooleanInput | null,
  cargoInsuranceDetail?: ModelStringInput | null,
  packed?: ModelBooleanInput | null,
  packedDetail?: ModelStringInput | null,
  collected?: ModelBooleanInput | null,
  collectedDetail?: ModelStringInput | null,
  delivery?: ModelBooleanInput | null,
  deliveryDetail?: ModelStringInput | null,
  request?: ModelStringInput | null,
  transportType?: ModelTransportTypeInput | null,
  and?: Array< ModelShippingInstructionFilterInput | null > | null,
  or?: Array< ModelShippingInstructionFilterInput | null > | null,
  not?: ModelShippingInstructionFilterInput | null,
  shippingInstructionShipperUserId?: ModelIDInput | null,
  shippingInstructionShippingFeeId?: ModelIDInput | null,
  shippingInstructionFobId?: ModelIDInput | null,
  shippingInstructionFhdId?: ModelIDInput | null,
  shippingInstructionReservationId?: ModelIDInput | null,
};

export type ModelShippingInstructionConnection = {
  __typename: "ModelShippingInstructionConnection",
  items:  Array<ShippingInstruction | null >,
  nextToken?: string | null,
};

export type ModelReservationFilterInput = {
  id?: ModelIDInput | null,
  hawb?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  forwarderCompanyId?: ModelStringInput | null,
  shipperCompanyId?: ModelStringInput | null,
  departure?: ModelStringInput | null,
  and?: Array< ModelReservationFilterInput | null > | null,
  or?: Array< ModelReservationFilterInput | null > | null,
  not?: ModelReservationFilterInput | null,
  reservationShippingInstructionId?: ModelIDInput | null,
};

export type ModelReservationConnection = {
  __typename: "ModelReservationConnection",
  items:  Array<Reservation | null >,
  nextToken?: string | null,
};

export type ModelCurrencyFilterInput = {
  id?: ModelIDInput | null,
  code?: ModelStringInput | null,
  exchangeRateJPY?: ModelFloatInput | null,
  and?: Array< ModelCurrencyFilterInput | null > | null,
  or?: Array< ModelCurrencyFilterInput | null > | null,
  not?: ModelCurrencyFilterInput | null,
};

export type ModelCurrencyConnection = {
  __typename: "ModelCurrencyConnection",
  items:  Array<Currency | null >,
  nextToken?: string | null,
};

export type ModelForwarderUserFilterInput = {
  id?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  workLocation?: ModelStringInput | null,
  division?: ModelStringInput | null,
  position?: ModelStringInput | null,
  companyId?: ModelIDInput | null,
  and?: Array< ModelForwarderUserFilterInput | null > | null,
  or?: Array< ModelForwarderUserFilterInput | null > | null,
  not?: ModelForwarderUserFilterInput | null,
};

export type ModelCustomFeeFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  unitPrice?: ModelFloatInput | null,
  unitType?: ModelStringInput | null,
  calculationMethod?: ModelCalculationMethodInput | null,
  minimum?: ModelFloatInput | null,
  maximum?: ModelFloatInput | null,
  includeTax?: ModelBooleanInput | null,
  taxRate?: ModelFloatInput | null,
  remark?: ModelStringInput | null,
  fobId?: ModelIDInput | null,
  fhdId?: ModelIDInput | null,
  shippingFeeId?: ModelIDInput | null,
  estimateId?: ModelIDInput | null,
  and?: Array< ModelCustomFeeFilterInput | null > | null,
  or?: Array< ModelCustomFeeFilterInput | null > | null,
  not?: ModelCustomFeeFilterInput | null,
  customFeeCurrencyId?: ModelIDInput | null,
};

export type ModelForwarderCompanyFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  location?: ModelStringInput | null,
  establishment?: ModelStringInput | null,
  paidInCaptial?: ModelIntInput | null,
  corporateNumber?: ModelStringInput | null,
  iataAgency?: ModelStringInput | null,
  listed?: ModelBooleanInput | null,
  numberOfEmployees?: ModelNumberOfEmployeesInput | null,
  companyURL?: ModelStringInput | null,
  and?: Array< ModelForwarderCompanyFilterInput | null > | null,
  or?: Array< ModelForwarderCompanyFilterInput | null > | null,
  not?: ModelForwarderCompanyFilterInput | null,
  forwarderCompanyManagerId?: ModelIDInput | null,
};

export type ModelForwarderCompanyConnection = {
  __typename: "ModelForwarderCompanyConnection",
  items:  Array<ForwarderCompany | null >,
  nextToken?: string | null,
};

export type ModelShipperCompanyFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  location?: ModelStringInput | null,
  establishment?: ModelStringInput | null,
  paidInCaptial?: ModelIntInput | null,
  corporateNumber?: ModelStringInput | null,
  category?: ModelStringInput | null,
  listed?: ModelBooleanInput | null,
  numberOfEmployees?: ModelNumberOfEmployeesInput | null,
  companyURL?: ModelStringInput | null,
  and?: Array< ModelShipperCompanyFilterInput | null > | null,
  or?: Array< ModelShipperCompanyFilterInput | null > | null,
  not?: ModelShipperCompanyFilterInput | null,
  shipperCompanyManagerId?: ModelIDInput | null,
};

export type ModelShipperCompanyConnection = {
  __typename: "ModelShipperCompanyConnection",
  items:  Array<ShipperCompany | null >,
  nextToken?: string | null,
};

export type ModelEstimateFilterInput = {
  id?: ModelIDInput | null,
  departure?: ModelStringInput | null,
  arrival?: ModelStringInput | null,
  airline?: ModelStringInput | null,
  flightDate?: ModelStringInput | null,
  directFlight?: ModelBooleanInput | null,
  leadTime?: ModelIntInput | null,
  useSaf?: ModelBooleanInput | null,
  cargoType?: ModelCargoTypeInput | null,
  yardCutDate?: ModelStringInput | null,
  carrierShedCutDate?: ModelStringInput | null,
  minimum?: ModelFloatInput | null,
  under45?: ModelFloatInput | null,
  over45?: ModelFloatInput | null,
  over100?: ModelFloatInput | null,
  over300?: ModelFloatInput | null,
  over500?: ModelFloatInput | null,
  over1000?: ModelFloatInput | null,
  fsc?: ModelFloatInput | null,
  remark?: ModelStringInput | null,
  status?: ModelEstimateStatusInput | null,
  and?: Array< ModelEstimateFilterInput | null > | null,
  or?: Array< ModelEstimateFilterInput | null > | null,
  not?: ModelEstimateFilterInput | null,
  forwarderUserEstimatesId?: ModelIDInput | null,
  estimateCurrencyId?: ModelIDInput | null,
};

export type ModelNewsFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  newsDate?: ModelStringInput | null,
  newsContent?: ModelStringInput | null,
  mediaUrl?: ModelStringInput | null,
  and?: Array< ModelNewsFilterInput | null > | null,
  or?: Array< ModelNewsFilterInput | null > | null,
  not?: ModelNewsFilterInput | null,
};

export type ModelNewsConnection = {
  __typename: "ModelNewsConnection",
  items:  Array<News | null >,
  nextToken?: string | null,
};

export type ModelMarketConditionsWithSafFilterInput = {
  id?: ModelIDInput | null,
  departure?: ModelStringInput | null,
  arrival?: ModelStringInput | null,
  flightDate?: ModelStringInput | null,
  marketPrice?: ModelIntInput | null,
  and?: Array< ModelMarketConditionsWithSafFilterInput | null > | null,
  or?: Array< ModelMarketConditionsWithSafFilterInput | null > | null,
  not?: ModelMarketConditionsWithSafFilterInput | null,
};

export type ModelMarketConditionsWithSafConnection = {
  __typename: "ModelMarketConditionsWithSafConnection",
  items:  Array<MarketConditionsWithSaf | null >,
  nextToken?: string | null,
};

export type ModelMarketConditionsWithoutSafFilterInput = {
  id?: ModelIDInput | null,
  departure?: ModelStringInput | null,
  arrival?: ModelStringInput | null,
  flightDate?: ModelStringInput | null,
  marketPrice?: ModelIntInput | null,
  and?: Array< ModelMarketConditionsWithoutSafFilterInput | null > | null,
  or?: Array< ModelMarketConditionsWithoutSafFilterInput | null > | null,
  not?: ModelMarketConditionsWithoutSafFilterInput | null,
};

export type ModelMarketConditionsWithoutSafConnection = {
  __typename: "ModelMarketConditionsWithoutSafConnection",
  items:  Array<MarketConditionsWithoutSaf | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionShipperUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  workLocation?: ModelSubscriptionStringInput | null,
  division?: ModelSubscriptionStringInput | null,
  position?: ModelSubscriptionStringInput | null,
  area?: ModelSubscriptionStringInput | null,
  role?: ModelSubscriptionStringInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionShipperUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionShipperUserFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionFobFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  airport?: ModelSubscriptionStringInput | null,
  forwarderCompanyId?: ModelSubscriptionIDInput | null,
  remark?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFobFilterInput | null > | null,
  or?: Array< ModelSubscriptionFobFilterInput | null > | null,
};

export type ModelSubscriptionFhdFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  airport?: ModelSubscriptionStringInput | null,
  forwarderCompanyId?: ModelSubscriptionIDInput | null,
  remark?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFhdFilterInput | null > | null,
  or?: Array< ModelSubscriptionFhdFilterInput | null > | null,
};

export type ModelSubscriptionForwarderReviewFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  rate?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionForwarderReviewFilterInput | null > | null,
  or?: Array< ModelSubscriptionForwarderReviewFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionShipperReviewFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  rate?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionShipperReviewFilterInput | null > | null,
  or?: Array< ModelSubscriptionShipperReviewFilterInput | null > | null,
};

export type ModelSubscriptionInvitedUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  isForwarder?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionInvitedUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionInvitedUserFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionShippingCustomFeeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  unitPrice?: ModelSubscriptionFloatInput | null,
  unitType?: ModelSubscriptionStringInput | null,
  fee?: ModelSubscriptionFloatInput | null,
  taxRate?: ModelSubscriptionFloatInput | null,
  maximum?: ModelSubscriptionFloatInput | null,
  minimum?: ModelSubscriptionFloatInput | null,
  remark?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionShippingCustomFeeFilterInput | null > | null,
  or?: Array< ModelSubscriptionShippingCustomFeeFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionShippingFeeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  price?: ModelSubscriptionFloatInput | null,
  fsc?: ModelSubscriptionFloatInput | null,
  minimum?: ModelSubscriptionFloatInput | null,
  remark?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionShippingFeeFilterInput | null > | null,
  or?: Array< ModelSubscriptionShippingFeeFilterInput | null > | null,
};

export type ModelSubscriptionShippingFobFeeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  remark?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionShippingFobFeeFilterInput | null > | null,
  or?: Array< ModelSubscriptionShippingFobFeeFilterInput | null > | null,
};

export type ModelSubscriptionShippingFhdFeeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  remark?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionShippingFhdFeeFilterInput | null > | null,
  or?: Array< ModelSubscriptionShippingFhdFeeFilterInput | null > | null,
};

export type ModelSubscriptionShippingInstructionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  departure?: ModelSubscriptionStringInput | null,
  arrival?: ModelSubscriptionStringInput | null,
  airline?: ModelSubscriptionStringInput | null,
  flightDate?: ModelSubscriptionStringInput | null,
  directFlight?: ModelSubscriptionBooleanInput | null,
  leadTime?: ModelSubscriptionIntInput | null,
  useSaf?: ModelSubscriptionBooleanInput | null,
  yardCutDate?: ModelSubscriptionStringInput | null,
  carrierShedCutDate?: ModelSubscriptionStringInput | null,
  itemName?: ModelSubscriptionStringInput | null,
  packingStyle?: ModelSubscriptionStringInput | null,
  packingStyleDetail?: ModelSubscriptionStringInput | null,
  cargoType?: ModelSubscriptionStringInput | null,
  isSds?: ModelSubscriptionBooleanInput | null,
  regulated?: ModelSubscriptionStringInput | null,
  regulatedDetail?: ModelSubscriptionStringInput | null,
  paymentCondition?: ModelSubscriptionStringInput | null,
  paymentConditionDetail?: ModelSubscriptionStringInput | null,
  cargoInsurance?: ModelSubscriptionBooleanInput | null,
  cargoInsuranceDetail?: ModelSubscriptionStringInput | null,
  packed?: ModelSubscriptionBooleanInput | null,
  packedDetail?: ModelSubscriptionStringInput | null,
  collected?: ModelSubscriptionBooleanInput | null,
  collectedDetail?: ModelSubscriptionStringInput | null,
  delivery?: ModelSubscriptionBooleanInput | null,
  deliveryDetail?: ModelSubscriptionStringInput | null,
  request?: ModelSubscriptionStringInput | null,
  transportType?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionShippingInstructionFilterInput | null > | null,
  or?: Array< ModelSubscriptionShippingInstructionFilterInput | null > | null,
};

export type ModelSubscriptionReservationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  hawb?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  forwarderCompanyId?: ModelSubscriptionStringInput | null,
  shipperCompanyId?: ModelSubscriptionStringInput | null,
  departure?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReservationFilterInput | null > | null,
  or?: Array< ModelSubscriptionReservationFilterInput | null > | null,
};

export type ModelSubscriptionCurrencyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  code?: ModelSubscriptionStringInput | null,
  exchangeRateJPY?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionCurrencyFilterInput | null > | null,
  or?: Array< ModelSubscriptionCurrencyFilterInput | null > | null,
};

export type ModelSubscriptionForwarderUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  workLocation?: ModelSubscriptionStringInput | null,
  division?: ModelSubscriptionStringInput | null,
  position?: ModelSubscriptionStringInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionForwarderUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionForwarderUserFilterInput | null > | null,
};

export type ModelSubscriptionCustomFeeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  unitPrice?: ModelSubscriptionFloatInput | null,
  unitType?: ModelSubscriptionStringInput | null,
  calculationMethod?: ModelSubscriptionStringInput | null,
  minimum?: ModelSubscriptionFloatInput | null,
  maximum?: ModelSubscriptionFloatInput | null,
  includeTax?: ModelSubscriptionBooleanInput | null,
  taxRate?: ModelSubscriptionFloatInput | null,
  remark?: ModelSubscriptionStringInput | null,
  fobId?: ModelSubscriptionIDInput | null,
  fhdId?: ModelSubscriptionIDInput | null,
  shippingFeeId?: ModelSubscriptionIDInput | null,
  estimateId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionCustomFeeFilterInput | null > | null,
  or?: Array< ModelSubscriptionCustomFeeFilterInput | null > | null,
};

export type ModelSubscriptionForwarderCompanyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  location?: ModelSubscriptionStringInput | null,
  establishment?: ModelSubscriptionStringInput | null,
  paidInCaptial?: ModelSubscriptionIntInput | null,
  corporateNumber?: ModelSubscriptionStringInput | null,
  iataAgency?: ModelSubscriptionStringInput | null,
  listed?: ModelSubscriptionBooleanInput | null,
  numberOfEmployees?: ModelSubscriptionStringInput | null,
  companyURL?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionForwarderCompanyFilterInput | null > | null,
  or?: Array< ModelSubscriptionForwarderCompanyFilterInput | null > | null,
};

export type ModelSubscriptionShipperCompanyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  location?: ModelSubscriptionStringInput | null,
  establishment?: ModelSubscriptionStringInput | null,
  paidInCaptial?: ModelSubscriptionIntInput | null,
  corporateNumber?: ModelSubscriptionStringInput | null,
  category?: ModelSubscriptionStringInput | null,
  listed?: ModelSubscriptionBooleanInput | null,
  numberOfEmployees?: ModelSubscriptionStringInput | null,
  companyURL?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionShipperCompanyFilterInput | null > | null,
  or?: Array< ModelSubscriptionShipperCompanyFilterInput | null > | null,
};

export type ModelSubscriptionEstimateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  departure?: ModelSubscriptionStringInput | null,
  arrival?: ModelSubscriptionStringInput | null,
  airline?: ModelSubscriptionStringInput | null,
  flightDate?: ModelSubscriptionStringInput | null,
  directFlight?: ModelSubscriptionBooleanInput | null,
  leadTime?: ModelSubscriptionIntInput | null,
  useSaf?: ModelSubscriptionBooleanInput | null,
  cargoType?: ModelSubscriptionStringInput | null,
  yardCutDate?: ModelSubscriptionStringInput | null,
  carrierShedCutDate?: ModelSubscriptionStringInput | null,
  minimum?: ModelSubscriptionFloatInput | null,
  under45?: ModelSubscriptionFloatInput | null,
  over45?: ModelSubscriptionFloatInput | null,
  over100?: ModelSubscriptionFloatInput | null,
  over300?: ModelSubscriptionFloatInput | null,
  over500?: ModelSubscriptionFloatInput | null,
  over1000?: ModelSubscriptionFloatInput | null,
  fsc?: ModelSubscriptionFloatInput | null,
  remark?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEstimateFilterInput | null > | null,
  or?: Array< ModelSubscriptionEstimateFilterInput | null > | null,
};

export type ModelSubscriptionNewsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  newsDate?: ModelSubscriptionStringInput | null,
  newsContent?: ModelSubscriptionStringInput | null,
  mediaUrl?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNewsFilterInput | null > | null,
  or?: Array< ModelSubscriptionNewsFilterInput | null > | null,
};

export type ModelSubscriptionMarketConditionsWithSafFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  departure?: ModelSubscriptionStringInput | null,
  arrival?: ModelSubscriptionStringInput | null,
  flightDate?: ModelSubscriptionStringInput | null,
  marketPrice?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionMarketConditionsWithSafFilterInput | null > | null,
  or?: Array< ModelSubscriptionMarketConditionsWithSafFilterInput | null > | null,
};

export type ModelSubscriptionMarketConditionsWithoutSafFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  departure?: ModelSubscriptionStringInput | null,
  arrival?: ModelSubscriptionStringInput | null,
  flightDate?: ModelSubscriptionStringInput | null,
  marketPrice?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionMarketConditionsWithoutSafFilterInput | null > | null,
  or?: Array< ModelSubscriptionMarketConditionsWithoutSafFilterInput | null > | null,
};

export type CreateShipperUserMutationVariables = {
  input: CreateShipperUserInput,
  condition?: ModelShipperUserConditionInput | null,
};

export type CreateShipperUserMutation = {
  createShipperUser?:  {
    __typename: "ShipperUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    workLocation: string,
    division: string,
    position?: string | null,
    area: string,
    role: ShipperRole,
    companyId?: string | null,
    company?:  {
      __typename: "ShipperCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      category: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      employees?:  {
        __typename: "ModelShipperUserConnection",
        items:  Array< {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shipperCompanyManagerId: string,
      owner?: string | null,
    } | null,
    postedReviews?:  {
      __typename: "ModelShipperReviewConnection",
      items:  Array< {
        __typename: "ShipperReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperUserPostedReviewsId?: string | null,
        forwarderCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateShipperUserMutationVariables = {
  input: UpdateShipperUserInput,
  condition?: ModelShipperUserConditionInput | null,
};

export type UpdateShipperUserMutation = {
  updateShipperUser?:  {
    __typename: "ShipperUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    workLocation: string,
    division: string,
    position?: string | null,
    area: string,
    role: ShipperRole,
    companyId?: string | null,
    company?:  {
      __typename: "ShipperCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      category: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      employees?:  {
        __typename: "ModelShipperUserConnection",
        items:  Array< {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shipperCompanyManagerId: string,
      owner?: string | null,
    } | null,
    postedReviews?:  {
      __typename: "ModelShipperReviewConnection",
      items:  Array< {
        __typename: "ShipperReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperUserPostedReviewsId?: string | null,
        forwarderCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteShipperUserMutationVariables = {
  input: DeleteShipperUserInput,
  condition?: ModelShipperUserConditionInput | null,
};

export type DeleteShipperUserMutation = {
  deleteShipperUser?:  {
    __typename: "ShipperUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    workLocation: string,
    division: string,
    position?: string | null,
    area: string,
    role: ShipperRole,
    companyId?: string | null,
    company?:  {
      __typename: "ShipperCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      category: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      employees?:  {
        __typename: "ModelShipperUserConnection",
        items:  Array< {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shipperCompanyManagerId: string,
      owner?: string | null,
    } | null,
    postedReviews?:  {
      __typename: "ModelShipperReviewConnection",
      items:  Array< {
        __typename: "ShipperReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperUserPostedReviewsId?: string | null,
        forwarderCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateFobMutationVariables = {
  input: CreateFobInput,
  condition?: ModelFobConditionInput | null,
};

export type CreateFobMutation = {
  createFob?:  {
    __typename: "Fob",
    id: string,
    airport: string,
    forwarderCompanyId: string,
    forwarder?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateFobMutationVariables = {
  input: UpdateFobInput,
  condition?: ModelFobConditionInput | null,
};

export type UpdateFobMutation = {
  updateFob?:  {
    __typename: "Fob",
    id: string,
    airport: string,
    forwarderCompanyId: string,
    forwarder?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteFobMutationVariables = {
  input: DeleteFobInput,
  condition?: ModelFobConditionInput | null,
};

export type DeleteFobMutation = {
  deleteFob?:  {
    __typename: "Fob",
    id: string,
    airport: string,
    forwarderCompanyId: string,
    forwarder?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateFhdMutationVariables = {
  input: CreateFhdInput,
  condition?: ModelFhdConditionInput | null,
};

export type CreateFhdMutation = {
  createFhd?:  {
    __typename: "Fhd",
    id: string,
    airport: string,
    forwarderCompanyId: string,
    forwarder?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateFhdMutationVariables = {
  input: UpdateFhdInput,
  condition?: ModelFhdConditionInput | null,
};

export type UpdateFhdMutation = {
  updateFhd?:  {
    __typename: "Fhd",
    id: string,
    airport: string,
    forwarderCompanyId: string,
    forwarder?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteFhdMutationVariables = {
  input: DeleteFhdInput,
  condition?: ModelFhdConditionInput | null,
};

export type DeleteFhdMutation = {
  deleteFhd?:  {
    __typename: "Fhd",
    id: string,
    airport: string,
    forwarderCompanyId: string,
    forwarder?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateForwarderReviewMutationVariables = {
  input: CreateForwarderReviewInput,
  condition?: ModelForwarderReviewConditionInput | null,
};

export type CreateForwarderReviewMutation = {
  createForwarderReview?:  {
    __typename: "ForwarderReview",
    id: string,
    title: string,
    description: string,
    rate: number,
    company?:  {
      __typename: "ShipperCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      category: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      employees?:  {
        __typename: "ModelShipperUserConnection",
        items:  Array< {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shipperCompanyManagerId: string,
      owner?: string | null,
    } | null,
    author?:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    forwarderUserPostedReviewsId?: string | null,
    shipperCompanyReceivedReviewsId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateForwarderReviewMutationVariables = {
  input: UpdateForwarderReviewInput,
  condition?: ModelForwarderReviewConditionInput | null,
};

export type UpdateForwarderReviewMutation = {
  updateForwarderReview?:  {
    __typename: "ForwarderReview",
    id: string,
    title: string,
    description: string,
    rate: number,
    company?:  {
      __typename: "ShipperCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      category: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      employees?:  {
        __typename: "ModelShipperUserConnection",
        items:  Array< {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shipperCompanyManagerId: string,
      owner?: string | null,
    } | null,
    author?:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    forwarderUserPostedReviewsId?: string | null,
    shipperCompanyReceivedReviewsId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteForwarderReviewMutationVariables = {
  input: DeleteForwarderReviewInput,
  condition?: ModelForwarderReviewConditionInput | null,
};

export type DeleteForwarderReviewMutation = {
  deleteForwarderReview?:  {
    __typename: "ForwarderReview",
    id: string,
    title: string,
    description: string,
    rate: number,
    company?:  {
      __typename: "ShipperCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      category: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      employees?:  {
        __typename: "ModelShipperUserConnection",
        items:  Array< {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shipperCompanyManagerId: string,
      owner?: string | null,
    } | null,
    author?:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    forwarderUserPostedReviewsId?: string | null,
    shipperCompanyReceivedReviewsId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateShipperReviewMutationVariables = {
  input: CreateShipperReviewInput,
  condition?: ModelShipperReviewConditionInput | null,
};

export type CreateShipperReviewMutation = {
  createShipperReview?:  {
    __typename: "ShipperReview",
    id: string,
    title: string,
    description: string,
    rate: number,
    company?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    author?:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shipperUserPostedReviewsId?: string | null,
    forwarderCompanyReceivedReviewsId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateShipperReviewMutationVariables = {
  input: UpdateShipperReviewInput,
  condition?: ModelShipperReviewConditionInput | null,
};

export type UpdateShipperReviewMutation = {
  updateShipperReview?:  {
    __typename: "ShipperReview",
    id: string,
    title: string,
    description: string,
    rate: number,
    company?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    author?:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shipperUserPostedReviewsId?: string | null,
    forwarderCompanyReceivedReviewsId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteShipperReviewMutationVariables = {
  input: DeleteShipperReviewInput,
  condition?: ModelShipperReviewConditionInput | null,
};

export type DeleteShipperReviewMutation = {
  deleteShipperReview?:  {
    __typename: "ShipperReview",
    id: string,
    title: string,
    description: string,
    rate: number,
    company?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    author?:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shipperUserPostedReviewsId?: string | null,
    forwarderCompanyReceivedReviewsId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateInvitedUserMutationVariables = {
  input: CreateInvitedUserInput,
  condition?: ModelInvitedUserConditionInput | null,
};

export type CreateInvitedUserMutation = {
  createInvitedUser?:  {
    __typename: "InvitedUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    companyId: string,
    isForwarder: boolean,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateInvitedUserMutationVariables = {
  input: UpdateInvitedUserInput,
  condition?: ModelInvitedUserConditionInput | null,
};

export type UpdateInvitedUserMutation = {
  updateInvitedUser?:  {
    __typename: "InvitedUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    companyId: string,
    isForwarder: boolean,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteInvitedUserMutationVariables = {
  input: DeleteInvitedUserInput,
  condition?: ModelInvitedUserConditionInput | null,
};

export type DeleteInvitedUserMutation = {
  deleteInvitedUser?:  {
    __typename: "InvitedUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    companyId: string,
    isForwarder: boolean,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateShippingCustomFeeMutationVariables = {
  input: CreateShippingCustomFeeInput,
  condition?: ModelShippingCustomFeeConditionInput | null,
};

export type CreateShippingCustomFeeMutation = {
  createShippingCustomFee?:  {
    __typename: "ShippingCustomFee",
    id: string,
    title: string,
    currency:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    unitPrice: number,
    unitType: string,
    fee: number,
    taxRate?: number | null,
    maximum?: number | null,
    minimum?: number | null,
    remark: string,
    shippingFee?:  {
      __typename: "ShippingFee",
      id: string,
      price?: number | null,
      fsc?: number | null,
      minimum?: number | null,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFeeCurrencyId?: string | null,
      shippingFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    shippingFobFee?:  {
      __typename: "ShippingFobFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFobFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    shippingFhdFee?:  {
      __typename: "ShippingFhdFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFhdFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFeeCustomFeesId?: string | null,
    shippingFobFeeCustomFeesId?: string | null,
    shippingFhdFeeCustomFeesId?: string | null,
    shippingCustomFeeCurrencyId: string,
    owner?: string | null,
  } | null,
};

export type UpdateShippingCustomFeeMutationVariables = {
  input: UpdateShippingCustomFeeInput,
  condition?: ModelShippingCustomFeeConditionInput | null,
};

export type UpdateShippingCustomFeeMutation = {
  updateShippingCustomFee?:  {
    __typename: "ShippingCustomFee",
    id: string,
    title: string,
    currency:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    unitPrice: number,
    unitType: string,
    fee: number,
    taxRate?: number | null,
    maximum?: number | null,
    minimum?: number | null,
    remark: string,
    shippingFee?:  {
      __typename: "ShippingFee",
      id: string,
      price?: number | null,
      fsc?: number | null,
      minimum?: number | null,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFeeCurrencyId?: string | null,
      shippingFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    shippingFobFee?:  {
      __typename: "ShippingFobFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFobFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    shippingFhdFee?:  {
      __typename: "ShippingFhdFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFhdFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFeeCustomFeesId?: string | null,
    shippingFobFeeCustomFeesId?: string | null,
    shippingFhdFeeCustomFeesId?: string | null,
    shippingCustomFeeCurrencyId: string,
    owner?: string | null,
  } | null,
};

export type DeleteShippingCustomFeeMutationVariables = {
  input: DeleteShippingCustomFeeInput,
  condition?: ModelShippingCustomFeeConditionInput | null,
};

export type DeleteShippingCustomFeeMutation = {
  deleteShippingCustomFee?:  {
    __typename: "ShippingCustomFee",
    id: string,
    title: string,
    currency:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    unitPrice: number,
    unitType: string,
    fee: number,
    taxRate?: number | null,
    maximum?: number | null,
    minimum?: number | null,
    remark: string,
    shippingFee?:  {
      __typename: "ShippingFee",
      id: string,
      price?: number | null,
      fsc?: number | null,
      minimum?: number | null,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFeeCurrencyId?: string | null,
      shippingFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    shippingFobFee?:  {
      __typename: "ShippingFobFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFobFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    shippingFhdFee?:  {
      __typename: "ShippingFhdFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFhdFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFeeCustomFeesId?: string | null,
    shippingFobFeeCustomFeesId?: string | null,
    shippingFhdFeeCustomFeesId?: string | null,
    shippingCustomFeeCurrencyId: string,
    owner?: string | null,
  } | null,
};

export type CreateShippingFeeMutationVariables = {
  input: CreateShippingFeeInput,
  condition?: ModelShippingFeeConditionInput | null,
};

export type CreateShippingFeeMutation = {
  createShippingFee?:  {
    __typename: "ShippingFee",
    id: string,
    price?: number | null,
    fsc?: number | null,
    minimum?: number | null,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFeeCurrencyId?: string | null,
    shippingFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateShippingFeeMutationVariables = {
  input: UpdateShippingFeeInput,
  condition?: ModelShippingFeeConditionInput | null,
};

export type UpdateShippingFeeMutation = {
  updateShippingFee?:  {
    __typename: "ShippingFee",
    id: string,
    price?: number | null,
    fsc?: number | null,
    minimum?: number | null,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFeeCurrencyId?: string | null,
    shippingFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteShippingFeeMutationVariables = {
  input: DeleteShippingFeeInput,
  condition?: ModelShippingFeeConditionInput | null,
};

export type DeleteShippingFeeMutation = {
  deleteShippingFee?:  {
    __typename: "ShippingFee",
    id: string,
    price?: number | null,
    fsc?: number | null,
    minimum?: number | null,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFeeCurrencyId?: string | null,
    shippingFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateShippingFobFeeMutationVariables = {
  input: CreateShippingFobFeeInput,
  condition?: ModelShippingFobFeeConditionInput | null,
};

export type CreateShippingFobFeeMutation = {
  createShippingFobFee?:  {
    __typename: "ShippingFobFee",
    id: string,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFobFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateShippingFobFeeMutationVariables = {
  input: UpdateShippingFobFeeInput,
  condition?: ModelShippingFobFeeConditionInput | null,
};

export type UpdateShippingFobFeeMutation = {
  updateShippingFobFee?:  {
    __typename: "ShippingFobFee",
    id: string,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFobFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteShippingFobFeeMutationVariables = {
  input: DeleteShippingFobFeeInput,
  condition?: ModelShippingFobFeeConditionInput | null,
};

export type DeleteShippingFobFeeMutation = {
  deleteShippingFobFee?:  {
    __typename: "ShippingFobFee",
    id: string,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFobFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateShippingFhdFeeMutationVariables = {
  input: CreateShippingFhdFeeInput,
  condition?: ModelShippingFhdFeeConditionInput | null,
};

export type CreateShippingFhdFeeMutation = {
  createShippingFhdFee?:  {
    __typename: "ShippingFhdFee",
    id: string,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFhdFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateShippingFhdFeeMutationVariables = {
  input: UpdateShippingFhdFeeInput,
  condition?: ModelShippingFhdFeeConditionInput | null,
};

export type UpdateShippingFhdFeeMutation = {
  updateShippingFhdFee?:  {
    __typename: "ShippingFhdFee",
    id: string,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFhdFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteShippingFhdFeeMutationVariables = {
  input: DeleteShippingFhdFeeInput,
  condition?: ModelShippingFhdFeeConditionInput | null,
};

export type DeleteShippingFhdFeeMutation = {
  deleteShippingFhdFee?:  {
    __typename: "ShippingFhdFee",
    id: string,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFhdFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateShippingInstructionMutationVariables = {
  input: CreateShippingInstructionInput,
  condition?: ModelShippingInstructionConditionInput | null,
};

export type CreateShippingInstructionMutation = {
  createShippingInstruction?:  {
    __typename: "ShippingInstruction",
    id: string,
    departure: string,
    arrival: string,
    airline: string,
    flightDate: string,
    directFlight: boolean,
    leadTime: number,
    useSaf: boolean,
    yardCutDate: string,
    carrierShedCutDate: string,
    itemName: string,
    packingStyle: string,
    packingStyleDetail?: string | null,
    cargoType: CargoType,
    isSds?: boolean | null,
    regulated: string,
    regulatedDetail?: string | null,
    paymentCondition: PaymentCondition,
    paymentConditionDetail?: string | null,
    taxPaymentConditions:  {
      __typename: "TaxPaymentConditions",
      realTimeAccount?: string | null,
      comprehensiveDeferredPayment?: string | null,
      cashOnDeliveryAllowed?: boolean | null,
      beforePermissionPickup?: boolean | null,
      othersOption?: string | null,
    },
    cargoInsuranceConditions?:  {
      __typename: "CargoInsuranceConditions",
      comprehensiveInsurance?: string | null,
      individualInsurance?: boolean | null,
      uninsuredWithInsurance?: boolean | null,
      othersOption?: string | null,
    } | null,
    document?:  {
      __typename: "Document",
      invoice?: boolean | null,
      packingList?: boolean | null,
      nonApplicabilityCertificate?: boolean | null,
      msds?: boolean | null,
      other?: boolean | null,
      detail?: string | null,
    } | null,
    consigneeContact?:  {
      __typename: "Contact",
      companyName: string,
      name: string,
      email: string,
      phoneNumber: string,
    } | null,
    shipperContact?:  {
      __typename: "Contact",
      companyName: string,
      name: string,
      email: string,
      phoneNumber: string,
    } | null,
    cargoInsurance: boolean,
    cargoInsuranceDetail?: string | null,
    packed: boolean,
    packedDetail?: string | null,
    collected: boolean,
    collectedDetail?: string | null,
    delivery: boolean,
    deliveryDetail?: string | null,
    request?: string | null,
    shipperUser:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    shippingItems?:  Array< {
      __typename: "ShippingItem",
      width: number,
      length: number,
      height: number,
      weight: number,
      numberOfUnit: number,
      volume: number,
      gw: number,
      volumeWeight: number,
      cw: number,
    } | null > | null,
    transportType: TransportType,
    shippingFee:  {
      __typename: "ShippingFee",
      id: string,
      price?: number | null,
      fsc?: number | null,
      minimum?: number | null,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFeeCurrencyId?: string | null,
      shippingFeeShippingInstructionId?: string | null,
      owner?: string | null,
    },
    fob?:  {
      __typename: "ShippingFobFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFobFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    fhd?:  {
      __typename: "ShippingFhdFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFhdFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    reservation?:  {
      __typename: "Reservation",
      id: string,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      hawb?: string | null,
      status?: Status | null,
      forwarderCompanyId?: string | null,
      shipperCompanyId?: string | null,
      departure?: string | null,
      createdAt: string,
      updatedAt: string,
      reservationShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingInstructionShipperUserId: string,
    shippingInstructionShippingFeeId: string,
    shippingInstructionFobId?: string | null,
    shippingInstructionFhdId?: string | null,
    shippingInstructionReservationId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateShippingInstructionMutationVariables = {
  input: UpdateShippingInstructionInput,
  condition?: ModelShippingInstructionConditionInput | null,
};

export type UpdateShippingInstructionMutation = {
  updateShippingInstruction?:  {
    __typename: "ShippingInstruction",
    id: string,
    departure: string,
    arrival: string,
    airline: string,
    flightDate: string,
    directFlight: boolean,
    leadTime: number,
    useSaf: boolean,
    yardCutDate: string,
    carrierShedCutDate: string,
    itemName: string,
    packingStyle: string,
    packingStyleDetail?: string | null,
    cargoType: CargoType,
    isSds?: boolean | null,
    regulated: string,
    regulatedDetail?: string | null,
    paymentCondition: PaymentCondition,
    paymentConditionDetail?: string | null,
    taxPaymentConditions:  {
      __typename: "TaxPaymentConditions",
      realTimeAccount?: string | null,
      comprehensiveDeferredPayment?: string | null,
      cashOnDeliveryAllowed?: boolean | null,
      beforePermissionPickup?: boolean | null,
      othersOption?: string | null,
    },
    cargoInsuranceConditions?:  {
      __typename: "CargoInsuranceConditions",
      comprehensiveInsurance?: string | null,
      individualInsurance?: boolean | null,
      uninsuredWithInsurance?: boolean | null,
      othersOption?: string | null,
    } | null,
    document?:  {
      __typename: "Document",
      invoice?: boolean | null,
      packingList?: boolean | null,
      nonApplicabilityCertificate?: boolean | null,
      msds?: boolean | null,
      other?: boolean | null,
      detail?: string | null,
    } | null,
    consigneeContact?:  {
      __typename: "Contact",
      companyName: string,
      name: string,
      email: string,
      phoneNumber: string,
    } | null,
    shipperContact?:  {
      __typename: "Contact",
      companyName: string,
      name: string,
      email: string,
      phoneNumber: string,
    } | null,
    cargoInsurance: boolean,
    cargoInsuranceDetail?: string | null,
    packed: boolean,
    packedDetail?: string | null,
    collected: boolean,
    collectedDetail?: string | null,
    delivery: boolean,
    deliveryDetail?: string | null,
    request?: string | null,
    shipperUser:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    shippingItems?:  Array< {
      __typename: "ShippingItem",
      width: number,
      length: number,
      height: number,
      weight: number,
      numberOfUnit: number,
      volume: number,
      gw: number,
      volumeWeight: number,
      cw: number,
    } | null > | null,
    transportType: TransportType,
    shippingFee:  {
      __typename: "ShippingFee",
      id: string,
      price?: number | null,
      fsc?: number | null,
      minimum?: number | null,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFeeCurrencyId?: string | null,
      shippingFeeShippingInstructionId?: string | null,
      owner?: string | null,
    },
    fob?:  {
      __typename: "ShippingFobFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFobFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    fhd?:  {
      __typename: "ShippingFhdFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFhdFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    reservation?:  {
      __typename: "Reservation",
      id: string,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      hawb?: string | null,
      status?: Status | null,
      forwarderCompanyId?: string | null,
      shipperCompanyId?: string | null,
      departure?: string | null,
      createdAt: string,
      updatedAt: string,
      reservationShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingInstructionShipperUserId: string,
    shippingInstructionShippingFeeId: string,
    shippingInstructionFobId?: string | null,
    shippingInstructionFhdId?: string | null,
    shippingInstructionReservationId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteShippingInstructionMutationVariables = {
  input: DeleteShippingInstructionInput,
  condition?: ModelShippingInstructionConditionInput | null,
};

export type DeleteShippingInstructionMutation = {
  deleteShippingInstruction?:  {
    __typename: "ShippingInstruction",
    id: string,
    departure: string,
    arrival: string,
    airline: string,
    flightDate: string,
    directFlight: boolean,
    leadTime: number,
    useSaf: boolean,
    yardCutDate: string,
    carrierShedCutDate: string,
    itemName: string,
    packingStyle: string,
    packingStyleDetail?: string | null,
    cargoType: CargoType,
    isSds?: boolean | null,
    regulated: string,
    regulatedDetail?: string | null,
    paymentCondition: PaymentCondition,
    paymentConditionDetail?: string | null,
    taxPaymentConditions:  {
      __typename: "TaxPaymentConditions",
      realTimeAccount?: string | null,
      comprehensiveDeferredPayment?: string | null,
      cashOnDeliveryAllowed?: boolean | null,
      beforePermissionPickup?: boolean | null,
      othersOption?: string | null,
    },
    cargoInsuranceConditions?:  {
      __typename: "CargoInsuranceConditions",
      comprehensiveInsurance?: string | null,
      individualInsurance?: boolean | null,
      uninsuredWithInsurance?: boolean | null,
      othersOption?: string | null,
    } | null,
    document?:  {
      __typename: "Document",
      invoice?: boolean | null,
      packingList?: boolean | null,
      nonApplicabilityCertificate?: boolean | null,
      msds?: boolean | null,
      other?: boolean | null,
      detail?: string | null,
    } | null,
    consigneeContact?:  {
      __typename: "Contact",
      companyName: string,
      name: string,
      email: string,
      phoneNumber: string,
    } | null,
    shipperContact?:  {
      __typename: "Contact",
      companyName: string,
      name: string,
      email: string,
      phoneNumber: string,
    } | null,
    cargoInsurance: boolean,
    cargoInsuranceDetail?: string | null,
    packed: boolean,
    packedDetail?: string | null,
    collected: boolean,
    collectedDetail?: string | null,
    delivery: boolean,
    deliveryDetail?: string | null,
    request?: string | null,
    shipperUser:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    shippingItems?:  Array< {
      __typename: "ShippingItem",
      width: number,
      length: number,
      height: number,
      weight: number,
      numberOfUnit: number,
      volume: number,
      gw: number,
      volumeWeight: number,
      cw: number,
    } | null > | null,
    transportType: TransportType,
    shippingFee:  {
      __typename: "ShippingFee",
      id: string,
      price?: number | null,
      fsc?: number | null,
      minimum?: number | null,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFeeCurrencyId?: string | null,
      shippingFeeShippingInstructionId?: string | null,
      owner?: string | null,
    },
    fob?:  {
      __typename: "ShippingFobFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFobFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    fhd?:  {
      __typename: "ShippingFhdFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFhdFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    reservation?:  {
      __typename: "Reservation",
      id: string,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      hawb?: string | null,
      status?: Status | null,
      forwarderCompanyId?: string | null,
      shipperCompanyId?: string | null,
      departure?: string | null,
      createdAt: string,
      updatedAt: string,
      reservationShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingInstructionShipperUserId: string,
    shippingInstructionShippingFeeId: string,
    shippingInstructionFobId?: string | null,
    shippingInstructionFhdId?: string | null,
    shippingInstructionReservationId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateReservationMutationVariables = {
  input: CreateReservationInput,
  condition?: ModelReservationConditionInput | null,
};

export type CreateReservationMutation = {
  createReservation?:  {
    __typename: "Reservation",
    id: string,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    hawb?: string | null,
    status?: Status | null,
    forwarderCompanyId?: string | null,
    shipperCompanyId?: string | null,
    departure?: string | null,
    createdAt: string,
    updatedAt: string,
    reservationShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateReservationMutationVariables = {
  input: UpdateReservationInput,
  condition?: ModelReservationConditionInput | null,
};

export type UpdateReservationMutation = {
  updateReservation?:  {
    __typename: "Reservation",
    id: string,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    hawb?: string | null,
    status?: Status | null,
    forwarderCompanyId?: string | null,
    shipperCompanyId?: string | null,
    departure?: string | null,
    createdAt: string,
    updatedAt: string,
    reservationShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteReservationMutationVariables = {
  input: DeleteReservationInput,
  condition?: ModelReservationConditionInput | null,
};

export type DeleteReservationMutation = {
  deleteReservation?:  {
    __typename: "Reservation",
    id: string,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    hawb?: string | null,
    status?: Status | null,
    forwarderCompanyId?: string | null,
    shipperCompanyId?: string | null,
    departure?: string | null,
    createdAt: string,
    updatedAt: string,
    reservationShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateCurrencyMutationVariables = {
  input: CreateCurrencyInput,
  condition?: ModelCurrencyConditionInput | null,
};

export type CreateCurrencyMutation = {
  createCurrency?:  {
    __typename: "Currency",
    id: string,
    code: string,
    exchangeRateJPY: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCurrencyMutationVariables = {
  input: UpdateCurrencyInput,
  condition?: ModelCurrencyConditionInput | null,
};

export type UpdateCurrencyMutation = {
  updateCurrency?:  {
    __typename: "Currency",
    id: string,
    code: string,
    exchangeRateJPY: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCurrencyMutationVariables = {
  input: DeleteCurrencyInput,
  condition?: ModelCurrencyConditionInput | null,
};

export type DeleteCurrencyMutation = {
  deleteCurrency?:  {
    __typename: "Currency",
    id: string,
    code: string,
    exchangeRateJPY: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateForwarderUserMutationVariables = {
  input: CreateForwarderUserInput,
  condition?: ModelForwarderUserConditionInput | null,
};

export type CreateForwarderUserMutation = {
  createForwarderUser?:  {
    __typename: "ForwarderUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    workLocation: string,
    division: string,
    position?: string | null,
    companyId?: string | null,
    company?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    postedReviews?:  {
      __typename: "ModelForwarderReviewConnection",
      items:  Array< {
        __typename: "ForwarderReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderUserPostedReviewsId?: string | null,
        shipperCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    estimates?:  {
      __typename: "ModelEstimateConnection",
      items:  Array< {
        __typename: "Estimate",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        cargoType: CargoType,
        yardCutDate: string,
        carrierShedCutDate: string,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum: number,
        under45: number,
        over45: number,
        over100: number,
        over300: number,
        over500: number,
        over1000: number,
        fsc: number,
        remark?: string | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        forwarderUser:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        status: EstimateStatus,
        createdAt: string,
        updatedAt: string,
        forwarderUserEstimatesId?: string | null,
        estimateCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateForwarderUserMutationVariables = {
  input: UpdateForwarderUserInput,
  condition?: ModelForwarderUserConditionInput | null,
};

export type UpdateForwarderUserMutation = {
  updateForwarderUser?:  {
    __typename: "ForwarderUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    workLocation: string,
    division: string,
    position?: string | null,
    companyId?: string | null,
    company?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    postedReviews?:  {
      __typename: "ModelForwarderReviewConnection",
      items:  Array< {
        __typename: "ForwarderReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderUserPostedReviewsId?: string | null,
        shipperCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    estimates?:  {
      __typename: "ModelEstimateConnection",
      items:  Array< {
        __typename: "Estimate",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        cargoType: CargoType,
        yardCutDate: string,
        carrierShedCutDate: string,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum: number,
        under45: number,
        over45: number,
        over100: number,
        over300: number,
        over500: number,
        over1000: number,
        fsc: number,
        remark?: string | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        forwarderUser:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        status: EstimateStatus,
        createdAt: string,
        updatedAt: string,
        forwarderUserEstimatesId?: string | null,
        estimateCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteForwarderUserMutationVariables = {
  input: DeleteForwarderUserInput,
  condition?: ModelForwarderUserConditionInput | null,
};

export type DeleteForwarderUserMutation = {
  deleteForwarderUser?:  {
    __typename: "ForwarderUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    workLocation: string,
    division: string,
    position?: string | null,
    companyId?: string | null,
    company?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    postedReviews?:  {
      __typename: "ModelForwarderReviewConnection",
      items:  Array< {
        __typename: "ForwarderReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderUserPostedReviewsId?: string | null,
        shipperCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    estimates?:  {
      __typename: "ModelEstimateConnection",
      items:  Array< {
        __typename: "Estimate",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        cargoType: CargoType,
        yardCutDate: string,
        carrierShedCutDate: string,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum: number,
        under45: number,
        over45: number,
        over100: number,
        over300: number,
        over500: number,
        over1000: number,
        fsc: number,
        remark?: string | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        forwarderUser:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        status: EstimateStatus,
        createdAt: string,
        updatedAt: string,
        forwarderUserEstimatesId?: string | null,
        estimateCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCustomFeeMutationVariables = {
  input: CreateCustomFeeInput,
  condition?: ModelCustomFeeConditionInput | null,
};

export type CreateCustomFeeMutation = {
  createCustomFee?:  {
    __typename: "CustomFee",
    id: string,
    title: string,
    unitPrice: number,
    unitType: string,
    calculationMethod: CalculationMethod,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    minimum?: number | null,
    maximum?: number | null,
    includeTax?: boolean | null,
    taxRate?: number | null,
    remark?: string | null,
    fobId?: string | null,
    fhdId?: string | null,
    shippingFeeId?: string | null,
    estimateId?: string | null,
    createdAt: string,
    updatedAt: string,
    customFeeCurrencyId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateCustomFeeMutationVariables = {
  input: UpdateCustomFeeInput,
  condition?: ModelCustomFeeConditionInput | null,
};

export type UpdateCustomFeeMutation = {
  updateCustomFee?:  {
    __typename: "CustomFee",
    id: string,
    title: string,
    unitPrice: number,
    unitType: string,
    calculationMethod: CalculationMethod,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    minimum?: number | null,
    maximum?: number | null,
    includeTax?: boolean | null,
    taxRate?: number | null,
    remark?: string | null,
    fobId?: string | null,
    fhdId?: string | null,
    shippingFeeId?: string | null,
    estimateId?: string | null,
    createdAt: string,
    updatedAt: string,
    customFeeCurrencyId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteCustomFeeMutationVariables = {
  input: DeleteCustomFeeInput,
  condition?: ModelCustomFeeConditionInput | null,
};

export type DeleteCustomFeeMutation = {
  deleteCustomFee?:  {
    __typename: "CustomFee",
    id: string,
    title: string,
    unitPrice: number,
    unitType: string,
    calculationMethod: CalculationMethod,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    minimum?: number | null,
    maximum?: number | null,
    includeTax?: boolean | null,
    taxRate?: number | null,
    remark?: string | null,
    fobId?: string | null,
    fhdId?: string | null,
    shippingFeeId?: string | null,
    estimateId?: string | null,
    createdAt: string,
    updatedAt: string,
    customFeeCurrencyId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateForwarderCompanyMutationVariables = {
  input: CreateForwarderCompanyInput,
  condition?: ModelForwarderCompanyConditionInput | null,
};

export type CreateForwarderCompanyMutation = {
  createForwarderCompany?:  {
    __typename: "ForwarderCompany",
    id: string,
    name: string,
    firstName: string,
    lastName: string,
    location: string,
    establishment: string,
    paidInCaptial: number,
    corporateNumber: string,
    iataAgency: string,
    listed: boolean,
    numberOfEmployees: NumberOfEmployees,
    companyURL: string,
    manager:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    fobs?:  {
      __typename: "ModelFobConnection",
      items:  Array< {
        __typename: "Fob",
        id: string,
        airport: string,
        forwarderCompanyId: string,
        forwarder?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fhds?:  {
      __typename: "ModelFhdConnection",
      items:  Array< {
        __typename: "Fhd",
        id: string,
        airport: string,
        forwarderCompanyId: string,
        forwarder?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    employees?:  {
      __typename: "ModelForwarderUserConnection",
      items:  Array< {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    receivedReviews?:  {
      __typename: "ModelShipperReviewConnection",
      items:  Array< {
        __typename: "ShipperReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperUserPostedReviewsId?: string | null,
        forwarderCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    forwarderCompanyManagerId: string,
    owner?: string | null,
  } | null,
};

export type UpdateForwarderCompanyMutationVariables = {
  input: UpdateForwarderCompanyInput,
  condition?: ModelForwarderCompanyConditionInput | null,
};

export type UpdateForwarderCompanyMutation = {
  updateForwarderCompany?:  {
    __typename: "ForwarderCompany",
    id: string,
    name: string,
    firstName: string,
    lastName: string,
    location: string,
    establishment: string,
    paidInCaptial: number,
    corporateNumber: string,
    iataAgency: string,
    listed: boolean,
    numberOfEmployees: NumberOfEmployees,
    companyURL: string,
    manager:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    fobs?:  {
      __typename: "ModelFobConnection",
      items:  Array< {
        __typename: "Fob",
        id: string,
        airport: string,
        forwarderCompanyId: string,
        forwarder?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fhds?:  {
      __typename: "ModelFhdConnection",
      items:  Array< {
        __typename: "Fhd",
        id: string,
        airport: string,
        forwarderCompanyId: string,
        forwarder?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    employees?:  {
      __typename: "ModelForwarderUserConnection",
      items:  Array< {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    receivedReviews?:  {
      __typename: "ModelShipperReviewConnection",
      items:  Array< {
        __typename: "ShipperReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperUserPostedReviewsId?: string | null,
        forwarderCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    forwarderCompanyManagerId: string,
    owner?: string | null,
  } | null,
};

export type DeleteForwarderCompanyMutationVariables = {
  input: DeleteForwarderCompanyInput,
  condition?: ModelForwarderCompanyConditionInput | null,
};

export type DeleteForwarderCompanyMutation = {
  deleteForwarderCompany?:  {
    __typename: "ForwarderCompany",
    id: string,
    name: string,
    firstName: string,
    lastName: string,
    location: string,
    establishment: string,
    paidInCaptial: number,
    corporateNumber: string,
    iataAgency: string,
    listed: boolean,
    numberOfEmployees: NumberOfEmployees,
    companyURL: string,
    manager:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    fobs?:  {
      __typename: "ModelFobConnection",
      items:  Array< {
        __typename: "Fob",
        id: string,
        airport: string,
        forwarderCompanyId: string,
        forwarder?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fhds?:  {
      __typename: "ModelFhdConnection",
      items:  Array< {
        __typename: "Fhd",
        id: string,
        airport: string,
        forwarderCompanyId: string,
        forwarder?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    employees?:  {
      __typename: "ModelForwarderUserConnection",
      items:  Array< {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    receivedReviews?:  {
      __typename: "ModelShipperReviewConnection",
      items:  Array< {
        __typename: "ShipperReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperUserPostedReviewsId?: string | null,
        forwarderCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    forwarderCompanyManagerId: string,
    owner?: string | null,
  } | null,
};

export type CreateShipperCompanyMutationVariables = {
  input: CreateShipperCompanyInput,
  condition?: ModelShipperCompanyConditionInput | null,
};

export type CreateShipperCompanyMutation = {
  createShipperCompany?:  {
    __typename: "ShipperCompany",
    id: string,
    name: string,
    firstName: string,
    lastName: string,
    location: string,
    establishment: string,
    paidInCaptial: number,
    corporateNumber: string,
    category: string,
    listed: boolean,
    numberOfEmployees: NumberOfEmployees,
    companyURL: string,
    manager:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    employees?:  {
      __typename: "ModelShipperUserConnection",
      items:  Array< {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    receivedReviews?:  {
      __typename: "ModelForwarderReviewConnection",
      items:  Array< {
        __typename: "ForwarderReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderUserPostedReviewsId?: string | null,
        shipperCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shipperCompanyManagerId: string,
    owner?: string | null,
  } | null,
};

export type UpdateShipperCompanyMutationVariables = {
  input: UpdateShipperCompanyInput,
  condition?: ModelShipperCompanyConditionInput | null,
};

export type UpdateShipperCompanyMutation = {
  updateShipperCompany?:  {
    __typename: "ShipperCompany",
    id: string,
    name: string,
    firstName: string,
    lastName: string,
    location: string,
    establishment: string,
    paidInCaptial: number,
    corporateNumber: string,
    category: string,
    listed: boolean,
    numberOfEmployees: NumberOfEmployees,
    companyURL: string,
    manager:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    employees?:  {
      __typename: "ModelShipperUserConnection",
      items:  Array< {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    receivedReviews?:  {
      __typename: "ModelForwarderReviewConnection",
      items:  Array< {
        __typename: "ForwarderReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderUserPostedReviewsId?: string | null,
        shipperCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shipperCompanyManagerId: string,
    owner?: string | null,
  } | null,
};

export type DeleteShipperCompanyMutationVariables = {
  input: DeleteShipperCompanyInput,
  condition?: ModelShipperCompanyConditionInput | null,
};

export type DeleteShipperCompanyMutation = {
  deleteShipperCompany?:  {
    __typename: "ShipperCompany",
    id: string,
    name: string,
    firstName: string,
    lastName: string,
    location: string,
    establishment: string,
    paidInCaptial: number,
    corporateNumber: string,
    category: string,
    listed: boolean,
    numberOfEmployees: NumberOfEmployees,
    companyURL: string,
    manager:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    employees?:  {
      __typename: "ModelShipperUserConnection",
      items:  Array< {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    receivedReviews?:  {
      __typename: "ModelForwarderReviewConnection",
      items:  Array< {
        __typename: "ForwarderReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderUserPostedReviewsId?: string | null,
        shipperCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shipperCompanyManagerId: string,
    owner?: string | null,
  } | null,
};

export type CreateEstimateMutationVariables = {
  input: CreateEstimateInput,
  condition?: ModelEstimateConditionInput | null,
};

export type CreateEstimateMutation = {
  createEstimate?:  {
    __typename: "Estimate",
    id: string,
    departure: string,
    arrival: string,
    airline: string,
    flightDate: string,
    directFlight: boolean,
    leadTime: number,
    useSaf: boolean,
    cargoType: CargoType,
    yardCutDate: string,
    carrierShedCutDate: string,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    minimum: number,
    under45: number,
    over45: number,
    over100: number,
    over300: number,
    over500: number,
    over1000: number,
    fsc: number,
    remark?: string | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    forwarderUser:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    status: EstimateStatus,
    createdAt: string,
    updatedAt: string,
    forwarderUserEstimatesId?: string | null,
    estimateCurrencyId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateEstimateMutationVariables = {
  input: UpdateEstimateInput,
  condition?: ModelEstimateConditionInput | null,
};

export type UpdateEstimateMutation = {
  updateEstimate?:  {
    __typename: "Estimate",
    id: string,
    departure: string,
    arrival: string,
    airline: string,
    flightDate: string,
    directFlight: boolean,
    leadTime: number,
    useSaf: boolean,
    cargoType: CargoType,
    yardCutDate: string,
    carrierShedCutDate: string,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    minimum: number,
    under45: number,
    over45: number,
    over100: number,
    over300: number,
    over500: number,
    over1000: number,
    fsc: number,
    remark?: string | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    forwarderUser:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    status: EstimateStatus,
    createdAt: string,
    updatedAt: string,
    forwarderUserEstimatesId?: string | null,
    estimateCurrencyId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteEstimateMutationVariables = {
  input: DeleteEstimateInput,
  condition?: ModelEstimateConditionInput | null,
};

export type DeleteEstimateMutation = {
  deleteEstimate?:  {
    __typename: "Estimate",
    id: string,
    departure: string,
    arrival: string,
    airline: string,
    flightDate: string,
    directFlight: boolean,
    leadTime: number,
    useSaf: boolean,
    cargoType: CargoType,
    yardCutDate: string,
    carrierShedCutDate: string,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    minimum: number,
    under45: number,
    over45: number,
    over100: number,
    over300: number,
    over500: number,
    over1000: number,
    fsc: number,
    remark?: string | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    forwarderUser:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    status: EstimateStatus,
    createdAt: string,
    updatedAt: string,
    forwarderUserEstimatesId?: string | null,
    estimateCurrencyId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateNewsMutationVariables = {
  input: CreateNewsInput,
  condition?: ModelNewsConditionInput | null,
};

export type CreateNewsMutation = {
  createNews?:  {
    __typename: "News",
    id: string,
    title: string,
    newsDate: string,
    newsContent?: string | null,
    mediaUrl?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateNewsMutationVariables = {
  input: UpdateNewsInput,
  condition?: ModelNewsConditionInput | null,
};

export type UpdateNewsMutation = {
  updateNews?:  {
    __typename: "News",
    id: string,
    title: string,
    newsDate: string,
    newsContent?: string | null,
    mediaUrl?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteNewsMutationVariables = {
  input: DeleteNewsInput,
  condition?: ModelNewsConditionInput | null,
};

export type DeleteNewsMutation = {
  deleteNews?:  {
    __typename: "News",
    id: string,
    title: string,
    newsDate: string,
    newsContent?: string | null,
    mediaUrl?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateMarketConditionsWithSafMutationVariables = {
  input: CreateMarketConditionsWithSafInput,
  condition?: ModelMarketConditionsWithSafConditionInput | null,
};

export type CreateMarketConditionsWithSafMutation = {
  createMarketConditionsWithSaf?:  {
    __typename: "MarketConditionsWithSaf",
    id: string,
    departure: string,
    arrival: string,
    flightDate: string,
    marketPrice: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateMarketConditionsWithSafMutationVariables = {
  input: UpdateMarketConditionsWithSafInput,
  condition?: ModelMarketConditionsWithSafConditionInput | null,
};

export type UpdateMarketConditionsWithSafMutation = {
  updateMarketConditionsWithSaf?:  {
    __typename: "MarketConditionsWithSaf",
    id: string,
    departure: string,
    arrival: string,
    flightDate: string,
    marketPrice: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteMarketConditionsWithSafMutationVariables = {
  input: DeleteMarketConditionsWithSafInput,
  condition?: ModelMarketConditionsWithSafConditionInput | null,
};

export type DeleteMarketConditionsWithSafMutation = {
  deleteMarketConditionsWithSaf?:  {
    __typename: "MarketConditionsWithSaf",
    id: string,
    departure: string,
    arrival: string,
    flightDate: string,
    marketPrice: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateMarketConditionsWithoutSafMutationVariables = {
  input: CreateMarketConditionsWithoutSafInput,
  condition?: ModelMarketConditionsWithoutSafConditionInput | null,
};

export type CreateMarketConditionsWithoutSafMutation = {
  createMarketConditionsWithoutSaf?:  {
    __typename: "MarketConditionsWithoutSaf",
    id: string,
    departure: string,
    arrival: string,
    flightDate: string,
    marketPrice: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateMarketConditionsWithoutSafMutationVariables = {
  input: UpdateMarketConditionsWithoutSafInput,
  condition?: ModelMarketConditionsWithoutSafConditionInput | null,
};

export type UpdateMarketConditionsWithoutSafMutation = {
  updateMarketConditionsWithoutSaf?:  {
    __typename: "MarketConditionsWithoutSaf",
    id: string,
    departure: string,
    arrival: string,
    flightDate: string,
    marketPrice: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteMarketConditionsWithoutSafMutationVariables = {
  input: DeleteMarketConditionsWithoutSafInput,
  condition?: ModelMarketConditionsWithoutSafConditionInput | null,
};

export type DeleteMarketConditionsWithoutSafMutation = {
  deleteMarketConditionsWithoutSaf?:  {
    __typename: "MarketConditionsWithoutSaf",
    id: string,
    departure: string,
    arrival: string,
    flightDate: string,
    marketPrice: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type SendInvitationQueryVariables = {
  event: SendInvitationEventInput,
};

export type SendInvitationQuery = {
  sendInvitation?:  {
    __typename: "StatusResult",
    statusCode?: number | null,
    body?: string | null,
  } | null,
};

export type GetShipperUserQueryVariables = {
  id: string,
};

export type GetShipperUserQuery = {
  getShipperUser?:  {
    __typename: "ShipperUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    workLocation: string,
    division: string,
    position?: string | null,
    area: string,
    role: ShipperRole,
    companyId?: string | null,
    company?:  {
      __typename: "ShipperCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      category: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      employees?:  {
        __typename: "ModelShipperUserConnection",
        items:  Array< {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shipperCompanyManagerId: string,
      owner?: string | null,
    } | null,
    postedReviews?:  {
      __typename: "ModelShipperReviewConnection",
      items:  Array< {
        __typename: "ShipperReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperUserPostedReviewsId?: string | null,
        forwarderCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListShipperUsersQueryVariables = {
  filter?: ModelShipperUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShipperUsersQuery = {
  listShipperUsers?:  {
    __typename: "ModelShipperUserConnection",
    items:  Array< {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFobQueryVariables = {
  id: string,
};

export type GetFobQuery = {
  getFob?:  {
    __typename: "Fob",
    id: string,
    airport: string,
    forwarderCompanyId: string,
    forwarder?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListFobsQueryVariables = {
  filter?: ModelFobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFobsQuery = {
  listFobs?:  {
    __typename: "ModelFobConnection",
    items:  Array< {
      __typename: "Fob",
      id: string,
      airport: string,
      forwarderCompanyId: string,
      forwarder?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      customItems?:  {
        __typename: "ModelCustomFeeConnection",
        items:  Array< {
          __typename: "CustomFee",
          id: string,
          title: string,
          unitPrice: number,
          unitType: string,
          calculationMethod: CalculationMethod,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum?: number | null,
          maximum?: number | null,
          includeTax?: boolean | null,
          taxRate?: number | null,
          remark?: string | null,
          fobId?: string | null,
          fhdId?: string | null,
          shippingFeeId?: string | null,
          estimateId?: string | null,
          createdAt: string,
          updatedAt: string,
          customFeeCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFhdQueryVariables = {
  id: string,
};

export type GetFhdQuery = {
  getFhd?:  {
    __typename: "Fhd",
    id: string,
    airport: string,
    forwarderCompanyId: string,
    forwarder?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListFhdsQueryVariables = {
  filter?: ModelFhdFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFhdsQuery = {
  listFhds?:  {
    __typename: "ModelFhdConnection",
    items:  Array< {
      __typename: "Fhd",
      id: string,
      airport: string,
      forwarderCompanyId: string,
      forwarder?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      customItems?:  {
        __typename: "ModelCustomFeeConnection",
        items:  Array< {
          __typename: "CustomFee",
          id: string,
          title: string,
          unitPrice: number,
          unitType: string,
          calculationMethod: CalculationMethod,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum?: number | null,
          maximum?: number | null,
          includeTax?: boolean | null,
          taxRate?: number | null,
          remark?: string | null,
          fobId?: string | null,
          fhdId?: string | null,
          shippingFeeId?: string | null,
          estimateId?: string | null,
          createdAt: string,
          updatedAt: string,
          customFeeCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetForwarderReviewQueryVariables = {
  id: string,
};

export type GetForwarderReviewQuery = {
  getForwarderReview?:  {
    __typename: "ForwarderReview",
    id: string,
    title: string,
    description: string,
    rate: number,
    company?:  {
      __typename: "ShipperCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      category: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      employees?:  {
        __typename: "ModelShipperUserConnection",
        items:  Array< {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shipperCompanyManagerId: string,
      owner?: string | null,
    } | null,
    author?:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    forwarderUserPostedReviewsId?: string | null,
    shipperCompanyReceivedReviewsId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListForwarderReviewsQueryVariables = {
  filter?: ModelForwarderReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListForwarderReviewsQuery = {
  listForwarderReviews?:  {
    __typename: "ModelForwarderReviewConnection",
    items:  Array< {
      __typename: "ForwarderReview",
      id: string,
      title: string,
      description: string,
      rate: number,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      author?:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderUserPostedReviewsId?: string | null,
      shipperCompanyReceivedReviewsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetShipperReviewQueryVariables = {
  id: string,
};

export type GetShipperReviewQuery = {
  getShipperReview?:  {
    __typename: "ShipperReview",
    id: string,
    title: string,
    description: string,
    rate: number,
    company?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    author?:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shipperUserPostedReviewsId?: string | null,
    forwarderCompanyReceivedReviewsId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListShipperReviewsQueryVariables = {
  filter?: ModelShipperReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShipperReviewsQuery = {
  listShipperReviews?:  {
    __typename: "ModelShipperReviewConnection",
    items:  Array< {
      __typename: "ShipperReview",
      id: string,
      title: string,
      description: string,
      rate: number,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      author?:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shipperUserPostedReviewsId?: string | null,
      forwarderCompanyReceivedReviewsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInvitedUserQueryVariables = {
  email: string,
};

export type GetInvitedUserQuery = {
  getInvitedUser?:  {
    __typename: "InvitedUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    companyId: string,
    isForwarder: boolean,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListInvitedUsersQueryVariables = {
  email?: string | null,
  filter?: ModelInvitedUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListInvitedUsersQuery = {
  listInvitedUsers?:  {
    __typename: "ModelInvitedUserConnection",
    items:  Array< {
      __typename: "InvitedUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      companyId: string,
      isForwarder: boolean,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetShippingCustomFeeQueryVariables = {
  id: string,
};

export type GetShippingCustomFeeQuery = {
  getShippingCustomFee?:  {
    __typename: "ShippingCustomFee",
    id: string,
    title: string,
    currency:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    unitPrice: number,
    unitType: string,
    fee: number,
    taxRate?: number | null,
    maximum?: number | null,
    minimum?: number | null,
    remark: string,
    shippingFee?:  {
      __typename: "ShippingFee",
      id: string,
      price?: number | null,
      fsc?: number | null,
      minimum?: number | null,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFeeCurrencyId?: string | null,
      shippingFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    shippingFobFee?:  {
      __typename: "ShippingFobFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFobFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    shippingFhdFee?:  {
      __typename: "ShippingFhdFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFhdFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFeeCustomFeesId?: string | null,
    shippingFobFeeCustomFeesId?: string | null,
    shippingFhdFeeCustomFeesId?: string | null,
    shippingCustomFeeCurrencyId: string,
    owner?: string | null,
  } | null,
};

export type ListShippingCustomFeesQueryVariables = {
  filter?: ModelShippingCustomFeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShippingCustomFeesQuery = {
  listShippingCustomFees?:  {
    __typename: "ModelShippingCustomFeeConnection",
    items:  Array< {
      __typename: "ShippingCustomFee",
      id: string,
      title: string,
      currency:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      unitPrice: number,
      unitType: string,
      fee: number,
      taxRate?: number | null,
      maximum?: number | null,
      minimum?: number | null,
      remark: string,
      shippingFee?:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      shippingFobFee?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      shippingFhdFee?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFeeCustomFeesId?: string | null,
      shippingFobFeeCustomFeesId?: string | null,
      shippingFhdFeeCustomFeesId?: string | null,
      shippingCustomFeeCurrencyId: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetShippingFeeQueryVariables = {
  id: string,
};

export type GetShippingFeeQuery = {
  getShippingFee?:  {
    __typename: "ShippingFee",
    id: string,
    price?: number | null,
    fsc?: number | null,
    minimum?: number | null,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFeeCurrencyId?: string | null,
    shippingFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListShippingFeesQueryVariables = {
  filter?: ModelShippingFeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShippingFeesQuery = {
  listShippingFees?:  {
    __typename: "ModelShippingFeeConnection",
    items:  Array< {
      __typename: "ShippingFee",
      id: string,
      price?: number | null,
      fsc?: number | null,
      minimum?: number | null,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFeeCurrencyId?: string | null,
      shippingFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetShippingFobFeeQueryVariables = {
  id: string,
};

export type GetShippingFobFeeQuery = {
  getShippingFobFee?:  {
    __typename: "ShippingFobFee",
    id: string,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFobFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListShippingFobFeesQueryVariables = {
  filter?: ModelShippingFobFeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShippingFobFeesQuery = {
  listShippingFobFees?:  {
    __typename: "ModelShippingFobFeeConnection",
    items:  Array< {
      __typename: "ShippingFobFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFobFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetShippingFhdFeeQueryVariables = {
  id: string,
};

export type GetShippingFhdFeeQuery = {
  getShippingFhdFee?:  {
    __typename: "ShippingFhdFee",
    id: string,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFhdFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListShippingFhdFeesQueryVariables = {
  filter?: ModelShippingFhdFeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShippingFhdFeesQuery = {
  listShippingFhdFees?:  {
    __typename: "ModelShippingFhdFeeConnection",
    items:  Array< {
      __typename: "ShippingFhdFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFhdFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetShippingInstructionQueryVariables = {
  id: string,
};

export type GetShippingInstructionQuery = {
  getShippingInstruction?:  {
    __typename: "ShippingInstruction",
    id: string,
    departure: string,
    arrival: string,
    airline: string,
    flightDate: string,
    directFlight: boolean,
    leadTime: number,
    useSaf: boolean,
    yardCutDate: string,
    carrierShedCutDate: string,
    itemName: string,
    packingStyle: string,
    packingStyleDetail?: string | null,
    cargoType: CargoType,
    isSds?: boolean | null,
    regulated: string,
    regulatedDetail?: string | null,
    paymentCondition: PaymentCondition,
    paymentConditionDetail?: string | null,
    taxPaymentConditions:  {
      __typename: "TaxPaymentConditions",
      realTimeAccount?: string | null,
      comprehensiveDeferredPayment?: string | null,
      cashOnDeliveryAllowed?: boolean | null,
      beforePermissionPickup?: boolean | null,
      othersOption?: string | null,
    },
    cargoInsuranceConditions?:  {
      __typename: "CargoInsuranceConditions",
      comprehensiveInsurance?: string | null,
      individualInsurance?: boolean | null,
      uninsuredWithInsurance?: boolean | null,
      othersOption?: string | null,
    } | null,
    document?:  {
      __typename: "Document",
      invoice?: boolean | null,
      packingList?: boolean | null,
      nonApplicabilityCertificate?: boolean | null,
      msds?: boolean | null,
      other?: boolean | null,
      detail?: string | null,
    } | null,
    consigneeContact?:  {
      __typename: "Contact",
      companyName: string,
      name: string,
      email: string,
      phoneNumber: string,
    } | null,
    shipperContact?:  {
      __typename: "Contact",
      companyName: string,
      name: string,
      email: string,
      phoneNumber: string,
    } | null,
    cargoInsurance: boolean,
    cargoInsuranceDetail?: string | null,
    packed: boolean,
    packedDetail?: string | null,
    collected: boolean,
    collectedDetail?: string | null,
    delivery: boolean,
    deliveryDetail?: string | null,
    request?: string | null,
    shipperUser:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    shippingItems?:  Array< {
      __typename: "ShippingItem",
      width: number,
      length: number,
      height: number,
      weight: number,
      numberOfUnit: number,
      volume: number,
      gw: number,
      volumeWeight: number,
      cw: number,
    } | null > | null,
    transportType: TransportType,
    shippingFee:  {
      __typename: "ShippingFee",
      id: string,
      price?: number | null,
      fsc?: number | null,
      minimum?: number | null,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFeeCurrencyId?: string | null,
      shippingFeeShippingInstructionId?: string | null,
      owner?: string | null,
    },
    fob?:  {
      __typename: "ShippingFobFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFobFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    fhd?:  {
      __typename: "ShippingFhdFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFhdFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    reservation?:  {
      __typename: "Reservation",
      id: string,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      hawb?: string | null,
      status?: Status | null,
      forwarderCompanyId?: string | null,
      shipperCompanyId?: string | null,
      departure?: string | null,
      createdAt: string,
      updatedAt: string,
      reservationShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingInstructionShipperUserId: string,
    shippingInstructionShippingFeeId: string,
    shippingInstructionFobId?: string | null,
    shippingInstructionFhdId?: string | null,
    shippingInstructionReservationId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListShippingInstructionsQueryVariables = {
  filter?: ModelShippingInstructionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShippingInstructionsQuery = {
  listShippingInstructions?:  {
    __typename: "ModelShippingInstructionConnection",
    items:  Array< {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReservationQueryVariables = {
  id: string,
};

export type GetReservationQuery = {
  getReservation?:  {
    __typename: "Reservation",
    id: string,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    hawb?: string | null,
    status?: Status | null,
    forwarderCompanyId?: string | null,
    shipperCompanyId?: string | null,
    departure?: string | null,
    createdAt: string,
    updatedAt: string,
    reservationShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListReservationsQueryVariables = {
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReservationsQuery = {
  listReservations?:  {
    __typename: "ModelReservationConnection",
    items:  Array< {
      __typename: "Reservation",
      id: string,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      hawb?: string | null,
      status?: Status | null,
      forwarderCompanyId?: string | null,
      shipperCompanyId?: string | null,
      departure?: string | null,
      createdAt: string,
      updatedAt: string,
      reservationShippingInstructionId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ShipperUsersByCompanyIdQueryVariables = {
  companyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShipperUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShipperUsersByCompanyIdQuery = {
  shipperUsersByCompanyId?:  {
    __typename: "ModelShipperUserConnection",
    items:  Array< {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FobsByForwarderCompanyIdQueryVariables = {
  forwarderCompanyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FobsByForwarderCompanyIdQuery = {
  fobsByForwarderCompanyId?:  {
    __typename: "ModelFobConnection",
    items:  Array< {
      __typename: "Fob",
      id: string,
      airport: string,
      forwarderCompanyId: string,
      forwarder?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      customItems?:  {
        __typename: "ModelCustomFeeConnection",
        items:  Array< {
          __typename: "CustomFee",
          id: string,
          title: string,
          unitPrice: number,
          unitType: string,
          calculationMethod: CalculationMethod,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum?: number | null,
          maximum?: number | null,
          includeTax?: boolean | null,
          taxRate?: number | null,
          remark?: string | null,
          fobId?: string | null,
          fhdId?: string | null,
          shippingFeeId?: string | null,
          estimateId?: string | null,
          createdAt: string,
          updatedAt: string,
          customFeeCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FhdsByForwarderCompanyIdQueryVariables = {
  forwarderCompanyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFhdFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FhdsByForwarderCompanyIdQuery = {
  fhdsByForwarderCompanyId?:  {
    __typename: "ModelFhdConnection",
    items:  Array< {
      __typename: "Fhd",
      id: string,
      airport: string,
      forwarderCompanyId: string,
      forwarder?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      customItems?:  {
        __typename: "ModelCustomFeeConnection",
        items:  Array< {
          __typename: "CustomFee",
          id: string,
          title: string,
          unitPrice: number,
          unitType: string,
          calculationMethod: CalculationMethod,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum?: number | null,
          maximum?: number | null,
          includeTax?: boolean | null,
          taxRate?: number | null,
          remark?: string | null,
          fobId?: string | null,
          fhdId?: string | null,
          shippingFeeId?: string | null,
          estimateId?: string | null,
          createdAt: string,
          updatedAt: string,
          customFeeCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type InvitedUsersByCompanyIdQueryVariables = {
  companyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvitedUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InvitedUsersByCompanyIdQuery = {
  invitedUsersByCompanyId?:  {
    __typename: "ModelInvitedUserConnection",
    items:  Array< {
      __typename: "InvitedUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      companyId: string,
      isForwarder: boolean,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCurrencyQueryVariables = {
  id: string,
};

export type GetCurrencyQuery = {
  getCurrency?:  {
    __typename: "Currency",
    id: string,
    code: string,
    exchangeRateJPY: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCurrenciesQueryVariables = {
  filter?: ModelCurrencyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCurrenciesQuery = {
  listCurrencies?:  {
    __typename: "ModelCurrencyConnection",
    items:  Array< {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetForwarderUserQueryVariables = {
  id: string,
};

export type GetForwarderUserQuery = {
  getForwarderUser?:  {
    __typename: "ForwarderUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    workLocation: string,
    division: string,
    position?: string | null,
    companyId?: string | null,
    company?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    postedReviews?:  {
      __typename: "ModelForwarderReviewConnection",
      items:  Array< {
        __typename: "ForwarderReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderUserPostedReviewsId?: string | null,
        shipperCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    estimates?:  {
      __typename: "ModelEstimateConnection",
      items:  Array< {
        __typename: "Estimate",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        cargoType: CargoType,
        yardCutDate: string,
        carrierShedCutDate: string,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum: number,
        under45: number,
        over45: number,
        over100: number,
        over300: number,
        over500: number,
        over1000: number,
        fsc: number,
        remark?: string | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        forwarderUser:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        status: EstimateStatus,
        createdAt: string,
        updatedAt: string,
        forwarderUserEstimatesId?: string | null,
        estimateCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListForwarderUsersQueryVariables = {
  filter?: ModelForwarderUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListForwarderUsersQuery = {
  listForwarderUsers?:  {
    __typename: "ModelForwarderUserConnection",
    items:  Array< {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ForwarderUsersByCompanyIdQueryVariables = {
  companyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelForwarderUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ForwarderUsersByCompanyIdQuery = {
  forwarderUsersByCompanyId?:  {
    __typename: "ModelForwarderUserConnection",
    items:  Array< {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCustomFeeQueryVariables = {
  id: string,
};

export type GetCustomFeeQuery = {
  getCustomFee?:  {
    __typename: "CustomFee",
    id: string,
    title: string,
    unitPrice: number,
    unitType: string,
    calculationMethod: CalculationMethod,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    minimum?: number | null,
    maximum?: number | null,
    includeTax?: boolean | null,
    taxRate?: number | null,
    remark?: string | null,
    fobId?: string | null,
    fhdId?: string | null,
    shippingFeeId?: string | null,
    estimateId?: string | null,
    createdAt: string,
    updatedAt: string,
    customFeeCurrencyId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListCustomFeesQueryVariables = {
  filter?: ModelCustomFeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomFeesQuery = {
  listCustomFees?:  {
    __typename: "ModelCustomFeeConnection",
    items:  Array< {
      __typename: "CustomFee",
      id: string,
      title: string,
      unitPrice: number,
      unitType: string,
      calculationMethod: CalculationMethod,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      minimum?: number | null,
      maximum?: number | null,
      includeTax?: boolean | null,
      taxRate?: number | null,
      remark?: string | null,
      fobId?: string | null,
      fhdId?: string | null,
      shippingFeeId?: string | null,
      estimateId?: string | null,
      createdAt: string,
      updatedAt: string,
      customFeeCurrencyId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomFeesByFobIdQueryVariables = {
  fobId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomFeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomFeesByFobIdQuery = {
  customFeesByFobId?:  {
    __typename: "ModelCustomFeeConnection",
    items:  Array< {
      __typename: "CustomFee",
      id: string,
      title: string,
      unitPrice: number,
      unitType: string,
      calculationMethod: CalculationMethod,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      minimum?: number | null,
      maximum?: number | null,
      includeTax?: boolean | null,
      taxRate?: number | null,
      remark?: string | null,
      fobId?: string | null,
      fhdId?: string | null,
      shippingFeeId?: string | null,
      estimateId?: string | null,
      createdAt: string,
      updatedAt: string,
      customFeeCurrencyId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomFeesByFhdIdQueryVariables = {
  fhdId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomFeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomFeesByFhdIdQuery = {
  customFeesByFhdId?:  {
    __typename: "ModelCustomFeeConnection",
    items:  Array< {
      __typename: "CustomFee",
      id: string,
      title: string,
      unitPrice: number,
      unitType: string,
      calculationMethod: CalculationMethod,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      minimum?: number | null,
      maximum?: number | null,
      includeTax?: boolean | null,
      taxRate?: number | null,
      remark?: string | null,
      fobId?: string | null,
      fhdId?: string | null,
      shippingFeeId?: string | null,
      estimateId?: string | null,
      createdAt: string,
      updatedAt: string,
      customFeeCurrencyId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomFeesByShippingFeeIdQueryVariables = {
  shippingFeeId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomFeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomFeesByShippingFeeIdQuery = {
  customFeesByShippingFeeId?:  {
    __typename: "ModelCustomFeeConnection",
    items:  Array< {
      __typename: "CustomFee",
      id: string,
      title: string,
      unitPrice: number,
      unitType: string,
      calculationMethod: CalculationMethod,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      minimum?: number | null,
      maximum?: number | null,
      includeTax?: boolean | null,
      taxRate?: number | null,
      remark?: string | null,
      fobId?: string | null,
      fhdId?: string | null,
      shippingFeeId?: string | null,
      estimateId?: string | null,
      createdAt: string,
      updatedAt: string,
      customFeeCurrencyId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomFeesByEstimateIdQueryVariables = {
  estimateId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomFeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomFeesByEstimateIdQuery = {
  customFeesByEstimateId?:  {
    __typename: "ModelCustomFeeConnection",
    items:  Array< {
      __typename: "CustomFee",
      id: string,
      title: string,
      unitPrice: number,
      unitType: string,
      calculationMethod: CalculationMethod,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      minimum?: number | null,
      maximum?: number | null,
      includeTax?: boolean | null,
      taxRate?: number | null,
      remark?: string | null,
      fobId?: string | null,
      fhdId?: string | null,
      shippingFeeId?: string | null,
      estimateId?: string | null,
      createdAt: string,
      updatedAt: string,
      customFeeCurrencyId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetForwarderCompanyQueryVariables = {
  id: string,
};

export type GetForwarderCompanyQuery = {
  getForwarderCompany?:  {
    __typename: "ForwarderCompany",
    id: string,
    name: string,
    firstName: string,
    lastName: string,
    location: string,
    establishment: string,
    paidInCaptial: number,
    corporateNumber: string,
    iataAgency: string,
    listed: boolean,
    numberOfEmployees: NumberOfEmployees,
    companyURL: string,
    manager:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    fobs?:  {
      __typename: "ModelFobConnection",
      items:  Array< {
        __typename: "Fob",
        id: string,
        airport: string,
        forwarderCompanyId: string,
        forwarder?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fhds?:  {
      __typename: "ModelFhdConnection",
      items:  Array< {
        __typename: "Fhd",
        id: string,
        airport: string,
        forwarderCompanyId: string,
        forwarder?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    employees?:  {
      __typename: "ModelForwarderUserConnection",
      items:  Array< {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    receivedReviews?:  {
      __typename: "ModelShipperReviewConnection",
      items:  Array< {
        __typename: "ShipperReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperUserPostedReviewsId?: string | null,
        forwarderCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    forwarderCompanyManagerId: string,
    owner?: string | null,
  } | null,
};

export type ListForwarderCompaniesQueryVariables = {
  filter?: ModelForwarderCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListForwarderCompaniesQuery = {
  listForwarderCompanies?:  {
    __typename: "ModelForwarderCompanyConnection",
    items:  Array< {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetShipperCompanyQueryVariables = {
  id: string,
};

export type GetShipperCompanyQuery = {
  getShipperCompany?:  {
    __typename: "ShipperCompany",
    id: string,
    name: string,
    firstName: string,
    lastName: string,
    location: string,
    establishment: string,
    paidInCaptial: number,
    corporateNumber: string,
    category: string,
    listed: boolean,
    numberOfEmployees: NumberOfEmployees,
    companyURL: string,
    manager:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    employees?:  {
      __typename: "ModelShipperUserConnection",
      items:  Array< {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    receivedReviews?:  {
      __typename: "ModelForwarderReviewConnection",
      items:  Array< {
        __typename: "ForwarderReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderUserPostedReviewsId?: string | null,
        shipperCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shipperCompanyManagerId: string,
    owner?: string | null,
  } | null,
};

export type ListShipperCompaniesQueryVariables = {
  filter?: ModelShipperCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShipperCompaniesQuery = {
  listShipperCompanies?:  {
    __typename: "ModelShipperCompanyConnection",
    items:  Array< {
      __typename: "ShipperCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      category: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      employees?:  {
        __typename: "ModelShipperUserConnection",
        items:  Array< {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shipperCompanyManagerId: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEstimateQueryVariables = {
  id: string,
};

export type GetEstimateQuery = {
  getEstimate?:  {
    __typename: "Estimate",
    id: string,
    departure: string,
    arrival: string,
    airline: string,
    flightDate: string,
    directFlight: boolean,
    leadTime: number,
    useSaf: boolean,
    cargoType: CargoType,
    yardCutDate: string,
    carrierShedCutDate: string,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    minimum: number,
    under45: number,
    over45: number,
    over100: number,
    over300: number,
    over500: number,
    over1000: number,
    fsc: number,
    remark?: string | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    forwarderUser:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    status: EstimateStatus,
    createdAt: string,
    updatedAt: string,
    forwarderUserEstimatesId?: string | null,
    estimateCurrencyId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListEstimatesQueryVariables = {
  filter?: ModelEstimateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEstimatesQuery = {
  listEstimates?:  {
    __typename: "ModelEstimateConnection",
    items:  Array< {
      __typename: "Estimate",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      cargoType: CargoType,
      yardCutDate: string,
      carrierShedCutDate: string,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      minimum: number,
      under45: number,
      over45: number,
      over100: number,
      over300: number,
      over500: number,
      over1000: number,
      fsc: number,
      remark?: string | null,
      customItems?:  {
        __typename: "ModelCustomFeeConnection",
        items:  Array< {
          __typename: "CustomFee",
          id: string,
          title: string,
          unitPrice: number,
          unitType: string,
          calculationMethod: CalculationMethod,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum?: number | null,
          maximum?: number | null,
          includeTax?: boolean | null,
          taxRate?: number | null,
          remark?: string | null,
          fobId?: string | null,
          fhdId?: string | null,
          shippingFeeId?: string | null,
          estimateId?: string | null,
          createdAt: string,
          updatedAt: string,
          customFeeCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      forwarderUser:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      status: EstimateStatus,
      createdAt: string,
      updatedAt: string,
      forwarderUserEstimatesId?: string | null,
      estimateCurrencyId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEstimatesByDepartureQueryVariables = {
  departure: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstimateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEstimatesByDepartureQuery = {
  listEstimatesByDeparture?:  {
    __typename: "ModelEstimateConnection",
    items:  Array< {
      __typename: "Estimate",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      cargoType: CargoType,
      yardCutDate: string,
      carrierShedCutDate: string,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      minimum: number,
      under45: number,
      over45: number,
      over100: number,
      over300: number,
      over500: number,
      over1000: number,
      fsc: number,
      remark?: string | null,
      customItems?:  {
        __typename: "ModelCustomFeeConnection",
        items:  Array< {
          __typename: "CustomFee",
          id: string,
          title: string,
          unitPrice: number,
          unitType: string,
          calculationMethod: CalculationMethod,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum?: number | null,
          maximum?: number | null,
          includeTax?: boolean | null,
          taxRate?: number | null,
          remark?: string | null,
          fobId?: string | null,
          fhdId?: string | null,
          shippingFeeId?: string | null,
          estimateId?: string | null,
          createdAt: string,
          updatedAt: string,
          customFeeCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      forwarderUser:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      status: EstimateStatus,
      createdAt: string,
      updatedAt: string,
      forwarderUserEstimatesId?: string | null,
      estimateCurrencyId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListFollowsByArrivalQueryVariables = {
  arrival: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstimateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFollowsByArrivalQuery = {
  listFollowsByArrival?:  {
    __typename: "ModelEstimateConnection",
    items:  Array< {
      __typename: "Estimate",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      cargoType: CargoType,
      yardCutDate: string,
      carrierShedCutDate: string,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      minimum: number,
      under45: number,
      over45: number,
      over100: number,
      over300: number,
      over500: number,
      over1000: number,
      fsc: number,
      remark?: string | null,
      customItems?:  {
        __typename: "ModelCustomFeeConnection",
        items:  Array< {
          __typename: "CustomFee",
          id: string,
          title: string,
          unitPrice: number,
          unitType: string,
          calculationMethod: CalculationMethod,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum?: number | null,
          maximum?: number | null,
          includeTax?: boolean | null,
          taxRate?: number | null,
          remark?: string | null,
          fobId?: string | null,
          fhdId?: string | null,
          shippingFeeId?: string | null,
          estimateId?: string | null,
          createdAt: string,
          updatedAt: string,
          customFeeCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      forwarderUser:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      status: EstimateStatus,
      createdAt: string,
      updatedAt: string,
      forwarderUserEstimatesId?: string | null,
      estimateCurrencyId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEstimatesByFlightDateQueryVariables = {
  flightDate: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEstimateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEstimatesByFlightDateQuery = {
  listEstimatesByFlightDate?:  {
    __typename: "ModelEstimateConnection",
    items:  Array< {
      __typename: "Estimate",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      cargoType: CargoType,
      yardCutDate: string,
      carrierShedCutDate: string,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      minimum: number,
      under45: number,
      over45: number,
      over100: number,
      over300: number,
      over500: number,
      over1000: number,
      fsc: number,
      remark?: string | null,
      customItems?:  {
        __typename: "ModelCustomFeeConnection",
        items:  Array< {
          __typename: "CustomFee",
          id: string,
          title: string,
          unitPrice: number,
          unitType: string,
          calculationMethod: CalculationMethod,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum?: number | null,
          maximum?: number | null,
          includeTax?: boolean | null,
          taxRate?: number | null,
          remark?: string | null,
          fobId?: string | null,
          fhdId?: string | null,
          shippingFeeId?: string | null,
          estimateId?: string | null,
          createdAt: string,
          updatedAt: string,
          customFeeCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      forwarderUser:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      status: EstimateStatus,
      createdAt: string,
      updatedAt: string,
      forwarderUserEstimatesId?: string | null,
      estimateCurrencyId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNewsQueryVariables = {
  id: string,
};

export type GetNewsQuery = {
  getNews?:  {
    __typename: "News",
    id: string,
    title: string,
    newsDate: string,
    newsContent?: string | null,
    mediaUrl?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListNewsQueryVariables = {
  filter?: ModelNewsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNewsQuery = {
  listNews?:  {
    __typename: "ModelNewsConnection",
    items:  Array< {
      __typename: "News",
      id: string,
      title: string,
      newsDate: string,
      newsContent?: string | null,
      mediaUrl?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMarketConditionsWithSafQueryVariables = {
  id: string,
};

export type GetMarketConditionsWithSafQuery = {
  getMarketConditionsWithSaf?:  {
    __typename: "MarketConditionsWithSaf",
    id: string,
    departure: string,
    arrival: string,
    flightDate: string,
    marketPrice: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListMarketConditionsWithSafsQueryVariables = {
  filter?: ModelMarketConditionsWithSafFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMarketConditionsWithSafsQuery = {
  listMarketConditionsWithSafs?:  {
    __typename: "ModelMarketConditionsWithSafConnection",
    items:  Array< {
      __typename: "MarketConditionsWithSaf",
      id: string,
      departure: string,
      arrival: string,
      flightDate: string,
      marketPrice: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMarketConditionsWithoutSafQueryVariables = {
  id: string,
};

export type GetMarketConditionsWithoutSafQuery = {
  getMarketConditionsWithoutSaf?:  {
    __typename: "MarketConditionsWithoutSaf",
    id: string,
    departure: string,
    arrival: string,
    flightDate: string,
    marketPrice: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListMarketConditionsWithoutSafsQueryVariables = {
  filter?: ModelMarketConditionsWithoutSafFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMarketConditionsWithoutSafsQuery = {
  listMarketConditionsWithoutSafs?:  {
    __typename: "ModelMarketConditionsWithoutSafConnection",
    items:  Array< {
      __typename: "MarketConditionsWithoutSaf",
      id: string,
      departure: string,
      arrival: string,
      flightDate: string,
      marketPrice: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateShipperUserSubscriptionVariables = {
  filter?: ModelSubscriptionShipperUserFilterInput | null,
  owner?: string | null,
};

export type OnCreateShipperUserSubscription = {
  onCreateShipperUser?:  {
    __typename: "ShipperUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    workLocation: string,
    division: string,
    position?: string | null,
    area: string,
    role: ShipperRole,
    companyId?: string | null,
    company?:  {
      __typename: "ShipperCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      category: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      employees?:  {
        __typename: "ModelShipperUserConnection",
        items:  Array< {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shipperCompanyManagerId: string,
      owner?: string | null,
    } | null,
    postedReviews?:  {
      __typename: "ModelShipperReviewConnection",
      items:  Array< {
        __typename: "ShipperReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperUserPostedReviewsId?: string | null,
        forwarderCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateShipperUserSubscriptionVariables = {
  filter?: ModelSubscriptionShipperUserFilterInput | null,
  owner?: string | null,
};

export type OnUpdateShipperUserSubscription = {
  onUpdateShipperUser?:  {
    __typename: "ShipperUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    workLocation: string,
    division: string,
    position?: string | null,
    area: string,
    role: ShipperRole,
    companyId?: string | null,
    company?:  {
      __typename: "ShipperCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      category: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      employees?:  {
        __typename: "ModelShipperUserConnection",
        items:  Array< {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shipperCompanyManagerId: string,
      owner?: string | null,
    } | null,
    postedReviews?:  {
      __typename: "ModelShipperReviewConnection",
      items:  Array< {
        __typename: "ShipperReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperUserPostedReviewsId?: string | null,
        forwarderCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteShipperUserSubscriptionVariables = {
  filter?: ModelSubscriptionShipperUserFilterInput | null,
  owner?: string | null,
};

export type OnDeleteShipperUserSubscription = {
  onDeleteShipperUser?:  {
    __typename: "ShipperUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    workLocation: string,
    division: string,
    position?: string | null,
    area: string,
    role: ShipperRole,
    companyId?: string | null,
    company?:  {
      __typename: "ShipperCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      category: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      employees?:  {
        __typename: "ModelShipperUserConnection",
        items:  Array< {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shipperCompanyManagerId: string,
      owner?: string | null,
    } | null,
    postedReviews?:  {
      __typename: "ModelShipperReviewConnection",
      items:  Array< {
        __typename: "ShipperReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperUserPostedReviewsId?: string | null,
        forwarderCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateFobSubscriptionVariables = {
  filter?: ModelSubscriptionFobFilterInput | null,
  owner?: string | null,
};

export type OnCreateFobSubscription = {
  onCreateFob?:  {
    __typename: "Fob",
    id: string,
    airport: string,
    forwarderCompanyId: string,
    forwarder?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateFobSubscriptionVariables = {
  filter?: ModelSubscriptionFobFilterInput | null,
  owner?: string | null,
};

export type OnUpdateFobSubscription = {
  onUpdateFob?:  {
    __typename: "Fob",
    id: string,
    airport: string,
    forwarderCompanyId: string,
    forwarder?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteFobSubscriptionVariables = {
  filter?: ModelSubscriptionFobFilterInput | null,
  owner?: string | null,
};

export type OnDeleteFobSubscription = {
  onDeleteFob?:  {
    __typename: "Fob",
    id: string,
    airport: string,
    forwarderCompanyId: string,
    forwarder?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateFhdSubscriptionVariables = {
  filter?: ModelSubscriptionFhdFilterInput | null,
  owner?: string | null,
};

export type OnCreateFhdSubscription = {
  onCreateFhd?:  {
    __typename: "Fhd",
    id: string,
    airport: string,
    forwarderCompanyId: string,
    forwarder?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateFhdSubscriptionVariables = {
  filter?: ModelSubscriptionFhdFilterInput | null,
  owner?: string | null,
};

export type OnUpdateFhdSubscription = {
  onUpdateFhd?:  {
    __typename: "Fhd",
    id: string,
    airport: string,
    forwarderCompanyId: string,
    forwarder?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteFhdSubscriptionVariables = {
  filter?: ModelSubscriptionFhdFilterInput | null,
  owner?: string | null,
};

export type OnDeleteFhdSubscription = {
  onDeleteFhd?:  {
    __typename: "Fhd",
    id: string,
    airport: string,
    forwarderCompanyId: string,
    forwarder?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateForwarderReviewSubscriptionVariables = {
  filter?: ModelSubscriptionForwarderReviewFilterInput | null,
  owner?: string | null,
};

export type OnCreateForwarderReviewSubscription = {
  onCreateForwarderReview?:  {
    __typename: "ForwarderReview",
    id: string,
    title: string,
    description: string,
    rate: number,
    company?:  {
      __typename: "ShipperCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      category: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      employees?:  {
        __typename: "ModelShipperUserConnection",
        items:  Array< {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shipperCompanyManagerId: string,
      owner?: string | null,
    } | null,
    author?:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    forwarderUserPostedReviewsId?: string | null,
    shipperCompanyReceivedReviewsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateForwarderReviewSubscriptionVariables = {
  filter?: ModelSubscriptionForwarderReviewFilterInput | null,
  owner?: string | null,
};

export type OnUpdateForwarderReviewSubscription = {
  onUpdateForwarderReview?:  {
    __typename: "ForwarderReview",
    id: string,
    title: string,
    description: string,
    rate: number,
    company?:  {
      __typename: "ShipperCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      category: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      employees?:  {
        __typename: "ModelShipperUserConnection",
        items:  Array< {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shipperCompanyManagerId: string,
      owner?: string | null,
    } | null,
    author?:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    forwarderUserPostedReviewsId?: string | null,
    shipperCompanyReceivedReviewsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteForwarderReviewSubscriptionVariables = {
  filter?: ModelSubscriptionForwarderReviewFilterInput | null,
  owner?: string | null,
};

export type OnDeleteForwarderReviewSubscription = {
  onDeleteForwarderReview?:  {
    __typename: "ForwarderReview",
    id: string,
    title: string,
    description: string,
    rate: number,
    company?:  {
      __typename: "ShipperCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      category: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      employees?:  {
        __typename: "ModelShipperUserConnection",
        items:  Array< {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shipperCompanyManagerId: string,
      owner?: string | null,
    } | null,
    author?:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    forwarderUserPostedReviewsId?: string | null,
    shipperCompanyReceivedReviewsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateShipperReviewSubscriptionVariables = {
  filter?: ModelSubscriptionShipperReviewFilterInput | null,
  owner?: string | null,
};

export type OnCreateShipperReviewSubscription = {
  onCreateShipperReview?:  {
    __typename: "ShipperReview",
    id: string,
    title: string,
    description: string,
    rate: number,
    company?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    author?:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shipperUserPostedReviewsId?: string | null,
    forwarderCompanyReceivedReviewsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateShipperReviewSubscriptionVariables = {
  filter?: ModelSubscriptionShipperReviewFilterInput | null,
  owner?: string | null,
};

export type OnUpdateShipperReviewSubscription = {
  onUpdateShipperReview?:  {
    __typename: "ShipperReview",
    id: string,
    title: string,
    description: string,
    rate: number,
    company?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    author?:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shipperUserPostedReviewsId?: string | null,
    forwarderCompanyReceivedReviewsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteShipperReviewSubscriptionVariables = {
  filter?: ModelSubscriptionShipperReviewFilterInput | null,
  owner?: string | null,
};

export type OnDeleteShipperReviewSubscription = {
  onDeleteShipperReview?:  {
    __typename: "ShipperReview",
    id: string,
    title: string,
    description: string,
    rate: number,
    company?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    author?:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shipperUserPostedReviewsId?: string | null,
    forwarderCompanyReceivedReviewsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateInvitedUserSubscriptionVariables = {
  filter?: ModelSubscriptionInvitedUserFilterInput | null,
  owner?: string | null,
  email?: string | null,
};

export type OnCreateInvitedUserSubscription = {
  onCreateInvitedUser?:  {
    __typename: "InvitedUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    companyId: string,
    isForwarder: boolean,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateInvitedUserSubscriptionVariables = {
  filter?: ModelSubscriptionInvitedUserFilterInput | null,
  owner?: string | null,
  email?: string | null,
};

export type OnUpdateInvitedUserSubscription = {
  onUpdateInvitedUser?:  {
    __typename: "InvitedUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    companyId: string,
    isForwarder: boolean,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteInvitedUserSubscriptionVariables = {
  filter?: ModelSubscriptionInvitedUserFilterInput | null,
  owner?: string | null,
  email?: string | null,
};

export type OnDeleteInvitedUserSubscription = {
  onDeleteInvitedUser?:  {
    __typename: "InvitedUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    companyId: string,
    isForwarder: boolean,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateShippingCustomFeeSubscriptionVariables = {
  filter?: ModelSubscriptionShippingCustomFeeFilterInput | null,
  owner?: string | null,
};

export type OnCreateShippingCustomFeeSubscription = {
  onCreateShippingCustomFee?:  {
    __typename: "ShippingCustomFee",
    id: string,
    title: string,
    currency:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    unitPrice: number,
    unitType: string,
    fee: number,
    taxRate?: number | null,
    maximum?: number | null,
    minimum?: number | null,
    remark: string,
    shippingFee?:  {
      __typename: "ShippingFee",
      id: string,
      price?: number | null,
      fsc?: number | null,
      minimum?: number | null,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFeeCurrencyId?: string | null,
      shippingFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    shippingFobFee?:  {
      __typename: "ShippingFobFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFobFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    shippingFhdFee?:  {
      __typename: "ShippingFhdFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFhdFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFeeCustomFeesId?: string | null,
    shippingFobFeeCustomFeesId?: string | null,
    shippingFhdFeeCustomFeesId?: string | null,
    shippingCustomFeeCurrencyId: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateShippingCustomFeeSubscriptionVariables = {
  filter?: ModelSubscriptionShippingCustomFeeFilterInput | null,
  owner?: string | null,
};

export type OnUpdateShippingCustomFeeSubscription = {
  onUpdateShippingCustomFee?:  {
    __typename: "ShippingCustomFee",
    id: string,
    title: string,
    currency:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    unitPrice: number,
    unitType: string,
    fee: number,
    taxRate?: number | null,
    maximum?: number | null,
    minimum?: number | null,
    remark: string,
    shippingFee?:  {
      __typename: "ShippingFee",
      id: string,
      price?: number | null,
      fsc?: number | null,
      minimum?: number | null,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFeeCurrencyId?: string | null,
      shippingFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    shippingFobFee?:  {
      __typename: "ShippingFobFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFobFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    shippingFhdFee?:  {
      __typename: "ShippingFhdFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFhdFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFeeCustomFeesId?: string | null,
    shippingFobFeeCustomFeesId?: string | null,
    shippingFhdFeeCustomFeesId?: string | null,
    shippingCustomFeeCurrencyId: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteShippingCustomFeeSubscriptionVariables = {
  filter?: ModelSubscriptionShippingCustomFeeFilterInput | null,
  owner?: string | null,
};

export type OnDeleteShippingCustomFeeSubscription = {
  onDeleteShippingCustomFee?:  {
    __typename: "ShippingCustomFee",
    id: string,
    title: string,
    currency:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    unitPrice: number,
    unitType: string,
    fee: number,
    taxRate?: number | null,
    maximum?: number | null,
    minimum?: number | null,
    remark: string,
    shippingFee?:  {
      __typename: "ShippingFee",
      id: string,
      price?: number | null,
      fsc?: number | null,
      minimum?: number | null,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFeeCurrencyId?: string | null,
      shippingFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    shippingFobFee?:  {
      __typename: "ShippingFobFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFobFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    shippingFhdFee?:  {
      __typename: "ShippingFhdFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFhdFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFeeCustomFeesId?: string | null,
    shippingFobFeeCustomFeesId?: string | null,
    shippingFhdFeeCustomFeesId?: string | null,
    shippingCustomFeeCurrencyId: string,
    owner?: string | null,
  } | null,
};

export type OnCreateShippingFeeSubscriptionVariables = {
  filter?: ModelSubscriptionShippingFeeFilterInput | null,
  owner?: string | null,
};

export type OnCreateShippingFeeSubscription = {
  onCreateShippingFee?:  {
    __typename: "ShippingFee",
    id: string,
    price?: number | null,
    fsc?: number | null,
    minimum?: number | null,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFeeCurrencyId?: string | null,
    shippingFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateShippingFeeSubscriptionVariables = {
  filter?: ModelSubscriptionShippingFeeFilterInput | null,
  owner?: string | null,
};

export type OnUpdateShippingFeeSubscription = {
  onUpdateShippingFee?:  {
    __typename: "ShippingFee",
    id: string,
    price?: number | null,
    fsc?: number | null,
    minimum?: number | null,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFeeCurrencyId?: string | null,
    shippingFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteShippingFeeSubscriptionVariables = {
  filter?: ModelSubscriptionShippingFeeFilterInput | null,
  owner?: string | null,
};

export type OnDeleteShippingFeeSubscription = {
  onDeleteShippingFee?:  {
    __typename: "ShippingFee",
    id: string,
    price?: number | null,
    fsc?: number | null,
    minimum?: number | null,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFeeCurrencyId?: string | null,
    shippingFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateShippingFobFeeSubscriptionVariables = {
  filter?: ModelSubscriptionShippingFobFeeFilterInput | null,
  owner?: string | null,
};

export type OnCreateShippingFobFeeSubscription = {
  onCreateShippingFobFee?:  {
    __typename: "ShippingFobFee",
    id: string,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFobFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateShippingFobFeeSubscriptionVariables = {
  filter?: ModelSubscriptionShippingFobFeeFilterInput | null,
  owner?: string | null,
};

export type OnUpdateShippingFobFeeSubscription = {
  onUpdateShippingFobFee?:  {
    __typename: "ShippingFobFee",
    id: string,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFobFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteShippingFobFeeSubscriptionVariables = {
  filter?: ModelSubscriptionShippingFobFeeFilterInput | null,
  owner?: string | null,
};

export type OnDeleteShippingFobFeeSubscription = {
  onDeleteShippingFobFee?:  {
    __typename: "ShippingFobFee",
    id: string,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFobFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateShippingFhdFeeSubscriptionVariables = {
  filter?: ModelSubscriptionShippingFhdFeeFilterInput | null,
  owner?: string | null,
};

export type OnCreateShippingFhdFeeSubscription = {
  onCreateShippingFhdFee?:  {
    __typename: "ShippingFhdFee",
    id: string,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFhdFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateShippingFhdFeeSubscriptionVariables = {
  filter?: ModelSubscriptionShippingFhdFeeFilterInput | null,
  owner?: string | null,
};

export type OnUpdateShippingFhdFeeSubscription = {
  onUpdateShippingFhdFee?:  {
    __typename: "ShippingFhdFee",
    id: string,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFhdFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteShippingFhdFeeSubscriptionVariables = {
  filter?: ModelSubscriptionShippingFhdFeeFilterInput | null,
  owner?: string | null,
};

export type OnDeleteShippingFhdFeeSubscription = {
  onDeleteShippingFhdFee?:  {
    __typename: "ShippingFhdFee",
    id: string,
    customFees?:  {
      __typename: "ModelShippingCustomFeeConnection",
      items:  Array< {
        __typename: "ShippingCustomFee",
        id: string,
        title: string,
        currency:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        unitPrice: number,
        unitType: string,
        fee: number,
        taxRate?: number | null,
        maximum?: number | null,
        minimum?: number | null,
        remark: string,
        shippingFee?:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFobFee?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        shippingFhdFee?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCustomFeesId?: string | null,
        shippingFobFeeCustomFeesId?: string | null,
        shippingFhdFeeCustomFeesId?: string | null,
        shippingCustomFeeCurrencyId: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    remark?: string | null,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingFhdFeeShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateShippingInstructionSubscriptionVariables = {
  filter?: ModelSubscriptionShippingInstructionFilterInput | null,
  owner?: string | null,
};

export type OnCreateShippingInstructionSubscription = {
  onCreateShippingInstruction?:  {
    __typename: "ShippingInstruction",
    id: string,
    departure: string,
    arrival: string,
    airline: string,
    flightDate: string,
    directFlight: boolean,
    leadTime: number,
    useSaf: boolean,
    yardCutDate: string,
    carrierShedCutDate: string,
    itemName: string,
    packingStyle: string,
    packingStyleDetail?: string | null,
    cargoType: CargoType,
    isSds?: boolean | null,
    regulated: string,
    regulatedDetail?: string | null,
    paymentCondition: PaymentCondition,
    paymentConditionDetail?: string | null,
    taxPaymentConditions:  {
      __typename: "TaxPaymentConditions",
      realTimeAccount?: string | null,
      comprehensiveDeferredPayment?: string | null,
      cashOnDeliveryAllowed?: boolean | null,
      beforePermissionPickup?: boolean | null,
      othersOption?: string | null,
    },
    cargoInsuranceConditions?:  {
      __typename: "CargoInsuranceConditions",
      comprehensiveInsurance?: string | null,
      individualInsurance?: boolean | null,
      uninsuredWithInsurance?: boolean | null,
      othersOption?: string | null,
    } | null,
    document?:  {
      __typename: "Document",
      invoice?: boolean | null,
      packingList?: boolean | null,
      nonApplicabilityCertificate?: boolean | null,
      msds?: boolean | null,
      other?: boolean | null,
      detail?: string | null,
    } | null,
    consigneeContact?:  {
      __typename: "Contact",
      companyName: string,
      name: string,
      email: string,
      phoneNumber: string,
    } | null,
    shipperContact?:  {
      __typename: "Contact",
      companyName: string,
      name: string,
      email: string,
      phoneNumber: string,
    } | null,
    cargoInsurance: boolean,
    cargoInsuranceDetail?: string | null,
    packed: boolean,
    packedDetail?: string | null,
    collected: boolean,
    collectedDetail?: string | null,
    delivery: boolean,
    deliveryDetail?: string | null,
    request?: string | null,
    shipperUser:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    shippingItems?:  Array< {
      __typename: "ShippingItem",
      width: number,
      length: number,
      height: number,
      weight: number,
      numberOfUnit: number,
      volume: number,
      gw: number,
      volumeWeight: number,
      cw: number,
    } | null > | null,
    transportType: TransportType,
    shippingFee:  {
      __typename: "ShippingFee",
      id: string,
      price?: number | null,
      fsc?: number | null,
      minimum?: number | null,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFeeCurrencyId?: string | null,
      shippingFeeShippingInstructionId?: string | null,
      owner?: string | null,
    },
    fob?:  {
      __typename: "ShippingFobFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFobFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    fhd?:  {
      __typename: "ShippingFhdFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFhdFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    reservation?:  {
      __typename: "Reservation",
      id: string,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      hawb?: string | null,
      status?: Status | null,
      forwarderCompanyId?: string | null,
      shipperCompanyId?: string | null,
      departure?: string | null,
      createdAt: string,
      updatedAt: string,
      reservationShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingInstructionShipperUserId: string,
    shippingInstructionShippingFeeId: string,
    shippingInstructionFobId?: string | null,
    shippingInstructionFhdId?: string | null,
    shippingInstructionReservationId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateShippingInstructionSubscriptionVariables = {
  filter?: ModelSubscriptionShippingInstructionFilterInput | null,
  owner?: string | null,
};

export type OnUpdateShippingInstructionSubscription = {
  onUpdateShippingInstruction?:  {
    __typename: "ShippingInstruction",
    id: string,
    departure: string,
    arrival: string,
    airline: string,
    flightDate: string,
    directFlight: boolean,
    leadTime: number,
    useSaf: boolean,
    yardCutDate: string,
    carrierShedCutDate: string,
    itemName: string,
    packingStyle: string,
    packingStyleDetail?: string | null,
    cargoType: CargoType,
    isSds?: boolean | null,
    regulated: string,
    regulatedDetail?: string | null,
    paymentCondition: PaymentCondition,
    paymentConditionDetail?: string | null,
    taxPaymentConditions:  {
      __typename: "TaxPaymentConditions",
      realTimeAccount?: string | null,
      comprehensiveDeferredPayment?: string | null,
      cashOnDeliveryAllowed?: boolean | null,
      beforePermissionPickup?: boolean | null,
      othersOption?: string | null,
    },
    cargoInsuranceConditions?:  {
      __typename: "CargoInsuranceConditions",
      comprehensiveInsurance?: string | null,
      individualInsurance?: boolean | null,
      uninsuredWithInsurance?: boolean | null,
      othersOption?: string | null,
    } | null,
    document?:  {
      __typename: "Document",
      invoice?: boolean | null,
      packingList?: boolean | null,
      nonApplicabilityCertificate?: boolean | null,
      msds?: boolean | null,
      other?: boolean | null,
      detail?: string | null,
    } | null,
    consigneeContact?:  {
      __typename: "Contact",
      companyName: string,
      name: string,
      email: string,
      phoneNumber: string,
    } | null,
    shipperContact?:  {
      __typename: "Contact",
      companyName: string,
      name: string,
      email: string,
      phoneNumber: string,
    } | null,
    cargoInsurance: boolean,
    cargoInsuranceDetail?: string | null,
    packed: boolean,
    packedDetail?: string | null,
    collected: boolean,
    collectedDetail?: string | null,
    delivery: boolean,
    deliveryDetail?: string | null,
    request?: string | null,
    shipperUser:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    shippingItems?:  Array< {
      __typename: "ShippingItem",
      width: number,
      length: number,
      height: number,
      weight: number,
      numberOfUnit: number,
      volume: number,
      gw: number,
      volumeWeight: number,
      cw: number,
    } | null > | null,
    transportType: TransportType,
    shippingFee:  {
      __typename: "ShippingFee",
      id: string,
      price?: number | null,
      fsc?: number | null,
      minimum?: number | null,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFeeCurrencyId?: string | null,
      shippingFeeShippingInstructionId?: string | null,
      owner?: string | null,
    },
    fob?:  {
      __typename: "ShippingFobFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFobFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    fhd?:  {
      __typename: "ShippingFhdFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFhdFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    reservation?:  {
      __typename: "Reservation",
      id: string,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      hawb?: string | null,
      status?: Status | null,
      forwarderCompanyId?: string | null,
      shipperCompanyId?: string | null,
      departure?: string | null,
      createdAt: string,
      updatedAt: string,
      reservationShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingInstructionShipperUserId: string,
    shippingInstructionShippingFeeId: string,
    shippingInstructionFobId?: string | null,
    shippingInstructionFhdId?: string | null,
    shippingInstructionReservationId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteShippingInstructionSubscriptionVariables = {
  filter?: ModelSubscriptionShippingInstructionFilterInput | null,
  owner?: string | null,
};

export type OnDeleteShippingInstructionSubscription = {
  onDeleteShippingInstruction?:  {
    __typename: "ShippingInstruction",
    id: string,
    departure: string,
    arrival: string,
    airline: string,
    flightDate: string,
    directFlight: boolean,
    leadTime: number,
    useSaf: boolean,
    yardCutDate: string,
    carrierShedCutDate: string,
    itemName: string,
    packingStyle: string,
    packingStyleDetail?: string | null,
    cargoType: CargoType,
    isSds?: boolean | null,
    regulated: string,
    regulatedDetail?: string | null,
    paymentCondition: PaymentCondition,
    paymentConditionDetail?: string | null,
    taxPaymentConditions:  {
      __typename: "TaxPaymentConditions",
      realTimeAccount?: string | null,
      comprehensiveDeferredPayment?: string | null,
      cashOnDeliveryAllowed?: boolean | null,
      beforePermissionPickup?: boolean | null,
      othersOption?: string | null,
    },
    cargoInsuranceConditions?:  {
      __typename: "CargoInsuranceConditions",
      comprehensiveInsurance?: string | null,
      individualInsurance?: boolean | null,
      uninsuredWithInsurance?: boolean | null,
      othersOption?: string | null,
    } | null,
    document?:  {
      __typename: "Document",
      invoice?: boolean | null,
      packingList?: boolean | null,
      nonApplicabilityCertificate?: boolean | null,
      msds?: boolean | null,
      other?: boolean | null,
      detail?: string | null,
    } | null,
    consigneeContact?:  {
      __typename: "Contact",
      companyName: string,
      name: string,
      email: string,
      phoneNumber: string,
    } | null,
    shipperContact?:  {
      __typename: "Contact",
      companyName: string,
      name: string,
      email: string,
      phoneNumber: string,
    } | null,
    cargoInsurance: boolean,
    cargoInsuranceDetail?: string | null,
    packed: boolean,
    packedDetail?: string | null,
    collected: boolean,
    collectedDetail?: string | null,
    delivery: boolean,
    deliveryDetail?: string | null,
    request?: string | null,
    shipperUser:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    shippingItems?:  Array< {
      __typename: "ShippingItem",
      width: number,
      length: number,
      height: number,
      weight: number,
      numberOfUnit: number,
      volume: number,
      gw: number,
      volumeWeight: number,
      cw: number,
    } | null > | null,
    transportType: TransportType,
    shippingFee:  {
      __typename: "ShippingFee",
      id: string,
      price?: number | null,
      fsc?: number | null,
      minimum?: number | null,
      currency?:  {
        __typename: "Currency",
        id: string,
        code: string,
        exchangeRateJPY: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFeeCurrencyId?: string | null,
      shippingFeeShippingInstructionId?: string | null,
      owner?: string | null,
    },
    fob?:  {
      __typename: "ShippingFobFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFobFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    fhd?:  {
      __typename: "ShippingFhdFee",
      id: string,
      customFees?:  {
        __typename: "ModelShippingCustomFeeConnection",
        items:  Array< {
          __typename: "ShippingCustomFee",
          id: string,
          title: string,
          currency:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          unitPrice: number,
          unitType: string,
          fee: number,
          taxRate?: number | null,
          maximum?: number | null,
          minimum?: number | null,
          remark: string,
          shippingFee?:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFobFee?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          shippingFhdFee?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCustomFeesId?: string | null,
          shippingFobFeeCustomFeesId?: string | null,
          shippingFhdFeeCustomFeesId?: string | null,
          shippingCustomFeeCurrencyId: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      remark?: string | null,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingFhdFeeShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    reservation?:  {
      __typename: "Reservation",
      id: string,
      shippingInstruction?:  {
        __typename: "ShippingInstruction",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        yardCutDate: string,
        carrierShedCutDate: string,
        itemName: string,
        packingStyle: string,
        packingStyleDetail?: string | null,
        cargoType: CargoType,
        isSds?: boolean | null,
        regulated: string,
        regulatedDetail?: string | null,
        paymentCondition: PaymentCondition,
        paymentConditionDetail?: string | null,
        taxPaymentConditions:  {
          __typename: "TaxPaymentConditions",
          realTimeAccount?: string | null,
          comprehensiveDeferredPayment?: string | null,
          cashOnDeliveryAllowed?: boolean | null,
          beforePermissionPickup?: boolean | null,
          othersOption?: string | null,
        },
        cargoInsuranceConditions?:  {
          __typename: "CargoInsuranceConditions",
          comprehensiveInsurance?: string | null,
          individualInsurance?: boolean | null,
          uninsuredWithInsurance?: boolean | null,
          othersOption?: string | null,
        } | null,
        document?:  {
          __typename: "Document",
          invoice?: boolean | null,
          packingList?: boolean | null,
          nonApplicabilityCertificate?: boolean | null,
          msds?: boolean | null,
          other?: boolean | null,
          detail?: string | null,
        } | null,
        consigneeContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        shipperContact?:  {
          __typename: "Contact",
          companyName: string,
          name: string,
          email: string,
          phoneNumber: string,
        } | null,
        cargoInsurance: boolean,
        cargoInsuranceDetail?: string | null,
        packed: boolean,
        packedDetail?: string | null,
        collected: boolean,
        collectedDetail?: string | null,
        delivery: boolean,
        deliveryDetail?: string | null,
        request?: string | null,
        shipperUser:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        shippingItems?:  Array< {
          __typename: "ShippingItem",
          width: number,
          length: number,
          height: number,
          weight: number,
          numberOfUnit: number,
          volume: number,
          gw: number,
          volumeWeight: number,
          cw: number,
        } | null > | null,
        transportType: TransportType,
        shippingFee:  {
          __typename: "ShippingFee",
          id: string,
          price?: number | null,
          fsc?: number | null,
          minimum?: number | null,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFeeCurrencyId?: string | null,
          shippingFeeShippingInstructionId?: string | null,
          owner?: string | null,
        },
        fob?:  {
          __typename: "ShippingFobFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFobFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        fhd?:  {
          __typename: "ShippingFhdFee",
          id: string,
          customFees?:  {
            __typename: "ModelShippingCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingFhdFeeShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        reservation?:  {
          __typename: "Reservation",
          id: string,
          shippingInstruction?:  {
            __typename: "ShippingInstruction",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            yardCutDate: string,
            carrierShedCutDate: string,
            itemName: string,
            packingStyle: string,
            packingStyleDetail?: string | null,
            cargoType: CargoType,
            isSds?: boolean | null,
            regulated: string,
            regulatedDetail?: string | null,
            paymentCondition: PaymentCondition,
            paymentConditionDetail?: string | null,
            cargoInsurance: boolean,
            cargoInsuranceDetail?: string | null,
            packed: boolean,
            packedDetail?: string | null,
            collected: boolean,
            collectedDetail?: string | null,
            delivery: boolean,
            deliveryDetail?: string | null,
            request?: string | null,
            transportType: TransportType,
            createdAt: string,
            updatedAt: string,
            shippingInstructionShipperUserId: string,
            shippingInstructionShippingFeeId: string,
            shippingInstructionFobId?: string | null,
            shippingInstructionFhdId?: string | null,
            shippingInstructionReservationId?: string | null,
            owner?: string | null,
          } | null,
          hawb?: string | null,
          status?: Status | null,
          forwarderCompanyId?: string | null,
          shipperCompanyId?: string | null,
          departure?: string | null,
          createdAt: string,
          updatedAt: string,
          reservationShippingInstructionId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingInstructionShipperUserId: string,
        shippingInstructionShippingFeeId: string,
        shippingInstructionFobId?: string | null,
        shippingInstructionFhdId?: string | null,
        shippingInstructionReservationId?: string | null,
        owner?: string | null,
      } | null,
      hawb?: string | null,
      status?: Status | null,
      forwarderCompanyId?: string | null,
      shipperCompanyId?: string | null,
      departure?: string | null,
      createdAt: string,
      updatedAt: string,
      reservationShippingInstructionId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shippingInstructionShipperUserId: string,
    shippingInstructionShippingFeeId: string,
    shippingInstructionFobId?: string | null,
    shippingInstructionFhdId?: string | null,
    shippingInstructionReservationId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateReservationSubscriptionVariables = {
  filter?: ModelSubscriptionReservationFilterInput | null,
  owner?: string | null,
};

export type OnCreateReservationSubscription = {
  onCreateReservation?:  {
    __typename: "Reservation",
    id: string,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    hawb?: string | null,
    status?: Status | null,
    forwarderCompanyId?: string | null,
    shipperCompanyId?: string | null,
    departure?: string | null,
    createdAt: string,
    updatedAt: string,
    reservationShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateReservationSubscriptionVariables = {
  filter?: ModelSubscriptionReservationFilterInput | null,
  owner?: string | null,
};

export type OnUpdateReservationSubscription = {
  onUpdateReservation?:  {
    __typename: "Reservation",
    id: string,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    hawb?: string | null,
    status?: Status | null,
    forwarderCompanyId?: string | null,
    shipperCompanyId?: string | null,
    departure?: string | null,
    createdAt: string,
    updatedAt: string,
    reservationShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteReservationSubscriptionVariables = {
  filter?: ModelSubscriptionReservationFilterInput | null,
  owner?: string | null,
};

export type OnDeleteReservationSubscription = {
  onDeleteReservation?:  {
    __typename: "Reservation",
    id: string,
    shippingInstruction?:  {
      __typename: "ShippingInstruction",
      id: string,
      departure: string,
      arrival: string,
      airline: string,
      flightDate: string,
      directFlight: boolean,
      leadTime: number,
      useSaf: boolean,
      yardCutDate: string,
      carrierShedCutDate: string,
      itemName: string,
      packingStyle: string,
      packingStyleDetail?: string | null,
      cargoType: CargoType,
      isSds?: boolean | null,
      regulated: string,
      regulatedDetail?: string | null,
      paymentCondition: PaymentCondition,
      paymentConditionDetail?: string | null,
      taxPaymentConditions:  {
        __typename: "TaxPaymentConditions",
        realTimeAccount?: string | null,
        comprehensiveDeferredPayment?: string | null,
        cashOnDeliveryAllowed?: boolean | null,
        beforePermissionPickup?: boolean | null,
        othersOption?: string | null,
      },
      cargoInsuranceConditions?:  {
        __typename: "CargoInsuranceConditions",
        comprehensiveInsurance?: string | null,
        individualInsurance?: boolean | null,
        uninsuredWithInsurance?: boolean | null,
        othersOption?: string | null,
      } | null,
      document?:  {
        __typename: "Document",
        invoice?: boolean | null,
        packingList?: boolean | null,
        nonApplicabilityCertificate?: boolean | null,
        msds?: boolean | null,
        other?: boolean | null,
        detail?: string | null,
      } | null,
      consigneeContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      shipperContact?:  {
        __typename: "Contact",
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
      } | null,
      cargoInsurance: boolean,
      cargoInsuranceDetail?: string | null,
      packed: boolean,
      packedDetail?: string | null,
      collected: boolean,
      collectedDetail?: string | null,
      delivery: boolean,
      deliveryDetail?: string | null,
      request?: string | null,
      shipperUser:  {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      shippingItems?:  Array< {
        __typename: "ShippingItem",
        width: number,
        length: number,
        height: number,
        weight: number,
        numberOfUnit: number,
        volume: number,
        gw: number,
        volumeWeight: number,
        cw: number,
      } | null > | null,
      transportType: TransportType,
      shippingFee:  {
        __typename: "ShippingFee",
        id: string,
        price?: number | null,
        fsc?: number | null,
        minimum?: number | null,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFeeCurrencyId?: string | null,
        shippingFeeShippingInstructionId?: string | null,
        owner?: string | null,
      },
      fob?:  {
        __typename: "ShippingFobFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFobFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      fhd?:  {
        __typename: "ShippingFhdFee",
        id: string,
        customFees?:  {
          __typename: "ModelShippingCustomFeeConnection",
          items:  Array< {
            __typename: "ShippingCustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            fee: number,
            taxRate?: number | null,
            maximum?: number | null,
            minimum?: number | null,
            remark: string,
            createdAt: string,
            updatedAt: string,
            shippingFeeCustomFeesId?: string | null,
            shippingFobFeeCustomFeesId?: string | null,
            shippingFhdFeeCustomFeesId?: string | null,
            shippingCustomFeeCurrencyId: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shippingFhdFeeShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shippingInstruction?:  {
          __typename: "ShippingInstruction",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          yardCutDate: string,
          carrierShedCutDate: string,
          itemName: string,
          packingStyle: string,
          packingStyleDetail?: string | null,
          cargoType: CargoType,
          isSds?: boolean | null,
          regulated: string,
          regulatedDetail?: string | null,
          paymentCondition: PaymentCondition,
          paymentConditionDetail?: string | null,
          taxPaymentConditions:  {
            __typename: "TaxPaymentConditions",
            realTimeAccount?: string | null,
            comprehensiveDeferredPayment?: string | null,
            cashOnDeliveryAllowed?: boolean | null,
            beforePermissionPickup?: boolean | null,
            othersOption?: string | null,
          },
          cargoInsuranceConditions?:  {
            __typename: "CargoInsuranceConditions",
            comprehensiveInsurance?: string | null,
            individualInsurance?: boolean | null,
            uninsuredWithInsurance?: boolean | null,
            othersOption?: string | null,
          } | null,
          document?:  {
            __typename: "Document",
            invoice?: boolean | null,
            packingList?: boolean | null,
            nonApplicabilityCertificate?: boolean | null,
            msds?: boolean | null,
            other?: boolean | null,
            detail?: string | null,
          } | null,
          consigneeContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          shipperContact?:  {
            __typename: "Contact",
            companyName: string,
            name: string,
            email: string,
            phoneNumber: string,
          } | null,
          cargoInsurance: boolean,
          cargoInsuranceDetail?: string | null,
          packed: boolean,
          packedDetail?: string | null,
          collected: boolean,
          collectedDetail?: string | null,
          delivery: boolean,
          deliveryDetail?: string | null,
          request?: string | null,
          shipperUser:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          shippingItems?:  Array< {
            __typename: "ShippingItem",
            width: number,
            length: number,
            height: number,
            weight: number,
            numberOfUnit: number,
            volume: number,
            gw: number,
            volumeWeight: number,
            cw: number,
          } | null > | null,
          transportType: TransportType,
          shippingFee:  {
            __typename: "ShippingFee",
            id: string,
            price?: number | null,
            fsc?: number | null,
            minimum?: number | null,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFeeCurrencyId?: string | null,
            shippingFeeShippingInstructionId?: string | null,
            owner?: string | null,
          },
          fob?:  {
            __typename: "ShippingFobFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFobFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          fhd?:  {
            __typename: "ShippingFhdFee",
            id: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            shippingFhdFeeShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          reservation?:  {
            __typename: "Reservation",
            id: string,
            hawb?: string | null,
            status?: Status | null,
            forwarderCompanyId?: string | null,
            shipperCompanyId?: string | null,
            departure?: string | null,
            createdAt: string,
            updatedAt: string,
            reservationShippingInstructionId?: string | null,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shippingInstructionShipperUserId: string,
          shippingInstructionShippingFeeId: string,
          shippingInstructionFobId?: string | null,
          shippingInstructionFhdId?: string | null,
          shippingInstructionReservationId?: string | null,
          owner?: string | null,
        } | null,
        hawb?: string | null,
        status?: Status | null,
        forwarderCompanyId?: string | null,
        shipperCompanyId?: string | null,
        departure?: string | null,
        createdAt: string,
        updatedAt: string,
        reservationShippingInstructionId?: string | null,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      shippingInstructionShipperUserId: string,
      shippingInstructionShippingFeeId: string,
      shippingInstructionFobId?: string | null,
      shippingInstructionFhdId?: string | null,
      shippingInstructionReservationId?: string | null,
      owner?: string | null,
    } | null,
    hawb?: string | null,
    status?: Status | null,
    forwarderCompanyId?: string | null,
    shipperCompanyId?: string | null,
    departure?: string | null,
    createdAt: string,
    updatedAt: string,
    reservationShippingInstructionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateCurrencySubscriptionVariables = {
  filter?: ModelSubscriptionCurrencyFilterInput | null,
  owner?: string | null,
};

export type OnCreateCurrencySubscription = {
  onCreateCurrency?:  {
    __typename: "Currency",
    id: string,
    code: string,
    exchangeRateJPY: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCurrencySubscriptionVariables = {
  filter?: ModelSubscriptionCurrencyFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCurrencySubscription = {
  onUpdateCurrency?:  {
    __typename: "Currency",
    id: string,
    code: string,
    exchangeRateJPY: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCurrencySubscriptionVariables = {
  filter?: ModelSubscriptionCurrencyFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCurrencySubscription = {
  onDeleteCurrency?:  {
    __typename: "Currency",
    id: string,
    code: string,
    exchangeRateJPY: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateForwarderUserSubscriptionVariables = {
  filter?: ModelSubscriptionForwarderUserFilterInput | null,
  owner?: string | null,
};

export type OnCreateForwarderUserSubscription = {
  onCreateForwarderUser?:  {
    __typename: "ForwarderUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    workLocation: string,
    division: string,
    position?: string | null,
    companyId?: string | null,
    company?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    postedReviews?:  {
      __typename: "ModelForwarderReviewConnection",
      items:  Array< {
        __typename: "ForwarderReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderUserPostedReviewsId?: string | null,
        shipperCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    estimates?:  {
      __typename: "ModelEstimateConnection",
      items:  Array< {
        __typename: "Estimate",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        cargoType: CargoType,
        yardCutDate: string,
        carrierShedCutDate: string,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum: number,
        under45: number,
        over45: number,
        over100: number,
        over300: number,
        over500: number,
        over1000: number,
        fsc: number,
        remark?: string | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        forwarderUser:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        status: EstimateStatus,
        createdAt: string,
        updatedAt: string,
        forwarderUserEstimatesId?: string | null,
        estimateCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateForwarderUserSubscriptionVariables = {
  filter?: ModelSubscriptionForwarderUserFilterInput | null,
  owner?: string | null,
};

export type OnUpdateForwarderUserSubscription = {
  onUpdateForwarderUser?:  {
    __typename: "ForwarderUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    workLocation: string,
    division: string,
    position?: string | null,
    companyId?: string | null,
    company?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    postedReviews?:  {
      __typename: "ModelForwarderReviewConnection",
      items:  Array< {
        __typename: "ForwarderReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderUserPostedReviewsId?: string | null,
        shipperCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    estimates?:  {
      __typename: "ModelEstimateConnection",
      items:  Array< {
        __typename: "Estimate",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        cargoType: CargoType,
        yardCutDate: string,
        carrierShedCutDate: string,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum: number,
        under45: number,
        over45: number,
        over100: number,
        over300: number,
        over500: number,
        over1000: number,
        fsc: number,
        remark?: string | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        forwarderUser:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        status: EstimateStatus,
        createdAt: string,
        updatedAt: string,
        forwarderUserEstimatesId?: string | null,
        estimateCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteForwarderUserSubscriptionVariables = {
  filter?: ModelSubscriptionForwarderUserFilterInput | null,
  owner?: string | null,
};

export type OnDeleteForwarderUserSubscription = {
  onDeleteForwarderUser?:  {
    __typename: "ForwarderUser",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    workLocation: string,
    division: string,
    position?: string | null,
    companyId?: string | null,
    company?:  {
      __typename: "ForwarderCompany",
      id: string,
      name: string,
      firstName: string,
      lastName: string,
      location: string,
      establishment: string,
      paidInCaptial: number,
      corporateNumber: string,
      iataAgency: string,
      listed: boolean,
      numberOfEmployees: NumberOfEmployees,
      companyURL: string,
      manager:  {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      fobs?:  {
        __typename: "ModelFobConnection",
        items:  Array< {
          __typename: "Fob",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      fhds?:  {
        __typename: "ModelFhdConnection",
        items:  Array< {
          __typename: "Fhd",
          id: string,
          airport: string,
          forwarderCompanyId: string,
          forwarder?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          remark?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      employees?:  {
        __typename: "ModelForwarderUserConnection",
        items:  Array< {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      receivedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      forwarderCompanyManagerId: string,
      owner?: string | null,
    } | null,
    postedReviews?:  {
      __typename: "ModelForwarderReviewConnection",
      items:  Array< {
        __typename: "ForwarderReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderUserPostedReviewsId?: string | null,
        shipperCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    estimates?:  {
      __typename: "ModelEstimateConnection",
      items:  Array< {
        __typename: "Estimate",
        id: string,
        departure: string,
        arrival: string,
        airline: string,
        flightDate: string,
        directFlight: boolean,
        leadTime: number,
        useSaf: boolean,
        cargoType: CargoType,
        yardCutDate: string,
        carrierShedCutDate: string,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum: number,
        under45: number,
        over45: number,
        over100: number,
        over300: number,
        over500: number,
        over1000: number,
        fsc: number,
        remark?: string | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        forwarderUser:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        status: EstimateStatus,
        createdAt: string,
        updatedAt: string,
        forwarderUserEstimatesId?: string | null,
        estimateCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCustomFeeSubscriptionVariables = {
  filter?: ModelSubscriptionCustomFeeFilterInput | null,
  owner?: string | null,
};

export type OnCreateCustomFeeSubscription = {
  onCreateCustomFee?:  {
    __typename: "CustomFee",
    id: string,
    title: string,
    unitPrice: number,
    unitType: string,
    calculationMethod: CalculationMethod,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    minimum?: number | null,
    maximum?: number | null,
    includeTax?: boolean | null,
    taxRate?: number | null,
    remark?: string | null,
    fobId?: string | null,
    fhdId?: string | null,
    shippingFeeId?: string | null,
    estimateId?: string | null,
    createdAt: string,
    updatedAt: string,
    customFeeCurrencyId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateCustomFeeSubscriptionVariables = {
  filter?: ModelSubscriptionCustomFeeFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCustomFeeSubscription = {
  onUpdateCustomFee?:  {
    __typename: "CustomFee",
    id: string,
    title: string,
    unitPrice: number,
    unitType: string,
    calculationMethod: CalculationMethod,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    minimum?: number | null,
    maximum?: number | null,
    includeTax?: boolean | null,
    taxRate?: number | null,
    remark?: string | null,
    fobId?: string | null,
    fhdId?: string | null,
    shippingFeeId?: string | null,
    estimateId?: string | null,
    createdAt: string,
    updatedAt: string,
    customFeeCurrencyId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteCustomFeeSubscriptionVariables = {
  filter?: ModelSubscriptionCustomFeeFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCustomFeeSubscription = {
  onDeleteCustomFee?:  {
    __typename: "CustomFee",
    id: string,
    title: string,
    unitPrice: number,
    unitType: string,
    calculationMethod: CalculationMethod,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    minimum?: number | null,
    maximum?: number | null,
    includeTax?: boolean | null,
    taxRate?: number | null,
    remark?: string | null,
    fobId?: string | null,
    fhdId?: string | null,
    shippingFeeId?: string | null,
    estimateId?: string | null,
    createdAt: string,
    updatedAt: string,
    customFeeCurrencyId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateForwarderCompanySubscriptionVariables = {
  filter?: ModelSubscriptionForwarderCompanyFilterInput | null,
  owner?: string | null,
};

export type OnCreateForwarderCompanySubscription = {
  onCreateForwarderCompany?:  {
    __typename: "ForwarderCompany",
    id: string,
    name: string,
    firstName: string,
    lastName: string,
    location: string,
    establishment: string,
    paidInCaptial: number,
    corporateNumber: string,
    iataAgency: string,
    listed: boolean,
    numberOfEmployees: NumberOfEmployees,
    companyURL: string,
    manager:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    fobs?:  {
      __typename: "ModelFobConnection",
      items:  Array< {
        __typename: "Fob",
        id: string,
        airport: string,
        forwarderCompanyId: string,
        forwarder?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fhds?:  {
      __typename: "ModelFhdConnection",
      items:  Array< {
        __typename: "Fhd",
        id: string,
        airport: string,
        forwarderCompanyId: string,
        forwarder?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    employees?:  {
      __typename: "ModelForwarderUserConnection",
      items:  Array< {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    receivedReviews?:  {
      __typename: "ModelShipperReviewConnection",
      items:  Array< {
        __typename: "ShipperReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperUserPostedReviewsId?: string | null,
        forwarderCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    forwarderCompanyManagerId: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateForwarderCompanySubscriptionVariables = {
  filter?: ModelSubscriptionForwarderCompanyFilterInput | null,
  owner?: string | null,
};

export type OnUpdateForwarderCompanySubscription = {
  onUpdateForwarderCompany?:  {
    __typename: "ForwarderCompany",
    id: string,
    name: string,
    firstName: string,
    lastName: string,
    location: string,
    establishment: string,
    paidInCaptial: number,
    corporateNumber: string,
    iataAgency: string,
    listed: boolean,
    numberOfEmployees: NumberOfEmployees,
    companyURL: string,
    manager:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    fobs?:  {
      __typename: "ModelFobConnection",
      items:  Array< {
        __typename: "Fob",
        id: string,
        airport: string,
        forwarderCompanyId: string,
        forwarder?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fhds?:  {
      __typename: "ModelFhdConnection",
      items:  Array< {
        __typename: "Fhd",
        id: string,
        airport: string,
        forwarderCompanyId: string,
        forwarder?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    employees?:  {
      __typename: "ModelForwarderUserConnection",
      items:  Array< {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    receivedReviews?:  {
      __typename: "ModelShipperReviewConnection",
      items:  Array< {
        __typename: "ShipperReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperUserPostedReviewsId?: string | null,
        forwarderCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    forwarderCompanyManagerId: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteForwarderCompanySubscriptionVariables = {
  filter?: ModelSubscriptionForwarderCompanyFilterInput | null,
  owner?: string | null,
};

export type OnDeleteForwarderCompanySubscription = {
  onDeleteForwarderCompany?:  {
    __typename: "ForwarderCompany",
    id: string,
    name: string,
    firstName: string,
    lastName: string,
    location: string,
    establishment: string,
    paidInCaptial: number,
    corporateNumber: string,
    iataAgency: string,
    listed: boolean,
    numberOfEmployees: NumberOfEmployees,
    companyURL: string,
    manager:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    fobs?:  {
      __typename: "ModelFobConnection",
      items:  Array< {
        __typename: "Fob",
        id: string,
        airport: string,
        forwarderCompanyId: string,
        forwarder?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fhds?:  {
      __typename: "ModelFhdConnection",
      items:  Array< {
        __typename: "Fhd",
        id: string,
        airport: string,
        forwarderCompanyId: string,
        forwarder?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        customItems?:  {
          __typename: "ModelCustomFeeConnection",
          items:  Array< {
            __typename: "CustomFee",
            id: string,
            title: string,
            unitPrice: number,
            unitType: string,
            calculationMethod: CalculationMethod,
            minimum?: number | null,
            maximum?: number | null,
            includeTax?: boolean | null,
            taxRate?: number | null,
            remark?: string | null,
            fobId?: string | null,
            fhdId?: string | null,
            shippingFeeId?: string | null,
            estimateId?: string | null,
            createdAt: string,
            updatedAt: string,
            customFeeCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        remark?: string | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    employees?:  {
      __typename: "ModelForwarderUserConnection",
      items:  Array< {
        __typename: "ForwarderUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        companyId?: string | null,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        estimates?:  {
          __typename: "ModelEstimateConnection",
          items:  Array< {
            __typename: "Estimate",
            id: string,
            departure: string,
            arrival: string,
            airline: string,
            flightDate: string,
            directFlight: boolean,
            leadTime: number,
            useSaf: boolean,
            cargoType: CargoType,
            yardCutDate: string,
            carrierShedCutDate: string,
            minimum: number,
            under45: number,
            over45: number,
            over100: number,
            over300: number,
            over500: number,
            over1000: number,
            fsc: number,
            remark?: string | null,
            status: EstimateStatus,
            createdAt: string,
            updatedAt: string,
            forwarderUserEstimatesId?: string | null,
            estimateCurrencyId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    receivedReviews?:  {
      __typename: "ModelShipperReviewConnection",
      items:  Array< {
        __typename: "ShipperReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ForwarderCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          iataAgency: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          fobs?:  {
            __typename: "ModelFobConnection",
            nextToken?: string | null,
          } | null,
          fhds?:  {
            __typename: "ModelFhdConnection",
            nextToken?: string | null,
          } | null,
          employees?:  {
            __typename: "ModelForwarderUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperUserPostedReviewsId?: string | null,
        forwarderCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    forwarderCompanyManagerId: string,
    owner?: string | null,
  } | null,
};

export type OnCreateShipperCompanySubscriptionVariables = {
  filter?: ModelSubscriptionShipperCompanyFilterInput | null,
  owner?: string | null,
};

export type OnCreateShipperCompanySubscription = {
  onCreateShipperCompany?:  {
    __typename: "ShipperCompany",
    id: string,
    name: string,
    firstName: string,
    lastName: string,
    location: string,
    establishment: string,
    paidInCaptial: number,
    corporateNumber: string,
    category: string,
    listed: boolean,
    numberOfEmployees: NumberOfEmployees,
    companyURL: string,
    manager:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    employees?:  {
      __typename: "ModelShipperUserConnection",
      items:  Array< {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    receivedReviews?:  {
      __typename: "ModelForwarderReviewConnection",
      items:  Array< {
        __typename: "ForwarderReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderUserPostedReviewsId?: string | null,
        shipperCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shipperCompanyManagerId: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateShipperCompanySubscriptionVariables = {
  filter?: ModelSubscriptionShipperCompanyFilterInput | null,
  owner?: string | null,
};

export type OnUpdateShipperCompanySubscription = {
  onUpdateShipperCompany?:  {
    __typename: "ShipperCompany",
    id: string,
    name: string,
    firstName: string,
    lastName: string,
    location: string,
    establishment: string,
    paidInCaptial: number,
    corporateNumber: string,
    category: string,
    listed: boolean,
    numberOfEmployees: NumberOfEmployees,
    companyURL: string,
    manager:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    employees?:  {
      __typename: "ModelShipperUserConnection",
      items:  Array< {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    receivedReviews?:  {
      __typename: "ModelForwarderReviewConnection",
      items:  Array< {
        __typename: "ForwarderReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderUserPostedReviewsId?: string | null,
        shipperCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shipperCompanyManagerId: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteShipperCompanySubscriptionVariables = {
  filter?: ModelSubscriptionShipperCompanyFilterInput | null,
  owner?: string | null,
};

export type OnDeleteShipperCompanySubscription = {
  onDeleteShipperCompany?:  {
    __typename: "ShipperCompany",
    id: string,
    name: string,
    firstName: string,
    lastName: string,
    location: string,
    establishment: string,
    paidInCaptial: number,
    corporateNumber: string,
    category: string,
    listed: boolean,
    numberOfEmployees: NumberOfEmployees,
    companyURL: string,
    manager:  {
      __typename: "ShipperUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      area: string,
      role: ShipperRole,
      companyId?: string | null,
      company?:  {
        __typename: "ShipperCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        category: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ShipperUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          area: string,
          role: ShipperRole,
          companyId?: string | null,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelShipperReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        employees?:  {
          __typename: "ModelShipperUserConnection",
          items:  Array< {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelForwarderReviewConnection",
          items:  Array< {
            __typename: "ForwarderReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            forwarderUserPostedReviewsId?: string | null,
            shipperCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        shipperCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelShipperReviewConnection",
        items:  Array< {
          __typename: "ShipperReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperUserPostedReviewsId?: string | null,
          forwarderCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    employees?:  {
      __typename: "ModelShipperUserConnection",
      items:  Array< {
        __typename: "ShipperUser",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        workLocation: string,
        division: string,
        position?: string | null,
        area: string,
        role: ShipperRole,
        companyId?: string | null,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        postedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    receivedReviews?:  {
      __typename: "ModelForwarderReviewConnection",
      items:  Array< {
        __typename: "ForwarderReview",
        id: string,
        title: string,
        description: string,
        rate: number,
        company?:  {
          __typename: "ShipperCompany",
          id: string,
          name: string,
          firstName: string,
          lastName: string,
          location: string,
          establishment: string,
          paidInCaptial: number,
          corporateNumber: string,
          category: string,
          listed: boolean,
          numberOfEmployees: NumberOfEmployees,
          companyURL: string,
          manager:  {
            __typename: "ShipperUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            area: string,
            role: ShipperRole,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          employees?:  {
            __typename: "ModelShipperUserConnection",
            nextToken?: string | null,
          } | null,
          receivedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          shipperCompanyManagerId: string,
          owner?: string | null,
        } | null,
        author?:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderUserPostedReviewsId?: string | null,
        shipperCompanyReceivedReviewsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    shipperCompanyManagerId: string,
    owner?: string | null,
  } | null,
};

export type OnCreateEstimateSubscriptionVariables = {
  filter?: ModelSubscriptionEstimateFilterInput | null,
  owner?: string | null,
};

export type OnCreateEstimateSubscription = {
  onCreateEstimate?:  {
    __typename: "Estimate",
    id: string,
    departure: string,
    arrival: string,
    airline: string,
    flightDate: string,
    directFlight: boolean,
    leadTime: number,
    useSaf: boolean,
    cargoType: CargoType,
    yardCutDate: string,
    carrierShedCutDate: string,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    minimum: number,
    under45: number,
    over45: number,
    over100: number,
    over300: number,
    over500: number,
    over1000: number,
    fsc: number,
    remark?: string | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    forwarderUser:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    status: EstimateStatus,
    createdAt: string,
    updatedAt: string,
    forwarderUserEstimatesId?: string | null,
    estimateCurrencyId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateEstimateSubscriptionVariables = {
  filter?: ModelSubscriptionEstimateFilterInput | null,
  owner?: string | null,
};

export type OnUpdateEstimateSubscription = {
  onUpdateEstimate?:  {
    __typename: "Estimate",
    id: string,
    departure: string,
    arrival: string,
    airline: string,
    flightDate: string,
    directFlight: boolean,
    leadTime: number,
    useSaf: boolean,
    cargoType: CargoType,
    yardCutDate: string,
    carrierShedCutDate: string,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    minimum: number,
    under45: number,
    over45: number,
    over100: number,
    over300: number,
    over500: number,
    over1000: number,
    fsc: number,
    remark?: string | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    forwarderUser:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    status: EstimateStatus,
    createdAt: string,
    updatedAt: string,
    forwarderUserEstimatesId?: string | null,
    estimateCurrencyId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteEstimateSubscriptionVariables = {
  filter?: ModelSubscriptionEstimateFilterInput | null,
  owner?: string | null,
};

export type OnDeleteEstimateSubscription = {
  onDeleteEstimate?:  {
    __typename: "Estimate",
    id: string,
    departure: string,
    arrival: string,
    airline: string,
    flightDate: string,
    directFlight: boolean,
    leadTime: number,
    useSaf: boolean,
    cargoType: CargoType,
    yardCutDate: string,
    carrierShedCutDate: string,
    currency?:  {
      __typename: "Currency",
      id: string,
      code: string,
      exchangeRateJPY: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    minimum: number,
    under45: number,
    over45: number,
    over100: number,
    over300: number,
    over500: number,
    over1000: number,
    fsc: number,
    remark?: string | null,
    customItems?:  {
      __typename: "ModelCustomFeeConnection",
      items:  Array< {
        __typename: "CustomFee",
        id: string,
        title: string,
        unitPrice: number,
        unitType: string,
        calculationMethod: CalculationMethod,
        currency?:  {
          __typename: "Currency",
          id: string,
          code: string,
          exchangeRateJPY: number,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        minimum?: number | null,
        maximum?: number | null,
        includeTax?: boolean | null,
        taxRate?: number | null,
        remark?: string | null,
        fobId?: string | null,
        fhdId?: string | null,
        shippingFeeId?: string | null,
        estimateId?: string | null,
        createdAt: string,
        updatedAt: string,
        customFeeCurrencyId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    forwarderUser:  {
      __typename: "ForwarderUser",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      workLocation: string,
      division: string,
      position?: string | null,
      companyId?: string | null,
      company?:  {
        __typename: "ForwarderCompany",
        id: string,
        name: string,
        firstName: string,
        lastName: string,
        location: string,
        establishment: string,
        paidInCaptial: number,
        corporateNumber: string,
        iataAgency: string,
        listed: boolean,
        numberOfEmployees: NumberOfEmployees,
        companyURL: string,
        manager:  {
          __typename: "ForwarderUser",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          phoneNumber: string,
          workLocation: string,
          division: string,
          position?: string | null,
          companyId?: string | null,
          company?:  {
            __typename: "ForwarderCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            iataAgency: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            forwarderCompanyManagerId: string,
            owner?: string | null,
          } | null,
          postedReviews?:  {
            __typename: "ModelForwarderReviewConnection",
            nextToken?: string | null,
          } | null,
          estimates?:  {
            __typename: "ModelEstimateConnection",
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        fobs?:  {
          __typename: "ModelFobConnection",
          items:  Array< {
            __typename: "Fob",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        fhds?:  {
          __typename: "ModelFhdConnection",
          items:  Array< {
            __typename: "Fhd",
            id: string,
            airport: string,
            forwarderCompanyId: string,
            remark?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        employees?:  {
          __typename: "ModelForwarderUserConnection",
          items:  Array< {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        receivedReviews?:  {
          __typename: "ModelShipperReviewConnection",
          items:  Array< {
            __typename: "ShipperReview",
            id: string,
            title: string,
            description: string,
            rate: number,
            createdAt: string,
            updatedAt: string,
            shipperUserPostedReviewsId?: string | null,
            forwarderCompanyReceivedReviewsId?: string | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        forwarderCompanyManagerId: string,
        owner?: string | null,
      } | null,
      postedReviews?:  {
        __typename: "ModelForwarderReviewConnection",
        items:  Array< {
          __typename: "ForwarderReview",
          id: string,
          title: string,
          description: string,
          rate: number,
          company?:  {
            __typename: "ShipperCompany",
            id: string,
            name: string,
            firstName: string,
            lastName: string,
            location: string,
            establishment: string,
            paidInCaptial: number,
            corporateNumber: string,
            category: string,
            listed: boolean,
            numberOfEmployees: NumberOfEmployees,
            companyURL: string,
            createdAt: string,
            updatedAt: string,
            shipperCompanyManagerId: string,
            owner?: string | null,
          } | null,
          author?:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          forwarderUserPostedReviewsId?: string | null,
          shipperCompanyReceivedReviewsId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      estimates?:  {
        __typename: "ModelEstimateConnection",
        items:  Array< {
          __typename: "Estimate",
          id: string,
          departure: string,
          arrival: string,
          airline: string,
          flightDate: string,
          directFlight: boolean,
          leadTime: number,
          useSaf: boolean,
          cargoType: CargoType,
          yardCutDate: string,
          carrierShedCutDate: string,
          currency?:  {
            __typename: "Currency",
            id: string,
            code: string,
            exchangeRateJPY: number,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null,
          minimum: number,
          under45: number,
          over45: number,
          over100: number,
          over300: number,
          over500: number,
          over1000: number,
          fsc: number,
          remark?: string | null,
          customItems?:  {
            __typename: "ModelCustomFeeConnection",
            nextToken?: string | null,
          } | null,
          forwarderUser:  {
            __typename: "ForwarderUser",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            phoneNumber: string,
            workLocation: string,
            division: string,
            position?: string | null,
            companyId?: string | null,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          },
          status: EstimateStatus,
          createdAt: string,
          updatedAt: string,
          forwarderUserEstimatesId?: string | null,
          estimateCurrencyId?: string | null,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    status: EstimateStatus,
    createdAt: string,
    updatedAt: string,
    forwarderUserEstimatesId?: string | null,
    estimateCurrencyId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateNewsSubscriptionVariables = {
  filter?: ModelSubscriptionNewsFilterInput | null,
  owner?: string | null,
};

export type OnCreateNewsSubscription = {
  onCreateNews?:  {
    __typename: "News",
    id: string,
    title: string,
    newsDate: string,
    newsContent?: string | null,
    mediaUrl?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateNewsSubscriptionVariables = {
  filter?: ModelSubscriptionNewsFilterInput | null,
  owner?: string | null,
};

export type OnUpdateNewsSubscription = {
  onUpdateNews?:  {
    __typename: "News",
    id: string,
    title: string,
    newsDate: string,
    newsContent?: string | null,
    mediaUrl?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteNewsSubscriptionVariables = {
  filter?: ModelSubscriptionNewsFilterInput | null,
  owner?: string | null,
};

export type OnDeleteNewsSubscription = {
  onDeleteNews?:  {
    __typename: "News",
    id: string,
    title: string,
    newsDate: string,
    newsContent?: string | null,
    mediaUrl?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateMarketConditionsWithSafSubscriptionVariables = {
  filter?: ModelSubscriptionMarketConditionsWithSafFilterInput | null,
  owner?: string | null,
};

export type OnCreateMarketConditionsWithSafSubscription = {
  onCreateMarketConditionsWithSaf?:  {
    __typename: "MarketConditionsWithSaf",
    id: string,
    departure: string,
    arrival: string,
    flightDate: string,
    marketPrice: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateMarketConditionsWithSafSubscriptionVariables = {
  filter?: ModelSubscriptionMarketConditionsWithSafFilterInput | null,
  owner?: string | null,
};

export type OnUpdateMarketConditionsWithSafSubscription = {
  onUpdateMarketConditionsWithSaf?:  {
    __typename: "MarketConditionsWithSaf",
    id: string,
    departure: string,
    arrival: string,
    flightDate: string,
    marketPrice: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteMarketConditionsWithSafSubscriptionVariables = {
  filter?: ModelSubscriptionMarketConditionsWithSafFilterInput | null,
  owner?: string | null,
};

export type OnDeleteMarketConditionsWithSafSubscription = {
  onDeleteMarketConditionsWithSaf?:  {
    __typename: "MarketConditionsWithSaf",
    id: string,
    departure: string,
    arrival: string,
    flightDate: string,
    marketPrice: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateMarketConditionsWithoutSafSubscriptionVariables = {
  filter?: ModelSubscriptionMarketConditionsWithoutSafFilterInput | null,
  owner?: string | null,
};

export type OnCreateMarketConditionsWithoutSafSubscription = {
  onCreateMarketConditionsWithoutSaf?:  {
    __typename: "MarketConditionsWithoutSaf",
    id: string,
    departure: string,
    arrival: string,
    flightDate: string,
    marketPrice: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateMarketConditionsWithoutSafSubscriptionVariables = {
  filter?: ModelSubscriptionMarketConditionsWithoutSafFilterInput | null,
  owner?: string | null,
};

export type OnUpdateMarketConditionsWithoutSafSubscription = {
  onUpdateMarketConditionsWithoutSaf?:  {
    __typename: "MarketConditionsWithoutSaf",
    id: string,
    departure: string,
    arrival: string,
    flightDate: string,
    marketPrice: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteMarketConditionsWithoutSafSubscriptionVariables = {
  filter?: ModelSubscriptionMarketConditionsWithoutSafFilterInput | null,
  owner?: string | null,
};

export type OnDeleteMarketConditionsWithoutSafSubscription = {
  onDeleteMarketConditionsWithoutSaf?:  {
    __typename: "MarketConditionsWithoutSaf",
    id: string,
    departure: string,
    arrival: string,
    flightDate: string,
    marketPrice: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};
