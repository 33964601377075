import {Box, Button, Stack, Typography} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useLocation} from 'react-router-dom';
import {Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {EstimateDetail} from '../../components/EstimateDetail';
import {EstimateSummary} from '../../components/EstimateSummary';
import {Item} from '../../components/InputEstimateItems';
import {useEstimates} from '../../queries/estimate';
import {isNonNull} from '../../utils/isEmpty';
import 'swiper/css';
import 'swiper/css/navigation';

export function roundUpToNearestHalf(number: number): number {
  const roundedNumber = Math.ceil(number * 2) / 2;
  return Number(roundedNumber.toFixed(1));
}

export const SearchEstimateResultCompareScreen = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [selectedEstimateId, setSelectedEstimateId] = useState<null | string>(
    null,
  );
  const [totalCw, setTotalCw] = useState(0);
  const [totalGw, setTotalGw] = useState(0);
  const [unit, setUnit] = useState(0);

  const state = location.state as {
    departure: string;
    arrival: string;
    flightDate: Date;
    airlineCode: string;
    charge: string;
    searchItems: Item[];
  };

  const {data: estimates, isLoading} = useEstimates({
    departure: state.departure,
    arrival: state.arrival,
    startDate: state.flightDate,
    endDate: state.flightDate,
    airlineCode: state.airlineCode,
  });

  const handleShowDetail = useCallback(() => {
    setIsDetailOpen(true);
  }, []);

  const handleBackSubmit = useCallback(() => {
    navigate('/searchEstimate/results', {
      state: {
        departure: state.departure,
        arrival: state.arrival,
        charge: state.charge,
        items: state.searchItems,
      },
    });
  }, [
    navigate,
    state.arrival,
    state.charge,
    state.departure,
    state.searchItems,
  ]);

  useEffect(() => {
    // 金額計算のため貨物のcw合計値,gw合計値,個数合計を計算
    const totalVolumWeight = state.searchItems.reduce((sum, currentItem) => {
      return sum + (currentItem.volumeWeight || 0);
    }, 0);
    const totalGw = state.searchItems.reduce((sum, currentItem) => {
      return sum + (currentItem.gw || 0);
    }, 0);
    const totalCw = roundUpToNearestHalf(Math.max(totalGw, totalVolumWeight));
    const unit = state.searchItems.reduce((sum, currentItem) => {
      return sum + (currentItem.numberOfUnit || 0);
    }, 0);
    setTotalCw(totalCw);
    setTotalGw(totalGw);
    setUnit(unit);
  }, [state.searchItems]);

  useEffect(() => {
    document.title = `${t('quotationsList')} | MAST`;
  }, [t]);

  return (
    <>
      <Stack
        alignItems="center"
        flexDirection="column"
        sx={{
          paddingBottom: '120px',
        }}>
        <Stack justifyContent="start" width="1200px">
          <Stack flexDirection="row" mb={3} justifyContent="space-between">
            <Stack direction="row" alignItems="flex-end" spacing={3}>
              <Typography variant="h2">{t('searchQuotation')}</Typography>
              <Typography variant="h4">
                {estimates?.items.length}
                {t('found')}
              </Typography>
            </Stack>
            <Button
              variant="primary"
              type="submit"
              size="large"
              onClick={handleBackSubmit}>
              {t('returnToSearch')}
            </Button>
          </Stack>
          {(!isLoading ?? (!estimates || estimates?.items.length === 0)) && (
            <Box sx={{width: '100%', position: 'relative'}}>
              <Swiper
                spaceBetween={20}
                slidesPerView={3.2}
                modules={[Navigation]}
                navigation={{
                  prevEl: '#buttonPrev',
                  nextEl: '#buttonNext',
                }}>
                {estimates?.items.filter(isNonNull).map((estimate, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <EstimateSummary
                        estimateId={estimate.id}
                        totalCw={totalCw}
                        searchItems={state.searchItems}
                        totalGw={totalGw}
                        unit={unit}
                        charge={state.charge}
                        setIsDetailOpen={setIsDetailOpen}
                        showDetail={handleShowDetail}
                        setSelectedEstimateId={setSelectedEstimateId}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <div
                id="buttonPrev"
                style={{
                  position: 'absolute',
                  top: '100px',
                  left: '-120px',
                  cursor: 'pointer',
                }}>
                <svg
                  width="68"
                  height="68"
                  viewBox="0 0 68 68"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle cx="34" cy="34" r="34" fill="white" />
                  <path
                    d="M42 17L25 34L42 51"
                    stroke="#3F6CDF"
                    strokeWidth="6"
                  />
                </svg>
              </div>
              <div
                id="buttonNext"
                style={{
                  position: 'absolute',
                  top: '100px',
                  right: '-120px',
                  cursor: 'pointer',
                }}>
                <svg
                  width="68"
                  height="68"
                  viewBox="0 0 68 68"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle
                    cx="34"
                    cy="34"
                    r="34"
                    transform="rotate(-180 34 34)"
                    fill="white"
                  />
                  <path
                    d="M26 51L43 34L26 17"
                    stroke="#3F6CDF"
                    strokeWidth="6"
                  />
                </svg>
              </div>
            </Box>
          )}
        </Stack>
      </Stack>
      <EstimateDetail
        isDetailOpen={isDetailOpen}
        selectedEstimateId={selectedEstimateId}
        totalCw={totalCw}
        totalGw={totalGw}
        unit={unit}
        charge={state.charge}
        setIsDetailOpen={setIsDetailOpen}
      />
    </>
  );
};
