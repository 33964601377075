import {PaymentCondition} from '../API';
import i18n from '../i18n/i18n';

export const getLocalizedPaymentCondition = (
  paymentCondition: PaymentCondition,
) => {
  return {
    [PaymentCondition.CREDIT_REGISTERED_WITH_HISTORY]: () =>
      i18n.t('transactions'),
    [PaymentCondition.EOM_NEXT_MONTH_MAX_60]: () => i18n.t('sixtyDays'),
    [PaymentCondition.MID_MONTH_EOM_MAX_30]: () => i18n.t('thirtyDays'),
    [PaymentCondition.CASH_ON_DELIVERY]: () => i18n.t('cod'),
    [PaymentCondition.PAYMENT_DEADLINE_10BD]: () => i18n.t(''),
    [PaymentCondition.PAYMENT_DEADLINE_5BD]: () => i18n.t(''),
  }[paymentCondition]();
};
