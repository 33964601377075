import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation} from 'react-query';
import {
  CreateForwarderReviewMutation,
  CreateForwarderReviewMutationVariables,
  CreateShipperReviewMutation,
  CreateShipperReviewMutationVariables,
} from '../API';
import {createShipperReview, createForwarderReview} from '../graphql/mutations';

export const useCreateShipperReviewMutation = () => {
  return useMutation(
    async (variables: CreateShipperReviewMutationVariables) => {
      const review = (await API.graphql(
        graphqlOperation(createShipperReview, variables),
      )) as GraphQLResult<CreateShipperReviewMutation>;
      return review.data?.createShipperReview;
    },
  );
};

export const useCreateForwarderReviewMutation = () => {
  return useMutation(
    async (variables: CreateForwarderReviewMutationVariables) => {
      const review = (await API.graphql(
        graphqlOperation(createForwarderReview, variables),
      )) as GraphQLResult<CreateForwarderReviewMutation>;
      return review.data?.createForwarderReview;
    },
  );
};
