import {Stack, Chip} from '@mui/material';
import 'chart.js/auto';
import React, {useState, useCallback} from 'react';
import {Chart} from './Chart';

export const MarketConditionsChart = () => {
  const arrivals = ['LAX', 'ORD', 'HKG', 'PVG', 'FRA', 'AMS'];
  const [selectedArrival, setSelectedArrival] = useState('LAX');

  const handleSelect = useCallback((arrival: string) => {
    setSelectedArrival(arrival);
  }, []);

  return (
    <Stack alignItems="center" spacing={6}>
      <Stack
        flexDirection="row"
        sx={{
          flexWrap: 'wrap',
        }}>
        {arrivals.map(arrival => (
          <Chip
            key={arrival}
            label={`NRT-${arrival}`}
            onClick={() => handleSelect(arrival)}
            sx={{
              backgroundColor:
                selectedArrival === arrival ? '#D9D9D9' : 'white',
              color: selectedArrival === arrival ? '#2196F3' : '#494747',
              marginRight: '8px',
              '& .MuiChip-label': {
                paddingX: '8px',
              },
            }}
          />
        ))}
      </Stack>
      <Chart arrival={selectedArrival} />
    </Stack>
  );
};
