import styled from '@emotion/styled';

export const StyledTable = styled.table`
  padding-top: 40px;
  border-collapse: collapse;
  border-spacing: 0;
  tr {
    border-bottom: 1px solid #d9d9d9;
    &:first-child {
      th {
        font-weight: normal;
        padding: 10px 0px 10px 8px;
      }
    }
  }
  th {
    padding: 4px 10px;
    font-size: 13px;
    text-align: left;
    font-weight: bold;
    border-bottom: 1px solid #d9d9d9;
  }
  td {
    width: 10%;
    padding: 8px 10px;
    font-size: 13px;
    border-bottom: 1px solid #d9d9d9;
    &:last-child {
      width: 5%;
    }
  }
`;
