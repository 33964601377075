import {ShipperRole} from '../API';

const RoleOptions: ReadonlyArray<{
  readonly id: number;
  readonly name: string;
  readonly value: string;
}> = [
  {id: 1, name: '航空輸出', value: ShipperRole.AIR_EXPORT},
  {id: 2, name: '海上輸出', value: ShipperRole.SEA_EXPORT},
  {id: 3, name: '海上輸入', value: ShipperRole.SEA_IMPORT},
  {id: 4, name: '倉庫', value: ShipperRole.WAREHOUSE},
  {id: 5, name: '航空輸入', value: ShipperRole.AIR_IMPORT},
  {id: 6, name: 'トラック', value: ShipperRole.TRUCK},
  {id: 7, name: 'その他', value: ShipperRole.OTHER},
] as const;

export default RoleOptions;
