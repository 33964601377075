import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery} from 'react-query';
import {
  ListShippingFhdFeesQueryVariables,
  ListShippingFhdFeesQuery,
  GetShippingFhdFeeQueryVariables,
  GetShippingFhdFeeQuery,
  CreateShippingFhdFeeMutationVariables,
  CreateShippingFhdFeeMutation,
  UpdateShippingFhdFeeMutationVariables,
  UpdateShippingFhdFeeMutation,
  DeleteShippingFhdFeeMutationVariables,
  DeleteShippingFhdFeeMutation,
} from '../API';
import {
  createShippingFhdFee,
  deleteShippingFhdFee,
  updateShippingFhdFee,
} from '../graphql/mutations';
import {getShippingFhdFee, listShippingFhdFees} from '../graphql/queries';

export const useShippingFhdFees = () => {
  return useQuery(['shippingFhdFees'], async () => {
    const variables: ListShippingFhdFeesQueryVariables = {
      limit: 10000,
    };
    const result = (await API.graphql(
      graphqlOperation(listShippingFhdFees, variables),
    )) as GraphQLResult<ListShippingFhdFeesQuery>;
    return result?.data?.listShippingFhdFees;
  });
};

export const useShippingFhdFee = (id: string) => {
  return useQuery(
    ['shippingFhdFee', id],
    async () => {
      const variable: GetShippingFhdFeeQueryVariables = {
        id,
      };
      const result = (await API.graphql(
        graphqlOperation(getShippingFhdFee, variable),
      )) as GraphQLResult<GetShippingFhdFeeQuery>;
      return result.data?.getShippingFhdFee;
    },
    {
      enabled: !!id,
    },
  );
};

export const useCreateShippingFhdFeeMutation = () => {
  return useMutation(
    async (variables: CreateShippingFhdFeeMutationVariables) => {
      const shippingFhdFee = (await API.graphql(
        graphqlOperation(createShippingFhdFee, variables),
      )) as GraphQLResult<CreateShippingFhdFeeMutation>;
      return shippingFhdFee.data?.createShippingFhdFee;
    },
  );
};

export const useUpdateShippingFhdFeeMutation = () => {
  return useMutation(
    async (variables: UpdateShippingFhdFeeMutationVariables) => {
      const shippingFhdFee = (await API.graphql(
        graphqlOperation(updateShippingFhdFee, variables),
      )) as GraphQLResult<UpdateShippingFhdFeeMutation>;
      return shippingFhdFee.data?.updateShippingFhdFee;
    },
  );
};

export const useDeleteShippingFhdFeeMutation = () => {
  return useMutation(
    async (variables: DeleteShippingFhdFeeMutationVariables) => {
      const shippingFhdFee = (await API.graphql(
        graphqlOperation(deleteShippingFhdFee, variables),
      )) as GraphQLResult<DeleteShippingFhdFeeMutation>;
      return shippingFhdFee.data?.deleteShippingFhdFee;
    },
  );
};
