import {zodResolver} from '@hookform/resolvers/zod';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import React, {useEffect, useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {z} from 'zod';
import RoleOptions from '../../data/roleOptions';
import {useOAuthUserState} from '../../providers/AuthProvider';
import {useCreateForwarderUserMutation} from '../../queries/forwarderUser';
import {
  useInvitedUser,
  useDeleteInvitedUserMutation,
} from '../../queries/invitedUser';
import {useCreateShipperUserMutation} from '../../queries/shipperUser';

export const formInput = z.object({
  workLocation: z.string().min(1),
  division: z.string().min(1),
  position: z.any(),
  phoneNumber: z.string().min(1),
  role: z.any(),
});

export type FormInput = z.infer<typeof formInput>;

export const RegisterInvitedUserScreen = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const userState = useOAuthUserState();
  if (userState.state === 'loading') {
    return null;
  }
  const sub = userState.user?.attributes.sub ?? '';
  const email = userState.user?.attributes.email ?? '';

  const {data: invitedUser} = useInvitedUser(email);
  const {mutate: createShipperUserMutation} = useCreateShipperUserMutation();
  const {mutate: createForwarderUserMutation} =
    useCreateForwarderUserMutation();
  const {mutate: deleteInvitedUserMutation} = useDeleteInvitedUserMutation();

  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<FormInput>({
    mode: 'all',
    criteriaMode: 'all',
    shouldFocusError: false,
    resolver: zodResolver(formInput),
  });
  const [area, setArea] = useState('');

  const [termsAcception, setTermsAcception] = useState(false);

  const isForwarder = invitedUser?.isForwarder;
  const firstName = invitedUser?.firstName ?? '';
  const lastName = invitedUser?.lastName ?? '';
  const onSubmit: SubmitHandler<FormInput> = async data => {
    const companyId = invitedUser?.companyId ?? '';
    if (isForwarder) {
      createForwarderUserMutation(
        {
          input: {
            id: sub,
            email: email,
            firstName: firstName,
            lastName: lastName,
            companyId: companyId,
            workLocation: data.workLocation,
            position: data.position,
            division: data.division,
            phoneNumber: data.phoneNumber,
          },
        },
        {
          onSuccess: user => {
            if (user) {
              deleteInvitedUserMutation(
                {
                  input: {
                    email: user.email,
                  },
                },
                {
                  onSuccess: () => {
                    navigate(0);
                  },
                  onError: async err => {
                    Sentry.captureException(err);
                    return;
                  },
                },
              );
            }
          },
          onError: async err => {
            Sentry.captureException(err);
            return;
          },
        },
      );
    } else {
      createShipperUserMutation(
        {
          input: {
            id: sub,
            email: email,
            firstName: firstName,
            lastName: lastName,
            companyId: companyId,
            workLocation: data.workLocation,
            position: data.position,
            division: data.division,
            phoneNumber: data.phoneNumber,
            area: area,
            role: data.role,
          },
        },
        {
          onSuccess: user => {
            if (user?.email) {
              deleteInvitedUserMutation(
                {
                  input: {
                    email: user.email,
                  },
                },
                {
                  onSuccess: () => {
                    navigate(0);
                  },
                  onError: async err => {
                    Sentry.captureException(err);
                    return;
                  },
                },
              );
            }
          },
          onError: async err => {
            Sentry.captureException(err);
            return;
          },
        },
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const {getNames} = require('country-list');
  const countries: string[] = getNames();

  useEffect(() => {
    document.title = `${t('createAccount')} | MAST`;
  }, [t]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        background: '#fafafa',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '452px',
          px: '53px',
          py: '53px',
        }}>
        <Typography mb={5} variant="h1">
          MAST
        </Typography>
        <Typography align="center">
          {t('welcome')}
          {t('lastAndFirstName', {lastName, firstName})} <br />
          {t('provideAccount')}
        </Typography>
        <Stack
          direction="column"
          justifyContent="center"
          sx={{mt: '24px'}}
          spacing={2}>
          <Stack direction="column" spacing={1}>
            <Stack
              direction="row"
              spacing={2}
              alignContent="center"
              sx={{
                width: 'calc(652px * 0.3)',
                flexWrap: 'wrap',
              }}>
              <Typography sx={{fontWeight: '500'}}>
                {t('phoneNumber')}
              </Typography>
              <Typography
                color="red"
                sx={{ml: '12px', fontWeight: '500', fontSize: '14px'}}>
                {t('requiredField')}
              </Typography>
            </Stack>
            <Stack direction="column">
              <TextField
                fullWidth
                sx={{
                  backgroundColor: '#fff',
                }}
                error={!!errors.phoneNumber}
                {...register('phoneNumber', {
                  required: true,
                })}
              />
              {errors.phoneNumber && (
                <Typography variant="error">{t('enterPhoneNumber')}</Typography>
              )}
            </Stack>
          </Stack>
          <Stack direction="column" spacing={1}>
            <Stack
              direction="row"
              spacing={2}
              alignContent="center"
              sx={{
                width: 'calc(652px * 0.3)',
                flexWrap: 'wrap',
              }}>
              <Typography>{t('workplaceAddress')}</Typography>
              <Typography
                color="red"
                sx={{ml: '12px', fontWeight: '500', fontSize: '14px'}}>
                {t('requiredField')}
              </Typography>
            </Stack>
            <Stack direction="column">
              <TextField
                fullWidth
                sx={{
                  backgroundColor: '#fff',
                }}
                error={!!errors.workLocation}
                {...register('workLocation', {
                  required: true,
                })}
              />
              {errors.workLocation && (
                <Typography variant="error">
                  {t('enterWorkplaceAddress')}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Stack direction="column" spacing={1}>
            <Stack
              direction="row"
              spacing={2}
              alignContent="center"
              sx={{
                width: 'calc(652px * 0.3)',
                flexWrap: 'wrap',
              }}>
              <Typography sx={{fontWeight: '500'}}>
                {t('department')}
              </Typography>
              <Typography
                color="red"
                sx={{ml: '12px', fontWeight: '500', fontSize: '14px'}}>
                {t('requiredField')}
              </Typography>
            </Stack>
            <Stack direction="column">
              <TextField
                fullWidth
                sx={{
                  backgroundColor: '#fff',
                }}
                error={!!errors.division}
                {...register('division', {
                  required: true,
                })}
              />
              {errors.division && (
                <Typography variant="error">{t('enterDepartment')}</Typography>
              )}
            </Stack>
          </Stack>
          <Stack direction="column" spacing={1}>
            <Stack
              direction="row"
              spacing={2}
              alignContent="center"
              sx={{
                width: 'calc(652px * 0.3)',
                flexWrap: 'wrap',
              }}>
              <Typography sx={{fontWeight: '500'}}>
                {t('jobPosition')}
              </Typography>
            </Stack>
            <Stack direction="column">
              <TextField
                fullWidth
                sx={{
                  backgroundColor: '#fff',
                }}
                error={!!errors.position}
                {...register('position', {
                  required: true,
                })}
              />
              {errors.position && (
                <Typography variant="error">{t('enterJobPosition')}</Typography>
              )}
            </Stack>
          </Stack>
          {!isForwarder && (
            <>
              <Stack direction="column" spacing={1}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignContent="center"
                  sx={{
                    width: 'calc(652px * 0.3)',
                    flexWrap: 'wrap',
                  }}>
                  <Typography sx={{fontWeight: '500'}}>
                    {t('mainAreas')}
                  </Typography>
                  <Typography
                    color="red"
                    sx={{ml: '12px', fontWeight: '500', fontSize: '14px'}}>
                    {t('requiredField')}
                  </Typography>
                </Stack>
                <Autocomplete
                  value={area}
                  onChange={(_, newValue) => {
                    setArea(newValue ? newValue : 'Japan');
                  }}
                  sx={{width: '452px', backgroundColor: '#fff'}}
                  options={countries}
                  getOptionLabel={country => country}
                  size="small"
                  renderInput={params => <TextField {...params} />}
                />
              </Stack>
              <Stack direction="column" spacing={1}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignContent="center"
                  sx={{
                    width: 'calc(652px * 0.3)',
                    flexWrap: 'wrap',
                  }}>
                  <Typography sx={{fontWeight: '500'}}>
                    {t('mainResponsibilities')}
                  </Typography>
                  <Typography
                    color="red"
                    sx={{ml: '12px', fontWeight: '500', fontSize: '14px'}}>
                    {t('requiredField')}
                  </Typography>
                </Stack>
                <Stack direction="column">
                  <Controller
                    name="role"
                    control={control}
                    render={({field}) => (
                      <>
                        <Select
                          labelId="area-label"
                          {...field}
                          size="small"
                          sx={{
                            backgroundColor: '#fff',
                            paddingTop: '2.5px',
                            paddingBottom: '2.5px',
                          }}>
                          {RoleOptions.map(option => (
                            <MenuItem key={option.id} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.role && (
                          <Typography variant="error">
                            {t('enterMainResponsibilities')}
                          </Typography>
                        )}
                      </>
                    )}
                  />
                  {errors.role && (
                    <Typography variant="error">
                      {t('enterMainResponsibilities')}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </>
          )}
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{mt: '32px'}}>
            <Checkbox
              checked={termsAcception}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setTermsAcception(event.target.checked);
              }}
            />
            <Trans
              i18nKey="agreement"
              components={{
                link_terms: (
                  <Link
                    sx={{ml: '8px'}}
                    href="https://www.notion.so/cozmic/3f158b6aed084d5880574720c4f5829b?pvs=4"
                    underline="hover"
                    target="_blank"></Link>
                ),
                link_privacy: (
                  <Link
                    href="https://www.notion.so/cozmic/de2c8de607bf471285cdf0f1d46627be?pvs=4"
                    underline="hover"
                    target="_blank"></Link>
                ),
              }}
            />
          </Stack>
          <Stack alignItems="center" justifyContent="center" sx={{mt: '24px'}}>
            <Button
              variant="primary"
              type="submit"
              size="large"
              disabled={
                !!errors.workLocation ||
                !!errors.division ||
                !!errors.phoneNumber ||
                !termsAcception
              }
              onClick={handleSubmit(onSubmit)}>
              {t('register')}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};
