import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {
  CreateShipperUserMutation,
  CreateShipperUserMutationVariables,
  DeleteShipperUserMutation,
  DeleteShipperUserMutationVariables,
  GetShipperUserQuery,
  GetShipperUserQueryVariables,
  ListShipperUsersQuery,
  ListShipperUsersQueryVariables,
  UpdateShipperUserMutation,
  UpdateShipperUserMutationVariables,
  ShipperUsersByCompanyIdQuery,
  ShipperUsersByCompanyIdQueryVariables,
} from '../API';
import {
  createShipperUser,
  deleteShipperUser,
  updateShipperUser,
} from '../graphql/mutations';
import {
  getShipperUser,
  listShipperUsers,
  shipperUsersByCompanyId,
} from '../graphql/queries';

export const useShipperUsers = () => {
  return useQuery(['shipperUsers'], async () => {
    const variables: ListShipperUsersQueryVariables = {
      limit: 10000,
    };
    const result = (await API.graphql(
      graphqlOperation(listShipperUsers, variables),
    )) as GraphQLResult<ListShipperUsersQuery>;
    return result?.data?.listShipperUsers;
  });
};

export const useShipperUser = (userId?: string) => {
  return useQuery(
    ['shipperUser', userId],
    async () => {
      if (!userId) return;
      const variable: GetShipperUserQueryVariables = {
        id: userId,
      };
      const result = (await API.graphql(
        graphqlOperation(getShipperUser, variable),
      )) as GraphQLResult<GetShipperUserQuery>;
      return result.data?.getShipperUser;
    },
    {
      enabled: !!userId,
    },
  );
};

export const useCreateShipperUserMutation = () => {
  return useMutation(async (variables: CreateShipperUserMutationVariables) => {
    const user = (await API.graphql(
      graphqlOperation(createShipperUser, variables),
    )) as GraphQLResult<CreateShipperUserMutation>;
    return user.data?.createShipperUser;
  });
};

export const useUpdateShipperUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (variables: UpdateShipperUserMutationVariables) => {
      const user = (await API.graphql(
        graphqlOperation(updateShipperUser, variables),
      )) as GraphQLResult<UpdateShipperUserMutation>;
      return user.data?.updateShipperUser;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
    },
  );
};

export const useDeleteShipperUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (variables: DeleteShipperUserMutationVariables) => {
      const user = (await API.graphql(
        graphqlOperation(deleteShipperUser, variables),
      )) as GraphQLResult<DeleteShipperUserMutation>;
      return user.data?.deleteShipperUser;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
    },
  );
};

export const useShipperUsersByCompanyId = (companyId: string) => {
  return useQuery(
    ['shipperUsersByCompanyId', companyId],
    async () => {
      const variables: ShipperUsersByCompanyIdQueryVariables = {
        companyId: companyId,
        limit: 10000,
      };
      const result = (await API.graphql(
        graphqlOperation(shipperUsersByCompanyId, variables),
      )) as GraphQLResult<ShipperUsersByCompanyIdQuery>;
      return result?.data?.shipperUsersByCompanyId;
    },
    {
      enabled: true,
    },
  );
};
