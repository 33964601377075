import {Stack, Typography, Box} from '@mui/material';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ShipperRole} from '../../API';
import {InviteUserModal} from '../../components/InviteUserModal';
import {UserList} from '../../components/UserList';
import {useOAuthUserState} from '../../providers/AuthProvider';
import {useForwarderCompany} from '../../queries/forwarderCompany';
import {
  useForwarderUser,
  useForwarderUsersByCompanyId,
} from '../../queries/forwarderUser';
import {useInvitedUsersByCompanyId} from '../../queries/invitedUser';
import {useShipperCompany} from '../../queries/shipperCompany';
import {
  useShipperUser,
  useShipperUsersByCompanyId,
} from '../../queries/shipperUser';
import {isNonNull} from '../../utils/isEmpty';

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  companyId: string;
  phoneNumber?: string;
  workLocation?: string;
  division?: string;
  position?: string;
  area?: string;
  role?: ShipperRole;
  status?: string;
  createdAt: string;
}

export const EmployeesScreen = () => {
  const {t} = useTranslation();
  const [users, setUsers] = useState<User[]>([]);

  const userState = useOAuthUserState();
  const sub = userState.user?.attributes.sub ?? '';
  const isForwarders = userState.user?.isForwarders ?? false;
  const {data: forwarderUser} = useForwarderUser(sub);
  const forwarderUsers = useForwarderUsersByCompanyId(
    forwarderUser?.company?.id ?? '',
  ).data;
  const {data: shipperUser} = useShipperUser(sub);
  const shipperUsers = useShipperUsersByCompanyId(
    shipperUser?.company?.id ?? '',
  ).data;
  const invitedUsers = useInvitedUsersByCompanyId(
    isForwarders ? forwarderUser?.company?.id : shipperUser?.company?.id,
  ).data;

  useEffect(() => {
    const usersWithStatus = isForwarders
      ? forwarderUsers?.items.filter(isNonNull).map(user => {
          return {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            createdAt: user.createdAt,
            companyId: user.companyId,
            status: 'active',
          } as User;
        })
      : shipperUsers?.items.filter(isNonNull).map((user): User => {
          return {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            createdAt: user.createdAt,
            companyId: user.companyId,
            status: 'active',
          } as User;
        });
    const invitedUsersWithStatus = invitedUsers?.items
      .filter(isNonNull)
      .map(user => {
        return {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          createdAt: user.createdAt,
          companyId: user.companyId,
          status: 'invited',
        } as User;
      });
    const mergedUsers = (usersWithStatus || []).concat(
      invitedUsersWithStatus || [],
    );
    mergedUsers.sort((a, b) => {
      const dateA = a && a.createdAt ? new Date(a.createdAt) : null;
      const dateB = b && b.createdAt ? new Date(b.createdAt) : null;
      if (dateA === null || dateB === null) {
        return 0;
      }
      return dateA.getTime() - dateB.getTime();
    });
    setUsers(mergedUsers);
  }, [forwarderUsers, shipperUsers, invitedUsers, isForwarders]);

  useEffect(() => {
    document.title = `${t('employeeManagement')} | MAST`;
  }, [t]);

  return (
    <>
      <Stack
        alignItems="center"
        flexDirection="column"
        sx={{
          paddingBottom: '120px',
        }}>
        <Stack justifyContent="start" width="1120px">
          <Stack flexDirection="row" mb={3} justifyContent="space-between">
            <Typography variant="h2">{t('employeeManagement')}</Typography>
            {isForwarders ? (
              <InviteUserModal
                userMutation={useForwarderUser}
                companyMutation={useForwarderCompany}></InviteUserModal>
            ) : (
              <InviteUserModal
                userMutation={useShipperUser}
                companyMutation={useShipperCompany}></InviteUserModal>
            )}
          </Stack>
          <Box
            sx={{
              borderRadius: '12px',
              boxShadow: '0 2px 18px rgba(204, 204, 204, 0.3)',
              backgroundColor: '#fff',
              padding: '40px',
            }}>
            <UserList data={users} isForwarders={isForwarders} />
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
