import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {
  CreateForwarderUserMutation,
  CreateForwarderUserMutationVariables,
  DeleteForwarderUserMutation,
  DeleteForwarderUserMutationVariables,
  GetForwarderUserQuery,
  GetForwarderUserQueryVariables,
  ListForwarderUsersQuery,
  ListForwarderUsersQueryVariables,
  UpdateForwarderUserMutation,
  UpdateForwarderUserMutationVariables,
  ForwarderUsersByCompanyIdQuery,
  ForwarderUsersByCompanyIdQueryVariables,
} from '../API';
import {
  createForwarderUser,
  deleteForwarderUser,
  updateForwarderUser,
} from '../graphql/mutations';
import {
  forwarderUsersByCompanyId,
  getForwarderUser,
  listForwarderUsers,
} from '../graphql/queries';

export const useForwarderUsers = () => {
  return useQuery(['forwarderUsers'], async () => {
    const variables: ListForwarderUsersQueryVariables = {
      limit: 10000,
    };
    const result = (await API.graphql(
      graphqlOperation(listForwarderUsers, variables),
    )) as GraphQLResult<ListForwarderUsersQuery>;
    return result?.data?.listForwarderUsers;
  });
};

export const useForwarderUser = (userId?: string) => {
  return useQuery(
    ['forwarderUser', userId],
    async () => {
      if (!userId) return;
      const variable: GetForwarderUserQueryVariables = {
        id: userId,
      };
      const result = (await API.graphql(
        graphqlOperation(getForwarderUser, variable),
      )) as GraphQLResult<GetForwarderUserQuery>;
      return result.data?.getForwarderUser;
    },
    {
      enabled: !!userId,
    },
  );
};

export const useCreateForwarderUserMutation = () => {
  return useMutation(
    async (variables: CreateForwarderUserMutationVariables) => {
      const user = (await API.graphql(
        graphqlOperation(createForwarderUser, variables),
      )) as GraphQLResult<CreateForwarderUserMutation>;
      return user.data?.createForwarderUser;
    },
  );
};

export const useUpdateForwarderUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (variables: UpdateForwarderUserMutationVariables) => {
      const user = (await API.graphql(
        graphqlOperation(updateForwarderUser, variables),
      )) as GraphQLResult<UpdateForwarderUserMutation>;
      return user.data?.updateForwarderUser;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
    },
  );
};

export const useDeleteForwarderUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (variables: DeleteForwarderUserMutationVariables) => {
      const user = (await API.graphql(
        graphqlOperation(deleteForwarderUser, variables),
      )) as GraphQLResult<DeleteForwarderUserMutation>;
      return user.data?.deleteForwarderUser;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
    },
  );
};

export const useForwarderUsersByCompanyId = (companyId: string) => {
  return useQuery(
    ['forwarderUsersByCompanyId', companyId],
    async () => {
      const variables: ForwarderUsersByCompanyIdQueryVariables = {
        companyId: companyId,
        limit: 10000,
      };
      const result = (await API.graphql(
        graphqlOperation(forwarderUsersByCompanyId, variables),
      )) as GraphQLResult<ForwarderUsersByCompanyIdQuery>;
      return result?.data?.forwarderUsersByCompanyId;
    },
    {
      enabled: true,
    },
  );
};
