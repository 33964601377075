import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {
  CreateShipperCompanyMutation,
  CreateShipperCompanyMutationVariables,
  DeleteShipperCompanyMutation,
  DeleteShipperCompanyMutationVariables,
  GetShipperCompanyQuery,
  GetShipperCompanyQueryVariables,
  ListShipperCompaniesQuery,
  ListShipperCompaniesQueryVariables,
  UpdateShipperCompanyMutation,
  UpdateShipperCompanyMutationVariables,
} from '../API';
import {
  createShipperCompany,
  deleteShipperCompany,
  updateShipperCompany,
} from '../graphql/mutations';
import {getShipperCompany, listShipperCompanies} from '../graphql/queries';

export const useShipperCompanies = () => {
  return useQuery(['shipperCompanies'], async () => {
    const variables: ListShipperCompaniesQueryVariables = {
      limit: 10000,
    };
    const result = (await API.graphql(
      graphqlOperation(listShipperCompanies, variables),
    )) as GraphQLResult<ListShipperCompaniesQuery>;
    return result?.data?.listShipperCompanies;
  });
};

export const useShipperCompany = (userId?: string) => {
  return useQuery(
    ['shipperCompany', userId],
    async () => {
      if (!userId) return;
      const variable: GetShipperCompanyQueryVariables = {
        id: userId,
      };
      const result = (await API.graphql(
        graphqlOperation(getShipperCompany, variable),
      )) as GraphQLResult<GetShipperCompanyQuery>;
      return result.data?.getShipperCompany;
    },
    {
      enabled: !!userId,
    },
  );
};

export const useCreateShipperCompanyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (variables: CreateShipperCompanyMutationVariables) => {
      const user = (await API.graphql(
        graphqlOperation(createShipperCompany, variables),
      )) as GraphQLResult<CreateShipperCompanyMutation>;
      return user.data?.createShipperCompany;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
    },
  );
};

export const useUpdateShipperCompanyMutation = () => {
  return useMutation(
    async (variables: UpdateShipperCompanyMutationVariables) => {
      const user = (await API.graphql(
        graphqlOperation(updateShipperCompany, variables),
      )) as GraphQLResult<UpdateShipperCompanyMutation>;
      return user.data?.updateShipperCompany;
    },
  );
};

export const useDeleteShipperCompanyMutation = () => {
  return useMutation(
    async (variables: DeleteShipperCompanyMutationVariables) => {
      const user = (await API.graphql(
        graphqlOperation(deleteShipperCompany, variables),
      )) as GraphQLResult<DeleteShipperCompanyMutation>;
      return user.data?.deleteShipperCompany;
    },
  );
};
