import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {CompanyProfile} from './CompanyProfile';
import {useShipperCompany} from '../../queries/shipperCompany';
import {isNonNull} from '../../utils/isEmpty';

export const ShipperCompanyProfileScreen = () => {
  const location = useLocation();
  const state = location.state as {
    companyId: string;
  };
  const {data: shipperCompany} = useShipperCompany(state.companyId);
  const [averageRate, setAverateRate] = useState(0);

  useEffect(() => {
    const items = shipperCompany?.receivedReviews?.items ?? [];
    let totalRate = 0;
    items.forEach(item => {
      if (item) {
        totalRate += item.rate;
      }
    });
    const averageRate = items.length > 0 ? totalRate / items.length : 0;
    setAverateRate(averageRate);
  }, [shipperCompany]);

  if (!shipperCompany) return <></>;
  return (
    <>
      <CompanyProfile
        name={shipperCompany.name}
        averageRate={averageRate}
        firstName={shipperCompany.firstName}
        lastName={shipperCompany.lastName}
        location={shipperCompany.location}
        establishment={shipperCompany.establishment}
        paidInCaptial={shipperCompany.paidInCaptial}
        corporateNumber={shipperCompany.corporateNumber}
        listed={shipperCompany.listed}
        numberOfEmployees={shipperCompany.numberOfEmployees}
        companyURL={shipperCompany.companyURL}
        reviews={
          shipperCompany.receivedReviews?.items
            .filter(isNonNull)
            .map(review => {
              return {
                rate: review.rate ?? 0,
                title: review.title ?? '',
                description: review.description ?? '',
                createdAt: review.createdAt ?? '',
              };
            }) ?? []
        }
      />
    </>
  );
};
