import i18n from '../i18n/i18n';

export const getLocalizedPackingStyle = (
  packingStyle: string,
  packingStyleDetail: string,
) => {
  if (packingStyle === 'carton') {
    return i18n.t('carton');
  } else if (packingStyle === 'pallet') {
    return i18n.t('pallet');
  } else if (packingStyle === 'other') {
    return packingStyleDetail;
  }
};
