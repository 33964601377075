import {Grid, Stack, TextField, Typography} from '@mui/material';
import {Button as MuiButton} from '@mui/material';
import {Controller, useFieldArray, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {NumericFormat} from 'react-number-format';
import {LabelNumericFormat} from './LabelNumericFormat';
import {LabelSelect} from './LabelSelect';
import {LabelTextField} from './LabelTextField';
import {CalculationMethod} from '../API';
import {DeleteIcon} from '../images/DeleteIcon';
import {PlusIcon} from '../images/PlusIcon';
import {useCurrencies} from '../queries/currency';
import {FormInput} from '../screens/Forwarder/CreateEstimate';

export const CustomItems: React.FC<{minimumItems: number}> = ({
  minimumItems,
}) => {
  const {t} = useTranslation();
  const {data: currencies} = useCurrencies();
  const {
    control,
    formState: {errors},
    setValue,
    watch,
  } = useFormContext<FormInput>();

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'customItems',
  });

  const handleAddItemClick = () => {
    append({
      id: '',
      title: '',
      unitPrice: 0,
      unitType: '',
      calculationMethod: CalculationMethod.PER_SHIPMENT,
      customFeeCurrencyId: '',
      minimum: null,
      maximum: null,
      includeTax: true,
      taxRate: 0.0,
      remark: '',
    });
  };

  const handleRemoveItemClick = (index: number) => {
    remove(index);
  };

  return (
    <Stack
      direction="column"
      gap="8px"
      justifyContent="left"
      alignContent="left">
      {fields.map((field, index) => (
        <Stack
          key={field.id}
          sx={{
            background: '#ffffff',
            width: '696px',
            borderRadius: '6px',
            border: 1,
            borderColor: '#ACC0F1',
          }}>
          <Stack flexDirection="column" spacing={2} mx="20px" my="20px">
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center">
              <Controller
                name={`customItems.${index}.title`}
                control={control}
                render={({field}) => (
                  <LabelTextField
                    title={t('itemName')}
                    placeholder={t('enterItemName')}
                    {...field}
                    error={!!errors.customItems?.[index]?.title}
                    helperText={errors.customItems?.[index]?.title?.message}
                  />
                )}
              />
              {fields.length > minimumItems && (
                <MuiButton
                  sx={{
                    width: '24px',
                    minWidth: '24px',
                    height: '24px',
                  }}
                  onClick={() => handleRemoveItemClick(index)}>
                  <DeleteIcon />
                </MuiButton>
              )}
            </Stack>
            <Stack flexDirection="row" alignItems="center">
              <Grid container spacing="13px">
                <Grid item xs={3}>
                  <Controller
                    name={`customItems.${index}.unitPrice`}
                    control={control}
                    render={({field}) => (
                      <LabelNumericFormat
                        title={t('unitPrice')}
                        isRequired={true}
                        value={field.value}
                        onChange={field.onChange}
                        error={!!errors.customItems?.[index]?.unitPrice}
                        helperText={
                          errors.customItems?.[index]?.unitPrice?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name={`customItems.${index}.unitType`}
                    control={control}
                    render={({field}) => (
                      <LabelTextField
                        title={t('unit') + ' ' + t('freeEntry')}
                        {...field}
                        sx={{textAlignLast: 'right'}}
                        error={!!errors.customItems?.[index]?.unitType}
                        helperText={
                          errors.customItems?.[index]?.unitType?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name={`customItems.${index}.calculationMethod`}
                    control={control}
                    render={({field}) => (
                      <LabelSelect
                        title={t('formulaOfCalculation')}
                        {...field}
                        options={[
                          {
                            value: CalculationMethod.PER_SHIPMENT,
                            label: t('perShipment'),
                          },
                          {
                            value: CalculationMethod.PER_UNIT,
                            label: t('perPiece'),
                          },
                          {
                            value: CalculationMethod.PER_CW,
                            label: t('perCW'),
                          },
                          {
                            value: CalculationMethod.PER_GW,
                            label: t('perGW'),
                          },
                          {
                            value: CalculationMethod.ACTUAL_COST,
                            label: t('actualCost'),
                          },
                        ]}
                        error={!!errors.customItems?.[index]?.calculationMethod}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Stack>
            <Stack flexDirection="row" alignItems="center">
              <Grid container spacing="13px">
                <Grid item xs={3}>
                  <Controller
                    name={`customItems.${index}.minimum`}
                    control={control}
                    render={({field}) => (
                      <LabelNumericFormat
                        title={t('minimum') + t('optional')}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name={`customItems.${index}.maximum`}
                    control={control}
                    render={({field}) => (
                      <LabelNumericFormat
                        title={t('maximum') + t('optional')}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Stack>
            <Stack flexDirection="row" alignItems="center">
              <Grid container spacing="13px">
                <Grid item xs={3}>
                  <Controller
                    name={`customItems.${index}.customFeeCurrencyId`}
                    control={control}
                    render={({field}) => (
                      <LabelSelect
                        title={t('currency')}
                        value={field.value}
                        onChange={event => {
                          const selectedCurrencyId = event.target.value;
                          const selectedCurrency = currencies?.items.find(
                            currency => currency?.id === selectedCurrencyId,
                          );

                          if (selectedCurrency) {
                            setValue(
                              `customItems.${index}.customFeeCurrencyId`,
                              selectedCurrency.id,
                            );
                          }
                        }}
                        options={
                          currencies?.items?.map(item => ({
                            value: item?.id,
                            label: item?.code,
                          })) || []
                        }
                        error={
                          !!errors.customItems?.[index]?.customFeeCurrencyId
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name={`customItems.${index}.includeTax`}
                    control={control}
                    render={({field}) => (
                      <LabelSelect
                        title={t('taxableItem')}
                        {...field}
                        options={[
                          {value: true, label: t('taxableItem')},
                          {value: false, label: t('taxFree')},
                        ]}
                        error={!!errors.customItems?.[index]?.includeTax}
                        onChange={e => {
                          const includeTax = e.target.value === true;
                          field.onChange(includeTax);
                          if (!includeTax) {
                            setValue(`customItems.${index}.taxRate`, 0);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Stack flexDirection="column">
                    <Typography variant="h5">{t('taxRate')}</Typography>
                    <Controller
                      name={`customItems.${index}.taxRate`}
                      control={control}
                      render={({field}) => (
                        <NumericFormat
                          value={field.value}
                          onValueChange={values => {
                            const newTaxRate = parseFloat(values.value);
                            field.onChange(newTaxRate);
                          }}
                          inputProps={{style: {textAlign: 'right'}}}
                          customInput={TextField}
                          size="small"
                          variant="outlined"
                          InputLabelProps={{shrink: true}}
                          sx={{width: '152px', marginTop: '8px'}}
                          error={!!errors.customItems?.[index]?.taxRate}
                          helperText={
                            errors.customItems?.[index]?.taxRate?.message
                          }
                          disabled={!watch(`customItems.${index}.includeTax`)}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            <Stack flexDirection="row" alignItems="center">
              <Controller
                name={`customItems.${index}.remark`}
                control={control}
                render={({field}) => (
                  <LabelTextField
                    title={t('remark')}
                    {...field}
                    multiline={true}
                    minRows={3}
                    sx={{width: '656px'}}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Stack>
      ))}
      <MuiButton
        sx={{
          alignItems: 'left',
          justifyContent: 'left',
        }}
        onClick={handleAddItemClick}>
        <PlusIcon color="primary" />
        <Typography ml="4px" color="primary">
          {t('addItem')}
        </Typography>
      </MuiButton>
    </Stack>
  );
};
