import {zodResolver} from '@hookform/resolvers/zod';
import {
  Box,
  Stack,
  Typography,
  TextField,
  Chip,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Link,
} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {useEffect, useState} from 'react';
import * as React from 'react';
import {useForm, SubmitHandler, Controller} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {z} from 'zod';
import {NumberOfEmployees} from '../../API';
import {categoryOptions, getLabelByValue} from '../../data/categoryOptions';
import {EditIcon} from '../../images/EditIcon';
import {useOAuthUserState} from '../../providers/AuthProvider';
import {
  useShipperCompany,
  useUpdateShipperCompanyMutation,
} from '../../queries/shipperCompany';
import {useShipperUser} from '../../queries/shipperUser';

export const formInput = z.object({
  name: z.string().min(1),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  location: z.string().min(1),
  establishment: z.string().min(1),
  paidInCaptial: z.number().min(1),
  companyURL: z.string().url(),
  corporateNumber: z.string().min(1),
  category: z.string().min(1),
});

export type FormInput = z.infer<typeof formInput>;

export const ShipperCompanyInfoScreen = () => {
  const {t} = useTranslation();
  const userState = useOAuthUserState();
  const sub = userState.user?.attributes.sub ?? '';

  const {data: shipper} = useShipperUser(sub);
  const {data: shipperCompany} = useShipperCompany(shipper?.company?.id);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<FormInput>({
    mode: 'all',
    criteriaMode: 'all',
    shouldFocusError: false,
    resolver: zodResolver(formInput),
  });
  const [listed, setListed] = useState<boolean | null>(null);
  const [numberOfEmployees, setNumberOfEmployees] =
    useState<NumberOfEmployees | null>(null);
  const [categoryLabel, setCategoryLabel] = useState(
    getLabelByValue(shipperCompany?.category),
  );

  const [originalListed, setOriginalListed] = useState<boolean | null>(null);
  const [originalNumberOfEmployees, setOriginalNumberOfEmployees] =
    useState<NumberOfEmployees | null>(null);
  const [originalCategoryLabel, setOriginalCategoryLabel] = useState(
    getLabelByValue(shipperCompany?.category),
  );

  useEffect(() => {
    if (shipperCompany) {
      setValue('name', shipperCompany.name);
      setValue('firstName', shipperCompany.firstName);
      setValue('lastName', shipperCompany.lastName);
      setValue('location', shipperCompany.location);
      setValue('establishment', shipperCompany.establishment);
      setValue('paidInCaptial', shipperCompany.paidInCaptial);
      setListed(shipperCompany.listed);
      setNumberOfEmployees(shipperCompany.numberOfEmployees);
      setValue('companyURL', shipperCompany.companyURL);
      setValue('corporateNumber', shipperCompany.corporateNumber);
      setValue('category', shipperCompany.category);
      setCategoryLabel(getLabelByValue(shipperCompany?.category));
    }
  }, [shipperCompany, setValue, setCategoryLabel]);

  const {mutate: updateShipperCompanyMutation} =
    useUpdateShipperCompanyMutation();

  const onSubmit: SubmitHandler<FormInput> = async data => {
    if (shipperCompany) {
      await updateShipperCompanyMutation({
        input: {
          id: shipperCompany.id,
          name: data.name,
          firstName: data.firstName,
          lastName: data.lastName,
          location: data.location,
          establishment: data.establishment,
          paidInCaptial: data.paidInCaptial,
          listed,
          numberOfEmployees,
          companyURL: data.companyURL,
          corporateNumber: data.corporateNumber,
          category: data.category,
          shipperCompanyManagerId: shipperCompany?.shipperCompanyManagerId,
        },
      });
    }
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
    // 編集前のデータに戻す
    setValue('name', originalData['name']);
    setValue('firstName', originalData['firstName']);
    setValue('lastName', originalData['lastName']);
    setValue('location', originalData['location']);
    setValue('establishment', originalData['establishment']);
    setValue('paidInCaptial', originalData['paidInCaptial']);
    setListed(originalListed);
    setNumberOfEmployees(originalNumberOfEmployees);
    setValue('companyURL', originalData['companyURL']);
    setValue('corporateNumber', originalData['corporateNumber']);
    setValue('category', originalData['category']);
    setCategoryLabel(originalCategoryLabel);
  };

  const [editing, setEditing] = useState(false);
  const [originalData, setOriginalData] = useState({
    name: '',
    firstName: '',
    lastName: '',
    location: '',
    establishment: '',
    paidInCaptial: 0,
    companyURL: '',
    corporateNumber: '',
    category: '',
  });

  useEffect(() => {
    document.title = `${t('companyProfile')} | MAST`;
  }, [t]);
  return (
    <>
      {editing ? (
        /*会社情報編集画面*/
        <Stack
          alignItems="center"
          flexDirection="column"
          sx={{
            paddingBottom: '120px',
          }}>
          <Stack justifyContent="start" width="730px">
            <Typography mb={3} variant="h2">
              {t('companyProfile')}
            </Typography>
            <Box
              sx={{
                borderRadius: '12px',
                boxShadow: '0 2px 18px rgba(204, 204, 204, 0.3)',
                backgroundColor: '#fff',
              }}>
              <Stack spacing={3} my="26px" ml={5}>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h5">{t('companyName')}</Typography>
                    <Typography variant="error" sx={{marginLeft: '12px'}}>
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <TextField
                    fullWidth
                    sx={{maxWidth: '452px'}}
                    placeholder=""
                    error={!!errors.name}
                    {...register('name', {required: true})}
                  />
                  {errors.name && (
                    <Typography variant="error">
                      {t('enterCompanyName')}
                    </Typography>
                  )}
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h5">
                      {t('companyRepresentitive')}
                    </Typography>
                    <Typography variant="error" sx={{marginLeft: '12px'}}>
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <Stack mt={1} flexDirection="row">
                    <Stack flexDirection="column">
                      <TextField
                        sx={{width: '222px'}}
                        placeholder=""
                        error={!!errors.lastName}
                        {...register('lastName', {required: true})}
                      />
                      {errors.lastName && (
                        <Typography variant="error">
                          {t('enterLastName')}
                        </Typography>
                      )}
                    </Stack>
                    <Stack ml={1} flexDirection="column">
                      <TextField
                        sx={{width: '222px'}}
                        placeholder=""
                        error={!!errors.firstName}
                        {...register('firstName', {required: true})}
                      />
                      {errors.firstName && (
                        <Typography variant="error">
                          {t('enterFirstName')}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h5">
                      {t('headquartersAddress')}
                    </Typography>
                    <Typography variant="error" sx={{marginLeft: '12px'}}>
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <TextField
                    fullWidth
                    sx={{maxWidth: '452px'}}
                    placeholder=""
                    error={!!errors.location}
                    {...register('location', {required: true})}
                  />
                  {errors.location && (
                    <Typography variant="error">
                      {t('enterHeadquatersAddress')}
                    </Typography>
                  )}
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h5">
                      {t('establishmentDate')}
                    </Typography>
                    <Typography variant="error" sx={{marginLeft: '12px'}}>
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="ja">
                    <DatePicker
                      sx={{width: '452px'}}
                      format="YYYY/M/D"
                      slotProps={{textField: {size: 'small'}}}
                      defaultValue={dayjs(getValues('establishment'))}
                      onChange={newValue =>
                        setValue(
                          'establishment',
                          dayjs(newValue).format('YYYY-MM-DD'),
                        )
                      }
                    />
                  </LocalizationProvider>
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h5">{t('capitalAmount')}</Typography>
                    <Typography variant="error" sx={{marginLeft: '12px'}}>
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <TextField
                    sx={{width: '452px'}}
                    type="number"
                    size="small"
                    placeholder={t('capitalAmount')}
                    error={!!errors.paidInCaptial}
                    {...register('paidInCaptial', {
                      required: true,
                      valueAsNumber: true,
                    })}
                    onChange={event => {
                      setValue('paidInCaptial', Number(event.target.value), {
                        shouldValidate: true,
                      });
                    }}
                  />
                  {errors.paidInCaptial && (
                    <Typography variant="error" fontSize="14px">
                      {t('enterCapitalAmount')}
                    </Typography>
                  )}
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h5">
                      {t('corporateRegistrationNumber')}
                    </Typography>
                    <Typography variant="error" sx={{marginLeft: '12px'}}>
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <TextField
                    sx={{width: '452px'}}
                    type="number"
                    size="small"
                    placeholder={t('corporateRegistrationNumber')}
                    error={!!errors.corporateNumber}
                    {...register('corporateNumber', {
                      required: true,
                    })}
                    onChange={event => {
                      setValue('corporateNumber', event.target.value, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  {errors.corporateNumber && (
                    <Typography variant="error" fontSize="14px">
                      {t('enterCorporateRegistrationNumber')}
                    </Typography>
                  )}
                  <Typography fontSize="12px">
                    <Trans
                      i18nKey="checkCorporateRegistrationNumber"
                      components={{
                        link_here: (
                          <Link
                            href="https://info.gbiz.go.jp/"
                            underline="hover"
                            target="_blank"></Link>
                        ),
                      }}
                    />
                  </Typography>
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h5">{t('industry')}</Typography>
                    <Typography variant="error" sx={{marginLeft: '12px'}}>
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <Controller
                    name="category"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <>
                        <Select
                          labelId="area-label"
                          {...field}
                          size="small"
                          sx={{
                            backgroundColor: '#fff',
                            paddingTop: '2.5px',
                            paddingBottom: '2.5px',
                            maxWidth: '452px',
                          }}
                          onChange={e => {
                            const selectedValue = e.target.value;
                            const categoryLabel =
                              getLabelByValue(selectedValue);
                            setCategoryLabel(categoryLabel);
                            field.onChange(selectedValue);
                          }}>
                          {categoryOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.category && (
                          <Typography variant="error" fontSize="14px">
                            {t('enterIndustry')}
                          </Typography>
                        )}
                      </>
                    )}
                  />
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h5">{t('category')}</Typography>
                    <Typography variant="error" sx={{marginLeft: '12px'}}>
                      {t('requiredField')}
                    </Typography>
                  </Stack>

                  <RadioGroup
                    value={listed}
                    style={{marginLeft: '10px'}}
                    onChange={e => setListed(e.target.value === 'true')}>
                    <FormControlLabel
                      value="true"
                      control={<Radio size="small" />}
                      label={t('listedCompany')}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio size="small" />}
                      label={t('unlistedCompany')}
                    />
                  </RadioGroup>
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h5">{t('employeesNumber')}</Typography>
                    <Typography variant="error" sx={{marginLeft: '12px'}}>
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <RadioGroup
                    value={numberOfEmployees}
                    style={{marginLeft: '10px'}}
                    onChange={e =>
                      setNumberOfEmployees(e.target.value as NumberOfEmployees)
                    }>
                    <FormControlLabel
                      value={NumberOfEmployees.UNDER5}
                      control={<Radio size="small" />}
                      label={t('lessFivePeople')}
                    />
                    <FormControlLabel
                      value={NumberOfEmployees.UNDER20}
                      control={<Radio size="small" />}
                      label={t('lessTwentyPeople')}
                    />
                    <FormControlLabel
                      value={NumberOfEmployees.UNDER100}
                      control={<Radio size="small" />}
                      label={t('lessHundredPeople')}
                    />
                    <FormControlLabel
                      value={NumberOfEmployees.UNDER300}
                      control={<Radio size="small" />}
                      label={t('lessThreeHundredPeople')}
                    />
                    <FormControlLabel
                      value={NumberOfEmployees.OVER300}
                      control={<Radio size="small" />}
                      label={t('moreThreeHundredPeople')}
                    />
                  </RadioGroup>
                </Stack>
                <Stack flexDirection="column" spacing={1}>
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="h5">{t('homepageLink')}</Typography>
                    <Typography variant="error" sx={{marginLeft: '12px'}}>
                      {t('requiredField')}
                    </Typography>
                  </Stack>
                  <TextField
                    fullWidth
                    sx={{maxWidth: '452px'}}
                    placeholder=""
                    error={!!errors.companyURL}
                    {...register('companyURL', {required: true})}
                  />
                  {errors.companyURL && (
                    <Typography variant="error">{t('enterHPLink')}</Typography>
                  )}
                </Stack>
              </Stack>
              <Box
                sx={{
                  borderRadius: '24px',
                  marginLeft: '40px',
                  marginBottom: '32px',
                }}>
                <Button variant="gray" size="small" onClick={handleCancel}>
                  {t('cancel')}
                </Button>
                <Button
                  sx={{marginLeft: '24px'}}
                  variant="primary"
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  size="small">
                  {t('update')}
                </Button>
              </Box>
            </Box>
          </Stack>
        </Stack>
      ) : (
        /*会社情報確認画面*/
        <Stack
          alignItems="center"
          flexDirection="column"
          sx={{
            paddingBottom: '120px',
          }}>
          <Stack justifyContent="start" width="730px">
            <Stack
              flexDirection="row"
              mb={3}
              justifyContent="space-between"
              alignItems="flex-end">
              <Typography variant="h2">{t('companyProfile')}</Typography>
              <Chip
                color="primary"
                icon={<EditIcon color="primary" style={{fill: 'none'}} />}
                label={t('edit')}
                variant="outlined"
                onClick={() => {
                  setEditing(true);
                  setOriginalData({...getValues()});
                  setOriginalListed(listed);
                  setOriginalNumberOfEmployees(numberOfEmployees);
                  setOriginalCategoryLabel(categoryLabel);
                }}
                sx={{
                  width: '127px',
                  backgroundColor: '#FFFFFF',
                  '& .MuiChip-label': {
                    paddingLeft: '8px',
                    fontSize: '16px',
                    fontWeight: '500',
                    lineHeight: '20.8px',
                  },
                  '&.MuiChip-root': {
                    height: '45px',
                    borderRadius: '30px',
                  },
                }}
              />
            </Stack>
            <Box
              sx={{
                borderRadius: '12px',
                boxShadow: '0 2px 18px rgba(204, 204, 204, 0.3)',
                backgroundColor: '#fff',
              }}>
              <Stack spacing={3} my="26px" ml={5}>
                <Stack>
                  <Typography fontSize="20px">{getValues('name')}</Typography>
                </Stack>
                <Box>
                  <Typography>{t('representativeDirectorName')}</Typography>
                  <Typography fontSize="20px">
                    {`${getValues('lastName') ?? ''} ${
                      getValues('firstName') ?? ''
                    }`}
                  </Typography>
                </Box>
                <Box>
                  <Typography>{t('headquartersAddress')}</Typography>
                  <Typography fontSize="20px">
                    {getValues('location')}
                  </Typography>
                </Box>
                <Box>
                  <Typography>{t('establishmentDate')}</Typography>
                  <Typography fontSize="20px">
                    {getValues('establishment')
                      ? dayjs(getValues('establishment')).format('YYYY/M/D')
                      : ''}
                  </Typography>
                </Box>
                <Box>
                  <Typography>{t('capitalAmount')}</Typography>
                  <Typography fontSize="20px">
                    {getValues('paidInCaptial')}
                  </Typography>
                </Box>
                <Box>
                  <Typography>{t('corporateRegistrationNumber')}</Typography>
                  <Typography fontSize="20px">
                    {getValues('corporateNumber')}
                  </Typography>
                </Box>
                <Box>
                  <Typography>{t('industry')}</Typography>
                  <Typography fontSize="20px">{categoryLabel}</Typography>
                </Box>
                <Box>
                  <Typography>{t('category')}</Typography>
                  <Typography fontSize="20px">
                    {`${listed ? t('listedCompany') : t('unlistedCompany')}`}
                  </Typography>
                </Box>
                <Box>
                  <Typography>{t('employeesNumber')}</Typography>
                  <Typography fontSize="20px">
                    {`${
                      numberOfEmployees === NumberOfEmployees.UNDER5
                        ? t('lessFivePeople')
                        : numberOfEmployees === NumberOfEmployees.UNDER20
                        ? t('lessTwentyPeople')
                        : numberOfEmployees === NumberOfEmployees.UNDER100
                        ? t('lessHundredPeople')
                        : numberOfEmployees === NumberOfEmployees.UNDER300
                        ? t('lessThreeHundredPeople')
                        : t('moreThreeHundredPeople')
                    }`}
                  </Typography>
                </Box>
                <Box>
                  <Typography>{t('homepageLink')}</Typography>
                  <Typography fontSize="20px">
                    {getValues('companyURL')}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      )}
    </>
  );
};
