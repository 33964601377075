import {Rating, Stack, Typography} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useForwarderCompany} from '../queries/forwarderCompany';

interface ForwarderCompanyRateProps {
  forwarderCompanyId?: string | null;
}

export const ForwarderCompanyRate: React.FC<
  ForwarderCompanyRateProps
> = props => {
  const {data: forwarderCompany} = useForwarderCompany(
    props.forwarderCompanyId ?? '',
  );
  const navigate = useNavigate();
  const [averageRate, setAverateRate] = useState(0);
  useEffect(() => {
    const items = forwarderCompany?.receivedReviews?.items ?? [];
    let totalRate = 0;
    items.forEach(item => {
      if (item) {
        totalRate += item.rate;
      }
    });
    const averageRate = items.length > 0 ? totalRate / items.length : 0;
    setAverateRate(averageRate);
  }, [forwarderCompany]);

  return (
    <Stack>
      <Typography
        variant="h5"
        onClick={() => {
          navigate('/forwarderCompanyProfile', {
            state: {companyId: props.forwarderCompanyId},
          });
        }}
        style={{cursor: 'pointer'}}>
        {forwarderCompany?.name}
      </Typography>
      <Rating name="read-only" value={averageRate} readOnly />
    </Stack>
  );
};
