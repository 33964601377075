import 'react-phone-input-2/lib/style.css';
import {zodResolver} from '@hookform/resolvers/zod';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import {
  Stack,
  Typography,
  TextField,
  Autocomplete,
  Divider,
  Grid,
  Box,
  Snackbar,
  Alert,
  AlertTitle,
  Button,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import dayjs, {Dayjs} from 'dayjs';
import {useState, useEffect, useCallback} from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {FormInput, formInput} from './CreateEstimate';
import {CargoType, Estimate} from '../../API';
import {CustomItems} from '../../components/CustomItems';
import {LabelDatePicker} from '../../components/LabelDatePicker';
import {LabelNumericFormat} from '../../components/LabelNumericFormat';
import {LabelSelect} from '../../components/LabelSelect';
import {LabelTextField} from '../../components/LabelTextField';
import {Title} from '../../components/Title';
import {airlines} from '../../data/airlines';
import {airports} from '../../data/airports';
import Inner from '../../layouts/Inner';
import {useOAuthUserState} from '../../providers/AuthProvider';
import {useCurrencies} from '../../queries/currency';
import {
  useCreateCustomFeeMutation,
  useUpdateCustomFeeMutation,
  useDeleteCustomFeeMutation,
} from '../../queries/customFee';
import {useUpdateEstimateMutation, useEstimate} from '../../queries/estimate';
import {useFhds} from '../../queries/fhd';
import {useFobs} from '../../queries/fob';
import {useForwarderUser} from '../../queries/forwarderUser';
import {assert} from '../../utils/assert';
import {getLocalizedCargoType} from '../../utils/getLocalizedCargoType';
import {getLocalizedLeadTime} from '../../utils/getLocalizedLeadTime';
import {isNonNull} from '../../utils/isEmpty';
import {toAWSDate} from '../../utils/toAWSDate';

export type EstimateInputForm = Omit<Estimate, '__typename' | 'forwarderUser'>;

export const UpdateEstimateScreen = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useOAuthUserState();
  const sub = userState.user?.attributes.sub ?? '';
  const {data: forwarder} = useForwarderUser(sub);
  const state = location.state as {
    id: string;
  };
  const {data: fobs} = useFobs(forwarder?.companyId ?? '');
  const {data: fhds} = useFhds(forwarder?.companyId ?? '');
  const {data: defaultEstimate} = useEstimate(state.id);
  const {data: currencies} = useCurrencies();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessages, setAlertMessages] = useState<
    {title: string; message: string}[]
  >([]);
  const [sortedDefaultEstimate, setSortedDefaultEstimate] =
    useState<Estimate | null>(null);
  const {mutateAsync: updateEstimateMutation} = useUpdateEstimateMutation();
  const {mutateAsync: updateCustomFeeMutation} = useUpdateCustomFeeMutation();
  const {mutateAsync: createCustomFeeMutation} = useCreateCustomFeeMutation();
  const {mutateAsync: deleteCustomFeeMutation} = useDeleteCustomFeeMutation();

  const methods = useForm<FormInput>({
    defaultValues: {
      id: defaultEstimate?.id,
      departure: defaultEstimate?.departure,
      arrival: defaultEstimate?.arrival,
      airline: defaultEstimate?.airline,
      flightDate: defaultEstimate?.flightDate,
      directFlight: defaultEstimate?.directFlight,
      leadTime: defaultEstimate?.leadTime,
      useSaf: defaultEstimate?.useSaf,
      cargoType: defaultEstimate?.cargoType,
      yardCutDate: defaultEstimate?.yardCutDate,
      carrierShedCutDate: defaultEstimate?.carrierShedCutDate,
      estimateCurrencyId: defaultEstimate?.estimateCurrencyId ?? undefined,
      minimum: defaultEstimate?.minimum,
      under45: defaultEstimate?.under45,
      over45: defaultEstimate?.over45,
      over100: defaultEstimate?.over100,
      over300: defaultEstimate?.over300,
      over500: defaultEstimate?.over500,
      over1000: defaultEstimate?.over1000,
      fsc: defaultEstimate?.fsc,
      remark: defaultEstimate?.remark,
      customItems:
        sortedDefaultEstimate?.customItems?.items.map(
          item =>
            item && {
              id: item?.id ?? '',
              title: item?.title ?? '',
              unitPrice: item?.unitPrice ?? 0,
              unitType: item?.unitType ?? '',
              calculationMethod: item?.calculationMethod,
              customFeeCurrencyId: item?.customFeeCurrencyId ?? '',
              minimum: item?.minimum ?? null,
              maximum: item?.maximum ?? null,
              includeTax: item?.includeTax ?? true,
              taxRate: item?.taxRate ?? null,
              remark: item?.remark ?? null,
            },
        ) || [],
    },
    mode: 'all',
    criteriaMode: 'all',
    resolver: zodResolver(formInput),
  });

  const {
    control,
    formState: {errors},
    setValue,
    handleSubmit,
    getValues,
    reset,
  } = methods;

  useEffect(() => {
    reset({
      id: defaultEstimate?.id,
      departure: defaultEstimate?.departure,
      arrival: defaultEstimate?.arrival,
      airline: defaultEstimate?.airline,
      flightDate: defaultEstimate?.flightDate,
      directFlight: defaultEstimate?.directFlight,
      leadTime: defaultEstimate?.leadTime,
      useSaf: defaultEstimate?.useSaf,
      cargoType: defaultEstimate?.cargoType,
      yardCutDate: defaultEstimate?.yardCutDate,
      carrierShedCutDate: defaultEstimate?.carrierShedCutDate,
      estimateCurrencyId: defaultEstimate?.estimateCurrencyId ?? undefined,
      minimum: defaultEstimate?.minimum,
      under45: defaultEstimate?.under45,
      over45: defaultEstimate?.over45,
      over100: defaultEstimate?.over100,
      over300: defaultEstimate?.over300,
      over500: defaultEstimate?.over500,
      over1000: defaultEstimate?.over1000,
      fsc: defaultEstimate?.fsc,
      remark: defaultEstimate?.remark,
      customItems:
        sortedDefaultEstimate?.customItems?.items.map(
          item =>
            item && {
              id: item?.id ?? '',
              title: item?.title ?? '',
              unitPrice: item?.unitPrice ?? 0,
              unitType: item?.unitType ?? '',
              calculationMethod: item?.calculationMethod,
              customFeeCurrencyId: item?.customFeeCurrencyId ?? '',
              minimum: item?.minimum ?? null,
              maximum: item?.maximum ?? null,
              includeTax: item?.includeTax ?? true,
              taxRate: item?.taxRate ?? null,
              remark: item?.remark ?? null,
            },
        ) || [],
    });
  }, [reset, defaultEstimate, sortedDefaultEstimate?.customItems?.items]);

  const customItems = getValues('customItems');

  const updateEstimate: SubmitHandler<FormInput> = useCallback(
    async data => {
      try {
        // FOBFHDが設定されていない空港を設定した場合アラートを表示
        setAlertMessages([]);
        if (!fobs?.some(fob => fob?.airport === data.departure)) {
          setAlertOpen(true);
          setAlertMessages(prevMessages => [
            ...prevMessages,
            {
              title: t('exportChargeNotSet'),
              message:
                `${
                  airports.find(airport => airport.code === data.departure)
                    ?.name ?? ''
                }` + t('cannotRegisterExport'),
            },
          ]);
        }
        if (!fhds?.some(fhd => fhd?.airport === data.arrival)) {
          setAlertOpen(true);
          setAlertMessages(prevMessages => [
            ...prevMessages,
            {
              title: t('importChargeNotSet'),
              message:
                `${
                  airports.find(airport => airport.code === data.arrival)
                    ?.name ?? ''
                }` + t('cannotRegisterImport'),
            },
          ]);
        }
        if (
          !fobs?.some(fob => fob?.airport === data.departure) ||
          !fhds?.some(fhd => fhd?.airport === data.arrival)
        ) {
          return;
        }
        // Estimateレコードを更新
        await updateEstimateMutation({
          input: {
            id: data.id,
            departure: data.departure,
            arrival: data.arrival,
            airline: data.airline,
            flightDate: toAWSDate(dayjs(data.flightDate).toDate()),
            directFlight: data.directFlight,
            leadTime: data.leadTime,
            useSaf: data.useSaf,
            cargoType: data.cargoType,
            yardCutDate: toAWSDate(dayjs(data.yardCutDate).toDate()),
            carrierShedCutDate: toAWSDate(
              dayjs(data.carrierShedCutDate).toDate(),
            ),
            estimateCurrencyId: data.estimateCurrencyId,
            minimum: data.minimum,
            under45: data.under45,
            over45: data.over45,
            over100: data.over100,
            over300: data.over300,
            over500: data.over500,
            over1000: data.over1000,
            fsc: data.fsc,
            remark: data.remark,
            forwarderUserEstimatesId: userState.user?.attributes.sub,
          },
        });

        for (const customItem of data.customItems) {
          assert(
            customItem,
            'An error occurs because customItem is undefined in UpdateEstimateScreen',
          );
          if (customItem.id !== '') {
            // カスタム料金の項目が更新された場合
            await updateCustomFeeMutation(
              {
                input: {
                  id: customItem.id,
                  title: customItem.title,
                  unitPrice: customItem.unitPrice,
                  unitType: customItem.unitType,
                  calculationMethod: customItem.calculationMethod,
                  minimum: customItem.minimum,
                  maximum: customItem.maximum,
                  includeTax: customItem.includeTax,
                  taxRate: customItem.taxRate,
                  remark: customItem.remark,
                  estimateId: data.id,
                  customFeeCurrencyId: customItem.customFeeCurrencyId,
                },
              },
              {
                onError: err => {
                  Sentry.captureException(err);
                  return navigate('error');
                },
              },
            );
          } else {
            // カスタム料金の項目が追加された場合
            await createCustomFeeMutation(
              {
                input: {
                  title: customItem.title,
                  unitPrice: customItem.unitPrice,
                  unitType: customItem.unitType,
                  calculationMethod: customItem.calculationMethod,
                  minimum: customItem.minimum,
                  maximum: customItem.maximum,
                  includeTax: customItem.includeTax,
                  taxRate: customItem.taxRate,
                  remark: customItem.remark,
                  estimateId: data.id,
                  customFeeCurrencyId: customItem.customFeeCurrencyId,
                },
              },
              {
                onError: err => {
                  Sentry.captureException(err);
                  return navigate('error');
                },
              },
            );
          }
        }

        customItems?.filter(isNonNull).map(item => {
          if (
            !data.customItems.map(item => item && item.id).includes(item.id)
          ) {
            // カスタム料金が削除された場合
            deleteCustomFeeMutation(
              {
                input: {id: item.id},
              },
              {
                onError: err => {
                  Sentry.captureException(err);
                  return navigate('error');
                },
              },
            );
          }
        });
        navigate('/estimateList');
      } catch {
        navigate('error');
      }
    },
    [
      createCustomFeeMutation,
      customItems,
      deleteCustomFeeMutation,
      fhds,
      fobs,
      navigate,
      t,
      updateCustomFeeMutation,
      updateEstimateMutation,
      userState.user?.attributes.sub,
    ],
  );

  useEffect(() => {
    document.title = `${t('quotationsList')} | MAST`;
  }, [t]);

  useEffect(() => {
    if (defaultEstimate?.customItems?.items) {
      const sortedItems = [...defaultEstimate.customItems.items].sort(
        (a, b) =>
          new Date(a?.updatedAt ?? '').getTime() -
          new Date(b?.updatedAt ?? '').getTime(),
      );
      const sortedDefaultEstimate = {
        ...defaultEstimate,
        customItems: {
          ...defaultEstimate.customItems,
          items: sortedItems,
        },
      } as Estimate;
      setSortedDefaultEstimate(sortedDefaultEstimate);
    } else {
      setSortedDefaultEstimate(null);
    }
  }, [defaultEstimate]);

  const cargoTypeOptions = [CargoType.NORMAL];

  const leadTimeOptions = [0, 1, 2, 3, 4, 5, 6, 7];

  return (
    <>
      <FormProvider {...methods}>
        <Stack alignItems="center" flexDirection="column">
          <Stack width="778px">
            <Title title={t('quotation')} />
          </Stack>
          <Stack
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mt={3}>
            <Inner>
              <Stack spacing={3}>
                <Stack
                  flexDirection="column"
                  sx={{
                    gap: '24px',
                  }}>
                  <Stack
                    flexDirection="column"
                    sx={{
                      width: '494px',
                      gap: '24px',
                    }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      border={1}
                      borderRadius="10px"
                      borderColor="#C4C4C4">
                      <Box
                        display="flex"
                        flexDirection="column"
                        sx={{
                          marginY: '15px',
                          marginLeft: '14px',
                          marginRight: '19px',
                        }}>
                        <Typography variant="h5">
                          {t('originAirport')}
                        </Typography>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center">
                          <FlightTakeoffIcon
                            sx={{
                              fontSize: '19px',
                            }}
                          />
                          <Controller
                            name="departure"
                            control={control}
                            render={({field}) => (
                              <Autocomplete
                                value={airports.find(airport => {
                                  const defaultValue = {
                                    id: 1,
                                    name: 'Anaa / AAA / French Polynesia',
                                    code: 'AAA',
                                  };
                                  return field.value !== undefined
                                    ? airport.code === field.value
                                    : airport.code === defaultValue.code;
                                })}
                                onChange={(_, value) => {
                                  field.onChange(value?.code ?? '');
                                }}
                                sx={{width: '191px'}}
                                options={airports}
                                getOptionLabel={airport =>
                                  airport ? airport.name : ''
                                }
                                size="small"
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    placeholder={t('selectAirport')}
                                    variant="standard"
                                    InputProps={{
                                      ...params.InputProps,
                                      disableUnderline: true,
                                      style: {
                                        height: '27px',
                                      },
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      sx: {
                                        height: '27px',
                                        paddingY: 'unset !important',
                                        paddingLeft: '3px !important',
                                      },
                                    }}
                                    error={!!errors.departure}
                                    helperText={errors.departure?.message}
                                  />
                                )}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                      <Divider orientation="vertical" flexItem />
                      <Box
                        display="flex"
                        flexDirection="column"
                        sx={{
                          marginY: '15px',
                          marginLeft: '14px',
                          marginRight: '19px',
                        }}>
                        <Typography variant="h5">
                          {t('destinationAirport')}
                        </Typography>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center">
                          <FlightLandIcon
                            sx={{
                              fontSize: '19px',
                            }}
                          />
                          <Controller
                            name="arrival"
                            control={control}
                            render={({field}) => (
                              <Autocomplete
                                value={airports.find(airport => {
                                  const defaultValue = {
                                    id: 1,
                                    name: 'Anaa / AAA / French Polynesia',
                                    code: 'AAA',
                                  };
                                  return field.value !== undefined
                                    ? airport.code === field.value
                                    : airport.code === defaultValue.code;
                                })}
                                onChange={(_, value) => {
                                  field.onChange(value?.code ?? '');
                                }}
                                sx={{width: '191px'}}
                                options={airports}
                                getOptionLabel={airport =>
                                  airport ? airport.name : ''
                                }
                                size="small"
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    placeholder={t('selectAirport')}
                                    variant="standard"
                                    InputProps={{
                                      ...params.InputProps,
                                      disableUnderline: true,
                                      style: {
                                        height: '27px',
                                      },
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      sx: {
                                        height: '27px',
                                        paddingY: 'unset !important',
                                        paddingLeft: '3px !important',
                                      },
                                    }}
                                    error={!!errors.arrival}
                                    helperText={errors.arrival?.message}
                                  />
                                )}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="494px">
                      <Grid container spacing="12px">
                        <Grid item xs={6}>
                          <Stack
                            direction="column"
                            width="100%"
                            height="100%"
                            gap="9px">
                            <Typography variant="h5">{t('airline')}</Typography>
                            <Controller
                              name="airline"
                              control={control}
                              render={({field}) => (
                                <Autocomplete
                                  value={airlines.find(airline => {
                                    const defaultValue = {
                                      id: 1,
                                      name: 'To Be Determined',
                                      code: 'TBD',
                                    };
                                    return field.value !== undefined
                                      ? airline.code === field.value
                                      : airline.code === defaultValue.code;
                                  })}
                                  onChange={(_, value) => {
                                    field.onChange(value?.code ?? '');
                                  }}
                                  sx={{width: '241px'}}
                                  options={airlines}
                                  getOptionLabel={airline =>
                                    airline ? airline.name : ''
                                  }
                                  size="small"
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      placeholder={t('selectAirline')}
                                      inputProps={{
                                        ...params.inputProps,
                                        sx: {
                                          paddingLeft: '9px !important',
                                          paddingY: '5px !important',
                                        },
                                      }}
                                      error={!!errors.airline}
                                      helperText={errors.airline?.message}
                                    />
                                  )}
                                />
                              )}
                            />
                          </Stack>
                        </Grid>
                        <Controller
                          name="flightDate"
                          control={control}
                          render={({field, fieldState}) => (
                            <Grid item xs={6}>
                              <LabelDatePicker
                                title={t('etd')}
                                value={dayjs(field.value)}
                                onChange={(date: Dayjs | null) => {
                                  if (date) {
                                    field.onChange(toAWSDate(date.toDate()));
                                  }
                                }}
                                error={fieldState.error?.message}
                              />
                            </Grid>
                          )}
                        />
                      </Grid>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="494px">
                      <Grid container spacing="13px">
                        <Controller
                          name="directFlight"
                          control={control}
                          render={({field}) => (
                            <Grid item xs={4}>
                              <LabelSelect
                                title={t('flightType')}
                                {...field}
                                value={
                                  field.value === undefined ? true : field.value
                                }
                                options={[
                                  {value: true, label: t('directFlight')},
                                  {value: false, label: t('connectingFlight')},
                                ]}
                              />
                            </Grid>
                          )}
                        />
                        <Controller
                          name="leadTime"
                          control={control}
                          render={({field}) => (
                            <Grid item xs={4}>
                              <LabelSelect
                                title={t('leadTime')}
                                {...field}
                                value={
                                  field.value === undefined ? 0 : field.value
                                }
                                options={leadTimeOptions.map(value => ({
                                  value,
                                  label: getLocalizedLeadTime(value),
                                }))}
                              />
                            </Grid>
                          )}
                        />
                        <Controller
                          name="useSaf"
                          control={control}
                          render={({field}) => (
                            <Grid item xs={4}>
                              <LabelSelect
                                title={t('saf')}
                                {...field}
                                value={
                                  field.value === undefined ? true : field.value
                                }
                                options={[
                                  {value: true, label: t('use')},
                                  {value: false, label: t('nonUse')},
                                ]}
                              />
                            </Grid>
                          )}
                        />
                      </Grid>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="494px">
                      <Grid container>
                        <Grid item xs={12}>
                          <Controller
                            name="cargoType"
                            control={control}
                            render={({field}) => (
                              <LabelSelect
                                title={t('commodityTypeExport')}
                                {...field}
                                value={
                                  field.value === undefined
                                    ? CargoType.NORMAL
                                    : field.value
                                }
                                options={cargoTypeOptions.map(value => ({
                                  value,
                                  label: getLocalizedCargoType(value),
                                }))}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="494px">
                      <Grid container spacing="12px">
                        <Controller
                          name="yardCutDate"
                          control={control}
                          render={({field, fieldState}) => (
                            <Grid item xs={6}>
                              <LabelDatePicker
                                title={t('ownWarehouse') + ' ' + t('cut')}
                                value={dayjs(field.value)}
                                onChange={(date: Dayjs | null) => {
                                  if (date) {
                                    field.onChange(toAWSDate(date.toDate()));
                                  }
                                }}
                                error={fieldState.error?.message}
                              />
                            </Grid>
                          )}
                        />
                        <Controller
                          name="carrierShedCutDate"
                          control={control}
                          render={({field, fieldState}) => (
                            <Grid item xs={6}>
                              <LabelDatePicker
                                title={t('airlineWarehouseDirectCut')}
                                value={dayjs(field.value)}
                                onChange={(date: Dayjs | null) => {
                                  if (date) {
                                    field.onChange(toAWSDate(date.toDate()));
                                  }
                                }}
                                error={fieldState.error?.message}
                              />
                            </Grid>
                          )}
                        />
                      </Grid>
                    </Stack>
                  </Stack>
                  <Stack
                    sx={{
                      width: '125px',
                    }}>
                    <Controller
                      name="estimateCurrencyId"
                      control={control}
                      render={({field}) => (
                        <LabelSelect
                          title={t('currency')}
                          value={
                            field.value === undefined ? 'JPY' : field.value
                          }
                          onChange={event => {
                            const selectedCurrencyId = event.target.value;
                            const selectedCurrency = currencies?.items.find(
                              currency => currency?.id === selectedCurrencyId,
                            );

                            if (selectedCurrency) {
                              setValue(
                                'estimateCurrencyId',
                                selectedCurrency.id,
                              );
                            }
                          }}
                          options={
                            currencies?.items?.map(item => ({
                              value: item?.id,
                              label: item?.code,
                            })) || []
                          }
                          error={!!errors.estimateCurrencyId}
                        />
                      )}
                    />
                  </Stack>
                  <Stack
                    flexDirection="column"
                    justifyContent="space-between"
                    sx={{
                      gap: '16px',
                    }}>
                    <Stack direction="row" alignItems="center" height="90px">
                      <Grid container spacing="13px">
                        <Grid item xs={4}>
                          <Controller
                            name="minimum"
                            control={control}
                            render={({field}) => (
                              <LabelNumericFormat
                                title={t('minimum')}
                                isRequired={true}
                                value={field.value}
                                onChange={field.onChange}
                                error={!!errors.minimum}
                                helperText={errors.minimum?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name="under45"
                            control={control}
                            render={({field}) => (
                              <LabelNumericFormat
                                title={t('minusFortyFive')}
                                isRequired={true}
                                value={field.value}
                                onChange={field.onChange}
                                error={!!errors.under45}
                                helperText={errors.under45?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name="over45"
                            control={control}
                            render={({field}) => (
                              <LabelNumericFormat
                                title={t('plusFortyFive')}
                                isRequired={true}
                                value={field.value}
                                onChange={field.onChange}
                                error={!!errors.over45}
                                helperText={errors.over45?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack direction="row" alignItems="center" height="90px">
                      <Grid container spacing="13px">
                        <Grid item xs={4}>
                          <Controller
                            name="over100"
                            control={control}
                            render={({field}) => (
                              <LabelNumericFormat
                                title={t('plusHundred')}
                                isRequired={true}
                                value={field.value}
                                onChange={field.onChange}
                                error={!!errors.over100}
                                helperText={errors.over100?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name="over300"
                            control={control}
                            render={({field}) => (
                              <LabelNumericFormat
                                title={t('plusThreeHundred')}
                                isRequired={true}
                                value={field.value}
                                onChange={field.onChange}
                                error={!!errors.over300}
                                helperText={errors.over300?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name="over500"
                            control={control}
                            render={({field}) => (
                              <LabelNumericFormat
                                title={t('plusFiveHundred')}
                                isRequired={true}
                                value={field.value}
                                onChange={field.onChange}
                                error={!!errors.over500}
                                helperText={errors.over500?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack direction="row" alignItems="center" height="90px">
                      <Grid container spacing="13px">
                        <Grid item xs={4}>
                          <Controller
                            name="over1000"
                            control={control}
                            render={({field}) => (
                              <LabelNumericFormat
                                title={t('plusThousand')}
                                isRequired={true}
                                value={field.value}
                                onChange={field.onChange}
                                error={!!errors.over1000}
                                helperText={errors.over1000?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name="fsc"
                            control={control}
                            render={({field}) => (
                              <LabelNumericFormat
                                title={t('fsc')}
                                isRequired={true}
                                value={field.value}
                                onChange={field.onChange}
                                error={!!errors.fsc}
                                helperText={errors.fsc?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                </Stack>
                <Typography variant="h5">{t('customizedItem')}</Typography>
                <CustomItems minimumItems={0} />
                <Box width="100%">
                  <Controller
                    name="remark"
                    control={control}
                    render={({field}) => (
                      <LabelTextField
                        title={t('remark')}
                        {...field}
                        multiline={true}
                        minRows={3}
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  sx={{
                    width: '564px',
                  }}>
                  <Button
                    variant="gray"
                    size="large"
                    onClick={() => {
                      navigate('/estimateList');
                    }}>
                    {t('back')}
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    size="large"
                    style={{marginLeft: '24px'}}
                    onClick={handleSubmit(updateEstimate)}>
                    {t('update')}
                  </Button>
                </Stack>
              </Stack>
            </Inner>
          </Stack>
        </Stack>
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          style={{top: '72px'}}
          open={alertOpen}
          autoHideDuration={6000}
          onClose={() => {
            setAlertOpen(false);
          }}>
          <Alert
            severity="warning"
            sx={{width: '588px'}}
            onClose={() => {
              setAlertOpen(false);
            }}>
            <Stack spacing={2}>
              {alertMessages.map(item => {
                return (
                  <>
                    <AlertTitle>{item.title}</AlertTitle>
                    {item.message}
                  </>
                );
              })}
            </Stack>
          </Alert>
        </Snackbar>
      </FormProvider>
    </>
  );
};
