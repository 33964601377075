import {CalculationMethod} from '../API';
import i18n from '../i18n/i18n';

export const getLocalizedCalculationMethod = (
  calculationMethod: CalculationMethod,
) => {
  switch (calculationMethod) {
    case CalculationMethod.PER_SHIPMENT:
      return i18n.t('perShipment');
    case CalculationMethod.PER_UNIT:
      return i18n.t('perPiece');
    case CalculationMethod.PER_CW:
      return i18n.t('perCW');
    case CalculationMethod.PER_GW:
      return i18n.t('perGW');
    default:
      return '';
  }
};
