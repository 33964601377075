import {Typography} from '@mui/material';
import {FieldError, FieldErrorsImpl, Merge} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {LabelTextField} from './LabelTextField';
import {ContactInput} from '../API';

type ShipperContactProps = {
  shipperContact: ContactInput | null;
  handleContactChange: (newContact: Partial<ContactInput>) => void;
  errors?:
    | Merge<
        FieldError,
        FieldErrorsImpl<{
          name: string;
          email: string;
          companyName: string;
          phoneNumber: string;
        }>
      >
    | undefined;
};

export const ShipperContact: React.FC<ShipperContactProps> = ({
  shipperContact,
  handleContactChange,
  errors,
}) => {
  const {t} = useTranslation();
  return (
    <>
      <Typography variant="h3">{t('shipper')}</Typography>
      <LabelTextField
        title={t('companyName')}
        value={shipperContact?.companyName}
        isRequired={true}
        error={!!errors?.companyName}
        onChange={event => {
          handleContactChange({
            ...shipperContact,
            companyName: event.target.value,
          });
        }}
      />
      {errors?.companyName && (
        <Typography variant="error">{t('requiredFieldVaridation')}</Typography>
      )}
      <LabelTextField
        title={t('personInCharge')}
        value={shipperContact?.name}
        isRequired={true}
        error={!!errors?.name}
        onChange={event => {
          handleContactChange({
            ...shipperContact,
            name: event.target.value,
          });
        }}
      />
      {errors?.name && (
        <Typography variant="error">{t('requiredFieldVaridation')}</Typography>
      )}
      <LabelTextField
        title={t('email')}
        value={shipperContact?.email}
        isRequired={true}
        error={!!errors?.email}
        onChange={event => {
          handleContactChange({
            ...shipperContact,
            email: event.target.value,
          });
        }}
      />
      {errors?.email && (
        <Typography variant="error">{t('enterEmailCorrectly')}</Typography>
      )}
      <LabelTextField
        title={t('phoneNumber')}
        value={shipperContact?.phoneNumber}
        isRequired={true}
        error={!!errors?.phoneNumber}
        onChange={event => {
          handleContactChange({
            ...shipperContact,
            phoneNumber: event.target.value,
          });
        }}
      />
      {errors?.phoneNumber && (
        <Typography variant="error">
          {t('enterPhoneNumberCorrectly')}
        </Typography>
      )}
    </>
  );
};
