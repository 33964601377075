import {Typography} from '@mui/material';
import {FC} from 'react';

export const Title: FC<{
  title: string;
}> = ({title}) => {
  return (
    <Typography
      sx={{
        fontSize: '24px',
        fontWeight: 700,
        color: '#494747',
      }}>
      {title}
    </Typography>
  );
};
