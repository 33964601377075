import {Pagination} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {EditUserModal} from './EditUserModal';
import {User} from '../screens/Manager/Employees';

interface UserListProps {
  data: User[];
  isForwarders: boolean;
}

export const UserList = (props: UserListProps) => {
  const {t} = useTranslation();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const rowsPerPage = 10;

  const [viewUser, setViewUser] = useState<UserListProps['data']>([]);

  const handleChangePage = (event: ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    setViewUser(props.data.slice((page - 1) * rowsPerPage, page * rowsPerPage));
    setPageCount(Math.ceil(props.data.length / rowsPerPage));
  }, [props.data, page, rowsPerPage]);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: '#3F6CDF',
                  color: '#fff',
                }}>
                {t('name')}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: '#3F6CDF',
                  color: '#fff',
                }}>
                {t('email')}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: '#3F6CDF',
                  color: '#fff',
                }}>
                {t('status')}
              </TableCell>
              <TableCell
                sx={{
                  marginLeft: 'auto',
                  textAlign: 'center',
                  backgroundColor: '#3F6CDF',
                  color: '#fff',
                }}>
                {t('edit')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {viewUser.map((row, index) => {
              const data = {
                id: row.id,
                email: row.email,
                firstName: row.firstName,
                lastName: row.lastName,
                companyId: row.companyId,
                phoneNumber: row.phoneNumber,
                workLocation: row.workLocation,
                division: row.division,
                position: row.position,
                area: row.area,
                role: row.role,
                status: row.status,
                createdAt: row.createdAt,
              };

              const backgroundColor = index % 2 === 0 ? '#fff' : '#F5F8FA';
              return (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: backgroundColor,
                  }}>
                  <TableCell
                    sx={{
                      padding: '23px',
                      border: 'none',
                    }}>
                    {row.lastName} {row.firstName}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '23px',
                      border: 'none',
                    }}>
                    {row.email}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '23px',
                      border: 'none',
                    }}>
                    {row.status === 'active' ? (
                      <>{t('active')}</>
                    ) : (
                      <>{t('receivingInvitation')}</>
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '23px',
                      border: 'none',
                    }}>
                    <EditUserModal
                      data={data}
                      isForwarder={props.isForwarders}
                      isInvited={row.status === 'invited'}></EditUserModal>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={pageCount}
        page={page}
        color="primary"
        onChange={handleChangePage}
        sx={{display: 'flex', justifyContent: 'center', marginTop: '40px'}}
      />
    </>
  );
};
