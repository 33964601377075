import {Stack, Box, Typography, Rating, Grid} from '@mui/material';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {NumberOfEmployees} from '../../API';
import {ExpandableText} from '../../components/ExpandableText';
import {LabelValue} from '../../components/LabelValue';

type Props = {
  name: string;
  averageRate: number;
  firstName: string;
  lastName: string;
  location: string;
  establishment: string;
  paidInCaptial: number;
  corporateNumber: string;
  listed: boolean;
  numberOfEmployees: NumberOfEmployees;
  companyURL: string;
  reviews: {
    rate: number;
    title: string;
    description: string;
    createdAt: string;
  }[];
};

export const CompanyProfile = ({
  name,
  averageRate,
  firstName,
  lastName,
  location,
  establishment,
  paidInCaptial,
  corporateNumber,
  listed,
  numberOfEmployees,
  companyURL,
  reviews,
}: Props) => {
  const {t} = useTranslation();

  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          <Box sx={{background: 'white', borderRadius: '12px'}}>
            <Stack pt={3} ml={3} pb={14} spacing={3}>
              <Stack height="56px" justifyContent="center">
                <Typography variant="h5">{name ?? ''}</Typography>
              </Stack>
              <Stack flexDirection="column">
                <Typography variant="body2">{t('averageRating')}</Typography>
                <Rating
                  readOnly
                  value={averageRate}
                  precision={0.5}
                  sx={{marginTop: '4px'}}
                />
              </Stack>
              <LabelValue
                title={t('companyRepresentitive')}
                value={`${lastName ?? ''} ${firstName ?? ''}`}
                titleSize="14px"
                titleWeight="300"
                valueSize="14px"
                valueWeight="600"
                valueLeftMargin="0px"
                valueBottomMargin="0px"
              />
              <LabelValue
                title={t('headquartersAddress')}
                value={location ?? ''}
                titleSize="14px"
                titleWeight="300"
                valueSize="14px"
                valueWeight="600"
                valueLeftMargin="0px"
                valueBottomMargin="0px"
              />
              <LabelValue
                title={t('establishmentDate')}
                value={dayjs(establishment).format(`YYYY/MM/DD`)}
                titleSize="14px"
                titleWeight="300"
                valueSize="14px"
                valueWeight="600"
                valueLeftMargin="0px"
                valueBottomMargin="0px"
              />
              <LabelValue
                title={t('capitalAmount')}
                value={`${paidInCaptial.toLocaleString() ?? ''} ${t('jpy')}`}
                titleSize="14px"
                titleWeight="300"
                valueSize="14px"
                valueWeight="600"
                valueLeftMargin="0px"
                valueBottomMargin="0px"
              />
              <LabelValue
                title={t('corporateRegistrationNumber')}
                value={corporateNumber ?? ''}
                titleSize="14px"
                titleWeight="300"
                valueSize="14px"
                valueWeight="600"
                valueLeftMargin="0px"
                valueBottomMargin="0px"
              />
              <LabelValue
                title={t('category')}
                value={
                  listed
                    ? `${t('listedCompany')}`
                    : `${t('unlistedCompany')}` ?? ''
                }
                titleSize="14px"
                titleWeight="300"
                valueSize="14px"
                valueWeight="600"
                valueLeftMargin="0px"
                valueBottomMargin="0px"
              />
              <LabelValue
                title={t('employeesNumber')}
                value={`${
                  numberOfEmployees === NumberOfEmployees.UNDER5
                    ? t('lessFivePeople')
                    : numberOfEmployees === NumberOfEmployees.UNDER20
                    ? t('lessTwentyPeople')
                    : numberOfEmployees === NumberOfEmployees.UNDER100
                    ? t('lessHundredPeople')
                    : numberOfEmployees === NumberOfEmployees.UNDER300
                    ? t('lessThreeHundredPeople')
                    : t('moreThreeHundredPeople')
                }`}
                titleSize="14px"
                titleWeight="300"
                valueSize="14px"
                valueWeight="600"
                valueLeftMargin="0px"
                valueBottomMargin="0px"
              />
              <LabelValue
                title={t('homepageLink')}
                value={companyURL ?? ''}
                titleSize="14px"
                titleWeight="300"
                valueSize="14px"
                valueWeight="600"
                valueLeftMargin="0px"
                valueBottomMargin="0px"
                linked={true}
              />
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Stack flexDirection="column" spacing={2} ml={7}>
            {reviews.map(review => {
              if (!review) return null;
              return (
                <Box
                  width="693px"
                  minHeight="164px"
                  px={2}
                  py={2}
                  sx={{background: 'white', borderRadius: '12px'}}>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    sx={{marginTop: '4px'}}>
                    <Rating readOnly value={review.rate} />
                    <Typography
                      variant="h4"
                      ml={1}
                      sx={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}>
                      {review.title}
                    </Typography>
                  </Stack>
                  <Typography mt={1}>{`${t('reviewDate')} ${dayjs(
                    review.createdAt,
                  ).format('YYYY/MM/DD')}`}</Typography>
                  <ExpandableText
                    text={review.description}
                    maxLength={135}
                    style={{marginTop: '8px'}}
                  />
                </Box>
              );
            }) ?? null}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
